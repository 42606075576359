div.myList{
    padding: 130px 0 40px;
    div.top{
        padding-bottom: 60px;
        border-bottom: 1px solid var(--border-color);
        h1.heading{
            font-size: 46px;
            margin-bottom: 10px;
            color: var(--text-color);
            @media all and (max-width:640px){
                font-size: 36px;
            }
            &.gaia{
              font-family: "Avenir-Black", sans-serif;
            }
        }
        p.desc{
            font-size: 18px;
            color: var(--inner-text-color);
            @media all and (max-width:640px){
                font-size: 14px;
            }
        }
    }
    div.bottom{
        margin-top: 40px;
        div.itemsContainer{
            display: flex;
            flex-wrap: wrap;
            div.items{
                &.rowCount3{
                    width: 32.5%;
                    margin-right: 13px;
                    margin-bottom: 30px;
                    &:nth-child(3n){
                        margin-right: 0;
                    }
                    @media all and (max-width:1200px){
                        width: 32%;
                    }
                    &.landscape {
                        @media all and (min-width: 981px) {
                          &:hover {
                            div.showCardContainer {
                              .square {
                                position: absolute;
                                z-index: 9999999;
                                transform: translateX(60px) translateY(-60px) scale(1.1);
                                div.metaData {
                                  display: block;
                                  visibility: visible !important;
                                  animation: fadeIn 0.3s ease-in-out;
                                }
                              }
                            }
                            &:nth-child(3n) {
                              div.showCardContainer {
                                div.square {
                                  transform: translateX(-60px) translateY(-60px) scale(1.1);
                                }
                              }
                            }
                          
                          }
                        }
            
                        @media all and (max-width: 980px) {
                          width: 24.3%;
                          margin-right: 6px;
                          margin-bottom: 40px;
                          &:nth-child(6n) {
                            margin-right: 6px;
                          }
                          &:nth-child(4n) {
                            margin-right: 0;
                          }
                        }
                        @media all and (max-width: 768px) {
                          width: 32.3%;
                          &:nth-child(4n) {
                            margin-right: 6px;
                          }
                          &:nth-child(3n) {
                            margin-right: 0;
                          }
                        }
                        @media all and (max-width: 640px) {
                          width: 48.2%;
            
                          &:nth-child(3n) {
                            margin-right: 6px;
                          }
                          &:nth-child(2n) {
                            margin-right: 0;
                          }
                        }
                        @media all and (max-width: 480px) {
                          width: 100%;
                          margin-right: 0;
                          &:nth-child(6n) {
                            margin-right: 0;
                          }
                          &:nth-child(4n) {
                            margin-right: 0;
                          }
                          &:nth-child(3n) {
                            margin-right: 0;
                          }
            
                          &:nth-child(2n) {
                            margin-right: 0;
                          }
                          @media all and (max-width: 360px) {
                            width: 100%;
            
                            &:nth-child(3n) {
                              margin-right: 0;
                            }
                            margin-right: 0;
                          }
                        }
                      }
                      &.portrait {
                        @media all and (min-width: 981px) {
                          &:hover {
                            div.showCardContainer {
                              .square {
                                position: absolute;
                                z-index: 9999999;
                                transform: translateX(60px) translateY(-60px) scale(1.2);
                                div.metaData {
                                  display: block;
                                  visibility: visible !important;
                                  animation: fadeIn 0.3s ease-in-out;
                                }
                              }
                            }
                            &:nth-child(3n) {
                              div.showCardContainer {
                                div.square {
                                  transform: translateX(-60px) translateY(-60px) scale(1.2);
                                }
                              }
                            }
                            @media all and (max-width: 1300px) {
                              &:nth-child(6n) {
                                div.showCardContainer {
                                  div.square {
                                    transform: translateX(-60px) translateY(-60px)
                                      scale(1.2);
                                  }
                                }
                              }
                              &:nth-child(7n) {
                                div.showCardContainer {
                                  div.square {
                                    transform: translateX(60px) translateY(-60px) scale(1.2);
                                  }
                                }
                              }
                            }
                            &:last-child {
                              div.showCardContainer {
                                div.square {
                                  transform: translateX(-60px) translateY(-60px) scale(1.2);
                                }
                              }
                            }
                          }
                        }
                       
                        @media all and (max-width: 980px) {
                          width: 24%;
                          margin-right: 6px;
                          margin-bottom: 40px;
                          &:nth-child(5n) {
                            margin-right: 6px;
                          }
                          &:nth-child(4n) {
                            margin-right: 0;
                          }
                          &:nth-child(6n) {
                            margin-right: 6px;
                          }
                        }
                        @media all and (max-width: 768px) {
                          width: 32%;
                          &:nth-child(4n) {
                            margin-right: 6px;
                          }
                          &:nth-child(3n) {
                            margin-right: 0;
                          }
                        }
                        @media all and (max-width: 640px) {
                          width: 48%;
                          &:nth-child(3n) {
                            margin-right: 6px;
                          }
                          &:nth-child(2n) {
                            margin-right: 0;
                          }
                        }
                      }
                }
                &.rowCount4{
                    width: 24%;
                    margin-right: 13px;
                    margin-bottom: 30px;
                    &:nth-child(4n){
                        margin-right: 0;
                    }
                    @media all and (max-width:1200px){
                        width: 23.8%;
                    }
                    &.landscape {
                        @media all and (min-width: 981px) {
                          &:hover {
                            div.showCardContainer {
                              .square {
                                position: absolute;
                                z-index: 9999999;
                                transform: translateX(60px) translateY(-60px) scale(1.1);
                                div.metaData {
                                  display: block;
                                  visibility: visible !important;
                                  animation: fadeIn 0.3s ease-in-out;
                                }
                              }
                            }
                            &:nth-child(4n) {
                              div.showCardContainer {
                                div.square {
                                  transform: translateX(-60px) translateY(-60px) scale(1.1);
                                }
                              }
                            }
                          
                          }
                        }
            
                        @media all and (max-width: 980px) {
                          width: 24.3%;
                          margin-right: 6px;
                          margin-bottom: 40px;
                          &:nth-child(6n) {
                            margin-right: 6px;
                          }
                          &:nth-child(4n) {
                            margin-right: 0;
                          }
                        }
                        @media all and (max-width: 768px) {
                          width: 32.3%;
                          &:nth-child(4n) {
                            margin-right: 6px;
                          }
                          &:nth-child(3n) {
                            margin-right: 0;
                          }
                        }
                        @media all and (max-width: 640px) {
                          width: 48.2%;
            
                          &:nth-child(3n) {
                            margin-right: 6px;
                          }
                          &:nth-child(2n) {
                            margin-right: 0;
                          }
                        }
                        @media all and (max-width: 480px) {
                          width: 100%;
                          margin-right: 0;
                          &:nth-child(6n) {
                            margin-right: 0;
                          }
                          &:nth-child(4n) {
                            margin-right: 0;
                          }
                          &:nth-child(3n) {
                            margin-right: 0;
                          }
            
                          &:nth-child(2n) {
                            margin-right: 0;
                          }
                          @media all and (max-width: 360px) {
                            width: 100%;
            
                            &:nth-child(3n) {
                              margin-right: 0;
                            }
                            margin-right: 0;
                          }
                        }
                      }
                      &.portrait {
                        @media all and (min-width: 981px) {
                          &:hover {
                            div.showCardContainer {
                              .square {
                                position: absolute;
                                z-index: 9999999;
                                transform: translateX(60px) translateY(-60px) scale(1.2);
                                div.metaData {
                                  display: block;
                                  visibility: visible !important;
                                  animation: fadeIn 0.3s ease-in-out;
                                }
                              }
                            }
                            &:nth-child(4n) {
                              div.showCardContainer {
                                div.square {
                                  transform: translateX(-60px) translateY(-60px) scale(1.2);
                                }
                              }
                            }
                            @media all and (max-width: 1300px) {
                              &:nth-child(6n) {
                                div.showCardContainer {
                                  div.square {
                                    transform: translateX(-60px) translateY(-60px)
                                      scale(1.2);
                                  }
                                }
                              }
                              &:nth-child(7n) {
                                div.showCardContainer {
                                  div.square {
                                    transform: translateX(60px) translateY(-60px) scale(1.2);
                                  }
                                }
                              }
                            }
                            &:last-child {
                              div.showCardContainer {
                                div.square {
                                  transform: translateX(-60px) translateY(-60px) scale(1.2);
                                }
                              }
                            }
                          }
                        }
                        
                        @media all and (max-width: 980px) {
                          width: 24%;
                          margin-right: 6px;
                          margin-bottom: 40px;
                          &:nth-child(5n) {
                            margin-right: 6px;
                          }
                          &:nth-child(4n) {
                            margin-right: 0;
                          }
                          &:nth-child(6n) {
                            margin-right: 6px;
                          }
                        }
                        @media all and (max-width: 768px) {
                          width: 32%;
                          &:nth-child(4n) {
                            margin-right: 6px;
                          }
                          &:nth-child(3n) {
                            margin-right: 0;
                          }
                        }
                        @media all and (max-width: 640px) {
                          width: 48%;
                          &:nth-child(3n) {
                            margin-right: 6px;
                          }
                          &:nth-child(2n) {
                            margin-right: 0;
                          }
                        }
                      }
                }
                &.rowCount5{
                    width: 19%;
                    margin-right: 13px;
                    margin-bottom: 30px;
                    &:nth-child(5n){
                        margin-right: 0;
                    }
                    @media all and (max-width:1200px){
                        width: 18.5%;
                    }
                    &.landscape {
                        @media all and (min-width: 981px) {
                          &:hover {
                            div.showCardContainer {
                              .square {
                                position: absolute;
                                z-index: 9999999;
                                transform: translateX(60px) translateY(-60px) scale(1.1);
                                div.metaData {
                                  display: block;
                                  visibility: visible !important;
                                  animation: fadeIn 0.3s ease-in-out;
                                }
                              }
                            }
                            &:nth-child(5n) {
                              div.showCardContainer {
                                div.square {
                                  transform: translateX(-60px) translateY(-60px) scale(1.1);
                                }
                              }
                            }
                          
                          }
                        }
            
                        @media all and (max-width: 980px) {
                          width: 24.3%;
                          margin-right: 6px;
                          margin-bottom: 40px;
                          &:nth-child(6n) {
                            margin-right: 6px;
                          }
                          &:nth-child(4n) {
                            margin-right: 0;
                          }
                        }
                        @media all and (max-width: 768px) {
                          width: 32.3%;
                          &:nth-child(4n) {
                            margin-right: 6px;
                          }
                          &:nth-child(3n) {
                            margin-right: 0;
                          }
                        }
                        @media all and (max-width: 640px) {
                          width: 48.2%;
            
                          &:nth-child(3n) {
                            margin-right: 6px;
                          }
                          &:nth-child(2n) {
                            margin-right: 0;
                          }
                        }
                        @media all and (max-width: 480px) {
                          width: 100%;
                          margin-right: 0;
                          &:nth-child(6n) {
                            margin-right: 0;
                          }
                          &:nth-child(4n) {
                            margin-right: 0;
                          }
                          &:nth-child(3n) {
                            margin-right: 0;
                          }
            
                          &:nth-child(2n) {
                            margin-right: 0;
                          }
                          @media all and (max-width: 360px) {
                            width: 100%;
            
                            &:nth-child(3n) {
                              margin-right: 0;
                            }
                            margin-right: 0;
                          }
                        }
                      }
                      &.portrait {
                        @media all and (min-width: 981px) {
                          &:hover {
                            div.showCardContainer {
                              .square {
                                position: absolute;
                                z-index: 9999999;
                                transform: translateX(60px) translateY(-60px) scale(1.2);
                                div.metaData {
                                  display: block;
                                  visibility: visible !important;
                                  animation: fadeIn 0.3s ease-in-out;
                                }
                              }
                            }
                            &:nth-child(5n) {
                              div.showCardContainer {
                                div.square {
                                  transform: translateX(-60px) translateY(-60px) scale(1.2);
                                }
                              }
                            }
                            @media all and (max-width: 1300px) {
                              &:nth-child(6n) {
                                div.showCardContainer {
                                  div.square {
                                    transform: translateX(-60px) translateY(-60px)
                                      scale(1.2);
                                  }
                                }
                              }
                              &:nth-child(7n) {
                                div.showCardContainer {
                                  div.square {
                                    transform: translateX(60px) translateY(-60px) scale(1.2);
                                  }
                                }
                              }
                            }
                            &:last-child {
                              div.showCardContainer {
                                div.square {
                                  transform: translateX(-60px) translateY(-60px) scale(1.2);
                                }
                              }
                            }
                          }
                        }
                        // width: 13.8%;
                        // &:nth-child(7n) {
                        //   margin-right: 0;
                        // }
                        // @media all and (max-width: 1300px) {
                        //   width: 16%;
                        //   &:nth-child(7n) {
                        //     margin-right: 6px;
                        //   }
                        //   &:nth-child(6n) {
                        //     margin-right: 0;
                        //   }
                        // }
                        @media all and (max-width: 980px) {
                          width: 24%;
                          margin-right: 6px;
                          margin-bottom: 40px;
                          &:nth-child(5n) {
                            margin-right: 6px;
                          }
                          &:nth-child(4n) {
                            margin-right: 0;
                          }
                          &:nth-child(6n) {
                            margin-right: 6px;
                          }
                        }
                        @media all and (max-width: 768px) {
                          width: 32%;
                          &:nth-child(4n) {
                            margin-right: 6px;
                          }
                          &:nth-child(3n) {
                            margin-right: 0;
                          }
                        }
                        @media all and (max-width: 640px) {
                          width: 48%;
                          &:nth-child(3n) {
                            margin-right: 6px;
                          }
                          &:nth-child(2n) {
                            margin-right: 0;
                          }
                        }
                      }
                }
                &.rowCount6{
                    width: 15.8%;
                    margin-right: 13px;
                    margin-bottom: 30px;
                    &:nth-child(6n){
                        margin-right: 0;
                    }
                    @media all and (max-width:1400px){
                        width: 15.5%;
                    }
                    @media all and (max-width:1200px){
                        width: 15%;
                    }
                    &.landscape {
                        @media all and (min-width: 981px) {
                          &:hover {
                            div.showCardContainer {
                              .square {
                                position: absolute;
                                z-index: 9999999;
                                transform: translateX(60px) translateY(-60px) scale(1.1);
                                div.metaData {
                                  display: block;
                                  visibility: visible !important;
                                  animation: fadeIn 0.3s ease-in-out;
                                }
                              }
                            }
                            &:nth-child(6n) {
                              div.showCardContainer {
                                div.square {
                                  transform: translateX(-60px) translateY(-60px) scale(1.1);
                                }
                              }
                            }
                            // &:last-child {
                            //   div.showCardContainer {
                            //     div.square {
                            //       transform: translateX(-60px) translateY(-60px) scale(1.1);
                            //     }
                            //   }
                            // }
                          }
                        }
            
                        // @media all and (max-width: 1400px) {
                        //   width: 16.2%;
                        // }
                        // @media all and (max-width: 1200px) {
                        //   width: 16.1%;
                        //   //
                        // }
                        @media all and (max-width: 980px) {
                          width: 24.3%;
                          margin-right: 6px;
                          margin-bottom: 40px;
                          &:nth-child(6n) {
                            margin-right: 6px;
                          }
                          &:nth-child(4n) {
                            margin-right: 0;
                          }
                        }
                        @media all and (max-width: 768px) {
                          width: 32.3%;
                          &:nth-child(4n) {
                            margin-right: 6px;
                          }
                          &:nth-child(3n) {
                            margin-right: 0;
                          }
                        }
                        @media all and (max-width: 640px) {
                          width: 48.2%;
            
                          &:nth-child(3n) {
                            margin-right: 6px;
                          }
                          &:nth-child(2n) {
                            margin-right: 0;
                          }
                        }
                        @media all and (max-width: 480px) {
                          width: 100%;
                          margin-right: 0;
                          &:nth-child(6n) {
                            margin-right: 0;
                          }
                          &:nth-child(4n) {
                            margin-right: 0;
                          }
                          &:nth-child(3n) {
                            margin-right: 0;
                          }
            
                          &:nth-child(2n) {
                            margin-right: 0;
                          }
                          @media all and (max-width: 360px) {
                            width: 100%;
            
                            &:nth-child(3n) {
                              margin-right: 0;
                            }
                            margin-right: 0;
                          }
                        }
                      }
                      &.portrait {
                        @media all and (min-width: 981px) {
                          &:hover {
                            div.showCardContainer {
                              .square {
                                position: absolute;
                                z-index: 9999999;
                                transform: translateX(60px) translateY(-60px) scale(1.2);
                                div.metaData {
                                  display: block;
                                  visibility: visible !important;
                                  animation: fadeIn 0.3s ease-in-out;
                                }
                              }
                            }
                            &:nth-child(6n) {
                              div.showCardContainer {
                                div.square {
                                  transform: translateX(-60px) translateY(-60px) scale(1.2);
                                }
                              }
                            }
                            @media all and (max-width: 1300px) {
                              &:nth-child(6n) {
                                div.showCardContainer {
                                  div.square {
                                    transform: translateX(-60px) translateY(-60px)
                                      scale(1.2);
                                  }
                                }
                              }
                              &:nth-child(7n) {
                                div.showCardContainer {
                                  div.square {
                                    transform: translateX(60px) translateY(-60px) scale(1.2);
                                  }
                                }
                              }
                            }
                            &:last-child {
                              div.showCardContainer {
                                div.square {
                                  transform: translateX(-60px) translateY(-60px) scale(1.2);
                                }
                              }
                            }
                          }
                        }
                        // width: 13.8%;
                        // &:nth-child(7n) {
                        //   margin-right: 0;
                        // }
                        // @media all and (max-width: 1300px) {
                        //   width: 16%;
                        //   &:nth-child(7n) {
                        //     margin-right: 6px;
                        //   }
                        //   &:nth-child(6n) {
                        //     margin-right: 0;
                        //   }
                        // }
                        @media all and (max-width: 980px) {
                          width: 24%;
                          margin-right: 6px;
                          margin-bottom: 40px;
                          &:nth-child(5n) {
                            margin-right: 6px;
                          }
                          &:nth-child(4n) {
                            margin-right: 0;
                          }
                          &:nth-child(6n) {
                            margin-right: 6px;
                          }
                        }
                        @media all and (max-width: 768px) {
                          width: 32%;
                          &:nth-child(4n) {
                            margin-right: 6px;
                          }
                          &:nth-child(3n) {
                            margin-right: 0;
                          }
                        }
                        @media all and (max-width: 640px) {
                          width: 48%;
                          &:nth-child(3n) {
                            margin-right: 6px;
                          }
                          &:nth-child(2n) {
                            margin-right: 0;
                          }
                        }
                      }
                }
                &.rowCount7 {
                    width: 13.6%;
                    margin-right: 11px;
                    margin-bottom: 30px;
                    &:nth-child(7n){
                        margin-right: 0;
                    }
                    @media all and (max-width:1400px){
                        width: 16%;
                    }
                    @media all and (max-width:1200px){
                        width: 16%;
                    }
                    &.landscape {
                     
                      @media all and (max-width: 2600px) {
                        width: 13%;
                        margin-right: 29px;
                    
                      }
                      @media all and (max-width: 2500px) {
                        width: 13%;
                        margin-right: 25px;
                      }
                      @media all and (max-width: 2200px) {
                        width: 13.8%;
                        margin-right: 8px;
                      }
                      @media all and (max-width: 1980px) {
                        width: 13.9%;
                        margin-right: 6px;
                      }
                      @media all and (max-width: 1768px) {
                        width: 13.9%;
                        margin-right: 5px;
                    
                      }
                      @media all and (max-width: 1600px) {
                        width: 13.8%;
                        margin-right: 5px;
                      }
                      @media all and (max-width: 1400px) {
                        width: 13.8%;
                        margin-right: 5px;
                      }
                      @media all and (max-width: 1200px) {
                        width: 13.7%;
                        margin-right: 5px;
                      }
                      @media all and (min-width: 981px) {
                        &:hover {
                          div.showCardContainer {
                            .square {
                              position: absolute;
                              z-index: 9999999;
                              transform: translateX(60px) translateY(-60px) scale(1.1);
                              div.metaData {
                                display: block;
                                visibility: visible !important;
                                animation: fadeIn 0.3s ease-in-out;
                              }
                            }
                          }
                          &:nth-child(6n) {
                            div.showCardContainer {
                              div.square {
                                transform: translateX(-60px) translateY(-60px) scale(1.1);
                              }
                            }
                          }
                         
                        }
                      }
          
                     
                      @media all and (max-width: 980px) {
                        width: 24.3%;
                        margin-right: 6px;
                        margin-bottom: 40px;
                        &:nth-child(6n) {
                          margin-right: 6px;
                        }
                        &:nth-child(4n) {
                          margin-right: 0;
                        }
                      }
                      @media all and (max-width: 768px) {
                        width: 32.3%;
                        &:nth-child(4n) {
                          margin-right: 6px;
                        }
                        &:nth-child(3n) {
                          margin-right: 0;
                        }
                      }
                      @media all and (max-width: 640px) {
                        width: 48.2%;
          
                        &:nth-child(3n) {
                          margin-right: 6px;
                        }
                        &:nth-child(2n) {
                          margin-right: 0;
                        }
                      }
                      @media all and (max-width: 480px) {
                        width: 100%;
                        margin-right: 0;
                        &:nth-child(6n) {
                          margin-right: 0;
                        }
                        &:nth-child(4n) {
                          margin-right: 0;
                        }
                        &:nth-child(3n) {
                          margin-right: 0;
                        }
          
                        &:nth-child(2n) {
                          margin-right: 0;
                        }
                        @media all and (max-width: 360px) {
                          width: 100%;
          
                          &:nth-child(3n) {
                            margin-right: 0;
                          }
                          margin-right: 0;
                        }
                      }
                    }
                    &.portrait {
                     
                      @media all and (max-width: 1600px) {
                        width: 13.8%;
                        margin-right: 7px;
                        &:nth-child(7n) {
                          margin-right: 0px;
                        }
                      }
                      @media all and (max-width: 1400px) {
                        width: 14%;
                        margin-right: 3px;
                      }
                      @media all and (max-width: 1200px) {
                        width: 13.7%;
                        margin-right: 6.1px;
                      }
                      @media all and (min-width: 981px) {
                        &:hover {
                          div.showCardContainer {
                            .square {
                              position: absolute;
                              z-index: 9999999;
                              transform: translateX(60px) translateY(-60px) scale(1.2);
                              div.metaData {
                                display: block;
                                visibility: visible !important;
                                animation: fadeIn 0.3s ease-in-out;
                              }
                            }
                          }
                          &:nth-child(6n) {
                            div.showCardContainer {
                              div.square {
                                transform: translateX(-60px) translateY(-60px) scale(1.2);
                              }
                            }
                          }
                          @media all and (max-width: 1300px) {
                            &:nth-child(6n) {
                              div.showCardContainer {
                                div.square {
                                  transform: translateX(-60px) translateY(-60px)
                                    scale(1.2);
                                }
                              }
                            }
                            &:nth-child(7n) {
                              div.showCardContainer {
                                div.square {
                                  transform: translateX(60px) translateY(-60px) scale(1.2);
                                }
                              }
                            }
                          }
                          &:last-child {
                            div.showCardContainer {
                              div.square {
                                transform: translateX(-60px) translateY(-60px) scale(1.2);
                              }
                            }
                          }
                        }
                      }
                     
                      @media all and (max-width: 980px) {
                        width: 24%;
                        margin-right: 6px;
                        margin-bottom: 40px;
                        &:nth-child(5n) {
                          margin-right: 6px;
                        }
                        &:nth-child(4n) {
                          margin-right: 0;
                        }
                        &:nth-child(6n) {
                          margin-right: 6px;
                        }
                      }
                      @media all and (max-width: 768px) {
                        width: 32%;
                        &:nth-child(4n) {
                          margin-right: 6px;
                        }
                        &:nth-child(3n) {
                          margin-right: 0;
                        }
                      }
                      @media all and (max-width: 640px) {
                        width: 48%;
                        &:nth-child(3n) {
                          margin-right: 6px;
                        }
                        &:nth-child(2n) {
                          margin-right: 0;
                        }
                      }
                    }
                  }
                  &.rowCount8 {
                    width: 12%;
                    margin-right: 8px;
                    margin-bottom: 30px;
                    &:nth-child(8n) {
                      margin-right: 0;
                    }
                    
                    &.landscape {
                      @media all and (max-width: 2500px) {
                        width: 12%;
                        margin-right: 10px;
                      }
                      @media all and (max-width: 2200px) {
                        width: 12%;
                        margin-right: 9px;
                      }
                      @media all and (max-width: 1980px) {
                        width: 12%;
                        margin-right: 8px;
                      }
                      @media all and (max-width: 1768px) {
                       width: 12%;
                      margin-right: 7px;
                      }
                      @media all and (max-width: 1600px) {
                        width: 12%;
                        margin-right: 6px;
                      }
                      @media all and (max-width: 1400px) {
                        width: 12%;
                        margin-right: 5px;
                      }
                      @media all and (max-width: 1200px) {
                        width: 11.9%;
                        margin-right: 5px;
                      }
                      @media all and (min-width: 981px) {
                        &:hover {
                          div.showCardContainer {
                            .square {
                              position: absolute;
                              z-index: 9999999;
                              transform: translateX(60px) translateY(-60px) scale(1.1);
                              div.metaData {
                                display: block;
                                visibility: visible !important;
                                animation: fadeIn 0.3s ease-in-out;
                              }
                            }
                          }
                          &:nth-child(5n) {
                            div.showCardContainer {
                              div.square {
                                transform: translateX(-60px) translateY(-60px) scale(1.1);
                              }
                            }
                          }
                         
                        }
                      }
          
                     
                      @media all and (max-width: 980px) {
                        width: 24.3%;
                        margin-right: 6px;
                        margin-bottom: 40px;
                        &:nth-child(6n) {
                          margin-right: 6px;
                        }
                        &:nth-child(4n) {
                          margin-right: 0;
                        }
                      }
                      @media all and (max-width: 768px) {
                        width: 32.3%;
                        &:nth-child(4n) {
                          margin-right: 6px;
                        }
                        &:nth-child(3n) {
                          margin-right: 0;
                        }
                      }
                      @media all and (max-width: 640px) {
                        width: 48.2%;
          
                        &:nth-child(3n) {
                          margin-right: 6px;
                        }
                        &:nth-child(2n) {
                          margin-right: 0;
                        }
                      }
                      @media all and (max-width: 480px) {
                        width: 100%;
                        margin-right: 0;
                        &:nth-child(6n) {
                          margin-right: 0;
                        }
                        &:nth-child(4n) {
                          margin-right: 0;
                        }
                        &:nth-child(3n) {
                          margin-right: 0;
                        }
          
                        &:nth-child(2n) {
                          margin-right: 0;
                        }
                        @media all and (max-width: 360px) {
                          width: 100%;
          
                          &:nth-child(3n) {
                            margin-right: 0;
                          }
                          margin-right: 0;
                        }
                      }
                    }
                    &.portrait {
                       width: 11%;
                       margin-right: 21px;
                    @media all and (max-width: 2600px) {
                        width: 12%;
                        margin-right: 13px;
                        &:nth-child(8n){
                          margin-right: 0;
                      }
                      }
                      @media all and (max-width: 2500px) {
                        width: 12%;
                        margin-right: 11px;
                      }
                      @media all and (max-width: 2200px) {
                        width: 12%;
                        margin-right: 10px;
                      }
                      @media all and (max-width: 1980px) {
                        width: 12%;
                        margin-right: 9px;
                      }
                      @media all and (max-width: 1768px) {
                       width: 12%;
                      margin-right: 8px;
                      }
                      @media all and (max-width: 1600px) {
                        width: 12%;
                        margin-right: 7px;
                      }
                      @media all and (max-width: 1400px) {
                        width: 12%;
                        margin-right: 6px;
                      }
                      @media all and (max-width: 1200px) {
                        width: 11.9%;
                        margin-right: 6px;
                      }
                      @media all and (min-width: 981px) {
                        &:hover {
                          div.showCardContainer {
                            .square {
                              position: absolute;
                              z-index: 9999999;
                              transform: translateX(60px) translateY(-60px) scale(1.2);
                              div.metaData {
                                display: block;
                                visibility: visible !important;
                                animation: fadeIn 0.3s ease-in-out;
                              }
                            }
                          }
                          &:nth-child(8n) {
                            div.showCardContainer {
                              div.square {
                                transform: translateX(-60px) translateY(-60px) scale(1.2);
                              }
                            }
                          }
                         
                          &:last-child {
                            div.showCardContainer {
                              div.square {
                                transform: translateX(-60px) translateY(-60px) scale(1.2);
                              }
                            }
                          }
                        }
                      }
                     
                      @media all and (max-width: 980px) {
                        width: 24%;
                        margin-right: 6px;
                        margin-bottom: 40px;
                        &:nth-child(5n) {
                          margin-right: 6px;
                        }
                        &:nth-child(4n) {
                          margin-right: 0;
                        }
                        &:nth-child(6n) {
                          margin-right: 6px;
                        }
                      }
                      @media all and (max-width: 768px) {
                        width: 32%;
                        &:nth-child(4n) {
                          margin-right: 6px;
                        }
                        &:nth-child(3n) {
                          margin-right: 0;
                        }
                      }
                      @media all and (max-width: 640px) {
                        width: 48%;
                        &:nth-child(3n) {
                          margin-right: 6px;
                        }
                        &:nth-child(2n) {
                          margin-right: 0;
                        }
                      }
                    }
                  }
                &.portrait{
                  
                    // @media all and (max-width:1400px){
                    //     width: 15.6%;
                    // }
                    // @media all and (max-width:1200px){
                    //     width: 18.8%;
                    //     &:nth-child(5n){
                    //         margin-right: 0;
                    //     }
                    //    &:nth-child(6n){
                    //     margin-right: 13px;
                    //    }
                    // }
                    @media all and (max-width:980px){
                        width:23.5%;
                        margin-right: 13px;
                        margin-bottom: 40px;
                        &:nth-child(4n){
                            margin-right: 0;
                        }
                       &:nth-child(5n){
                        margin-right: 13px;
                       }
                    }
                    @media all and (max-width:768px){
                        width:23%;
                        &:nth-child(4n){
                            margin-right: 0;
                        }
                       &:nth-child(5n){
                        margin-right: 13px;
                       }
                    }
                    @media all and (max-width:640px){
                        width:31%;
                        
                       &:nth-child(4n){
                        margin-right: 13px;
                       }
                       &:nth-child(3n){
                        margin-right: 0;
                    }
                    }
                    @media all and (max-width:480px){
                        width:47%;
                        
                       &:nth-child(3n){
                        margin-right: 13px;
                       }
                       &:nth-child(2n){
                        margin-right: 0;
                    }
                    }
                }
                &.landscape{
                    // width: 32.8%;
               
                // &:nth-child(3n){
                //     margin-right: 0;
                // }
                // @media all and (max-width:1400px){
                //     width: 32.5%;
                // }
                // @media all and (max-width:1200px){
                //     width:32%;
                // //     &:nth-child(4n){
                // //         margin-right: 0;
                // //     }
                // //    &:nth-child(5n){
                // //     margin-right: 13px;
                // //    }
                // }
                @media all and (max-width:980px){
                    width:32%;
                    margin-right: 13px;
                margin-bottom: 40px;
                    &:nth-child(3n){
                        margin-right: 0;
                    }
                    
                }
                @media all and (max-width:768px){
                    width:47.6%;
                    &:nth-child(2n){
                        margin-right: 0;
                    }
                    &:nth-child(3n){
                        margin-right: 13px;
                    }
                }
                @media all and (max-width:640px){
                    width:48%;
                    
                   &:nth-child(3n){
                    margin-right: 13px;
                   }
                   &:nth-child(2n){
                    margin-right: 0;
                }
                }
                @media all and (max-width:480px){
                    width:47%;
                    
                   &:nth-child(3n){
                    margin-right: 13px;
                   }
                   &:nth-child(2n){
                    margin-right: 0;
                }
                }
                }

            }
        }
    }
}

div.myList.rtl{

  .bottom{
    .itemsContainer{
      .items{
        &.rowCount3{
          margin-right: 0;
          margin-left: 13px;
          &:nth-child(3n){
              margin-left: 0;
          }

          &.landscape {
            @media all and (min-width: 981px) {
              &:hover {
                div.showCardContainer {
                  .square {
                    transform: translateX(-60px) translateY(-60px) scale(1.1);
                  }
                }
                &:nth-child(3n) {
                  div.showCardContainer {
                    div.square {
                      transform: translateX(60px) translateY(-60px) scale(1.1);
                    }
                  }
                }
              }
            }
            @media all and (max-width: 980px) {
              margin-right: 0;
              margin-left: 6px;
              &:nth-child(6n) {
                margin-right: 0;
                margin-left: 6px;
              }
              &:nth-child(4n) {
                margin-left: 0;
              }
            }
            @media all and (max-width: 768px) {
              &:nth-child(4n) {
                margin-right: 0;
                margin-left: 6px;
              }
              &:nth-child(3n) {
                margin-left: 0;
              }
            }
            @media all and (max-width: 640px) {
              &:nth-child(3n) {
                margin-right: 0;
                margin-left: 6px;
              }
              &:nth-child(2n) {
                margin-left: 0;
              }
            }
            @media all and (max-width: 480px) {
              margin-left: 0;
              &:nth-child(6n) {
                margin-left: 0;
              }
              &:nth-child(4n) {
                margin-left: 0;
              }
              &:nth-child(3n) {
                margin-left: 0;
              }

              &:nth-child(2n) {
                margin-left: 0;
              }
              @media all and (max-width: 360px) {
                &:nth-child(3n) {
                  margin-left: 0;
                }
                margin-left: 0;
              }
            }
          }
          &.portrait {
            @media all and (min-width: 981px) {
              &:hover {
                div.showCardContainer {
                  .square {
                    transform: translateX(-60px) translateY(-60px) scale(1.2);
                  }
                }
                &:nth-child(3n) {
                  div.showCardContainer {
                    div.square {
                      transform: translateX(60px) translateY(-60px) scale(1.2);
                    }
                  }
                }
                @media all and (max-width: 1300px) {
                  &:nth-child(6n) {
                    div.showCardContainer {
                      div.square {
                        transform: translateX(60px) translateY(-60px)
                          scale(1.2);
                      }
                    }
                  }
                  &:nth-child(7n) {
                    div.showCardContainer {
                      div.square {
                        transform: translateX(-60px) translateY(-60px) scale(1.2);
                      }
                    }
                  }
                }
                &:last-child {
                  div.showCardContainer {
                    div.square {
                      transform: translateX(60px) translateY(-60px) scale(1.2);
                    }
                  }
                }
              }
            }
           
            @media all and (max-width: 980px) {
              margin-right: 0;
              margin-left: 6px;
              &:nth-child(5n) {
                margin-right: 0;
                margin-left: 6px;
              }
              &:nth-child(4n) {
                margin-left: 0;
              }
              &:nth-child(6n) {
                margin-right: 0;
                margin-left: 6px;
              }
            }
            @media all and (max-width: 768px) {
              &:nth-child(4n) {
                margin-right: 0;
                margin-left: 6px;
              }
              &:nth-child(3n) {
                margin-left: 0;
              }
            }
            @media all and (max-width: 640px) {
              &:nth-child(3n) {
                margin-right: 0;
                margin-left: 6px;
              }
              &:nth-child(2n) {
                margin-left: 0;
              }
            }
          }
        }
        &.rowCount4{
          margin-right: 0;
          margin-left: 13px;
          &:nth-child(4n){
              margin-left: 0;
          }
          &.landscape {
            @media all and (min-width: 981px) {
              &:hover {
                div.showCardContainer {
                  .square {
                    transform: translateX(-60px) translateY(-60px) scale(1.1);
                  }
                }
                &:nth-child(4n) {
                  div.showCardContainer {
                    div.square {
                      transform: translateX(60px) translateY(-60px) scale(1.1);
                    }
                  }
                }
                
              }
            }

          
            @media all and (max-width: 980px) {
              margin-right: 0;
              margin-left: 6px;
              &:nth-child(6n) {
                margin-right: 0;
                margin-left: 6px;
              }
              &:nth-child(4n) {
                margin-left: 0;
              }
            }
            @media all and (max-width: 768px) {
              &:nth-child(4n) {
                margin-right: 0;
                margin-left: 6px;
              }
              &:nth-child(3n) {
                margin-left: 0;
              }
            }
            @media all and (max-width: 640px) {
              &:nth-child(3n) {
                margin-right: 0;
                margin-left: 6px;
              }
              &:nth-child(2n) {
                margin-left: 0;
              }
            }
            @media all and (max-width: 480px) {
              margin-left: 0;
              &:nth-child(6n) {
                margin-left: 0;
              }
              &:nth-child(4n) {
                margin-left: 0;
              }
              &:nth-child(3n) {
                margin-left: 0;
              }

              &:nth-child(2n) {
                margin-left: 0;
              }
              @media all and (max-width: 360px) {
                &:nth-child(3n) {
                  margin-left: 0;
                }
                margin-left: 0;
              }
            }
          }
          &.portrait {
            @media all and (min-width: 981px) {
              &:hover {
                div.showCardContainer {
                  .square {
                    transform: translateX(-60px) translateY(-60px) scale(1.2);
                  }
                }
                &:nth-child(4n) {
                  div.showCardContainer {
                    div.square {
                      transform: translateX(60px) translateY(-60px) scale(1.2);
                    }
                  }
                }
                @media all and (max-width: 1300px) {
                  &:nth-child(6n) {
                    div.showCardContainer {
                      div.square {
                        transform: translateX(60px) translateY(-60px)
                          scale(1.2);
                      }
                    }
                  }
                  &:nth-child(7n) {
                    div.showCardContainer {
                      div.square {
                        transform: translateX(-60px) translateY(-60px) scale(1.2);
                      }
                    }
                  }
                }
                &:last-child {
                  div.showCardContainer {
                    div.square {
                      transform: translateX(60px) translateY(-60px) scale(1.2);
                    }
                  }
                }
              }
            }
           
            @media all and (max-width: 980px) {
              margin-right: 0;
              margin-left: 6px;
              &:nth-child(5n) {
                margin-right: 0;
                margin-left: 6px;
              }
              &:nth-child(4n) {
                margin-left: 0;
              }
              &:nth-child(6n) {
                margin-right: 0;
                margin-left: 6px;
              }
            }
            @media all and (max-width: 768px) {
              &:nth-child(4n) {
                margin-right: 0;
                margin-left: 6px;
              }
              &:nth-child(3n) {
                margin-left: 0;
              }
            }
            @media all and (max-width: 640px) {
              &:nth-child(3n) {
                margin-right: 0;
                margin-left: 6px;
              }
              &:nth-child(2n) {
                margin-left: 0;
              }
            }
          }
        }
        &.rowCount5{
          margin-right: 0;
          margin-left: 13px;
          &:nth-child(5n){
              margin-left: 0;
          }
          &.landscape {
            @media all and (min-width: 981px) {
              &:hover {
                div.showCardContainer {
                  .square {
                    transform: translateX(-60px) translateY(-60px) scale(1.1);
                  }
                }
                &:nth-child(5n) {
                  div.showCardContainer {
                    div.square {
                      transform: translateX(60px) translateY(-60px) scale(1.1);
                    }
                  }
                }
              
              }
            }

          
            @media all and (max-width: 980px) {
              margin-right: 0;
              margin-left: 6px;
              &:nth-child(6n) {
                margin-right: 0;
                margin-left: 6px;
              }
              &:nth-child(4n) {
                margin-left: 0;
              }
            }
            @media all and (max-width: 768px) {
              &:nth-child(4n) {
                margin-right: 0;
                margin-left: 6px;
              }
              &:nth-child(3n) {
                margin-left: 0;
              }
            }
            @media all and (max-width: 640px) {
              &:nth-child(3n) {
                margin-right: 0;
                margin-left: 6px;
              }
              &:nth-child(2n) {
                margin-left: 0;
              }
            }
            @media all and (max-width: 480px) {
              margin-left: 0;
              &:nth-child(6n) {
                margin-left: 0;
              }
              &:nth-child(4n) {
                margin-left: 0;
              }
              &:nth-child(3n) {
                margin-left: 0;
              }

              &:nth-child(2n) {
                margin-left: 0;
              }
              @media all and (max-width: 360px) {
                &:nth-child(3n) {
                  margin-left: 0;
                }
                margin-left: 0;
              }
            }
          }
          &.portrait {
            @media all and (min-width: 981px) {
              &:hover {
                div.showCardContainer {
                  .square {
                    transform: translateX(-60px) translateY(-60px) scale(1.2);
                  }
                }
                &:nth-child(5n) {
                  div.showCardContainer {
                    div.square {
                      transform: translateX(60px) translateY(-60px) scale(1.2);
                    }
                  }
                }
                @media all and (max-width: 1300px) {
                  &:nth-child(6n) {
                    div.showCardContainer {
                      div.square {
                        transform: translateX(60px) translateY(-60px)
                          scale(1.2);
                      }
                    }
                  }
                  &:nth-child(7n) {
                    div.showCardContainer {
                      div.square {
                        transform: translateX(-60px) translateY(-60px) scale(1.2);
                      }
                    }
                  }
                }
                &:last-child {
                  div.showCardContainer {
                    div.square {
                      transform: translateX(60px) translateY(-60px) scale(1.2);
                    }
                  }
                }
              }
            }
            @media all and (max-width: 980px) {
              margin-right: 0;
              margin-left: 6px;
              &:nth-child(5n) {
                margin-right: 0;
                margin-left: 6px;
              }
              &:nth-child(4n) {
                margin-left: 0;
              }
              &:nth-child(6n) {
                margin-right: 0;
                margin-left: 6px;
              }
            }
            @media all and (max-width: 768px) {
              &:nth-child(4n) {
                margin-right: 0;
                margin-left: 6px;
              }
              &:nth-child(3n) {
                margin-left: 0;
              }
            }
            @media all and (max-width: 640px) {
              &:nth-child(3n) {
                margin-right: 0;
                margin-left: 6px;
              }
              &:nth-child(2n) {
                margin-left: 0;
              }
            }
          }
        }
        &.rowCount6{
          margin-right: 0;
          margin-left: 13px;
          &:nth-child(6n){
              margin-left: 0;
          }
          &.landscape {
            @media all and (min-width: 981px) {
              &:hover {
                div.showCardContainer {
                  .square {
                    transform: translateX(-60px) translateY(-60px) scale(1.1);
                  }
                }
                &:nth-child(6n) {
                  div.showCardContainer {
                    div.square {
                      transform: translateX(60px) translateY(-60px) scale(1.1);
                    }
                  }
                }
                
              }
            }

            
            @media all and (max-width: 980px) {
              margin-right: 0;
              margin-left: 6px;
              &:nth-child(6n) {
                margin-right: 0;
                margin-left: 6px;
              }
              &:nth-child(4n) {
                margin-left: 0;
              }
            }
            @media all and (max-width: 768px) {
              &:nth-child(4n) {
                margin-right: 0;
                margin-left: 6px;
              }
              &:nth-child(3n) {
                margin-left: 0;
              }
            }
            @media all and (max-width: 640px) {
              &:nth-child(3n) {
                margin-right: 0;
                margin-left: 6px;
              }
              &:nth-child(2n) {
                margin-left: 0;
              }
            }
            @media all and (max-width: 480px) {
              margin-left: 0;
              &:nth-child(6n) {
                margin-left: 0;
              }
              &:nth-child(4n) {
                margin-left: 0;
              }
              &:nth-child(3n) {
                margin-left: 0;
              }

              &:nth-child(2n) {
                margin-left: 0;
              }
              @media all and (max-width: 360px) {
                &:nth-child(3n) {
                  margin-left: 0;
                }
                margin-left: 0;
              }
            }
          }
          &.portrait {
            @media all and (min-width: 981px) {
              &:hover {
                div.showCardContainer {
                  .square {
                    transform: translateX(-60px) translateY(-60px) scale(1.2);
                  }
                }
                &:nth-child(6n) {
                  div.showCardContainer {
                    div.square {
                      transform: translateX(60px) translateY(-60px) scale(1.2);
                    }
                  }
                }
                @media all and (max-width: 1300px) {
                  &:nth-child(6n) {
                    div.showCardContainer {
                      div.square {
                        transform: translateX(60px) translateY(-60px)
                          scale(1.2);
                      }
                    }
                  }
                  &:nth-child(7n) {
                    div.showCardContainer {
                      div.square {
                        transform: translateX(-60px) translateY(-60px) scale(1.2);
                      }
                    }
                  }
                }
                &:last-child {
                  div.showCardContainer {
                    div.square {
                      transform: translateX(60px) translateY(-60px) scale(1.2);
                    }
                  }
                }
              }
            }
            @media all and (max-width: 980px) {
              margin-right: 0;
              margin-left: 6px;
              &:nth-child(5n) {
                margin-right: 0;
                margin-left: 6px;
              }
              &:nth-child(4n) {
                margin-left: 0;
              }
              &:nth-child(6n) {
                margin-right: 0;
                margin-left: 6px;
              }
            }
            @media all and (max-width: 768px) {
              &:nth-child(4n) {
                margin-right: 0;
                margin-left: 6px;
              }
              &:nth-child(3n) {
                margin-left: 0;
              }
            }
            @media all and (max-width: 640px) {
              &:nth-child(3n) {
                margin-right: 0;
                margin-left: 6px;
              }
              &:nth-child(2n) {
                margin-left: 0;
              }
            }
          }
        }
        &.rowCount7 {
          margin-right: 0;
          margin-left: 11px;
          &:nth-child(7n){
              margin-left: 0;
          }
          &.landscape {
            @media all and (max-width: 2600px) {
              margin-right: 0;
              margin-left: 29px;
            }
            @media all and (max-width: 2500px) {
              margin-right: 0;
              margin-left: 25px;
            }
            @media all and (max-width: 2200px) {
              margin-right: 0;
              margin-left: 8px;
            }
            @media all and (max-width: 1980px) {
              margin-right: 0;
              margin-left: 6px;
            }
            @media all and (max-width: 1768px) {
              margin-right: 0;
              margin-left: 5px;
            }
            @media all and (max-width: 1600px) {
              margin-right: 0;
              margin-left: 5px;
            }
            @media all and (max-width: 1400px) {
              margin-right: 0;
              margin-left: 5px;
            }
            @media all and (max-width: 1200px) {
              margin-right: 0;
              margin-left: 5px;
            }
            @media all and (min-width: 981px) {
              &:hover {
                div.showCardContainer {
                  .square {
                    transform: translateX(-60px) translateY(-60px) scale(1.1);
                  }
                }
                &:nth-child(6n) {
                  div.showCardContainer {
                    div.square {
                      transform: translateX(60px) translateY(-60px) scale(1.1);
                    }
                  }
                }
               
              }
            }

           
            @media all and (max-width: 980px) {
              margin-right: 0;
              margin-left: 6px;
              &:nth-child(6n) {
                margin-right: 0;
                margin-left: 6px;
              }
              &:nth-child(4n) {
                margin-left: 0;
              }
            }
            @media all and (max-width: 768px) {
              &:nth-child(4n) {
                margin-right: 0;
                margin-left: 6px;
              }
              &:nth-child(3n) {
                margin-left: 0;
              }
            }
            @media all and (max-width: 640px) {
              &:nth-child(3n) {
                margin-right: 0;
                margin-left: 6px;
              }
              &:nth-child(2n) {
                margin-left: 0;
              }
            }
            @media all and (max-width: 480px) {
              margin-left: 0;
              &:nth-child(6n) {
                margin-left: 0;
              }
              &:nth-child(4n) {
                margin-left: 0;
              }
              &:nth-child(3n) {
                margin-left: 0;
              }

              &:nth-child(2n) {
                margin-left: 0;
              }
              @media all and (max-width: 360px) {
                &:nth-child(3n) {
                  margin-left: 0;
                }
                margin-left: 0;
              }
            }
          }
          &.portrait {
            @media all and (max-width: 1600px) {
              margin-right: 0;
              margin-left: 7px;
              &:nth-child(7n) {
                margin-right: 0;
                margin-left: 0px;
              }
            }
            @media all and (max-width: 1400px) {
              margin-right: 0;
              margin-left: 3px;
            }
            @media all and (max-width: 1200px) {
              margin-right: 0;
              margin-left: 6.1px;
            }
            @media all and (min-width: 981px) {
              &:hover {
                div.showCardContainer {
                  .square {
                    transform: translateX(-60px) translateY(-60px) scale(1.2);
                  }
                }
                &:nth-child(6n) {
                  div.showCardContainer {
                    div.square {
                      transform: translateX(60px) translateY(-60px) scale(1.2);
                    }
                  }
                }
                @media all and (max-width: 1300px) {
                  &:nth-child(6n) {
                    div.showCardContainer {
                      div.square {
                        transform: translateX(60px) translateY(-60px)
                          scale(1.2);
                      }
                    }
                  }
                  &:nth-child(7n) {
                    div.showCardContainer {
                      div.square {
                        transform: translateX(-60px) translateY(-60px) scale(1.2);
                      }
                    }
                  }
                }
                &:last-child {
                  div.showCardContainer {
                    div.square {
                      transform: translateX(60px) translateY(-60px) scale(1.2);
                    }
                  }
                }
              }
            }
           
            @media all and (max-width: 980px) {
              margin-right: 0;
              margin-left: 6px;
              &:nth-child(5n) {
                margin-right: 0;
                margin-left: 6px;
              }
              &:nth-child(4n) {
                margin-left: 0;
              }
              &:nth-child(6n) {
                margin-right: 0;
                margin-left: 6px;
              }
            }
            @media all and (max-width: 768px) {
              &:nth-child(4n) {
                margin-right: 0;
                margin-left: 6px;
              }
              &:nth-child(3n) {
                margin-left: 0;
              }
            }
            @media all and (max-width: 640px) {
              &:nth-child(3n) {
                margin-right: 0;
                margin-left: 6px;
              }
              &:nth-child(2n) {
                margin-left: 0;
              }
            }
          }
        }
        &.rowCount8 {
          margin-right: 0;
          margin-left: 8px;
          &:nth-child(8n) {
            margin-left: 0;
          }
          
          &.landscape {
            @media all and (max-width: 2500px) {
              margin-right: 0;
              margin-left: 10px;
            }
            @media all and (max-width: 2200px) {
              margin-right: 0;
              margin-left: 9px;
            }
            @media all and (max-width: 1980px) {
              margin-right: 0;
              margin-left: 8px;
            }
            @media all and (max-width: 1768px) {
             margin-right: 0;
            margin-left: 7px;
            }
            @media all and (max-width: 1600px) {
              margin-right: 0;
              margin-left: 6px;
            }
            @media all and (max-width: 1400px) {
              margin-right: 0;
              margin-left: 5px;
            }
            @media all and (max-width: 1200px) {
              margin-right: 0;
              margin-left: 5px;
            }
            @media all and (min-width: 981px) {
              &:hover {
                div.showCardContainer {
                  .square {
                    transform: translateX(-60px) translateY(-60px) scale(1.1);
                  }
                }
                &:nth-child(5n) {
                  div.showCardContainer {
                    div.square {
                      transform: translateX(60px) translateY(-60px) scale(1.1);
                    }
                  }
                }
               
              }
            }

           
            @media all and (max-width: 980px) {
              margin-right: 0;
              margin-left: 6px;
              &:nth-child(6n) {
                margin-right: 0;
                margin-left: 6px;
              }
              &:nth-child(4n) {
                margin-left: 0;
              }
            }
            @media all and (max-width: 768px) {
              &:nth-child(4n) {
                margin-right: 0;
                margin-left: 6px;
              }
              &:nth-child(3n) {
                margin-left: 0;
              }
            }
            @media all and (max-width: 640px) {
              &:nth-child(3n) {
                margin-right: 0;
                margin-left: 6px;
              }
              &:nth-child(2n) {
                margin-left: 0;
              }
            }
            @media all and (max-width: 480px) {
              margin-left: 0;
              &:nth-child(6n) {
                margin-left: 0;
              }
              &:nth-child(4n) {
                margin-left: 0;
              }
              &:nth-child(3n) {
                margin-left: 0;
              }

              &:nth-child(2n) {
                margin-left: 0;
              }
              @media all and (max-width: 360px) {
                &:nth-child(3n) {
                  margin-left: 0;
                }
                margin-left: 0;
              }
            }
          }
          &.portrait {
            margin-right: 0;
             margin-left: 21px;
          @media all and (max-width: 2600px) {
            margin-right: 0;
              margin-left: 13px;
              &:nth-child(8n){
                margin-left: 0;
            }
            }
            @media all and (max-width: 2500px) {
              margin-right: 0;
              margin-left: 11px;
            }
            @media all and (max-width: 2200px) {
              margin-right: 0;
              margin-left: 10px;
            }
            @media all and (max-width: 1980px) {
              margin-right: 0;
              margin-left: 9px;
            }
            @media all and (max-width: 1768px) {
             margin-right: 0;
            margin-left: 8px;
            }
            @media all and (max-width: 1600px) {
              margin-right: 0;
              margin-left: 7px;
            }
            @media all and (max-width: 1400px) {
              margin-right: 0;
              margin-left: 6px;
            }
            @media all and (max-width: 1200px) {
              margin-right: 0;
              margin-left: 6px;
            }
            @media all and (min-width: 981px) {
              &:hover {
                div.showCardContainer {
                  .square {
                    transform: translateX(-60px) translateY(-60px) scale(1.2);
                  }
                }
                &:nth-child(8n) {
                  div.showCardContainer {
                    div.square {
                      transform: translateX(60px) translateY(-60px) scale(1.2);
                    }
                  }
                }
               
                &:last-child {
                  div.showCardContainer {
                    div.square {
                      transform: translateX(60px) translateY(-60px) scale(1.2);
                    }
                  }
                }
              }
            }
           
            @media all and (max-width: 980px) {
              margin-right: 0;
              margin-left: 6px;
              &:nth-child(5n) {
                margin-right: 0;
                margin-left: 6px;
              }
              &:nth-child(4n) {
                margin-left: 0;
              }
              &:nth-child(6n) {
                margin-right: 0;
                margin-left: 6px;
              }
            }
            @media all and (max-width: 768px) {
              &:nth-child(4n) {
                margin-right: 0;
                margin-left: 6px;
              }
              &:nth-child(3n) {
                margin-left: 0;
              }
            }
            @media all and (max-width: 640px) {
              &:nth-child(3n) {
                margin-right: 0;
                margin-left: 6px;
              }
              &:nth-child(2n) {
                margin-left: 0;
              }
            }
          }
        }
        &.portrait{
          @media all and (max-width:980px){
            margin-right: 0;
              margin-left: 13px;
              &:nth-child(4n){
                  margin-left: 0;
              }
             &:nth-child(5n){
              margin-right: 0;
              margin-left: 13px;
             }
          }
          @media all and (max-width:768px){
              &:nth-child(4n){
                  margin-left: 0;
              }
             &:nth-child(5n){
              margin-right: 0;
              margin-left: 13px;
             }
          }
          @media all and (max-width:640px){
             &:nth-child(4n){
              margin-right: 0;
              margin-left: 13px;
             }
             &:nth-child(3n){
              margin-left: 0;
          }
          }
          @media all and (max-width:480px){
             &:nth-child(3n){
              margin-right: 0;
              margin-left: 13px;
             }
             &:nth-child(2n){
              margin-left: 0;
          }
          }
      }
      &.landscape{
      @media all and (max-width:980px){
        margin-right: 0;
          margin-left: 13px;
          &:nth-child(3n){
              margin-left: 0;
          }
          
      }
      @media all and (max-width:768px){
          &:nth-child(2n){
              margin-left: 0;
          }
          &:nth-child(3n){
            margin-right: 0;
              margin-left: 13px;
          }
      }
      @media all and (max-width:640px){
         &:nth-child(3n){
          margin-right: 0;
          margin-left: 13px;
         }
         &:nth-child(2n){
          margin-left: 0;
      }
      }
      @media all and (max-width:480px){
         &:nth-child(3n){
          margin-right: 0;
          margin-left: 13px;
         }
         &:nth-child(2n){
          margin-left: 0;
      }
      }
      }
      }
    }
  }
}
