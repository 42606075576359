.package-item {
    background: hsla(0, 0%, 80%, .08);
    border-radius: 3px;
    cursor: pointer;
    display: inline-block;
    margin: 10px;
    padding: 5px 20px;
    text-align: left;
    z-index: 9999;
    color: #ffffffcc;
}

.package-item:hover {
    color: #000;
    background: #ffffffcc;
}