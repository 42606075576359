@charset "UTF-8";
div.header-container {
  position: fixed;
  top: 0;
  z-index: 99;
  width: 100%;
  background: linear-gradient(180deg, rgba(16, 20, 31, 0.8) 0.98%, rgba(16, 20, 31, 0.4) 59.23%, rgba(16, 20, 31, 0.2) 78.16%, rgba(16, 20, 31, 0.0001) 96.12%);
  height: 100px;
  display: flex;
  align-items: center;
  transition: 0.4s ease-out;
}
div.header-container.background {
  background: var(--bg-color);
}
div.header-container div.wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
@media all and (max-width: 980px) {
  div.header-container div.wrapper {
    width: 97%;
  }
}
div.header-container div.wrapper div.leftContainer {
  display: flex;
  align-items: center;
}
div.header-container div.wrapper div.leftContainer div.menuIconContainer {
  display: none;
}
@media all and (max-width: 768px) {
  div.header-container div.wrapper div.leftContainer div.menuIconContainer {
    display: flex;
    margin-inline-end: 10px;
    background-color: rgba(150, 153, 163, 0.16);
    padding: 8px 12px;
    border-radius: 6px;
  }
  div.header-container div.wrapper div.leftContainer div.menuIconContainer svg {
    width: 25px !important;
    height: 25px !important;
    color: #fff;
  }
}
div.header-container div.wrapper div.leftContainer div.logoContainer h1 {
  width: 160px;
}
@media all and (max-width: 480px) {
  div.header-container div.wrapper div.leftContainer div.logoContainer h1 {
    width: 100px;
  }
}
div.header-container div.wrapper div.leftContainer div.logoContainer h1 img {
  display: block;
  width: 100%;
  -o-object-fit: contain;
     object-fit: contain;
  aspect-ratio: 2;
}
@media all and (max-width: 1200px) {
  div.header-container div.wrapper div.leftContainer ul.hide.hide {
    display: none;
  }
}
div.header-container div.wrapper div.leftContainer ul.browse {
  display: flex;
  align-items: center;
}
div.header-container div.wrapper div.leftContainer ul.browse li {
  cursor: pointer;
  padding: 0 30px;
}
div.header-container div.wrapper div.leftContainer ul.browse li:nth-child(2) {
  padding: 0;
}
div.header-container div.wrapper div.leftContainer ul.browse li.active {
  padding-bottom: 60px;
  margin-top: 60px;
}
div.header-container div.wrapper div.leftContainer ul.browse li.active span {
  color: var(--text-hover);
}
div.header-container div.wrapper div.leftContainer ul.browse li span {
  font-size: 14px;
  font-weight: 900;
  transition: 0.3s ease-in-out;
  color: #fff;
}
div.header-container div.wrapper div.leftContainer ul.browse li.mainMenu {
  cursor: pointer;
  padding: 0 15px;
}
div.header-container div.wrapper div.leftContainer ul.browse li.mainMenu:nth-child(2) {
  padding: 0 15px 0 0px;
}
div.header-container div.wrapper div.leftContainer ul.browse li.mainMenu:nth-last-child(2) {
  padding: 0 0px 0 15px;
}
div.header-container div.wrapper div.leftContainer ul.browse li.mainMenu.active {
  padding-bottom: 60px;
  margin-top: 60px;
}
div.header-container div.wrapper div.leftContainer ul.browse li.mainMenu.active span {
  color: var(--text-hover);
}
div.header-container div.wrapper div.leftContainer ul.browse li.mainMenu span {
  font-size: 14px;
  font-weight: 900;
  transition: 0.3s ease-in-out;
  color: #fff;
}
@media all and (max-width: 768px) {
  div.header-container div.wrapper div.leftContainer ul.browse {
    display: none;
  }
}
div.header-container div.wrapper div.rightContainer {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 56%;
  transition: 0.2s ease-in-out;
}
@media all and (max-width: 1400px) {
  div.header-container div.wrapper div.rightContainer {
    width: 66%;
  }
}
div.header-container div.wrapper div.rightContainer.increase {
  width: 90%;
}
@media all and (max-width: 1200px) {
  div.header-container div.wrapper div.rightContainer.increase {
    width: 90%;
  }
}
@media all and (max-width: 980px) {
  div.header-container div.wrapper div.rightContainer {
    width: 78%;
  }
}
div.header-container div.wrapper div.rightContainer div.inputContainer {
  border: 1px solid #9e9fa4;
  padding: 12px 15px;
  display: flex;
  align-items: center;
  border-radius: 8px;
  width: 50%;
  justify-content: space-between;
  transition: 0.2s ease-in-out;
  margin-inline-end: 30px;
  cursor: pointer;
}
div.header-container div.wrapper div.rightContainer div.inputContainer.showInput.showInput {
  width: 40%;
}
@media all and (max-width: 1400px) {
  div.header-container div.wrapper div.rightContainer div.inputContainer {
    width: 44%;
  }
}
@media all and (max-width: 1200px) {
  div.header-container div.wrapper div.rightContainer div.inputContainer {
    width: 8%;
  }
  div.header-container div.wrapper div.rightContainer div.inputContainer.showInput {
    width: 70%;
  }
  div.header-container div.wrapper div.rightContainer div.inputContainer.showInput input {
    display: block;
  }
}
@media all and (max-width: 980px) {
  div.header-container div.wrapper div.rightContainer div.inputContainer {
    width: 12%;
    justify-content: center;
    padding: 10px 8px;
    margin-inline-end: 18px;
  }
  div.header-container div.wrapper div.rightContainer div.inputContainer.showInput {
    width: 57%;
    justify-content: space-between;
  }
}
@media all and (max-width: 768px) {
  div.header-container div.wrapper div.rightContainer div.inputContainer {
    display: none;
  }
}
div.header-container div.wrapper div.rightContainer div.inputContainer:hover {
  border-color: #c5c5c8;
}
div.header-container div.wrapper div.rightContainer div.inputContainer:hover input::-moz-placeholder {
  color: #fff;
}
div.header-container div.wrapper div.rightContainer div.inputContainer:hover input::placeholder {
  color: #fff;
}
div.header-container div.wrapper div.rightContainer div.inputContainer svg {
  width: 25px !important;
  height: 25px !important;
  color: #fff !important;
  margin-inline-end: 20px;
}
@media all and (max-width: 1200px) {
  div.header-container div.wrapper div.rightContainer div.inputContainer svg {
    margin-inline-end: 0;
  }
}
div.header-container div.wrapper div.rightContainer div.inputContainer input {
  border: none;
  outline: none;
  width: 90%;
  background-color: transparent;
  font-size: 16px;
  color: #fff;
}
@media all and (max-width: 1200px) {
  div.header-container div.wrapper div.rightContainer div.inputContainer input {
    display: none;
  }
}
div.header-container div.wrapper div.rightContainer div.inputContainer input::-moz-placeholder {
  color: #9e9fa4;
  font-size: 16px;
  -moz-transition: 0.3s ease-in-out;
  transition: 0.3s ease-in-out;
}
div.header-container div.wrapper div.rightContainer div.inputContainer input::placeholder {
  color: #9e9fa4;
  font-size: 16px;
  transition: 0.3s ease-in-out;
}
div.header-container div.wrapper div.rightContainer div.languageDropdown {
  position: relative;
  cursor: pointer;
  display: inline-block;
  font-size: 14px;
  color: white;
  padding: 15px 0;
}
@media (max-width: 768px) {
  div.header-container div.wrapper div.rightContainer div.languageDropdown {
    display: none;
  }
}
div.header-container div.wrapper div.rightContainer div.languageDropdown:hover {
  color: var(--text-hover);
}
div.header-container div.wrapper div.rightContainer div.languageDropdown div.language {
  font-size: 14px;
  font-weight: bold;
  transition: color 0.3s ease, font-weight 0.3s ease;
}
div.header-container div.wrapper div.rightContainer div.languageDropdown div.languageOptions {
  position: absolute;
  top: 100%;
  inset-inline-start: 0;
  min-width: 210px;
  background-color: var(--bg-color);
  padding-top: 15px;
  padding-bottom: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  z-index: 9999;
}
div.header-container div.wrapper div.rightContainer div.languageDropdown div.languageOptions::before {
  content: "";
  position: absolute;
  top: 0;
  inset-inline-start: 0;
  width: 100%;
  height: 4px;
  background: linear-gradient(281deg, var(--gradient-right-color), var(--gradient-left-color));
}
div.header-container div.wrapper div.rightContainer div.languageDropdown div.languageOptions div.optionsList {
  display: flex;
  flex-direction: column;
}
div.header-container div.wrapper div.rightContainer div.languageDropdown div.languageOptions div.optionsList div.languageOption {
  font-size: 14px;
  padding: 8px 12px;
  color: var(--text-color);
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
  white-space: nowrap;
}
div.header-container div.wrapper div.rightContainer div.languageDropdown div.languageOptions div.optionsList div.languageOption[data-selected=true] {
  color: var(--gradient-left-color);
}
div.header-container div.wrapper div.rightContainer div.languageDropdown div.languageOptions div.optionsList div.languageOption[data-selected=true]:after {
  content: "✔";
  margin-inline-start: auto;
}
div.header-container div.wrapper div.rightContainer div.languageDropdown div.languageOptions div.optionsList div.languageOption:hover {
  color: var(--gradient-left-color);
}
div.header-container div.wrapper div.rightContainer div.language {
  color: var(--white-color);
  cursor: pointer;
}
@media all and (max-width: 768px) {
  div.header-container div.wrapper div.rightContainer div.language {
    display: none;
  }
}
div.header-container div.wrapper div.rightContainer div.language span {
  display: inline-block;
  margin-inline-start: 6px;
  font-size: 16px;
  font-weight: 500;
}
div.header-container div.wrapper div.rightContainer div.userInfo {
  display: flex;
  align-items: center;
  cursor: pointer;
  padding: 0 40px;
}
@media all and (max-width: 360px) {
  div.header-container div.wrapper div.rightContainer div.userInfo {
    padding: 0 21px;
  }
}
div.header-container div.wrapper div.rightContainer div.userInfo.active svg {
  transform: rotate(-180deg);
}
div.header-container div.wrapper div.rightContainer div.userInfo h4 {
  color: var(--text-color);
  font-size: 14px;
  margin-inline-end: 10px;
}
div.header-container div.wrapper div.rightContainer div.userInfo h4.active {
  padding-top: 60px;
  padding-bottom: 60px;
}
div.header-container div.wrapper div.rightContainer div.userInfo svg {
  color: var(--text-color) !important;
  width: 22px !important;
  height: 22px !important;
  transition: 0.3s ease-in-out;
}
div.header-container div.wrapper div.rightContainer div.buttonContainer {
  display: flex;
  margin-inline-start: 30px;
}
@media all and (max-width: 980px) {
  div.header-container div.wrapper div.rightContainer div.buttonContainer {
    margin-inline-start: 18px;
  }
}
@media all and (max-width: 480px) {
  div.header-container div.wrapper div.rightContainer div.buttonContainer {
    margin-inline-start: 15px;
  }
}
@media all and (max-width: 360px) {
  div.header-container div.wrapper div.rightContainer div.buttonContainer {
    margin-inline-start: 7px;
  }
}
div.header-container div.wrapper div.rightContainer div.buttonContainer button.register {
  background-color: #fff;
  padding: 13px 0;
  width: 6rem;
  border-radius: 8px;
  font-size: 14px;
  font-weight: 700;
  margin-inline-end: 30px;
  cursor: pointer;
}
@media all and (max-width: 768px) {
  div.header-container div.wrapper div.rightContainer div.buttonContainer button.register {
    padding: 11px 0;
    margin-inline-end: 18px;
  }
}
@media all and (max-width: 360px) {
  div.header-container div.wrapper div.rightContainer div.buttonContainer button.register {
    padding: 8px 0;
    width: 5rem;
    font-size: 12px;
    margin-inline-end: 10px;
  }
}
div.header-container div.wrapper div.rightContainer div.buttonContainer button.signIn {
  background-color: transparent;
  font-weight: 700;
  font-size: 14px;
  color: #fff;
  cursor: pointer;
  white-space: nowrap;
}
@media all and (max-width: 360px) {
  div.header-container div.wrapper div.rightContainer div.buttonContainer button.signIn {
    font-size: 12px;
  }
}

div.userInfoContainer {
  position: fixed;
  inset-inline-end: 30px;
  width: 20rem;
  top: 100px;
  background-color: rgb(37, 42, 52);
  border-radius: 8px;
  display: none;
  z-index: 9999;
}
div.userInfoContainer.active {
  display: block;
}
@media all and (max-width: 640px) {
  div.userInfoContainer {
    width: 84%;
  }
}
div.userInfoContainer div.top {
  padding: 20px 20px 0;
}
div.userInfoContainer div.top ul a li {
  padding: 15px 0;
  cursor: pointer;
}
div.userInfoContainer div.top ul a li:first-child {
  padding-top: 0;
}
div.userInfoContainer div.top ul a li:last-child {
  border-bottom: none;
}
div.userInfoContainer div.top ul a li span {
  color: var(--text-color);
  font-weight: 600;
  font-size: 15px;
}
div.userInfoContainer div.top ul a:last-child {
  border: none;
}
div.userInfoContainer div.bottom {
  background: linear-gradient(180deg, #10141f, rgba(16, 20, 31, 0));
  padding: 15px;
}
div.userInfoContainer div.bottom div.signOutContainer {
  display: flex;
  align-items: center;
  padding: 12px;
  background-color: var(--border-color);
  border-radius: 8px;
  cursor: pointer;
}
div.userInfoContainer div.bottom div.signOutContainer svg {
  color: var(--text-color);
  width: 22px !important;
  height: 22px !important;
  margin-inline-end: 10px;
}
div.userInfoContainer div.bottom div.signOutContainer span {
  color: var(--text-color);
  font-weight: 600;
  font-size: 15px;
}

@media all and (max-width: 1200px) {
  div.overlayOne.active {
    position: fixed;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    background-color: var(--overlay);
    z-index: 999;
    display: flex;
    justify-content: center;
    align-items: center;
    animation: fadeIn 0.3s ease-in-out;
  }
}

div.overlayOne.active {
  display: flex;
}

div.browseMore {
  display: none;
}
@media all and (max-width: 1200px) {
  div.browseMore {
    display: none;
  }
}
div.browseMore div.overlay {
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  background-color: var(--overlay);
  z-index: 9;
  display: flex;
  justify-content: center;
  align-items: center;
  animation: fadeIn 0.3s ease-in-out;
}
div.browseMore div.browseMoreContainer {
  animation: fadeIn 0.7s ease-in-out;
  position: fixed;
  top: 100px;
  inset-inline-start: 10%;
  background-color: #fff;
  display: flex;
  border-radius: 6px;
  z-index: 999;
}
@media all and (max-width: 2700px) {
  div.browseMore div.browseMoreContainer {
    inset-inline-start: 7%;
  }
}
@media all and (max-width: 2500px) {
  div.browseMore div.browseMoreContainer {
    inset-inline-start: 9.5%;
  }
}
@media all and (max-width: 1980px) {
  div.browseMore div.browseMoreContainer {
    inset-inline-start: 9.5%;
  }
}
@media all and (max-width: 1768px) {
  div.browseMore div.browseMoreContainer {
    inset-inline-start: 9.5%;
  }
}
div.browseMore div.browseMoreContainer::after {
  content: "";
  top: 81px;
  /* width: 7px; */
  /* height: 4px; */
  border-color: transparent transparent #fff;
  border-style: solid;
  border-width: 0px 9px 15px;
  position: fixed;
  /* right: 2%; */
  /* transform: translateX(-580%); */
  inset-inline-start: 16.5%;
}
@media all and (max-width: 2700px) {
  div.browseMore div.browseMoreContainer::after {
    inset-inline-start: 10.5%;
    top: 7%;
  }
}
@media all and (max-width: 2500px) {
  div.browseMore div.browseMoreContainer::after {
    inset-inline-start: 11.5%;
    top: 8%;
  }
}
@media all and (max-width: 1980px) {
  div.browseMore div.browseMoreContainer::after {
    top: 10%;
    inset-inline-start: 13.5%;
  }
}
@media all and (max-width: 1768px) {
  div.browseMore div.browseMoreContainer::after {
    top: 12%;
  }
}
@media all and (max-width: 1400px) {
  div.browseMore div.browseMoreContainer::after {
    inset-inline-start: 17.5%;
  }
}
div.browseMore div.browseMoreContainer h1.categoryName {
  font-size: 14px;
  margin-bottom: 20px;
  color: var(--grey-color);
}
div.browseMore div.browseMoreContainer ul {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
}
div.browseMore div.browseMoreContainer ul li {
  margin-bottom: 8px;
  cursor: pointer;
}
div.browseMore div.browseMoreContainer ul li:hover span {
  color: var(--text-hover);
}
div.browseMore div.browseMoreContainer ul li span {
  font-size: 14px;
  color: #10141f;
  transition: 0.3s ease-in-out;
}
div.browseMore div.browseMoreContainer div.left {
  padding: 30px 25px;
  overflow-y: scroll;
  height: 500px;
}
div.browseMore div.browseMoreContainer div.left::-webkit-scrollbar {
  width: 8px;
}
div.browseMore div.browseMoreContainer div.left::-webkit-scrollbar-thumb {
  background-color: darkgrey;
  border-bottom-right-radius: 3px;
  border-top-right-radius: 3px;
  border-top-left-radius: 3px;
  border-bottom-left-radius: 3px;
}
div.browseMore div.browseMoreContainer div.left::-webkit-scrollbar-thumb:hover {
  background-color: rgb(125, 128, 128);
}
div.browseMore div.browseMoreContainer div.center {
  width: 40%;
  background-color: #f0f3f5;
  padding: 30px 25px;
}
div.browseMore div.browseMoreContainer div.center div.sections {
  display: flex;
  justify-content: space-between;
}
div.browseMore div.browseMoreContainer div.center div.sections div.leftSection {
  width: 50%;
}
div.browseMore div.browseMoreContainer div.center div.sections div.rightSection {
  width: 50%;
}
div.browseMore div.browseMoreContainer div.right {
  width: 40%;
  padding: 30px 25px;
}
div.browseMore .browseMoreContainerNew {
  position: fixed;
  top: 100px;
  inset-inline-start: 10%;
  background-color: var(--bg-color);
  border-radius: 6px;
  z-index: 999;
  height: 500px;
  overflow-y: auto;
  scrollbar-width: none;
  -ms-overflow-style: none;
  animation: fadeIn 0.7s ease-in-out;
}
div.browseMore .browseMoreContainerNew::-webkit-scrollbar {
  width: 0;
  background: transparent;
}
div.browseMore .browseMoreContainerNew h1.categoryName {
  font-size: 14px;
  color: var(--grey-color);
  margin: 3% 2%;
}
div.browseMore .browseMoreContainerNew li {
  padding-inline-start: 0;
}
div.browseMore .browseMoreContainerNew li li {
  position: relative;
  margin-bottom: 8px;
  height: 40px;
  cursor: pointer;
  overflow: hidden;
  transition: transform 0.3s ease-in-out;
}
div.browseMore .browseMoreContainerNew li li:hover {
  transform: scale(1.05);
}
div.browseMore .browseMoreContainerNew li li:hover span {
  color: var(--bg-color);
  top: 9px;
  inset-inline-start: 10px;
}
div.browseMore .browseMoreContainerNew li li:hover .shadeGradient {
  opacity: 0.8;
}
div.browseMore .browseMoreContainerNew li li:hover::after {
  opacity: 1;
}
div.browseMore .browseMoreContainerNew li li span {
  font-size: 14px;
  color: var(--text-color);
  z-index: 2;
  position: relative;
  transition: color 0.3s ease-in-out;
  margin-top: 3%;
  margin-inline-start: 2%;
}
div.browseMore .browseMoreContainerNew li li .shadeGradient {
  position: absolute;
  top: 0;
  inset-inline-end: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  pointer-events: none;
  transition: opacity 0.3s ease-in-out;
  z-index: 1;
}
div.browseMore .browseMoreContainerNew li li::after {
  content: "";
  position: absolute;
  top: 0;
  inset-inline-start: 0;
  width: 2px;
  height: 100%;
  background-color: var(--buttonSelection);
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
  z-index: 3;
}
div.browseMore .browseMoreContainerNew div.categoryContainer {
  padding: 15px 25px 0;
}

div.browseMore.active {
  display: block;
}
@media all and (max-width: 1200px) {
  div.browseMore.active {
    display: none;
  }
}

div.browseMoreResponsive div.container {
  position: fixed;
  inset-inline-start: -50%;
  width: 35%;
  background-color: var(--secondary-bg-color);
  padding: 35px 30px;
  z-index: 9999;
  top: 0;
  bottom: 0;
  overflow: scroll;
  transition: 0.5s ease-in-out;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}
div.browseMoreResponsive div.container::-webkit-scrollbar {
  display: none;
}
@media all and (max-width: 1200px) {
  div.browseMoreResponsive div.container.active {
    inset-inline-start: 0;
  }
}
@media all and (max-width: 980px) {
  div.browseMoreResponsive div.container {
    width: 45%;
  }
}
@media all and (max-width: 768px) {
  div.browseMoreResponsive div.container {
    width: 100%;
    inset-inline-start: -100%;
  }
}
div.browseMoreResponsive div.container div.head {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
div.browseMoreResponsive div.container div.head.small {
  display: none;
}
@media all and (max-width: 768px) {
  div.browseMoreResponsive div.container div.head.large {
    display: none;
  }
  div.browseMoreResponsive div.container div.head.small {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  div.browseMoreResponsive div.container div.head.small div.left {
    display: flex;
    align-items: center;
    width: 20%;
  }
  div.browseMoreResponsive div.container div.head.small div.left svg {
    width: 22px !important;
    height: 22px !important;
    color: #fff;
    margin-inline-end: 10px;
  }
  div.browseMoreResponsive div.container div.head.small div.left span {
    color: #fff;
    font-size: 14px;
    font-weight: 700;
  }
  div.browseMoreResponsive div.container div.head.small div.right {
    width: 50%;
    display: flex;
    justify-content: end;
  }
  div.browseMoreResponsive div.container div.head.small div.right.increase {
    width: 100%;
  }
  div.browseMoreResponsive div.container div.head.small div.right div.inputContainer {
    border: 1px solid #9e9fa4;
    padding: 8px 10px;
    display: flex;
    align-items: center;
    border-radius: 8px;
    width: 3.5rem;
    justify-content: center;
    transition: 0.2s ease-in-out;
    cursor: pointer;
  }
  div.browseMoreResponsive div.container div.head.small div.right div.inputContainer input {
    display: none;
  }
  div.browseMoreResponsive div.container div.head.small div.right div.inputContainer.showInput {
    width: 100%;
    justify-content: space-between;
  }
  div.browseMoreResponsive div.container div.head.small div.right div.inputContainer.showInput input {
    display: block;
    outline: none;
    border: none;
    background-color: transparent;
    width: 93%;
    color: #fff;
    font-size: 16px;
  }
}
div.browseMoreResponsive div.container div.head div.left h1 {
  color: var(--text-hover);
  font-size: 15px;
  font-weight: 700;
}
div.browseMoreResponsive div.container div.head div.right svg {
  color: #fff;
  width: 23px !important;
  height: 23px !important;
}
div.browseMoreResponsive div.container div.language {
  color: var(--white-color);
  cursor: pointer;
  margin-top: 20px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
div.browseMoreResponsive div.container div.language span {
  display: inline-block;
  margin-inline-start: 6px;
  font-size: 15px;
  font-weight: 500;
}
div.browseMoreResponsive div.container div.top {
  margin-top: 20px;
  padding-bottom: 30px;
  border-bottom: 1px solid #3d3e43;
}
div.browseMoreResponsive div.container div.top h1.categoryName {
  font-size: 16px;
  margin-bottom: 20px;
  color: var(--grey-color);
  font-weight: 700;
}
div.browseMoreResponsive div.container div.top ul li {
  margin-bottom: 8px;
}
div.browseMoreResponsive div.container div.top ul li span {
  font-size: 14px;
  color: #fff;
  cursor: pointer;
}
div.browseMoreResponsive div.container div.top ul li span:hover {
  color: var(--button-color);
}
div.browseMoreResponsive div.container div.center {
  padding: 30px 0;
  border-bottom: 1px solid #3d3e43;
}
div.browseMoreResponsive div.container div.center h1.categoryName {
  font-size: 16px;
  margin-bottom: 20px;
  color: var(--grey-color);
  font-weight: 700;
}
div.browseMoreResponsive div.container div.center ul li {
  margin-bottom: 8px;
}
div.browseMoreResponsive div.container div.center ul li span {
  font-size: 14px;
  color: #fff;
}
div.browseMoreResponsive div.container div.bottom {
  padding: 30px 0;
}
div.browseMoreResponsive div.container div.bottom h1.categoryName {
  font-size: 16px;
  margin-bottom: 20px;
  color: var(--grey-color);
  font-weight: 700;
}
div.browseMoreResponsive div.container div.bottom ul li {
  margin-bottom: 8px;
}
div.browseMoreResponsive div.container div.bottom ul li span {
  font-size: 14px;
  color: #fff;
}
div.browseMoreResponsive div.container div.languageDropdown {
  position: relative;
  cursor: pointer;
  display: inline-block;
  font-size: 16px;
  color: white;
  padding: 15px 0;
}
div.browseMoreResponsive div.container div.languageDropdown:hover {
  color: var(--text-hover);
}
div.browseMoreResponsive div.container div.languageDropdown h1.language {
  font-size: 16px;
  font-weight: bold;
  transition: color 0.3s ease, font-weight 0.3s ease;
}
div.browseMoreResponsive div.container div.languageDropdown div.languageOptions {
  position: absolute;
  top: 100%;
  inset-inline-start: 0;
  min-width: 210px;
  background-color: var(--bg-color);
  padding-top: 15px;
  padding-bottom: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  z-index: 100;
}
div.browseMoreResponsive div.container div.languageDropdown div.languageOptions::before {
  content: "";
  position: absolute;
  top: 0;
  inset-inline-start: 0;
  width: 100%;
  height: 4px;
  background: linear-gradient(281deg, var(--gradient-right-color), var(--gradient-left-color));
}
div.browseMoreResponsive div.container div.languageDropdown div.languageOptions div.optionsList {
  display: flex;
  flex-direction: column;
}
div.browseMoreResponsive div.container div.languageDropdown div.languageOptions div.optionsList div.languageOption {
  font-size: 16px;
  padding: 8px 12px;
  color: var(--text-color);
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
  white-space: nowrap;
}
div.browseMoreResponsive div.container div.languageDropdown div.languageOptions div.optionsList div.languageOption[data-selected=true] {
  color: var(--gradient-left-color);
}
div.browseMoreResponsive div.container div.languageDropdown div.languageOptions div.optionsList div.languageOption[data-selected=true]:after {
  content: "✔";
  margin-inline-start: auto;
}
div.browseMoreResponsive div.container div.languageDropdown div.languageOptions div.optionsList div.languageOption:hover {
  color: var(--gradient-left-color);
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes fadeOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
div.featured {
  height: 599px;
  overflow: hidden;
  width: 100%;
  position: relative;
  border-bottom: 1px solid var(--border-color);
}
@media all and (max-width: 768px) {
  div.featured {
    height: 556px;
  }
}
div.featured .swiper {
  height: 100%;
}
div.featured div.bgImageContainer {
  width: 100%;
  display: block;
  height: 100%;
}
div.featured div.bgImageContainer img {
  display: block;
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
}
@media all and (max-width: 768px) {
  div.featured div.bgImageContainer img {
    -o-object-fit: cover;
       object-fit: cover;
  }
}
div.featured div.bottomGradient {
  position: absolute;
  bottom: 0;
  width: 100%;
  z-index: 1;
  height: 100%;
  background-position: top;
  background-size: cover;
  background-repeat: no-repeat;
  background: linear-gradient(78.83deg, rgb(16, 20, 31) 0%, rgba(16, 20, 31, 0) 61.46%), linear-gradient(360deg, rgb(16, 20, 31) 0%, rgba(16, 20, 31, 0) 48.44%, rgba(16, 20, 31, 0.4) 100%);
}
div.featured div.gallerySection {
  position: absolute;
  z-index: 9;
  inset-inline-end: 0;
  bottom: 42px;
  width: 22rem;
}
@media all and (max-width: 1400px) {
  div.featured div.gallerySection {
    width: 18.5rem;
  }
}
@media all and (max-width: 1200px) {
  div.featured div.gallerySection {
    width: 18rem;
  }
}
@media all and (max-width: 980px) {
  div.featured div.gallerySection {
    display: none;
  }
}
div.featured div.gallerySection .swiper {
  overflow: hidden;
}
div.featured div.gallerySection .swiper-slide {
  opacity: 0.4;
}
div.featured div.gallerySection .swiper-slide-active {
  opacity: 1;
}
div.featured div.gallerySection div.imageContainer {
  width: 100%;
}
div.featured div.gallerySection div.imageContainer img {
  width: 100%;
  display: block;
  border-radius: 6px;
}
div.featured div.contents {
  position: absolute;
  bottom: 30px;
  width: 92%;
  margin: 0 auto;
  left: 50%;
  transform: translateX(-50%);
  z-index: 2;
}
div.featured div.contents span.line {
  height: 1px;
  background-color: var(--border-color);
  width: 30%;
  display: block;
  margin-bottom: 20px;
}
@media all and (max-width: 768px) {
  div.featured div.contents span.line {
    display: none;
  }
}
div.featured div.contents h1.title {
  font-size: 55px;
  color: var(--text-color);
  margin-bottom: 30px;
  animation: slideUp 0.5s ease-in-out;
}
@media all and (max-width: 768px) {
  div.featured div.contents h1.title {
    font-size: 36px;
    margin-bottom: 0;
  }
}
div.featured div.contents div.containers {
  display: flex;
  align-items: center;
}
div.featured div.contents div.containers div.left {
  width: 8%;
  margin-inline-end: 30px;
}
@media all and (max-width: 1200px) {
  div.featured div.contents div.containers div.left {
    width: 12%;
  }
}
@media all and (max-width: 980px) {
  div.featured div.contents div.containers div.left {
    width: 14%;
  }
}
@media all and (max-width: 768px) {
  div.featured div.contents div.containers div.left {
    display: none;
  }
}
div.featured div.contents div.containers div.left div.activeThumb {
  width: 100%;
}
div.featured div.contents div.containers div.left div.activeThumb div.imageContainer {
  width: 100%;
}
div.featured div.contents div.containers div.left div.activeThumb div.imageContainer img {
  width: 100%;
  display: block;
  border-radius: 8px;
}
div.featured div.contents div.containers div.right {
  width: 78%;
}
div.featured div.contents div.containers div.right div.metaData {
  padding: 35px 0;
  margin: 0 auto;
}
@media all and (max-width: 768px) {
  div.featured div.contents div.containers div.right div.metaData {
    padding: 20px 0;
  }
}
div.featured div.contents div.containers div.right div.metaData div.datas {
  animation: slideUp 0.5s ease-in-out;
}
div.featured div.contents div.containers div.right div.metaData div.datas span {
  color: var(--grey-color);
  font-size: 18px;
}
@media all and (max-width: 768px) {
  div.featured div.contents div.containers div.right div.metaData div.datas span {
    font-size: 15px;
  }
}
div.featured div.contents div.containers div.right div.metaData div.datas span.year {
  margin-inline-end: 6px;
}
div.featured div.contents div.containers div.right div.metaData div.datas span.duration {
  margin-inline-end: 6px;
}
div.featured div.contents div.containers div.right div.metaData div.datas span.duration.showDot::before {
  content: "·";
  margin-inline-end: 6px;
}
div.featured div.contents div.containers div.right div.metaData div.datas span.duration.ltr {
  direction: ltr !important;
  unicode-bidi: embed !important;
}
div.featured div.contents div.containers div.right div.metaData div.datas span.certificate {
  display: inline-block;
  padding: 1px 6px;
  background-color: rgba(150, 153, 163, 0.32);
  font-size: 12px;
  font-weight: 700;
  color: var(--text-color);
  border-radius: 4px;
}
div.featured div.contents div.containers div.right div.metaData div.datas div.genres {
  margin-top: 6px;
}
div.featured div.contents div.containers div.right div.metaData div.datas div.genres span {
  margin-inline-end: 6px;
}
div.featured div.contents div.containers div.right div.metaData div.datas div.genres span::before {
  content: "·";
  margin-inline-end: 6px;
}
div.featured div.contents div.containers div.right div.metaData div.datas div.genres span:first-child::before {
  display: none;
}
div.featured div.contents div.containers div.right div.metaData div.datas div.genres span:last-child {
  margin-inline-end: 0;
}
div.featured div.contents div.containers div.right div.metaData div.buttonContainer {
  margin-top: 30px;
  background: var(--button-color);
  padding: 12px 24px;
  width: 13rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 8px;
  color: var(--text-color);
  font-weight: 700;
  cursor: pointer;
  transition: 0.3s ease-in-out;
}
div.featured div.contents div.containers div.right div.metaData div.buttonContainer.no-flex {
  justify-content: start;
}
div.featured div.contents div.containers div.right div.metaData div.buttonContainer svg {
  margin-inline-end: 10px;
}
div.featured div.contents div.containers div.right div.metaData div.buttonContainer:hover {
  background: var(--button-hover);
}
div.featured div.contents div.containers div.right div.metaData div.buttonContainer span {
  color: var(--text-color);
  font-weight: 700;
  font-size: 15px;
}
div.featured div.contents div.containers div.right div.metaData div.buttonContainer span.free {
  display: inline-block;
  padding: 1px 6px;
  color: #000;
  background-color: var(--text-color);
  border-radius: 30px;
  font-size: 10px;
}
@keyframes slideUp {
  0% {
    transform: translateY(0);
    opacity: 0;
  }
  50% {
    transform: translateY(15px);
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
}

div.homePage {
  overflow-x: hidden;
}
div.homePage .swiper {
  overflow: visible;
}
div.homePage .swiper-button-disabled {
  display: none !important;
}
div.homePage .swiper-button-next {
  right: -82px;
  height: 100%;
  width: 6%;
  top: 5%;
  background: linear-gradient(-97deg, rgb(28, 31, 41) 0.98%, rgba(28, 31, 41, 0.4) 100.23%, rgba(28, 31, 41, 0.2) 100.16%, rgba(28, 31, 41, 0.0001) 52.12%);
}
@media all and (min-width: 320px) {
  div.homePage .swiper-button-next {
    display: flex;
    right: -26px;
    width: 9%;
    top: 11%;
  }
}
@media all and (min-width: 360px) {
  div.homePage .swiper-button-next {
    top: 9%;
    right: -42px;
  }
}
@media all and (min-width: 480px) {
  div.homePage .swiper-button-next {
    top: 7%;
    width: 5%;
    right: -51px;
  }
}
@media all and (min-width: 640px) {
  div.homePage .swiper-button-next {
    top: 5%;
    right: -60px;
  }
}
@media all and (min-width: 768px) {
  div.homePage .swiper-button-next {
    width: 4%;
    right: -80px;
  }
}
@media all and (min-width: 980px) {
  div.homePage .swiper-button-next {
    width: 4%;
    right: -47px;
  }
}
@media all and (min-width: 1200px) {
  div.homePage .swiper-button-next {
    right: -57px;
  }
}
@media all and (min-width: 1400px) {
  div.homePage .swiper-button-next {
    right: -67px;
  }
}
@media all and (min-width: 1600px) {
  div.homePage .swiper-button-next {
    right: -70px;
  }
}
@media all and (min-width: 1768px) {
  div.homePage .swiper-button-next {
    right: -79px;
  }
}
div.homePage .swiper-button-prev {
  left: -77px;
  height: 100%;
  width: 5%;
  top: 5%;
  background: linear-gradient(-97deg, rgb(28, 31, 41) 0.98%, rgba(28, 31, 41, 0.4) 100.23%, rgba(28, 31, 41, 0.2) 100.16%, rgba(28, 31, 41, 0.0001) 52.12%);
}
@media all and (min-width: 320px) {
  div.homePage .swiper-button-prev {
    display: flex;
    left: -26px;
    width: 9%;
    top: 11%;
  }
}
@media all and (min-width: 360px) {
  div.homePage .swiper-button-prev {
    top: 9%;
    left: -42px;
  }
}
@media all and (min-width: 480px) {
  div.homePage .swiper-button-prev {
    top: 7%;
    width: 5%;
    left: -51px;
  }
}
@media all and (min-width: 640px) {
  div.homePage .swiper-button-prev {
    top: 5%;
    left: -60px;
  }
}
@media all and (min-width: 768px) {
  div.homePage .swiper-button-prev {
    width: 4%;
    left: -80px;
  }
}
@media all and (min-width: 980px) {
  div.homePage .swiper-button-prev {
    width: 4%;
    left: -47px;
  }
}
@media all and (min-width: 1200px) {
  div.homePage .swiper-button-prev {
    left: -57px;
  }
}
@media all and (min-width: 1400px) {
  div.homePage .swiper-button-prev {
    left: -67px;
  }
}
@media all and (min-width: 1600px) {
  div.homePage .swiper-button-prev {
    left: -70px;
  }
}
@media all and (min-width: 1768px) {
  div.homePage .swiper-button-prev {
    left: -79px;
  }
}
div.homePage .section-slider .swiper-button-next {
  right: -50px;
  width: 10%;
  background-color: white;
}
div.homePage .section-slider .swiper-button-prev {
  left: -50px;
  background-color: white;
}

div.categoriesContainer {
  padding: 15px 0;
  border-bottom: 1px solid var(--border-color);
  overflow-x: hidden;
}
div.categoriesContainer:hover .swiper-button-next::after {
  opacity: 1;
}
div.categoriesContainer:hover .swiper-button-prev::after {
  opacity: 1;
}
div.categoriesContainer .swiper {
  overflow: visible;
}
div.categoriesContainer .swiper.landscape .swiper-button-next {
  right: -82px;
  height: 100%;
  width: 6%;
  top: 5%;
  background: linear-gradient(-97deg, rgb(28, 31, 41) 0.98%, rgba(28, 31, 41, 0.4) 100.23%, rgba(28, 31, 41, 0.2) 100.16%, rgba(28, 31, 41, 0.0001) 52.12%);
}
@media all and (min-width: 320px) {
  div.categoriesContainer .swiper.landscape .swiper-button-next {
    display: none;
  }
}
@media all and (min-width: 980px) {
  div.categoriesContainer .swiper.landscape .swiper-button-next {
    display: flex;
    width: 4%;
    right: -47px;
    top: 8%;
  }
}
@media all and (min-width: 1200px) {
  div.categoriesContainer .swiper.landscape .swiper-button-next {
    right: -57px;
    width: 4% !important;
    top: 7%;
  }
}
@media all and (min-width: 1400px) {
  div.categoriesContainer .swiper.landscape .swiper-button-next {
    top: 6%;
    right: -67px;
  }
}
@media all and (min-width: 1600px) {
  div.categoriesContainer .swiper.landscape .swiper-button-next {
    right: -70px;
  }
}
@media all and (min-width: 1768px) {
  div.categoriesContainer .swiper.landscape .swiper-button-next {
    right: -79px;
    top: 5%;
  }
}
@media all and (min-width: 1980px) {
  div.categoriesContainer .swiper.landscape .swiper-button-next {
    right: -91px;
  }
}
@media all and (min-width: 2200px) {
  div.categoriesContainer .swiper.landscape .swiper-button-next {
    right: -99px;
  }
}
div.categoriesContainer .swiper.landscape .swiper-button-prev {
  left: -77px;
  height: 100%;
  width: 5%;
  top: 5%;
  background: linear-gradient(-97deg, rgb(28, 31, 41) 0.98%, rgba(28, 31, 41, 0.4) 100.23%, rgba(28, 31, 41, 0.2) 100.16%, rgba(28, 31, 41, 0.0001) 52.12%);
}
@media all and (min-width: 320px) {
  div.categoriesContainer .swiper.landscape .swiper-button-prev {
    display: none;
  }
}
@media all and (min-width: 980px) {
  div.categoriesContainer .swiper.landscape .swiper-button-prev {
    display: flex;
    width: 4%;
    left: -47px;
    top: 8%;
  }
}
@media all and (min-width: 1200px) {
  div.categoriesContainer .swiper.landscape .swiper-button-prev {
    left: -57px;
    width: 4% !important;
    top: 7%;
  }
}
@media all and (min-width: 1400px) {
  div.categoriesContainer .swiper.landscape .swiper-button-prev {
    left: -67px;
    top: 6%;
  }
}
@media all and (min-width: 1600px) {
  div.categoriesContainer .swiper.landscape .swiper-button-prev {
    left: -70px;
  }
}
@media all and (min-width: 1768px) {
  div.categoriesContainer .swiper.landscape .swiper-button-prev {
    left: -79px;
    top: 5%;
  }
}
@media all and (min-width: 1980px) {
  div.categoriesContainer .swiper.landscape .swiper-button-prev {
    left: -91px;
  }
}
@media all and (min-width: 2200px) {
  div.categoriesContainer .swiper.landscape .swiper-button-prev {
    left: -99px;
  }
}
div.categoriesContainer .swiper.portrait .swiper-button-next {
  right: -82px;
  height: 100%;
  width: 6%;
  top: 5%;
  background: linear-gradient(-97deg, rgb(28, 31, 41) 0.98%, rgba(28, 31, 41, 0.4) 100.23%, rgba(28, 31, 41, 0.2) 100.16%, rgba(28, 31, 41, 0.0001) 52.12%);
}
@media all and (min-width: 320px) {
  div.categoriesContainer .swiper.portrait .swiper-button-next {
    display: none;
  }
}
@media all and (min-width: 980px) {
  div.categoriesContainer .swiper.portrait .swiper-button-next {
    display: flex;
    width: 4%;
    right: -74px;
    top: 3%;
  }
}
@media all and (min-width: 1200px) {
  div.categoriesContainer .swiper.portrait .swiper-button-next {
    right: -57px;
    width: 4% !important;
    top: 5%;
  }
}
@media all and (min-width: 1400px) {
  div.categoriesContainer .swiper.portrait .swiper-button-next {
    right: -67px;
  }
}
@media all and (min-width: 1600px) {
  div.categoriesContainer .swiper.portrait .swiper-button-next {
    right: -70px;
  }
}
@media all and (min-width: 1768px) {
  div.categoriesContainer .swiper.portrait .swiper-button-next {
    right: -79px;
    top: 4%;
  }
}
@media all and (min-width: 1980px) {
  div.categoriesContainer .swiper.portrait .swiper-button-next {
    right: -91px;
  }
}
@media all and (min-width: 2200px) {
  div.categoriesContainer .swiper.portrait .swiper-button-next {
    right: -99px;
  }
}
div.categoriesContainer .swiper.portrait .swiper-button-prev {
  left: -77px;
  height: 100%;
  width: 5%;
  top: 5%;
  background: linear-gradient(-97deg, rgb(28, 31, 41) 0.98%, rgba(28, 31, 41, 0.4) 100.23%, rgba(28, 31, 41, 0.2) 100.16%, rgba(28, 31, 41, 0.0001) 52.12%);
}
@media all and (min-width: 320px) {
  div.categoriesContainer .swiper.portrait .swiper-button-prev {
    display: none;
  }
}
@media all and (min-width: 980px) {
  div.categoriesContainer .swiper.portrait .swiper-button-prev {
    display: flex;
    width: 4%;
    left: -75px;
    top: 2%;
  }
}
@media all and (min-width: 1200px) {
  div.categoriesContainer .swiper.portrait .swiper-button-prev {
    left: -57px;
    width: 4% !important;
    top: 5%;
  }
}
@media all and (min-width: 1400px) {
  div.categoriesContainer .swiper.portrait .swiper-button-prev {
    left: -67px;
  }
}
@media all and (min-width: 1600px) {
  div.categoriesContainer .swiper.portrait .swiper-button-prev {
    left: -70px;
  }
}
@media all and (min-width: 1768px) {
  div.categoriesContainer .swiper.portrait .swiper-button-prev {
    left: -79px;
    top: 4%;
  }
}
@media all and (min-width: 1980px) {
  div.categoriesContainer .swiper.portrait .swiper-button-prev {
    left: -91px;
  }
}
@media all and (min-width: 2200px) {
  div.categoriesContainer .swiper.portrait .swiper-button-prev {
    left: -99px;
  }
}
div.categoriesContainer .categoryNameContainer {
  display: inline-flex;
  align-items: center;
  cursor: pointer;
  width: 100%;
}
div.categoriesContainer .categoryNameContainer:hover h1 {
  color: var(--text-hover);
}
div.categoriesContainer .categoryNameContainer:hover span {
  color: var(--text-color);
}
div.categoriesContainer .categoryNameContainer:hover svg {
  color: var(--text-hover);
}
div.categoriesContainer .categoryNameContainer h1 {
  font-size: 24px;
  margin-inline-end: 20px;
  color: var(--text-color);
  transition: color 0.3s ease-in-out;
  display: inline-block;
}
div.categoriesContainer .categoryNameContainer span {
  color: var(--text-hover);
}
div.categoriesContainer .categoryNameContainer svg {
  color: var(--text-color);
  height: 20px !important;
  width: 20px !important;
  transition: 0.3s ease-in-out;
  vertical-align: sub;
}
div.categoriesContainer div.itemsContainer {
  margin-top: 20px;
}
div.categoriesContainer div.itemsContainer div.show {
  width: 14rem;
}
@media all and (min-width: 320px) {
  div.categoriesContainer div.itemsContainer div.show {
    width: 100%;
  }
}

div.categoriesContainer.rtl {
  direction: rtl;
  text-align: right;
}
div.categoriesContainer.rtl .categoryNameContainer svg {
  transform: rotate(180deg);
}

div.categoriesContainer.ltr {
  direction: ltr;
  text-align: left;
}
div.categoriesContainer.ltr .categoryNameContainer {
  display: inline-block;
  direction: rtl;
  text-align: right;
}
div.categoriesContainer.ltr .categoryNameContainer svg {
  transform: rotate(180deg);
}
div.categoriesContainer.ltr .itemsContainer .show {
  direction: rtl;
  text-align: right;
}

div.showCardContainer {
  width: 100%;
  position: relative;
}
div.showCardContainer div.showsSubscriptions {
  margin-top: 10px;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
}
@media all and (max-width: 1200px) {
  div.showCardContainer div.showsSubscriptions {
    display: none;
  }
}
div.showCardContainer div.showsSubscriptions div.items {
  width: 5rem;
  margin-inline-end: 10px;
  background-color: var(--yellow-color);
  padding: 4px 0;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 10px;
  font-weight: 600;
  color: var(--text-color);
  margin-bottom: 10px;
  border-radius: 30px;
}
div.showCardContainer div.showsSubscriptions div.items.free {
  background-color: var(--success-color);
}
@media all and (max-width: 1200px) {
  div.showCardContainer div.showsSubscriptions div.items {
    font-size: 8px;
    width: 4rem;
  }
}
div.showCardContainer div.premiumStatus div.premium {
  position: absolute;
  top: 6px;
  inset-inline-start: 6px;
  z-index: 8;
  background-color: rgba(8, 25, 43, 0.65);
  width: 35px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 3px;
}
@media all and (max-width: 1200px) {
  div.showCardContainer div.premiumStatus div.premium {
    width: 26px;
  }
}
div.showCardContainer div.premiumStatus div.premium svg {
  width: 18px !important;
  height: 18px !important;
}
@media all and (max-width: 1200px) {
  div.showCardContainer div.premiumStatus div.premium svg {
    width: 12px !important;
    heigth: 12px !important;
  }
}
div.showCardContainer div.premiumStatus div.free {
  position: absolute;
  top: 6px;
  inset-inline-start: 6px;
  z-index: 8;
  width: 50px;
}
@media all and (max-width: 1200px) {
  div.showCardContainer div.premiumStatus div.free {
    width: 30px;
  }
}
div.showCardContainer div.premiumStatus div.free img {
  display: block;
  width: 100%;
}
div.showCardContainer div.premiumStatus div.lock {
  position: absolute;
  top: 6px;
  inset-inline-start: 6px;
  z-index: 8;
  width: 20px;
}
@media all and (max-width: 1200px) {
  div.showCardContainer div.premiumStatus div.lock {
    width: 15px;
  }
}
div.showCardContainer div.premiumStatus div.lock img {
  display: block;
  width: 100%;
}
div.showCardContainer.showContents {
  cursor: pointer;
}
div.showCardContainer.showContents:hover div.imageContainer div.extraActions {
  visibility: visible;
  animation: fadeIn 0.2s ease-in-out forwards;
}
div.showCardContainer.showContents:hover div.imageContainer img {
  transform: scale(1.1);
}
div.showCardContainer.showContents:hover div.metaData h1.title {
  color: var(--text-hover);
}
div.showCardContainer div.imageContainer {
  width: 100%;
  display: block;
  position: relative;
  overflow: hidden;
  border-radius: 3px;
}
div.showCardContainer div.imageContainer img {
  width: 100%;
  display: block;
  border-radius: 3px;
  transition: 0.2s ease-in-out;
}
div.showCardContainer div.imageContainer img.landscape {
  min-height: 130px;
  background: black;
}
div.showCardContainer div.imageContainer img.portrait {
  min-height: 420px;
  background: black;
}
div.showCardContainer div.imageContainer div.continueWatchingBar {
  position: absolute;
  bottom: 0;
  height: 6px;
  background-color: var(--text-color);
  width: 100%;
}
div.showCardContainer div.imageContainer div.continueWatchingBar div.line {
  height: 100%;
  background-color: var(--button-hover);
}
div.showCardContainer div.imageContainer div.extraActions {
  visibility: hidden;
  animation: fadeOut 0.2s ease-in-out forwards;
}
div.showCardContainer div.imageContainer div.extraActions div.showCardOverlay {
  position: absolute;
  top: 0;
  bottom: 0;
  inset-inline-end: 0;
  inset-inline-start: 0;
  background-color: var(--overlay);
  z-index: 8;
}
div.showCardContainer div.imageContainer div.extraActions div.playIconContainer {
  position: absolute;
  z-index: 9;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border: 3px solid var(--text-color);
  display: flex;
  justify-content: center;
  align-items: center;
  width: 60px;
  height: 60px;
  border-radius: 50%;
  transition: 0.3s ease-in-out;
  cursor: pointer;
}
@media all and (min-width: 320px) {
  div.showCardContainer div.imageContainer div.extraActions div.playIconContainer {
    width: 30px;
    height: 30px;
  }
  div.showCardContainer div.imageContainer div.extraActions div.playIconContainer svg {
    width: 13px !important;
    height: 25px !important;
  }
}
@media all and (min-width: 320px) {
  div.showCardContainer div.imageContainer div.extraActions div.playIconContainer {
    width: 50px;
    height: 50px;
  }
}
div.showCardContainer div.imageContainer div.extraActions div.playIconContainer:hover {
  border-color: var(--text-hover);
}
div.showCardContainer div.imageContainer div.extraActions div.playIconContainer:hover svg {
  color: var(--text-hover);
}
div.showCardContainer div.imageContainer div.extraActions div.playIconContainer svg {
  width: 13px !important;
  height: 13px !important;
  color: var(--text-color);
  transition: 0.3s ease-in-out;
}
@media all and (min-width: 320px) {
  div.showCardContainer div.imageContainer div.extraActions div.playIconContainer svg {
    width: 13px !important;
    height: 13px !important;
  }
}
@media all and (min-width: 480px) {
  div.showCardContainer div.imageContainer div.extraActions div.playIconContainer svg {
    width: 25px !important;
    height: 18px !important;
  }
}
div.showCardContainer div.imageContainer div.extraActions div.addToList {
  position: absolute;
  bottom: 3px;
  width: 96%;
  z-index: 9;
  left: 50%;
  border-radius: 8px;
  transform: translateX(-50%);
  padding: 6px;
  text-align: center;
  box-shadow: rgb(62, 1, 1) 0px 16px 24px;
  background-color: rgb(49, 46, 4);
  -webkit-backdrop-filter: blur(8px);
          backdrop-filter: blur(8px);
}
@media all and (min-width: 320px) {
  div.showCardContainer div.imageContainer div.extraActions div.addToList {
    padding: 0;
    border-radius: 2px;
    width: 97%;
  }
  div.showCardContainer div.imageContainer div.extraActions div.addToList span {
    font-size: 8px;
  }
}
@media all and (min-width: 480px) {
  div.showCardContainer div.imageContainer div.extraActions div.addToList {
    padding: 3px;
  }
  div.showCardContainer div.imageContainer div.extraActions div.addToList span {
    font-size: 10px;
  }
}
div.showCardContainer div.imageContainer div.extraActions div.addToList:hover span {
  color: var(--text-hover);
}
div.showCardContainer div.imageContainer div.extraActions div.addToList span {
  color: var(--text-color);
  font-size: 10px;
  transition: 0.3s ease-in-out;
}
@media all and (min-width: 480px) {
  div.showCardContainer div.imageContainer div.extraActions div.addToList span {
    font-size: 11px;
  }
}
div.showCardContainer div.metaData {
  margin-top: 10px;
  color: var(--inner-text-color);
}
div.showCardContainer div.metaData h1.title {
  color: var(--text-color);
  font-size: 16px;
  transition: 0.3s ease-in-out;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}
@media all and (min-width: 320px) {
  div.showCardContainer div.metaData h1.title {
    font-size: 12px;
  }
}
@media all and (min-width: 640px) {
  div.showCardContainer div.metaData h1.title {
    font-size: 14px;
  }
}
div.showCardContainer div.metaData div.datas {
  display: flex;
  justify-content: space-between;
}
@media all and (max-width: 980px) {
  div.showCardContainer div.metaData div.datas {
    display: none;
  }
}
div.showCardContainer div.metaData div.datas div.left span {
  font-size: 12px;
  margin-inline-end: 6px;
}
div.showCardContainer div.metaData div.datas div.left span.duration.showDot::before {
  content: "·";
  margin-inline-end: 6px;
}
div.showCardContainer div.metaData div.datas div.left span.duration.ltr {
  direction: ltr !important;
  unicode-bidi: embed !important;
}
div.showCardContainer div.metaData div.datas div.left span:last-child {
  margin-inline-end: 0;
}
div.showCardContainer div.metaData div.datas div.right span {
  font-size: 14px;
  display: inline-block;
  padding: 1px 6px;
  background-color: rgba(150, 153, 163, 0.32);
  font-size: 12px;
  font-weight: 700;
  color: var(--text-color);
  border-radius: 4px;
}
@media all and (min-width: 320px) {
  div.showCardContainer div.metaData div.datas div.right span.certificate {
    font-size: 9px;
  }
}
div.showCardContainer div.metaData div.genres {
  font-size: 12px;
  display: flex;
  flex-wrap: wrap;
}
@media all and (min-width: 320px) {
  div.showCardContainer div.metaData div.genres {
    display: none;
  }
}
@media all and (min-width: 768px) {
  div.showCardContainer div.metaData div.genres {
    display: flex;
  }
}
div.showCardContainer div.metaData div.genres span {
  margin-inline-end: 6px;
}
div.showCardContainer div.metaData div.genres span:last-child {
  margin-inline-end: 0;
}
div.showCardContainer div.metaData div.genres span:first-child::before {
  display: none;
}
div.showCardContainer div.metaData div.genres span::before {
  content: "·";
  margin-inline-end: 6px;
}
div.showCardContainer div.metaData p.description {
  font-size: 12px;
  overflow: hidden;
  text-overflow: ellipsis;
  height: 30px;
  display: -webkit-box;
  -webkit-line-clamp: 2; /* number of lines to show */
  line-clamp: 2;
  -webkit-box-orient: vertical;
}

div.footerContainer {
  padding: 30px 0;
  position: relative;
  z-index: 9;
  background-color: var(--bg-color);
  border-top: 1px solid var(--border-color);
}
div.footerContainer div.topContainer {
  display: flex;
  justify-content: space-between;
}
div.footerContainer div.topContainer div.left {
  width: 20%;
  border-bottom: 1px solid var(--border-color);
  padding-bottom: 20px;
}
div.footerContainer div.topContainer div.left div.logoContainer {
  width: 160px;
}
@media all and (max-width: 480px) {
  div.footerContainer div.topContainer div.left div.logoContainer {
    width: 100px;
  }
}
div.footerContainer div.topContainer div.left div.logoContainer img {
  display: block;
  width: 100%;
}
div.footerContainer div.topContainer div.right {
  width: 20%;
  border-bottom: 1px solid var(--border-color);
  padding-bottom: 20px;
}
@media all and (max-width: 640px) {
  div.footerContainer div.topContainer div.right {
    width: 35%;
  }
}
div.footerContainer div.topContainer div.right ul.social {
  display: flex;
  align-items: center;
}
div.footerContainer div.topContainer div.right ul.social li {
  margin-right: 10px;
  cursor: pointer;
}
div.footerContainer div.topContainer div.right ul.social li:last-child {
  margin-right: 0;
}
div.footerContainer div.topContainer div.right ul.social li:hover svg {
  opacity: 0.6;
  color: var(--text-hover);
}
div.footerContainer div.topContainer div.right ul.social li svg {
  width: 20px !important;
  height: 20px !important;
  color: var(--grey-color);
  transition: 0.3s ease-in-out;
}
div.footerContainer div.centerContainer div.informationContainer {
  display: flex;
  flex-wrap: wrap;
  padding: 40px 0;
}
div.footerContainer div.centerContainer div.informationContainer div.items {
  width: 20%;
  color: var(--text-color);
}
@media all and (max-width: 768px) {
  div.footerContainer div.centerContainer div.informationContainer div.items {
    width: 25%;
    margin-bottom: 20px;
  }
}
@media all and (max-width: 640px) {
  div.footerContainer div.centerContainer div.informationContainer div.items {
    width: 33%;
  }
}
div.footerContainer div.centerContainer div.informationContainer div.items h6.heading {
  font-size: 12px;
  color: var(--inner-text-color);
  margin-bottom: 10px;
}
div.footerContainer div.centerContainer div.informationContainer div.items ul li span {
  font-size: 12px;
  cursor: pointer;
  transition: 0.3s ease-in-out;
}
div.footerContainer div.centerContainer div.informationContainer div.items ul li span:hover {
  opacity: 0.6;
  color: var(--text-hover);
}
div.footerContainer div.bottomContainer {
  border-top: 1px solid var(--border-color);
  padding: 40px 0;
}
div.footerContainer div.bottomContainer div.stores {
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
}
div.footerContainer div.bottomContainer div.stores svg {
  margin-right: 10px;
  width: 120px !important;
  transition: 0.3s ease-in-out;
  cursor: pointer;
}
div.footerContainer div.bottomContainer div.stores svg:last-child {
  margin-right: 0;
}
div.footerContainer div.bottomContainer div.stores svg:hover {
  opacity: 0.8;
}
div.footerContainer div.bottomContainer p {
  text-align: center;
  font-size: 12px;
  color: var(--grey-color);
  opacity: 0.7;
}
div.footerContainer div.bottomContainer p a {
  color: var(--text-color);
}
div.footerContainer div.bottomContainer div.typeOfPayments {
  color: var(--grey-color) !important;
  display: flex;
  justify-content: center;
  margin-top: 10px;
}
div.footerContainer div.bottomContainer div.typeOfPayments i {
  margin-right: 6px;
}
div.footerContainer div.bottomContainer div.typeOfPayments i:last-child {
  margin-right: 0;
}

div.footerContainer.rtl .topContainer .right .social li {
  margin-right: 0;
  margin-left: 10px;
}
div.footerContainer.rtl .topContainer .right .social li:last-child {
  margin-left: 0;
}
div.footerContainer.rtl .bottomContainer .stores svg {
  margin-right: 0;
  margin-left: 10px;
}
div.footerContainer.rtl .bottomContainer .stores svg:last-child {
  margin-left: 0;
}
div.footerContainer.rtl .bottomContainer .typeOfPayments i {
  margin-right: 0;
  margin-left: 6px;
}
div.footerContainer.rtl .bottomContainer .typeOfPayments i:last-child {
  margin-left: 0;
}

div.detailsScreen {
  padding: 100px 0 0;
  position: relative;
}
div.detailsScreen .swiper-button-next {
  right: -9px !important;
  top: 45%;
}
@media all and (min-width: 1200px) {
  div.detailsScreen .swiper-button-next {
    right: 0 !important;
  }
}
div.detailsScreen .swiper-button-prev {
  left: -9px !important;
  top: 45%;
}
@media all and (min-width: 1200px) {
  div.detailsScreen .swiper-button-prev {
    left: 0 !important;
  }
}
div.detailsScreen div.wrapper {
  background-color: var(--bg-color);
  position: relative;
  z-index: 8;
}
div.detailsScreen div.bgImageContainer {
  display: block;
  position: fixed;
  top: 0;
  height: 100%;
  z-index: 7;
  width: 100%;
}
div.detailsScreen div.bgImageContainer div.gradient {
  position: absolute;
  bottom: 0;
  z-index: 8;
  width: 100%;
  height: 100%;
  background-position: top;
  background-size: cover;
  background-repeat: no-repeat;
  background: linear-gradient(78.83deg, #10141f, rgba(16, 20, 31, 0) 260.46%), linear-gradient(1turn, #10141f, rgba(16, 20, 31, 0) 48.44%, rgba(16, 20, 31, 0.4)), rgba(16, 20, 31, 0.4);
}
div.detailsScreen div.bgImageContainer img {
  width: 100%;
  display: block;
}
div.detailsScreen div.videoContainer {
  background-color: #000;
  width: 100%;
}
div.detailsScreen div.videoContainer.heightIncrease {
  height: 38em;
}
@media all and (max-width: 1200px) {
  div.detailsScreen div.videoContainer.heightIncrease {
    height: 25em;
  }
}
div.detailsScreen div.videoContainer div.gradientTop {
  position: absolute;
  top: 0;
  z-index: 8;
  width: 100%;
  height: 20%;
  background-position: top;
  background-size: cover;
  background-repeat: no-repeat;
  background: linear-gradient(0deg, rgba(16, 20, 31, 0), rgba(16, 20, 31, 0.2) 19%, rgba(16, 20, 31, 0.4) 39%, rgba(16, 20, 31, 0.8));
}
div.detailsScreen div.videoContainer div.player {
  position: relative;
  z-index: 9;
  width: 100%;
  display: block;
}
div.detailsScreen div.videoContainer div.player div.closeIcon {
  display: none;
}
div.detailsScreen div.videoContainer div.player div.nowPlaying {
  position: absolute;
  top: 20px;
  inset-inline-end: 20px;
  z-index: 7;
}
div.detailsScreen div.videoContainer div.player div.nowPlaying h1 {
  color: var(--text-color);
}
@media all and (max-width: 1200px) {
  div.detailsScreen div.videoContainer div.player div.nowPlaying h1 {
    font-size: 23px;
  }
}
@media all and (max-width: 980px) {
  div.detailsScreen div.videoContainer div.player div.nowPlaying h1 {
    font-size: 20px;
  }
}
@media all and (max-width: 768px) {
  div.detailsScreen div.videoContainer div.player div.nowPlaying h1 {
    font-size: 18px;
  }
}
@media all and (max-width: 640px) {
  div.detailsScreen div.videoContainer div.player div.nowPlaying h1 {
    font-size: 16px;
  }
}
@media all and (max-width: 480px) {
  div.detailsScreen div.videoContainer div.player div.nowPlaying h1 {
    font-size: 14px;
  }
}
div.detailsScreen div.videoContainer div.player.pipMode {
  position: fixed;
  top: 110px;
  width: 22%;
  inset-inline-end: 0;
  z-index: 999999999;
  height: 200px;
}
@media all and (max-width: 768px) {
  div.detailsScreen div.videoContainer div.player.pipMode {
    width: 40%;
  }
}
@media all and (max-width: 640px) {
  div.detailsScreen div.videoContainer div.player.pipMode {
    width: 50%;
  }
}
@media all and (max-width: 480px) {
  div.detailsScreen div.videoContainer div.player.pipMode {
    width: 68%;
  }
}
div.detailsScreen div.videoContainer div.player.pipMode video {
  -o-object-fit: cover;
     object-fit: cover;
  height: 100%;
}
div.detailsScreen div.videoContainer div.player.pipMode .video-js {
  padding: 0;
  height: 100%;
}
div.detailsScreen div.videoContainer div.player.pipMode div.gradientTop {
  display: none;
}
div.detailsScreen div.videoContainer div.player.pipMode div.nowPlaying {
  display: none;
}
div.detailsScreen div.videoContainer div.player.pipMode:hover div.closeIcon {
  position: absolute;
  top: 10px;
  inset-inline-start: 10px;
  z-index: 9;
  background-color: var(--bg-color);
  height: 25px;
  width: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
}
div.detailsScreen div.videoContainer div.player.pipMode:hover div.closeIcon svg {
  color: var(--text-color);
}
div.detailsScreen div.videoContainer div.player.pipMode:hover div.gradientTop {
  display: block;
}
div.detailsScreen div.videoContainer video {
  display: block;
  width: 100%;
}
div.detailsScreen div.details {
  position: relative;
  z-index: 8;
  display: flex;
  padding: 30px 0 130px;
}
@media all and (max-width: 980px) {
  div.detailsScreen div.details {
    flex-direction: column;
  }
}
div.detailsScreen div.details div.watchNow {
  width: 100%;
  margin-top: 15px;
}
div.detailsScreen div.details div.watchNow button {
  width: 100%;
  display: block;
  background: var(--button-color);
  padding: 15px 10px;
  font-size: 15px;
  color: var(--text-color);
  font-weight: 600;
  border-radius: 8px;
  cursor: pointer;
  transition: 0.3s ease-in-out;
}
div.detailsScreen div.details div.watchNow button:hover {
  background: var(--button-hover);
}
div.detailsScreen div.details div.trailer {
  width: 100%;
  margin-top: 15px;
}
div.detailsScreen div.details div.trailer button {
  width: 100%;
  display: block;
  background-color: rgba(150, 153, 163, 0.16);
  padding: 15px 10px;
  font-size: 15px;
  color: var(--text-color);
  font-weight: 600;
  border-radius: 8px;
  cursor: pointer;
  transition: 0.3s ease-in-out;
}
div.detailsScreen div.details div.addtoList {
  width: 100%;
  margin-top: 15px;
}
div.detailsScreen div.details div.addtoList button {
  width: 100%;
  display: block;
  background-color: rgba(150, 153, 163, 0.16);
  padding: 15px 10px;
  font-size: 15px;
  color: var(--text-color);
  font-weight: 600;
  border-radius: 8px;
  cursor: pointer;
  transition: 0.3s ease-in-out;
}
div.detailsScreen div.details div.addtoList button:hover {
  background-color: rgba(203, 203, 208, 0.16);
}
div.detailsScreen div.details div.buttons {
  margin-top: 15px;
  display: flex;
  width: 100%;
}
div.detailsScreen div.details div.buttons div.buttonContainerOne {
  width: 70%;
  position: relative;
  margin-inline-end: 10px;
}
@media all and (max-width: 980px) {
  div.detailsScreen div.details div.buttons div.buttonContainerOne {
    width: 90%;
  }
}
@media all and (max-width: 640px) {
  div.detailsScreen div.details div.buttons div.buttonContainerOne {
    width: 80%;
  }
}
@media all and (max-width: 480px) {
  div.detailsScreen div.details div.buttons div.buttonContainerOne {
    width: 75%;
  }
}
div.detailsScreen div.details div.buttons div.buttonContainerOne div.shareContainer {
  position: absolute;
  bottom: -126px;
  inset-inline-start: -20px;
  z-index: 9;
  background-color: #1c1f29;
  border: 1px solid var(--border-color);
  width: 232px;
  border-radius: 8px;
  display: none;
}
@media all and (max-width: 1200px) {
  div.detailsScreen div.details div.buttons div.buttonContainerOne div.shareContainer {
    width: 200px;
  }
}
@media all and (max-width: 980px) {
  div.detailsScreen div.details div.buttons div.buttonContainerOne div.shareContainer {
    width: 232px;
    inset-inline-start: 50%;
    transform: translateX(-50%);
  }
}
@media all and (max-width: 480px) {
  div.detailsScreen div.details div.buttons div.buttonContainerOne div.shareContainer {
    width: 100%;
  }
}
div.detailsScreen div.details div.buttons div.buttonContainerOne div.shareContainer.active {
  display: block;
}
div.detailsScreen div.details div.buttons div.buttonContainerOne div.shareContainer::after {
  content: "";
  top: -9px;
  width: 0;
  height: 0;
  border-color: transparent transparent rgba(240, 241, 245, 0.16);
  border-style: solid;
  border-width: 0 8px 8px;
  position: absolute;
  inset-inline-start: 50%;
  transform: translateX(-50%);
}
@media all and (max-width: 1200px) {
  div.detailsScreen div.details div.buttons div.buttonContainerOne div.shareContainer::after {
    inset-inline-start: 40%;
  }
}
@media all and (max-width: 980px) {
  div.detailsScreen div.details div.buttons div.buttonContainerOne div.shareContainer::after {
    inset-inline-start: 50%;
  }
}
div.detailsScreen div.details div.buttons div.buttonContainerOne div.shareContainer div.facebookContainer button.facebook {
  display: flex !important;
  justify-content: space-between !important;
  padding: 15px !important;
  color: var(--text-color) !important;
  cursor: pointer !important;
  border-bottom: 1px solid var(--border-color) !important;
  width: 100% !important;
}
div.detailsScreen div.details div.buttons div.buttonContainerOne div.shareContainer div.facebookContainer button.facebook div.icon {
  width: 10%;
}
div.detailsScreen div.details div.buttons div.buttonContainerOne div.shareContainer div.facebookContainer button.facebook div.icon svg {
  width: 20px !important;
  height: 20px !important;
  color: var(--grey-color);
  transition: 0.3s ease-in-out;
}
div.detailsScreen div.details div.buttons div.buttonContainerOne div.shareContainer div.facebookContainer button.facebook div.icon svg:hover {
  color: var(--text-color);
}
div.detailsScreen div.details div.buttons div.buttonContainerOne div.shareContainer div.facebookContainer div.left {
  width: 10%;
}
div.detailsScreen div.details div.buttons div.buttonContainerOne div.shareContainer div.facebookContainer div.right svg {
  width: 20px !important;
  height: 20px !important;
  color: var(--grey-color);
  transition: 0.3s ease-in-out;
}
div.detailsScreen div.details div.buttons div.buttonContainerOne div.shareContainer div.facebookContainer div.right svg:hover {
  color: var(--text-color);
}
div.detailsScreen div.details div.buttons div.buttonContainerOne div.shareContainer div.twitterContainer button.twitter {
  display: flex !important;
  justify-content: space-between !important;
  padding: 15px !important;
  color: var(--text-color) !important;
  cursor: pointer !important;
  width: 100% !important;
}
div.detailsScreen div.details div.buttons div.buttonContainerOne div.shareContainer div.twitterContainer button.twitter div.icon {
  width: 10%;
}
div.detailsScreen div.details div.buttons div.buttonContainerOne div.shareContainer div.twitterContainer button.twitter div.icon svg {
  width: 20px !important;
  height: 20px !important;
  color: var(--grey-color);
  transition: 0.3s ease-in-out;
}
div.detailsScreen div.details div.buttons div.buttonContainerOne div.shareContainer div.twitterContainer button.twitter div.icon svg:hover {
  color: var(--text-color);
}
div.detailsScreen div.details div.buttons div.buttonContainerOne div.shareContainer div.twitterContainer div.left {
  width: 10%;
}
div.detailsScreen div.details div.buttons div.buttonContainerOne div.shareContainer div.twitterContainer div.right svg {
  width: 20px !important;
  height: 20px !important;
  color: var(--grey-color);
  transition: 0.3s ease-in-out;
}
div.detailsScreen div.details div.buttons div.buttonContainerOne div.shareContainer div.twitterContainer div.right svg:hover {
  color: var(--text-color);
}
div.detailsScreen div.details div.buttons div.buttonContainerOne button.share {
  display: block;
  background-color: rgba(150, 153, 163, 0.16);
  padding: 15px 10px;
  font-size: 15px;
  color: var(--text-color);
  font-weight: 600;
  border-radius: 8px;
  width: 100%;
  cursor: pointer;
  transition: 0.3s ease-in-out;
}
div.detailsScreen div.details div.buttons div.buttonContainerOne button.share:hover {
  background-color: rgba(203, 203, 208, 0.16);
}
div.detailsScreen div.details div.buttons div.buttonContainerTwo {
  width: 30%;
  position: relative;
}
@media all and (max-width: 980px) {
  div.detailsScreen div.details div.buttons div.buttonContainerTwo {
    width: 10%;
  }
}
@media all and (max-width: 640px) {
  div.detailsScreen div.details div.buttons div.buttonContainerTwo {
    width: 18%;
  }
}
@media all and (max-width: 480px) {
  div.detailsScreen div.details div.buttons div.buttonContainerTwo {
    width: 25%;
  }
}
div.detailsScreen div.details div.buttons div.buttonContainerTwo button.more {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(150, 153, 163, 0.16);
  padding: 15px 10px;
  font-size: 15px;
  color: var(--text-color);
  border-radius: 8px;
  width: 100%;
  cursor: pointer;
  transition: 0.3s ease-in-out;
}
div.detailsScreen div.details div.buttons div.buttonContainerTwo button.more svg {
  width: 20px !important;
  height: 20px !important;
}
div.detailsScreen div.details div.buttons div.buttonContainerTwo button.more:hover {
  background-color: rgba(203, 203, 208, 0.16);
}
div.detailsScreen div.details div.buttons div.buttonContainerTwo div.reportProblemContainer {
  position: absolute;
  bottom: -66px;
  inset-inline-end: 0;
  z-index: 9;
  background-color: #1c1f29;
  border: 1px solid var(--border-color);
  width: 200px;
  border-radius: 8px;
  padding: 15px;
  display: none;
  cursor: pointer;
}
div.detailsScreen div.details div.buttons div.buttonContainerTwo div.reportProblemContainer.active {
  display: block;
}
@media all and (max-width: 1200px) {
  div.detailsScreen div.details div.buttons div.buttonContainerTwo div.reportProblemContainer {
    width: 164px;
  }
}
@media all and (max-width: 980px) {
  div.detailsScreen div.details div.buttons div.buttonContainerTwo div.reportProblemContainer {
    width: 200px;
  }
}
div.detailsScreen div.details div.buttons div.buttonContainerTwo div.reportProblemContainer span {
  color: var(--text-color);
}
div.detailsScreen div.details div.buttons div.buttonContainerTwo div.reportProblemContainer::after {
  content: "";
  top: -9px;
  width: 0;
  height: 0;
  border-color: transparent transparent rgba(240, 241, 245, 0.16);
  border-style: solid;
  border-width: 0 8px 8px;
  position: absolute;
  inset-inline-end: 8%;
  transform: translateX(-50%);
}
@media all and (max-width: 1200px) {
  div.detailsScreen div.details div.buttons div.buttonContainerTwo div.reportProblemContainer::after {
    inset-inline-end: 5%;
  }
}
@media all and (max-width: 980px) {
  div.detailsScreen div.details div.buttons div.buttonContainerTwo div.reportProblemContainer::after {
    inset-inline-end: 8%;
  }
}
div.detailsScreen div.details div.subscriptions {
  margin-top: 20px;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
}
div.detailsScreen div.details div.subscriptions div.items {
  cursor: pointer;
  margin-inline-end: 10px;
  background-color: var(--yellow-color);
  padding: 10px 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 13px;
  font-weight: 600;
  color: var(--black-color);
  margin-bottom: 20px;
  border-radius: 30px;
}
div.detailsScreen div.details div.subscriptions div.items.free {
  background-color: var(--success-color);
}
div.detailsScreen div.details div.leftContainer {
  width: 27%;
  margin-inline-end: 40px;
}
@media all and (max-width: 980px) {
  div.detailsScreen div.details div.leftContainer {
    display: none;
  }
}
div.detailsScreen div.details div.leftContainer div.showCardDetails {
  width: 100%;
  position: sticky;
  top: 0;
}
div.detailsScreen div.details div.leftContainer div.showCardDetails div.imageContainer {
  width: 100%;
}
div.detailsScreen div.details div.leftContainer div.showCardDetails div.imageContainer img {
  width: 100%;
  display: block;
  border-radius: 5px;
}
div.detailsScreen div.details div.rightContainer {
  width: 100%;
  overflow-y: scroll;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}
div.detailsScreen div.details div.rightContainer::-webkit-scrollbar {
  display: none;
}
div.detailsScreen div.details div.rightContainer div.showMetadata {
  border-bottom: 1px solid var(--border-color);
  padding-bottom: 40px;
  width: 100%;
}
div.detailsScreen div.details div.rightContainer div.showMetadata h1.name {
  font-size: 58px;
  font-weight: 700;
  color: var(--text-color);
}
@media all and (max-width: 1200px) {
  div.detailsScreen div.details div.rightContainer div.showMetadata h1.name {
    font-size: 48px;
  }
}
@media all and (max-width: 640px) {
  div.detailsScreen div.details div.rightContainer div.showMetadata h1.name {
    font-size: 35px;
  }
}
div.detailsScreen div.details div.rightContainer div.showMetadata div.data {
  display: flex;
  margin-bottom: 6px;
}
div.detailsScreen div.details div.rightContainer div.showMetadata div.data span {
  font-size: 18px;
  color: var(--inner-text-color);
}
@media all and (max-width: 640px) {
  div.detailsScreen div.details div.rightContainer div.showMetadata div.data span {
    font-size: 14px;
  }
}
div.detailsScreen div.details div.rightContainer div.showMetadata div.data span.year {
  margin-inline-end: 6px;
}
div.detailsScreen div.details div.rightContainer div.showMetadata div.data span.duration {
  margin-inline-end: 8px;
}
div.detailsScreen div.details div.rightContainer div.showMetadata div.data span.duration.showDot::before {
  content: "·";
  margin-inline-end: 6px;
}
div.detailsScreen div.details div.rightContainer div.showMetadata div.data span.duration.ltr {
  direction: ltr !important;
  unicode-bidi: embed !important;
}
div.detailsScreen div.details div.rightContainer div.showMetadata div.data span.certificate {
  display: block;
  padding: 4px 6px;
  background-color: rgba(150, 153, 163, 0.32);
  font-size: 11px;
  font-weight: 700;
  color: var(--text-color);
  border-radius: 4px;
}
@media all and (max-width: 640px) {
  div.detailsScreen div.details div.rightContainer div.showMetadata div.data span.certificate {
    font-size: 10px;
  }
}
div.detailsScreen div.details div.rightContainer div.showMetadata div.data div.userRatings {
  display: flex;
  margin-inline-start: 15px;
}
@media all and (max-width: 360px) {
  div.detailsScreen div.details div.rightContainer div.showMetadata div.data div.userRatings {
    margin-inline-start: 10px;
  }
}
div.detailsScreen div.details div.rightContainer div.showMetadata div.data div.userRatings div.star.active svg {
  fill: #fbe725;
}
div.detailsScreen div.details div.rightContainer div.showMetadata div.data div.userRatings div.star svg {
  width: 23px !important;
  height: 23px !important;
  fill: var(--grey-color);
  transition: 0.3s ease-in-out;
}
div.detailsScreen div.details div.rightContainer div.showMetadata div.data div.addRating {
  margin-inline-start: 15px;
  cursor: pointer;
}
div.detailsScreen div.details div.rightContainer div.showMetadata div.data div.addRating:hover svg {
  fill: var(--grey-color);
}
div.detailsScreen div.details div.rightContainer div.showMetadata div.data div.addRating svg {
  width: 27px !important;
  height: 27px !important;
  fill: var(--white-color);
  transition: 0.3s ease-in-out;
}
div.detailsScreen div.details div.rightContainer div.showMetadata div.genres {
  margin-bottom: 10px;
  cursor: pointer;
}
div.detailsScreen div.details div.rightContainer div.showMetadata div.genres span {
  font-size: 18px;
  color: var(--inner-text-color);
  margin-inline-end: 6px;
  display: inline-block;
}
@media all and (max-width: 640px) {
  div.detailsScreen div.details div.rightContainer div.showMetadata div.genres span {
    font-size: 14px;
  }
}
div.detailsScreen div.details div.rightContainer div.showMetadata div.genres span::before {
  content: "·";
  margin-inline-end: 6px;
}
div.detailsScreen div.details div.rightContainer div.showMetadata div.genres span:first-child::before {
  display: none;
}
div.detailsScreen div.details div.rightContainer div.showMetadata div.genres span:last-child {
  margin-inline-end: 0;
}
div.detailsScreen div.details div.rightContainer div.showMetadata div.genres span:hover {
  color: var(--white-color);
}
div.detailsScreen div.details div.rightContainer div.showMetadata div.watchNow {
  display: none;
}
@media all and (max-width: 980px) {
  div.detailsScreen div.details div.rightContainer div.showMetadata div.watchNow {
    display: block;
  }
}
div.detailsScreen div.details div.rightContainer div.showMetadata div.trailer {
  display: none;
}
@media all and (max-width: 980px) {
  div.detailsScreen div.details div.rightContainer div.showMetadata div.trailer {
    display: block;
  }
}
div.detailsScreen div.details div.rightContainer div.showMetadata div.addtoList {
  display: none;
}
@media all and (max-width: 980px) {
  div.detailsScreen div.details div.rightContainer div.showMetadata div.addtoList {
    display: block;
  }
}
div.detailsScreen div.details div.rightContainer div.showMetadata div.buttons {
  display: none;
  margin-bottom: 20px;
}
@media all and (max-width: 980px) {
  div.detailsScreen div.details div.rightContainer div.showMetadata div.buttons {
    display: flex;
  }
}
div.detailsScreen div.details div.rightContainer div.showMetadata p.description {
  font-size: 15px;
  color: var(--text-color);
  line-height: 1.4em;
  letter-spacing: 0.3px;
  max-width: 100%;
}
@media all and (max-width: 1200px) {
  div.detailsScreen div.details div.rightContainer div.showMetadata p.description {
    max-width: 100%;
  }
}
div.detailsScreen div.details div.rightContainer div.showMetadata div.info {
  margin-top: 20px;
}
div.detailsScreen div.details div.rightContainer div.showMetadata div.info div.starring {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
}
div.detailsScreen div.details div.rightContainer div.showMetadata div.info div.starring div.left {
  width: 15%;
}
@media all and (max-width: 768px) {
  div.detailsScreen div.details div.rightContainer div.showMetadata div.info div.starring div.left {
    width: 20%;
  }
}
@media all and (max-width: 640px) {
  div.detailsScreen div.details div.rightContainer div.showMetadata div.info div.starring div.left {
    width: 25%;
  }
}
@media all and (max-width: 480px) {
  div.detailsScreen div.details div.rightContainer div.showMetadata div.info div.starring div.left {
    width: 30%;
  }
}
@media all and (max-width: 360px) {
  div.detailsScreen div.details div.rightContainer div.showMetadata div.info div.starring div.left {
    width: 33%;
  }
}
div.detailsScreen div.details div.rightContainer div.showMetadata div.info div.starring div.left h6 {
  font-size: 16px;
  font-weight: 400;
  color: var(--inner-text-color);
}
div.detailsScreen div.details div.rightContainer div.showMetadata div.info div.starring div.right {
  width: 85%;
}
@media all and (max-width: 980px) {
  div.detailsScreen div.details div.rightContainer div.showMetadata div.info div.starring div.right {
    width: 75%;
  }
}
@media all and (max-width: 640px) {
  div.detailsScreen div.details div.rightContainer div.showMetadata div.info div.starring div.right {
    width: 70%;
  }
}
@media all and (max-width: 480px) {
  div.detailsScreen div.details div.rightContainer div.showMetadata div.info div.starring div.right {
    width: 65%;
  }
}
@media all and (max-width: 360px) {
  div.detailsScreen div.details div.rightContainer div.showMetadata div.info div.starring div.right {
    width: 55%;
  }
}
div.detailsScreen div.details div.rightContainer div.showMetadata div.info div.starring div.right ul.names {
  display: flex;
  flex-wrap: wrap;
}
div.detailsScreen div.details div.rightContainer div.showMetadata div.info div.starring div.right ul.names li {
  font-size: 16px;
  font-weight: 600;
  margin-inline-end: 8px;
  color: var(--text-color);
}
div.detailsScreen div.details div.rightContainer div.showMetadata div.info div.starring div.right ul.names li:last-child {
  margin-inline-end: 0;
}
@media all and (max-width: 480px) {
  div.detailsScreen div.details div.rightContainer div.showMetadata div.info div.starring div.right ul.names li {
    font-size: 14px;
  }
}
div.detailsScreen div.details div.rightContainer div.showMetadata div.info div.director {
  display: flex;
  justify-content: space-between;
}
div.detailsScreen div.details div.rightContainer div.showMetadata div.info div.director div.left {
  width: 15%;
}
@media all and (max-width: 768px) {
  div.detailsScreen div.details div.rightContainer div.showMetadata div.info div.director div.left {
    width: 20%;
  }
}
@media all and (max-width: 640px) {
  div.detailsScreen div.details div.rightContainer div.showMetadata div.info div.director div.left {
    width: 25%;
  }
}
@media all and (max-width: 480px) {
  div.detailsScreen div.details div.rightContainer div.showMetadata div.info div.director div.left {
    width: 30%;
  }
}
@media all and (max-width: 360px) {
  div.detailsScreen div.details div.rightContainer div.showMetadata div.info div.director div.left {
    width: 33%;
  }
}
div.detailsScreen div.details div.rightContainer div.showMetadata div.info div.director div.left h6 {
  font-size: 16px;
  font-weight: 400;
  color: var(--inner-text-color);
}
div.detailsScreen div.details div.rightContainer div.showMetadata div.info div.director div.right {
  width: 85%;
}
@media all and (max-width: 980px) {
  div.detailsScreen div.details div.rightContainer div.showMetadata div.info div.director div.right {
    width: 75%;
  }
}
@media all and (max-width: 640px) {
  div.detailsScreen div.details div.rightContainer div.showMetadata div.info div.director div.right {
    width: 70%;
  }
}
@media all and (max-width: 480px) {
  div.detailsScreen div.details div.rightContainer div.showMetadata div.info div.director div.right {
    width: 65%;
  }
}
@media all and (max-width: 360px) {
  div.detailsScreen div.details div.rightContainer div.showMetadata div.info div.director div.right {
    width: 55%;
  }
}
div.detailsScreen div.details div.rightContainer div.showMetadata div.info div.director div.right h6 {
  font-size: 16px;
  font-weight: 600;
  margin-inline-end: 8px;
  color: var(--text-color);
}
@media all and (max-width: 480px) {
  div.detailsScreen div.details div.rightContainer div.showMetadata div.info div.director div.right h6 {
    font-size: 14px;
  }
}
div.detailsScreen div.details div.rightContainer div.categories {
  padding: 30px 0px;
  background-color: transparent !important;
}
@media all and (max-width: 768px) {
  div.detailsScreen div.details div.rightContainer div.categories {
    padding: 30px 30px;
  }
}
div.detailsScreen div.details div.rightContainer div.categories h1.heading {
  font-size: 25px;
  color: var(--text-color);
  margin-bottom: 20px;
  text-transform: capitalize;
}
div.detailsScreen div.details div.rightContainer div.categories div.categoryLists ul {
  display: flex;
  flex-wrap: wrap;
}
div.detailsScreen div.details div.rightContainer div.categories div.categoryLists ul li.list {
  display: flex;
  margin-inline-end: 10px;
  width: 24%;
  background-color: var(--bg-color);
  margin-bottom: 10px;
  cursor: pointer;
}
div.detailsScreen div.details div.rightContainer div.categories div.categoryLists ul li.list:nth-child(4n) {
  margin-inline-end: 0;
}
@media all and (max-width: 1200px) {
  div.detailsScreen div.details div.rightContainer div.categories div.categoryLists ul li.list {
    width: 32.6%;
  }
  div.detailsScreen div.details div.rightContainer div.categories div.categoryLists ul li.list:nth-child(3n) {
    margin-inline-end: 0;
  }
  div.detailsScreen div.details div.rightContainer div.categories div.categoryLists ul li.list:nth-child(4n) {
    margin-inline-end: 10px;
  }
}
@media all and (max-width: 980px) {
  div.detailsScreen div.details div.rightContainer div.categories div.categoryLists ul li.list {
    width: 49%;
  }
  div.detailsScreen div.details div.rightContainer div.categories div.categoryLists ul li.list:nth-child(3n) {
    margin-inline-end: 10px;
  }
  div.detailsScreen div.details div.rightContainer div.categories div.categoryLists ul li.list:nth-child(2n) {
    margin-inline-end: 0;
  }
}
@media all and (max-width: 640px) {
  div.detailsScreen div.details div.rightContainer div.categories div.categoryLists ul li.list {
    width: 100%;
    margin-inline-end: 0;
  }
  div.detailsScreen div.details div.rightContainer div.categories div.categoryLists ul li.list:nth-child(3n) {
    margin-inline-end: 0;
  }
  div.detailsScreen div.details div.rightContainer div.categories div.categoryLists ul li.list:nth-child(2n) {
    margin-inline-end: 0;
  }
}
div.detailsScreen div.details div.rightContainer div.categories div.categoryLists ul li.list div.left div.imageContainer {
  width: 70px;
}
div.detailsScreen div.details div.rightContainer div.categories div.categoryLists ul li.list div.left div.imageContainer img {
  display: block;
  width: 100%;
}
div.detailsScreen div.details div.rightContainer div.categories div.categoryLists ul li.list div.right {
  padding: 10px;
}
div.detailsScreen div.details div.rightContainer div.categories div.categoryLists ul li.list div.right h1.title {
  font-size: 14px;
  color: var(--text-color);
}
div.detailsScreen div.details div.rightContainer div.aboutSection {
  background-color: transparent !important;
  padding: 30px 0px;
}
@media all and (max-width: 768px) {
  div.detailsScreen div.details div.rightContainer div.aboutSection {
    padding: 30px 30px;
  }
}
div.detailsScreen div.details div.rightContainer div.aboutSection h1.heading {
  font-size: 25px;
  color: var(--text-color);
  margin-bottom: 20px;
}
div.detailsScreen div.details div.rightContainer div.aboutSection div.aboutLists {
  display: flex;
  flex-wrap: wrap;
}
div.detailsScreen div.details div.rightContainer div.aboutSection div.aboutLists ul {
  flex-direction: column;
  width: 100%;
}
div.detailsScreen div.details div.rightContainer div.aboutSection div.aboutLists ul div.aboutContainer {
  background-color: transparent;
  width: 100%;
  justify-content: flex-start;
  display: flex;
  flex-direction: column;
}
div.detailsScreen div.details div.rightContainer div.aboutSection div.aboutLists ul div.aboutContainer li.aboutlist {
  width: 100%;
  background-color: var(--white-color);
  margin-inline-end: 7px;
  cursor: pointer;
}
div.detailsScreen div.details div.rightContainer div.aboutSection div.aboutLists ul div.aboutContainer li.aboutlist:hover div.info h1.name {
  color: var(--grey-color);
}
div.detailsScreen div.details div.rightContainer div.aboutSection div.aboutLists ul div.aboutContainer li.aboutlist:nth-child(8n) {
  margin-inline-end: 0;
}
div.detailsScreen div.details div.rightContainer div.aboutSection div.aboutLists ul div.aboutContainer li.aboutlist:last-child {
  margin-inline-end: 0 !important;
}
@media all and (max-width: 2600px) {
  div.detailsScreen div.details div.rightContainer div.aboutSection div.aboutLists ul div.aboutContainer li.aboutlist {
    width: 12%;
  }
  div.detailsScreen div.details div.rightContainer div.aboutSection div.aboutLists ul div.aboutContainer li.aboutlist:nth-child(7n) {
    margin-inline-end: 0;
  }
  div.detailsScreen div.details div.rightContainer div.aboutSection div.aboutLists ul div.aboutContainer li.aboutlist:nth-child(8n) {
    margin-inline-end: 7px;
  }
}
@media all and (max-width: 1400px) {
  div.detailsScreen div.details div.rightContainer div.aboutSection div.aboutLists ul div.aboutContainer li.aboutlist {
    width: 14%;
  }
  div.detailsScreen div.details div.rightContainer div.aboutSection div.aboutLists ul div.aboutContainer li.aboutlist:nth-child(7n) {
    margin-inline-end: 0;
  }
  div.detailsScreen div.details div.rightContainer div.aboutSection div.aboutLists ul div.aboutContainer li.aboutlist:nth-child(8n) {
    margin-inline-end: 7px;
  }
}
@media all and (max-width: 1200px) {
  div.detailsScreen div.details div.rightContainer div.aboutSection div.aboutLists ul div.aboutContainer li.aboutlist {
    width: 19%;
  }
  div.detailsScreen div.details div.rightContainer div.aboutSection div.aboutLists ul div.aboutContainer li.aboutlist:nth-child(7n) {
    margin-inline-end: 7px;
  }
  div.detailsScreen div.details div.rightContainer div.aboutSection div.aboutLists ul div.aboutContainer li.aboutlist:nth-child(6n) {
    margin-inline-end: 0;
  }
}
@media all and (max-width: 980px) {
  div.detailsScreen div.details div.rightContainer div.aboutSection div.aboutLists ul div.aboutContainer li.aboutlist {
    width: 24%;
  }
  div.detailsScreen div.details div.rightContainer div.aboutSection div.aboutLists ul div.aboutContainer li.aboutlist:nth-child(4n) {
    margin-inline-end: 0;
  }
  div.detailsScreen div.details div.rightContainer div.aboutSection div.aboutLists ul div.aboutContainer li.aboutlist:nth-child(6n) {
    margin-inline-end: 7px;
  }
}
@media all and (max-width: 768px) {
  div.detailsScreen div.details div.rightContainer div.aboutSection div.aboutLists ul div.aboutContainer li.aboutlist {
    width: 32%;
  }
  div.detailsScreen div.details div.rightContainer div.aboutSection div.aboutLists ul div.aboutContainer li.aboutlist:nth-child(4n) {
    margin-inline-end: 0;
  }
  div.detailsScreen div.details div.rightContainer div.aboutSection div.aboutLists ul div.aboutContainer li.aboutlist:nth-child(6n) {
    margin-inline-end: 7px;
  }
}
@media all and (max-width: 480px) {
  div.detailsScreen div.details div.rightContainer div.aboutSection div.aboutLists ul div.aboutContainer li.aboutlist {
    width: 49%;
  }
  div.detailsScreen div.details div.rightContainer div.aboutSection div.aboutLists ul div.aboutContainer li.aboutlist:nth-child(3n) {
    margin-inline-end: 7px;
  }
  div.detailsScreen div.details div.rightContainer div.aboutSection div.aboutLists ul div.aboutContainer li.aboutlist:nth-child(2n) {
    margin-inline-end: 0;
  }
}
@media all and (max-width: 360px) {
  div.detailsScreen div.details div.rightContainer div.aboutSection div.aboutLists ul div.aboutContainer li.aboutlist {
    width: 99%;
  }
  div.detailsScreen div.details div.rightContainer div.aboutSection div.aboutLists ul div.aboutContainer li.aboutlist:nth-child(3n) {
    margin-inline-end: 7px;
  }
  div.detailsScreen div.details div.rightContainer div.aboutSection div.aboutLists ul div.aboutContainer li.aboutlist:nth-child(2n) {
    margin-inline-end: 0;
  }
}
div.detailsScreen div.details div.rightContainer div.aboutSection div.aboutLists ul div.aboutContainer li.aboutlist div.imageContainer {
  width: 100%;
  background-color: var(--white-color);
  margin-inline-end: 7px;
  position: relative;
  align-content: space-around;
}
div.detailsScreen div.details div.rightContainer div.aboutSection div.aboutLists ul div.aboutContainer li.aboutlist div.imageContainer img {
  width: 100%;
  align-items: center;
  display: block;
}
div.detailsScreen div.details div.rightContainer div.aboutSection div.aboutLists ul div.aboutContainer li.aboutlist div.imageContainer div.openurl {
  position: absolute;
  z-index: 99;
  margin-top: -44px;
  inset-inline-end: 0;
}
div.detailsScreen div.details div.rightContainer div.aboutSection div.aboutLists ul div.aboutContainer li.aboutlist div.imageContainer div.openurl svg {
  fill: #fff !important;
  width: 20px;
  stroke: #fff;
}
div.detailsScreen div.details div.rightContainer div.aboutSection div.aboutLists ul div.aboutContainer li.aboutlist div.info {
  padding: 10px 10px 30px;
  background: #fff;
  padding: 8px;
  min-height: 121px;
  max-height: 121px;
}
div.detailsScreen div.details div.rightContainer div.aboutSection div.aboutLists ul div.aboutContainer li.aboutlist div.info h1.title {
  font-size: 16px;
  color: var(--text-hover);
}
div.detailsScreen div.details div.rightContainer div.aboutSection div.aboutLists ul div.aboutContainer li.aboutlist div.info p.description {
  color: #666;
  font-weight: 400;
  font-size: 12px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  margin-top: 10px;
}
div.detailsScreen div.details div.rightContainer div.awardsAndFestivals {
  padding: 30px 0px 0;
  background-color: transparent !important;
  align-items: center;
}
div.detailsScreen div.details div.rightContainer div.awardsAndFestivals h1.heading {
  font-size: 25px;
  color: var(--text-color);
  margin-bottom: 20px;
}
div.detailsScreen div.details div.rightContainer div.awardsAndFestivals ul.lists {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  align-content: center;
}
div.detailsScreen div.details div.rightContainer div.awardsAndFestivals ul.lists li.list {
  margin-inline-end: 10px;
  width: 24%;
  margin-bottom: 20px;
  text-align: center;
  align-items: center;
}
div.detailsScreen div.details div.rightContainer div.awardsAndFestivals ul.lists li.list:nth-child(4n) {
  margin-inline-end: 0;
}
@media all and (max-width: 1200px) {
  div.detailsScreen div.details div.rightContainer div.awardsAndFestivals ul.lists li.list {
    width: 32.6%;
  }
  div.detailsScreen div.details div.rightContainer div.awardsAndFestivals ul.lists li.list:nth-child(3n) {
    margin-inline-end: 0;
  }
  div.detailsScreen div.details div.rightContainer div.awardsAndFestivals ul.lists li.list:nth-child(4n) {
    margin-inline-end: 10px;
  }
}
@media all and (max-width: 980px) {
  div.detailsScreen div.details div.rightContainer div.awardsAndFestivals ul.lists li.list {
    width: 49%;
  }
  div.detailsScreen div.details div.rightContainer div.awardsAndFestivals ul.lists li.list:nth-child(3n) {
    margin-inline-end: 10px;
  }
  div.detailsScreen div.details div.rightContainer div.awardsAndFestivals ul.lists li.list:nth-child(2n) {
    margin-inline-end: 0;
  }
}
@media all and (max-width: 640px) {
  div.detailsScreen div.details div.rightContainer div.awardsAndFestivals ul.lists li.list {
    width: 100%;
    margin-inline-end: 0;
  }
  div.detailsScreen div.details div.rightContainer div.awardsAndFestivals ul.lists li.list:nth-child(3n) {
    margin-inline-end: 0;
  }
  div.detailsScreen div.details div.rightContainer div.awardsAndFestivals ul.lists li.list:nth-child(2n) {
    margin-inline-end: 0;
  }
}
div.detailsScreen div.details div.rightContainer div.awardsAndFestivals ul.lists li.list div.imageContainer {
  margin: 0 auto 20px;
  width: 40px;
  filter: grayscale(100%);
  background: rgb(200, 200, 200);
  margin-bottom: 20px;
  align-items: center;
}
div.detailsScreen div.details div.rightContainer div.awardsAndFestivals ul.lists li.list div.imageContainer img {
  display: block;
  width: 100%;
}
div.detailsScreen div.details div.rightContainer div.awardsAndFestivals ul.lists li.list h1.title {
  color: var(--text-color);
  font-size: 14px;
  margin-bottom: 10px;
}
div.detailsScreen div.details div.rightContainer div.awardsAndFestivals ul.lists li.list div.descriptionAndYear {
  display: flex;
  justify-content: center;
}
div.detailsScreen div.details div.rightContainer div.awardsAndFestivals ul.lists li.list div.descriptionAndYear span.year {
  margin-inline-end: 6px;
  font-size: 13px;
  color: var(--grey-color);
  font-weight: normal;
}
div.detailsScreen div.details div.rightContainer div.awardsAndFestivals ul.lists li.list div.descriptionAndYear p.description {
  font-size: 13px;
  color: var(--grey-color);
  font-weight: normal;
}
div.detailsScreen div.details div.rightContainer div.castAndCrew {
  background-color: transparent !important;
  padding: 30px 0px;
}
@media all and (max-width: 768px) {
  div.detailsScreen div.details div.rightContainer div.castAndCrew {
    padding: 30px 30px;
  }
}
div.detailsScreen div.details div.rightContainer div.castAndCrew h1.heading {
  font-size: 25px;
  color: var(--text-color);
  margin-bottom: 20p;
}
div.detailsScreen div.details div.rightContainer div.castAndCrew ul.lists {
  display: flex;
  flex-wrap: wrap;
}
div.detailsScreen div.details div.rightContainer div.castAndCrew ul.lists li.list {
  width: 15%;
  background-color: var(--bg-color);
  margin-inline-end: 7px;
  cursor: pointer;
}
div.detailsScreen div.details div.rightContainer div.castAndCrew ul.lists li.list:hover div.info h1.name {
  color: var(--text-hover);
}
div.detailsScreen div.details div.rightContainer div.castAndCrew ul.lists li.list:nth-child(8n) {
  margin-inline-end: 0;
}
div.detailsScreen div.details div.rightContainer div.castAndCrew ul.lists li.list:last-child {
  margin-inline-end: 0 !important;
}
@media all and (max-width: 1400px) {
  div.detailsScreen div.details div.rightContainer div.castAndCrew ul.lists li.list {
    width: 13.7%;
  }
  div.detailsScreen div.details div.rightContainer div.castAndCrew ul.lists li.list:nth-child(7n) {
    margin-inline-end: 0;
  }
  div.detailsScreen div.details div.rightContainer div.castAndCrew ul.lists li.list:nth-child(8n) {
    margin-inline-end: 7px;
  }
}
@media all and (max-width: 1200px) {
  div.detailsScreen div.details div.rightContainer div.castAndCrew ul.lists li.list {
    width: 19%;
  }
  div.detailsScreen div.details div.rightContainer div.castAndCrew ul.lists li.list:nth-child(7n) {
    margin-inline-end: 7px;
  }
  div.detailsScreen div.details div.rightContainer div.castAndCrew ul.lists li.list:nth-child(6n) {
    margin-inline-end: 0;
  }
}
@media all and (max-width: 980px) {
  div.detailsScreen div.details div.rightContainer div.castAndCrew ul.lists li.list {
    width: 24%;
  }
  div.detailsScreen div.details div.rightContainer div.castAndCrew ul.lists li.list:nth-child(4n) {
    margin-inline-end: 0;
  }
  div.detailsScreen div.details div.rightContainer div.castAndCrew ul.lists li.list:nth-child(6n) {
    margin-inline-end: 7px;
  }
}
@media all and (max-width: 768px) {
  div.detailsScreen div.details div.rightContainer div.castAndCrew ul.lists li.list {
    width: 32.5%;
    margin-bottom: 2%;
  }
  div.detailsScreen div.details div.rightContainer div.castAndCrew ul.lists li.list:nth-child(4n) {
    margin-inline-end: 7px;
  }
  div.detailsScreen div.details div.rightContainer div.castAndCrew ul.lists li.list:nth-child(3n) {
    margin-inline-end: 0px;
  }
}
@media all and (max-width: 640px) {
  div.detailsScreen div.details div.rightContainer div.castAndCrew ul.lists li.list {
    width: 32%;
  }
  div.detailsScreen div.details div.rightContainer div.castAndCrew ul.lists li.list:nth-child(4n) {
    margin-inline-end: 7px;
  }
  div.detailsScreen div.details div.rightContainer div.castAndCrew ul.lists li.list:nth-child(3n) {
    margin-inline-end: 0px;
  }
}
@media all and (max-width: 480px) {
  div.detailsScreen div.details div.rightContainer div.castAndCrew ul.lists li.list {
    width: 48%;
  }
  div.detailsScreen div.details div.rightContainer div.castAndCrew ul.lists li.list:nth-child(3n) {
    margin-inline-end: 7px;
  }
  div.detailsScreen div.details div.rightContainer div.castAndCrew ul.lists li.list:nth-child(2n) {
    margin-inline-end: 0;
  }
}
@media all and (max-width: 360px) {
  div.detailsScreen div.details div.rightContainer div.castAndCrew ul.lists li.list {
    width: 99%;
  }
}
div.detailsScreen div.details div.rightContainer div.castAndCrew ul.lists li.list div.imageContainer {
  background: rgb(200, 200, 200);
  width: 100%;
}
div.detailsScreen div.details div.rightContainer div.castAndCrew ul.lists li.list div.imageContainer img {
  width: 100%;
  display: block;
}
div.detailsScreen div.details div.rightContainer div.castAndCrew ul.lists li.list div.info {
  padding: 10px 10px 20px;
  background-color: rgba(203, 203, 208, 0.16);
}
div.detailsScreen div.details div.rightContainer div.castAndCrew ul.lists li.list div.info h1.name {
  font-size: 14px;
  color: var(--text-color);
}
div.detailsScreen div.details div.rightContainer div.castAndCrew ul.lists li.list div.info p.role {
  font-size: 12px;
  color: var(--grey-color);
}
div.loginPage {
  background-image: url("./assets/Images/login_bg.svg");
  background-repeat: no-repeat;
}
div.loginPage.gaia {
  background-image: none;
}
div.loginPage span.error {
  color: #E72A31;
  font-size: 12px;
  text-align: start;
  display: block;
  margin-bottom: 10px;
}
div.loginPage div.updatesContainer {
  text-align: center;
  padding-top: 100px;
  width: 50%;
  margin: 0 auto -90px;
}
@media all and (max-width: 1200px) {
  div.loginPage div.updatesContainer {
    width: 60%;
  }
}
@media all and (max-width: 640px) {
  div.loginPage div.updatesContainer {
    width: 90%;
  }
}
div.loginPage div.updatesContainer p {
  background-color: #fff;
  font-size: 14px;
  color: var(--error-color);
  padding: 10px;
  border-radius: 5px;
  font-weight: 600;
  border-bottom: 4px solid var(--error-color);
}
div.loginPage div.loginContainer {
  width: 20rem;
  margin: 0 auto;
  padding: 130px 0;
  text-align: center;
}
@media all and (max-width: 640px) {
  div.loginPage div.loginContainer {
    width: 95%;
  }
}
div.loginPage div.loginContainer.gaia {
  width: 30rem;
  padding-right: 20px;
  padding-left: 20px;
  border-radius: 2px;
  background: linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0) 20%, rgba(0, 0, 0, 0.03) 60%), linear-gradient(to top, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0) 20%, rgba(0, 0, 0, 0.03) 60%);
  background-size: 100% 80%;
  background-position: center;
  background-repeat: no-repeat;
}
@media (max-width: 480px) {
  div.loginPage div.loginContainer.gaia {
    width: 20rem;
  }
}
div.loginPage div.loginContainer div.top div#appleid-signin {
  height: 60px;
  cursor: pointer;
  padding: 5px;
  border: transparent;
  border-radius: 10px;
  transition: border 5s ease;
}
div.loginPage div.loginContainer div.top div#appleid-signin:hover {
  border: 1px solid transparent;
}
div.loginPage div.loginContainer div.top div.userIconContainer {
  width: 60px;
  height: 60px;
  border-radius: 50%;
  border: 2px solid var(--border-color);
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto 10px;
}
div.loginPage div.loginContainer div.top div.userIconContainer svg {
  width: 20px !important;
  height: 20px !important;
  color: var(--text-color);
}
div.loginPage div.loginContainer div.top h1 {
  color: var(--text-color);
  margin-bottom: 20px;
}
@media all and (max-width: 640px) {
  div.loginPage div.loginContainer div.top h1 {
    font-size: 25px;
  }
}
div.loginPage div.loginContainer div.top div.facebookContainer {
  display: flex;
  padding: 18px 20px;
  border-radius: 8px;
  align-items: center;
  cursor: pointer;
  transition: 0.2s ease-in-out;
  justify-content: center;
  position: relative;
  margin-bottom: 10px;
}
div.loginPage div.loginContainer div.top div.facebookContainer:hover div.background {
  transform: scale(1.0476190476, 1.0357142857);
}
div.loginPage div.loginContainer div.top div.facebookContainer div.background {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #3d6ac3;
  z-index: 1;
  transition: 0.1s ease-in-out;
  border-radius: 8px;
}
div.loginPage div.loginContainer div.top div.facebookContainer div.contents {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  z-index: 2;
}
div.loginPage div.loginContainer div.top div.facebookContainer div.left {
  line-height: 0;
  margin-right: 50px;
}
div.loginPage div.loginContainer div.top div.facebookContainer div.left svg {
  width: 20px !important;
  height: 20px !important;
  color: var(--text-color);
  line-height: 0;
}
div.loginPage div.loginContainer div.top div.facebookContainer div.right span {
  color: var(--text-color);
  font-weight: 700;
  line-height: 0;
  font-size: 15px;
}
div.loginPage div.loginContainer div.top div.googleContainer {
  display: flex;
  padding: 18px 20px;
  border-radius: 8px;
  align-items: center;
  cursor: pointer;
  transition: 0.2s ease-in-out;
  justify-content: center;
  position: relative;
  margin-bottom: 10px;
}
div.loginPage div.loginContainer div.top div.googleContainer:hover div.background {
  transform: scale(1.0476190476, 1.0357142857);
}
div.loginPage div.loginContainer div.top div.googleContainer div.background {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #fff;
  z-index: 1;
  transition: 0.1s ease-in-out;
  border-radius: 8px;
}
div.loginPage div.loginContainer div.top div.googleContainer div.contents {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  z-index: 2;
}
div.loginPage div.loginContainer div.top div.googleContainer div.left {
  line-height: 0;
  margin-right: 50px;
}
div.loginPage div.loginContainer div.top div.googleContainer div.left svg {
  width: 20px !important;
  height: 20px !important;
  color: var(--text-color);
  line-height: 0;
}
div.loginPage div.loginContainer div.top div.googleContainer div.right span {
  color: #3c4043;
  font-weight: 700;
  line-height: 0;
  font-size: 15px;
}
div.loginPage div.loginContainer div.top div.seperate {
  display: flex;
  align-items: center;
  margin-top: 15px;
}
div.loginPage div.loginContainer div.top div.seperate span {
  font-size: 12px;
  color: var(--text-color);
}
div.loginPage div.loginContainer div.top div.seperate span.line {
  height: 0.1px;
  width: 45%;
  background-color: var(--inner-text-color);
}
div.loginPage div.loginContainer div.top div.seperate span.line:first-child {
  margin-right: 8px;
}
div.loginPage div.loginContainer div.top div.seperate span.line:last-child {
  margin-left: 8px;
}
div.loginPage div.loginContainer div.buttonContainer {
  display: flex;
  padding: 18px 20px;
  border-radius: 4px;
  align-items: center;
  margin-bottom: 15px;
  cursor: pointer;
  transition: 0.2s ease-in-out;
  justify-content: center;
  position: relative;
}
div.loginPage div.loginContainer div.buttonContainer:hover div.background {
  transform: scale(1.0476190476, 1.0357142857);
}
div.loginPage div.loginContainer div.buttonContainer div.background {
  background: rgba(150, 153, 163, 0.16);
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border-radius: 4px;
  transition: 0.1s ease-in-out;
}
div.loginPage div.loginContainer div.buttonContainer button {
  background: transparent;
  font-size: 15px;
  color: var(--text-color);
  font-weight: 600;
}
div.loginPage div.loginContainer div.bottom {
  margin-top: 15px;
}
div.loginPage div.loginContainer div.bottom h3 {
  color: var(--text-color);
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 15px;
}
div.loginPage div.loginContainer div.bottom form {
  margin-bottom: 20px;
}
div.loginPage div.loginContainer div.bottom form div.emailContainer {
  background-color: white;
  width: 100%;
  margin-bottom: 10px;
  border-radius: 8px;
}
div.loginPage div.loginContainer div.bottom form div.emailContainer input {
  background: transparent;
  color: black;
  outline: none;
  border: none;
  text-align: start;
  width: 100%;
  padding: 20px 15px;
  border-radius: 8px;
}
div.loginPage div.loginContainer div.bottom form div.emailContainer input::-moz-placeholder {
  font-size: 15px;
  color: rgb(111, 113, 115);
}
div.loginPage div.loginContainer div.bottom form div.emailContainer input::placeholder {
  font-size: 15px;
  color: rgb(111, 113, 115);
}
div.loginPage div.loginContainer div.bottom form div.passwordContainer {
  background-color: white;
  width: 100%;
  margin-bottom: 10px;
  border-radius: 8px;
}
div.loginPage div.loginContainer div.bottom form div.passwordContainer input {
  background: transparent;
  color: black;
  outline: none;
  border: none;
  text-align: start;
  width: 100%;
  padding: 20px 15px;
  border-radius: 8px;
}
div.loginPage div.loginContainer div.bottom form div.passwordContainer input::-moz-placeholder {
  font-size: 15px;
  color: rgb(111, 113, 115);
}
div.loginPage div.loginContainer div.bottom form div.passwordContainer input::placeholder {
  font-size: 15px;
  color: rgb(111, 113, 115);
}
div.loginPage div.loginContainer div.bottom form div.buttonContainer {
  display: flex;
  padding: 18px 20px;
  border-radius: 4px;
  align-items: center;
  margin-bottom: 15px;
  cursor: pointer;
  transition: 0.2s ease-in-out;
  justify-content: center;
  position: relative;
}
div.loginPage div.loginContainer div.bottom form div.buttonContainer:hover div.background {
  transform: scale(1.0476190476, 1.0357142857);
}
div.loginPage div.loginContainer div.bottom form div.buttonContainer div.background {
  background: rgba(150, 153, 163, 0.16);
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border-radius: 4px;
  transition: 0.1s ease-in-out;
}
div.loginPage div.loginContainer div.bottom form div.buttonContainer button {
  background: transparent;
  font-size: 15px;
  color: var(--text-color);
  font-weight: 600;
}
div.loginPage div.loginContainer div.bottom form h4.forgotPassword {
  color: var(--text-color);
  font-size: 15px;
  font-weight: 600;
  margin-bottom: 6px;
  cursor: pointer;
}
div.loginPage div.loginContainer div.bottom form h4 {
  font-size: 15px;
  color: #d5d5d6;
  font-weight: 400;
}
div.loginPage div.loginContainer div.bottom form h4 span {
  font-weight: 600;
  font-size: 15px;
  cursor: pointer;
}
div.loginPage div.loginContainer div.bottom form h4.gaia {
  color: grey;
}
div.loginPage div.loginContainer div.bottom .template-9-font input, div.loginPage div.loginContainer div.bottom .template-9-font select {
  font-family: "Gilroy-Medium" !important;
}
div.loginPage div.loginContainer div.bottom .template-10-font input, div.loginPage div.loginContainer div.bottom .template-10-font select {
  font-family: "Avenir-Roman", sans-serif !important;
}
div.loginPage div.loginContainer div.bottom p {
  font-size: 11px;
  color: var(--inner-text-color);
  line-height: 1.6em;
}
div.loginPage div.loginContainer div.bottom p span {
  color: var(--text-color);
  cursor: pointer;
}

div.loginPage.rtl .loginContainer .top .facebookContainer div.left {
  margin-right: 0;
  margin-left: 50px;
}
div.loginPage.rtl .loginContainer .top .googleContainer div.left {
  margin-right: 0;
  margin-left: 50px;
}
div.loginPage.rtl .loginContainer .top .seperate span.line:first-child {
  margin-right: 0;
  margin-left: 8px;
}
div.loginPage.rtl .loginContainer .top .seperate span.line:last-child {
  margin-left: 0;
  margin-right: 8px;
}

div.registerPage {
  background-image: url("./assets/Images/login_bg.svg");
  background-repeat: no-repeat;
}
div.registerPage.gaia {
  background-image: none;
}
div.registerPage span.error {
  color: #e72a31;
  font-size: 12px;
  text-align: start;
  display: block;
  margin-bottom: 10px;
}
div.registerPage div.registerContainer {
  width: 20rem;
  margin: 0 auto;
  padding: 130px 0 100px;
  text-align: center;
}
@media all and (max-width: 640px) {
  div.registerPage div.registerContainer {
    width: 95%;
  }
}
div.registerPage div.registerContainer.gaia {
  width: 30rem;
  padding-right: 20px;
  padding-left: 20px;
  padding-bottom: 8%;
  border-radius: 2px;
  background: linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0) 20%, rgba(0, 0, 0, 0.03) 60%), linear-gradient(to top, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0) 20%, rgba(0, 0, 0, 0.03) 60%);
  background-size: 100% 80%;
  background-position: center;
  background-repeat: no-repeat;
}
@media (max-width: 480px) {
  div.registerPage div.registerContainer.gaia {
    width: 20rem;
  }
}
@media (max-width: 360px) {
  div.registerPage div.registerContainer.gaia {
    width: 18rem;
  }
}
div.registerPage div.registerContainer div.top div.userIconContainer {
  width: 60px;
  height: 60px;
  border-radius: 50%;
  border: 2px solid var(--border-color);
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto 10px;
}
div.registerPage div.registerContainer div.top div.userIconContainer svg {
  width: 20px !important;
  height: 20px !important;
  color: var(--text-color);
}
div.registerPage div.registerContainer div.top h1 {
  color: var(--text-color);
}
@media all and (max-width: 640px) {
  div.registerPage div.registerContainer div.top h1 {
    font-size: 25px;
  }
}
div.registerPage div.registerContainer div.top h6 {
  font-size: 15px;
  color: var(--inner-text-color);
  margin-bottom: 20px;
}
div.registerPage div.registerContainer div.top div.facebookContainer {
  display: flex;
  padding: 18px 20px;
  border-radius: 8px;
  align-items: center;
  cursor: pointer;
  transition: 0.2s ease-in-out;
  justify-content: center;
  position: relative;
  margin-bottom: 10px;
}
div.registerPage div.registerContainer div.top div.facebookContainer:hover div.background {
  transform: scale(1.0476190476, 1.0357142857);
}
div.registerPage div.registerContainer div.top div.facebookContainer div.background {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #3d6ac3;
  z-index: 1;
  transition: 0.1s ease-in-out;
  border-radius: 8px;
}
div.registerPage div.registerContainer div.top div.facebookContainer div.contents {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  z-index: 2;
}
div.registerPage div.registerContainer div.top div.facebookContainer div.left {
  line-height: 0;
  margin-right: 50px;
}
div.registerPage div.registerContainer div.top div.facebookContainer div.left svg {
  width: 20px !important;
  height: 20px !important;
  color: var(--text-color);
  line-height: 0;
}
div.registerPage div.registerContainer div.top div.facebookContainer div.right span {
  color: var(--text-color);
  font-weight: 700;
  line-height: 0;
  font-size: 15px;
}
div.registerPage div.registerContainer div.top div.googleContainer {
  display: flex;
  padding: 18px 20px;
  border-radius: 8px;
  align-items: center;
  cursor: pointer;
  transition: 0.2s ease-in-out;
  justify-content: center;
  position: relative;
}
div.registerPage div.registerContainer div.top div.googleContainer:hover div.background {
  transform: scale(1.0476190476, 1.0357142857);
}
div.registerPage div.registerContainer div.top div.googleContainer div.background {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #fff;
  z-index: 1;
  transition: 0.1s ease-in-out;
  border-radius: 8px;
}
div.registerPage div.registerContainer div.top div.googleContainer div.contents {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  z-index: 2;
}
div.registerPage div.registerContainer div.top div.googleContainer div.left {
  line-height: 0;
  margin-right: 50px;
}
div.registerPage div.registerContainer div.top div.googleContainer div.left svg {
  width: 20px !important;
  height: 20px !important;
  color: var(--text-color);
  line-height: 0;
}
div.registerPage div.registerContainer div.top div.googleContainer div.right span {
  color: #3c4043;
  font-weight: 700;
  line-height: 0;
  font-size: 15px;
}
div.registerPage div.registerContainer div.top div.seperate {
  display: flex;
  align-items: center;
  margin-top: 15px;
}
div.registerPage div.registerContainer div.top div.seperate span {
  font-size: 12px;
  color: var(--text-color);
}
div.registerPage div.registerContainer div.top div.seperate span.line {
  height: 0.1px;
  width: 45%;
  background-color: var(--inner-text-color);
}
div.registerPage div.registerContainer div.top div.seperate span.line:first-child {
  margin-right: 8px;
}
div.registerPage div.registerContainer div.top div.seperate span.line:last-child {
  margin-left: 8px;
}
div.registerPage div.registerContainer div.bottom {
  margin-top: 15px;
}
div.registerPage div.registerContainer div.bottom h3 {
  color: var(--text-color);
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 15px;
}
div.registerPage div.registerContainer div.bottom form {
  margin-bottom: 20px;
}
div.registerPage div.registerContainer div.bottom form div.oneByOne {
  background-color: white;
  width: 100%;
  margin-bottom: 10px;
  border-radius: 8px;
}
div.registerPage div.registerContainer div.bottom form div.oneByOne input {
  background: transparent;
  outline: none;
  color: black;
  border: none;
  text-align: start;
  width: 100%;
  padding: 17px 15px;
  border-radius: 8px;
}
div.registerPage div.registerContainer div.bottom form div.oneByOne input::-moz-placeholder {
  font-size: 15px;
  color: rgb(111, 113, 115);
}
div.registerPage div.registerContainer div.bottom form div.oneByOne input::placeholder {
  font-size: 15px;
  color: rgb(111, 113, 115);
}
div.registerPage div.registerContainer div.bottom form div.oneByOnePhone {
  background-color: white;
  width: 100%;
  margin-bottom: 10px;
  border-radius: 8px;
}
div.registerPage div.registerContainer div.bottom form div.oneByOnePhone input {
  background: transparent;
  outline: 1;
  border: 1;
  color: black;
  text-align: start;
  width: 80%;
  height: 35px;
  padding: 17px 15px;
  border-radius: 8px;
  text-align: justify;
}
div.registerPage div.registerContainer div.bottom form div.oneByOnePhone input::-moz-placeholder {
  font-size: 15px;
  color: rgb(111, 113, 115);
}
div.registerPage div.registerContainer div.bottom form div.oneByOnePhone input::placeholder {
  font-size: 15px;
  color: rgb(111, 113, 115);
}
div.registerPage div.registerContainer div.bottom form div.oneByTwo {
  width: 100%;
  margin-bottom: 10px;
  border-radius: 8px;
}
div.registerPage div.registerContainer div.bottom form div.oneByTwo label {
  font-size: 12px;
  color: var(--inner-text-color);
  text-align: start;
  display: block;
  font-weight: 600;
}
div.registerPage div.registerContainer div.bottom form div.oneByTwo div.containers {
  display: flex;
  height: 100%;
  border-radius: 8px;
}
div.registerPage div.registerContainer div.bottom form div.oneByTwo div.containers input {
  outline: none;
  border: none;
  text-align: start;
  width: 100%;
  height: 100%;
}
div.registerPage div.registerContainer div.bottom form div.oneByTwo div.containers input::-moz-placeholder {
  font-size: 15px;
  color: rgb(186, 184, 184);
}
div.registerPage div.registerContainer div.bottom form div.oneByTwo div.containers input::placeholder {
  font-size: 15px;
  color: rgb(186, 184, 184);
}
div.registerPage div.registerContainer div.bottom form div.oneByTwo div.containers input::-webkit-outer-spin-button,
div.registerPage div.registerContainer div.bottom form div.oneByTwo div.containers input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
div.registerPage div.registerContainer div.bottom form div.oneByTwo div.containers input[type=number] {
  -moz-appearance: textfield;
}
div.registerPage div.registerContainer div.bottom form div.oneByTwo div.containers div.left {
  margin-right: 10px;
  width: 70%;
  height: 100%;
}
div.registerPage div.registerContainer div.bottom form div.oneByTwo div.containers div.left input[type=date] {
  padding: 17px 15px;
  border-radius: 8px;
  margin-bottom: 10px;
}
div.registerPage div.registerContainer div.bottom form div.oneByTwo div.containers div.right {
  width: 30%;
  height: 100%;
}
div.registerPage div.registerContainer div.bottom form div.oneByTwo div.containers div.right div.genderContainer {
  background-color: var(--text-color);
  border-radius: 8px;
  position: relative;
  height: 100%;
  width: 100%;
  margin-bottom: 10px;
}
div.registerPage div.registerContainer div.bottom form div.oneByTwo div.containers div.right div.genderContainer svg {
  position: absolute;
  right: 5px;
  width: 15px !important;
  height: 15px !important;
  color: var(--inner-text-color);
  top: 50%;
  transform: translateY(-50%);
}
div.registerPage div.registerContainer div.bottom form div.oneByTwo div.containers div.right div.genderContainer select {
  padding: 17px 15px;
  border-radius: 8px;
  height: 100%;
  width: 100%;
  outline: none;
  border: none;
  -moz-appearance: none;
  -webkit-appearance: none;
}
div.registerPage div.registerContainer div.bottom form div.oneByTwo div.containers div.right div.genderContainer select option {
  padding: 17px 15px;
  background-color: var(--text-color);
  color: var(--black-color);
  border-bottom: 1px solid var(--border-color);
  position: relative;
}
div.registerPage div.registerContainer div.bottom form div.buttonContainer {
  display: flex;
  padding: 18px 20px;
  border-radius: 4px;
  align-items: center;
  margin-bottom: 15px;
  cursor: pointer;
  transition: 0.2s ease-in-out;
  justify-content: center;
  position: relative;
}
div.registerPage div.registerContainer div.bottom form div.buttonContainer:hover div.background {
  transform: scale(1.0476190476, 1.0357142857);
}
div.registerPage div.registerContainer div.bottom form div.buttonContainer div.background {
  background: rgba(150, 153, 163, 0.16);
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border-radius: 4px;
  transition: 0.1s ease-in-out;
}
div.registerPage div.registerContainer div.bottom form div.buttonContainer button {
  background: transparent;
  font-size: 15px;
  color: var(--text-color);
  font-weight: 600;
}
div.registerPage div.registerContainer div.bottom form h4.forgotPassword {
  color: var(--text-color);
  font-size: 15px;
  font-weight: 600;
  margin-bottom: 6px;
  cursor: pointer;
}
div.registerPage div.registerContainer div.bottom .template-9-font input, div.registerPage div.registerContainer div.bottom .template-9-font select {
  font-family: "Gilroy-Medium" !important;
}
div.registerPage div.registerContainer div.bottom .template-10-font input, div.registerPage div.registerContainer div.bottom .template-10-font select {
  font-family: "Avenir-Roman", sans-serif !important;
}
div.registerPage div.registerContainer div.bottom h4.signIn {
  font-size: 12px;
  color: #d5d5d6;
  font-weight: 400;
}
div.registerPage div.registerContainer div.bottom h4.signIn span {
  font-weight: 600;
  font-size: 15px;
  cursor: pointer;
}
div.registerPage div.registerContainer div.bottom h4.signIn.gaia {
  color: grey;
}
div.registerPage div.registerContainer div.bottom p {
  font-size: 11px;
  color: var(--inner-text-color);
  line-height: 1.6em;
  margin-bottom: 10px;
}
div.registerPage div.registerContainer div.bottom p span {
  color: var(--text-color);
  cursor: pointer;
}

div.registerPage.rtl .registerContainer .top .facebookContainer div.left {
  margin-right: 0;
  margin-left: 50px;
}
div.registerPage.rtl .registerContainer .top .googleContainer div.left {
  margin-right: 0;
  margin-left: 50px;
}
div.registerPage.rtl .registerContainer .top .seperate span.line:first-child {
  margin-right: 0;
  margin-left: 8px;
}
div.registerPage.rtl .registerContainer .top .seperate span.line:last-child {
  margin-left: 0;
  margin-right: 8px;
}
div.registerPage.rtl .registerContainer .bottom form .oneByTwo div.left {
  margin-right: 0;
  margin-left: 10px;
}
div.registerPage.rtl .registerContainer .bottom form .oneByTwo svg {
  right: 0;
  left: 5px;
}

div.commonPage {
  padding: 130px 0 40px;
}
div.commonPage div.top {
  padding-bottom: 60px;
  position: absolute;
  top: 84px;
  z-index: 8;
  left: 60px;
}
@media all and (max-width: 640px) {
  div.commonPage div.top {
    top: 50px;
    z-index: 8;
    left: 20px;
  }
}
div.commonPage div.top h1.heading {
  font-size: 26px;
  margin-bottom: 10px;
  margin-top: 20px;
  color: var(--text-color);
}
@media all and (max-width: 640px) {
  div.commonPage div.top h1.heading {
    font-size: 22px;
    margin-top: 50px;
  }
}
div.commonPage div.top h1.heading.gaia {
  font-family: "Avenir-Black", sans-serif;
}
div.commonPage div.top p.desc {
  font-size: 18px;
  color: var(--inner-text-color);
}
@media all and (max-width: 640px) {
  div.commonPage div.top p.desc {
    font-size: 14px;
  }
}
div.commonPage div.topbanner {
  position: absolute;
  top: 84px;
  z-index: 8;
  left: 60px;
  width: 91%;
  background-color: transparent;
}
@media all and (max-width: 640px) {
  div.commonPage div.topbanner {
    top: 50px;
    z-index: 8;
    left: 20px;
  }
}
div.commonPage div.topbanner h1.heading {
  font-size: 26px;
  margin-bottom: 10px;
  margin-top: 20px;
  color: var(--text-color);
}
@media all and (max-width: 640px) {
  div.commonPage div.topbanner h1.heading {
    font-size: 22px;
    margin-top: 50px;
  }
}
div.commonPage div.topbanner p.desc {
  font-size: 18px;
  color: var(--inner-text-color);
}
@media all and (max-width: 640px) {
  div.commonPage div.topbanner p.desc {
    font-size: 14px;
  }
}
div.commonPage div.bgImageContainer {
  width: 100%;
  display: block;
  height: 508px;
  position: relative;
}
@media ((max-width: 2600px) and (min-width: 768px)) {
  div.commonPage div.bgImageContainer {
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
  }
}
@media all and (max-width: 768px) {
  div.commonPage div.bgImageContainer {
    height: 556px;
  }
}
div.commonPage div.bgImageContainer img {
  display: block;
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
}
@media all and (max-width: 768px) {
  div.commonPage div.bgImageContainer img {
    -o-object-fit: cover;
       object-fit: cover;
  }
}
div.commonPage div.bannerContainer {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-bottom: 15%;
  position: relative;
  text-align: center;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}
@media all and (max-width: 1400px) {
  div.commonPage div.bannerContainer {
    padding-bottom: 17%;
  }
}
@media all and (max-width: 1200px) {
  div.commonPage div.bannerContainer {
    padding-bottom: 19%;
  }
}
@media all and (max-width: 980px) {
  div.commonPage div.bannerContainer {
    padding-bottom: 23%;
  }
}
@media all and (max-width: 768px) {
  div.commonPage div.bannerContainer {
    padding-bottom: 30%;
  }
}
@media all and (max-width: 640px) {
  div.commonPage div.bannerContainer {
    padding-bottom: 37%;
  }
}
@media all and (max-width: 480px) {
  div.commonPage div.bannerContainer {
    padding-bottom: 45%;
  }
}
@media all and (max-width: 360px) {
  div.commonPage div.bannerContainer {
    padding-bottom: 60%;
  }
}
div.commonPage div.bannerContainer img {
  display: none;
}
div.commonPage div.bannerContainer .topbanner, div.commonPage div.bannerContainer .top {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1;
  color: #fff;
}
@media all and (max-width: 1200px) {
  div.commonPage div.bannerContainer .topbanner, div.commonPage div.bannerContainer .top {
    top: 45%;
  }
}
@media all and (max-width: 640px) {
  div.commonPage div.bannerContainer .topbanner, div.commonPage div.bannerContainer .top {
    top: 40%;
  }
}
div.commonPage div.bannerContainer .topbanner .heading, div.commonPage div.bannerContainer .top .heading {
  font-size: 4rem;
  margin-bottom: 10px;
}
@media all and (max-width: 480px) {
  div.commonPage div.bannerContainer .topbanner .heading, div.commonPage div.bannerContainer .top .heading {
    font-size: 2.1rem;
  }
}
@media all and (max-width: 360px) {
  div.commonPage div.bannerContainer .topbanner .heading, div.commonPage div.bannerContainer .top .heading {
    font-size: 1.8rem;
  }
}
@media all and (max-width: 320px) {
  div.commonPage div.bannerContainer .topbanner .heading, div.commonPage div.bannerContainer .top .heading {
    font-size: 1.5rem;
  }
}
div.commonPage div.bannerContainer .topbanner .desc, div.commonPage div.bannerContainer .top .desc {
  font-size: 1.2rem;
}
div.commonPage div.bottomGradient {
  position: absolute;
  bottom: 0;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  z-index: 1;
  height: 100%;
  background-position: top;
  background-size: cover;
  background-repeat: no-repeat;
  background: linear-gradient(78.83deg, rgb(16, 20, 31) 0%, rgba(16, 20, 31, 0) 61.46%), linear-gradient(360deg, rgb(16, 20, 31) 0%, rgba(16, 20, 31, 0) 48.44%, rgba(16, 20, 31, 0.4) 100%);
}
div.commonPage div.bottom {
  margin-top: 40px;
}
div.commonPage div.bottom div.seasonContainer {
  border: 1px solid var(--border-color);
  border-radius: 8px;
  width: 20rem;
  margin-bottom: 20px;
  background: transparent;
  padding: 15px 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  color: var(--text-color);
  cursor: pointer;
  z-index: 999;
  overflow: visible;
}
@media all and (max-width: 480px) {
  div.commonPage div.bottom div.seasonContainer {
    width: 100%;
  }
}
div.commonPage div.bottom div.seasonContainer svg {
  width: 26px !important;
  height: 26px !important;
  transition: 0.3s ease-in-out;
}
div.commonPage div.bottom div.seasonContainer svg.active {
  transform: rotate(-180deg);
}
div.commonPage div.bottom div.seasonContainer.zIndexController div.listContainer {
  z-index: 9;
}
div.commonPage div.bottom div.seasonContainer div.listContainer {
  overflow-y: scroll;
  position: absolute;
  top: 55px;
  width: 100%;
  left: 0;
  right: 0;
  z-index: -1;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
  cursor: pointer;
}
div.commonPage div.bottom div.seasonContainer div.listContainer::-webkit-scrollbar {
  display: none;
}
div.commonPage div.bottom div.seasonContainer div.listContainer.scroll {
  -ms-overflow-style: unset; /* IE and Edge */
  scrollbar-width: unset; /* Firefox */
}
div.commonPage div.bottom div.seasonContainer div.listContainer.scroll::-webkit-scrollbar {
  display: block;
}
div.commonPage div.bottom div.seasonContainer div.listContainer.scroll::-webkit-scrollbar {
  width: 5px;
  border-radius: 30px;
}
div.commonPage div.bottom div.seasonContainer div.listContainer.scroll::-webkit-scrollbar-thumb {
  background-color: var(--bg-color);
  border-radius: 30px;
}
div.commonPage div.bottom div.seasonContainer div.listContainer.scroll::-webkit-scrollbar-track {
  background-color: var(--grey-color);
}
div.commonPage div.bottom div.seasonContainer div.list {
  border-bottom-right-radius: 8px;
  border-bottom-left-radius: 8px;
  display: none;
}
div.commonPage div.bottom div.seasonContainer div.list.active {
  display: block;
}
div.commonPage div.bottom div.seasonContainer div.list div.item {
  width: 100%;
  padding: 8px 10px;
  border-bottom: 1px solid var(--border-color);
  background-color: var(--bg-color);
}
div.commonPage div.bottom div.seasonContainer div.list div.item:last-child {
  border-bottom-right-radius: 8px;
  border-bottom-left-radius: 8px;
}
div.commonPage div.bottom div.seasonContainer div.list div.item span {
  font-size: 16px;
  color: var(--text-color);
}
div.commonPage div.bottom div.itemsContainer {
  display: flex;
  flex-wrap: wrap;
}
div.commonPage div.bottom div.itemsContainer div.items.rowCount3 {
  width: 32.5%;
  margin-right: 13px;
  margin-bottom: 30px;
}
div.commonPage div.bottom div.itemsContainer div.items.rowCount3:nth-child(3n) {
  margin-right: 0;
}
@media all and (max-width: 1200px) {
  div.commonPage div.bottom div.itemsContainer div.items.rowCount3 {
    width: 32%;
  }
}
@media all and (min-width: 981px) {
  div.commonPage div.bottom div.itemsContainer div.items.rowCount3.landscape:hover div.showCardContainer .square {
    position: absolute;
    z-index: 9999999;
    transform: translateX(60px) translateY(-60px) scale(1.1);
  }
  div.commonPage div.bottom div.itemsContainer div.items.rowCount3.landscape:hover div.showCardContainer .square div.metaData {
    display: block;
    visibility: visible !important;
    animation: fadeIn 0.3s ease-in-out;
  }
  div.commonPage div.bottom div.itemsContainer div.items.rowCount3.landscape:hover:nth-child(3n) div.showCardContainer div.square {
    transform: translateX(-60px) translateY(-60px) scale(1.1);
  }
}
@media all and (max-width: 980px) {
  div.commonPage div.bottom div.itemsContainer div.items.rowCount3.landscape {
    width: 24.3%;
    margin-right: 6px;
    margin-bottom: 40px;
  }
  div.commonPage div.bottom div.itemsContainer div.items.rowCount3.landscape:nth-child(6n) {
    margin-right: 6px;
  }
  div.commonPage div.bottom div.itemsContainer div.items.rowCount3.landscape:nth-child(4n) {
    margin-right: 0;
  }
}
@media all and (max-width: 768px) {
  div.commonPage div.bottom div.itemsContainer div.items.rowCount3.landscape {
    width: 32.3%;
  }
  div.commonPage div.bottom div.itemsContainer div.items.rowCount3.landscape:nth-child(4n) {
    margin-right: 6px;
  }
  div.commonPage div.bottom div.itemsContainer div.items.rowCount3.landscape:nth-child(3n) {
    margin-right: 0;
  }
}
@media all and (max-width: 640px) {
  div.commonPage div.bottom div.itemsContainer div.items.rowCount3.landscape {
    width: 48.2%;
  }
  div.commonPage div.bottom div.itemsContainer div.items.rowCount3.landscape:nth-child(3n) {
    margin-right: 6px;
  }
  div.commonPage div.bottom div.itemsContainer div.items.rowCount3.landscape:nth-child(2n) {
    margin-right: 0;
  }
}
@media all and (max-width: 480px) {
  div.commonPage div.bottom div.itemsContainer div.items.rowCount3.landscape {
    width: 100%;
    margin-right: 0;
  }
  div.commonPage div.bottom div.itemsContainer div.items.rowCount3.landscape:nth-child(6n) {
    margin-right: 0;
  }
  div.commonPage div.bottom div.itemsContainer div.items.rowCount3.landscape:nth-child(4n) {
    margin-right: 0;
  }
  div.commonPage div.bottom div.itemsContainer div.items.rowCount3.landscape:nth-child(3n) {
    margin-right: 0;
  }
  div.commonPage div.bottom div.itemsContainer div.items.rowCount3.landscape:nth-child(2n) {
    margin-right: 0;
  }
}
@media all and (max-width: 480px) and (max-width: 360px) {
  div.commonPage div.bottom div.itemsContainer div.items.rowCount3.landscape {
    width: 100%;
    margin-right: 0;
  }
  div.commonPage div.bottom div.itemsContainer div.items.rowCount3.landscape:nth-child(3n) {
    margin-right: 0;
  }
}
@media all and (min-width: 981px) {
  div.commonPage div.bottom div.itemsContainer div.items.rowCount3.portrait:hover div.showCardContainer .square {
    position: absolute;
    z-index: 9999999;
    transform: translateX(60px) translateY(-60px) scale(1.2);
  }
  div.commonPage div.bottom div.itemsContainer div.items.rowCount3.portrait:hover div.showCardContainer .square div.metaData {
    display: block;
    visibility: visible !important;
    animation: fadeIn 0.3s ease-in-out;
  }
  div.commonPage div.bottom div.itemsContainer div.items.rowCount3.portrait:hover:nth-child(3n) div.showCardContainer div.square {
    transform: translateX(-60px) translateY(-60px) scale(1.2);
  }
}
@media all and (min-width: 981px) and (max-width: 1300px) {
  div.commonPage div.bottom div.itemsContainer div.items.rowCount3.portrait:hover:nth-child(6n) div.showCardContainer div.square {
    transform: translateX(-60px) translateY(-60px) scale(1.2);
  }
  div.commonPage div.bottom div.itemsContainer div.items.rowCount3.portrait:hover:nth-child(7n) div.showCardContainer div.square {
    transform: translateX(60px) translateY(-60px) scale(1.2);
  }
}
@media all and (min-width: 981px) {
  div.commonPage div.bottom div.itemsContainer div.items.rowCount3.portrait:hover:last-child div.showCardContainer div.square {
    transform: translateX(-60px) translateY(-60px) scale(1.2);
  }
}
@media all and (max-width: 980px) {
  div.commonPage div.bottom div.itemsContainer div.items.rowCount3.portrait {
    width: 24%;
    margin-right: 6px;
    margin-bottom: 40px;
  }
  div.commonPage div.bottom div.itemsContainer div.items.rowCount3.portrait:nth-child(5n) {
    margin-right: 6px;
  }
  div.commonPage div.bottom div.itemsContainer div.items.rowCount3.portrait:nth-child(4n) {
    margin-right: 0;
  }
  div.commonPage div.bottom div.itemsContainer div.items.rowCount3.portrait:nth-child(6n) {
    margin-right: 6px;
  }
}
@media all and (max-width: 768px) {
  div.commonPage div.bottom div.itemsContainer div.items.rowCount3.portrait {
    width: 32%;
  }
  div.commonPage div.bottom div.itemsContainer div.items.rowCount3.portrait:nth-child(4n) {
    margin-right: 6px;
  }
  div.commonPage div.bottom div.itemsContainer div.items.rowCount3.portrait:nth-child(3n) {
    margin-right: 0;
  }
}
@media all and (max-width: 640px) {
  div.commonPage div.bottom div.itemsContainer div.items.rowCount3.portrait {
    width: 48%;
  }
  div.commonPage div.bottom div.itemsContainer div.items.rowCount3.portrait:nth-child(3n) {
    margin-right: 6px;
  }
  div.commonPage div.bottom div.itemsContainer div.items.rowCount3.portrait:nth-child(2n) {
    margin-right: 0;
  }
}
div.commonPage div.bottom div.itemsContainer div.items.rowCount4 {
  width: 24%;
  margin-right: 13px;
  margin-bottom: 30px;
}
div.commonPage div.bottom div.itemsContainer div.items.rowCount4:nth-child(4n) {
  margin-right: 0;
}
@media all and (max-width: 1200px) {
  div.commonPage div.bottom div.itemsContainer div.items.rowCount4 {
    width: 23.8%;
  }
}
@media all and (min-width: 981px) {
  div.commonPage div.bottom div.itemsContainer div.items.rowCount4.landscape:hover div.showCardContainer .square {
    position: absolute;
    z-index: 9999999;
    transform: translateX(60px) translateY(-60px) scale(1.1);
  }
  div.commonPage div.bottom div.itemsContainer div.items.rowCount4.landscape:hover div.showCardContainer .square div.metaData {
    display: block;
    visibility: visible !important;
    animation: fadeIn 0.3s ease-in-out;
  }
  div.commonPage div.bottom div.itemsContainer div.items.rowCount4.landscape:hover:nth-child(4n) div.showCardContainer div.square {
    transform: translateX(-60px) translateY(-60px) scale(1.1);
  }
}
@media all and (max-width: 980px) {
  div.commonPage div.bottom div.itemsContainer div.items.rowCount4.landscape {
    width: 24.3%;
    margin-right: 6px;
    margin-bottom: 40px;
  }
  div.commonPage div.bottom div.itemsContainer div.items.rowCount4.landscape:nth-child(6n) {
    margin-right: 6px;
  }
  div.commonPage div.bottom div.itemsContainer div.items.rowCount4.landscape:nth-child(4n) {
    margin-right: 0;
  }
}
@media all and (max-width: 768px) {
  div.commonPage div.bottom div.itemsContainer div.items.rowCount4.landscape {
    width: 32.3%;
  }
  div.commonPage div.bottom div.itemsContainer div.items.rowCount4.landscape:nth-child(4n) {
    margin-right: 6px;
  }
  div.commonPage div.bottom div.itemsContainer div.items.rowCount4.landscape:nth-child(3n) {
    margin-right: 0;
  }
}
@media all and (max-width: 640px) {
  div.commonPage div.bottom div.itemsContainer div.items.rowCount4.landscape {
    width: 48.2%;
  }
  div.commonPage div.bottom div.itemsContainer div.items.rowCount4.landscape:nth-child(3n) {
    margin-right: 6px;
  }
  div.commonPage div.bottom div.itemsContainer div.items.rowCount4.landscape:nth-child(2n) {
    margin-right: 0;
  }
}
@media all and (max-width: 480px) {
  div.commonPage div.bottom div.itemsContainer div.items.rowCount4.landscape {
    width: 100%;
    margin-right: 0;
  }
  div.commonPage div.bottom div.itemsContainer div.items.rowCount4.landscape:nth-child(6n) {
    margin-right: 0;
  }
  div.commonPage div.bottom div.itemsContainer div.items.rowCount4.landscape:nth-child(4n) {
    margin-right: 0;
  }
  div.commonPage div.bottom div.itemsContainer div.items.rowCount4.landscape:nth-child(3n) {
    margin-right: 0;
  }
  div.commonPage div.bottom div.itemsContainer div.items.rowCount4.landscape:nth-child(2n) {
    margin-right: 0;
  }
}
@media all and (max-width: 480px) and (max-width: 360px) {
  div.commonPage div.bottom div.itemsContainer div.items.rowCount4.landscape {
    width: 100%;
    margin-right: 0;
  }
  div.commonPage div.bottom div.itemsContainer div.items.rowCount4.landscape:nth-child(3n) {
    margin-right: 0;
  }
}
@media all and (min-width: 981px) {
  div.commonPage div.bottom div.itemsContainer div.items.rowCount4.portrait:hover div.showCardContainer .square {
    position: absolute;
    z-index: 9999999;
    transform: translateX(60px) translateY(-60px) scale(1.2);
  }
  div.commonPage div.bottom div.itemsContainer div.items.rowCount4.portrait:hover div.showCardContainer .square div.metaData {
    display: block;
    visibility: visible !important;
    animation: fadeIn 0.3s ease-in-out;
  }
  div.commonPage div.bottom div.itemsContainer div.items.rowCount4.portrait:hover:nth-child(4n) div.showCardContainer div.square {
    transform: translateX(-60px) translateY(-60px) scale(1.2);
  }
}
@media all and (min-width: 981px) and (max-width: 1300px) {
  div.commonPage div.bottom div.itemsContainer div.items.rowCount4.portrait:hover:nth-child(6n) div.showCardContainer div.square {
    transform: translateX(-60px) translateY(-60px) scale(1.2);
  }
  div.commonPage div.bottom div.itemsContainer div.items.rowCount4.portrait:hover:nth-child(7n) div.showCardContainer div.square {
    transform: translateX(60px) translateY(-60px) scale(1.2);
  }
}
@media all and (min-width: 981px) {
  div.commonPage div.bottom div.itemsContainer div.items.rowCount4.portrait:hover:last-child div.showCardContainer div.square {
    transform: translateX(-60px) translateY(-60px) scale(1.2);
  }
}
@media all and (max-width: 980px) {
  div.commonPage div.bottom div.itemsContainer div.items.rowCount4.portrait {
    width: 24%;
    margin-right: 6px;
    margin-bottom: 40px;
  }
  div.commonPage div.bottom div.itemsContainer div.items.rowCount4.portrait:nth-child(5n) {
    margin-right: 6px;
  }
  div.commonPage div.bottom div.itemsContainer div.items.rowCount4.portrait:nth-child(4n) {
    margin-right: 0;
  }
  div.commonPage div.bottom div.itemsContainer div.items.rowCount4.portrait:nth-child(6n) {
    margin-right: 6px;
  }
}
@media all and (max-width: 768px) {
  div.commonPage div.bottom div.itemsContainer div.items.rowCount4.portrait {
    width: 32%;
  }
  div.commonPage div.bottom div.itemsContainer div.items.rowCount4.portrait:nth-child(4n) {
    margin-right: 6px;
  }
  div.commonPage div.bottom div.itemsContainer div.items.rowCount4.portrait:nth-child(3n) {
    margin-right: 0;
  }
}
@media all and (max-width: 640px) {
  div.commonPage div.bottom div.itemsContainer div.items.rowCount4.portrait {
    width: 48%;
  }
  div.commonPage div.bottom div.itemsContainer div.items.rowCount4.portrait:nth-child(3n) {
    margin-right: 6px;
  }
  div.commonPage div.bottom div.itemsContainer div.items.rowCount4.portrait:nth-child(2n) {
    margin-right: 0;
  }
}
div.commonPage div.bottom div.itemsContainer div.items.rowCount5 {
  width: 19%;
  margin-right: 13px;
  margin-bottom: 30px;
}
div.commonPage div.bottom div.itemsContainer div.items.rowCount5:nth-child(5n) {
  margin-right: 0;
}
@media all and (max-width: 1200px) {
  div.commonPage div.bottom div.itemsContainer div.items.rowCount5 {
    width: 18.5%;
  }
}
@media all and (min-width: 981px) {
  div.commonPage div.bottom div.itemsContainer div.items.rowCount5.landscape:hover div.showCardContainer .square {
    position: absolute;
    z-index: 9999999;
    transform: translateX(60px) translateY(-60px) scale(1.1);
  }
  div.commonPage div.bottom div.itemsContainer div.items.rowCount5.landscape:hover div.showCardContainer .square div.metaData {
    display: block;
    visibility: visible !important;
    animation: fadeIn 0.3s ease-in-out;
  }
  div.commonPage div.bottom div.itemsContainer div.items.rowCount5.landscape:hover:nth-child(5n) div.showCardContainer div.square {
    transform: translateX(-60px) translateY(-60px) scale(1.1);
  }
}
@media all and (max-width: 980px) {
  div.commonPage div.bottom div.itemsContainer div.items.rowCount5.landscape {
    width: 24.3%;
    margin-right: 6px;
    margin-bottom: 40px;
  }
  div.commonPage div.bottom div.itemsContainer div.items.rowCount5.landscape:nth-child(6n) {
    margin-right: 6px;
  }
  div.commonPage div.bottom div.itemsContainer div.items.rowCount5.landscape:nth-child(4n) {
    margin-right: 0;
  }
}
@media all and (max-width: 768px) {
  div.commonPage div.bottom div.itemsContainer div.items.rowCount5.landscape {
    width: 32.3%;
  }
  div.commonPage div.bottom div.itemsContainer div.items.rowCount5.landscape:nth-child(4n) {
    margin-right: 6px;
  }
  div.commonPage div.bottom div.itemsContainer div.items.rowCount5.landscape:nth-child(3n) {
    margin-right: 0;
  }
}
@media all and (max-width: 640px) {
  div.commonPage div.bottom div.itemsContainer div.items.rowCount5.landscape {
    width: 48.2%;
  }
  div.commonPage div.bottom div.itemsContainer div.items.rowCount5.landscape:nth-child(3n) {
    margin-right: 6px;
  }
  div.commonPage div.bottom div.itemsContainer div.items.rowCount5.landscape:nth-child(2n) {
    margin-right: 0;
  }
}
@media all and (max-width: 480px) {
  div.commonPage div.bottom div.itemsContainer div.items.rowCount5.landscape {
    width: 100%;
    margin-right: 0;
  }
  div.commonPage div.bottom div.itemsContainer div.items.rowCount5.landscape:nth-child(6n) {
    margin-right: 0;
  }
  div.commonPage div.bottom div.itemsContainer div.items.rowCount5.landscape:nth-child(4n) {
    margin-right: 0;
  }
  div.commonPage div.bottom div.itemsContainer div.items.rowCount5.landscape:nth-child(3n) {
    margin-right: 0;
  }
  div.commonPage div.bottom div.itemsContainer div.items.rowCount5.landscape:nth-child(2n) {
    margin-right: 0;
  }
}
@media all and (max-width: 480px) and (max-width: 360px) {
  div.commonPage div.bottom div.itemsContainer div.items.rowCount5.landscape {
    width: 100%;
    margin-right: 0;
  }
  div.commonPage div.bottom div.itemsContainer div.items.rowCount5.landscape:nth-child(3n) {
    margin-right: 0;
  }
}
@media all and (min-width: 981px) {
  div.commonPage div.bottom div.itemsContainer div.items.rowCount5.portrait:hover div.showCardContainer .square {
    position: absolute;
    z-index: 9999999;
    transform: translateX(60px) translateY(-60px) scale(1.2);
  }
  div.commonPage div.bottom div.itemsContainer div.items.rowCount5.portrait:hover div.showCardContainer .square div.metaData {
    display: block;
    visibility: visible !important;
    animation: fadeIn 0.3s ease-in-out;
  }
  div.commonPage div.bottom div.itemsContainer div.items.rowCount5.portrait:hover:nth-child(5n) div.showCardContainer div.square {
    transform: translateX(-60px) translateY(-60px) scale(1.2);
  }
}
@media all and (min-width: 981px) and (max-width: 1300px) {
  div.commonPage div.bottom div.itemsContainer div.items.rowCount5.portrait:hover:nth-child(6n) div.showCardContainer div.square {
    transform: translateX(-60px) translateY(-60px) scale(1.2);
  }
  div.commonPage div.bottom div.itemsContainer div.items.rowCount5.portrait:hover:nth-child(7n) div.showCardContainer div.square {
    transform: translateX(60px) translateY(-60px) scale(1.2);
  }
}
@media all and (min-width: 981px) {
  div.commonPage div.bottom div.itemsContainer div.items.rowCount5.portrait:hover:last-child div.showCardContainer div.square {
    transform: translateX(-60px) translateY(-60px) scale(1.2);
  }
}
@media all and (max-width: 980px) {
  div.commonPage div.bottom div.itemsContainer div.items.rowCount5.portrait {
    width: 24%;
    margin-right: 6px;
    margin-bottom: 40px;
  }
  div.commonPage div.bottom div.itemsContainer div.items.rowCount5.portrait:nth-child(5n) {
    margin-right: 6px;
  }
  div.commonPage div.bottom div.itemsContainer div.items.rowCount5.portrait:nth-child(4n) {
    margin-right: 0;
  }
  div.commonPage div.bottom div.itemsContainer div.items.rowCount5.portrait:nth-child(6n) {
    margin-right: 6px;
  }
}
@media all and (max-width: 768px) {
  div.commonPage div.bottom div.itemsContainer div.items.rowCount5.portrait {
    width: 32%;
  }
  div.commonPage div.bottom div.itemsContainer div.items.rowCount5.portrait:nth-child(4n) {
    margin-right: 6px;
  }
  div.commonPage div.bottom div.itemsContainer div.items.rowCount5.portrait:nth-child(3n) {
    margin-right: 0;
  }
}
@media all and (max-width: 640px) {
  div.commonPage div.bottom div.itemsContainer div.items.rowCount5.portrait {
    width: 48%;
  }
  div.commonPage div.bottom div.itemsContainer div.items.rowCount5.portrait:nth-child(3n) {
    margin-right: 6px;
  }
  div.commonPage div.bottom div.itemsContainer div.items.rowCount5.portrait:nth-child(2n) {
    margin-right: 0;
  }
}
div.commonPage div.bottom div.itemsContainer div.items.rowCount6 {
  width: 15.8%;
  margin-right: 13px;
  margin-bottom: 30px;
}
div.commonPage div.bottom div.itemsContainer div.items.rowCount6:nth-child(6n) {
  margin-right: 0;
}
@media all and (max-width: 1400px) {
  div.commonPage div.bottom div.itemsContainer div.items.rowCount6 {
    width: 15.5%;
  }
}
@media all and (max-width: 1200px) {
  div.commonPage div.bottom div.itemsContainer div.items.rowCount6 {
    width: 15%;
  }
}
@media all and (min-width: 981px) {
  div.commonPage div.bottom div.itemsContainer div.items.rowCount6.landscape:hover div.showCardContainer .square {
    position: absolute;
    z-index: 9999999;
    transform: translateX(60px) translateY(-60px) scale(1.1);
  }
  div.commonPage div.bottom div.itemsContainer div.items.rowCount6.landscape:hover div.showCardContainer .square div.metaData {
    display: block;
    visibility: visible !important;
    animation: fadeIn 0.3s ease-in-out;
  }
  div.commonPage div.bottom div.itemsContainer div.items.rowCount6.landscape:hover:nth-child(6n) div.showCardContainer div.square {
    transform: translateX(-60px) translateY(-60px) scale(1.1);
  }
}
@media all and (max-width: 980px) {
  div.commonPage div.bottom div.itemsContainer div.items.rowCount6.landscape {
    width: 24.3%;
    margin-right: 6px;
    margin-bottom: 40px;
  }
  div.commonPage div.bottom div.itemsContainer div.items.rowCount6.landscape:nth-child(6n) {
    margin-right: 6px;
  }
  div.commonPage div.bottom div.itemsContainer div.items.rowCount6.landscape:nth-child(4n) {
    margin-right: 0;
  }
}
@media all and (max-width: 768px) {
  div.commonPage div.bottom div.itemsContainer div.items.rowCount6.landscape {
    width: 32.3%;
  }
  div.commonPage div.bottom div.itemsContainer div.items.rowCount6.landscape:nth-child(4n) {
    margin-right: 6px;
  }
  div.commonPage div.bottom div.itemsContainer div.items.rowCount6.landscape:nth-child(3n) {
    margin-right: 0;
  }
}
@media all and (max-width: 640px) {
  div.commonPage div.bottom div.itemsContainer div.items.rowCount6.landscape {
    width: 48.2%;
  }
  div.commonPage div.bottom div.itemsContainer div.items.rowCount6.landscape:nth-child(3n) {
    margin-right: 6px;
  }
  div.commonPage div.bottom div.itemsContainer div.items.rowCount6.landscape:nth-child(2n) {
    margin-right: 0;
  }
}
@media all and (max-width: 480px) {
  div.commonPage div.bottom div.itemsContainer div.items.rowCount6.landscape {
    width: 100%;
    margin-right: 0;
  }
  div.commonPage div.bottom div.itemsContainer div.items.rowCount6.landscape:nth-child(6n) {
    margin-right: 0;
  }
  div.commonPage div.bottom div.itemsContainer div.items.rowCount6.landscape:nth-child(4n) {
    margin-right: 0;
  }
  div.commonPage div.bottom div.itemsContainer div.items.rowCount6.landscape:nth-child(3n) {
    margin-right: 0;
  }
  div.commonPage div.bottom div.itemsContainer div.items.rowCount6.landscape:nth-child(2n) {
    margin-right: 0;
  }
}
@media all and (max-width: 480px) and (max-width: 360px) {
  div.commonPage div.bottom div.itemsContainer div.items.rowCount6.landscape {
    width: 100%;
    margin-right: 0;
  }
  div.commonPage div.bottom div.itemsContainer div.items.rowCount6.landscape:nth-child(3n) {
    margin-right: 0;
  }
}
@media all and (min-width: 981px) {
  div.commonPage div.bottom div.itemsContainer div.items.rowCount6.portrait:hover div.showCardContainer .square {
    position: absolute;
    z-index: 9999999;
    transform: translateX(60px) translateY(-60px) scale(1.2);
  }
  div.commonPage div.bottom div.itemsContainer div.items.rowCount6.portrait:hover div.showCardContainer .square div.metaData {
    display: block;
    visibility: visible !important;
    animation: fadeIn 0.3s ease-in-out;
  }
  div.commonPage div.bottom div.itemsContainer div.items.rowCount6.portrait:hover:nth-child(6n) div.showCardContainer div.square {
    transform: translateX(-60px) translateY(-60px) scale(1.2);
  }
}
@media all and (min-width: 981px) and (max-width: 1300px) {
  div.commonPage div.bottom div.itemsContainer div.items.rowCount6.portrait:hover:nth-child(6n) div.showCardContainer div.square {
    transform: translateX(-60px) translateY(-60px) scale(1.2);
  }
  div.commonPage div.bottom div.itemsContainer div.items.rowCount6.portrait:hover:nth-child(7n) div.showCardContainer div.square {
    transform: translateX(60px) translateY(-60px) scale(1.2);
  }
}
@media all and (min-width: 981px) {
  div.commonPage div.bottom div.itemsContainer div.items.rowCount6.portrait:hover:last-child div.showCardContainer div.square {
    transform: translateX(-60px) translateY(-60px) scale(1.2);
  }
}
@media all and (max-width: 980px) {
  div.commonPage div.bottom div.itemsContainer div.items.rowCount6.portrait {
    width: 24%;
    margin-right: 6px;
    margin-bottom: 40px;
  }
  div.commonPage div.bottom div.itemsContainer div.items.rowCount6.portrait:nth-child(5n) {
    margin-right: 6px;
  }
  div.commonPage div.bottom div.itemsContainer div.items.rowCount6.portrait:nth-child(4n) {
    margin-right: 0;
  }
  div.commonPage div.bottom div.itemsContainer div.items.rowCount6.portrait:nth-child(6n) {
    margin-right: 6px;
  }
}
@media all and (max-width: 768px) {
  div.commonPage div.bottom div.itemsContainer div.items.rowCount6.portrait {
    width: 32%;
  }
  div.commonPage div.bottom div.itemsContainer div.items.rowCount6.portrait:nth-child(4n) {
    margin-right: 6px;
  }
  div.commonPage div.bottom div.itemsContainer div.items.rowCount6.portrait:nth-child(3n) {
    margin-right: 0;
  }
}
@media all and (max-width: 640px) {
  div.commonPage div.bottom div.itemsContainer div.items.rowCount6.portrait {
    width: 48%;
  }
  div.commonPage div.bottom div.itemsContainer div.items.rowCount6.portrait:nth-child(3n) {
    margin-right: 6px;
  }
  div.commonPage div.bottom div.itemsContainer div.items.rowCount6.portrait:nth-child(2n) {
    margin-right: 0;
  }
}
div.commonPage div.bottom div.itemsContainer div.items.rowCount7 {
  width: 13.9%;
  margin-right: 6px;
  margin-bottom: 30px;
}
div.commonPage div.bottom div.itemsContainer div.items.rowCount7:nth-child(7n) {
  margin-right: 0;
}
@media all and (max-width: 1200px) {
  div.commonPage div.bottom div.itemsContainer div.items.rowCount7 {
    width: 19.3%;
  }
}
@media all and (max-width: 2600px) {
  div.commonPage div.bottom div.itemsContainer div.items.rowCount7.portrait {
    width: 14%;
    margin-right: 6.5px;
  }
}
@media all and (max-width: 2500px) {
  div.commonPage div.bottom div.itemsContainer div.items.rowCount7.portrait {
    width: 14%;
    margin-right: 5.5px;
  }
}
@media all and (max-width: 2200px) {
  div.commonPage div.bottom div.itemsContainer div.items.rowCount7.portrait {
    width: 13.9%;
    margin-right: 6.5px;
  }
}
@media all and (max-width: 1980px) {
  div.commonPage div.bottom div.itemsContainer div.items.rowCount7.portrait {
    width: 14%;
    margin-right: 4.5px;
  }
}
@media all and (max-width: 1768px) {
  div.commonPage div.bottom div.itemsContainer div.items.rowCount7.portrait {
    width: 14%;
    margin-right: 4px;
  }
}
@media all and (max-width: 1600px) {
  div.commonPage div.bottom div.itemsContainer div.items.rowCount7.portrait {
    width: 13.7%;
    margin-right: 7.5px;
  }
}
@media all and (max-width: 1400px) {
  div.commonPage div.bottom div.itemsContainer div.items.rowCount7.portrait {
    width: 14%;
    margin-right: 3px;
  }
}
@media all and (max-width: 1200px) {
  div.commonPage div.bottom div.itemsContainer div.items.rowCount7.portrait {
    width: 13.6%;
    margin-right: 6.1px;
  }
}
@media all and (min-width: 981px) {
  div.commonPage div.bottom div.itemsContainer div.items.rowCount7.portrait:hover div.showCardContainer .square {
    position: absolute;
    z-index: 9999999;
    transform: translateX(60px) translateY(-60px) scale(1.2);
  }
  div.commonPage div.bottom div.itemsContainer div.items.rowCount7.portrait:hover div.showCardContainer .square div.metaData {
    display: block;
    visibility: visible !important;
    animation: fadeIn 0.3s ease-in-out;
  }
  div.commonPage div.bottom div.itemsContainer div.items.rowCount7.portrait:hover:nth-child(7n) div.showCardContainer div.square {
    transform: translateX(-60px) translateY(-60px) scale(1.2);
  }
}
@media all and (min-width: 981px) and (max-width: 1300px) {
  div.commonPage div.bottom div.itemsContainer div.items.rowCount7.portrait:hover:nth-child(6n) div.showCardContainer div.square {
    transform: translateX(-60px) translateY(-60px) scale(1.2);
  }
  div.commonPage div.bottom div.itemsContainer div.items.rowCount7.portrait:hover:nth-child(7n) div.showCardContainer div.square {
    transform: translateX(60px) translateY(-60px) scale(1.2);
  }
}
@media all and (min-width: 981px) {
  div.commonPage div.bottom div.itemsContainer div.items.rowCount7.portrait:hover:last-child div.showCardContainer div.square {
    transform: translateX(-60px) translateY(-60px) scale(1.2);
  }
}
@media all and (max-width: 980px) {
  div.commonPage div.bottom div.itemsContainer div.items.rowCount7.portrait {
    width: 24%;
    margin-right: 6px;
    margin-bottom: 40px;
  }
  div.commonPage div.bottom div.itemsContainer div.items.rowCount7.portrait:nth-child(5n) {
    margin-right: 6px;
  }
  div.commonPage div.bottom div.itemsContainer div.items.rowCount7.portrait:nth-child(4n) {
    margin-right: 0;
  }
  div.commonPage div.bottom div.itemsContainer div.items.rowCount7.portrait:nth-child(6n) {
    margin-right: 6px;
  }
}
@media all and (max-width: 768px) {
  div.commonPage div.bottom div.itemsContainer div.items.rowCount7.portrait {
    width: 32%;
  }
  div.commonPage div.bottom div.itemsContainer div.items.rowCount7.portrait:nth-child(4n) {
    margin-right: 6px;
  }
  div.commonPage div.bottom div.itemsContainer div.items.rowCount7.portrait:nth-child(3n) {
    margin-right: 0;
  }
}
@media all and (max-width: 640px) {
  div.commonPage div.bottom div.itemsContainer div.items.rowCount7.portrait {
    width: 48%;
  }
  div.commonPage div.bottom div.itemsContainer div.items.rowCount7.portrait:nth-child(3n) {
    margin-right: 6px;
  }
  div.commonPage div.bottom div.itemsContainer div.items.rowCount7.portrait:nth-child(2n) {
    margin-right: 0;
  }
}
div.commonPage div.bottom div.itemsContainer div.items.rowCount8 {
  width: 12%;
  margin-right: 8px;
  margin-bottom: 30px;
}
div.commonPage div.bottom div.itemsContainer div.items.rowCount8:nth-child(8n) {
  margin-right: 0;
}
@media all and (max-width: 1200px) {
  div.commonPage div.bottom div.itemsContainer div.items.rowCount8 {
    width: 12%;
    margin-right: 6px;
  }
}
@media all and (min-width: 981px) {
  div.commonPage div.bottom div.itemsContainer div.items.rowCount8.landscape:hover div.showCardContainer .square {
    position: absolute;
    z-index: 9999999;
    transform: translateX(60px) translateY(-60px) scale(1.1);
  }
  div.commonPage div.bottom div.itemsContainer div.items.rowCount8.landscape:hover div.showCardContainer .square div.metaData {
    display: block;
    visibility: visible !important;
    animation: fadeIn 0.3s ease-in-out;
  }
  div.commonPage div.bottom div.itemsContainer div.items.rowCount8.landscape:hover:nth-child(5n) div.showCardContainer div.square {
    transform: translateX(-60px) translateY(-60px) scale(1.1);
  }
}
@media all and (max-width: 980px) {
  div.commonPage div.bottom div.itemsContainer div.items.rowCount8.landscape {
    width: 24.3%;
    margin-right: 6px;
    margin-bottom: 40px;
  }
  div.commonPage div.bottom div.itemsContainer div.items.rowCount8.landscape:nth-child(6n) {
    margin-right: 6px;
  }
  div.commonPage div.bottom div.itemsContainer div.items.rowCount8.landscape:nth-child(4n) {
    margin-right: 0;
  }
}
@media all and (max-width: 768px) {
  div.commonPage div.bottom div.itemsContainer div.items.rowCount8.landscape {
    width: 32.3%;
  }
  div.commonPage div.bottom div.itemsContainer div.items.rowCount8.landscape:nth-child(4n) {
    margin-right: 6px;
  }
  div.commonPage div.bottom div.itemsContainer div.items.rowCount8.landscape:nth-child(3n) {
    margin-right: 0;
  }
}
@media all and (max-width: 640px) {
  div.commonPage div.bottom div.itemsContainer div.items.rowCount8.landscape {
    width: 48.2%;
  }
  div.commonPage div.bottom div.itemsContainer div.items.rowCount8.landscape:nth-child(3n) {
    margin-right: 6px;
  }
  div.commonPage div.bottom div.itemsContainer div.items.rowCount8.landscape:nth-child(2n) {
    margin-right: 0;
  }
}
@media all and (max-width: 480px) {
  div.commonPage div.bottom div.itemsContainer div.items.rowCount8.landscape {
    width: 100%;
    margin-right: 0;
  }
  div.commonPage div.bottom div.itemsContainer div.items.rowCount8.landscape:nth-child(6n) {
    margin-right: 0;
  }
  div.commonPage div.bottom div.itemsContainer div.items.rowCount8.landscape:nth-child(4n) {
    margin-right: 0;
  }
  div.commonPage div.bottom div.itemsContainer div.items.rowCount8.landscape:nth-child(3n) {
    margin-right: 0;
  }
  div.commonPage div.bottom div.itemsContainer div.items.rowCount8.landscape:nth-child(2n) {
    margin-right: 0;
  }
}
@media all and (max-width: 480px) and (max-width: 360px) {
  div.commonPage div.bottom div.itemsContainer div.items.rowCount8.landscape {
    width: 100%;
    margin-right: 0;
  }
  div.commonPage div.bottom div.itemsContainer div.items.rowCount8.landscape:nth-child(3n) {
    margin-right: 0;
  }
}
@media all and (max-width: 2600px) {
  div.commonPage div.bottom div.itemsContainer div.items.rowCount8.portrait {
    width: 12.1%;
    margin-right: 10px;
  }
  div.commonPage div.bottom div.itemsContainer div.items.rowCount8.portrait:nth-child(8n) {
    margin-right: 0px;
  }
}
@media all and (max-width: 2500px) {
  div.commonPage div.bottom div.itemsContainer div.items.rowCount8.portrait {
    width: 12%;
    margin-right: 11px;
  }
}
@media all and (max-width: 2200px) {
  div.commonPage div.bottom div.itemsContainer div.items.rowCount8.portrait {
    width: 12.2%;
    margin-right: 6px;
  }
}
@media all and (max-width: 1980px) {
  div.commonPage div.bottom div.itemsContainer div.items.rowCount8.portrait {
    width: 12%;
    margin-right: 9px;
  }
}
@media all and (max-width: 1768px) {
  div.commonPage div.bottom div.itemsContainer div.items.rowCount8.portrait {
    width: 11.9%;
    margin-right: 10px;
  }
}
@media all and (max-width: 1600px) {
  div.commonPage div.bottom div.itemsContainer div.items.rowCount8.portrait {
    width: 12%;
    margin-right: 7px;
  }
}
@media all and (max-width: 1400px) {
  div.commonPage div.bottom div.itemsContainer div.items.rowCount8.portrait {
    width: 12%;
    margin-right: 6px;
  }
}
@media all and (max-width: 1200px) {
  div.commonPage div.bottom div.itemsContainer div.items.rowCount8.portrait {
    width: 12%;
    margin-right: 5px;
  }
}
@media all and (min-width: 981px) {
  div.commonPage div.bottom div.itemsContainer div.items.rowCount8.portrait:hover div.showCardContainer .square {
    position: absolute;
    z-index: 9999999;
    transform: translateX(60px) translateY(-60px) scale(1.2);
  }
  div.commonPage div.bottom div.itemsContainer div.items.rowCount8.portrait:hover div.showCardContainer .square div.metaData {
    display: block;
    visibility: visible !important;
    animation: fadeIn 0.3s ease-in-out;
  }
  div.commonPage div.bottom div.itemsContainer div.items.rowCount8.portrait:hover:nth-child(8n) div.showCardContainer div.square {
    transform: translateX(-60px) translateY(-60px) scale(1.2);
  }
}
@media all and (max-width: 980px) {
  div.commonPage div.bottom div.itemsContainer div.items.rowCount8.portrait {
    width: 24%;
    margin-right: 6px;
    margin-bottom: 40px;
  }
}
@media all and (max-width: 768px) {
  div.commonPage div.bottom div.itemsContainer div.items.rowCount8.portrait {
    width: 32%;
  }
  div.commonPage div.bottom div.itemsContainer div.items.rowCount8.portrait:nth-child(4n) {
    margin-right: 6px;
  }
  div.commonPage div.bottom div.itemsContainer div.items.rowCount8.portrait:nth-child(3n) {
    margin-right: 0;
  }
}
@media all and (max-width: 640px) {
  div.commonPage div.bottom div.itemsContainer div.items.rowCount8.portrait {
    width: 48%;
  }
  div.commonPage div.bottom div.itemsContainer div.items.rowCount8.portrait:nth-child(3n) {
    margin-right: 6px;
  }
  div.commonPage div.bottom div.itemsContainer div.items.rowCount8.portrait:nth-child(2n) {
    margin-right: 0;
  }
}
@media all and (max-width: 980px) {
  div.commonPage div.bottom div.itemsContainer div.items {
    width: 24%;
    margin-right: 6px;
    margin-bottom: 40px;
  }
  div.commonPage div.bottom div.itemsContainer div.items:nth-child(5n) {
    margin-right: 6px;
  }
  div.commonPage div.bottom div.itemsContainer div.items:nth-child(4n) {
    margin-right: 0;
  }
  div.commonPage div.bottom div.itemsContainer div.items:nth-child(6n) {
    margin-right: 6px;
  }
}
@media all and (max-width: 640px) {
  div.commonPage div.bottom div.itemsContainer div.items {
    width: 48%;
  }
  div.commonPage div.bottom div.itemsContainer div.items:nth-child(3n) {
    margin-right: 6px;
  }
  div.commonPage div.bottom div.itemsContainer div.items:nth-child(2n) {
    margin-right: 0;
  }
}
@media all and (max-width: 980px) {
  div.commonPage div.bottom div.itemsContainer div.items.portrait {
    width: 23.5%;
    margin-right: 13px;
    margin-bottom: 40px;
  }
  div.commonPage div.bottom div.itemsContainer div.items.portrait:nth-child(4n) {
    margin-right: 0;
  }
  div.commonPage div.bottom div.itemsContainer div.items.portrait:nth-child(5n) {
    margin-right: 13px;
  }
}
@media all and (max-width: 768px) {
  div.commonPage div.bottom div.itemsContainer div.items.portrait {
    width: 23%;
  }
  div.commonPage div.bottom div.itemsContainer div.items.portrait:nth-child(4n) {
    margin-right: 0;
  }
  div.commonPage div.bottom div.itemsContainer div.items.portrait:nth-child(5n) {
    margin-right: 13px;
  }
}
@media all and (max-width: 640px) {
  div.commonPage div.bottom div.itemsContainer div.items.portrait {
    width: 31%;
  }
  div.commonPage div.bottom div.itemsContainer div.items.portrait:nth-child(4n) {
    margin-right: 13px;
  }
  div.commonPage div.bottom div.itemsContainer div.items.portrait:nth-child(3n) {
    margin-right: 0;
  }
}
@media all and (max-width: 480px) {
  div.commonPage div.bottom div.itemsContainer div.items.portrait {
    width: 47%;
  }
  div.commonPage div.bottom div.itemsContainer div.items.portrait:nth-child(3n) {
    margin-right: 13px;
  }
  div.commonPage div.bottom div.itemsContainer div.items.portrait:nth-child(2n) {
    margin-right: 0;
  }
}
@media all and (max-width: 980px) {
  div.commonPage div.bottom div.itemsContainer div.items.landscape {
    width: 32%;
    margin-right: 13px;
    margin-bottom: 40px;
  }
  div.commonPage div.bottom div.itemsContainer div.items.landscape:nth-child(3n) {
    margin-right: 0;
  }
}
@media all and (max-width: 768px) {
  div.commonPage div.bottom div.itemsContainer div.items.landscape {
    width: 47.6%;
  }
  div.commonPage div.bottom div.itemsContainer div.items.landscape:nth-child(2n) {
    margin-right: 0;
  }
  div.commonPage div.bottom div.itemsContainer div.items.landscape:nth-child(3n) {
    margin-right: 13px;
  }
}
@media all and (max-width: 640px) {
  div.commonPage div.bottom div.itemsContainer div.items.landscape {
    width: 48%;
  }
  div.commonPage div.bottom div.itemsContainer div.items.landscape:nth-child(3n) {
    margin-right: 13px;
  }
  div.commonPage div.bottom div.itemsContainer div.items.landscape:nth-child(2n) {
    margin-right: 0;
  }
}
@media all and (max-width: 480px) {
  div.commonPage div.bottom div.itemsContainer div.items.landscape {
    width: 47%;
  }
  div.commonPage div.bottom div.itemsContainer div.items.landscape:nth-child(3n) {
    margin-right: 13px;
  }
  div.commonPage div.bottom div.itemsContainer div.items.landscape:nth-child(2n) {
    margin-right: 0;
  }
}

div.commonPage.rtl .top {
  left: 0;
  right: 60px;
}
div.commonPage.rtl .bottom .itemsContainer .items.rowCount3 {
  margin-right: 0;
  margin-left: 13px;
}
div.commonPage.rtl .bottom .itemsContainer .items.rowCount3:nth-child(3n) {
  margin-left: 0;
}
@media all and (min-width: 981px) {
  div.commonPage.rtl .bottom .itemsContainer .items.rowCount3.landscape:hover div.showCardContainer .square {
    transform: translateX(-60px) translateY(-60px) scale(1.1);
  }
  div.commonPage.rtl .bottom .itemsContainer .items.rowCount3.landscape:hover:nth-child(3n) div.showCardContainer div.square {
    transform: translateX(60px) translateY(-60px) scale(1.1);
  }
}
@media all and (max-width: 980px) {
  div.commonPage.rtl .bottom .itemsContainer .items.rowCount3.landscape {
    margin-right: 0;
    margin-left: 6px;
  }
  div.commonPage.rtl .bottom .itemsContainer .items.rowCount3.landscape:nth-child(6n) {
    margin-right: 0;
    margin-left: 6px;
  }
  div.commonPage.rtl .bottom .itemsContainer .items.rowCount3.landscape:nth-child(4n) {
    margin-left: 0;
  }
}
@media all and (max-width: 768px) {
  div.commonPage.rtl .bottom .itemsContainer .items.rowCount3.landscape:nth-child(4n) {
    margin-right: 0;
    margin-left: 6px;
  }
  div.commonPage.rtl .bottom .itemsContainer .items.rowCount3.landscape:nth-child(3n) {
    margin-left: 0;
  }
}
@media all and (max-width: 640px) {
  div.commonPage.rtl .bottom .itemsContainer .items.rowCount3.landscape:nth-child(3n) {
    margin-right: 0;
    margin-left: 6px;
  }
  div.commonPage.rtl .bottom .itemsContainer .items.rowCount3.landscape:nth-child(2n) {
    margin-left: 0;
  }
}
@media all and (max-width: 480px) {
  div.commonPage.rtl .bottom .itemsContainer .items.rowCount3.landscape {
    margin-left: 0;
  }
  div.commonPage.rtl .bottom .itemsContainer .items.rowCount3.landscape:nth-child(6n) {
    margin-left: 0;
  }
  div.commonPage.rtl .bottom .itemsContainer .items.rowCount3.landscape:nth-child(4n) {
    margin-left: 0;
  }
  div.commonPage.rtl .bottom .itemsContainer .items.rowCount3.landscape:nth-child(3n) {
    margin-left: 0;
  }
  div.commonPage.rtl .bottom .itemsContainer .items.rowCount3.landscape:nth-child(2n) {
    margin-left: 0;
  }
}
@media all and (max-width: 480px) and (max-width: 360px) {
  div.commonPage.rtl .bottom .itemsContainer .items.rowCount3.landscape {
    margin-left: 0;
  }
  div.commonPage.rtl .bottom .itemsContainer .items.rowCount3.landscape:nth-child(3n) {
    margin-left: 0;
  }
}
@media all and (min-width: 981px) {
  div.commonPage.rtl .bottom .itemsContainer .items.rowCount3.portrait:hover div.showCardContainer .square {
    transform: translateX(-60px) translateY(-60px) scale(1.2);
  }
  div.commonPage.rtl .bottom .itemsContainer .items.rowCount3.portrait:hover:nth-child(3n) div.showCardContainer div.square {
    transform: translateX(60px) translateY(-60px) scale(1.2);
  }
}
@media all and (min-width: 981px) and (max-width: 1300px) {
  div.commonPage.rtl .bottom .itemsContainer .items.rowCount3.portrait:hover:nth-child(6n) div.showCardContainer div.square {
    transform: translateX(60px) translateY(-60px) scale(1.2);
  }
  div.commonPage.rtl .bottom .itemsContainer .items.rowCount3.portrait:hover:nth-child(7n) div.showCardContainer div.square {
    transform: translateX(-60px) translateY(-60px) scale(1.2);
  }
}
@media all and (min-width: 981px) {
  div.commonPage.rtl .bottom .itemsContainer .items.rowCount3.portrait:hover:last-child div.showCardContainer div.square {
    transform: translateX(60px) translateY(-60px) scale(1.2);
  }
}
@media all and (max-width: 980px) {
  div.commonPage.rtl .bottom .itemsContainer .items.rowCount3.portrait {
    margin-right: 0;
    margin-left: 6px;
  }
  div.commonPage.rtl .bottom .itemsContainer .items.rowCount3.portrait:nth-child(5n) {
    margin-right: 0;
    margin-left: 6px;
  }
  div.commonPage.rtl .bottom .itemsContainer .items.rowCount3.portrait:nth-child(4n) {
    margin-left: 0;
  }
  div.commonPage.rtl .bottom .itemsContainer .items.rowCount3.portrait:nth-child(6n) {
    margin-right: 0;
    margin-left: 6px;
  }
}
@media all and (max-width: 768px) {
  div.commonPage.rtl .bottom .itemsContainer .items.rowCount3.portrait:nth-child(4n) {
    margin-right: 0;
    margin-left: 6px;
  }
  div.commonPage.rtl .bottom .itemsContainer .items.rowCount3.portrait:nth-child(3n) {
    margin-left: 0;
  }
}
@media all and (max-width: 640px) {
  div.commonPage.rtl .bottom .itemsContainer .items.rowCount3.portrait:nth-child(3n) {
    margin-right: 0;
    margin-left: 6px;
  }
  div.commonPage.rtl .bottom .itemsContainer .items.rowCount3.portrait:nth-child(2n) {
    margin-left: 0;
  }
}
div.commonPage.rtl .bottom .itemsContainer .items.rowCount4 {
  margin-right: 0;
  margin-left: 13px;
}
div.commonPage.rtl .bottom .itemsContainer .items.rowCount4:nth-child(4n) {
  margin-left: 0;
}
@media all and (min-width: 981px) {
  div.commonPage.rtl .bottom .itemsContainer .items.rowCount4.landscape:hover div.showCardContainer .square {
    transform: translateX(-60px) translateY(-60px) scale(1.1);
  }
  div.commonPage.rtl .bottom .itemsContainer .items.rowCount4.landscape:hover:nth-child(4n) div.showCardContainer div.square {
    transform: translateX(60px) translateY(-60px) scale(1.1);
  }
}
@media all and (max-width: 980px) {
  div.commonPage.rtl .bottom .itemsContainer .items.rowCount4.landscape {
    margin-right: 0;
    margin-left: 6px;
  }
  div.commonPage.rtl .bottom .itemsContainer .items.rowCount4.landscape:nth-child(6n) {
    margin-right: 0;
    margin-left: 6px;
  }
  div.commonPage.rtl .bottom .itemsContainer .items.rowCount4.landscape:nth-child(4n) {
    margin-left: 0;
  }
}
@media all and (max-width: 768px) {
  div.commonPage.rtl .bottom .itemsContainer .items.rowCount4.landscape:nth-child(4n) {
    margin-right: 0;
    margin-left: 6px;
  }
  div.commonPage.rtl .bottom .itemsContainer .items.rowCount4.landscape:nth-child(3n) {
    margin-left: 0;
  }
}
@media all and (max-width: 640px) {
  div.commonPage.rtl .bottom .itemsContainer .items.rowCount4.landscape:nth-child(3n) {
    margin-right: 0;
    margin-left: 6px;
  }
  div.commonPage.rtl .bottom .itemsContainer .items.rowCount4.landscape:nth-child(2n) {
    margin-left: 0;
  }
}
@media all and (max-width: 480px) {
  div.commonPage.rtl .bottom .itemsContainer .items.rowCount4.landscape {
    margin-left: 0;
  }
  div.commonPage.rtl .bottom .itemsContainer .items.rowCount4.landscape:nth-child(6n) {
    margin-left: 0;
  }
  div.commonPage.rtl .bottom .itemsContainer .items.rowCount4.landscape:nth-child(4n) {
    margin-left: 0;
  }
  div.commonPage.rtl .bottom .itemsContainer .items.rowCount4.landscape:nth-child(3n) {
    margin-left: 0;
  }
  div.commonPage.rtl .bottom .itemsContainer .items.rowCount4.landscape:nth-child(2n) {
    margin-left: 0;
  }
}
@media all and (max-width: 480px) and (max-width: 360px) {
  div.commonPage.rtl .bottom .itemsContainer .items.rowCount4.landscape {
    margin-left: 0;
  }
  div.commonPage.rtl .bottom .itemsContainer .items.rowCount4.landscape:nth-child(3n) {
    margin-left: 0;
  }
}
@media all and (min-width: 981px) {
  div.commonPage.rtl .bottom .itemsContainer .items.rowCount4.portrait:hover div.showCardContainer .square {
    transform: translateX(-60px) translateY(-60px) scale(1.2);
  }
  div.commonPage.rtl .bottom .itemsContainer .items.rowCount4.portrait:hover:nth-child(4n) div.showCardContainer div.square {
    transform: translateX(60px) translateY(-60px) scale(1.2);
  }
}
@media all and (min-width: 981px) and (max-width: 1300px) {
  div.commonPage.rtl .bottom .itemsContainer .items.rowCount4.portrait:hover:nth-child(6n) div.showCardContainer div.square {
    transform: translateX(60px) translateY(-60px) scale(1.2);
  }
  div.commonPage.rtl .bottom .itemsContainer .items.rowCount4.portrait:hover:nth-child(7n) div.showCardContainer div.square {
    transform: translateX(-60px) translateY(-60px) scale(1.2);
  }
}
@media all and (min-width: 981px) {
  div.commonPage.rtl .bottom .itemsContainer .items.rowCount4.portrait:hover:last-child div.showCardContainer div.square {
    transform: translateX(60px) translateY(-60px) scale(1.2);
  }
}
@media all and (max-width: 980px) {
  div.commonPage.rtl .bottom .itemsContainer .items.rowCount4.portrait {
    margin-right: 0;
    margin-left: 6px;
  }
  div.commonPage.rtl .bottom .itemsContainer .items.rowCount4.portrait:nth-child(5n) {
    margin-right: 0;
    margin-left: 6px;
  }
  div.commonPage.rtl .bottom .itemsContainer .items.rowCount4.portrait:nth-child(4n) {
    margin-left: 0;
  }
  div.commonPage.rtl .bottom .itemsContainer .items.rowCount4.portrait:nth-child(6n) {
    margin-right: 0;
    margin-left: 6px;
  }
}
@media all and (max-width: 768px) {
  div.commonPage.rtl .bottom .itemsContainer .items.rowCount4.portrait:nth-child(4n) {
    margin-right: 0;
    margin-left: 6px;
  }
  div.commonPage.rtl .bottom .itemsContainer .items.rowCount4.portrait:nth-child(3n) {
    margin-left: 0;
  }
}
@media all and (max-width: 640px) {
  div.commonPage.rtl .bottom .itemsContainer .items.rowCount4.portrait:nth-child(3n) {
    margin-right: 0;
    margin-left: 6px;
  }
  div.commonPage.rtl .bottom .itemsContainer .items.rowCount4.portrait:nth-child(2n) {
    margin-left: 0;
  }
}
div.commonPage.rtl .bottom .itemsContainer .items.rowCount5 {
  margin-right: 0;
  margin-left: 13px;
}
div.commonPage.rtl .bottom .itemsContainer .items.rowCount5:nth-child(5n) {
  margin-left: 0;
}
@media all and (min-width: 981px) {
  div.commonPage.rtl .bottom .itemsContainer .items.rowCount5.landscape:hover div.showCardContainer .square {
    transform: translateX(-60px) translateY(-60px) scale(1.1);
  }
  div.commonPage.rtl .bottom .itemsContainer .items.rowCount5.landscape:hover:nth-child(5n) div.showCardContainer div.square {
    transform: translateX(60px) translateY(-60px) scale(1.1);
  }
}
@media all and (max-width: 980px) {
  div.commonPage.rtl .bottom .itemsContainer .items.rowCount5.landscape {
    margin-right: 0;
    margin-left: 6px;
  }
  div.commonPage.rtl .bottom .itemsContainer .items.rowCount5.landscape:nth-child(6n) {
    margin-right: 0;
    margin-left: 6px;
  }
  div.commonPage.rtl .bottom .itemsContainer .items.rowCount5.landscape:nth-child(4n) {
    margin-left: 0;
  }
}
@media all and (max-width: 768px) {
  div.commonPage.rtl .bottom .itemsContainer .items.rowCount5.landscape:nth-child(4n) {
    margin-right: 0;
    margin-left: 6px;
  }
  div.commonPage.rtl .bottom .itemsContainer .items.rowCount5.landscape:nth-child(3n) {
    margin-left: 0;
  }
}
@media all and (max-width: 640px) {
  div.commonPage.rtl .bottom .itemsContainer .items.rowCount5.landscape:nth-child(3n) {
    margin-right: 0;
    margin-left: 6px;
  }
  div.commonPage.rtl .bottom .itemsContainer .items.rowCount5.landscape:nth-child(2n) {
    margin-left: 0;
  }
}
@media all and (max-width: 480px) {
  div.commonPage.rtl .bottom .itemsContainer .items.rowCount5.landscape {
    margin-left: 0;
  }
  div.commonPage.rtl .bottom .itemsContainer .items.rowCount5.landscape:nth-child(6n) {
    margin-left: 0;
  }
  div.commonPage.rtl .bottom .itemsContainer .items.rowCount5.landscape:nth-child(4n) {
    margin-left: 0;
  }
  div.commonPage.rtl .bottom .itemsContainer .items.rowCount5.landscape:nth-child(3n) {
    margin-left: 0;
  }
  div.commonPage.rtl .bottom .itemsContainer .items.rowCount5.landscape:nth-child(2n) {
    margin-left: 0;
  }
}
@media all and (max-width: 480px) and (max-width: 360px) {
  div.commonPage.rtl .bottom .itemsContainer .items.rowCount5.landscape {
    margin-left: 0;
  }
  div.commonPage.rtl .bottom .itemsContainer .items.rowCount5.landscape:nth-child(3n) {
    margin-left: 0;
  }
}
@media all and (min-width: 981px) {
  div.commonPage.rtl .bottom .itemsContainer .items.rowCount5.portrait:hover div.showCardContainer .square {
    transform: translateX(-60px) translateY(-60px) scale(1.2);
  }
  div.commonPage.rtl .bottom .itemsContainer .items.rowCount5.portrait:hover:nth-child(5n) div.showCardContainer div.square {
    transform: translateX(60px) translateY(-60px) scale(1.2);
  }
}
@media all and (min-width: 981px) and (max-width: 1300px) {
  div.commonPage.rtl .bottom .itemsContainer .items.rowCount5.portrait:hover:nth-child(6n) div.showCardContainer div.square {
    transform: translateX(60px) translateY(-60px) scale(1.2);
  }
  div.commonPage.rtl .bottom .itemsContainer .items.rowCount5.portrait:hover:nth-child(7n) div.showCardContainer div.square {
    transform: translateX(-60px) translateY(-60px) scale(1.2);
  }
}
@media all and (min-width: 981px) {
  div.commonPage.rtl .bottom .itemsContainer .items.rowCount5.portrait:hover:last-child div.showCardContainer div.square {
    transform: translateX(60px) translateY(-60px) scale(1.2);
  }
}
@media all and (max-width: 980px) {
  div.commonPage.rtl .bottom .itemsContainer .items.rowCount5.portrait {
    margin-right: 0;
    margin-left: 6px;
  }
  div.commonPage.rtl .bottom .itemsContainer .items.rowCount5.portrait:nth-child(5n) {
    margin-right: 0;
    margin-left: 6px;
  }
  div.commonPage.rtl .bottom .itemsContainer .items.rowCount5.portrait:nth-child(4n) {
    margin-left: 0;
  }
  div.commonPage.rtl .bottom .itemsContainer .items.rowCount5.portrait:nth-child(6n) {
    margin-right: 0;
    margin-left: 6px;
  }
}
@media all and (max-width: 768px) {
  div.commonPage.rtl .bottom .itemsContainer .items.rowCount5.portrait:nth-child(4n) {
    margin-right: 0;
    margin-left: 6px;
  }
  div.commonPage.rtl .bottom .itemsContainer .items.rowCount5.portrait:nth-child(3n) {
    margin-left: 0;
  }
}
@media all and (max-width: 640px) {
  div.commonPage.rtl .bottom .itemsContainer .items.rowCount5.portrait:nth-child(3n) {
    margin-right: 0;
    margin-left: 6px;
  }
  div.commonPage.rtl .bottom .itemsContainer .items.rowCount5.portrait:nth-child(2n) {
    margin-left: 0;
  }
}
div.commonPage.rtl .bottom .itemsContainer .items.rowCount6 {
  margin-right: 0;
  margin-left: 13px;
}
div.commonPage.rtl .bottom .itemsContainer .items.rowCount6:nth-child(6n) {
  margin-left: 0;
}
@media all and (min-width: 981px) {
  div.commonPage.rtl .bottom .itemsContainer .items.rowCount6.landscape:hover div.showCardContainer .square {
    transform: translateX(-60px) translateY(-60px) scale(1.1);
  }
  div.commonPage.rtl .bottom .itemsContainer .items.rowCount6.landscape:hover:nth-child(6n) div.showCardContainer div.square {
    transform: translateX(60px) translateY(-60px) scale(1.1);
  }
}
@media all and (max-width: 980px) {
  div.commonPage.rtl .bottom .itemsContainer .items.rowCount6.landscape {
    margin-right: 0;
    margin-left: 6px;
  }
  div.commonPage.rtl .bottom .itemsContainer .items.rowCount6.landscape:nth-child(6n) {
    margin-right: 0;
    margin-left: 6px;
  }
  div.commonPage.rtl .bottom .itemsContainer .items.rowCount6.landscape:nth-child(4n) {
    margin-left: 0;
  }
}
@media all and (max-width: 768px) {
  div.commonPage.rtl .bottom .itemsContainer .items.rowCount6.landscape:nth-child(4n) {
    margin-right: 0;
    margin-left: 6px;
  }
  div.commonPage.rtl .bottom .itemsContainer .items.rowCount6.landscape:nth-child(3n) {
    margin-left: 0;
  }
}
@media all and (max-width: 640px) {
  div.commonPage.rtl .bottom .itemsContainer .items.rowCount6.landscape:nth-child(3n) {
    margin-right: 0;
    margin-left: 6px;
  }
  div.commonPage.rtl .bottom .itemsContainer .items.rowCount6.landscape:nth-child(2n) {
    margin-left: 0;
  }
}
@media all and (max-width: 480px) {
  div.commonPage.rtl .bottom .itemsContainer .items.rowCount6.landscape {
    margin-left: 0;
  }
  div.commonPage.rtl .bottom .itemsContainer .items.rowCount6.landscape:nth-child(6n) {
    margin-left: 0;
  }
  div.commonPage.rtl .bottom .itemsContainer .items.rowCount6.landscape:nth-child(4n) {
    margin-left: 0;
  }
  div.commonPage.rtl .bottom .itemsContainer .items.rowCount6.landscape:nth-child(3n) {
    margin-left: 0;
  }
  div.commonPage.rtl .bottom .itemsContainer .items.rowCount6.landscape:nth-child(2n) {
    margin-left: 0;
  }
}
@media all and (max-width: 480px) and (max-width: 360px) {
  div.commonPage.rtl .bottom .itemsContainer .items.rowCount6.landscape {
    margin-left: 0;
  }
  div.commonPage.rtl .bottom .itemsContainer .items.rowCount6.landscape:nth-child(3n) {
    margin-left: 0;
  }
}
@media all and (min-width: 981px) {
  div.commonPage.rtl .bottom .itemsContainer .items.rowCount6.portrait:hover div.showCardContainer .square {
    transform: translateX(-60px) translateY(-60px) scale(1.2);
  }
  div.commonPage.rtl .bottom .itemsContainer .items.rowCount6.portrait:hover:nth-child(6n) div.showCardContainer div.square {
    transform: translateX(60px) translateY(-60px) scale(1.2);
  }
}
@media all and (min-width: 981px) and (max-width: 1300px) {
  div.commonPage.rtl .bottom .itemsContainer .items.rowCount6.portrait:hover:nth-child(6n) div.showCardContainer div.square {
    transform: translateX(60px) translateY(-60px) scale(1.2);
  }
  div.commonPage.rtl .bottom .itemsContainer .items.rowCount6.portrait:hover:nth-child(7n) div.showCardContainer div.square {
    transform: translateX(-60px) translateY(-60px) scale(1.2);
  }
}
@media all and (min-width: 981px) {
  div.commonPage.rtl .bottom .itemsContainer .items.rowCount6.portrait:hover:last-child div.showCardContainer div.square {
    transform: translateX(60px) translateY(-60px) scale(1.2);
  }
}
@media all and (max-width: 980px) {
  div.commonPage.rtl .bottom .itemsContainer .items.rowCount6.portrait {
    margin-right: 0;
    margin-left: 6px;
  }
  div.commonPage.rtl .bottom .itemsContainer .items.rowCount6.portrait:nth-child(5n) {
    margin-right: 0;
    margin-left: 6px;
  }
  div.commonPage.rtl .bottom .itemsContainer .items.rowCount6.portrait:nth-child(4n) {
    margin-left: 0;
  }
  div.commonPage.rtl .bottom .itemsContainer .items.rowCount6.portrait:nth-child(6n) {
    margin-right: 0;
    margin-left: 6px;
  }
}
@media all and (max-width: 768px) {
  div.commonPage.rtl .bottom .itemsContainer .items.rowCount6.portrait:nth-child(4n) {
    margin-right: 0;
    margin-left: 6px;
  }
  div.commonPage.rtl .bottom .itemsContainer .items.rowCount6.portrait:nth-child(3n) {
    margin-left: 0;
  }
}
@media all and (max-width: 640px) {
  div.commonPage.rtl .bottom .itemsContainer .items.rowCount6.portrait:nth-child(3n) {
    margin-right: 0;
    margin-left: 6px;
  }
  div.commonPage.rtl .bottom .itemsContainer .items.rowCount6.portrait:nth-child(2n) {
    margin-left: 0;
  }
}
div.commonPage.rtl .bottom .itemsContainer .items.rowCount7 {
  margin-right: 0;
  margin-left: 6px;
}
div.commonPage.rtl .bottom .itemsContainer .items.rowCount7:nth-child(7n) {
  margin-left: 0;
}
@media all and (max-width: 2600px) {
  div.commonPage.rtl .bottom .itemsContainer .items.rowCount7.portrait {
    margin-right: 0;
    margin-left: 6.5px;
  }
}
@media all and (max-width: 2500px) {
  div.commonPage.rtl .bottom .itemsContainer .items.rowCount7.portrait {
    margin-right: 0;
    margin-left: 5.5px;
  }
}
@media all and (max-width: 2200px) {
  div.commonPage.rtl .bottom .itemsContainer .items.rowCount7.portrait {
    margin-right: 0;
    margin-left: 6.5px;
  }
}
@media all and (max-width: 1980px) {
  div.commonPage.rtl .bottom .itemsContainer .items.rowCount7.portrait {
    margin-right: 0;
    margin-left: 4.5px;
  }
}
@media all and (max-width: 1768px) {
  div.commonPage.rtl .bottom .itemsContainer .items.rowCount7.portrait {
    margin-right: 0;
    margin-left: 4px;
  }
}
@media all and (max-width: 1600px) {
  div.commonPage.rtl .bottom .itemsContainer .items.rowCount7.portrait {
    margin-right: 0;
    margin-left: 7.5px;
  }
}
@media all and (max-width: 1400px) {
  div.commonPage.rtl .bottom .itemsContainer .items.rowCount7.portrait {
    margin-right: 0;
    margin-left: 3px;
  }
}
@media all and (max-width: 1200px) {
  div.commonPage.rtl .bottom .itemsContainer .items.rowCount7.portrait {
    margin-right: 0;
    margin-left: 6.1px;
  }
}
@media all and (min-width: 981px) {
  div.commonPage.rtl .bottom .itemsContainer .items.rowCount7.portrait:hover div.showCardContainer .square {
    transform: translateX(-60px) translateY(-60px) scale(1.2);
  }
  div.commonPage.rtl .bottom .itemsContainer .items.rowCount7.portrait:hover:nth-child(7n) div.showCardContainer div.square {
    transform: translateX(60px) translateY(-60px) scale(1.2);
  }
}
@media all and (min-width: 981px) and (max-width: 1300px) {
  div.commonPage.rtl .bottom .itemsContainer .items.rowCount7.portrait:hover:nth-child(6n) div.showCardContainer div.square {
    transform: translateX(60px) translateY(-60px) scale(1.2);
  }
  div.commonPage.rtl .bottom .itemsContainer .items.rowCount7.portrait:hover:nth-child(7n) div.showCardContainer div.square {
    transform: translateX(-60px) translateY(-60px) scale(1.2);
  }
}
@media all and (min-width: 981px) {
  div.commonPage.rtl .bottom .itemsContainer .items.rowCount7.portrait:hover:last-child div.showCardContainer div.square {
    transform: translateX(60px) translateY(-60px) scale(1.2);
  }
}
@media all and (max-width: 980px) {
  div.commonPage.rtl .bottom .itemsContainer .items.rowCount7.portrait {
    margin-right: 0;
    margin-left: 6px;
  }
  div.commonPage.rtl .bottom .itemsContainer .items.rowCount7.portrait:nth-child(5n) {
    margin-right: 0;
    margin-left: 6px;
  }
  div.commonPage.rtl .bottom .itemsContainer .items.rowCount7.portrait:nth-child(4n) {
    margin-left: 0;
  }
  div.commonPage.rtl .bottom .itemsContainer .items.rowCount7.portrait:nth-child(6n) {
    margin-right: 0;
    margin-left: 6px;
  }
}
@media all and (max-width: 768px) {
  div.commonPage.rtl .bottom .itemsContainer .items.rowCount7.portrait:nth-child(4n) {
    margin-right: 0;
    margin-left: 6px;
  }
  div.commonPage.rtl .bottom .itemsContainer .items.rowCount7.portrait:nth-child(3n) {
    margin-left: 0;
  }
}
@media all and (max-width: 640px) {
  div.commonPage.rtl .bottom .itemsContainer .items.rowCount7.portrait:nth-child(3n) {
    margin-right: 0;
    margin-left: 6px;
  }
  div.commonPage.rtl .bottom .itemsContainer .items.rowCount7.portrait:nth-child(2n) {
    margin-left: 0;
  }
}
div.commonPage.rtl .bottom .itemsContainer .items.rowCount8 {
  margin-right: 0;
  margin-left: 8px;
}
div.commonPage.rtl .bottom .itemsContainer .items.rowCount8:nth-child(8n) {
  margin-left: 0;
}
@media all and (max-width: 1200px) {
  div.commonPage.rtl .bottom .itemsContainer .items.rowCount8 {
    margin-right: 0;
    margin-left: 6px;
  }
}
@media all and (min-width: 981px) {
  div.commonPage.rtl .bottom .itemsContainer .items.rowCount8.landscape:hover div.showCardContainer .square {
    transform: translateX(-60px) translateY(-60px) scale(1.1);
  }
  div.commonPage.rtl .bottom .itemsContainer .items.rowCount8.landscape:hover:nth-child(5n) div.showCardContainer div.square {
    transform: translateX(60px) translateY(-60px) scale(1.1);
  }
}
@media all and (max-width: 980px) {
  div.commonPage.rtl .bottom .itemsContainer .items.rowCount8.landscape {
    margin-right: 0;
    margin-left: 6px;
  }
  div.commonPage.rtl .bottom .itemsContainer .items.rowCount8.landscape:nth-child(6n) {
    margin-right: 0;
    margin-left: 6px;
  }
  div.commonPage.rtl .bottom .itemsContainer .items.rowCount8.landscape:nth-child(4n) {
    margin-left: 0;
  }
}
@media all and (max-width: 768px) {
  div.commonPage.rtl .bottom .itemsContainer .items.rowCount8.landscape:nth-child(4n) {
    margin-right: 0;
    margin-left: 6px;
  }
  div.commonPage.rtl .bottom .itemsContainer .items.rowCount8.landscape:nth-child(3n) {
    margin-left: 0;
  }
}
@media all and (max-width: 640px) {
  div.commonPage.rtl .bottom .itemsContainer .items.rowCount8.landscape:nth-child(3n) {
    margin-right: 0;
    margin-left: 6px;
  }
  div.commonPage.rtl .bottom .itemsContainer .items.rowCount8.landscape:nth-child(2n) {
    margin-left: 0;
  }
}
@media all and (max-width: 480px) {
  div.commonPage.rtl .bottom .itemsContainer .items.rowCount8.landscape {
    margin-left: 0;
  }
  div.commonPage.rtl .bottom .itemsContainer .items.rowCount8.landscape:nth-child(6n) {
    margin-left: 0;
  }
  div.commonPage.rtl .bottom .itemsContainer .items.rowCount8.landscape:nth-child(4n) {
    margin-left: 0;
  }
  div.commonPage.rtl .bottom .itemsContainer .items.rowCount8.landscape:nth-child(3n) {
    margin-left: 0;
  }
  div.commonPage.rtl .bottom .itemsContainer .items.rowCount8.landscape:nth-child(2n) {
    margin-left: 0;
  }
}
@media all and (max-width: 480px) and (max-width: 360px) {
  div.commonPage.rtl .bottom .itemsContainer .items.rowCount8.landscape {
    margin-left: 0;
  }
  div.commonPage.rtl .bottom .itemsContainer .items.rowCount8.landscape:nth-child(3n) {
    margin-left: 0;
  }
}
@media all and (max-width: 2600px) {
  div.commonPage.rtl .bottom .itemsContainer .items.rowCount8.portrait {
    margin-right: 0;
    margin-left: 10px;
  }
  div.commonPage.rtl .bottom .itemsContainer .items.rowCount8.portrait:nth-child(8n) {
    margin-left: 0px;
  }
}
@media all and (max-width: 2500px) {
  div.commonPage.rtl .bottom .itemsContainer .items.rowCount8.portrait {
    margin-right: 0;
    margin-left: 11px;
  }
}
@media all and (max-width: 2200px) {
  div.commonPage.rtl .bottom .itemsContainer .items.rowCount8.portrait {
    margin-right: 0;
    margin-left: 6px;
  }
}
@media all and (max-width: 1980px) {
  div.commonPage.rtl .bottom .itemsContainer .items.rowCount8.portrait {
    margin-right: 0;
    margin-left: 9px;
  }
}
@media all and (max-width: 1768px) {
  div.commonPage.rtl .bottom .itemsContainer .items.rowCount8.portrait {
    margin-right: 0;
    margin-left: 10px;
  }
}
@media all and (max-width: 1600px) {
  div.commonPage.rtl .bottom .itemsContainer .items.rowCount8.portrait {
    margin-right: 0;
    margin-left: 7px;
  }
}
@media all and (max-width: 1400px) {
  div.commonPage.rtl .bottom .itemsContainer .items.rowCount8.portrait {
    margin-right: 0;
    margin-left: 6px;
  }
}
@media all and (max-width: 1200px) {
  div.commonPage.rtl .bottom .itemsContainer .items.rowCount8.portrait {
    margin-right: 0;
    margin-left: 5px;
  }
}
@media all and (min-width: 981px) {
  div.commonPage.rtl .bottom .itemsContainer .items.rowCount8.portrait:hover div.showCardContainer .square {
    transform: translateX(-60px) translateY(-60px) scale(1.2);
  }
  div.commonPage.rtl .bottom .itemsContainer .items.rowCount8.portrait:hover:nth-child(8n) div.showCardContainer div.square {
    transform: translateX(60px) translateY(-60px) scale(1.2);
  }
}
@media all and (max-width: 980px) {
  div.commonPage.rtl .bottom .itemsContainer .items.rowCount8.portrait {
    margin-right: 0;
    margin-left: 6px;
  }
}
@media all and (max-width: 768px) {
  div.commonPage.rtl .bottom .itemsContainer .items.rowCount8.portrait:nth-child(4n) {
    margin-right: 0;
    margin-left: 6px;
  }
  div.commonPage.rtl .bottom .itemsContainer .items.rowCount8.portrait:nth-child(3n) {
    margin-left: 0;
  }
}
@media all and (max-width: 640px) {
  div.commonPage.rtl .bottom .itemsContainer .items.rowCount8.portrait:nth-child(3n) {
    margin-right: 0;
    margin-left: 6px;
  }
  div.commonPage.rtl .bottom .itemsContainer .items.rowCount8.portrait:nth-child(2n) {
    margin-left: 0;
  }
}
@media all and (max-width: 980px) {
  div.commonPage.rtl .bottom .itemsContainer .items {
    margin-right: 0;
    margin-left: 6px;
  }
  div.commonPage.rtl .bottom .itemsContainer .items:nth-child(5n) {
    margin-right: 0;
    margin-left: 6px;
  }
  div.commonPage.rtl .bottom .itemsContainer .items:nth-child(4n) {
    margin-left: 0;
  }
  div.commonPage.rtl .bottom .itemsContainer .items:nth-child(6n) {
    margin-right: 0;
    margin-left: 6px;
  }
}
@media all and (max-width: 640px) {
  div.commonPage.rtl .bottom .itemsContainer .items:nth-child(3n) {
    margin-right: 0;
    margin-left: 6px;
  }
  div.commonPage.rtl .bottom .itemsContainer .items:nth-child(2n) {
    margin-left: 0;
  }
}
@media all and (max-width: 980px) {
  div.commonPage.rtl .bottom .itemsContainer .items.portrait {
    margin-right: 0;
    margin-left: 13px;
  }
  div.commonPage.rtl .bottom .itemsContainer .items.portrait:nth-child(4n) {
    margin-left: 0;
  }
  div.commonPage.rtl .bottom .itemsContainer .items.portrait:nth-child(5n) {
    margin-right: 0;
    margin-left: 13px;
  }
}
@media all and (max-width: 768px) {
  div.commonPage.rtl .bottom .itemsContainer .items.portrait:nth-child(4n) {
    margin-left: 0;
  }
  div.commonPage.rtl .bottom .itemsContainer .items.portrait:nth-child(5n) {
    margin-right: 0;
    margin-left: 13px;
  }
}
@media all and (max-width: 640px) {
  div.commonPage.rtl .bottom .itemsContainer .items.portrait:nth-child(4n) {
    margin-right: 0;
    margin-left: 13px;
  }
  div.commonPage.rtl .bottom .itemsContainer .items.portrait:nth-child(3n) {
    margin-left: 0;
  }
}
@media all and (max-width: 480px) {
  div.commonPage.rtl .bottom .itemsContainer .items.portrait:nth-child(3n) {
    margin-right: 0;
    margin-left: 13px;
  }
  div.commonPage.rtl .bottom .itemsContainer .items.portrait:nth-child(2n) {
    margin-left: 0;
  }
}
@media all and (max-width: 980px) {
  div.commonPage.rtl .bottom .itemsContainer .items.landscape {
    margin-right: 0;
    margin-left: 13px;
  }
  div.commonPage.rtl .bottom .itemsContainer .items.landscape:nth-child(3n) {
    margin-left: 0;
  }
}
@media all and (max-width: 768px) {
  div.commonPage.rtl .bottom .itemsContainer .items.landscape:nth-child(2n) {
    margin-left: 0;
  }
  div.commonPage.rtl .bottom .itemsContainer .items.landscape:nth-child(3n) {
    margin-right: 0;
    margin-left: 13px;
  }
}
@media all and (max-width: 640px) {
  div.commonPage.rtl .bottom .itemsContainer .items.landscape:nth-child(3n) {
    margin-right: 0;
    margin-left: 13px;
  }
  div.commonPage.rtl .bottom .itemsContainer .items.landscape:nth-child(2n) {
    margin-left: 0;
  }
}
@media all and (max-width: 480px) {
  div.commonPage.rtl .bottom .itemsContainer .items.landscape:nth-child(3n) {
    margin-right: 0;
    margin-left: 13px;
  }
  div.commonPage.rtl .bottom .itemsContainer .items.landscape:nth-child(2n) {
    margin-left: 0;
  }
}

div.failed {
  padding: 178px 0;
}
div.failed div.failedContainer {
  background-color: #fff;
  width: 25%;
  margin: 0 auto;
  position: relative;
  padding: 70px 20px 30px;
  text-align: center;
  border-radius: 8px;
}
@media all and (max-width: 1200px) {
  div.failed div.failedContainer {
    width: 31%;
  }
}
@media all and (max-width: 980px) {
  div.failed div.failedContainer {
    width: 41%;
  }
}
@media all and (max-width: 768px) {
  div.failed div.failedContainer {
    width: 45%;
  }
}
@media (max-width: 640px) {
  div.failed div.failedContainer {
    width: 52%;
  }
}
@media (max-width: 480px) {
  div.failed div.failedContainer {
    width: 68%;
  }
}
div.failed div.failedContainer h5 {
  color: #000;
  margin-bottom: 20px;
  font-size: 30px;
}
div.failed div.failedContainer p {
  color: #636363;
  font-size: 15px;
  margin-bottom: 40px;
}
div.failed div.failedContainer button {
  width: 100%;
  outline: none;
  border: 1px solid #e50914;
  background-color: #e50914;
  color: #fff;
  padding: 10px 0;
  border-radius: 5px;
  transition: 0.3s ease-in-out;
  cursor: pointer;
}
div.failed div.failedContainer button:hover {
  background-color: transparent;
  color: #000;
}
div.failed div.failedContainer div.crossIcon {
  text-align: center;
  position: absolute;
  top: -35px;
  right: 0;
  left: 0;
}
div.failed div.failedContainer div.crossIcon svg {
  padding: 20px;
  background-color: #e50914;
  border-radius: 50%;
}

/* div.successContainer {
    padding: 100px 0;
    background-color: #fff;
    width: 30%;
    margin: 0 auto;
} */
div.success {
  padding: 178px 0;
}
div.success div.successContainer {
  background-color: #fff;
  width: 25%;
  margin: 0 auto;
  position: relative;
  padding: 70px 20px 30px;
  text-align: center;
  border-radius: 8px;
}
@media all and (max-width: 1200px) {
  div.success div.successContainer {
    width: 31%;
  }
}
@media all and (max-width: 980px) {
  div.success div.successContainer {
    width: 41%;
  }
}
@media all and (max-width: 768px) {
  div.success div.successContainer {
    width: 45%;
  }
}
@media (max-width: 640px) {
  div.success div.successContainer {
    width: 52%;
  }
}
@media (max-width: 480px) {
  div.success div.successContainer {
    width: 68%;
  }
}
div.success div.successContainer h5 {
  color: #000;
  margin-bottom: 20px;
  font-size: 30px;
}
div.success div.successContainer p {
  color: #636363;
  font-size: 15px;
  margin-bottom: 40px;
}
div.success div.successContainer button {
  width: 100%;
  outline: none;
  border: 1px solid #28a745;
  background-color: #28a745;
  color: #fff;
  padding: 10px 0;
  border-radius: 5px;
  transition: 0.3s ease-in-out;
  cursor: pointer;
}
div.success div.successContainer button:hover {
  background-color: transparent;
  color: #000;
}
div.success div.successContainer div.checkIcon {
  text-align: center;
  position: absolute;
  top: -35px;
  right: 0;
  left: 0;
}
div.success div.successContainer div.checkIcon svg {
  padding: 20px;
  background-color: #28a745;
  border-radius: 50%;
}

div.forgotPassword span.error {
  color: #E72A31;
  font-size: 12px;
  text-align: left;
  display: block;
  margin-bottom: 10px;
}
div.forgotPassword div.forgotPasswordContainer {
  padding: 150px 0;
}
div.forgotPassword div.forgotPasswordContainer h1.heading {
  font-size: 34px;
  color: var(--text-color);
  text-align: center;
  margin-bottom: 20px;
}
@media all and (max-width: 480px) {
  div.forgotPassword div.forgotPasswordContainer h1.heading {
    font-size: 26px;
  }
}
div.forgotPassword div.forgotPasswordContainer div.formContainer {
  text-align: center;
  width: 35rem;
  margin: 0 auto;
}
@media all and (max-width: 640px) {
  div.forgotPassword div.forgotPasswordContainer div.formContainer {
    width: 25rem;
  }
}
@media all and (max-width: 480px) {
  div.forgotPassword div.forgotPasswordContainer div.formContainer {
    width: 100%;
  }
}
div.forgotPassword div.forgotPasswordContainer div.formContainer form input {
  background: transparent;
  outline: none;
  border: 1px solid var(--inner-text-color);
  text-align: left;
  width: 100%;
  padding: 20px 15px;
  border-radius: 8px;
  caret-color: var(--text-color);
  margin-bottom: 30px;
  color: var(--text-color);
}
div.forgotPassword div.forgotPasswordContainer div.formContainer form input:focus {
  border: 1px solid var(--text-color);
}
div.forgotPassword div.forgotPasswordContainer div.formContainer form input::-moz-placeholder {
  font-size: 15px;
  color: rgb(186, 184, 184);
}
div.forgotPassword div.forgotPasswordContainer div.formContainer form input::placeholder {
  font-size: 15px;
  color: rgb(186, 184, 184);
}
div.forgotPassword div.forgotPasswordContainer div.formContainer form div.buttonContainer {
  display: flex;
  padding: 18px 20px;
  border-radius: 4px;
  align-items: center;
  margin-bottom: 15px;
  cursor: pointer;
  transition: 0.2s ease-in-out;
  justify-content: center;
  position: relative;
  width: 20rem;
  margin: 0 auto;
}
@media all and (max-width: 640px) {
  div.forgotPassword div.forgotPasswordContainer div.formContainer form div.buttonContainer {
    width: 15rem;
  }
}
@media all and (max-width: 480px) {
  div.forgotPassword div.forgotPasswordContainer div.formContainer form div.buttonContainer {
    width: 10rem;
  }
}
div.forgotPassword div.forgotPasswordContainer div.formContainer form div.buttonContainer:hover div.background {
  transform: scale(1.0476190476, 1.0357142857);
}
div.forgotPassword div.forgotPasswordContainer div.formContainer form div.buttonContainer div.background {
  background: var(--text-hover);
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border-radius: 4px;
  transition: 0.1s ease-in-out;
  z-index: 1;
}
div.forgotPassword div.forgotPasswordContainer div.formContainer form div.buttonContainer button {
  background: transparent;
  font-size: 15px;
  color: var(--text-color);
  font-weight: 600;
  position: relative;
  z-index: 2;
  cursor: pointer;
}

div.resetPassword {
  padding: 150px 0;
}
div.resetPassword span.error {
  color: #E72A31;
  font-size: 12px;
  text-align: left;
  display: block;
  margin-bottom: 10px;
}
div.resetPassword span.success {
  font-size: 14px;
  display: block;
  text-align: center;
  margin-top: 10px;
}
div.resetPassword h1.heading {
  font-size: 34px;
  padding-bottom: 10px;
  text-align: center;
  color: var(--text-color);
}
@media all and (max-width: 640px) {
  div.resetPassword h1.heading {
    font-size: 22px;
    margin: 0 20px;
  }
}
div.resetPassword div.contents {
  margin-top: 40px;
}
@media all and (max-width: 480px) {
  div.resetPassword div.contents {
    padding: 0 10px;
  }
}
div.resetPassword div.contents div.formSection {
  width: 30%;
  margin: 0 auto;
}
@media all and (max-width: 1200px) {
  div.resetPassword div.contents div.formSection {
    width: 55%;
  }
}
@media all and (max-width: 980px) {
  div.resetPassword div.contents div.formSection {
    width: 75%;
  }
}
@media all and (max-width: 640px) {
  div.resetPassword div.contents div.formSection {
    width: 100%;
  }
}
div.resetPassword div.contents div.formSection form div.inputContainer {
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
}
div.resetPassword div.contents div.formSection form div.inputContainer:nth-child(3) {
  margin-bottom: 60px;
}
div.resetPassword div.contents div.formSection form div.inputContainer label {
  font-size: 16px;
  margin-bottom: 8px;
  color: var(--inner-text-color);
}
div.resetPassword div.contents div.formSection form div.inputContainer input {
  outline: none;
  border-radius: 5px;
  font-size: 14px;
  background: transparent;
  text-align: left;
  padding: 20px 15px;
  border: 1px solid var(--inner-text-color);
  caret-color: var(--text-color);
  color: var(--text-color);
  margin-bottom: 10px;
}
div.resetPassword div.contents div.formSection form div.inputContainer input:focus {
  border: 1px solid var(--text-color);
}
@media all and (max-width: 640px) {
  div.resetPassword div.contents div.formSection form div.inputContainer input {
    padding: 16px 15px;
  }
}
div.resetPassword div.contents div.formSection form div.buttonContainer {
  display: flex;
  padding: 18px 20px;
  border-radius: 4px;
  align-items: center;
  margin-bottom: 15px;
  cursor: pointer;
  transition: 0.2s ease-in-out;
  justify-content: center;
  position: relative;
  width: 20rem;
  margin: 0 auto;
}
@media all and (max-width: 640px) {
  div.resetPassword div.contents div.formSection form div.buttonContainer {
    width: 15rem;
  }
}
@media all and (max-width: 480px) {
  div.resetPassword div.contents div.formSection form div.buttonContainer {
    width: 10rem;
  }
}
div.resetPassword div.contents div.formSection form div.buttonContainer:hover div.background {
  transform: scale(1.0476190476, 1.0357142857);
}
div.resetPassword div.contents div.formSection form div.buttonContainer div.background {
  background: var(--text-hover);
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border-radius: 4px;
  transition: 0.1s ease-in-out;
  z-index: 1;
}
div.resetPassword div.contents div.formSection form div.buttonContainer button {
  background: transparent;
  font-size: 15px;
  color: var(--text-color);
  font-weight: 600;
  position: relative;
  z-index: 2;
  cursor: pointer;
}

header.accountsPageHeader {
  height: 100px;
  position: fixed;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  align-items: center;
  width: 95%;
  justify-content: space-between;
  margin: 0 auto;
}
header.accountsPageHeader div.left div.logoContainer h1 {
  width: 120px;
}
@media all and (max-width: 480px) {
  header.accountsPageHeader div.left div.logoContainer h1 {
    width: 72px;
  }
}
@media all and (max-width: 360px) {
  header.accountsPageHeader div.left div.logoContainer h1 {
    width: 72px;
  }
}
header.accountsPageHeader div.left div.logoContainer h1 img {
  display: block;
  width: 100%;
}
header.accountsPageHeader div.right button {
  background: transparent;
  color: var(--text-color);
  font-size: 15px;
  font-weight: 600;
  cursor: pointer;
}

div.otpModal div.overlayOtp {
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.7);
  z-index: 8;
  display: flex;
  justify-content: center;
  align-items: center;
}
div.otpModal div.otpContainer {
  z-index: 9;
  text-align: center;
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  background-color: var(--bg-color);
  width: 40%;
  margin: 0 auto;
  padding: 60px 60px 60px;
  border-radius: 6px;
  display: flex;
  flex-direction: column;
  /* Firefox */
}
@media all and (max-width: 1200px) {
  div.otpModal div.otpContainer {
    padding: 30px;
  }
}
@media all and (max-width: 768px) {
  div.otpModal div.otpContainer {
    width: 60%;
  }
}
@media all and (max-width: 480px) {
  div.otpModal div.otpContainer {
    width: 90%;
  }
}
div.otpModal div.otpContainer label {
  font-size: 16px;
  margin-bottom: 10px;
  color: var(--text-color);
}
div.otpModal div.otpContainer button.bg-button {
  margin: 20px auto 0;
  width: 30%;
}
div.otpModal div.otpContainer input {
  outline: none;
  border-radius: 5px;
  font-size: 14px;
  background: transparent;
  text-align: start;
  padding: 20px 15px;
  border: 1px solid var(--inner-text-color);
  caret-color: var(--text-color);
  margin-bottom: 10px;
  color: var(--text-color);
}
div.otpModal div.otpContainer input:focus {
  border: 1px solid var(--text-color);
}
@media all and (max-width: 640px) {
  div.otpModal div.otpContainer input {
    padding: 16px 15px;
  }
}
div.otpModal div.otpContainer input::-webkit-outer-spin-button,
div.otpModal div.otpContainer input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
div.otpModal div.otpContainer input[type=number] {
  -moz-appearance: textfield;
}
div.otpModal div.otpContainer div.buttonContainer {
  display: flex;
  padding: 18px 20px;
  border-radius: 4px;
  align-items: center;
  margin-bottom: 15px;
  cursor: pointer;
  transition: 0.2s ease-in-out;
  justify-content: center;
  position: relative;
  width: 20rem;
  margin: 30px auto 0;
}
@media all and (max-width: 640px) {
  div.otpModal div.otpContainer div.buttonContainer {
    width: 15rem;
    padding: 10px 20px;
  }
}
@media all and (max-width: 480px) {
  div.otpModal div.otpContainer div.buttonContainer {
    width: 10rem;
  }
}
div.otpModal div.otpContainer div.buttonContainer:hover div.background {
  transform: scale(1.0476190476, 1.0357142857);
}
div.otpModal div.otpContainer div.buttonContainer div.background {
  background: var(--text-hover);
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border-radius: 4px;
  transition: 0.1s ease-in-out;
  z-index: 1;
}
div.otpModal div.otpContainer div.buttonContainer button {
  background: transparent;
  font-size: 15px;
  color: var(--text-color);
  font-weight: 600;
  position: relative;
  z-index: 2;
  cursor: pointer;
}

div.countinueWatching div.overlayModal {
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.7);
  z-index: 99;
  display: flex;
  justify-content: center;
  align-items: center;
}
div.countinueWatching div.contents {
  z-index: 999;
  text-align: center;
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  background-color: var(--bg-color);
  width: 40%;
  margin: 0 auto;
  padding: 60px 60px 60px;
  border-radius: 6px;
  display: flex;
  flex-direction: column;
}
@media all and (max-width: 1200px) {
  div.countinueWatching div.contents {
    padding: 30px;
  }
}
@media all and (max-width: 768px) {
  div.countinueWatching div.contents {
    width: 60%;
  }
}
@media all and (max-width: 480px) {
  div.countinueWatching div.contents {
    width: 90%;
  }
}
div.countinueWatching div.contents h1 {
  color: var(--inner-text-color);
  font-size: 22px;
}
div.countinueWatching div.contents div.buttons {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}
div.countinueWatching div.contents div.buttons button.bg-button {
  margin: 20px auto 0;
  width: 30%;
}
div.countinueWatching div.contents div.buttons div.buttonContainer {
  display: flex;
  padding: 18px 20px;
  border-radius: 4px;
  align-items: center;
  margin-bottom: 15px;
  cursor: pointer;
  margin-inline-end: 20px;
  transition: 0.2s ease-in-out;
  justify-content: center;
  position: relative;
  width: 10rem;
}
div.countinueWatching div.contents div.buttons div.buttonContainer:last-child {
  margin-inline-end: 0;
}
@media all and (max-width: 640px) {
  div.countinueWatching div.contents div.buttons div.buttonContainer {
    width: 15rem;
    padding: 10px 20px;
  }
}
@media all and (max-width: 480px) {
  div.countinueWatching div.contents div.buttons div.buttonContainer {
    width: 10rem;
  }
}
div.countinueWatching div.contents div.buttons div.buttonContainer:hover div.background {
  transform: scale(1.0476190476, 1.0357142857);
}
div.countinueWatching div.contents div.buttons div.buttonContainer div.background {
  background: var(--text-hover);
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border-radius: 4px;
  transition: 0.1s ease-in-out;
  z-index: 1;
}
div.countinueWatching div.contents div.buttons div.buttonContainer button {
  background: transparent;
  font-size: 15px;
  color: var(--text-color);
  font-weight: 600;
  position: relative;
  z-index: 2;
  cursor: pointer;
}

div.currentPasswordModal div.overlayModal {
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.7);
  z-index: 99;
  display: flex;
  justify-content: center;
  align-items: center;
}
div.currentPasswordModal div.currentPasswordContainer {
  z-index: 999;
  text-align: center;
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  background-color: var(--bg-color);
  width: 40%;
  margin: 0 auto;
  padding: 60px 60px 60px;
  border-radius: 6px;
  display: flex;
  flex-direction: column;
  /* Firefox */
}
@media all and (max-width: 1200px) {
  div.currentPasswordModal div.currentPasswordContainer {
    padding: 30px;
  }
}
@media all and (max-width: 768px) {
  div.currentPasswordModal div.currentPasswordContainer {
    width: 60%;
  }
}
@media all and (max-width: 480px) {
  div.currentPasswordModal div.currentPasswordContainer {
    width: 90%;
  }
}
div.currentPasswordModal div.currentPasswordContainer label {
  font-size: 16px;
  margin-bottom: 10px;
  color: var(--text-color);
}
div.currentPasswordModal div.currentPasswordContainer button.bg-button {
  margin: 20px auto 0;
  width: 30%;
}
div.currentPasswordModal div.currentPasswordContainer input {
  outline: none;
  border-radius: 5px;
  font-size: 14px;
  background: transparent;
  text-align: start;
  padding: 20px 15px;
  border: 1px solid var(--inner-text-color);
  caret-color: var(--text-color);
  display: block;
  width: 100%;
  color: var(--text-color);
}
div.currentPasswordModal div.currentPasswordContainer input:focus {
  border: 1px solid var(--text-color);
}
@media all and (max-width: 640px) {
  div.currentPasswordModal div.currentPasswordContainer input {
    padding: 16px 15px;
  }
}
div.currentPasswordModal div.currentPasswordContainer input::-webkit-outer-spin-button,
div.currentPasswordModal div.currentPasswordContainer input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
div.currentPasswordModal div.currentPasswordContainer input[type=number] {
  -moz-appearance: textfield;
}
div.currentPasswordModal div.currentPasswordContainer div.buttonContainer {
  display: flex;
  padding: 18px 20px;
  border-radius: 4px;
  align-items: center;
  margin-bottom: 15px;
  cursor: pointer;
  transition: 0.2s ease-in-out;
  justify-content: center;
  position: relative;
  width: 10rem;
  margin: 30px auto 0;
}
@media all and (max-width: 640px) {
  div.currentPasswordModal div.currentPasswordContainer div.buttonContainer {
    width: 15rem;
    padding: 10px 20px;
  }
}
@media all and (max-width: 480px) {
  div.currentPasswordModal div.currentPasswordContainer div.buttonContainer {
    width: 10rem;
  }
}
div.currentPasswordModal div.currentPasswordContainer div.buttonContainer:hover div.background {
  transform: scale(1.0476190476, 1.0357142857);
}
div.currentPasswordModal div.currentPasswordContainer div.buttonContainer div.background {
  background: var(--text-hover);
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border-radius: 4px;
  transition: 0.1s ease-in-out;
  z-index: 1;
}
div.currentPasswordModal div.currentPasswordContainer div.buttonContainer button {
  background: transparent;
  font-size: 15px;
  color: var(--text-color);
  font-weight: 600;
  position: relative;
  z-index: 2;
  cursor: pointer;
}
div.currentPasswordModal div.currentPasswordContainer div.closePasswordContainer {
  text-align: end;
  cursor: pointer;
}

div.watchWithoutAds div.overlayModal {
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.7);
  z-index: 99;
  display: flex;
  justify-content: center;
  align-items: center;
}
div.watchWithoutAds div.contents {
  z-index: 999;
  text-align: center;
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  background-color: var(--bg-color);
  width: 40%;
  margin: 0 auto;
  padding: 60px 60px 60px;
  border-radius: 6px;
  display: flex;
  flex-direction: column;
}
@media all and (max-width: 1200px) {
  div.watchWithoutAds div.contents {
    padding: 30px;
  }
}
@media all and (max-width: 768px) {
  div.watchWithoutAds div.contents {
    width: 60%;
  }
}
@media all and (max-width: 480px) {
  div.watchWithoutAds div.contents {
    width: 90%;
  }
}
div.watchWithoutAds div.contents h1 {
  color: var(--inner-text-color);
  font-size: 22px;
}
div.watchWithoutAds div.contents div.buttons {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}
div.watchWithoutAds div.contents div.buttons button.bg-button {
  margin: 20px auto 0;
  width: 30%;
}
div.watchWithoutAds div.contents div.buttons div.buttonContainer {
  display: flex;
  padding: 18px 20px;
  border-radius: 4px;
  align-items: center;
  margin-bottom: 15px;
  cursor: pointer;
  margin-inline-end: 20px;
  transition: 0.2s ease-in-out;
  justify-content: center;
  position: relative;
  width: 10rem;
}
div.watchWithoutAds div.contents div.buttons div.buttonContainer:last-child {
  margin-inline-end: 0;
}
@media all and (max-width: 640px) {
  div.watchWithoutAds div.contents div.buttons div.buttonContainer {
    width: 15rem;
    padding: 10px 20px;
  }
}
@media all and (max-width: 480px) {
  div.watchWithoutAds div.contents div.buttons div.buttonContainer {
    width: 10rem;
  }
}
div.watchWithoutAds div.contents div.buttons div.buttonContainer:hover div.background {
  transform: scale(1.0476190476, 1.0357142857);
}
div.watchWithoutAds div.contents div.buttons div.buttonContainer div.background {
  background: var(--text-hover);
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border-radius: 4px;
  transition: 0.1s ease-in-out;
  z-index: 1;
}
div.watchWithoutAds div.contents div.buttons div.buttonContainer button {
  background: transparent;
  font-size: 15px;
  color: var(--text-color);
  font-weight: 600;
  position: relative;
  z-index: 2;
  cursor: pointer;
}

div.logoutAll div.overlayModal {
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.7);
  z-index: 99;
  display: flex;
  justify-content: center;
  align-items: center;
}
div.logoutAll div.closeIconContainer {
  position: fixed;
  top: 20px;
  inset-inline-end: 30px;
  z-index: 999;
}
div.logoutAll div.closeIconContainer svg {
  width: 20px !important;
  height: 20px !important;
}
div.logoutAll div.contents {
  z-index: 999;
  text-align: center;
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  background-color: var(--bg-color);
  width: 40%;
  margin: 0 auto;
  padding: 60px 60px 60px;
  border-radius: 6px;
  display: flex;
  flex-direction: column;
}
@media all and (max-width: 1200px) {
  div.logoutAll div.contents {
    padding: 30px;
  }
}
@media all and (max-width: 768px) {
  div.logoutAll div.contents {
    width: 80%;
  }
}
@media all and (max-width: 480px) {
  div.logoutAll div.contents {
    width: 90%;
  }
}
div.logoutAll div.contents h1.error {
  font-size: 20px;
  color: #E72A31;
  margin-bottom: 10px;
}
div.logoutAll div.contents p {
  color: var(--text-color);
  font-size: 16px;
}
div.logoutAll div.contents h1 {
  color: var(--inner-text-color);
  font-size: 18px;
}
@media all and (max-width: 768px) {
  div.logoutAll div.contents h1 {
    font-size: 16px;
  }
}
div.logoutAll div.contents div.buttonContainer {
  display: flex;
  padding: 18px 20px;
  border-radius: 4px;
  align-items: center;
  cursor: pointer;
  transition: 0.2s ease-in-out;
  justify-content: center;
  position: relative;
  margin: 20px auto 0;
  width: 10rem;
}
@media all and (max-width: 768px) {
  div.logoutAll div.contents div.buttonContainer {
    padding: 10px 20px;
  }
}
@media all and (max-width: 640px) {
  div.logoutAll div.contents div.buttonContainer {
    width: 15rem;
    padding: 10px 20px;
  }
}
@media all and (max-width: 480px) {
  div.logoutAll div.contents div.buttonContainer {
    width: 10rem;
  }
}
div.logoutAll div.contents div.buttonContainer:hover div.background {
  transform: scale(1.0476190476, 1.0357142857);
}
div.logoutAll div.contents div.buttonContainer div.background {
  background: var(--text-hover);
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border-radius: 4px;
  transition: 0.1s ease-in-out;
  z-index: 1;
}
div.logoutAll div.contents div.buttonContainer button {
  background: transparent;
  font-size: 15px;
  color: var(--text-color);
  font-weight: 600;
  position: relative;
  z-index: 2;
  cursor: pointer;
}

div.cancelSub div.overlayModal {
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.7);
  z-index: 99;
  display: flex;
  justify-content: center;
  align-items: center;
}
div.cancelSub div.closeIconContainer {
  position: fixed;
  top: 20px;
  inset-inline-end: 30px;
  z-index: 999;
}
div.cancelSub div.closeIconContainer svg {
  width: 20px !important;
  height: 20px !important;
  stroke: #E72A31 !important;
}
div.cancelSub div.contents {
  z-index: 999;
  text-align: center;
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  background-color: var(--bg-color);
  width: 40%;
  margin: 0 auto;
  padding: 60px 60px 60px;
  border-radius: 6px;
  display: flex;
  flex-direction: column;
}
@media all and (max-width: 1200px) {
  div.cancelSub div.contents {
    padding: 30px;
  }
}
@media all and (max-width: 768px) {
  div.cancelSub div.contents {
    width: 80%;
  }
}
@media all and (max-width: 480px) {
  div.cancelSub div.contents {
    width: 90%;
  }
}
div.cancelSub div.contents h1.error {
  font-size: 20px;
  color: #E72A31;
  margin-bottom: 10px;
}
div.cancelSub div.contents p {
  color: var(--text-color);
  font-size: 16px;
}
div.cancelSub div.contents h1 {
  color: var(--inner-text-color);
  font-size: 18px;
}
@media all and (max-width: 768px) {
  div.cancelSub div.contents h1 {
    font-size: 16px;
  }
}
div.cancelSub div.contents div.buttons {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}
div.cancelSub div.contents div.buttons button.bg-button {
  margin: 20px auto 0;
  width: 30%;
}
div.cancelSub div.contents div.buttons div.buttonContainer {
  display: flex;
  padding: 18px 20px;
  border-radius: 4px;
  align-items: center;
  margin-bottom: 15px;
  cursor: pointer;
  margin-inline-end: 20px;
  transition: 0.2s ease-in-out;
  justify-content: center;
  position: relative;
  width: 10rem;
}
div.cancelSub div.contents div.buttons div.buttonContainer:last-child {
  margin-inline-end: 0;
}
div.cancelSub div.contents div.buttons div.buttonContainer:last-child div.background {
  background: var(--error-color);
}
@media all and (max-width: 640px) {
  div.cancelSub div.contents div.buttons div.buttonContainer {
    width: 15rem;
    padding: 10px 20px;
  }
}
@media all and (max-width: 480px) {
  div.cancelSub div.contents div.buttons div.buttonContainer {
    width: 10rem;
  }
}
div.cancelSub div.contents div.buttons div.buttonContainer:hover div.background {
  transform: scale(1.0476190476, 1.0357142857);
}
div.cancelSub div.contents div.buttons div.buttonContainer div.background {
  background: var(--success-color);
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border-radius: 4px;
  transition: 0.1s ease-in-out;
  z-index: 1;
}
div.cancelSub div.contents div.buttons div.buttonContainer button {
  background: transparent;
  font-size: 15px;
  color: var(--text-color);
  font-weight: 600;
  position: relative;
  z-index: 2;
  cursor: pointer;
}

div.loginRequest {
  background: linear-gradient(354deg, rgb(20, 20, 20) 0%, rgb(20, 20, 20) 38%, rgb(129, 17, 23) 100%);
  background-repeat: no-repeat;
  height: 100vh;
}

div.playNextEpisode div.overlayModal {
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.7);
  z-index: 99;
  display: flex;
  justify-content: center;
  align-items: center;
}
div.playNextEpisode div.contents {
  z-index: 999;
  text-align: center;
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  background-color: var(--bg-color);
  width: 40%;
  margin: 0 auto;
  padding: 60px 60px 60px;
  border-radius: 6px;
  display: flex;
  flex-direction: column;
}
@media all and (max-width: 1200px) {
  div.playNextEpisode div.contents {
    padding: 30px;
  }
}
@media all and (max-width: 768px) {
  div.playNextEpisode div.contents {
    width: 60%;
  }
}
@media all and (max-width: 480px) {
  div.playNextEpisode div.contents {
    width: 90%;
  }
}
div.playNextEpisode div.contents h1 {
  color: var(--inner-text-color);
  font-size: 22px;
}
div.playNextEpisode div.contents div.buttons {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}
div.playNextEpisode div.contents div.buttons button.bg-button {
  margin: 20px auto 0;
  width: 30%;
}
div.playNextEpisode div.contents div.buttons div.buttonContainer {
  display: flex;
  padding: 18px 20px;
  border-radius: 4px;
  align-items: center;
  margin-bottom: 15px;
  cursor: pointer;
  margin-inline-end: 20px;
  transition: 0.2s ease-in-out;
  justify-content: center;
  position: relative;
  width: 10rem;
}
div.playNextEpisode div.contents div.buttons div.buttonContainer:last-child {
  margin-inline-end: 0;
}
@media all and (max-width: 640px) {
  div.playNextEpisode div.contents div.buttons div.buttonContainer {
    width: 15rem;
    padding: 10px 20px;
  }
}
@media all and (max-width: 480px) {
  div.playNextEpisode div.contents div.buttons div.buttonContainer {
    width: 10rem;
  }
}
div.playNextEpisode div.contents div.buttons div.buttonContainer:hover div.background {
  transform: scale(1.0476190476, 1.0357142857);
}
div.playNextEpisode div.contents div.buttons div.buttonContainer div.background {
  background: var(--text-hover);
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border-radius: 4px;
  transition: 0.1s ease-in-out;
  z-index: 1;
}
div.playNextEpisode div.contents div.buttons div.buttonContainer button {
  background: transparent;
  font-size: 15px;
  color: var(--text-color);
  font-weight: 600;
  position: relative;
  z-index: 2;
  cursor: pointer;
}

div.accountSettings {
  padding: 130px 0;
  position: relative;
}
div.accountSettings span.error {
  font-size: 14px;
  color: #E72A31;
  display: block;
  margin-top: 10px;
  font-weight: normal !important;
}
div.accountSettings div.wrapper div.head h1.heading {
  font-size: 34px;
  padding-bottom: 10px;
  color: var(--text-color);
  text-align: center;
}
@media all and (max-width: 640px) {
  div.accountSettings div.wrapper div.head h1.heading {
    font-size: 28px;
  }
}
@media all and (max-width: 640px) {
  div.accountSettings div.wrapper div.head {
    padding-left: 20px;
  }
}
div.accountSettings div.wrapper div.contents {
  display: flex;
  justify-content: space-between;
  width: 80%;
  margin: 0 auto;
  padding-top: 100px;
}
@media all and (max-width: 1200px) {
  div.accountSettings div.wrapper div.contents {
    width: 90%;
  }
}
@media all and (max-width: 980px) {
  div.accountSettings div.wrapper div.contents {
    width: 100%;
  }
}
@media all and (max-width: 768px) {
  div.accountSettings div.wrapper div.contents {
    flex-direction: column;
    justify-content: center;
  }
}
@media all and (max-width: 640px) {
  div.accountSettings div.wrapper div.contents {
    padding: 80px 20px;
  }
}
div.accountSettings div.wrapper div.contents div.left {
  width: 40%;
}
@media all and (max-width: 768px) {
  div.accountSettings div.wrapper div.contents div.left {
    width: 100%;
    margin-bottom: 20px;
  }
}
div.accountSettings div.wrapper div.contents div.left div.profileImageContainer {
  width: 250px;
  text-align: center;
}
@media all and (max-width: 768px) {
  div.accountSettings div.wrapper div.contents div.left div.profileImageContainer {
    margin: 0 auto;
  }
}
div.accountSettings div.wrapper div.contents div.left div.profileImageContainer img {
  display: block;
  width: 100%;
  border-radius: 50%;
  height: 250px;
  -o-object-fit: cover;
     object-fit: cover;
}
div.accountSettings div.wrapper div.contents div.left div.profileImageContainer input {
  display: none;
}
div.accountSettings div.wrapper div.contents div.left div.profileImageContainer label {
  color: var(--button-color);
  font-size: 14px;
  display: inline-block;
  margin-top: 15px;
  cursor: pointer;
  font-weight: 600;
}
div.accountSettings div.wrapper div.contents div.right {
  width: 55%;
}
@media all and (max-width: 768px) {
  div.accountSettings div.wrapper div.contents div.right {
    width: 100%;
  }
}
div.accountSettings div.wrapper div.contents div.right div.formSection form input, div.accountSettings div.wrapper div.contents div.right div.formSection form select {
  outline: none;
  border-radius: 5px;
  font-size: 14px;
  background: transparent;
  text-align: left;
  padding: 20px 15px;
  border: 1px solid var(--inner-text-color);
  caret-color: var(--text-color);
  display: block;
  width: 100%;
  color: var(--text-color);
}
div.accountSettings div.wrapper div.contents div.right div.formSection form input:focus, div.accountSettings div.wrapper div.contents div.right div.formSection form select:focus {
  border: 1px solid var(--text-color);
}
@media all and (max-width: 640px) {
  div.accountSettings div.wrapper div.contents div.right div.formSection form input, div.accountSettings div.wrapper div.contents div.right div.formSection form select {
    padding: 16px 15px;
  }
}
div.accountSettings div.wrapper div.contents div.right div.formSection form select {
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
  color: var(--text-color) !important;
}
div.accountSettings div.wrapper div.contents div.right div.formSection form select option {
  color: #000 !important;
}
div.accountSettings div.wrapper div.contents div.right div.formSection form label {
  color: var(--inner-text-color);
  font-size: 16px;
  display: inline-block;
  margin-bottom: 10px;
}
div.accountSettings div.wrapper div.contents div.right div.formSection form span.success {
  font-size: 12px;
}
div.accountSettings div.wrapper div.contents div.right div.formSection form div.half {
  display: flex;
  justify-content: space-between;
  margin-bottom: 30px;
}
@media all and (max-width: 480px) {
  div.accountSettings div.wrapper div.contents div.right div.formSection form div.half {
    flex-direction: column;
    margin-bottom: 20px;
  }
}
div.accountSettings div.wrapper div.contents div.right div.formSection form div.half div.fname, div.accountSettings div.wrapper div.contents div.right div.formSection form div.half div.email {
  width: 49%;
}
@media all and (max-width: 480px) {
  div.accountSettings div.wrapper div.contents div.right div.formSection form div.half div.fname, div.accountSettings div.wrapper div.contents div.right div.formSection form div.half div.email {
    width: 100%;
    margin-bottom: 20px;
  }
}
div.accountSettings div.wrapper div.contents div.right div.formSection form div.half div.email input {
  color: var(--inner-text-color);
}
div.accountSettings div.wrapper div.contents div.right div.formSection form div.half div.lname, div.accountSettings div.wrapper div.contents div.right div.formSection form div.half div.country {
  width: 49%;
}
@media all and (max-width: 480px) {
  div.accountSettings div.wrapper div.contents div.right div.formSection form div.half div.lname, div.accountSettings div.wrapper div.contents div.right div.formSection form div.half div.country {
    width: 100%;
  }
}
div.accountSettings div.wrapper div.contents div.right div.formSection form div.full span.cpassword {
  display: inline-block !important;
}
div.accountSettings div.wrapper div.contents div.right div.formSection form div.full div.phone {
  width: 49%;
}
@media all and (max-width: 480px) {
  div.accountSettings div.wrapper div.contents div.right div.formSection form div.full div.phone {
    width: 100%;
  }
}
div.accountSettings div.wrapper div.contents div.right div.formSection form div.full span {
  color: var(--button-color);
  font-size: 14px;
  display: inline-block;
  margin-top: 10px;
}
div.accountSettings div.wrapper div.contents div.right div.formSection form span {
  display: block !important;
  font-weight: 600;
  cursor: pointer;
}
div.accountSettings div.wrapper div.contents div.right div.formSection form div.buttonContainer {
  display: flex;
  padding: 18px 20px;
  border-radius: 4px;
  align-items: center;
  margin-bottom: 15px;
  cursor: pointer;
  transition: 0.2s ease-in-out;
  justify-content: center;
  position: relative;
  width: 20rem;
  margin: 30px auto 0;
}
@media all and (max-width: 640px) {
  div.accountSettings div.wrapper div.contents div.right div.formSection form div.buttonContainer {
    width: 15rem;
  }
}
@media all and (max-width: 480px) {
  div.accountSettings div.wrapper div.contents div.right div.formSection form div.buttonContainer {
    width: 10rem;
  }
}
div.accountSettings div.wrapper div.contents div.right div.formSection form div.buttonContainer:hover div.background {
  transform: scale(1.0476190476, 1.0357142857);
}
div.accountSettings div.wrapper div.contents div.right div.formSection form div.buttonContainer div.background {
  background: var(--button-color);
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border-radius: 4px;
  transition: 0.1s ease-in-out;
  z-index: 1;
}
div.accountSettings div.wrapper div.contents div.right div.formSection form div.buttonContainer button {
  background: transparent;
  font-size: 15px;
  color: var(--text-color);
  font-weight: 600;
  position: relative;
  z-index: 2;
  cursor: pointer;
}
div.accountSettings div.wrapper div.contents div.right div.formSection .template-9-font input, div.accountSettings div.wrapper div.contents div.right div.formSection .template-9-font select {
  font-family: "Gilroy-Medium" !important;
}
div.accountSettings div.wrapper div.contents div.right div.formSection .template-10-font input, div.accountSettings div.wrapper div.contents div.right div.formSection .template-10-font select {
  font-family: "Avenir-Roman", sans-serif !important;
}
div.accountSettings div.wrapper div.planDetails {
  width: 80%;
  margin: 30px auto 0;
  padding: 20px 0 0;
  border-top: 1px solid var(--border-color);
  display: flex;
  justify-content: space-between;
}
@media all and (max-width: 768px) {
  div.accountSettings div.wrapper div.planDetails {
    width: 100%;
  }
}
@media all and (max-width: 480px) {
  div.accountSettings div.wrapper div.planDetails {
    flex-direction: column;
  }
}
div.accountSettings div.wrapper div.planDetails div.leftContainer {
  width: 30%;
}
@media all and (max-width: 480px) {
  div.accountSettings div.wrapper div.planDetails div.leftContainer {
    margin-bottom: 20px;
    width: 100%;
  }
}
div.accountSettings div.wrapper div.planDetails div.leftContainer h4.heading {
  color: var(--inner-text-color);
  font-size: 13px;
  font-weight: normal;
}
div.accountSettings div.wrapper div.planDetails div.rightContainer {
  width: 70%;
  display: flex;
  justify-content: space-between;
}
@media all and (max-width: 768px) {
  div.accountSettings div.wrapper div.planDetails div.rightContainer {
    flex-direction: column;
  }
}
@media all and (max-width: 768px) {
  div.accountSettings div.wrapper div.planDetails div.rightContainer div.left {
    margin-bottom: 20px;
  }
}
div.accountSettings div.wrapper div.planDetails div.rightContainer div.left p {
  color: #E72A31;
}
div.accountSettings div.wrapper div.planDetails div.rightContainer div.left h2.planName {
  color: var(--text-color);
  font-size: 14px;
}
div.accountSettings div.wrapper div.planDetails div.rightContainer div.left div.buttonContainer {
  display: flex;
  padding: 12px 20px;
  border-radius: 4px;
  align-items: center;
  margin-bottom: 15px;
  cursor: pointer;
  transition: 0.2s ease-in-out;
  justify-content: center;
  position: relative;
  width: 15rem;
  margin: 20px auto 0;
}
@media all and (max-width: 768px) {
  div.accountSettings div.wrapper div.planDetails div.rightContainer div.left div.buttonContainer {
    margin: 20px 0 0;
  }
}
@media all and (max-width: 640px) {
  div.accountSettings div.wrapper div.planDetails div.rightContainer div.left div.buttonContainer {
    width: 12rem;
  }
}
@media all and (max-width: 480px) {
  div.accountSettings div.wrapper div.planDetails div.rightContainer div.left div.buttonContainer {
    width: 15rem;
  }
}
div.accountSettings div.wrapper div.planDetails div.rightContainer div.left div.buttonContainer:hover div.background {
  transform: scale(1.0476190476, 1.0357142857);
}
div.accountSettings div.wrapper div.planDetails div.rightContainer div.left div.buttonContainer div.background {
  background: #E72A31;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border-radius: 4px;
  transition: 0.1s ease-in-out;
  z-index: 1;
}
div.accountSettings div.wrapper div.planDetails div.rightContainer div.left div.buttonContainer button {
  background: transparent;
  font-size: 15px;
  color: var(--text-color);
  font-weight: 600;
  position: relative;
  z-index: 2;
  cursor: pointer;
}
div.accountSettings div.wrapper div.planDetails div.rightContainer div.right span {
  display: block;
  color: var(--text-hover);
  font-size: 14px;
  font-weight: 600;
  margin-top: 10px;
  cursor: pointer;
}
div.accountSettings div.wrapper div.planDetails div.rightContainer div.right span:first-child {
  margin-top: 0;
}
div.accountSettings div.wrapper div.settings {
  width: 80%;
  margin: 30px auto 0;
  padding: 20px 0;
  border-top: 1px solid var(--border-color);
  display: flex;
  justify-content: space-between;
}
@media all and (max-width: 768px) {
  div.accountSettings div.wrapper div.settings {
    width: 100%;
  }
}
@media all and (max-width: 480px) {
  div.accountSettings div.wrapper div.settings {
    flex-direction: column;
  }
}
div.accountSettings div.wrapper div.settings div.leftContainer {
  width: 30%;
}
@media all and (max-width: 480px) {
  div.accountSettings div.wrapper div.settings div.leftContainer {
    margin-bottom: 20px;
    width: 100%;
  }
}
div.accountSettings div.wrapper div.settings div.leftContainer h4.heading {
  color: var(--inner-text-color);
  font-size: 13px;
  font-weight: normal;
}
div.accountSettings div.wrapper div.settings div.rightContainer {
  width: 70%;
}
div.accountSettings div.wrapper div.settings div.rightContainer ul li {
  margin-top: 10px;
}
div.accountSettings div.wrapper div.settings div.rightContainer ul li:first-child {
  margin-top: 0;
}
div.accountSettings div.wrapper div.settings div.rightContainer ul li span {
  display: inline-block;
  color: var(--button-color);
  font-size: 14px;
  font-weight: 600;
  cursor: pointer;
}

@media all and (max-width: 640px) {
  div.accountSettings.rtl .wrapper div.head {
    padding-left: 0;
    padding-right: 20px;
  }
}

div.billingAndSubscription {
  padding: 130px 0;
  width: 80%;
  margin: 0 auto;
}
@media all and (max-width: 1200px) {
  div.billingAndSubscription {
    width: 100%;
  }
}
div.billingAndSubscription div.wrapper h1.heading {
  font-size: 24px;
  color: var(--text-color);
  margin-bottom: 30px;
}
div.billingAndSubscription div.wrapper div.billingDetails {
  margin-bottom: 30px;
}
div.billingAndSubscription div.wrapper div.billingDetails h6.subHeading {
  font-size: 14px;
  color: var(--inner-text-color);
  font-weight: normal;
  letter-spacing: 0.3px;
  margin-bottom: 20px;
}
div.billingAndSubscription div.wrapper div.billingDetails div.billingDetailsContainer {
  border: 3px solid var(--border-color);
  padding: 20px;
  display: flex;
  justify-content: space-between;
}
div.billingAndSubscription div.wrapper div.billingDetails div.billingDetailsContainer span.head {
  display: block;
  color: var(--inner-text-color);
  margin-bottom: 10px;
}
div.billingAndSubscription div.wrapper div.billingDetails div.billingDetailsContainer span.value {
  display: block;
  color: var(--text-color);
  font-size: 14px;
}
div.billingAndSubscription div.wrapper div.billingDetails div.billingDetailsContainer span.value.bold {
  font-weight: 600;
  margin-bottom: 20px;
}
div.billingAndSubscription div.wrapper div.billingDetails div.billingDetailsContainer .billingButtonContainer {
  display: flex;
  padding: 18px 20px;
  border-radius: 4px;
  align-items: center;
  margin-bottom: 15px;
  cursor: pointer;
  transition: 0.2s ease-in-out;
  justify-content: center;
  position: relative;
  max-width: 20rem;
  margin: 30px auto 0;
}
div.billingAndSubscription div.wrapper div.billingDetails div.billingDetailsContainer .billingButtonContainer:hover div.background {
  transform: scale(1.0476190476, 1.0357142857);
}
div.billingAndSubscription div.wrapper div.billingDetails div.billingDetailsContainer .billingButtonContainer div.background {
  background: var(--text-hover);
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border-radius: 4px;
  transition: 0.1s ease-in-out;
  z-index: 1;
}
div.billingAndSubscription div.wrapper div.billingDetails div.billingDetailsContainer .billingButtonContainer button {
  background: transparent;
  font-size: 15px;
  color: var(--text-color);
  font-weight: 600;
  position: relative;
  z-index: 2;
  cursor: pointer;
}
@media all and (max-width: 768px) {
  div.billingAndSubscription div.wrapper div.billingDetails div.billingDetailsContainer {
    flex-direction: column;
  }
}
div.billingAndSubscription div.wrapper div.subscriptionDetails ul.subscriptionContainer li.head {
  display: flex;
  color: var(--inner-text-color);
  font-size: 14px;
}
@media all and (max-width: 768px) {
  div.billingAndSubscription div.wrapper div.subscriptionDetails ul.subscriptionContainer li.head {
    display: none;
  }
}
div.billingAndSubscription div.wrapper div.subscriptionDetails ul.subscriptionContainer li {
  border-bottom: 1px solid var(--border-color);
  display: flex;
  padding: 6px;
  color: var(--text-color);
  font-size: 14px;
}
div.billingAndSubscription div.wrapper div.subscriptionDetails ul.subscriptionContainer li div.left {
  display: flex;
  width: 80%;
}
@media all and (max-width: 1200px) {
  div.billingAndSubscription div.wrapper div.subscriptionDetails ul.subscriptionContainer li div.left {
    width: 100%;
  }
}
@media all and (max-width: 768px) {
  div.billingAndSubscription div.wrapper div.subscriptionDetails ul.subscriptionContainer li div.left {
    flex-direction: column;
  }
}
div.billingAndSubscription div.wrapper div.subscriptionDetails ul.subscriptionContainer li div.left div.date {
  width: 17%;
}
@media all and (max-width: 768px) {
  div.billingAndSubscription div.wrapper div.subscriptionDetails ul.subscriptionContainer li div.left div.date {
    width: 100%;
    margin-bottom: 10px;
  }
}
div.billingAndSubscription div.wrapper div.subscriptionDetails ul.subscriptionContainer li div.left div.transactionType {
  width: 25%;
}
@media all and (max-width: 768px) {
  div.billingAndSubscription div.wrapper div.subscriptionDetails ul.subscriptionContainer li div.left div.transactionType {
    width: 100%;
  }
}
div.billingAndSubscription div.wrapper div.subscriptionDetails ul.subscriptionContainer li div.left div.servicePeriod {
  width: 25%;
}
@media all and (max-width: 768px) {
  div.billingAndSubscription div.wrapper div.subscriptionDetails ul.subscriptionContainer li div.left div.servicePeriod {
    width: 100%;
    margin-bottom: 10px;
  }
}
div.billingAndSubscription div.wrapper div.subscriptionDetails ul.subscriptionContainer li div.left div.paymentMethod {
  width: 25%;
}
@media all and (max-width: 768px) {
  div.billingAndSubscription div.wrapper div.subscriptionDetails ul.subscriptionContainer li div.left div.paymentMethod {
    width: 100%;
    margin-bottom: 10px;
  }
}
div.billingAndSubscription div.wrapper div.subscriptionDetails ul.subscriptionContainer li div.left div.subscriptionName {
  width: 25%;
}
@media all and (max-width: 980px) {
  div.billingAndSubscription div.wrapper div.subscriptionDetails ul.subscriptionContainer li div.left div.subscriptionName {
    width: 30%;
  }
}
@media all and (max-width: 768px) {
  div.billingAndSubscription div.wrapper div.subscriptionDetails ul.subscriptionContainer li div.left div.subscriptionName {
    width: 100%;
  }
}
div.billingAndSubscription div.wrapper div.subscriptionDetails ul.subscriptionContainer li div.right {
  width: 20%;
  text-align: right;
}
@media all and (max-width: 980px) {
  div.billingAndSubscription div.wrapper div.subscriptionDetails ul.subscriptionContainer li div.right {
    width: 8%;
  }
}
div.billingAndSubscription div.wrapper div.bottom {
  margin-top: 30px;
}
div.billingAndSubscription div.wrapper div.bottom p {
  color: var(--inner-text-color);
  font-size: 12px;
}
div.billingAndSubscription div.wrapper div.bottom div.buttonContainer {
  display: flex;
  padding: 18px 20px;
  border-radius: 4px;
  align-items: center;
  margin-bottom: 15px;
  cursor: pointer;
  transition: 0.2s ease-in-out;
  justify-content: center;
  position: relative;
  width: 20rem;
  margin: 30px auto 0;
}
@media all and (max-width: 640px) {
  div.billingAndSubscription div.wrapper div.bottom div.buttonContainer {
    width: 15rem;
  }
}
@media all and (max-width: 480px) {
  div.billingAndSubscription div.wrapper div.bottom div.buttonContainer {
    width: 10rem;
  }
}
div.billingAndSubscription div.wrapper div.bottom div.buttonContainer:hover div.background {
  transform: scale(1.0476190476, 1.0357142857);
}
div.billingAndSubscription div.wrapper div.bottom div.buttonContainer div.background {
  background: var(--text-hover);
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border-radius: 4px;
  transition: 0.1s ease-in-out;
  z-index: 1;
}
div.billingAndSubscription div.wrapper div.bottom div.buttonContainer button {
  background: transparent;
  font-size: 15px;
  color: var(--text-color);
  font-weight: 600;
  position: relative;
  z-index: 2;
  cursor: pointer;
}

div.streamingActivity {
  padding: 130px 0;
  width: 80%;
  margin: 0 auto;
}
@media all and (max-width: 1200px) {
  div.streamingActivity {
    width: 100%;
  }
}
div.streamingActivity h1.heading {
  font-size: 24px;
  color: var(--text-color);
  margin-bottom: 30px;
}
div.streamingActivity p {
  font-size: 18px;
  color: var(--inner-text-color);
  margin-bottom: 30px;
}
div.streamingActivity ul.recentDeviceContainer li {
  border-bottom: 1px dashed #f5deb3;
  padding-bottom: 30px;
  margin-bottom: 30px;
}
div.streamingActivity ul.recentDeviceContainer li span {
  color: #f5deb3;
  display: block;
  font-size: 14px;
}
div.streamingActivity ul.recentDeviceContainer li span:first-child {
  margin-bottom: 10px;
}
div.streamingActivity div.buttonContainer {
  display: flex;
  padding: 18px 20px;
  border-radius: 4px;
  align-items: center;
  margin-bottom: 15px;
  cursor: pointer;
  transition: 0.2s ease-in-out;
  justify-content: center;
  position: relative;
  width: 20rem;
  margin: 30px auto 0;
}
@media all and (max-width: 640px) {
  div.streamingActivity div.buttonContainer {
    width: 15rem;
  }
}
@media all and (max-width: 480px) {
  div.streamingActivity div.buttonContainer {
    width: 10rem;
  }
}
div.streamingActivity div.buttonContainer:hover div.background {
  transform: scale(1.0476190476, 1.0357142857);
}
div.streamingActivity div.buttonContainer div.background {
  background: var(--text-hover);
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border-radius: 4px;
  transition: 0.1s ease-in-out;
  z-index: 1;
}
div.streamingActivity div.buttonContainer button {
  background: transparent;
  font-size: 15px;
  color: var(--text-color);
  font-weight: 600;
  position: relative;
  z-index: 2;
  cursor: pointer;
}

div.liveSection {
  width: 100%;
  margin-top: 30px;
}
div.liveSection h1.heading {
  font-size: 28px;
  color: var(--text-color);
  margin-bottom: 10px;
}
div.liveSection div.videoContainer {
  width: 100%;
  position: relative;
}
div.liveSection div.videoContainer video {
  display: block;
  width: 100%;
}
@media all and (min-width: 768px) {
  div.liveSection div.videoContainer:hover div.bottom div.details {
    transform: translateY(0);
  }
}
div.liveSection div.videoContainer div.bottom {
  width: 90%;
  position: absolute;
  bottom: 40px;
  inset-inline-start: 50%;
  transform: translateX(-50%);
  height: 200px;
  overflow: hidden;
}
@media all and (max-width: 768px) {
  div.liveSection div.videoContainer div.bottom {
    position: relative;
    bottom: unset;
    inset-inline-start: unset;
    transform: unset;
    width: 100%;
    height: auto;
  }
}
div.liveSection div.videoContainer div.bottom div.details {
  display: flex;
  justify-content: space-between;
  height: 100%;
  transform: translateY(200px);
  transition: 0.3s ease-in-out;
  background-color: rgba(41, 36, 35, 0.6);
  padding: 20px;
  border-radius: 8px;
}
@media all and (max-width: 768px) {
  div.liveSection div.videoContainer div.bottom div.details {
    flex-direction: column;
    transform: unset;
  }
}
div.liveSection div.videoContainer div.bottom div.details h1.heading {
  font-size: 28px;
  color: var(--text-color);
  margin-bottom: 10px;
}
@media all and (max-width: 768px) {
  div.liveSection div.videoContainer div.bottom div.details h1.heading {
    font-size: 22px;
  }
}
div.liveSection div.videoContainer div.bottom div.details h1.title {
  font-size: 20px;
  color: var(--text-hover);
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
div.liveSection div.videoContainer div.bottom div.details span.time {
  font-size: 14px;
  color: var(--inner-text-color);
  display: inline-block;
  margin-bottom: 10px;
}
div.liveSection div.videoContainer div.bottom div.details p.description {
  color: var(--text-color);
  font-size: 14px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2; /* number of lines to show */
  line-clamp: 2;
  -webkit-box-orient: vertical;
}
div.liveSection div.videoContainer div.bottom div.details div.left {
  width: 48%;
  height: 100%;
}
@media all and (max-width: 768px) {
  div.liveSection div.videoContainer div.bottom div.details div.left {
    width: 100%;
    margin-bottom: 20px;
  }
}
div.liveSection div.videoContainer div.bottom div.details div.right {
  width: 48%;
  height: 100%;
}
@media all and (max-width: 768px) {
  div.liveSection div.videoContainer div.bottom div.details div.right {
    width: 100%;
  }
}

div.showsRow {
  padding-top: 40px;
}
div.showsRow h1.heading {
  font-size: 25px;
  color: var(--text-color);
  margin-bottom: 20px;
}
div.showsRow li.showall {
  margin-bottom: 10px;
}
div.showsRow li.showall button {
  background-color: transparent;
  color: var(--text-color);
  font-size: 13px;
  cursor: pointer;
}
div.showsRow li.showall button:hover {
  color: var(--text-hover);
}
div.showsRow div.seasonContainer {
  border: 1px solid var(--border-color);
  border-radius: 8px;
  width: 20rem;
  margin-bottom: 20px;
  background: transparent;
  padding: 15px 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  color: var(--text-color);
  cursor: pointer;
}
@media all and (max-width: 480px) {
  div.showsRow div.seasonContainer {
    width: 100%;
  }
}
div.showsRow div.seasonContainer svg {
  width: 26px !important;
  height: 26px !important;
  transition: 0.3s ease-in-out;
}
div.showsRow div.seasonContainer svg.active {
  transform: rotate(-180deg);
}
div.showsRow div.seasonContainer.zIndexController div.listContainer {
  z-index: 9;
}
div.showsRow div.seasonContainer div.listContainer {
  height: 200px;
  overflow-y: scroll;
  position: absolute;
  top: 55px;
  width: 100%;
  left: 0;
  right: 0;
  z-index: -1;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
  cursor: pointer;
}
div.showsRow div.seasonContainer div.listContainer::-webkit-scrollbar {
  display: none;
}
div.showsRow div.seasonContainer div.listContainer.scroll {
  -ms-overflow-style: unset; /* IE and Edge */
  scrollbar-width: unset; /* Firefox */
}
div.showsRow div.seasonContainer div.listContainer.scroll::-webkit-scrollbar {
  display: block;
}
div.showsRow div.seasonContainer div.listContainer.scroll::-webkit-scrollbar {
  width: 5px;
  border-radius: 30px;
}
div.showsRow div.seasonContainer div.listContainer.scroll::-webkit-scrollbar-thumb {
  background-color: var(--bg-color);
  border-radius: 30px;
}
div.showsRow div.seasonContainer div.listContainer.scroll::-webkit-scrollbar-track {
  background-color: var(--grey-color);
}
div.showsRow div.seasonContainer div.list {
  border-bottom-right-radius: 8px;
  border-bottom-left-radius: 8px;
  display: none;
}
div.showsRow div.seasonContainer div.list.active {
  display: block;
}
div.showsRow div.seasonContainer div.list div.item {
  width: 100%;
  padding: 8px 10px;
  border-bottom: 1px solid var(--border-color);
  background-color: #1c1f29;
}
div.showsRow div.seasonContainer div.list div.item:last-child {
  border-bottom-right-radius: 8px;
  border-bottom-left-radius: 8px;
}
div.showsRow div.seasonContainer div.list div.item span {
  font-size: 16px;
  color: var(--text-color);
}

div.showsRow.rtl {
  direction: rtl;
  text-align: right;
}
div.showsRow.rtl .categoryNameContainer svg {
  transform: rotate(180deg);
}

div.showsRow.ltr {
  direction: ltr;
  text-align: left;
}
div.showsRow.ltr .heading {
  direction: rtl;
  text-align: right;
}
div.showsRow.ltr .show {
  direction: rtl;
  text-align: right;
}

div.subcriptionPage {
  padding: 150px 0;
}
div.subcriptionPage h1.heading {
  text-align: center;
  font-size: 34px;
  color: var(--text-color);
}
div.subcriptionPage div.subscriptionContainer {
  width: 90%;
  margin: 30px auto 0;
  overflow-x: scroll;
}
div.subcriptionPage div.subscriptionContainer table {
  width: 100%;
  border-collapse: collapse;
}
div.subcriptionPage div.subscriptionContainer table thead tr th {
  width: 80%;
}
div.subcriptionPage div.subscriptionContainer table thead tr th button {
  display: inline-block;
  padding: 10px 25px;
  border-radius: 30px;
  color: var(--text-color);
  cursor: pointer;
}
div.subcriptionPage div.subscriptionContainer table thead tr th button.active {
  background-color: var(--text-hover);
}
div.subcriptionPage div.subscriptionContainer table thead tr th button.no-active {
  background-color: var(--inner-text-color);
}
div.subcriptionPage div.subscriptionContainer table tbody tr {
  border-bottom: 1px solid var(--border-color);
}
div.subcriptionPage div.subscriptionContainer table tbody tr td {
  padding: 10px 0;
}
div.subcriptionPage div.subscriptionContainer table tbody tr td.big span {
  color: var(--text-color);
}
div.subcriptionPage div.subscriptionContainer table tbody tr td.value span {
  display: block;
  text-align: center;
}
div.subcriptionPage div.subscriptionContainer table tbody tr td.value span.no-active {
  color: var(--inner-text-color);
}
div.subcriptionPage div.subscriptionContainer table tbody tr td.value span.no-active svg {
  stroke: var(--inner-text-color) !important;
}
div.subcriptionPage div.subscriptionContainer table tbody tr td.value span.active {
  color: var(--text-hover);
}
div.subcriptionPage div.subscriptionContainer table tbody tr td.value span.active svg {
  stroke: var(--text-hover) !important;
}
div.subcriptionPage div.subscriptionContainer div.buyNowButtonContainer {
  display: flex;
  padding: 18px 20px;
  border-radius: 4px;
  align-items: center;
  margin-bottom: 15px;
  cursor: pointer;
  transition: 0.2s ease-in-out;
  justify-content: center;
  position: relative;
  width: 15rem;
  margin: 50px auto 0;
}
@media all and (max-width: 640px) {
  div.subcriptionPage div.subscriptionContainer div.buyNowButtonContainer {
    width: 15rem;
  }
}
@media all and (max-width: 480px) {
  div.subcriptionPage div.subscriptionContainer div.buyNowButtonContainer {
    width: 10rem;
  }
}
div.subcriptionPage div.subscriptionContainer div.buyNowButtonContainer:hover div.background {
  transform: scale(1.0476190476, 1.0357142857);
}
div.subcriptionPage div.subscriptionContainer div.buyNowButtonContainer div.background {
  background: var(--text-hover);
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border-radius: 4px;
  transition: 0.1s ease-in-out;
  z-index: 1;
}
div.subcriptionPage div.subscriptionContainer div.buyNowButtonContainer button {
  background: transparent;
  font-size: 15px;
  color: var(--text-color);
  font-weight: 600;
  position: relative;
  z-index: 2;
  cursor: pointer;
}

.ontop {
  width: 100%;
  height: 100%;
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10;
  background-color: rgba(0, 0, 0, 0.7);
}

.align-center {
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  position: fixed !important;
}

.min-ht {
  min-height: 25%;
}

div.paymentConfirm {
  text-align: center;
  position: absolute;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.7);
}
div.paymentConfirm div.paymentConfirmContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #fff;
  width: 40%;
  margin: 0 auto;
  position: fixed;
  padding: 34px 20px 30px;
  text-align: center;
  border-radius: 8px;
  z-index: 10;
}
@media all and (max-width: 1200px) {
  div.paymentConfirm div.paymentConfirmContainer {
    width: 45%;
  }
}
@media all and (max-width: 980px) {
  div.paymentConfirm div.paymentConfirmContainer {
    width: 56%;
  }
}
@media all and (max-width: 768px) {
  div.paymentConfirm div.paymentConfirmContainer {
    width: 70%;
  }
}
@media (max-width: 640px) {
  div.paymentConfirm div.paymentConfirmContainer {
    width: 86%;
  }
}
@media (max-width: 480px) {
  div.paymentConfirm div.paymentConfirmContainer {
    width: 88%;
  }
}
div.paymentConfirm div.paymentConfirmContainer h5 {
  color: #000;
  margin-bottom: 20px;
  font-size: 26px;
}
@media (max-width: 480px) {
  div.paymentConfirm div.paymentConfirmContainer h5 {
    font-size: 20px;
  }
}
div.paymentConfirm div.paymentConfirmContainer .button-container {
  padding: 10px 0;
}
div.paymentConfirm div.paymentConfirmContainer .amount-summary {
  font-family: Arial, sans-serif;
  padding: 0 12px;
  font-size: 16px;
}
@media (max-width: 480px) {
  div.paymentConfirm div.paymentConfirmContainer .amount-summary {
    font-size: 12.4px;
  }
}
div.paymentConfirm div.paymentConfirmContainer .amount-summary .row {
  display: flex;
  justify-content: space-between;
  padding: 18px 0;
}
@media (max-width: 480px) {
  div.paymentConfirm div.paymentConfirmContainer .amount-summary .row {
    padding: 8px 0;
  }
}
div.paymentConfirm div.paymentConfirmContainer .amount-summary .row .label {
  text-align: start;
  padding-inline-end: 48px;
}
div.paymentConfirm div.paymentConfirmContainer .amount-summary .row .value {
  text-align: end;
}
div.paymentConfirm div.paymentConfirmContainer .amount-summary .row.amount-due {
  font-weight: bold;
}
div.paymentConfirm div.paymentConfirmContainer div.df {
  display: flex;
}
div.paymentConfirm div.paymentConfirmContainer div.df button {
  width: 100%;
  outline: none;
  color: var(--text-color);
  background: var(--text-hover);
  color: #fff;
  padding: 10px 0;
  border-radius: 5px;
  transition: 0.3s ease-in-out;
  margin: 0 22px;
  cursor: pointer;
}
div.paymentConfirm div.paymentConfirmContainer div.df button:hover {
  color: var(--text-color);
  background: var(--text-hover);
  transform: scale(1.0476190476, 1.0357142857);
}

div.subscriptionList {
  padding: 100px 0;
}
div.subscriptionList div.arrowContainer {
  width: 5%;
  cursor: pointer;
}
div.subscriptionList div.arrowContainer h3 {
  width: 100%;
  background: transparent;
  cursor: pointer;
}
div.subscriptionList div.arrowContainer h3 svg {
  width: 30px;
  height: 100%;
  fill: white !important;
}
div.subscriptionList div.arrowContainer:hover {
  cursor: pointer;
  transform: scale(1.2);
}
div.subscriptionList h1.heading {
  font-size: 28px;
  text-align: center;
  color: var(--inner-text-color);
}
div.subscriptionList div.subscriptionDetails {
  border: 1px solid var(--border-color);
  box-shadow: rgb(229, 9, 2) 0px 13px 27px -5px, rgb(229, 9, 2) 0px 8px 0px -47px;
  padding: 15px 28px;
  margin-top: 50px;
  color: var(--inner-text-color);
}
div.subscriptionList div.subscriptionDetails h2.heading {
  font-size: 22px;
  color: var(--inner-text-color);
  text-align: center;
}
div.subscriptionList div.subscriptionDetails ul.conditions {
  list-style: square;
  margin-top: 30px;
}
div.subscriptionList div.subscriptionDetails ul.conditions li {
  line-height: 28px;
}
div.subscriptionList div.subscriptionDetails ul.conditions li span {
  font-size: 16px;
}
div.subscriptionList div.skipButton {
  padding: 30px 0 0;
  display: flex;
  justify-content: flex-end;
}
div.subscriptionList div.skipButton button {
  display: block;
  border: none;
  outline: none;
  padding: 10px 25px;
  background-color: var(--secondary-bg-color);
  border: 1px solid var(--border-color);
  color: #fff;
  border-radius: 3px;
  transition: 0.5s all ease-in-out;
  cursor: pointer;
}
div.subscriptionList div.skipButton button:hover {
  background-color: transparent;
}
div.subscriptionList div.skipButton button span {
  display: flex;
  align-items: center;
  justify-content: center;
}
@media all and (max-width: 640px) {
  div.subscriptionList div.skipButton button {
    width: 100%;
  }
}
div.subscriptionList div.pricing {
  margin-top: 30px;
}
div.subscriptionList div.pricing ul {
  display: flex;
  list-style: none;
  flex-wrap: wrap;
}
div.subscriptionList div.pricing ul li {
  margin-inline-end: 20px;
  margin-bottom: 30px;
  padding: 60px 60px;
  border: 1px solid var(--border-color);
  width: 23.8%;
  text-align: center;
  box-shadow: rgba(229, 9, 20, 0.24) 0px 3px 8px;
}
div.subscriptionList div.pricing ul li:last-child {
  margin-inline-end: 0;
}
div.subscriptionList div.pricing ul li:nth-child(4n) {
  margin-inline-end: 0;
}
@media all and (max-width: 1400px) {
  div.subscriptionList div.pricing ul li {
    width: 23.5%;
  }
}
@media all and (max-width: 1200px) {
  div.subscriptionList div.pricing ul li {
    width: 31.5%;
  }
  div.subscriptionList div.pricing ul li:nth-child(4n) {
    margin-inline-end: 20px;
  }
  div.subscriptionList div.pricing ul li:nth-child(3n) {
    margin-inline-end: 0;
  }
}
@media all and (max-width: 980px) {
  div.subscriptionList div.pricing ul li {
    width: 48%;
  }
  div.subscriptionList div.pricing ul li:nth-child(3n) {
    margin-inline-end: 20px;
  }
  div.subscriptionList div.pricing ul li:nth-child(2n) {
    margin-inline-end: 0;
  }
}
@media all and (max-width: 640px) {
  div.subscriptionList div.pricing ul li {
    width: 100%;
    margin-inline-end: 0;
  }
  div.subscriptionList div.pricing ul li:nth-child(3n) {
    margin-inline-end: 0;
  }
  div.subscriptionList div.pricing ul li:nth-child(2n) {
    margin-inline-end: 0;
  }
}
div.subscriptionList div.pricing ul li button {
  display: block;
  border: none;
  outline: none;
  padding: 10px 25px;
  background-color: var(--secondary-bg-color);
  border: 1px solid var(--border-color);
  margin: 0 auto 20px;
  color: #fff;
  border-radius: 30px;
  transition: 0.5s all ease-in-out;
  cursor: pointer;
}
div.subscriptionList div.pricing ul li button:hover {
  background-color: transparent;
}
div.subscriptionList div.pricing ul li span.category {
  display: block;
  margin-bottom: 10px;
  color: var(--inner-text-color);
}
div.subscriptionList div.pricing ul li span.price {
  display: block;
  color: var(--inner-text-color);
}
div.subscriptionList div.pricing ul li span.price.discount span.priceCut {
  margin-inline-end: 10px;
  text-decoration: line-through;
  display: block;
}
div.subscriptionList div.pricing ul li span.trialprice {
  font-weight: bold;
  background: #151c30;
  padding: 10px;
  color: #d0ac6a;
  border-radius: 6px;
}

/* div.subscriptionList div.subscriptionDetails ul.conditions li::before { 
    content: "-";
    margin-right: 4px;
} */
@media all and (max-width: 1200px) {
  div.subscriptionList div.subscriptionDetails h2.heading {
    font-size: 20px;
  }
  div.subscriptionList div.subscriptionDetails ul.conditions li {
    font-size: 15px;
  }
}
@media all and (max-width: 980px) {
  div.subscriptionList div.subscriptionDetails h2.heading {
    font-size: 19px;
  }
}
@media all and (max-width: 768px) {
  div.subscriptionList div.subscriptionDetails h2.heading {
    font-size: 18px;
  }
}
@media all and (max-width: 640px) {
  div.subscriptionList div.pricing ul {
    flex-direction: column;
  }
  div.subscriptionList div.pricing ul li:last-child {
    margin-bottom: 0;
  }
  div.subscriptionList div.pricing ul li button {
    margin: 0 auto 20px;
  }
}
@media all and (max-width: 480px) {
  div.subscriptionList div.pricing ul li {
    width: 100%;
  }
  div.subscriptionList h1.heading {
    font-size: 25px;
  }
}
div.paymentScreen {
  padding: 150px 0;
}
div.paymentScreen .paypal-button-container {
  min-width: 326px !important;
  max-width: 284px !important;
}
div.paymentScreen span.success {
  color: var(--success-color);
}
div.paymentScreen div.wrapper h1.heading {
  text-align: center;
  font-size: 34px;
  color: var(--text-color);
  margin-bottom: 30px;
}
div.paymentScreen div.wrapper div.mainContainer {
  width: 90%;
  margin: 0 auto;
  display: flex;
}
@media all and (max-width: 980px) {
  div.paymentScreen div.wrapper div.mainContainer {
    flex-direction: column;
    width: 80%;
  }
}
@media all and (max-width: 640px) {
  div.paymentScreen div.wrapper div.mainContainer {
    width: 100%;
  }
}
div.paymentScreen div.wrapper div.mainContainer div.left {
  width: 60%;
}
@media all and (max-width: 980px) {
  div.paymentScreen div.wrapper div.mainContainer div.left {
    width: 100%;
  }
}
div.paymentScreen div.wrapper div.mainContainer div.left div.couponCodeContainer label {
  font-size: 20px;
  display: block;
  color: var(--text-color);
}
div.paymentScreen div.wrapper div.mainContainer div.left div.couponCodeContainer div.inputContainer {
  margin-top: 20px;
  display: flex;
  align-items: center;
  width: 35rem;
}
@media all and (max-width: 1200px) {
  div.paymentScreen div.wrapper div.mainContainer div.left div.couponCodeContainer div.inputContainer {
    width: 29rem;
  }
}
@media all and (max-width: 640px) {
  div.paymentScreen div.wrapper div.mainContainer div.left div.couponCodeContainer div.inputContainer {
    width: 100%;
  }
}
@media all and (max-width: 480px) {
  div.paymentScreen div.wrapper div.mainContainer div.left div.couponCodeContainer div.inputContainer {
    flex-direction: column;
  }
}
div.paymentScreen div.wrapper div.mainContainer div.left div.couponCodeContainer div.inputContainer input {
  outline: none;
  border-radius: 5px;
  font-size: 14px;
  background: #000;
  text-align: start;
  padding: 20px 15px;
  border: 1px solid var(--inner-text-color);
  caret-color: var(--text-color);
  display: block;
  width: 100%;
  color: var(--text-color);
  margin-inline-end: 20px;
}
div.paymentScreen div.wrapper div.mainContainer div.left div.couponCodeContainer div.inputContainer input.gaia {
  background: none;
}
div.paymentScreen div.wrapper div.mainContainer div.left div.couponCodeContainer div.inputContainer input:focus {
  border: 1px solid var(--text-color);
}
@media all and (max-width: 640px) {
  div.paymentScreen div.wrapper div.mainContainer div.left div.couponCodeContainer div.inputContainer input {
    padding: 16px 15px;
  }
}
@media all and (max-width: 480px) {
  div.paymentScreen div.wrapper div.mainContainer div.left div.couponCodeContainer div.inputContainer input {
    margin-bottom: 20px;
  }
}
div.paymentScreen div.wrapper div.mainContainer div.left div.couponCodeContainer div.inputContainer div.buttonContainer {
  display: flex;
  padding: 18px 20px;
  border-radius: 4px;
  align-items: center;
  cursor: pointer;
  transition: 0.2s ease-in-out;
  justify-content: center;
  position: relative;
  width: 15rem;
  margin: 0 auto;
}
@media all and (max-width: 640px) {
  div.paymentScreen div.wrapper div.mainContainer div.left div.couponCodeContainer div.inputContainer div.buttonContainer {
    width: 15rem;
    padding: 16px 20px;
  }
}
@media all and (max-width: 480px) {
  div.paymentScreen div.wrapper div.mainContainer div.left div.couponCodeContainer div.inputContainer div.buttonContainer {
    width: 10rem;
  }
}
div.paymentScreen div.wrapper div.mainContainer div.left div.couponCodeContainer div.inputContainer div.buttonContainer:hover div.background {
  transform: scale(1.0476190476, 1.0357142857);
}
div.paymentScreen div.wrapper div.mainContainer div.left div.couponCodeContainer div.inputContainer div.buttonContainer div.background {
  background: var(--text-hover);
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border-radius: 4px;
  transition: 0.1s ease-in-out;
  z-index: 1;
}
div.paymentScreen div.wrapper div.mainContainer div.left div.couponCodeContainer div.inputContainer div.buttonContainer button {
  background: transparent;
  font-size: 15px;
  color: var(--text-color);
  font-weight: 600;
  position: relative;
  z-index: 2;
  cursor: pointer;
}
div.paymentScreen div.wrapper div.mainContainer div.left div.paymentGatewayContainer {
  margin-top: 30px;
  margin-bottom: 20px;
}
div.paymentScreen div.wrapper div.mainContainer div.left div.paymentGatewayContainer h2.heading {
  font-size: 20px;
  color: var(--text-color);
  font-weight: 400;
}
div.paymentScreen div.wrapper div.mainContainer div.left div.paymentGatewayContainer ul.gateways {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}
div.paymentScreen div.wrapper div.mainContainer div.left div.paymentGatewayContainer ul.gateways li {
  margin: 10px 10px 0px 0px;
  width: 90px;
}
div.paymentScreen div.wrapper div.mainContainer div.left div.paymentGatewayContainer ul.gateways li:last-child {
  margin-inline-end: 0;
}
@media all and (max-width: 640px) {
  div.paymentScreen div.wrapper div.mainContainer div.left div.paymentGatewayContainer ul.gateways li {
    width: 70px;
  }
}
@media all and (max-width: 480px) {
  div.paymentScreen div.wrapper div.mainContainer div.left div.paymentGatewayContainer ul.gateways li {
    width: 20%;
  }
}
div.paymentScreen div.wrapper div.mainContainer div.left div.paymentGatewayContainer ul.gateways li div.imageContainer {
  width: 100%;
  transition: 0.3s ease-in-out;
  cursor: pointer;
}
div.paymentScreen div.wrapper div.mainContainer div.left div.paymentGatewayContainer ul.gateways li div.imageContainer:hover {
  transform: scale(1.1);
}
div.paymentScreen div.wrapper div.mainContainer div.left div.paymentGatewayContainer ul.gateways li div.imageContainer img {
  width: 100%;
  display: block;
  border-radius: 5px;
}
div.paymentScreen div.wrapper div.mainContainer div.right {
  width: 40%;
  border-inline-start: 2px solid var(--border-color);
  padding: 80px 40px 0;
}
@media all and (max-width: 980px) {
  div.paymentScreen div.wrapper div.mainContainer div.right {
    width: 90%;
    padding: 40px 0 0;
    border-top: 2px solid var(--border-color);
    border-inline-start: none;
  }
}
@media all and (max-width: 768px) {
  div.paymentScreen div.wrapper div.mainContainer div.right {
    width: 100%;
  }
}
div.paymentScreen div.wrapper div.mainContainer div.right iframe {
  z-index: 2 !important;
}

div.ChangePassword {
  padding: 130px 0 120px;
}
div.ChangePassword span.error {
  font-size: 14px;
  color: #E72A31;
  display: block;
  margin-top: 10px;
}
div.ChangePassword span.error.center {
  text-align: center;
}
div.ChangePassword span.success {
  font-size: 14px;
  display: block;
  text-align: center;
  margin-top: 10px;
}
div.ChangePassword h1.heading {
  font-size: 34px;
  padding-bottom: 10px;
  color: var(--text-color);
  text-align: center;
}
@media all and (max-width: 640px) {
  div.ChangePassword h1.heading {
    font-size: 22px;
    margin: 0 20px;
  }
}
div.ChangePassword div.contents {
  margin-top: 40px;
}
@media all and (max-width: 480px) {
  div.ChangePassword div.contents {
    padding: 0 10px;
  }
}
div.ChangePassword div.contents div.formSection {
  width: 30%;
  margin: 0 auto;
}
@media all and (max-width: 1200px) {
  div.ChangePassword div.contents div.formSection {
    width: 55%;
  }
}
@media all and (max-width: 980px) {
  div.ChangePassword div.contents div.formSection {
    width: 75%;
  }
}
@media all and (max-width: 640px) {
  div.ChangePassword div.contents div.formSection {
    width: 85%;
  }
}
div.ChangePassword div.contents div.formSection form div.inputContainer {
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
}
div.ChangePassword div.contents div.formSection form div.inputContainer:nth-child(3) {
  margin-bottom: 60px;
}
div.ChangePassword div.contents div.formSection form div.inputContainer label {
  color: var(--inner-text-color);
  font-size: 16px;
  display: inline-block;
  margin-bottom: 10px;
}
div.ChangePassword div.contents div.formSection form div.inputContainer input {
  outline: none;
  border-radius: 5px;
  font-size: 14px;
  background: transparent;
  text-align: left;
  padding: 20px 15px;
  border: 1px solid var(--inner-text-color);
  caret-color: var(--text-color);
  display: block;
  width: 100%;
  color: var(--text-color);
}
div.ChangePassword div.contents div.formSection form div.inputContainer input:focus {
  border: 1px solid var(--text-color);
}
@media all and (max-width: 640px) {
  div.ChangePassword div.contents div.formSection form div.inputContainer input {
    padding: 16px 15px;
  }
}
div.ChangePassword div.contents div.formSection form div.buttonContainer {
  display: flex;
  padding: 18px 20px;
  border-radius: 4px;
  align-items: center;
  margin-bottom: 15px;
  cursor: pointer;
  transition: 0.2s ease-in-out;
  justify-content: center;
  position: relative;
  width: 20rem;
  margin: 30px auto 0;
}
@media all and (max-width: 640px) {
  div.ChangePassword div.contents div.formSection form div.buttonContainer {
    width: 15rem;
  }
}
@media all and (max-width: 480px) {
  div.ChangePassword div.contents div.formSection form div.buttonContainer {
    width: 10rem;
  }
}
div.ChangePassword div.contents div.formSection form div.buttonContainer:hover div.background {
  transform: scale(1.0476190476, 1.0357142857);
}
div.ChangePassword div.contents div.formSection form div.buttonContainer div.background {
  background: var(--text-hover);
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border-radius: 4px;
  transition: 0.1s ease-in-out;
  z-index: 1;
}
div.ChangePassword div.contents div.formSection form div.buttonContainer button {
  background: transparent;
  font-size: 15px;
  color: var(--text-color);
  font-weight: 600;
  position: relative;
  z-index: 2;
  cursor: pointer;
}

div.searchPage {
  padding: 130px 0 40px;
}
div.searchPage div.top {
  padding-bottom: 60px;
  border-bottom: 1px solid var(--border-color);
}
div.searchPage div.top h1.heading {
  font-size: 46px;
  margin-bottom: 10px;
  color: var(--text-color);
}
@media all and (max-width: 640px) {
  div.searchPage div.top h1.heading {
    font-size: 36px;
  }
}
div.searchPage div.top p.desc {
  font-size: 18px;
  color: var(--inner-text-color);
}
@media all and (max-width: 640px) {
  div.searchPage div.top p.desc {
    font-size: 14px;
  }
}
div.searchPage div.bottom {
  margin-top: 40px;
}
div.searchPage div.bottom div.categories {
  display: flex;
  margin-bottom: 20px;
}
div.searchPage div.bottom div.categories button {
  background-color: transparent;
  outline: none;
  color: var(--white-color);
  padding: 8px 30px;
  border-radius: 30px;
  font-size: 18px;
  margin-inline-end: 10px;
  cursor: pointer;
  border: 1px solid transparent;
}
@media all and (max-width: 1600px) {
  div.searchPage div.bottom div.categories button {
    font-size: 15px;
  }
}
@media all and (max-width: 1200px) {
  div.searchPage div.bottom div.categories button {
    font-size: 12px;
  }
}
@media all and (max-width: 768px) {
  div.searchPage div.bottom div.categories button {
    padding: 8px 15px;
  }
}
@media all and (max-width: 640px) {
  div.searchPage div.bottom div.categories button {
    padding: 8px 6px;
  }
}
@media all and (max-width: 480px) {
  div.searchPage div.bottom div.categories button {
    padding: 8px 4px;
    font-size: 8px;
  }
}
@media all and (max-width: 360px) {
  div.searchPage div.bottom div.categories button {
    padding: 8px 2px;
  }
}
div.searchPage div.bottom div.categories button.gaia-categoryButton {
  color: var(--text-color);
}
div.searchPage div.bottom div.categories button.gaia-categoryButton:hover {
  color: var(--white-color);
}
div.searchPage div.bottom div.categories button:last-child {
  margin-inline-end: 0;
}
div.searchPage div.bottom div.categories button.active {
  border: 1px solid var(--grey-color);
}
div.searchPage div.bottom div.categories button.gaia-active {
  border: 1px solid var(--grey-color);
  color: var(--text-color);
}
div.searchPage div.bottom div.categories button:hover {
  background-color: var(--button-hover);
}
div.searchPage div.bottom div.itemsContainer {
  display: flex;
  flex-wrap: wrap;
}
div.searchPage div.bottom div.itemsContainer div.items.rowCount3 {
  width: 32.5%;
  margin-inline-end: 13px;
  margin-bottom: 30px;
}
div.searchPage div.bottom div.itemsContainer div.items.rowCount3:nth-child(3n) {
  margin-inline-end: 0;
}
@media all and (max-width: 1200px) {
  div.searchPage div.bottom div.itemsContainer div.items.rowCount3 {
    width: 32%;
  }
}
div.searchPage div.bottom div.itemsContainer div.items.rowCount4 {
  width: 24%;
  margin-inline-end: 13px;
  margin-bottom: 30px;
}
div.searchPage div.bottom div.itemsContainer div.items.rowCount4:nth-child(4n) {
  margin-inline-end: 0;
}
@media all and (max-width: 1200px) {
  div.searchPage div.bottom div.itemsContainer div.items.rowCount4 {
    width: 23.8%;
  }
}
div.searchPage div.bottom div.itemsContainer div.items.rowCount5 {
  width: 19%;
  margin-inline-end: 13px;
  margin-bottom: 30px;
}
div.searchPage div.bottom div.itemsContainer div.items.rowCount5:nth-child(5n) {
  margin-inline-end: 0;
}
@media all and (max-width: 1200px) {
  div.searchPage div.bottom div.itemsContainer div.items.rowCount5 {
    width: 18.5%;
  }
}
div.searchPage div.bottom div.itemsContainer div.items.rowCount6 {
  width: 15.8%;
  margin-inline-end: 13px;
  margin-bottom: 30px;
}
div.searchPage div.bottom div.itemsContainer div.items.rowCount6:nth-child(6n) {
  margin-inline-end: 0;
}
@media all and (max-width: 1400px) {
  div.searchPage div.bottom div.itemsContainer div.items.rowCount6 {
    width: 15.5%;
  }
}
@media all and (max-width: 1200px) {
  div.searchPage div.bottom div.itemsContainer div.items.rowCount6 {
    width: 15%;
  }
}
div.searchPage div.bottom div.itemsContainer div.items.rowCount7 {
  width: 13.9%;
  margin-inline-end: 6px;
  margin-bottom: 30px;
}
div.searchPage div.bottom div.itemsContainer div.items.rowCount7:nth-child(7n) {
  margin-inline-end: 0;
}
@media all and (min-width: 981px) {
  div.searchPage div.bottom div.itemsContainer div.items.rowCount7.landscape:hover div.showCardContainer .square {
    position: absolute;
    z-index: 9999999;
    transform: translateX(60px) translateY(-60px) scale(1.1);
  }
  div.searchPage div.bottom div.itemsContainer div.items.rowCount7.landscape:hover div.showCardContainer .square div.metaData {
    display: block;
    visibility: visible !important;
    animation: fadeIn 0.3s ease-in-out;
  }
  div.searchPage div.bottom div.itemsContainer div.items.rowCount7.landscape:hover:nth-child(7n) div.showCardContainer div.square {
    transform: translateX(-60px) translateY(-60px) scale(1.1);
  }
}
@media all and (max-width: 980px) {
  div.searchPage div.bottom div.itemsContainer div.items.rowCount7.landscape {
    width: 24.3%;
    margin-inline-end: 6px;
    margin-bottom: 40px;
  }
  div.searchPage div.bottom div.itemsContainer div.items.rowCount7.landscape:nth-child(6n) {
    margin-inline-end: 6px;
  }
  div.searchPage div.bottom div.itemsContainer div.items.rowCount7.landscape:nth-child(4n) {
    margin-inline-end: 0;
  }
}
@media all and (max-width: 768px) {
  div.searchPage div.bottom div.itemsContainer div.items.rowCount7.landscape {
    width: 32.3%;
  }
  div.searchPage div.bottom div.itemsContainer div.items.rowCount7.landscape:nth-child(4n) {
    margin-inline-end: 6px;
  }
  div.searchPage div.bottom div.itemsContainer div.items.rowCount7.landscape:nth-child(3n) {
    margin-inline-end: 0;
  }
}
@media all and (max-width: 640px) {
  div.searchPage div.bottom div.itemsContainer div.items.rowCount7.landscape {
    width: 48.2%;
  }
  div.searchPage div.bottom div.itemsContainer div.items.rowCount7.landscape:nth-child(3n) {
    margin-inline-end: 6px;
  }
  div.searchPage div.bottom div.itemsContainer div.items.rowCount7.landscape:nth-child(2n) {
    margin-inline-end: 0;
  }
}
@media all and (max-width: 480px) {
  div.searchPage div.bottom div.itemsContainer div.items.rowCount7.landscape {
    width: 100%;
    margin-inline-end: 0;
  }
  div.searchPage div.bottom div.itemsContainer div.items.rowCount7.landscape:nth-child(6n) {
    margin-inline-end: 0;
  }
  div.searchPage div.bottom div.itemsContainer div.items.rowCount7.landscape:nth-child(4n) {
    margin-inline-end: 0;
  }
  div.searchPage div.bottom div.itemsContainer div.items.rowCount7.landscape:nth-child(3n) {
    margin-inline-end: 0;
  }
  div.searchPage div.bottom div.itemsContainer div.items.rowCount7.landscape:nth-child(2n) {
    margin-inline-end: 0;
  }
}
@media all and (max-width: 480px) and (max-width: 360px) {
  div.searchPage div.bottom div.itemsContainer div.items.rowCount7.landscape {
    width: 100%;
    margin-inline-end: 0;
  }
  div.searchPage div.bottom div.itemsContainer div.items.rowCount7.landscape:nth-child(3n) {
    margin-inline-end: 0;
  }
}
@media all and (max-width: 2600px) {
  div.searchPage div.bottom div.itemsContainer div.items.rowCount7.portrait {
    width: 14%;
    margin-inline-end: 7px;
  }
  div.searchPage div.bottom div.itemsContainer div.items.rowCount7.portrait:nth-child(7n) {
    margin-inline-end: 0;
  }
}
@media all and (max-width: 2500px) {
  div.searchPage div.bottom div.itemsContainer div.items.rowCount7.portrait {
    width: 14%;
    margin-inline-end: 6.5px;
  }
}
@media all and (max-width: 2200px) {
  div.searchPage div.bottom div.itemsContainer div.items.rowCount7.portrait {
    width: 14%;
    margin-inline-end: 6px;
  }
}
@media all and (max-width: 1980px) {
  div.searchPage div.bottom div.itemsContainer div.items.rowCount7.portrait {
    width: 14%;
    margin-inline-end: 5px;
  }
}
@media all and (max-width: 1768px) {
  div.searchPage div.bottom div.itemsContainer div.items.rowCount7.portrait {
    width: 14%;
    margin-inline-end: 4.8px;
  }
}
@media all and (max-width: 1600px) {
  div.searchPage div.bottom div.itemsContainer div.items.rowCount7.portrait {
    width: 13.8%;
    margin-inline-end: 7px;
  }
}
@media all and (max-width: 1400px) {
  div.searchPage div.bottom div.itemsContainer div.items.rowCount7.portrait {
    width: 14%;
    margin-inline-end: 3px;
  }
}
@media all and (max-width: 1200px) {
  div.searchPage div.bottom div.itemsContainer div.items.rowCount7.portrait {
    width: 13.7%;
    margin-inline-end: 6.1px;
  }
}
@media all and (min-width: 981px) {
  div.searchPage div.bottom div.itemsContainer div.items.rowCount7.portrait:hover div.showCardContainer .square {
    position: absolute;
    z-index: 9999999;
    transform: translateX(60px) translateY(-60px) scale(1.2);
  }
  div.searchPage div.bottom div.itemsContainer div.items.rowCount7.portrait:hover div.showCardContainer .square div.metaData {
    display: block;
    visibility: visible !important;
    animation: fadeIn 0.3s ease-in-out;
  }
  div.searchPage div.bottom div.itemsContainer div.items.rowCount7.portrait:hover:nth-child(6n) div.showCardContainer div.square {
    transform: translateX(-60px) translateY(-60px) scale(1.2);
  }
}
@media all and (min-width: 981px) and (max-width: 1300px) {
  div.searchPage div.bottom div.itemsContainer div.items.rowCount7.portrait:hover:nth-child(6n) div.showCardContainer div.square {
    transform: translateX(-60px) translateY(-60px) scale(1.2);
  }
  div.searchPage div.bottom div.itemsContainer div.items.rowCount7.portrait:hover:nth-child(7n) div.showCardContainer div.square {
    transform: translateX(60px) translateY(-60px) scale(1.2);
  }
}
@media all and (min-width: 981px) {
  div.searchPage div.bottom div.itemsContainer div.items.rowCount7.portrait:hover:last-child div.showCardContainer div.square {
    transform: translateX(-60px) translateY(-60px) scale(1.2);
  }
}
@media all and (max-width: 980px) {
  div.searchPage div.bottom div.itemsContainer div.items.rowCount7.portrait {
    width: 24%;
    margin-inline-end: 6px;
    margin-bottom: 40px;
  }
  div.searchPage div.bottom div.itemsContainer div.items.rowCount7.portrait:nth-child(5n) {
    margin-inline-end: 6px;
  }
  div.searchPage div.bottom div.itemsContainer div.items.rowCount7.portrait:nth-child(4n) {
    margin-inline-end: 0;
  }
  div.searchPage div.bottom div.itemsContainer div.items.rowCount7.portrait:nth-child(6n) {
    margin-inline-end: 6px;
  }
}
@media all and (max-width: 768px) {
  div.searchPage div.bottom div.itemsContainer div.items.rowCount7.portrait {
    width: 32%;
  }
  div.searchPage div.bottom div.itemsContainer div.items.rowCount7.portrait:nth-child(4n) {
    margin-inline-end: 6px;
  }
  div.searchPage div.bottom div.itemsContainer div.items.rowCount7.portrait:nth-child(3n) {
    margin-inline-end: 0;
  }
}
@media all and (max-width: 640px) {
  div.searchPage div.bottom div.itemsContainer div.items.rowCount7.portrait {
    width: 48%;
  }
  div.searchPage div.bottom div.itemsContainer div.items.rowCount7.portrait:nth-child(3n) {
    margin-inline-end: 6px;
  }
  div.searchPage div.bottom div.itemsContainer div.items.rowCount7.portrait:nth-child(2n) {
    margin-inline-end: 0;
  }
}
div.searchPage div.bottom div.itemsContainer div.items.rowCount8 {
  width: 12%;
  margin-inline-end: 8px;
  margin-bottom: 30px;
}
div.searchPage div.bottom div.itemsContainer div.items.rowCount8:nth-child(8n) {
  margin-inline-end: 0;
}
@media all and (max-width: 2500px) {
  div.searchPage div.bottom div.itemsContainer div.items.rowCount8.landscape {
    width: 12%;
    margin-inline-end: 10px;
  }
}
@media all and (max-width: 2200px) {
  div.searchPage div.bottom div.itemsContainer div.items.rowCount8.landscape {
    width: 12%;
    margin-inline-end: 9px;
  }
}
@media all and (max-width: 1980px) {
  div.searchPage div.bottom div.itemsContainer div.items.rowCount8.landscape {
    width: 12%;
    margin-inline-end: 8px;
  }
}
@media all and (max-width: 1768px) {
  div.searchPage div.bottom div.itemsContainer div.items.rowCount8.landscape {
    width: 12%;
    margin-inline-end: 7px;
  }
}
@media all and (max-width: 1600px) {
  div.searchPage div.bottom div.itemsContainer div.items.rowCount8.landscape {
    width: 12%;
    margin-inline-end: 6px;
  }
}
@media all and (max-width: 1400px) {
  div.searchPage div.bottom div.itemsContainer div.items.rowCount8.landscape {
    width: 12%;
    margin-inline-end: 5px;
  }
}
@media all and (max-width: 1200px) {
  div.searchPage div.bottom div.itemsContainer div.items.rowCount8.landscape {
    width: 11.9%;
    margin-inline-end: 5px;
  }
}
@media all and (min-width: 981px) {
  div.searchPage div.bottom div.itemsContainer div.items.rowCount8.landscape:hover div.showCardContainer .square {
    position: absolute;
    z-index: 9999999;
    transform: translateX(60px) translateY(-60px) scale(1.1);
  }
  div.searchPage div.bottom div.itemsContainer div.items.rowCount8.landscape:hover div.showCardContainer .square div.metaData {
    display: block;
    visibility: visible !important;
    animation: fadeIn 0.3s ease-in-out;
  }
  div.searchPage div.bottom div.itemsContainer div.items.rowCount8.landscape:hover:nth-child(5n) div.showCardContainer div.square {
    transform: translateX(-60px) translateY(-60px) scale(1.1);
  }
}
@media all and (max-width: 980px) {
  div.searchPage div.bottom div.itemsContainer div.items.rowCount8.landscape {
    width: 24.3%;
    margin-inline-end: 6px;
    margin-bottom: 40px;
  }
  div.searchPage div.bottom div.itemsContainer div.items.rowCount8.landscape:nth-child(6n) {
    margin-inline-end: 6px;
  }
  div.searchPage div.bottom div.itemsContainer div.items.rowCount8.landscape:nth-child(4n) {
    margin-inline-end: 0;
  }
}
@media all and (max-width: 768px) {
  div.searchPage div.bottom div.itemsContainer div.items.rowCount8.landscape {
    width: 32.3%;
  }
  div.searchPage div.bottom div.itemsContainer div.items.rowCount8.landscape:nth-child(4n) {
    margin-inline-end: 6px;
  }
  div.searchPage div.bottom div.itemsContainer div.items.rowCount8.landscape:nth-child(3n) {
    margin-inline-end: 0;
  }
}
@media all and (max-width: 640px) {
  div.searchPage div.bottom div.itemsContainer div.items.rowCount8.landscape {
    width: 48.2%;
  }
  div.searchPage div.bottom div.itemsContainer div.items.rowCount8.landscape:nth-child(3n) {
    margin-inline-end: 6px;
  }
  div.searchPage div.bottom div.itemsContainer div.items.rowCount8.landscape:nth-child(2n) {
    margin-inline-end: 0;
  }
}
@media all and (max-width: 480px) {
  div.searchPage div.bottom div.itemsContainer div.items.rowCount8.landscape {
    width: 100%;
    margin-inline-end: 0;
  }
  div.searchPage div.bottom div.itemsContainer div.items.rowCount8.landscape:nth-child(6n) {
    margin-inline-end: 0;
  }
  div.searchPage div.bottom div.itemsContainer div.items.rowCount8.landscape:nth-child(4n) {
    margin-inline-end: 0;
  }
  div.searchPage div.bottom div.itemsContainer div.items.rowCount8.landscape:nth-child(3n) {
    margin-inline-end: 0;
  }
  div.searchPage div.bottom div.itemsContainer div.items.rowCount8.landscape:nth-child(2n) {
    margin-inline-end: 0;
  }
}
@media all and (max-width: 480px) and (max-width: 360px) {
  div.searchPage div.bottom div.itemsContainer div.items.rowCount8.landscape {
    width: 100%;
    margin-inline-end: 0;
  }
  div.searchPage div.bottom div.itemsContainer div.items.rowCount8.landscape:nth-child(3n) {
    margin-inline-end: 0;
  }
}
div.searchPage div.bottom div.itemsContainer div.items.rowCount8.portrait {
  width: 11%;
  margin-inline-end: 21px;
}
@media all and (max-width: 2600px) {
  div.searchPage div.bottom div.itemsContainer div.items.rowCount8.portrait {
    width: 12%;
    margin-inline-end: 13px;
  }
  div.searchPage div.bottom div.itemsContainer div.items.rowCount8.portrait:nth-child(8n) {
    margin-inline-end: 0;
  }
}
@media all and (max-width: 2500px) {
  div.searchPage div.bottom div.itemsContainer div.items.rowCount8.portrait {
    width: 12%;
    margin-inline-end: 11px;
  }
}
@media all and (max-width: 2200px) {
  div.searchPage div.bottom div.itemsContainer div.items.rowCount8.portrait {
    width: 12%;
    margin-inline-end: 10px;
  }
}
@media all and (max-width: 1980px) {
  div.searchPage div.bottom div.itemsContainer div.items.rowCount8.portrait {
    width: 12%;
    margin-inline-end: 9px;
  }
}
@media all and (max-width: 1768px) {
  div.searchPage div.bottom div.itemsContainer div.items.rowCount8.portrait {
    width: 12%;
    margin-inline-end: 8px;
  }
}
@media all and (max-width: 1600px) {
  div.searchPage div.bottom div.itemsContainer div.items.rowCount8.portrait {
    width: 12%;
    margin-inline-end: 7px;
  }
}
@media all and (max-width: 1400px) {
  div.searchPage div.bottom div.itemsContainer div.items.rowCount8.portrait {
    width: 12%;
    margin-inline-end: 6px;
  }
}
@media all and (max-width: 1200px) {
  div.searchPage div.bottom div.itemsContainer div.items.rowCount8.portrait {
    width: 11.9%;
    margin-inline-end: 6px;
  }
}
@media all and (min-width: 981px) {
  div.searchPage div.bottom div.itemsContainer div.items.rowCount8.portrait:hover div.showCardContainer .square {
    position: absolute;
    z-index: 9999999;
    transform: translateX(60px) translateY(-60px) scale(1.2);
  }
  div.searchPage div.bottom div.itemsContainer div.items.rowCount8.portrait:hover div.showCardContainer .square div.metaData {
    display: block;
    visibility: visible !important;
    animation: fadeIn 0.3s ease-in-out;
  }
  div.searchPage div.bottom div.itemsContainer div.items.rowCount8.portrait:hover:nth-child(8n) div.showCardContainer div.square {
    transform: translateX(-60px) translateY(-60px) scale(1.2);
  }
  div.searchPage div.bottom div.itemsContainer div.items.rowCount8.portrait:hover:last-child div.showCardContainer div.square {
    transform: translateX(-60px) translateY(-60px) scale(1.2);
  }
}
@media all and (max-width: 980px) {
  div.searchPage div.bottom div.itemsContainer div.items.rowCount8.portrait {
    width: 24%;
    margin-inline-end: 6px;
    margin-bottom: 40px;
  }
  div.searchPage div.bottom div.itemsContainer div.items.rowCount8.portrait:nth-child(5n) {
    margin-inline-end: 6px;
  }
  div.searchPage div.bottom div.itemsContainer div.items.rowCount8.portrait:nth-child(4n) {
    margin-inline-end: 0;
  }
  div.searchPage div.bottom div.itemsContainer div.items.rowCount8.portrait:nth-child(6n) {
    margin-inline-end: 6px;
  }
}
@media all and (max-width: 768px) {
  div.searchPage div.bottom div.itemsContainer div.items.rowCount8.portrait {
    width: 32%;
  }
  div.searchPage div.bottom div.itemsContainer div.items.rowCount8.portrait:nth-child(4n) {
    margin-inline-end: 6px;
  }
  div.searchPage div.bottom div.itemsContainer div.items.rowCount8.portrait:nth-child(3n) {
    margin-inline-end: 0;
  }
}
@media all and (max-width: 640px) {
  div.searchPage div.bottom div.itemsContainer div.items.rowCount8.portrait {
    width: 48%;
  }
  div.searchPage div.bottom div.itemsContainer div.items.rowCount8.portrait:nth-child(3n) {
    margin-inline-end: 6px;
  }
  div.searchPage div.bottom div.itemsContainer div.items.rowCount8.portrait:nth-child(2n) {
    margin-inline-end: 0;
  }
}
@media all and (max-width: 980px) {
  div.searchPage div.bottom div.itemsContainer div.items.portrait {
    width: 23.5%;
    margin-inline-end: 13px;
    margin-bottom: 40px;
  }
  div.searchPage div.bottom div.itemsContainer div.items.portrait:nth-child(4n) {
    margin-inline-end: 0;
  }
  div.searchPage div.bottom div.itemsContainer div.items.portrait:nth-child(5n) {
    margin-inline-end: 13px;
  }
}
@media all and (max-width: 768px) {
  div.searchPage div.bottom div.itemsContainer div.items.portrait {
    width: 23%;
  }
  div.searchPage div.bottom div.itemsContainer div.items.portrait:nth-child(4n) {
    margin-inline-end: 0;
  }
  div.searchPage div.bottom div.itemsContainer div.items.portrait:nth-child(5n) {
    margin-inline-end: 13px;
  }
}
@media all and (max-width: 640px) {
  div.searchPage div.bottom div.itemsContainer div.items.portrait {
    width: 31%;
  }
  div.searchPage div.bottom div.itemsContainer div.items.portrait:nth-child(4n) {
    margin-inline-end: 13px;
  }
  div.searchPage div.bottom div.itemsContainer div.items.portrait:nth-child(3n) {
    margin-inline-end: 0;
  }
}
@media all and (max-width: 480px) {
  div.searchPage div.bottom div.itemsContainer div.items.portrait {
    width: 47%;
  }
  div.searchPage div.bottom div.itemsContainer div.items.portrait:nth-child(3n) {
    margin-inline-end: 13px;
  }
  div.searchPage div.bottom div.itemsContainer div.items.portrait:nth-child(2n) {
    margin-inline-end: 0;
  }
}
@media all and (max-width: 980px) {
  div.searchPage div.bottom div.itemsContainer div.items.landscape {
    width: 32%;
    margin-inline-end: 13px;
    margin-bottom: 40px;
  }
  div.searchPage div.bottom div.itemsContainer div.items.landscape:nth-child(3n) {
    margin-inline-end: 0;
  }
}
@media all and (max-width: 768px) {
  div.searchPage div.bottom div.itemsContainer div.items.landscape {
    width: 47.6%;
  }
  div.searchPage div.bottom div.itemsContainer div.items.landscape:nth-child(2n) {
    margin-inline-end: 0;
  }
  div.searchPage div.bottom div.itemsContainer div.items.landscape:nth-child(3n) {
    margin-inline-end: 13px;
  }
}
@media all and (max-width: 640px) {
  div.searchPage div.bottom div.itemsContainer div.items.landscape {
    width: 48%;
  }
  div.searchPage div.bottom div.itemsContainer div.items.landscape:nth-child(3n) {
    margin-inline-end: 13px;
  }
  div.searchPage div.bottom div.itemsContainer div.items.landscape:nth-child(2n) {
    margin-inline-end: 0;
  }
}
@media all and (max-width: 480px) {
  div.searchPage div.bottom div.itemsContainer div.items.landscape {
    width: 47%;
  }
  div.searchPage div.bottom div.itemsContainer div.items.landscape:nth-child(3n) {
    margin-inline-end: 13px;
  }
  div.searchPage div.bottom div.itemsContainer div.items.landscape:nth-child(2n) {
    margin-inline-end: 0;
  }
}
div.searchPage div.bottom div.itemsContainer div.shortsContainer {
  width: 19%;
  margin-inline-end: 1%;
  position: relative;
}
@media all and (max-width: 1200px) {
  div.searchPage div.bottom div.itemsContainer div.shortsContainer {
    width: 24%;
  }
}
@media all and (max-width: 768px) {
  div.searchPage div.bottom div.itemsContainer div.shortsContainer {
    width: 32.3%;
  }
}
@media all and (max-width: 480px) {
  div.searchPage div.bottom div.itemsContainer div.shortsContainer {
    width: 49%;
  }
}
div.searchPage div.bottom div.itemsContainer div.shortsContainer.showContents {
  cursor: pointer;
  width: 100%;
}
div.searchPage div.bottom div.itemsContainer div.shortsContainer.showContents:hover div.imageContainer img {
  transform: scale(1.1);
}
div.searchPage div.bottom div.itemsContainer div.shortsContainer.showContents:hover div.imageContainer div.extraActions {
  visibility: visible;
  opacity: 1;
  animation: fadeIn 0.2s ease-in-out forwards;
}
div.searchPage div.bottom div.itemsContainer div.shortsContainer.showContents:hover div.imageContainer div.extraActions div.showCardOverlay {
  background-color: var(--overlay);
  z-index: 8;
}
div.searchPage div.bottom div.itemsContainer div.shortsContainer.showContents:hover div.imageContainer div.extraActions div.showCardOverlay div.block-social-info {
  position: absolute;
  bottom: 2.2rem;
  z-index: 999;
  display: flex;
  justify-content: space-between;
  opacity: 1;
  transition: opacity 0.2s ease-in-out;
  background: transparent;
  padding: 10px 20px;
  width: 100%;
}
div.searchPage div.bottom div.itemsContainer div.shortsContainer.showContents:hover div.imageContainer div.extraActions div.showCardOverlay div.block-social-info .share-wrapper {
  position: absolute;
  top: auto;
  inset-inline-end: 0;
  inset-inline-start: 10%;
  bottom: 80%;
  width: 2.375rem;
  height: auto;
  display: none;
  padding: 10px 260x;
  background-color: #191919;
}
div.searchPage div.bottom div.itemsContainer div.shortsContainer.showContents:hover div.imageContainer div.extraActions div.showCardOverlay div.block-social-info .share-wrapper div.share-boxs div.overflow-hidden {
  display: grid;
  justify-content: center;
  margin-top: 20%;
}
div.searchPage div.bottom div.itemsContainer div.shortsContainer.showContents:hover div.imageContainer div.extraActions div.showCardOverlay div.block-social-info .share .share-wrapper {
  display: none; /* Hide until hover */
}
div.searchPage div.bottom div.itemsContainer div.shortsContainer.showContents:hover div.imageContainer div.extraActions div.showCardOverlay div.block-social-info .share:hover .share-wrapper {
  display: inline-block;
  bottom: 100%;
  inset-inline-start: 8%;
  width: 15%;
}
@media (min-width: 320px) {
  div.searchPage div.bottom div.itemsContainer div.shortsContainer.showContents:hover div.imageContainer div.extraActions div.showCardOverlay div.block-social-info .share:hover .share-wrapper {
    width: 30%;
  }
}
@media (min-width: 360px) {
  div.searchPage div.bottom div.itemsContainer div.shortsContainer.showContents:hover div.imageContainer div.extraActions div.showCardOverlay div.block-social-info .share:hover .share-wrapper {
    width: 26%;
    inset-inline-start: 11%;
  }
}
@media (min-width: 375px) {
  div.searchPage div.bottom div.itemsContainer div.shortsContainer.showContents:hover div.imageContainer div.extraActions div.showCardOverlay div.block-social-info .share:hover .share-wrapper {
    width: 26%;
  }
}
@media (min-width: 425px) {
  div.searchPage div.bottom div.itemsContainer div.shortsContainer.showContents:hover div.imageContainer div.extraActions div.showCardOverlay div.block-social-info .share:hover .share-wrapper {
    width: 23%;
  }
}
@media (min-width: 480px) {
  div.searchPage div.bottom div.itemsContainer div.shortsContainer.showContents:hover div.imageContainer div.extraActions div.showCardOverlay div.block-social-info .share:hover .share-wrapper {
    width: 20%;
  }
}
@media (min-width: 640px) {
  div.searchPage div.bottom div.itemsContainer div.shortsContainer.showContents:hover div.imageContainer div.extraActions div.showCardOverlay div.block-social-info .share:hover .share-wrapper {
    inset-inline-start: 8%;
    width: 23%;
  }
}
@media (min-width: 768px) {
  div.searchPage div.bottom div.itemsContainer div.shortsContainer.showContents:hover div.imageContainer div.extraActions div.showCardOverlay div.block-social-info .share:hover .share-wrapper {
    width: 19%;
    inset-inline-start: 9%;
  }
}
@media (min-width: 980px) {
  div.searchPage div.bottom div.itemsContainer div.shortsContainer.showContents:hover div.imageContainer div.extraActions div.showCardOverlay div.block-social-info .share:hover .share-wrapper {
    width: 20%;
    inset-inline-start: 9%;
  }
}
@media (min-width: 1024px) {
  div.searchPage div.bottom div.itemsContainer div.shortsContainer.showContents:hover div.imageContainer div.extraActions div.showCardOverlay div.block-social-info .share:hover .share-wrapper {
    width: 18%;
  }
}
@media (min-width: 1200px) {
  div.searchPage div.bottom div.itemsContainer div.shortsContainer.showContents:hover div.imageContainer div.extraActions div.showCardOverlay div.block-social-info .share:hover .share-wrapper {
    width: 15%;
  }
}
@media (min-width: 1400px) {
  div.searchPage div.bottom div.itemsContainer div.shortsContainer.showContents:hover div.imageContainer div.extraActions div.showCardOverlay div.block-social-info .share:hover .share-wrapper {
    width: 15%;
    inset-inline-start: 8%;
  }
}
@media (min-width: 2560px) {
  div.searchPage div.bottom div.itemsContainer div.shortsContainer.showContents:hover div.imageContainer div.extraActions div.showCardOverlay div.block-social-info .share:hover .share-wrapper {
    width: 9%;
    inset-inline-start: 6%;
  }
}
@media (min-width: 2600px) {
  div.searchPage div.bottom div.itemsContainer div.shortsContainer.showContents:hover div.imageContainer div.extraActions div.showCardOverlay div.block-social-info .share:hover .share-wrapper {
    width: 16%;
  }
}
div.searchPage div.bottom div.itemsContainer div.shortsContainer.showContents:hover div.imageContainer div.extraActions div.showCardOverlay div.block-social-info .music-play-lists {
  display: flex;
  width: 100%;
}
div.searchPage div.bottom div.itemsContainer div.shortsContainer.showContents:hover div.imageContainer div.extraActions div.showCardOverlay div.block-social-info .music-play-lists li {
  width: 22%;
  height: 40px;
  margin-bottom: 0.4375rem;
  border-radius: 50%;
  background-color: transparent;
  border: 2px solid var(--white);
  color: white !important;
  transition: all 0.3s ease;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 0.3s ease;
}
@media (max-width: 2600px) {
  div.searchPage div.bottom div.itemsContainer div.shortsContainer.showContents:hover div.imageContainer div.extraActions div.showCardOverlay div.block-social-info .music-play-lists li {
    height: 45px;
    width: 15%;
  }
}
@media (max-width: 1980px) {
  div.searchPage div.bottom div.itemsContainer div.shortsContainer.showContents:hover div.imageContainer div.extraActions div.showCardOverlay div.block-social-info .music-play-lists li {
    width: 20%;
  }
}
@media (max-width: 1600px) {
  div.searchPage div.bottom div.itemsContainer div.shortsContainer.showContents:hover div.imageContainer div.extraActions div.showCardOverlay div.block-social-info .music-play-lists li {
    width: 25%;
  }
}
@media (max-width: 1400px) {
  div.searchPage div.bottom div.itemsContainer div.shortsContainer.showContents:hover div.imageContainer div.extraActions div.showCardOverlay div.block-social-info .music-play-lists li {
    width: 28%;
  }
}
@media (max-width: 1200px) {
  div.searchPage div.bottom div.itemsContainer div.shortsContainer.showContents:hover div.imageContainer div.extraActions div.showCardOverlay div.block-social-info .music-play-lists li {
    height: 40px;
    width: 25%;
  }
}
@media (max-width: 980px) {
  div.searchPage div.bottom div.itemsContainer div.shortsContainer.showContents:hover div.imageContainer div.extraActions div.showCardOverlay div.block-social-info .music-play-lists li {
    width: 30%;
    height: 35px;
  }
}
@media (max-width: 768px) {
  div.searchPage div.bottom div.itemsContainer div.shortsContainer.showContents:hover div.imageContainer div.extraActions div.showCardOverlay div.block-social-info .music-play-lists li {
    height: 30px;
  }
}
@media (max-width: 480px) {
  div.searchPage div.bottom div.itemsContainer div.shortsContainer.showContents:hover div.imageContainer div.extraActions div.showCardOverlay div.block-social-info .music-play-lists li {
    width: 30%;
  }
}
@media (max-width: 360px) {
  div.searchPage div.bottom div.itemsContainer div.shortsContainer.showContents:hover div.imageContainer div.extraActions div.showCardOverlay div.block-social-info .music-play-lists li {
    width: 35%;
  }
}
div.searchPage div.bottom div.itemsContainer div.shortsContainer.showContents:hover div.imageContainer div.extraActions div.showCardOverlay div.block-social-info .music-play-lists li span.shareIcon {
  border-radius: 50%;
  width: 100%;
  height: 100%;
  border: 1px solid white !important;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
}
div.searchPage div.bottom div.itemsContainer div.shortsContainer.showContents:hover div.imageContainer div.extraActions div.showCardOverlay div.block-social-info .music-play-lists li span.shareIcon svg {
  fill: white !important;
  width: 70%;
}
div.searchPage div.bottom div.itemsContainer div.shortsContainer.showContents:hover div.imageContainer div.extraActions div.showCardOverlay div.block-social-info .music-play-lists li:hover {
  height: 100%;
  border: 1px solid white !important;
}
div.searchPage div.bottom div.itemsContainer div.shortsContainer.showContents:hover div.imageContainer div.extraActions div.showCardOverlay div.block-social-info .music-play-lists li .share > span {
  width: 100%;
  height: 100%;
  display: inline-block;
  font-size: 1rem;
  line-height: 1.625rem;
}
div.searchPage div.bottom div.itemsContainer div.shortsContainer.showContents:hover div.imageContainer div.extraActions div.showCardOverlay div.block-social-info .music-play-lists li i {
  color: var(--bs-white);
  font-size: 0.945em;
  line-height: 1.625rem;
  cursor: pointer;
}
div.searchPage div.bottom div.itemsContainer div.shortsContainer.showContents:hover div.imageContainer div.extraActions div.showCardOverlay div.block-social-info .music-play-lists:hover {
  color: var(--button-color);
}
div.searchPage div.bottom div.itemsContainer div.shortsContainer.showContents:hover div.imageContainer div.extraActions div.showCardOverlay div.block-social-info div.iq-button {
  margin-inline-start: auto;
  display: flex;
  justify-content: flex-end;
  background-color: var(--button-color);
  width: 22%;
  height: 40px;
  border-radius: 50%;
  justify-content: center;
  align-items: center;
}
@media (max-width: 2600px) {
  div.searchPage div.bottom div.itemsContainer div.shortsContainer.showContents:hover div.imageContainer div.extraActions div.showCardOverlay div.block-social-info div.iq-button {
    height: 45px;
    width: 15%;
  }
}
@media (max-width: 1980px) {
  div.searchPage div.bottom div.itemsContainer div.shortsContainer.showContents:hover div.imageContainer div.extraActions div.showCardOverlay div.block-social-info div.iq-button {
    width: 20%;
  }
}
@media (max-width: 1600px) {
  div.searchPage div.bottom div.itemsContainer div.shortsContainer.showContents:hover div.imageContainer div.extraActions div.showCardOverlay div.block-social-info div.iq-button {
    width: 25%;
  }
}
@media (max-width: 1400px) {
  div.searchPage div.bottom div.itemsContainer div.shortsContainer.showContents:hover div.imageContainer div.extraActions div.showCardOverlay div.block-social-info div.iq-button {
    width: 28%;
  }
}
@media (max-width: 1200px) {
  div.searchPage div.bottom div.itemsContainer div.shortsContainer.showContents:hover div.imageContainer div.extraActions div.showCardOverlay div.block-social-info div.iq-button {
    height: 40px;
    width: 25%;
  }
}
@media (max-width: 980px) {
  div.searchPage div.bottom div.itemsContainer div.shortsContainer.showContents:hover div.imageContainer div.extraActions div.showCardOverlay div.block-social-info div.iq-button {
    width: 30%;
    height: 35px;
  }
}
@media (max-width: 768px) {
  div.searchPage div.bottom div.itemsContainer div.shortsContainer.showContents:hover div.imageContainer div.extraActions div.showCardOverlay div.block-social-info div.iq-button {
    height: 30px;
  }
}
@media (max-width: 480px) {
  div.searchPage div.bottom div.itemsContainer div.shortsContainer.showContents:hover div.imageContainer div.extraActions div.showCardOverlay div.block-social-info div.iq-button {
    width: 30%;
  }
}
@media (max-width: 360px) {
  div.searchPage div.bottom div.itemsContainer div.shortsContainer.showContents:hover div.imageContainer div.extraActions div.showCardOverlay div.block-social-info div.iq-button {
    width: 35%;
  }
}
div.searchPage div.bottom div.itemsContainer div.shortsContainer.showContents:hover div.imageContainer div.extraActions div.showCardOverlay div.block-social-info div.iq-button:focus {
  background-color: transparent;
  width: 20%;
  border-radius: 50%;
  border: 2px solid var(--white);
}
div.searchPage div.bottom div.itemsContainer div.shortsContainer.showContents:hover div.imageContainer div.extraActions div.showCardOverlay div.block-social-info:focus {
  background-color: transparent;
  width: 20%;
  border-radius: 50%;
  border: 2px solid var(--white);
}
div.searchPage div.bottom div.itemsContainer div.shortsContainer.showContents:hover div.imageContainer div.extraActions div.showCardOverlay div.block-social-info div.iq-button:hover {
  color: gray;
  width: 13%;
  height: 100%;
  border-radius: 50%;
  background-color: var(--button-hover);
}
div.searchPage div.bottom div.itemsContainer div.shortsContainer.showContents:hover div.imageContainer div.extraActions div.showCardOverlay div.block-social-info div.iq-button:hover:focus {
  background-color: gray;
}
div.searchPage div.bottom div.itemsContainer div.shortsContainer.showContents:hover div.metaData h1.title {
  color: var(--text-hover);
}
div.searchPage div.bottom div.itemsContainer div.shortsContainer div.imageContainer {
  width: 100%;
  position: relative;
  overflow: hidden;
  border-radius: 3px;
}
div.searchPage div.bottom div.itemsContainer div.shortsContainer div.imageContainer img {
  width: 100%;
  height: 26rem;
  display: block;
  border-radius: 3px;
  transition: transform 0.2s ease-in-out;
}
@media all and (max-width: 1200px) {
  div.searchPage div.bottom div.itemsContainer div.shortsContainer div.imageContainer img {
    height: 20rem;
  }
}
@media all and (max-width: 640px) {
  div.searchPage div.bottom div.itemsContainer div.shortsContainer div.imageContainer img {
    height: 17rem;
  }
}
@media all and (max-width: 360px) {
  div.searchPage div.bottom div.itemsContainer div.shortsContainer div.imageContainer img {
    height: 13rem;
  }
}
div.searchPage div.bottom div.itemsContainer div.shortsContainer div.imageContainer img.landscape {
  min-height: 130px;
  background: black;
}
div.searchPage div.bottom div.itemsContainer div.shortsContainer div.imageContainer img.portrait {
  min-height: 420px;
  background: black;
}
div.searchPage div.bottom div.itemsContainer div.shortsContainer div.imageContainer div.extraActions {
  visibility: hidden;
  opacity: 0;
  transition: opacity 0.2s ease-in-out;
}
div.searchPage div.bottom div.itemsContainer div.shortsContainer div.imageContainer div.extraActions div.showCardOverlay {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  background-color: var(--overlay);
  z-index: 8;
}
div.searchPage div.bottom div.itemsContainer div.shortsContainer div.metaData {
  margin-top: 10px;
  color: var(--inner-text-color);
}
div.searchPage div.bottom div.itemsContainer div.shortsContainer div.metaData h1.title {
  color: var(--text-color);
  font-size: 16px;
  transition: color 0.3s ease;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}
@media (max-width: 480px) {
  div.searchPage div.bottom div.itemsContainer div.shortsContainer div.metaData h1.title {
    font-size: 13px;
  }
}
@media (min-width: 640px) {
  div.searchPage div.bottom div.itemsContainer div.shortsContainer div.metaData h1.title {
    font-size: 14px;
  }
}
div.searchPage div.bottom div.itemsContainer div.shortsContainer div.metaData div.datas {
  display: flex;
  justify-content: space-between;
}
@media (max-width: 980px) {
  div.searchPage div.bottom div.itemsContainer div.shortsContainer div.metaData div.datas {
    display: none;
  }
}
div.searchPage div.bottom div.itemsContainer div.shortsContainer div.metaData div.datas div.left span {
  font-size: 12px;
  margin-inline-end: 6px;
}
div.searchPage div.bottom div.itemsContainer div.shortsContainer div.metaData div.datas div.left span.duration.showDot::before {
  content: "·";
  margin-inline-end: 6px;
}
div.searchPage div.bottom div.itemsContainer div.shortsContainer div.metaData div.datas div.left span:last-child {
  margin-inline-end: 0;
}

div.myList {
  padding: 130px 0 40px;
}
div.myList div.top {
  padding-bottom: 60px;
  border-bottom: 1px solid var(--border-color);
}
div.myList div.top h1.heading {
  font-size: 46px;
  margin-bottom: 10px;
  color: var(--text-color);
}
@media all and (max-width: 640px) {
  div.myList div.top h1.heading {
    font-size: 36px;
  }
}
div.myList div.top h1.heading.gaia {
  font-family: "Avenir-Black", sans-serif;
}
div.myList div.top p.desc {
  font-size: 18px;
  color: var(--inner-text-color);
}
@media all and (max-width: 640px) {
  div.myList div.top p.desc {
    font-size: 14px;
  }
}
div.myList div.bottom {
  margin-top: 40px;
}
div.myList div.bottom div.itemsContainer {
  display: flex;
  flex-wrap: wrap;
}
div.myList div.bottom div.itemsContainer div.items.rowCount3 {
  width: 32.5%;
  margin-right: 13px;
  margin-bottom: 30px;
}
div.myList div.bottom div.itemsContainer div.items.rowCount3:nth-child(3n) {
  margin-right: 0;
}
@media all and (max-width: 1200px) {
  div.myList div.bottom div.itemsContainer div.items.rowCount3 {
    width: 32%;
  }
}
@media all and (min-width: 981px) {
  div.myList div.bottom div.itemsContainer div.items.rowCount3.landscape:hover div.showCardContainer .square {
    position: absolute;
    z-index: 9999999;
    transform: translateX(60px) translateY(-60px) scale(1.1);
  }
  div.myList div.bottom div.itemsContainer div.items.rowCount3.landscape:hover div.showCardContainer .square div.metaData {
    display: block;
    visibility: visible !important;
    animation: fadeIn 0.3s ease-in-out;
  }
  div.myList div.bottom div.itemsContainer div.items.rowCount3.landscape:hover:nth-child(3n) div.showCardContainer div.square {
    transform: translateX(-60px) translateY(-60px) scale(1.1);
  }
}
@media all and (max-width: 980px) {
  div.myList div.bottom div.itemsContainer div.items.rowCount3.landscape {
    width: 24.3%;
    margin-right: 6px;
    margin-bottom: 40px;
  }
  div.myList div.bottom div.itemsContainer div.items.rowCount3.landscape:nth-child(6n) {
    margin-right: 6px;
  }
  div.myList div.bottom div.itemsContainer div.items.rowCount3.landscape:nth-child(4n) {
    margin-right: 0;
  }
}
@media all and (max-width: 768px) {
  div.myList div.bottom div.itemsContainer div.items.rowCount3.landscape {
    width: 32.3%;
  }
  div.myList div.bottom div.itemsContainer div.items.rowCount3.landscape:nth-child(4n) {
    margin-right: 6px;
  }
  div.myList div.bottom div.itemsContainer div.items.rowCount3.landscape:nth-child(3n) {
    margin-right: 0;
  }
}
@media all and (max-width: 640px) {
  div.myList div.bottom div.itemsContainer div.items.rowCount3.landscape {
    width: 48.2%;
  }
  div.myList div.bottom div.itemsContainer div.items.rowCount3.landscape:nth-child(3n) {
    margin-right: 6px;
  }
  div.myList div.bottom div.itemsContainer div.items.rowCount3.landscape:nth-child(2n) {
    margin-right: 0;
  }
}
@media all and (max-width: 480px) {
  div.myList div.bottom div.itemsContainer div.items.rowCount3.landscape {
    width: 100%;
    margin-right: 0;
  }
  div.myList div.bottom div.itemsContainer div.items.rowCount3.landscape:nth-child(6n) {
    margin-right: 0;
  }
  div.myList div.bottom div.itemsContainer div.items.rowCount3.landscape:nth-child(4n) {
    margin-right: 0;
  }
  div.myList div.bottom div.itemsContainer div.items.rowCount3.landscape:nth-child(3n) {
    margin-right: 0;
  }
  div.myList div.bottom div.itemsContainer div.items.rowCount3.landscape:nth-child(2n) {
    margin-right: 0;
  }
}
@media all and (max-width: 480px) and (max-width: 360px) {
  div.myList div.bottom div.itemsContainer div.items.rowCount3.landscape {
    width: 100%;
    margin-right: 0;
  }
  div.myList div.bottom div.itemsContainer div.items.rowCount3.landscape:nth-child(3n) {
    margin-right: 0;
  }
}
@media all and (min-width: 981px) {
  div.myList div.bottom div.itemsContainer div.items.rowCount3.portrait:hover div.showCardContainer .square {
    position: absolute;
    z-index: 9999999;
    transform: translateX(60px) translateY(-60px) scale(1.2);
  }
  div.myList div.bottom div.itemsContainer div.items.rowCount3.portrait:hover div.showCardContainer .square div.metaData {
    display: block;
    visibility: visible !important;
    animation: fadeIn 0.3s ease-in-out;
  }
  div.myList div.bottom div.itemsContainer div.items.rowCount3.portrait:hover:nth-child(3n) div.showCardContainer div.square {
    transform: translateX(-60px) translateY(-60px) scale(1.2);
  }
}
@media all and (min-width: 981px) and (max-width: 1300px) {
  div.myList div.bottom div.itemsContainer div.items.rowCount3.portrait:hover:nth-child(6n) div.showCardContainer div.square {
    transform: translateX(-60px) translateY(-60px) scale(1.2);
  }
  div.myList div.bottom div.itemsContainer div.items.rowCount3.portrait:hover:nth-child(7n) div.showCardContainer div.square {
    transform: translateX(60px) translateY(-60px) scale(1.2);
  }
}
@media all and (min-width: 981px) {
  div.myList div.bottom div.itemsContainer div.items.rowCount3.portrait:hover:last-child div.showCardContainer div.square {
    transform: translateX(-60px) translateY(-60px) scale(1.2);
  }
}
@media all and (max-width: 980px) {
  div.myList div.bottom div.itemsContainer div.items.rowCount3.portrait {
    width: 24%;
    margin-right: 6px;
    margin-bottom: 40px;
  }
  div.myList div.bottom div.itemsContainer div.items.rowCount3.portrait:nth-child(5n) {
    margin-right: 6px;
  }
  div.myList div.bottom div.itemsContainer div.items.rowCount3.portrait:nth-child(4n) {
    margin-right: 0;
  }
  div.myList div.bottom div.itemsContainer div.items.rowCount3.portrait:nth-child(6n) {
    margin-right: 6px;
  }
}
@media all and (max-width: 768px) {
  div.myList div.bottom div.itemsContainer div.items.rowCount3.portrait {
    width: 32%;
  }
  div.myList div.bottom div.itemsContainer div.items.rowCount3.portrait:nth-child(4n) {
    margin-right: 6px;
  }
  div.myList div.bottom div.itemsContainer div.items.rowCount3.portrait:nth-child(3n) {
    margin-right: 0;
  }
}
@media all and (max-width: 640px) {
  div.myList div.bottom div.itemsContainer div.items.rowCount3.portrait {
    width: 48%;
  }
  div.myList div.bottom div.itemsContainer div.items.rowCount3.portrait:nth-child(3n) {
    margin-right: 6px;
  }
  div.myList div.bottom div.itemsContainer div.items.rowCount3.portrait:nth-child(2n) {
    margin-right: 0;
  }
}
div.myList div.bottom div.itemsContainer div.items.rowCount4 {
  width: 24%;
  margin-right: 13px;
  margin-bottom: 30px;
}
div.myList div.bottom div.itemsContainer div.items.rowCount4:nth-child(4n) {
  margin-right: 0;
}
@media all and (max-width: 1200px) {
  div.myList div.bottom div.itemsContainer div.items.rowCount4 {
    width: 23.8%;
  }
}
@media all and (min-width: 981px) {
  div.myList div.bottom div.itemsContainer div.items.rowCount4.landscape:hover div.showCardContainer .square {
    position: absolute;
    z-index: 9999999;
    transform: translateX(60px) translateY(-60px) scale(1.1);
  }
  div.myList div.bottom div.itemsContainer div.items.rowCount4.landscape:hover div.showCardContainer .square div.metaData {
    display: block;
    visibility: visible !important;
    animation: fadeIn 0.3s ease-in-out;
  }
  div.myList div.bottom div.itemsContainer div.items.rowCount4.landscape:hover:nth-child(4n) div.showCardContainer div.square {
    transform: translateX(-60px) translateY(-60px) scale(1.1);
  }
}
@media all and (max-width: 980px) {
  div.myList div.bottom div.itemsContainer div.items.rowCount4.landscape {
    width: 24.3%;
    margin-right: 6px;
    margin-bottom: 40px;
  }
  div.myList div.bottom div.itemsContainer div.items.rowCount4.landscape:nth-child(6n) {
    margin-right: 6px;
  }
  div.myList div.bottom div.itemsContainer div.items.rowCount4.landscape:nth-child(4n) {
    margin-right: 0;
  }
}
@media all and (max-width: 768px) {
  div.myList div.bottom div.itemsContainer div.items.rowCount4.landscape {
    width: 32.3%;
  }
  div.myList div.bottom div.itemsContainer div.items.rowCount4.landscape:nth-child(4n) {
    margin-right: 6px;
  }
  div.myList div.bottom div.itemsContainer div.items.rowCount4.landscape:nth-child(3n) {
    margin-right: 0;
  }
}
@media all and (max-width: 640px) {
  div.myList div.bottom div.itemsContainer div.items.rowCount4.landscape {
    width: 48.2%;
  }
  div.myList div.bottom div.itemsContainer div.items.rowCount4.landscape:nth-child(3n) {
    margin-right: 6px;
  }
  div.myList div.bottom div.itemsContainer div.items.rowCount4.landscape:nth-child(2n) {
    margin-right: 0;
  }
}
@media all and (max-width: 480px) {
  div.myList div.bottom div.itemsContainer div.items.rowCount4.landscape {
    width: 100%;
    margin-right: 0;
  }
  div.myList div.bottom div.itemsContainer div.items.rowCount4.landscape:nth-child(6n) {
    margin-right: 0;
  }
  div.myList div.bottom div.itemsContainer div.items.rowCount4.landscape:nth-child(4n) {
    margin-right: 0;
  }
  div.myList div.bottom div.itemsContainer div.items.rowCount4.landscape:nth-child(3n) {
    margin-right: 0;
  }
  div.myList div.bottom div.itemsContainer div.items.rowCount4.landscape:nth-child(2n) {
    margin-right: 0;
  }
}
@media all and (max-width: 480px) and (max-width: 360px) {
  div.myList div.bottom div.itemsContainer div.items.rowCount4.landscape {
    width: 100%;
    margin-right: 0;
  }
  div.myList div.bottom div.itemsContainer div.items.rowCount4.landscape:nth-child(3n) {
    margin-right: 0;
  }
}
@media all and (min-width: 981px) {
  div.myList div.bottom div.itemsContainer div.items.rowCount4.portrait:hover div.showCardContainer .square {
    position: absolute;
    z-index: 9999999;
    transform: translateX(60px) translateY(-60px) scale(1.2);
  }
  div.myList div.bottom div.itemsContainer div.items.rowCount4.portrait:hover div.showCardContainer .square div.metaData {
    display: block;
    visibility: visible !important;
    animation: fadeIn 0.3s ease-in-out;
  }
  div.myList div.bottom div.itemsContainer div.items.rowCount4.portrait:hover:nth-child(4n) div.showCardContainer div.square {
    transform: translateX(-60px) translateY(-60px) scale(1.2);
  }
}
@media all and (min-width: 981px) and (max-width: 1300px) {
  div.myList div.bottom div.itemsContainer div.items.rowCount4.portrait:hover:nth-child(6n) div.showCardContainer div.square {
    transform: translateX(-60px) translateY(-60px) scale(1.2);
  }
  div.myList div.bottom div.itemsContainer div.items.rowCount4.portrait:hover:nth-child(7n) div.showCardContainer div.square {
    transform: translateX(60px) translateY(-60px) scale(1.2);
  }
}
@media all and (min-width: 981px) {
  div.myList div.bottom div.itemsContainer div.items.rowCount4.portrait:hover:last-child div.showCardContainer div.square {
    transform: translateX(-60px) translateY(-60px) scale(1.2);
  }
}
@media all and (max-width: 980px) {
  div.myList div.bottom div.itemsContainer div.items.rowCount4.portrait {
    width: 24%;
    margin-right: 6px;
    margin-bottom: 40px;
  }
  div.myList div.bottom div.itemsContainer div.items.rowCount4.portrait:nth-child(5n) {
    margin-right: 6px;
  }
  div.myList div.bottom div.itemsContainer div.items.rowCount4.portrait:nth-child(4n) {
    margin-right: 0;
  }
  div.myList div.bottom div.itemsContainer div.items.rowCount4.portrait:nth-child(6n) {
    margin-right: 6px;
  }
}
@media all and (max-width: 768px) {
  div.myList div.bottom div.itemsContainer div.items.rowCount4.portrait {
    width: 32%;
  }
  div.myList div.bottom div.itemsContainer div.items.rowCount4.portrait:nth-child(4n) {
    margin-right: 6px;
  }
  div.myList div.bottom div.itemsContainer div.items.rowCount4.portrait:nth-child(3n) {
    margin-right: 0;
  }
}
@media all and (max-width: 640px) {
  div.myList div.bottom div.itemsContainer div.items.rowCount4.portrait {
    width: 48%;
  }
  div.myList div.bottom div.itemsContainer div.items.rowCount4.portrait:nth-child(3n) {
    margin-right: 6px;
  }
  div.myList div.bottom div.itemsContainer div.items.rowCount4.portrait:nth-child(2n) {
    margin-right: 0;
  }
}
div.myList div.bottom div.itemsContainer div.items.rowCount5 {
  width: 19%;
  margin-right: 13px;
  margin-bottom: 30px;
}
div.myList div.bottom div.itemsContainer div.items.rowCount5:nth-child(5n) {
  margin-right: 0;
}
@media all and (max-width: 1200px) {
  div.myList div.bottom div.itemsContainer div.items.rowCount5 {
    width: 18.5%;
  }
}
@media all and (min-width: 981px) {
  div.myList div.bottom div.itemsContainer div.items.rowCount5.landscape:hover div.showCardContainer .square {
    position: absolute;
    z-index: 9999999;
    transform: translateX(60px) translateY(-60px) scale(1.1);
  }
  div.myList div.bottom div.itemsContainer div.items.rowCount5.landscape:hover div.showCardContainer .square div.metaData {
    display: block;
    visibility: visible !important;
    animation: fadeIn 0.3s ease-in-out;
  }
  div.myList div.bottom div.itemsContainer div.items.rowCount5.landscape:hover:nth-child(5n) div.showCardContainer div.square {
    transform: translateX(-60px) translateY(-60px) scale(1.1);
  }
}
@media all and (max-width: 980px) {
  div.myList div.bottom div.itemsContainer div.items.rowCount5.landscape {
    width: 24.3%;
    margin-right: 6px;
    margin-bottom: 40px;
  }
  div.myList div.bottom div.itemsContainer div.items.rowCount5.landscape:nth-child(6n) {
    margin-right: 6px;
  }
  div.myList div.bottom div.itemsContainer div.items.rowCount5.landscape:nth-child(4n) {
    margin-right: 0;
  }
}
@media all and (max-width: 768px) {
  div.myList div.bottom div.itemsContainer div.items.rowCount5.landscape {
    width: 32.3%;
  }
  div.myList div.bottom div.itemsContainer div.items.rowCount5.landscape:nth-child(4n) {
    margin-right: 6px;
  }
  div.myList div.bottom div.itemsContainer div.items.rowCount5.landscape:nth-child(3n) {
    margin-right: 0;
  }
}
@media all and (max-width: 640px) {
  div.myList div.bottom div.itemsContainer div.items.rowCount5.landscape {
    width: 48.2%;
  }
  div.myList div.bottom div.itemsContainer div.items.rowCount5.landscape:nth-child(3n) {
    margin-right: 6px;
  }
  div.myList div.bottom div.itemsContainer div.items.rowCount5.landscape:nth-child(2n) {
    margin-right: 0;
  }
}
@media all and (max-width: 480px) {
  div.myList div.bottom div.itemsContainer div.items.rowCount5.landscape {
    width: 100%;
    margin-right: 0;
  }
  div.myList div.bottom div.itemsContainer div.items.rowCount5.landscape:nth-child(6n) {
    margin-right: 0;
  }
  div.myList div.bottom div.itemsContainer div.items.rowCount5.landscape:nth-child(4n) {
    margin-right: 0;
  }
  div.myList div.bottom div.itemsContainer div.items.rowCount5.landscape:nth-child(3n) {
    margin-right: 0;
  }
  div.myList div.bottom div.itemsContainer div.items.rowCount5.landscape:nth-child(2n) {
    margin-right: 0;
  }
}
@media all and (max-width: 480px) and (max-width: 360px) {
  div.myList div.bottom div.itemsContainer div.items.rowCount5.landscape {
    width: 100%;
    margin-right: 0;
  }
  div.myList div.bottom div.itemsContainer div.items.rowCount5.landscape:nth-child(3n) {
    margin-right: 0;
  }
}
@media all and (min-width: 981px) {
  div.myList div.bottom div.itemsContainer div.items.rowCount5.portrait:hover div.showCardContainer .square {
    position: absolute;
    z-index: 9999999;
    transform: translateX(60px) translateY(-60px) scale(1.2);
  }
  div.myList div.bottom div.itemsContainer div.items.rowCount5.portrait:hover div.showCardContainer .square div.metaData {
    display: block;
    visibility: visible !important;
    animation: fadeIn 0.3s ease-in-out;
  }
  div.myList div.bottom div.itemsContainer div.items.rowCount5.portrait:hover:nth-child(5n) div.showCardContainer div.square {
    transform: translateX(-60px) translateY(-60px) scale(1.2);
  }
}
@media all and (min-width: 981px) and (max-width: 1300px) {
  div.myList div.bottom div.itemsContainer div.items.rowCount5.portrait:hover:nth-child(6n) div.showCardContainer div.square {
    transform: translateX(-60px) translateY(-60px) scale(1.2);
  }
  div.myList div.bottom div.itemsContainer div.items.rowCount5.portrait:hover:nth-child(7n) div.showCardContainer div.square {
    transform: translateX(60px) translateY(-60px) scale(1.2);
  }
}
@media all and (min-width: 981px) {
  div.myList div.bottom div.itemsContainer div.items.rowCount5.portrait:hover:last-child div.showCardContainer div.square {
    transform: translateX(-60px) translateY(-60px) scale(1.2);
  }
}
@media all and (max-width: 980px) {
  div.myList div.bottom div.itemsContainer div.items.rowCount5.portrait {
    width: 24%;
    margin-right: 6px;
    margin-bottom: 40px;
  }
  div.myList div.bottom div.itemsContainer div.items.rowCount5.portrait:nth-child(5n) {
    margin-right: 6px;
  }
  div.myList div.bottom div.itemsContainer div.items.rowCount5.portrait:nth-child(4n) {
    margin-right: 0;
  }
  div.myList div.bottom div.itemsContainer div.items.rowCount5.portrait:nth-child(6n) {
    margin-right: 6px;
  }
}
@media all and (max-width: 768px) {
  div.myList div.bottom div.itemsContainer div.items.rowCount5.portrait {
    width: 32%;
  }
  div.myList div.bottom div.itemsContainer div.items.rowCount5.portrait:nth-child(4n) {
    margin-right: 6px;
  }
  div.myList div.bottom div.itemsContainer div.items.rowCount5.portrait:nth-child(3n) {
    margin-right: 0;
  }
}
@media all and (max-width: 640px) {
  div.myList div.bottom div.itemsContainer div.items.rowCount5.portrait {
    width: 48%;
  }
  div.myList div.bottom div.itemsContainer div.items.rowCount5.portrait:nth-child(3n) {
    margin-right: 6px;
  }
  div.myList div.bottom div.itemsContainer div.items.rowCount5.portrait:nth-child(2n) {
    margin-right: 0;
  }
}
div.myList div.bottom div.itemsContainer div.items.rowCount6 {
  width: 15.8%;
  margin-right: 13px;
  margin-bottom: 30px;
}
div.myList div.bottom div.itemsContainer div.items.rowCount6:nth-child(6n) {
  margin-right: 0;
}
@media all and (max-width: 1400px) {
  div.myList div.bottom div.itemsContainer div.items.rowCount6 {
    width: 15.5%;
  }
}
@media all and (max-width: 1200px) {
  div.myList div.bottom div.itemsContainer div.items.rowCount6 {
    width: 15%;
  }
}
@media all and (min-width: 981px) {
  div.myList div.bottom div.itemsContainer div.items.rowCount6.landscape:hover div.showCardContainer .square {
    position: absolute;
    z-index: 9999999;
    transform: translateX(60px) translateY(-60px) scale(1.1);
  }
  div.myList div.bottom div.itemsContainer div.items.rowCount6.landscape:hover div.showCardContainer .square div.metaData {
    display: block;
    visibility: visible !important;
    animation: fadeIn 0.3s ease-in-out;
  }
  div.myList div.bottom div.itemsContainer div.items.rowCount6.landscape:hover:nth-child(6n) div.showCardContainer div.square {
    transform: translateX(-60px) translateY(-60px) scale(1.1);
  }
}
@media all and (max-width: 980px) {
  div.myList div.bottom div.itemsContainer div.items.rowCount6.landscape {
    width: 24.3%;
    margin-right: 6px;
    margin-bottom: 40px;
  }
  div.myList div.bottom div.itemsContainer div.items.rowCount6.landscape:nth-child(6n) {
    margin-right: 6px;
  }
  div.myList div.bottom div.itemsContainer div.items.rowCount6.landscape:nth-child(4n) {
    margin-right: 0;
  }
}
@media all and (max-width: 768px) {
  div.myList div.bottom div.itemsContainer div.items.rowCount6.landscape {
    width: 32.3%;
  }
  div.myList div.bottom div.itemsContainer div.items.rowCount6.landscape:nth-child(4n) {
    margin-right: 6px;
  }
  div.myList div.bottom div.itemsContainer div.items.rowCount6.landscape:nth-child(3n) {
    margin-right: 0;
  }
}
@media all and (max-width: 640px) {
  div.myList div.bottom div.itemsContainer div.items.rowCount6.landscape {
    width: 48.2%;
  }
  div.myList div.bottom div.itemsContainer div.items.rowCount6.landscape:nth-child(3n) {
    margin-right: 6px;
  }
  div.myList div.bottom div.itemsContainer div.items.rowCount6.landscape:nth-child(2n) {
    margin-right: 0;
  }
}
@media all and (max-width: 480px) {
  div.myList div.bottom div.itemsContainer div.items.rowCount6.landscape {
    width: 100%;
    margin-right: 0;
  }
  div.myList div.bottom div.itemsContainer div.items.rowCount6.landscape:nth-child(6n) {
    margin-right: 0;
  }
  div.myList div.bottom div.itemsContainer div.items.rowCount6.landscape:nth-child(4n) {
    margin-right: 0;
  }
  div.myList div.bottom div.itemsContainer div.items.rowCount6.landscape:nth-child(3n) {
    margin-right: 0;
  }
  div.myList div.bottom div.itemsContainer div.items.rowCount6.landscape:nth-child(2n) {
    margin-right: 0;
  }
}
@media all and (max-width: 480px) and (max-width: 360px) {
  div.myList div.bottom div.itemsContainer div.items.rowCount6.landscape {
    width: 100%;
    margin-right: 0;
  }
  div.myList div.bottom div.itemsContainer div.items.rowCount6.landscape:nth-child(3n) {
    margin-right: 0;
  }
}
@media all and (min-width: 981px) {
  div.myList div.bottom div.itemsContainer div.items.rowCount6.portrait:hover div.showCardContainer .square {
    position: absolute;
    z-index: 9999999;
    transform: translateX(60px) translateY(-60px) scale(1.2);
  }
  div.myList div.bottom div.itemsContainer div.items.rowCount6.portrait:hover div.showCardContainer .square div.metaData {
    display: block;
    visibility: visible !important;
    animation: fadeIn 0.3s ease-in-out;
  }
  div.myList div.bottom div.itemsContainer div.items.rowCount6.portrait:hover:nth-child(6n) div.showCardContainer div.square {
    transform: translateX(-60px) translateY(-60px) scale(1.2);
  }
}
@media all and (min-width: 981px) and (max-width: 1300px) {
  div.myList div.bottom div.itemsContainer div.items.rowCount6.portrait:hover:nth-child(6n) div.showCardContainer div.square {
    transform: translateX(-60px) translateY(-60px) scale(1.2);
  }
  div.myList div.bottom div.itemsContainer div.items.rowCount6.portrait:hover:nth-child(7n) div.showCardContainer div.square {
    transform: translateX(60px) translateY(-60px) scale(1.2);
  }
}
@media all and (min-width: 981px) {
  div.myList div.bottom div.itemsContainer div.items.rowCount6.portrait:hover:last-child div.showCardContainer div.square {
    transform: translateX(-60px) translateY(-60px) scale(1.2);
  }
}
@media all and (max-width: 980px) {
  div.myList div.bottom div.itemsContainer div.items.rowCount6.portrait {
    width: 24%;
    margin-right: 6px;
    margin-bottom: 40px;
  }
  div.myList div.bottom div.itemsContainer div.items.rowCount6.portrait:nth-child(5n) {
    margin-right: 6px;
  }
  div.myList div.bottom div.itemsContainer div.items.rowCount6.portrait:nth-child(4n) {
    margin-right: 0;
  }
  div.myList div.bottom div.itemsContainer div.items.rowCount6.portrait:nth-child(6n) {
    margin-right: 6px;
  }
}
@media all and (max-width: 768px) {
  div.myList div.bottom div.itemsContainer div.items.rowCount6.portrait {
    width: 32%;
  }
  div.myList div.bottom div.itemsContainer div.items.rowCount6.portrait:nth-child(4n) {
    margin-right: 6px;
  }
  div.myList div.bottom div.itemsContainer div.items.rowCount6.portrait:nth-child(3n) {
    margin-right: 0;
  }
}
@media all and (max-width: 640px) {
  div.myList div.bottom div.itemsContainer div.items.rowCount6.portrait {
    width: 48%;
  }
  div.myList div.bottom div.itemsContainer div.items.rowCount6.portrait:nth-child(3n) {
    margin-right: 6px;
  }
  div.myList div.bottom div.itemsContainer div.items.rowCount6.portrait:nth-child(2n) {
    margin-right: 0;
  }
}
div.myList div.bottom div.itemsContainer div.items.rowCount7 {
  width: 13.6%;
  margin-right: 11px;
  margin-bottom: 30px;
}
div.myList div.bottom div.itemsContainer div.items.rowCount7:nth-child(7n) {
  margin-right: 0;
}
@media all and (max-width: 1400px) {
  div.myList div.bottom div.itemsContainer div.items.rowCount7 {
    width: 16%;
  }
}
@media all and (max-width: 1200px) {
  div.myList div.bottom div.itemsContainer div.items.rowCount7 {
    width: 16%;
  }
}
@media all and (max-width: 2600px) {
  div.myList div.bottom div.itemsContainer div.items.rowCount7.landscape {
    width: 13%;
    margin-right: 29px;
  }
}
@media all and (max-width: 2500px) {
  div.myList div.bottom div.itemsContainer div.items.rowCount7.landscape {
    width: 13%;
    margin-right: 25px;
  }
}
@media all and (max-width: 2200px) {
  div.myList div.bottom div.itemsContainer div.items.rowCount7.landscape {
    width: 13.8%;
    margin-right: 8px;
  }
}
@media all and (max-width: 1980px) {
  div.myList div.bottom div.itemsContainer div.items.rowCount7.landscape {
    width: 13.9%;
    margin-right: 6px;
  }
}
@media all and (max-width: 1768px) {
  div.myList div.bottom div.itemsContainer div.items.rowCount7.landscape {
    width: 13.9%;
    margin-right: 5px;
  }
}
@media all and (max-width: 1600px) {
  div.myList div.bottom div.itemsContainer div.items.rowCount7.landscape {
    width: 13.8%;
    margin-right: 5px;
  }
}
@media all and (max-width: 1400px) {
  div.myList div.bottom div.itemsContainer div.items.rowCount7.landscape {
    width: 13.8%;
    margin-right: 5px;
  }
}
@media all and (max-width: 1200px) {
  div.myList div.bottom div.itemsContainer div.items.rowCount7.landscape {
    width: 13.7%;
    margin-right: 5px;
  }
}
@media all and (min-width: 981px) {
  div.myList div.bottom div.itemsContainer div.items.rowCount7.landscape:hover div.showCardContainer .square {
    position: absolute;
    z-index: 9999999;
    transform: translateX(60px) translateY(-60px) scale(1.1);
  }
  div.myList div.bottom div.itemsContainer div.items.rowCount7.landscape:hover div.showCardContainer .square div.metaData {
    display: block;
    visibility: visible !important;
    animation: fadeIn 0.3s ease-in-out;
  }
  div.myList div.bottom div.itemsContainer div.items.rowCount7.landscape:hover:nth-child(6n) div.showCardContainer div.square {
    transform: translateX(-60px) translateY(-60px) scale(1.1);
  }
}
@media all and (max-width: 980px) {
  div.myList div.bottom div.itemsContainer div.items.rowCount7.landscape {
    width: 24.3%;
    margin-right: 6px;
    margin-bottom: 40px;
  }
  div.myList div.bottom div.itemsContainer div.items.rowCount7.landscape:nth-child(6n) {
    margin-right: 6px;
  }
  div.myList div.bottom div.itemsContainer div.items.rowCount7.landscape:nth-child(4n) {
    margin-right: 0;
  }
}
@media all and (max-width: 768px) {
  div.myList div.bottom div.itemsContainer div.items.rowCount7.landscape {
    width: 32.3%;
  }
  div.myList div.bottom div.itemsContainer div.items.rowCount7.landscape:nth-child(4n) {
    margin-right: 6px;
  }
  div.myList div.bottom div.itemsContainer div.items.rowCount7.landscape:nth-child(3n) {
    margin-right: 0;
  }
}
@media all and (max-width: 640px) {
  div.myList div.bottom div.itemsContainer div.items.rowCount7.landscape {
    width: 48.2%;
  }
  div.myList div.bottom div.itemsContainer div.items.rowCount7.landscape:nth-child(3n) {
    margin-right: 6px;
  }
  div.myList div.bottom div.itemsContainer div.items.rowCount7.landscape:nth-child(2n) {
    margin-right: 0;
  }
}
@media all and (max-width: 480px) {
  div.myList div.bottom div.itemsContainer div.items.rowCount7.landscape {
    width: 100%;
    margin-right: 0;
  }
  div.myList div.bottom div.itemsContainer div.items.rowCount7.landscape:nth-child(6n) {
    margin-right: 0;
  }
  div.myList div.bottom div.itemsContainer div.items.rowCount7.landscape:nth-child(4n) {
    margin-right: 0;
  }
  div.myList div.bottom div.itemsContainer div.items.rowCount7.landscape:nth-child(3n) {
    margin-right: 0;
  }
  div.myList div.bottom div.itemsContainer div.items.rowCount7.landscape:nth-child(2n) {
    margin-right: 0;
  }
}
@media all and (max-width: 480px) and (max-width: 360px) {
  div.myList div.bottom div.itemsContainer div.items.rowCount7.landscape {
    width: 100%;
    margin-right: 0;
  }
  div.myList div.bottom div.itemsContainer div.items.rowCount7.landscape:nth-child(3n) {
    margin-right: 0;
  }
}
@media all and (max-width: 1600px) {
  div.myList div.bottom div.itemsContainer div.items.rowCount7.portrait {
    width: 13.8%;
    margin-right: 7px;
  }
  div.myList div.bottom div.itemsContainer div.items.rowCount7.portrait:nth-child(7n) {
    margin-right: 0px;
  }
}
@media all and (max-width: 1400px) {
  div.myList div.bottom div.itemsContainer div.items.rowCount7.portrait {
    width: 14%;
    margin-right: 3px;
  }
}
@media all and (max-width: 1200px) {
  div.myList div.bottom div.itemsContainer div.items.rowCount7.portrait {
    width: 13.7%;
    margin-right: 6.1px;
  }
}
@media all and (min-width: 981px) {
  div.myList div.bottom div.itemsContainer div.items.rowCount7.portrait:hover div.showCardContainer .square {
    position: absolute;
    z-index: 9999999;
    transform: translateX(60px) translateY(-60px) scale(1.2);
  }
  div.myList div.bottom div.itemsContainer div.items.rowCount7.portrait:hover div.showCardContainer .square div.metaData {
    display: block;
    visibility: visible !important;
    animation: fadeIn 0.3s ease-in-out;
  }
  div.myList div.bottom div.itemsContainer div.items.rowCount7.portrait:hover:nth-child(6n) div.showCardContainer div.square {
    transform: translateX(-60px) translateY(-60px) scale(1.2);
  }
}
@media all and (min-width: 981px) and (max-width: 1300px) {
  div.myList div.bottom div.itemsContainer div.items.rowCount7.portrait:hover:nth-child(6n) div.showCardContainer div.square {
    transform: translateX(-60px) translateY(-60px) scale(1.2);
  }
  div.myList div.bottom div.itemsContainer div.items.rowCount7.portrait:hover:nth-child(7n) div.showCardContainer div.square {
    transform: translateX(60px) translateY(-60px) scale(1.2);
  }
}
@media all and (min-width: 981px) {
  div.myList div.bottom div.itemsContainer div.items.rowCount7.portrait:hover:last-child div.showCardContainer div.square {
    transform: translateX(-60px) translateY(-60px) scale(1.2);
  }
}
@media all and (max-width: 980px) {
  div.myList div.bottom div.itemsContainer div.items.rowCount7.portrait {
    width: 24%;
    margin-right: 6px;
    margin-bottom: 40px;
  }
  div.myList div.bottom div.itemsContainer div.items.rowCount7.portrait:nth-child(5n) {
    margin-right: 6px;
  }
  div.myList div.bottom div.itemsContainer div.items.rowCount7.portrait:nth-child(4n) {
    margin-right: 0;
  }
  div.myList div.bottom div.itemsContainer div.items.rowCount7.portrait:nth-child(6n) {
    margin-right: 6px;
  }
}
@media all and (max-width: 768px) {
  div.myList div.bottom div.itemsContainer div.items.rowCount7.portrait {
    width: 32%;
  }
  div.myList div.bottom div.itemsContainer div.items.rowCount7.portrait:nth-child(4n) {
    margin-right: 6px;
  }
  div.myList div.bottom div.itemsContainer div.items.rowCount7.portrait:nth-child(3n) {
    margin-right: 0;
  }
}
@media all and (max-width: 640px) {
  div.myList div.bottom div.itemsContainer div.items.rowCount7.portrait {
    width: 48%;
  }
  div.myList div.bottom div.itemsContainer div.items.rowCount7.portrait:nth-child(3n) {
    margin-right: 6px;
  }
  div.myList div.bottom div.itemsContainer div.items.rowCount7.portrait:nth-child(2n) {
    margin-right: 0;
  }
}
div.myList div.bottom div.itemsContainer div.items.rowCount8 {
  width: 12%;
  margin-right: 8px;
  margin-bottom: 30px;
}
div.myList div.bottom div.itemsContainer div.items.rowCount8:nth-child(8n) {
  margin-right: 0;
}
@media all and (max-width: 2500px) {
  div.myList div.bottom div.itemsContainer div.items.rowCount8.landscape {
    width: 12%;
    margin-right: 10px;
  }
}
@media all and (max-width: 2200px) {
  div.myList div.bottom div.itemsContainer div.items.rowCount8.landscape {
    width: 12%;
    margin-right: 9px;
  }
}
@media all and (max-width: 1980px) {
  div.myList div.bottom div.itemsContainer div.items.rowCount8.landscape {
    width: 12%;
    margin-right: 8px;
  }
}
@media all and (max-width: 1768px) {
  div.myList div.bottom div.itemsContainer div.items.rowCount8.landscape {
    width: 12%;
    margin-right: 7px;
  }
}
@media all and (max-width: 1600px) {
  div.myList div.bottom div.itemsContainer div.items.rowCount8.landscape {
    width: 12%;
    margin-right: 6px;
  }
}
@media all and (max-width: 1400px) {
  div.myList div.bottom div.itemsContainer div.items.rowCount8.landscape {
    width: 12%;
    margin-right: 5px;
  }
}
@media all and (max-width: 1200px) {
  div.myList div.bottom div.itemsContainer div.items.rowCount8.landscape {
    width: 11.9%;
    margin-right: 5px;
  }
}
@media all and (min-width: 981px) {
  div.myList div.bottom div.itemsContainer div.items.rowCount8.landscape:hover div.showCardContainer .square {
    position: absolute;
    z-index: 9999999;
    transform: translateX(60px) translateY(-60px) scale(1.1);
  }
  div.myList div.bottom div.itemsContainer div.items.rowCount8.landscape:hover div.showCardContainer .square div.metaData {
    display: block;
    visibility: visible !important;
    animation: fadeIn 0.3s ease-in-out;
  }
  div.myList div.bottom div.itemsContainer div.items.rowCount8.landscape:hover:nth-child(5n) div.showCardContainer div.square {
    transform: translateX(-60px) translateY(-60px) scale(1.1);
  }
}
@media all and (max-width: 980px) {
  div.myList div.bottom div.itemsContainer div.items.rowCount8.landscape {
    width: 24.3%;
    margin-right: 6px;
    margin-bottom: 40px;
  }
  div.myList div.bottom div.itemsContainer div.items.rowCount8.landscape:nth-child(6n) {
    margin-right: 6px;
  }
  div.myList div.bottom div.itemsContainer div.items.rowCount8.landscape:nth-child(4n) {
    margin-right: 0;
  }
}
@media all and (max-width: 768px) {
  div.myList div.bottom div.itemsContainer div.items.rowCount8.landscape {
    width: 32.3%;
  }
  div.myList div.bottom div.itemsContainer div.items.rowCount8.landscape:nth-child(4n) {
    margin-right: 6px;
  }
  div.myList div.bottom div.itemsContainer div.items.rowCount8.landscape:nth-child(3n) {
    margin-right: 0;
  }
}
@media all and (max-width: 640px) {
  div.myList div.bottom div.itemsContainer div.items.rowCount8.landscape {
    width: 48.2%;
  }
  div.myList div.bottom div.itemsContainer div.items.rowCount8.landscape:nth-child(3n) {
    margin-right: 6px;
  }
  div.myList div.bottom div.itemsContainer div.items.rowCount8.landscape:nth-child(2n) {
    margin-right: 0;
  }
}
@media all and (max-width: 480px) {
  div.myList div.bottom div.itemsContainer div.items.rowCount8.landscape {
    width: 100%;
    margin-right: 0;
  }
  div.myList div.bottom div.itemsContainer div.items.rowCount8.landscape:nth-child(6n) {
    margin-right: 0;
  }
  div.myList div.bottom div.itemsContainer div.items.rowCount8.landscape:nth-child(4n) {
    margin-right: 0;
  }
  div.myList div.bottom div.itemsContainer div.items.rowCount8.landscape:nth-child(3n) {
    margin-right: 0;
  }
  div.myList div.bottom div.itemsContainer div.items.rowCount8.landscape:nth-child(2n) {
    margin-right: 0;
  }
}
@media all and (max-width: 480px) and (max-width: 360px) {
  div.myList div.bottom div.itemsContainer div.items.rowCount8.landscape {
    width: 100%;
    margin-right: 0;
  }
  div.myList div.bottom div.itemsContainer div.items.rowCount8.landscape:nth-child(3n) {
    margin-right: 0;
  }
}
div.myList div.bottom div.itemsContainer div.items.rowCount8.portrait {
  width: 11%;
  margin-right: 21px;
}
@media all and (max-width: 2600px) {
  div.myList div.bottom div.itemsContainer div.items.rowCount8.portrait {
    width: 12%;
    margin-right: 13px;
  }
  div.myList div.bottom div.itemsContainer div.items.rowCount8.portrait:nth-child(8n) {
    margin-right: 0;
  }
}
@media all and (max-width: 2500px) {
  div.myList div.bottom div.itemsContainer div.items.rowCount8.portrait {
    width: 12%;
    margin-right: 11px;
  }
}
@media all and (max-width: 2200px) {
  div.myList div.bottom div.itemsContainer div.items.rowCount8.portrait {
    width: 12%;
    margin-right: 10px;
  }
}
@media all and (max-width: 1980px) {
  div.myList div.bottom div.itemsContainer div.items.rowCount8.portrait {
    width: 12%;
    margin-right: 9px;
  }
}
@media all and (max-width: 1768px) {
  div.myList div.bottom div.itemsContainer div.items.rowCount8.portrait {
    width: 12%;
    margin-right: 8px;
  }
}
@media all and (max-width: 1600px) {
  div.myList div.bottom div.itemsContainer div.items.rowCount8.portrait {
    width: 12%;
    margin-right: 7px;
  }
}
@media all and (max-width: 1400px) {
  div.myList div.bottom div.itemsContainer div.items.rowCount8.portrait {
    width: 12%;
    margin-right: 6px;
  }
}
@media all and (max-width: 1200px) {
  div.myList div.bottom div.itemsContainer div.items.rowCount8.portrait {
    width: 11.9%;
    margin-right: 6px;
  }
}
@media all and (min-width: 981px) {
  div.myList div.bottom div.itemsContainer div.items.rowCount8.portrait:hover div.showCardContainer .square {
    position: absolute;
    z-index: 9999999;
    transform: translateX(60px) translateY(-60px) scale(1.2);
  }
  div.myList div.bottom div.itemsContainer div.items.rowCount8.portrait:hover div.showCardContainer .square div.metaData {
    display: block;
    visibility: visible !important;
    animation: fadeIn 0.3s ease-in-out;
  }
  div.myList div.bottom div.itemsContainer div.items.rowCount8.portrait:hover:nth-child(8n) div.showCardContainer div.square {
    transform: translateX(-60px) translateY(-60px) scale(1.2);
  }
  div.myList div.bottom div.itemsContainer div.items.rowCount8.portrait:hover:last-child div.showCardContainer div.square {
    transform: translateX(-60px) translateY(-60px) scale(1.2);
  }
}
@media all and (max-width: 980px) {
  div.myList div.bottom div.itemsContainer div.items.rowCount8.portrait {
    width: 24%;
    margin-right: 6px;
    margin-bottom: 40px;
  }
  div.myList div.bottom div.itemsContainer div.items.rowCount8.portrait:nth-child(5n) {
    margin-right: 6px;
  }
  div.myList div.bottom div.itemsContainer div.items.rowCount8.portrait:nth-child(4n) {
    margin-right: 0;
  }
  div.myList div.bottom div.itemsContainer div.items.rowCount8.portrait:nth-child(6n) {
    margin-right: 6px;
  }
}
@media all and (max-width: 768px) {
  div.myList div.bottom div.itemsContainer div.items.rowCount8.portrait {
    width: 32%;
  }
  div.myList div.bottom div.itemsContainer div.items.rowCount8.portrait:nth-child(4n) {
    margin-right: 6px;
  }
  div.myList div.bottom div.itemsContainer div.items.rowCount8.portrait:nth-child(3n) {
    margin-right: 0;
  }
}
@media all and (max-width: 640px) {
  div.myList div.bottom div.itemsContainer div.items.rowCount8.portrait {
    width: 48%;
  }
  div.myList div.bottom div.itemsContainer div.items.rowCount8.portrait:nth-child(3n) {
    margin-right: 6px;
  }
  div.myList div.bottom div.itemsContainer div.items.rowCount8.portrait:nth-child(2n) {
    margin-right: 0;
  }
}
@media all and (max-width: 980px) {
  div.myList div.bottom div.itemsContainer div.items.portrait {
    width: 23.5%;
    margin-right: 13px;
    margin-bottom: 40px;
  }
  div.myList div.bottom div.itemsContainer div.items.portrait:nth-child(4n) {
    margin-right: 0;
  }
  div.myList div.bottom div.itemsContainer div.items.portrait:nth-child(5n) {
    margin-right: 13px;
  }
}
@media all and (max-width: 768px) {
  div.myList div.bottom div.itemsContainer div.items.portrait {
    width: 23%;
  }
  div.myList div.bottom div.itemsContainer div.items.portrait:nth-child(4n) {
    margin-right: 0;
  }
  div.myList div.bottom div.itemsContainer div.items.portrait:nth-child(5n) {
    margin-right: 13px;
  }
}
@media all and (max-width: 640px) {
  div.myList div.bottom div.itemsContainer div.items.portrait {
    width: 31%;
  }
  div.myList div.bottom div.itemsContainer div.items.portrait:nth-child(4n) {
    margin-right: 13px;
  }
  div.myList div.bottom div.itemsContainer div.items.portrait:nth-child(3n) {
    margin-right: 0;
  }
}
@media all and (max-width: 480px) {
  div.myList div.bottom div.itemsContainer div.items.portrait {
    width: 47%;
  }
  div.myList div.bottom div.itemsContainer div.items.portrait:nth-child(3n) {
    margin-right: 13px;
  }
  div.myList div.bottom div.itemsContainer div.items.portrait:nth-child(2n) {
    margin-right: 0;
  }
}
@media all and (max-width: 980px) {
  div.myList div.bottom div.itemsContainer div.items.landscape {
    width: 32%;
    margin-right: 13px;
    margin-bottom: 40px;
  }
  div.myList div.bottom div.itemsContainer div.items.landscape:nth-child(3n) {
    margin-right: 0;
  }
}
@media all and (max-width: 768px) {
  div.myList div.bottom div.itemsContainer div.items.landscape {
    width: 47.6%;
  }
  div.myList div.bottom div.itemsContainer div.items.landscape:nth-child(2n) {
    margin-right: 0;
  }
  div.myList div.bottom div.itemsContainer div.items.landscape:nth-child(3n) {
    margin-right: 13px;
  }
}
@media all and (max-width: 640px) {
  div.myList div.bottom div.itemsContainer div.items.landscape {
    width: 48%;
  }
  div.myList div.bottom div.itemsContainer div.items.landscape:nth-child(3n) {
    margin-right: 13px;
  }
  div.myList div.bottom div.itemsContainer div.items.landscape:nth-child(2n) {
    margin-right: 0;
  }
}
@media all and (max-width: 480px) {
  div.myList div.bottom div.itemsContainer div.items.landscape {
    width: 47%;
  }
  div.myList div.bottom div.itemsContainer div.items.landscape:nth-child(3n) {
    margin-right: 13px;
  }
  div.myList div.bottom div.itemsContainer div.items.landscape:nth-child(2n) {
    margin-right: 0;
  }
}

div.myList.rtl .bottom .itemsContainer .items.rowCount3 {
  margin-right: 0;
  margin-left: 13px;
}
div.myList.rtl .bottom .itemsContainer .items.rowCount3:nth-child(3n) {
  margin-left: 0;
}
@media all and (min-width: 981px) {
  div.myList.rtl .bottom .itemsContainer .items.rowCount3.landscape:hover div.showCardContainer .square {
    transform: translateX(-60px) translateY(-60px) scale(1.1);
  }
  div.myList.rtl .bottom .itemsContainer .items.rowCount3.landscape:hover:nth-child(3n) div.showCardContainer div.square {
    transform: translateX(60px) translateY(-60px) scale(1.1);
  }
}
@media all and (max-width: 980px) {
  div.myList.rtl .bottom .itemsContainer .items.rowCount3.landscape {
    margin-right: 0;
    margin-left: 6px;
  }
  div.myList.rtl .bottom .itemsContainer .items.rowCount3.landscape:nth-child(6n) {
    margin-right: 0;
    margin-left: 6px;
  }
  div.myList.rtl .bottom .itemsContainer .items.rowCount3.landscape:nth-child(4n) {
    margin-left: 0;
  }
}
@media all and (max-width: 768px) {
  div.myList.rtl .bottom .itemsContainer .items.rowCount3.landscape:nth-child(4n) {
    margin-right: 0;
    margin-left: 6px;
  }
  div.myList.rtl .bottom .itemsContainer .items.rowCount3.landscape:nth-child(3n) {
    margin-left: 0;
  }
}
@media all and (max-width: 640px) {
  div.myList.rtl .bottom .itemsContainer .items.rowCount3.landscape:nth-child(3n) {
    margin-right: 0;
    margin-left: 6px;
  }
  div.myList.rtl .bottom .itemsContainer .items.rowCount3.landscape:nth-child(2n) {
    margin-left: 0;
  }
}
@media all and (max-width: 480px) {
  div.myList.rtl .bottom .itemsContainer .items.rowCount3.landscape {
    margin-left: 0;
  }
  div.myList.rtl .bottom .itemsContainer .items.rowCount3.landscape:nth-child(6n) {
    margin-left: 0;
  }
  div.myList.rtl .bottom .itemsContainer .items.rowCount3.landscape:nth-child(4n) {
    margin-left: 0;
  }
  div.myList.rtl .bottom .itemsContainer .items.rowCount3.landscape:nth-child(3n) {
    margin-left: 0;
  }
  div.myList.rtl .bottom .itemsContainer .items.rowCount3.landscape:nth-child(2n) {
    margin-left: 0;
  }
}
@media all and (max-width: 480px) and (max-width: 360px) {
  div.myList.rtl .bottom .itemsContainer .items.rowCount3.landscape {
    margin-left: 0;
  }
  div.myList.rtl .bottom .itemsContainer .items.rowCount3.landscape:nth-child(3n) {
    margin-left: 0;
  }
}
@media all and (min-width: 981px) {
  div.myList.rtl .bottom .itemsContainer .items.rowCount3.portrait:hover div.showCardContainer .square {
    transform: translateX(-60px) translateY(-60px) scale(1.2);
  }
  div.myList.rtl .bottom .itemsContainer .items.rowCount3.portrait:hover:nth-child(3n) div.showCardContainer div.square {
    transform: translateX(60px) translateY(-60px) scale(1.2);
  }
}
@media all and (min-width: 981px) and (max-width: 1300px) {
  div.myList.rtl .bottom .itemsContainer .items.rowCount3.portrait:hover:nth-child(6n) div.showCardContainer div.square {
    transform: translateX(60px) translateY(-60px) scale(1.2);
  }
  div.myList.rtl .bottom .itemsContainer .items.rowCount3.portrait:hover:nth-child(7n) div.showCardContainer div.square {
    transform: translateX(-60px) translateY(-60px) scale(1.2);
  }
}
@media all and (min-width: 981px) {
  div.myList.rtl .bottom .itemsContainer .items.rowCount3.portrait:hover:last-child div.showCardContainer div.square {
    transform: translateX(60px) translateY(-60px) scale(1.2);
  }
}
@media all and (max-width: 980px) {
  div.myList.rtl .bottom .itemsContainer .items.rowCount3.portrait {
    margin-right: 0;
    margin-left: 6px;
  }
  div.myList.rtl .bottom .itemsContainer .items.rowCount3.portrait:nth-child(5n) {
    margin-right: 0;
    margin-left: 6px;
  }
  div.myList.rtl .bottom .itemsContainer .items.rowCount3.portrait:nth-child(4n) {
    margin-left: 0;
  }
  div.myList.rtl .bottom .itemsContainer .items.rowCount3.portrait:nth-child(6n) {
    margin-right: 0;
    margin-left: 6px;
  }
}
@media all and (max-width: 768px) {
  div.myList.rtl .bottom .itemsContainer .items.rowCount3.portrait:nth-child(4n) {
    margin-right: 0;
    margin-left: 6px;
  }
  div.myList.rtl .bottom .itemsContainer .items.rowCount3.portrait:nth-child(3n) {
    margin-left: 0;
  }
}
@media all and (max-width: 640px) {
  div.myList.rtl .bottom .itemsContainer .items.rowCount3.portrait:nth-child(3n) {
    margin-right: 0;
    margin-left: 6px;
  }
  div.myList.rtl .bottom .itemsContainer .items.rowCount3.portrait:nth-child(2n) {
    margin-left: 0;
  }
}
div.myList.rtl .bottom .itemsContainer .items.rowCount4 {
  margin-right: 0;
  margin-left: 13px;
}
div.myList.rtl .bottom .itemsContainer .items.rowCount4:nth-child(4n) {
  margin-left: 0;
}
@media all and (min-width: 981px) {
  div.myList.rtl .bottom .itemsContainer .items.rowCount4.landscape:hover div.showCardContainer .square {
    transform: translateX(-60px) translateY(-60px) scale(1.1);
  }
  div.myList.rtl .bottom .itemsContainer .items.rowCount4.landscape:hover:nth-child(4n) div.showCardContainer div.square {
    transform: translateX(60px) translateY(-60px) scale(1.1);
  }
}
@media all and (max-width: 980px) {
  div.myList.rtl .bottom .itemsContainer .items.rowCount4.landscape {
    margin-right: 0;
    margin-left: 6px;
  }
  div.myList.rtl .bottom .itemsContainer .items.rowCount4.landscape:nth-child(6n) {
    margin-right: 0;
    margin-left: 6px;
  }
  div.myList.rtl .bottom .itemsContainer .items.rowCount4.landscape:nth-child(4n) {
    margin-left: 0;
  }
}
@media all and (max-width: 768px) {
  div.myList.rtl .bottom .itemsContainer .items.rowCount4.landscape:nth-child(4n) {
    margin-right: 0;
    margin-left: 6px;
  }
  div.myList.rtl .bottom .itemsContainer .items.rowCount4.landscape:nth-child(3n) {
    margin-left: 0;
  }
}
@media all and (max-width: 640px) {
  div.myList.rtl .bottom .itemsContainer .items.rowCount4.landscape:nth-child(3n) {
    margin-right: 0;
    margin-left: 6px;
  }
  div.myList.rtl .bottom .itemsContainer .items.rowCount4.landscape:nth-child(2n) {
    margin-left: 0;
  }
}
@media all and (max-width: 480px) {
  div.myList.rtl .bottom .itemsContainer .items.rowCount4.landscape {
    margin-left: 0;
  }
  div.myList.rtl .bottom .itemsContainer .items.rowCount4.landscape:nth-child(6n) {
    margin-left: 0;
  }
  div.myList.rtl .bottom .itemsContainer .items.rowCount4.landscape:nth-child(4n) {
    margin-left: 0;
  }
  div.myList.rtl .bottom .itemsContainer .items.rowCount4.landscape:nth-child(3n) {
    margin-left: 0;
  }
  div.myList.rtl .bottom .itemsContainer .items.rowCount4.landscape:nth-child(2n) {
    margin-left: 0;
  }
}
@media all and (max-width: 480px) and (max-width: 360px) {
  div.myList.rtl .bottom .itemsContainer .items.rowCount4.landscape {
    margin-left: 0;
  }
  div.myList.rtl .bottom .itemsContainer .items.rowCount4.landscape:nth-child(3n) {
    margin-left: 0;
  }
}
@media all and (min-width: 981px) {
  div.myList.rtl .bottom .itemsContainer .items.rowCount4.portrait:hover div.showCardContainer .square {
    transform: translateX(-60px) translateY(-60px) scale(1.2);
  }
  div.myList.rtl .bottom .itemsContainer .items.rowCount4.portrait:hover:nth-child(4n) div.showCardContainer div.square {
    transform: translateX(60px) translateY(-60px) scale(1.2);
  }
}
@media all and (min-width: 981px) and (max-width: 1300px) {
  div.myList.rtl .bottom .itemsContainer .items.rowCount4.portrait:hover:nth-child(6n) div.showCardContainer div.square {
    transform: translateX(60px) translateY(-60px) scale(1.2);
  }
  div.myList.rtl .bottom .itemsContainer .items.rowCount4.portrait:hover:nth-child(7n) div.showCardContainer div.square {
    transform: translateX(-60px) translateY(-60px) scale(1.2);
  }
}
@media all and (min-width: 981px) {
  div.myList.rtl .bottom .itemsContainer .items.rowCount4.portrait:hover:last-child div.showCardContainer div.square {
    transform: translateX(60px) translateY(-60px) scale(1.2);
  }
}
@media all and (max-width: 980px) {
  div.myList.rtl .bottom .itemsContainer .items.rowCount4.portrait {
    margin-right: 0;
    margin-left: 6px;
  }
  div.myList.rtl .bottom .itemsContainer .items.rowCount4.portrait:nth-child(5n) {
    margin-right: 0;
    margin-left: 6px;
  }
  div.myList.rtl .bottom .itemsContainer .items.rowCount4.portrait:nth-child(4n) {
    margin-left: 0;
  }
  div.myList.rtl .bottom .itemsContainer .items.rowCount4.portrait:nth-child(6n) {
    margin-right: 0;
    margin-left: 6px;
  }
}
@media all and (max-width: 768px) {
  div.myList.rtl .bottom .itemsContainer .items.rowCount4.portrait:nth-child(4n) {
    margin-right: 0;
    margin-left: 6px;
  }
  div.myList.rtl .bottom .itemsContainer .items.rowCount4.portrait:nth-child(3n) {
    margin-left: 0;
  }
}
@media all and (max-width: 640px) {
  div.myList.rtl .bottom .itemsContainer .items.rowCount4.portrait:nth-child(3n) {
    margin-right: 0;
    margin-left: 6px;
  }
  div.myList.rtl .bottom .itemsContainer .items.rowCount4.portrait:nth-child(2n) {
    margin-left: 0;
  }
}
div.myList.rtl .bottom .itemsContainer .items.rowCount5 {
  margin-right: 0;
  margin-left: 13px;
}
div.myList.rtl .bottom .itemsContainer .items.rowCount5:nth-child(5n) {
  margin-left: 0;
}
@media all and (min-width: 981px) {
  div.myList.rtl .bottom .itemsContainer .items.rowCount5.landscape:hover div.showCardContainer .square {
    transform: translateX(-60px) translateY(-60px) scale(1.1);
  }
  div.myList.rtl .bottom .itemsContainer .items.rowCount5.landscape:hover:nth-child(5n) div.showCardContainer div.square {
    transform: translateX(60px) translateY(-60px) scale(1.1);
  }
}
@media all and (max-width: 980px) {
  div.myList.rtl .bottom .itemsContainer .items.rowCount5.landscape {
    margin-right: 0;
    margin-left: 6px;
  }
  div.myList.rtl .bottom .itemsContainer .items.rowCount5.landscape:nth-child(6n) {
    margin-right: 0;
    margin-left: 6px;
  }
  div.myList.rtl .bottom .itemsContainer .items.rowCount5.landscape:nth-child(4n) {
    margin-left: 0;
  }
}
@media all and (max-width: 768px) {
  div.myList.rtl .bottom .itemsContainer .items.rowCount5.landscape:nth-child(4n) {
    margin-right: 0;
    margin-left: 6px;
  }
  div.myList.rtl .bottom .itemsContainer .items.rowCount5.landscape:nth-child(3n) {
    margin-left: 0;
  }
}
@media all and (max-width: 640px) {
  div.myList.rtl .bottom .itemsContainer .items.rowCount5.landscape:nth-child(3n) {
    margin-right: 0;
    margin-left: 6px;
  }
  div.myList.rtl .bottom .itemsContainer .items.rowCount5.landscape:nth-child(2n) {
    margin-left: 0;
  }
}
@media all and (max-width: 480px) {
  div.myList.rtl .bottom .itemsContainer .items.rowCount5.landscape {
    margin-left: 0;
  }
  div.myList.rtl .bottom .itemsContainer .items.rowCount5.landscape:nth-child(6n) {
    margin-left: 0;
  }
  div.myList.rtl .bottom .itemsContainer .items.rowCount5.landscape:nth-child(4n) {
    margin-left: 0;
  }
  div.myList.rtl .bottom .itemsContainer .items.rowCount5.landscape:nth-child(3n) {
    margin-left: 0;
  }
  div.myList.rtl .bottom .itemsContainer .items.rowCount5.landscape:nth-child(2n) {
    margin-left: 0;
  }
}
@media all and (max-width: 480px) and (max-width: 360px) {
  div.myList.rtl .bottom .itemsContainer .items.rowCount5.landscape {
    margin-left: 0;
  }
  div.myList.rtl .bottom .itemsContainer .items.rowCount5.landscape:nth-child(3n) {
    margin-left: 0;
  }
}
@media all and (min-width: 981px) {
  div.myList.rtl .bottom .itemsContainer .items.rowCount5.portrait:hover div.showCardContainer .square {
    transform: translateX(-60px) translateY(-60px) scale(1.2);
  }
  div.myList.rtl .bottom .itemsContainer .items.rowCount5.portrait:hover:nth-child(5n) div.showCardContainer div.square {
    transform: translateX(60px) translateY(-60px) scale(1.2);
  }
}
@media all and (min-width: 981px) and (max-width: 1300px) {
  div.myList.rtl .bottom .itemsContainer .items.rowCount5.portrait:hover:nth-child(6n) div.showCardContainer div.square {
    transform: translateX(60px) translateY(-60px) scale(1.2);
  }
  div.myList.rtl .bottom .itemsContainer .items.rowCount5.portrait:hover:nth-child(7n) div.showCardContainer div.square {
    transform: translateX(-60px) translateY(-60px) scale(1.2);
  }
}
@media all and (min-width: 981px) {
  div.myList.rtl .bottom .itemsContainer .items.rowCount5.portrait:hover:last-child div.showCardContainer div.square {
    transform: translateX(60px) translateY(-60px) scale(1.2);
  }
}
@media all and (max-width: 980px) {
  div.myList.rtl .bottom .itemsContainer .items.rowCount5.portrait {
    margin-right: 0;
    margin-left: 6px;
  }
  div.myList.rtl .bottom .itemsContainer .items.rowCount5.portrait:nth-child(5n) {
    margin-right: 0;
    margin-left: 6px;
  }
  div.myList.rtl .bottom .itemsContainer .items.rowCount5.portrait:nth-child(4n) {
    margin-left: 0;
  }
  div.myList.rtl .bottom .itemsContainer .items.rowCount5.portrait:nth-child(6n) {
    margin-right: 0;
    margin-left: 6px;
  }
}
@media all and (max-width: 768px) {
  div.myList.rtl .bottom .itemsContainer .items.rowCount5.portrait:nth-child(4n) {
    margin-right: 0;
    margin-left: 6px;
  }
  div.myList.rtl .bottom .itemsContainer .items.rowCount5.portrait:nth-child(3n) {
    margin-left: 0;
  }
}
@media all and (max-width: 640px) {
  div.myList.rtl .bottom .itemsContainer .items.rowCount5.portrait:nth-child(3n) {
    margin-right: 0;
    margin-left: 6px;
  }
  div.myList.rtl .bottom .itemsContainer .items.rowCount5.portrait:nth-child(2n) {
    margin-left: 0;
  }
}
div.myList.rtl .bottom .itemsContainer .items.rowCount6 {
  margin-right: 0;
  margin-left: 13px;
}
div.myList.rtl .bottom .itemsContainer .items.rowCount6:nth-child(6n) {
  margin-left: 0;
}
@media all and (min-width: 981px) {
  div.myList.rtl .bottom .itemsContainer .items.rowCount6.landscape:hover div.showCardContainer .square {
    transform: translateX(-60px) translateY(-60px) scale(1.1);
  }
  div.myList.rtl .bottom .itemsContainer .items.rowCount6.landscape:hover:nth-child(6n) div.showCardContainer div.square {
    transform: translateX(60px) translateY(-60px) scale(1.1);
  }
}
@media all and (max-width: 980px) {
  div.myList.rtl .bottom .itemsContainer .items.rowCount6.landscape {
    margin-right: 0;
    margin-left: 6px;
  }
  div.myList.rtl .bottom .itemsContainer .items.rowCount6.landscape:nth-child(6n) {
    margin-right: 0;
    margin-left: 6px;
  }
  div.myList.rtl .bottom .itemsContainer .items.rowCount6.landscape:nth-child(4n) {
    margin-left: 0;
  }
}
@media all and (max-width: 768px) {
  div.myList.rtl .bottom .itemsContainer .items.rowCount6.landscape:nth-child(4n) {
    margin-right: 0;
    margin-left: 6px;
  }
  div.myList.rtl .bottom .itemsContainer .items.rowCount6.landscape:nth-child(3n) {
    margin-left: 0;
  }
}
@media all and (max-width: 640px) {
  div.myList.rtl .bottom .itemsContainer .items.rowCount6.landscape:nth-child(3n) {
    margin-right: 0;
    margin-left: 6px;
  }
  div.myList.rtl .bottom .itemsContainer .items.rowCount6.landscape:nth-child(2n) {
    margin-left: 0;
  }
}
@media all and (max-width: 480px) {
  div.myList.rtl .bottom .itemsContainer .items.rowCount6.landscape {
    margin-left: 0;
  }
  div.myList.rtl .bottom .itemsContainer .items.rowCount6.landscape:nth-child(6n) {
    margin-left: 0;
  }
  div.myList.rtl .bottom .itemsContainer .items.rowCount6.landscape:nth-child(4n) {
    margin-left: 0;
  }
  div.myList.rtl .bottom .itemsContainer .items.rowCount6.landscape:nth-child(3n) {
    margin-left: 0;
  }
  div.myList.rtl .bottom .itemsContainer .items.rowCount6.landscape:nth-child(2n) {
    margin-left: 0;
  }
}
@media all and (max-width: 480px) and (max-width: 360px) {
  div.myList.rtl .bottom .itemsContainer .items.rowCount6.landscape {
    margin-left: 0;
  }
  div.myList.rtl .bottom .itemsContainer .items.rowCount6.landscape:nth-child(3n) {
    margin-left: 0;
  }
}
@media all and (min-width: 981px) {
  div.myList.rtl .bottom .itemsContainer .items.rowCount6.portrait:hover div.showCardContainer .square {
    transform: translateX(-60px) translateY(-60px) scale(1.2);
  }
  div.myList.rtl .bottom .itemsContainer .items.rowCount6.portrait:hover:nth-child(6n) div.showCardContainer div.square {
    transform: translateX(60px) translateY(-60px) scale(1.2);
  }
}
@media all and (min-width: 981px) and (max-width: 1300px) {
  div.myList.rtl .bottom .itemsContainer .items.rowCount6.portrait:hover:nth-child(6n) div.showCardContainer div.square {
    transform: translateX(60px) translateY(-60px) scale(1.2);
  }
  div.myList.rtl .bottom .itemsContainer .items.rowCount6.portrait:hover:nth-child(7n) div.showCardContainer div.square {
    transform: translateX(-60px) translateY(-60px) scale(1.2);
  }
}
@media all and (min-width: 981px) {
  div.myList.rtl .bottom .itemsContainer .items.rowCount6.portrait:hover:last-child div.showCardContainer div.square {
    transform: translateX(60px) translateY(-60px) scale(1.2);
  }
}
@media all and (max-width: 980px) {
  div.myList.rtl .bottom .itemsContainer .items.rowCount6.portrait {
    margin-right: 0;
    margin-left: 6px;
  }
  div.myList.rtl .bottom .itemsContainer .items.rowCount6.portrait:nth-child(5n) {
    margin-right: 0;
    margin-left: 6px;
  }
  div.myList.rtl .bottom .itemsContainer .items.rowCount6.portrait:nth-child(4n) {
    margin-left: 0;
  }
  div.myList.rtl .bottom .itemsContainer .items.rowCount6.portrait:nth-child(6n) {
    margin-right: 0;
    margin-left: 6px;
  }
}
@media all and (max-width: 768px) {
  div.myList.rtl .bottom .itemsContainer .items.rowCount6.portrait:nth-child(4n) {
    margin-right: 0;
    margin-left: 6px;
  }
  div.myList.rtl .bottom .itemsContainer .items.rowCount6.portrait:nth-child(3n) {
    margin-left: 0;
  }
}
@media all and (max-width: 640px) {
  div.myList.rtl .bottom .itemsContainer .items.rowCount6.portrait:nth-child(3n) {
    margin-right: 0;
    margin-left: 6px;
  }
  div.myList.rtl .bottom .itemsContainer .items.rowCount6.portrait:nth-child(2n) {
    margin-left: 0;
  }
}
div.myList.rtl .bottom .itemsContainer .items.rowCount7 {
  margin-right: 0;
  margin-left: 11px;
}
div.myList.rtl .bottom .itemsContainer .items.rowCount7:nth-child(7n) {
  margin-left: 0;
}
@media all and (max-width: 2600px) {
  div.myList.rtl .bottom .itemsContainer .items.rowCount7.landscape {
    margin-right: 0;
    margin-left: 29px;
  }
}
@media all and (max-width: 2500px) {
  div.myList.rtl .bottom .itemsContainer .items.rowCount7.landscape {
    margin-right: 0;
    margin-left: 25px;
  }
}
@media all and (max-width: 2200px) {
  div.myList.rtl .bottom .itemsContainer .items.rowCount7.landscape {
    margin-right: 0;
    margin-left: 8px;
  }
}
@media all and (max-width: 1980px) {
  div.myList.rtl .bottom .itemsContainer .items.rowCount7.landscape {
    margin-right: 0;
    margin-left: 6px;
  }
}
@media all and (max-width: 1768px) {
  div.myList.rtl .bottom .itemsContainer .items.rowCount7.landscape {
    margin-right: 0;
    margin-left: 5px;
  }
}
@media all and (max-width: 1600px) {
  div.myList.rtl .bottom .itemsContainer .items.rowCount7.landscape {
    margin-right: 0;
    margin-left: 5px;
  }
}
@media all and (max-width: 1400px) {
  div.myList.rtl .bottom .itemsContainer .items.rowCount7.landscape {
    margin-right: 0;
    margin-left: 5px;
  }
}
@media all and (max-width: 1200px) {
  div.myList.rtl .bottom .itemsContainer .items.rowCount7.landscape {
    margin-right: 0;
    margin-left: 5px;
  }
}
@media all and (min-width: 981px) {
  div.myList.rtl .bottom .itemsContainer .items.rowCount7.landscape:hover div.showCardContainer .square {
    transform: translateX(-60px) translateY(-60px) scale(1.1);
  }
  div.myList.rtl .bottom .itemsContainer .items.rowCount7.landscape:hover:nth-child(6n) div.showCardContainer div.square {
    transform: translateX(60px) translateY(-60px) scale(1.1);
  }
}
@media all and (max-width: 980px) {
  div.myList.rtl .bottom .itemsContainer .items.rowCount7.landscape {
    margin-right: 0;
    margin-left: 6px;
  }
  div.myList.rtl .bottom .itemsContainer .items.rowCount7.landscape:nth-child(6n) {
    margin-right: 0;
    margin-left: 6px;
  }
  div.myList.rtl .bottom .itemsContainer .items.rowCount7.landscape:nth-child(4n) {
    margin-left: 0;
  }
}
@media all and (max-width: 768px) {
  div.myList.rtl .bottom .itemsContainer .items.rowCount7.landscape:nth-child(4n) {
    margin-right: 0;
    margin-left: 6px;
  }
  div.myList.rtl .bottom .itemsContainer .items.rowCount7.landscape:nth-child(3n) {
    margin-left: 0;
  }
}
@media all and (max-width: 640px) {
  div.myList.rtl .bottom .itemsContainer .items.rowCount7.landscape:nth-child(3n) {
    margin-right: 0;
    margin-left: 6px;
  }
  div.myList.rtl .bottom .itemsContainer .items.rowCount7.landscape:nth-child(2n) {
    margin-left: 0;
  }
}
@media all and (max-width: 480px) {
  div.myList.rtl .bottom .itemsContainer .items.rowCount7.landscape {
    margin-left: 0;
  }
  div.myList.rtl .bottom .itemsContainer .items.rowCount7.landscape:nth-child(6n) {
    margin-left: 0;
  }
  div.myList.rtl .bottom .itemsContainer .items.rowCount7.landscape:nth-child(4n) {
    margin-left: 0;
  }
  div.myList.rtl .bottom .itemsContainer .items.rowCount7.landscape:nth-child(3n) {
    margin-left: 0;
  }
  div.myList.rtl .bottom .itemsContainer .items.rowCount7.landscape:nth-child(2n) {
    margin-left: 0;
  }
}
@media all and (max-width: 480px) and (max-width: 360px) {
  div.myList.rtl .bottom .itemsContainer .items.rowCount7.landscape {
    margin-left: 0;
  }
  div.myList.rtl .bottom .itemsContainer .items.rowCount7.landscape:nth-child(3n) {
    margin-left: 0;
  }
}
@media all and (max-width: 1600px) {
  div.myList.rtl .bottom .itemsContainer .items.rowCount7.portrait {
    margin-right: 0;
    margin-left: 7px;
  }
  div.myList.rtl .bottom .itemsContainer .items.rowCount7.portrait:nth-child(7n) {
    margin-right: 0;
    margin-left: 0px;
  }
}
@media all and (max-width: 1400px) {
  div.myList.rtl .bottom .itemsContainer .items.rowCount7.portrait {
    margin-right: 0;
    margin-left: 3px;
  }
}
@media all and (max-width: 1200px) {
  div.myList.rtl .bottom .itemsContainer .items.rowCount7.portrait {
    margin-right: 0;
    margin-left: 6.1px;
  }
}
@media all and (min-width: 981px) {
  div.myList.rtl .bottom .itemsContainer .items.rowCount7.portrait:hover div.showCardContainer .square {
    transform: translateX(-60px) translateY(-60px) scale(1.2);
  }
  div.myList.rtl .bottom .itemsContainer .items.rowCount7.portrait:hover:nth-child(6n) div.showCardContainer div.square {
    transform: translateX(60px) translateY(-60px) scale(1.2);
  }
}
@media all and (min-width: 981px) and (max-width: 1300px) {
  div.myList.rtl .bottom .itemsContainer .items.rowCount7.portrait:hover:nth-child(6n) div.showCardContainer div.square {
    transform: translateX(60px) translateY(-60px) scale(1.2);
  }
  div.myList.rtl .bottom .itemsContainer .items.rowCount7.portrait:hover:nth-child(7n) div.showCardContainer div.square {
    transform: translateX(-60px) translateY(-60px) scale(1.2);
  }
}
@media all and (min-width: 981px) {
  div.myList.rtl .bottom .itemsContainer .items.rowCount7.portrait:hover:last-child div.showCardContainer div.square {
    transform: translateX(60px) translateY(-60px) scale(1.2);
  }
}
@media all and (max-width: 980px) {
  div.myList.rtl .bottom .itemsContainer .items.rowCount7.portrait {
    margin-right: 0;
    margin-left: 6px;
  }
  div.myList.rtl .bottom .itemsContainer .items.rowCount7.portrait:nth-child(5n) {
    margin-right: 0;
    margin-left: 6px;
  }
  div.myList.rtl .bottom .itemsContainer .items.rowCount7.portrait:nth-child(4n) {
    margin-left: 0;
  }
  div.myList.rtl .bottom .itemsContainer .items.rowCount7.portrait:nth-child(6n) {
    margin-right: 0;
    margin-left: 6px;
  }
}
@media all and (max-width: 768px) {
  div.myList.rtl .bottom .itemsContainer .items.rowCount7.portrait:nth-child(4n) {
    margin-right: 0;
    margin-left: 6px;
  }
  div.myList.rtl .bottom .itemsContainer .items.rowCount7.portrait:nth-child(3n) {
    margin-left: 0;
  }
}
@media all and (max-width: 640px) {
  div.myList.rtl .bottom .itemsContainer .items.rowCount7.portrait:nth-child(3n) {
    margin-right: 0;
    margin-left: 6px;
  }
  div.myList.rtl .bottom .itemsContainer .items.rowCount7.portrait:nth-child(2n) {
    margin-left: 0;
  }
}
div.myList.rtl .bottom .itemsContainer .items.rowCount8 {
  margin-right: 0;
  margin-left: 8px;
}
div.myList.rtl .bottom .itemsContainer .items.rowCount8:nth-child(8n) {
  margin-left: 0;
}
@media all and (max-width: 2500px) {
  div.myList.rtl .bottom .itemsContainer .items.rowCount8.landscape {
    margin-right: 0;
    margin-left: 10px;
  }
}
@media all and (max-width: 2200px) {
  div.myList.rtl .bottom .itemsContainer .items.rowCount8.landscape {
    margin-right: 0;
    margin-left: 9px;
  }
}
@media all and (max-width: 1980px) {
  div.myList.rtl .bottom .itemsContainer .items.rowCount8.landscape {
    margin-right: 0;
    margin-left: 8px;
  }
}
@media all and (max-width: 1768px) {
  div.myList.rtl .bottom .itemsContainer .items.rowCount8.landscape {
    margin-right: 0;
    margin-left: 7px;
  }
}
@media all and (max-width: 1600px) {
  div.myList.rtl .bottom .itemsContainer .items.rowCount8.landscape {
    margin-right: 0;
    margin-left: 6px;
  }
}
@media all and (max-width: 1400px) {
  div.myList.rtl .bottom .itemsContainer .items.rowCount8.landscape {
    margin-right: 0;
    margin-left: 5px;
  }
}
@media all and (max-width: 1200px) {
  div.myList.rtl .bottom .itemsContainer .items.rowCount8.landscape {
    margin-right: 0;
    margin-left: 5px;
  }
}
@media all and (min-width: 981px) {
  div.myList.rtl .bottom .itemsContainer .items.rowCount8.landscape:hover div.showCardContainer .square {
    transform: translateX(-60px) translateY(-60px) scale(1.1);
  }
  div.myList.rtl .bottom .itemsContainer .items.rowCount8.landscape:hover:nth-child(5n) div.showCardContainer div.square {
    transform: translateX(60px) translateY(-60px) scale(1.1);
  }
}
@media all and (max-width: 980px) {
  div.myList.rtl .bottom .itemsContainer .items.rowCount8.landscape {
    margin-right: 0;
    margin-left: 6px;
  }
  div.myList.rtl .bottom .itemsContainer .items.rowCount8.landscape:nth-child(6n) {
    margin-right: 0;
    margin-left: 6px;
  }
  div.myList.rtl .bottom .itemsContainer .items.rowCount8.landscape:nth-child(4n) {
    margin-left: 0;
  }
}
@media all and (max-width: 768px) {
  div.myList.rtl .bottom .itemsContainer .items.rowCount8.landscape:nth-child(4n) {
    margin-right: 0;
    margin-left: 6px;
  }
  div.myList.rtl .bottom .itemsContainer .items.rowCount8.landscape:nth-child(3n) {
    margin-left: 0;
  }
}
@media all and (max-width: 640px) {
  div.myList.rtl .bottom .itemsContainer .items.rowCount8.landscape:nth-child(3n) {
    margin-right: 0;
    margin-left: 6px;
  }
  div.myList.rtl .bottom .itemsContainer .items.rowCount8.landscape:nth-child(2n) {
    margin-left: 0;
  }
}
@media all and (max-width: 480px) {
  div.myList.rtl .bottom .itemsContainer .items.rowCount8.landscape {
    margin-left: 0;
  }
  div.myList.rtl .bottom .itemsContainer .items.rowCount8.landscape:nth-child(6n) {
    margin-left: 0;
  }
  div.myList.rtl .bottom .itemsContainer .items.rowCount8.landscape:nth-child(4n) {
    margin-left: 0;
  }
  div.myList.rtl .bottom .itemsContainer .items.rowCount8.landscape:nth-child(3n) {
    margin-left: 0;
  }
  div.myList.rtl .bottom .itemsContainer .items.rowCount8.landscape:nth-child(2n) {
    margin-left: 0;
  }
}
@media all and (max-width: 480px) and (max-width: 360px) {
  div.myList.rtl .bottom .itemsContainer .items.rowCount8.landscape {
    margin-left: 0;
  }
  div.myList.rtl .bottom .itemsContainer .items.rowCount8.landscape:nth-child(3n) {
    margin-left: 0;
  }
}
div.myList.rtl .bottom .itemsContainer .items.rowCount8.portrait {
  margin-right: 0;
  margin-left: 21px;
}
@media all and (max-width: 2600px) {
  div.myList.rtl .bottom .itemsContainer .items.rowCount8.portrait {
    margin-right: 0;
    margin-left: 13px;
  }
  div.myList.rtl .bottom .itemsContainer .items.rowCount8.portrait:nth-child(8n) {
    margin-left: 0;
  }
}
@media all and (max-width: 2500px) {
  div.myList.rtl .bottom .itemsContainer .items.rowCount8.portrait {
    margin-right: 0;
    margin-left: 11px;
  }
}
@media all and (max-width: 2200px) {
  div.myList.rtl .bottom .itemsContainer .items.rowCount8.portrait {
    margin-right: 0;
    margin-left: 10px;
  }
}
@media all and (max-width: 1980px) {
  div.myList.rtl .bottom .itemsContainer .items.rowCount8.portrait {
    margin-right: 0;
    margin-left: 9px;
  }
}
@media all and (max-width: 1768px) {
  div.myList.rtl .bottom .itemsContainer .items.rowCount8.portrait {
    margin-right: 0;
    margin-left: 8px;
  }
}
@media all and (max-width: 1600px) {
  div.myList.rtl .bottom .itemsContainer .items.rowCount8.portrait {
    margin-right: 0;
    margin-left: 7px;
  }
}
@media all and (max-width: 1400px) {
  div.myList.rtl .bottom .itemsContainer .items.rowCount8.portrait {
    margin-right: 0;
    margin-left: 6px;
  }
}
@media all and (max-width: 1200px) {
  div.myList.rtl .bottom .itemsContainer .items.rowCount8.portrait {
    margin-right: 0;
    margin-left: 6px;
  }
}
@media all and (min-width: 981px) {
  div.myList.rtl .bottom .itemsContainer .items.rowCount8.portrait:hover div.showCardContainer .square {
    transform: translateX(-60px) translateY(-60px) scale(1.2);
  }
  div.myList.rtl .bottom .itemsContainer .items.rowCount8.portrait:hover:nth-child(8n) div.showCardContainer div.square {
    transform: translateX(60px) translateY(-60px) scale(1.2);
  }
  div.myList.rtl .bottom .itemsContainer .items.rowCount8.portrait:hover:last-child div.showCardContainer div.square {
    transform: translateX(60px) translateY(-60px) scale(1.2);
  }
}
@media all and (max-width: 980px) {
  div.myList.rtl .bottom .itemsContainer .items.rowCount8.portrait {
    margin-right: 0;
    margin-left: 6px;
  }
  div.myList.rtl .bottom .itemsContainer .items.rowCount8.portrait:nth-child(5n) {
    margin-right: 0;
    margin-left: 6px;
  }
  div.myList.rtl .bottom .itemsContainer .items.rowCount8.portrait:nth-child(4n) {
    margin-left: 0;
  }
  div.myList.rtl .bottom .itemsContainer .items.rowCount8.portrait:nth-child(6n) {
    margin-right: 0;
    margin-left: 6px;
  }
}
@media all and (max-width: 768px) {
  div.myList.rtl .bottom .itemsContainer .items.rowCount8.portrait:nth-child(4n) {
    margin-right: 0;
    margin-left: 6px;
  }
  div.myList.rtl .bottom .itemsContainer .items.rowCount8.portrait:nth-child(3n) {
    margin-left: 0;
  }
}
@media all and (max-width: 640px) {
  div.myList.rtl .bottom .itemsContainer .items.rowCount8.portrait:nth-child(3n) {
    margin-right: 0;
    margin-left: 6px;
  }
  div.myList.rtl .bottom .itemsContainer .items.rowCount8.portrait:nth-child(2n) {
    margin-left: 0;
  }
}
@media all and (max-width: 980px) {
  div.myList.rtl .bottom .itemsContainer .items.portrait {
    margin-right: 0;
    margin-left: 13px;
  }
  div.myList.rtl .bottom .itemsContainer .items.portrait:nth-child(4n) {
    margin-left: 0;
  }
  div.myList.rtl .bottom .itemsContainer .items.portrait:nth-child(5n) {
    margin-right: 0;
    margin-left: 13px;
  }
}
@media all and (max-width: 768px) {
  div.myList.rtl .bottom .itemsContainer .items.portrait:nth-child(4n) {
    margin-left: 0;
  }
  div.myList.rtl .bottom .itemsContainer .items.portrait:nth-child(5n) {
    margin-right: 0;
    margin-left: 13px;
  }
}
@media all and (max-width: 640px) {
  div.myList.rtl .bottom .itemsContainer .items.portrait:nth-child(4n) {
    margin-right: 0;
    margin-left: 13px;
  }
  div.myList.rtl .bottom .itemsContainer .items.portrait:nth-child(3n) {
    margin-left: 0;
  }
}
@media all and (max-width: 480px) {
  div.myList.rtl .bottom .itemsContainer .items.portrait:nth-child(3n) {
    margin-right: 0;
    margin-left: 13px;
  }
  div.myList.rtl .bottom .itemsContainer .items.portrait:nth-child(2n) {
    margin-left: 0;
  }
}
@media all and (max-width: 980px) {
  div.myList.rtl .bottom .itemsContainer .items.landscape {
    margin-right: 0;
    margin-left: 13px;
  }
  div.myList.rtl .bottom .itemsContainer .items.landscape:nth-child(3n) {
    margin-left: 0;
  }
}
@media all and (max-width: 768px) {
  div.myList.rtl .bottom .itemsContainer .items.landscape:nth-child(2n) {
    margin-left: 0;
  }
  div.myList.rtl .bottom .itemsContainer .items.landscape:nth-child(3n) {
    margin-right: 0;
    margin-left: 13px;
  }
}
@media all and (max-width: 640px) {
  div.myList.rtl .bottom .itemsContainer .items.landscape:nth-child(3n) {
    margin-right: 0;
    margin-left: 13px;
  }
  div.myList.rtl .bottom .itemsContainer .items.landscape:nth-child(2n) {
    margin-left: 0;
  }
}
@media all and (max-width: 480px) {
  div.myList.rtl .bottom .itemsContainer .items.landscape:nth-child(3n) {
    margin-right: 0;
    margin-left: 13px;
  }
  div.myList.rtl .bottom .itemsContainer .items.landscape:nth-child(2n) {
    margin-left: 0;
  }
}

div.privacyPolicy {
  padding: 130px 0;
  direction: ltr !important;
  text-align: left !important;
}

div.termsAndConditions {
  padding: 130px 0;
  direction: ltr !important;
  text-align: left !important;
}

div.contactUs {
  padding: 130px 0;
}

div.contactSupport {
  padding: 130px 0;
}
div.contactSupport h1.heading {
  font-size: 28px;
  margin-bottom: 30px;
  color: var(--text-color);
  text-align: center;
}
@media all and (max-width: 640px) {
  div.contactSupport h1.heading {
    font-size: 28px;
  }
}
div.contactSupport div.formContainer {
  width: 50%;
  margin: 0 auto;
}
@media all and (max-width: 980px) {
  div.contactSupport div.formContainer {
    width: 100%;
  }
}
div.contactSupport div.formContainer form div.full {
  margin-bottom: 20px;
}
div.contactSupport div.formContainer form div.full input,
div.contactSupport div.formContainer form div.full select {
  color: var(--text-color) !important;
  outline: none;
  border-radius: 5px;
  font-size: 14px;
  background: transparent;
  text-align: left;
  padding: 20px 15px;
  border: 1px solid var(--inner-text-color);
  caret-color: var(--text-color);
  display: block;
  width: 100%;
  color: var(--text-color);
}
div.contactSupport div.formContainer form div.full input option,
div.contactSupport div.formContainer form div.full select option {
  color: #000 !important;
}
div.contactSupport div.formContainer form div.full input:focus,
div.contactSupport div.formContainer form div.full select:focus {
  border: 1px solid var(--text-color);
}
@media all and (max-width: 640px) {
  div.contactSupport div.formContainer form div.full input,
  div.contactSupport div.formContainer form div.full select {
    padding: 16px 15px;
  }
}
div.contactSupport div.formContainer form div.full label {
  color: var(--inner-text-color);
  font-size: 16px;
  display: inline-block;
  margin-bottom: 10px;
}
div.contactSupport div.formContainer form div.full.message {
  display: flex;
  flex-direction: column;
}
div.contactSupport div.formContainer form div.full.message textarea {
  height: 150px;
  outline: none;
  border-radius: 5px;
  font-size: 14px;
  background: transparent;
  caret-color: var(--text-color);
  color: var(--text-color);
  padding: 20px 15px;
}
div.contactSupport div.formContainer form div.full.message textarea:focus {
  border: 1px solid var(--text-color);
}
div.contactSupport div.formContainer form div.full div.buttonContainer {
  display: flex;
  padding: 18px 20px;
  border-radius: 4px;
  align-items: center;
  margin-bottom: 15px;
  cursor: pointer;
  transition: 0.2s ease-in-out;
  justify-content: center;
  position: relative;
  width: 20rem;
  margin: 30px auto 0;
}
@media all and (max-width: 640px) {
  div.contactSupport div.formContainer form div.full div.buttonContainer {
    width: 15rem;
  }
}
@media all and (max-width: 480px) {
  div.contactSupport div.formContainer form div.full div.buttonContainer {
    width: 10rem;
  }
}
div.contactSupport div.formContainer form div.full div.buttonContainer:hover div.background {
  transform: scale(1.0476190476, 1.0357142857);
}
div.contactSupport div.formContainer form div.full div.buttonContainer div.background {
  background: var(--text-hover);
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border-radius: 4px;
  transition: 0.1s ease-in-out;
  z-index: 1;
}
div.contactSupport div.formContainer form div.full div.buttonContainer button {
  background: transparent;
  font-size: 15px;
  color: var(--text-color);
  font-weight: 600;
  position: relative;
  z-index: 2;
  cursor: pointer;
}
div.contactSupport div.formContainer form div.full .captcha-container {
  display: flex;
  flex-direction: column;
}
div.contactSupport div.formContainer form div.full .react-tel-input .flag-dropdown {
  background-color: var(--bg-color) !important;
  cursor: pointer !important;
}
div.contactSupport div.formContainer form div.full .react-tel-input .country-list .search {
  padding: 10px 10px 6px 10px;
}
div.contactSupport div.formContainer form div.full .phone-input-responsive input.search-box {
  border: 1px solid var(--bg-color);
  caret-color: var(--bg-color);
}
@media (max-width: 1200px) {
  div.contactSupport div.formContainer form div.full .phone-input-responsive .form-control {
    padding: 0% 10% !important;
  }
}
@media (max-width: 980px) {
  div.contactSupport div.formContainer form div.full .phone-input-responsive .form-control {
    padding: 0% 7% !important;
  }
}
@media (max-width: 640px) {
  div.contactSupport div.formContainer form div.full .phone-input-responsive .form-control {
    padding: 0% 9% !important;
  }
}
@media (max-width: 480px) {
  div.contactSupport div.formContainer form div.full .phone-input-responsive .form-control {
    padding: 0% 13% !important;
  }
}
@media (max-width: 360px) {
  div.contactSupport div.formContainer form div.full .phone-input-responsive .form-control {
    padding: 0% 15% !important;
  }
}

div.aboutUs {
  padding: 130px 0;
  direction: ltr !important;
  text-align: left !important;
}

div.loading {
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  background-color: var(--bg-color);
  z-index: 99;
  display: flex;
  justify-content: center;
  align-items: center;
}
div.loading div.loadingOverlay {
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  background-color: var(--bg-color);
  z-index: 999;
  display: flex;
  justify-content: center;
  align-items: center;
}
div.loading div.loadingText {
  display: flex;
  align-items: flex-end;
  z-index: 999;
}
div.loading div.loadingText h1 {
  color: var(--inner-text-color);
  margin-right: 10px;
  font-size: 28px;
}
div.loading div.loadingText span {
  margin-right: 10px;
  color: var(--inner-text-color);
  animation: blinking 0.6s ease-in-out infinite;
}
div.loading div.loadingText span:nth-child(odd) {
  color: var(--text-hover);
}
div.loading div.loadingText span:nth-child(4) {
  animation: blinking 0.7s ease-in-out infinite;
}
div.loading div.loadingText span:last-child {
  margin-right: 0;
}
div.loading .loader {
  transform: rotateZ(45deg);
  perspective: 1000px;
  border-radius: 50%;
  width: 48px;
  height: 48px;
  color: #fff;
  z-index: 999;
}
div.loading .loader:before,
div.loading .loader:after {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: inherit;
  height: inherit;
  border-radius: 50%;
  transform: rotateX(70deg);
  animation: 1s spin linear infinite;
}
div.loading .loader:after {
  color: var(--text-hover);
  transform: rotateY(70deg);
  animation-delay: 0.4s;
}
@keyframes rotate {
  0% {
    transform: translate(-50%, -50%) rotateZ(0deg);
  }
  100% {
    transform: translate(-50%, -50%) rotateZ(360deg);
  }
}
@keyframes rotateccw {
  0% {
    transform: translate(-50%, -50%) rotate(0deg);
  }
  100% {
    transform: translate(-50%, -50%) rotate(-360deg);
  }
}
@keyframes spin {
  0%, 100% {
    box-shadow: 0.2em 0px 0 0px currentcolor;
  }
  12% {
    box-shadow: 0.2em 0.2em 0 0 currentcolor;
  }
  25% {
    box-shadow: 0 0.2em 0 0px currentcolor;
  }
  37% {
    box-shadow: -0.2em 0.2em 0 0 currentcolor;
  }
  50% {
    box-shadow: -0.2em 0 0 0 currentcolor;
  }
  62% {
    box-shadow: -0.2em -0.2em 0 0 currentcolor;
  }
  75% {
    box-shadow: 0px -0.2em 0 0 currentcolor;
  }
  87% {
    box-shadow: 0.2em -0.2em 0 0 currentcolor;
  }
}
@keyframes blinking {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

div.newsCard {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
  background-color: var(--bg-color);
  transition: 0.4s ease-in-out;
}
div.newsCard:hover {
  background-color: var(--secondary-bg-color);
}
@media all and (max-width: 980px) {
  div.newsCard {
    flex-direction: column;
  }
}
div.newsCard div.left {
  width: 15%;
}
@media all and (max-width: 1200px) {
  div.newsCard div.left {
    width: 50%;
  }
}
@media all and (max-width: 980px) {
  div.newsCard div.left {
    width: 100%;
    margin-bottom: 20px;
  }
}
div.newsCard div.left div.imageContainer {
  width: 100%;
}
div.newsCard div.left div.imageContainer img {
  width: 100%;
  display: block;
  border-radius: 8px;
}
div.newsCard div.right {
  width: 80%;
}
@media all and (max-width: 980px) {
  div.newsCard div.right {
    width: 100%;
  }
}
div.newsCard div.right h1.title {
  font-size: 22px;
  margin-bottom: 20px;
  color: var(--text-color);
}
@media all and (max-width: 1200px) {
  div.newsCard div.right h1.title {
    margin-bottom: 10px;
  }
}
@media all and (max-width: 640px) {
  div.newsCard div.right h1.title {
    font-size: 20px;
  }
}
div.newsCard div.right p {
  font-size: 16px;
  color: var(--inner-text-color);
  margin-bottom: 10px;
}
@media all and (max-width: 1200px) {
  div.newsCard div.right p {
    font-size: 14px;
    margin-bottom: 10px;
  }
}
@media all and (max-width: 640px) {
  div.newsCard div.right p {
    font-size: 13px;
  }
}
div.newsCard div.right div.dateSection {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}
@media all and (max-width: 1200px) {
  div.newsCard div.right div.dateSection {
    margin-bottom: 10px;
  }
}
div.newsCard div.right div.dateSection span.icon {
  width: 20px;
  display: inline-block;
  margin-right: 10px;
}
div.newsCard div.right div.dateSection span.icon img {
  width: 100%;
  display: block;
}
div.newsCard div.right div.dateSection span.date {
  font-size: 14px;
  color: var(--text-hover);
}
@media all and (max-width: 640px) {
  div.newsCard div.right div.dateSection span.date {
    font-size: 13px;
  }
}
div.newsCard div.right div.buttonContainer {
  display: flex;
  padding: 12px 20px;
  border-radius: 4px;
  align-items: center;
  margin-bottom: 15px;
  cursor: pointer;
  transition: 0.2s ease-in-out;
  justify-content: center;
  position: relative;
  width: 15rem;
  margin: 20px 0 0;
}
@media all and (max-width: 768px) {
  div.newsCard div.right div.buttonContainer {
    margin: 20px 0 0;
  }
}
@media all and (max-width: 640px) {
  div.newsCard div.right div.buttonContainer {
    width: 12rem;
  }
}
@media all and (max-width: 480px) {
  div.newsCard div.right div.buttonContainer {
    width: 15rem;
  }
}
div.newsCard div.right div.buttonContainer:hover div.background {
  transform: scale(1.0476190476, 1.0357142857);
}
div.newsCard div.right div.buttonContainer div.background {
  background: var(--text-hover);
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border-radius: 4px;
  transition: 0.1s ease-in-out;
  z-index: 1;
}
div.newsCard div.right div.buttonContainer button {
  background: transparent;
  font-size: 15px;
  color: var(--text-color);
  font-weight: 600;
  position: relative;
  z-index: 2;
  cursor: pointer;
}

div.latestNews {
  padding: 130px 0 80px;
  position: relative;
}
div.latestNews div.head {
  display: flex;
  justify-content: right;
}
@media all and (max-width: 640px) {
  div.latestNews div.head {
    padding-right: 20px;
  }
}
div.latestNews div.head div.logoContainer {
  width: 150px;
}
@media all and (max-width: 640px) {
  div.latestNews div.head div.logoContainer {
    width: 120px;
  }
}
div.latestNews div.head div.logoContainer img {
  display: block;
  width: 100%;
}
div.latestNews div.body h1.heading {
  font-size: 34px;
  color: var(--text-color);
  margin-bottom: 30px;
}
div.latestNews div.body div.gradientBg {
  background: url(./assets/Icons/gradient.svg) repeat-x center bottom;
  background-size: contain;
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 200px;
}
div.latestNews div.body div.gradientBg div.buttonContainer {
  text-align: center;
}
div.latestNews div.body div.gradientBg div.buttonContainer button.showMore {
  border: 1px solid var(--text-hover);
  background-color: transparent;
  color: var(--text-hover);
  padding: 6px 20px;
  font-size: 14px;
  border-radius: 6px;
  width: 15%;
  transition: 0.3s ease-in-out;
  margin: 140px auto 0;
  cursor: pointer;
}
@media all and (max-width: 1200px) {
  div.latestNews div.body div.gradientBg div.buttonContainer button.showMore {
    width: 20%;
  }
}
@media all and (max-width: 980px) {
  div.latestNews div.body div.gradientBg div.buttonContainer button.showMore {
    width: 25%;
  }
}
@media all and (max-width: 769px) {
  div.latestNews div.body div.gradientBg div.buttonContainer button.showMore {
    width: 30%;
  }
}
@media all and (max-width: 769px) {
  div.latestNews div.body div.gradientBg div.buttonContainer button.showMore {
    width: 30%;
  }
}
@media all and (max-width: 640px) {
  div.latestNews div.body div.gradientBg div.buttonContainer button.showMore {
    width: 35%;
  }
}
@media all and (max-width: 480px) {
  div.latestNews div.body div.gradientBg div.buttonContainer button.showMore {
    width: 45%;
  }
}
@media all and (max-width: 360px) {
  div.latestNews div.body div.gradientBg div.buttonContainer button.showMore {
    width: 50%;
  }
}
div.latestNews div.body div.gradientBg div.buttonContainer button.showMore svg {
  transition: 0.3s ease-in-out;
  margin-left: 6px;
}
div.latestNews div.body div.gradientBg div.buttonContainer button.showMore:hover {
  background-color: var(--text-hover);
  color: var(--text-color);
}

@media all and (max-width: 640px) {
  div.latestNews.rtl .head {
    padding-right: 0;
    padding-left: 20px;
  }
}
div.latestNews.rtl .body div.gradientBg {
  left: unset;
  right: 0;
}
div.latestNews.rtl .body div.gradientBg .buttonContainer .showMore svg {
  margin-left: 0;
  margin-right: 6px;
}

div.articleInfo {
  padding: 130px 0 80px;
}
div.articleInfo div.wrapper {
  width: 80%;
}
div.articleInfo div.wrapper h1.heading {
  font-size: 32px;
  color: var(--text-color);
}
@media all and (max-width: 640px) {
  div.articleInfo div.wrapper h1.heading {
    padding-left: 20px;
  }
}
div.articleInfo div.wrapper div.dateSection {
  display: flex;
  align-items: center;
  margin-top: 30px;
}
@media all and (max-width: 640px) {
  div.articleInfo div.wrapper div.dateSection {
    padding-left: 20px;
  }
}
div.articleInfo div.wrapper div.dateSection span.icon {
  width: 20px;
  display: block;
  margin-right: 10px;
}
div.articleInfo div.wrapper div.dateSection span.icon img {
  display: block;
  width: 100%;
}
div.articleInfo div.wrapper div.dateSection span.date {
  font-size: 12px;
  color: var(--text-hover);
}
div.articleInfo div.wrapper div.articleDetails {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  margin: 20px auto 0;
}
@media all and (max-width: 980px) {
  div.articleInfo div.wrapper div.articleDetails {
    width: 90%;
  }
}
@media all and (max-width: 768px) {
  div.articleInfo div.wrapper div.articleDetails {
    flex-direction: column;
  }
}
div.articleInfo div.wrapper div.articleDetails div.left {
  width: 100%;
  margin-bottom: 30px;
}
@media all and (max-width: 768px) {
  div.articleInfo div.wrapper div.articleDetails div.left {
    width: 100%;
    margin-bottom: 30px;
  }
}
div.articleInfo div.wrapper div.articleDetails div.left div.imageContainer {
  width: 100%;
  margin: 0 auto;
}
div.articleInfo div.wrapper div.articleDetails div.left div.imageContainer img {
  display: block;
  width: 100%;
  -o-object-fit: cover;
     object-fit: cover;
}
div.articleInfo div.wrapper div.articleDetails div.right {
  width: 100%;
}
@media all and (max-width: 768px) {
  div.articleInfo div.wrapper div.articleDetails div.right {
    width: 100%;
  }
}
div.articleInfo div.wrapper div.articleDetails div.right p {
  font-size: 15px;
  margin-bottom: 20px;
  color: var(--inner-text-color);
}
div.articleInfo div.wrapper div.articleDetails div.right p:last-child {
  margin-bottom: 0;
}
@media all and (max-width: 980px) {
  div.articleInfo div.wrapper div.articleDetails div.right p {
    font-size: 13px;
  }
}
div.articleInfo div.wrapper div.videoContainer {
  width: 80%;
  margin: 90px auto 0;
}
div.articleInfo div.wrapper div.videoContainer video {
  display: block;
  width: 100%;
}
@media all and (max-width: 980px) {
  div.articleInfo div.wrapper div.videoContainer {
    width: 90%;
  }
}
div.articleInfo div.wrapper div.buttonContainer {
  text-align: center;
  margin-top: 50px;
}
div.articleInfo div.wrapper div.buttonContainer button {
  background-color: transparent;
  border: 1px solid var(--primary-color);
  padding: 6px 10px;
  width: 15%;
  border-radius: 10px;
  color: var(--primary-color);
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
  transition: 0.4s ease-in-out;
}
@media all and (max-width: 980px) {
  div.articleInfo div.wrapper div.buttonContainer button {
    width: 20%;
    font-size: 14px;
  }
}
@media all and (max-width: 641px) {
  div.articleInfo div.wrapper div.buttonContainer button {
    width: 25%;
  }
}
@media all and (max-width: 480px) {
  div.articleInfo div.wrapper div.buttonContainer button {
    width: 35%;
    padding: 6px 15px;
  }
}
div.articleInfo div.wrapper div.buttonContainer button span {
  color: var(--primary-color);
  margin: 0 10px;
  transition: 0.4s ease-in-out;
}
div.articleInfo div.wrapper div.buttonContainer button svg {
  transition: 0.4s ease-in-out;
}
div.articleInfo div.wrapper div.buttonContainer button:hover {
  border-color: var(--icons-color);
}
div.articleInfo div.wrapper div.buttonContainer button:hover span {
  color: var(--icons-color);
}
div.articleInfo div.wrapper div.buttonContainer button:hover svg {
  fill: var(--icons-color);
}

@media all and (max-width: 640px) {
  div.articleInfo.rtl .wrapper .heading {
    padding-left: 0;
    padding-right: 20px;
  }
}
@media all and (max-width: 640px) {
  div.articleInfo.rtl .wrapper .dateSection {
    padding-left: 0;
    padding-right: 20px;
  }
}
div.articleInfo.rtl .wrapper .dateSection span.icon {
  margin-right: 0;
  margin-left: 10px;
}

div.landingScreen {
  padding: 100px 0 0;
}
div.landingScreen h1.heading {
  color: var(--text-color);
  margin-bottom: 10px;
  font-size: 45px;
  font-weight: 500;
}
@media all and (max-width: 1400px) {
  div.landingScreen h1.heading {
    font-size: 38px;
  }
}
@media all and (max-width: 1200px) {
  div.landingScreen h1.heading {
    font-size: 31px;
  }
}
@media all and (max-width: 768px) {
  div.landingScreen h1.heading {
    font-size: 27px;
  }
}
div.landingScreen p.description {
  color: var(--text-color);
  margin-bottom: 10px;
  font-size: 23px;
}
@media all and (max-width: 1400px) {
  div.landingScreen p.description {
    font-size: 19px;
  }
}
@media all and (max-width: 1200px) {
  div.landingScreen p.description {
    font-size: 17px;
  }
}
div.landingScreen div.buttonContainer {
  display: flex;
  padding: 12px 20px;
  border-radius: 4px;
  align-items: center;
  margin-bottom: 15px;
  cursor: pointer;
  transition: 0.2s ease-in-out;
  justify-content: center;
  position: relative;
  width: 15rem;
}
@media all and (max-width: 768px) {
  div.landingScreen div.buttonContainer {
    margin: 20px 0 0;
    width: 13rem;
  }
}
@media all and (max-width: 640px) {
  div.landingScreen div.buttonContainer {
    width: 12rem;
    margin: 0 auto;
  }
}
@media all and (max-width: 480px) {
  div.landingScreen div.buttonContainer {
    width: 15rem;
  }
}
div.landingScreen div.buttonContainer:hover div.background {
  transform: scale(1.0476190476, 1.0357142857);
}
div.landingScreen div.buttonContainer div.background {
  background: var(--text-hover);
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border-radius: 4px;
  transition: 0.1s ease-in-out;
  z-index: 1;
}
div.landingScreen div.buttonContainer button {
  background: transparent;
  font-size: 15px;
  color: var(--text-color);
  font-weight: 600;
  position: relative;
  z-index: 2;
  cursor: pointer;
}
@media all and (max-width: 768px) {
  div.landingScreen div.buttonContainer button {
    font-size: 13px;
  }
}
div.landingScreen div.spotlight {
  display: flex;
  align-items: center;
  background-color: var(--black-color);
  height: 500px;
}
@media all and (max-width: 640px) {
  div.landingScreen div.spotlight {
    flex-direction: column-reverse;
    height: 269px;
  }
}
div.landingScreen div.spotlight.rightType {
  position: relative;
}
div.landingScreen div.spotlight.rightType div.left {
  width: 45%;
  position: absolute;
  left: 7%;
  z-index: 3;
  height: 100%;
  padding-top: 120px;
  background: linear-gradient(to right, #000 80%, transparent 100%);
}
@media all and (max-width: 640px) {
  div.landingScreen div.spotlight.rightType div.left {
    background: linear-gradient(to top, #000 96%, transparent 100%);
    left: unset;
    width: 100%;
    text-align: center;
    padding-top: 40px;
    bottom: -44%;
  }
}
div.landingScreen div.spotlight.rightType div.right {
  width: 60%;
  position: absolute;
  right: 0;
  height: 100%;
  z-index: 1;
}
@media all and (max-width: 640px) {
  div.landingScreen div.spotlight.rightType div.right {
    left: unset;
    top: 0;
    width: 100%;
    height: 52%;
  }
}
div.landingScreen div.spotlight.rightType div.right div.imageContainer {
  width: 100%;
  height: 100%;
}
div.landingScreen div.spotlight.rightType div.right div.imageContainer img {
  display: block;
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
}
div.landingScreen div.watchNowSection {
  display: flex;
  color: var(--black-color);
  align-items: center;
  background-color: var(--black-color);
  position: relative;
  height: 500px;
}
@media all and (max-width: 640px) {
  div.landingScreen div.watchNowSection {
    flex-direction: column;
    height: 269px;
    margin-top: 99px;
  }
}
div.landingScreen div.watchNowSection div.left {
  width: 60%;
  height: 100%;
  position: absolute;
}
@media all and (max-width: 640px) {
  div.landingScreen div.watchNowSection div.left {
    left: unset;
    top: 0;
    width: 100%;
    height: 55%;
  }
}
div.landingScreen div.watchNowSection div.left div.imageContainer {
  height: 100%;
  width: 100%;
}
div.landingScreen div.watchNowSection div.left div.imageContainer img {
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
  display: block;
  width: 100%;
}
div.landingScreen div.watchNowSection div.right {
  width: 50%;
  position: absolute;
  right: 0;
  z-index: 3;
  height: 100%;
  padding-top: 120px;
  background: linear-gradient(to left, #000 87%, transparent 100%);
}
@media all and (max-width: 640px) {
  div.landingScreen div.watchNowSection div.right {
    background: linear-gradient(to top, #000 96%, transparent 100%);
    right: unset;
    width: 100%;
    text-align: center;
    padding-top: 40px;
    bottom: -47%;
  }
}
div.landingScreen div.watchNowSection div.right h1,
div.landingScreen div.watchNowSection div.right p,
div.landingScreen div.watchNowSection div.right div.buttonContainer {
  margin-left: 80px;
}
@media all and (max-width: 640px) {
  div.landingScreen div.watchNowSection div.right h1,
  div.landingScreen div.watchNowSection div.right p,
  div.landingScreen div.watchNowSection div.right div.buttonContainer {
    margin-left: 0;
  }
}
@media all and (max-width: 640px) {
  div.landingScreen div.watchNowSection div.right div.buttonContainer {
    margin: 0 auto;
  }
}
div.landingScreen div.watchNowSection div.right p {
  font-size: 20px;
}
@media all and (max-width: 1400px) {
  div.landingScreen div.watchNowSection div.right p {
    font-size: 19px;
  }
}
@media all and (max-width: 1200px) {
  div.landingScreen div.watchNowSection div.right p {
    font-size: 17px;
  }
}
div.landingScreen div.other.rightType {
  display: flex;
  align-items: center;
  background-color: var(--black-color);
  position: relative;
  height: 500px;
}
@media all and (max-width: 640px) {
  div.landingScreen div.other.rightType {
    flex-direction: column-reverse;
    height: 269px;
  }
}
div.landingScreen div.other.rightType div.left {
  width: 45%;
  position: absolute;
  left: 7%;
  z-index: 3;
  height: 100%;
  padding-top: 120px;
  background: linear-gradient(to right, #000 80%, transparent 100%);
}
@media all and (max-width: 640px) {
  div.landingScreen div.other.rightType div.left {
    background: linear-gradient(to top, #000 96%, transparent 100%);
    left: unset;
    width: 100%;
    text-align: center;
    padding-top: 40px;
    bottom: -91%;
  }
}
div.landingScreen div.other.rightType div.right {
  width: 60%;
  position: absolute;
  right: 0;
  height: 100%;
  z-index: 1;
}
@media all and (max-width: 640px) {
  div.landingScreen div.other.rightType div.right {
    left: unset;
    top: 129px;
    width: 100%;
    height: 52%;
  }
}
div.landingScreen div.other.rightType div.right div.imageContainer {
  width: 100%;
  height: 100%;
}
div.landingScreen div.other.rightType div.right div.imageContainer img {
  display: block;
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
}
div.landingScreen div.device {
  display: flex;
  background: var(--text-color);
  padding-top: 30px;
  justify-content: space-between;
  padding-bottom: 100px;
}
@media all and (max-width: 640px) {
  div.landingScreen div.device {
    margin-top: 245px;
    flex-direction: column;
  }
}
div.landingScreen div.device div.left {
  width: 40%;
  padding-left: 60px;
}
@media all and (max-width: 768px) {
  div.landingScreen div.device div.left {
    padding-left: 30px;
  }
}
@media all and (max-width: 640px) {
  div.landingScreen div.device div.left {
    width: 100%;
    margin-bottom: 30px;
  }
}
div.landingScreen div.device div.left h1.heading {
  color: var(--black-color);
}
div.landingScreen div.device div.left p.description {
  color: var(--inner-text-color);
}
div.landingScreen div.device div.right {
  width: 40%;
}
@media all and (max-width: 1980px) {
  div.landingScreen div.device div.right {
    width: 43%;
  }
}
@media all and (max-width: 1600px) {
  div.landingScreen div.device div.right {
    width: 46%;
  }
}
@media all and (max-width: 1400px) {
  div.landingScreen div.device div.right {
    width: 55%;
  }
}
@media all and (max-width: 1200px) {
  div.landingScreen div.device div.right {
    width: 46%;
  }
}
@media all and (max-width: 980px) {
  div.landingScreen div.device div.right {
    width: 55%;
  }
}
@media all and (max-width: 768px) {
  div.landingScreen div.device div.right {
    width: 56%;
  }
}
@media all and (max-width: 640px) {
  div.landingScreen div.device div.right {
    width: 91%;
    margin: 0 auto;
  }
}
div.landingScreen div.device div.right ul.devices {
  display: flex;
  flex-wrap: wrap;
}
div.landingScreen div.device div.right ul.devices li {
  cursor: pointer;
  width: 17rem;
  height: 10rem;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 6px;
  margin-bottom: 6px;
  background: rgb(54, 124, 160);
  background: linear-gradient(90deg, rgb(54, 124, 160) 0%, rgb(14, 64, 115) 35%, rgb(0, 41, 97) 100%);
  transition: 0.2s ease-in-out;
}
div.landingScreen div.device div.right ul.devices li:nth-child(3n) {
  margin-right: 0;
}
@media all and (max-width: 2200px) {
  div.landingScreen div.device div.right ul.devices li {
    width: 15rem;
  }
}
@media all and (max-width: 1980px) {
  div.landingScreen div.device div.right ul.devices li {
    width: 13rem;
    height: 9rem;
  }
}
@media all and (max-width: 1200px) {
  div.landingScreen div.device div.right ul.devices li:nth-child(3n) {
    margin-right: 6px;
  }
  div.landingScreen div.device div.right ul.devices li:nth-child(2n) {
    margin-right: 0;
  }
}
@media all and (max-width: 980px) {
  div.landingScreen div.device div.right ul.devices li {
    width: 12rem;
    height: 7rem;
  }
}
@media all and (max-width: 768px) {
  div.landingScreen div.device div.right ul.devices li {
    width: 10rem;
  }
}
@media all and (max-width: 640px) {
  div.landingScreen div.device div.right ul.devices li {
    width: 13rem;
  }
}
@media all and (max-width: 480px) {
  div.landingScreen div.device div.right ul.devices li {
    width: 10rem;
  }
}
@media all and (max-width: 360px) {
  div.landingScreen div.device div.right ul.devices li {
    width: 100%;
    margin-right: 0;
  }
  div.landingScreen div.device div.right ul.devices li:nth-child(3n) {
    margin-right: 0;
  }
}
div.landingScreen div.device div.right ul.devices li:hover {
  box-shadow: 0px 1px 6px 4px #333;
}
div.landingScreen div.device div.right ul.devices li span {
  color: var(--text-color);
  font-weight: 700;
  font-size: 30px;
}
@media all and (max-width: 980px) {
  div.landingScreen div.device div.right ul.devices li span {
    font-size: 24px;
  }
}

div.landingScreen.rtl .spotlight.rightType div.left {
  left: 0;
  right: 7%;
  background: linear-gradient(to left, #000 80%, transparent 100%);
}
@media all and (max-width: 640px) {
  div.landingScreen.rtl .spotlight.rightType div.left {
    left: 0;
    right: unset;
  }
}
div.landingScreen.rtl .spotlight.rightType div.right {
  right: unset;
  left: 0;
}
@media all and (max-width: 640px) {
  div.landingScreen.rtl .spotlight.rightType div.right {
    left: 0;
    right: unset;
  }
}
@media all and (max-width: 640px) {
  div.landingScreen.rtl .watchNowSection div.left {
    left: 0;
    right: unset;
  }
}
div.landingScreen.rtl .watchNowSection div.right {
  right: unset;
  left: 0;
  background: linear-gradient(to right, #000 87%, transparent 100%);
}
@media all and (max-width: 640px) {
  div.landingScreen.rtl .watchNowSection div.right {
    right: unset;
    left: unset;
  }
}
div.landingScreen.rtl .watchNowSection div.right h1,
div.landingScreen.rtl .watchNowSection div.right p,
div.landingScreen.rtl .watchNowSection div.right div.buttonContainer {
  margin-left: unset;
  margin-right: 80px;
}
@media all and (max-width: 640px) {
  div.landingScreen.rtl .watchNowSection div.right h1,
  div.landingScreen.rtl .watchNowSection div.right p,
  div.landingScreen.rtl .watchNowSection div.right div.buttonContainer {
    margin-right: 0;
  }
}
div.landingScreen.rtl div.other.rightType div.left {
  left: 0;
  right: 7%;
  background: linear-gradient(to left, #000 80%, transparent 100%);
}
@media all and (max-width: 640px) {
  div.landingScreen.rtl div.other.rightType div.left {
    left: 0;
    right: unset;
  }
}
div.landingScreen.rtl div.other.rightType div.right {
  right: unset;
  left: 0;
}
@media all and (max-width: 640px) {
  div.landingScreen.rtl div.other.rightType div.right {
    left: 0;
    right: unset;
  }
}
div.landingScreen.rtl .device div.left {
  padding-left: 0;
  padding-right: 60px;
}
@media all and (max-width: 768px) {
  div.landingScreen.rtl .device div.left {
    padding-left: 0;
    padding-right: 30px;
  }
}
div.landingScreen.rtl .device .right ul.devices li {
  margin-right: 0;
  margin-left: 6px;
}
div.landingScreen.rtl .device .right ul.devices li:nth-child(3n) {
  margin-right: 0;
  margin-left: 0;
}
@media all and (max-width: 1200px) {
  div.landingScreen.rtl .device .right ul.devices li:nth-child(3n) {
    margin-right: 0;
    margin-left: 6px;
  }
  div.landingScreen.rtl .device .right ul.devices li:nth-child(2n) {
    margin-right: 0;
    margin-left: 0;
  }
}
@media all and (max-width: 360px) {
  div.landingScreen.rtl .device .right ul.devices li {
    margin-right: 0;
    margin-left: 0;
  }
  div.landingScreen.rtl .device .right ul.devices li:nth-child(3n) {
    margin-right: 0;
    margin-left: 0;
  }
}

div.notFound {
  padding: 60px 0;
}
@media all and (max-width: 768px) {
  div.notFound {
    padding: 80px 0;
  }
}
@media all and (max-width: 480px) {
  div.notFound {
    padding: 100px 0;
  }
}
div.notFound div.imageContainer {
  width: 40%;
  margin: 0 auto;
}
@media all and (max-width: 768px) {
  div.notFound div.imageContainer {
    width: 50%;
  }
}
@media all and (max-width: 480px) {
  div.notFound div.imageContainer {
    width: 60%;
  }
}
div.notFound div.imageContainer img {
  width: 100%;
  display: block;
}
div.notFound div.content {
  text-align: center;
}
div.notFound div.content h1 {
  font-size: 80px;
  color: var(--text-hover);
  line-height: 0.8em;
}
@media all and (max-width: 768px) {
  div.notFound div.content h1 {
    font-size: 70px;
  }
}
@media all and (max-width: 480px) {
  div.notFound div.content h1 {
    font-size: 60px;
  }
}
div.notFound div.content p {
  font-size: 25px;
  color: var(--inner-text-color);
}
@media all and (max-width: 768px) {
  div.notFound div.content p {
    font-size: 20px;
  }
}
@media all and (max-width: 480px) {
  div.notFound div.content p {
    font-size: 16px;
  }
}
@media all and (max-width: 360px) {
  div.notFound div.content p {
    font-size: 14px;
  }
}
div.notFound div.content div.buttonContainer {
  display: flex;
  padding: 18px 20px;
  border-radius: 4px;
  align-items: center;
  margin-bottom: 15px;
  cursor: pointer;
  transition: 0.2s ease-in-out;
  justify-content: center;
  position: relative;
  width: 20rem;
  margin: 30px auto 0;
}
@media all and (max-width: 640px) {
  div.notFound div.content div.buttonContainer {
    width: 15rem;
  }
}
@media all and (max-width: 480px) {
  div.notFound div.content div.buttonContainer {
    width: 10rem;
  }
}
div.notFound div.content div.buttonContainer:hover div.background {
  transform: scale(1.0476190476, 1.0357142857);
}
div.notFound div.content div.buttonContainer div.background {
  background: var(--text-hover);
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border-radius: 4px;
  transition: 0.1s ease-in-out;
  z-index: 1;
}
div.notFound div.content div.buttonContainer button {
  background: transparent;
  font-size: 15px;
  color: var(--text-color);
  font-weight: 600;
  position: relative;
  z-index: 2;
  cursor: pointer;
}

div.tvAppIndex {
  padding: 130px 0 60px;
}
div.tvAppIndex div.tvActivationReverse div.topHeader {
  padding: 20px 10px;
  border-bottom: 1px solid var(--border-color);
}
div.tvAppIndex div.tvActivationReverse div.topHeader h1 {
  font-size: 22px;
  color: var(--text-color);
}
div.tvAppIndex div.tvActivationReverse div.bottomContainer {
  padding: 50px 0 20px;
}
div.tvAppIndex div.tvActivationReverse div.bottomContainer h2 {
  color: var(--text-color);
  font-size: 18px;
  margin-bottom: 20px;
}
div.tvAppIndex div.tvActivationReverse div.bottomContainer p {
  font-size: 16px;
  color: var(--text-color);
  margin-bottom: 15px;
}
div.tvAppIndex div.tvActivationReverse div.bottomContainer div.inputContainer form label {
  color: var(--text-color);
  font-size: 15px;
  display: block;
  margin-bottom: 10px;
}
div.tvAppIndex div.tvActivationReverse div.bottomContainer div.inputContainer form input {
  display: block;
  outline: none;
  border: none;
  padding: 15px 15px;
  border-radius: 5px;
  margin-bottom: 10px;
  width: 30%;
}
@media all and (max-width: 640px) {
  div.tvAppIndex div.tvActivationReverse div.bottomContainer div.inputContainer form input {
    width: 100%;
  }
}
div.tvAppIndex div.tvActivationReverse div.bottomContainer div.inputContainer form button {
  width: 8rem;
  padding: 6px 1px 9px;
  border-radius: 2px;
  background-color: var(--secondary-color);
  color: var(--white-color);
  margin-right: 10px;
  cursor: pointer;
  transition: 0.3s ease-in-out;
  font-size: 13px;
  margin-bottom: 10px;
}
div.tvAppIndex div.tvActivationReverse div.bottomContainer div.inputContainer form button:hover {
  background-color: var(--button-hover);
}
div.tvAppIndex div.tvActivationReverse div.bottomContainer div.inputContainer p.terms {
  font-size: 15px;
}
div.tvAppIndex div.tvActivationReverse div.bottomContainer div.inputContainer p.terms span {
  color: rgb(63, 131, 242);
  cursor: pointer;
}
div.tvAppIndex div.tvActivationReverse div.bottomContainer div.inputContainer p.terms span a {
  color: rgb(63, 131, 242);
  cursor: pointer;
}
div.tvAppIndex div.linkTvApp {
  padding: 130px 0;
}
div.tvAppIndex div.linkTvApp div.authCodeContainer {
  width: 35%;
  margin: 0 auto;
  background-color: #22293b;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  text-align: center;
  border-radius: 10px;
  padding: 50px 60px;
}
@media all and (max-width: 1200px) {
  div.tvAppIndex div.linkTvApp div.authCodeContainer {
    width: 40%;
  }
}
@media all and (max-width: 980px) {
  div.tvAppIndex div.linkTvApp div.authCodeContainer {
    width: 50%;
  }
}
@media all and (max-width: 768px) {
  div.tvAppIndex div.linkTvApp div.authCodeContainer {
    width: 60%;
  }
}
@media all and (max-width: 640px) {
  div.tvAppIndex div.linkTvApp div.authCodeContainer {
    width: 75%;
  }
}
@media all and (max-width: 480px) {
  div.tvAppIndex div.linkTvApp div.authCodeContainer {
    width: 90%;
    padding: 40px 40px;
  }
}
div.tvAppIndex div.linkTvApp div.authCodeContainer h1.heading {
  color: var(--text-color);
  font-size: 19px;
  font-weight: 600;
  margin-bottom: 8px;
}
div.tvAppIndex div.linkTvApp div.authCodeContainer p {
  color: var(--white-color);
  font-size: 14px;
  margin-bottom: 30px;
}
div.tvAppIndex div.linkTvApp div.authCodeContainer span.loading {
  display: block;
  color: var(--success-color);
  margin-bottom: 20px;
}
div.tvAppIndex div.linkTvApp div.authCodeContainer div.authCodeField {
  border: 1px solid var(--success-color);
  padding: 10px;
  font-size: 24px;
  color: var(--success-color);
  letter-spacing: 10px;
  font-weight: 700;
  margin-bottom: 15px;
}
div.tvAppIndex div.linkTvApp div.authCodeContainer div.authCodeField.expired {
  border: 1px solid var(--error-color);
  color: var(--error-color);
}
div.tvAppIndex div.linkTvApp div.authCodeContainer div.codeExpired span {
  color: #fff;
}
div.tvAppIndex div.linkTvApp div.authCodeContainer div.codeExpired div.regenerateButton {
  margin-top: 10px;
}
div.tvAppIndex div.linkTvApp div.authCodeContainer div.codeExpired div.regenerateButton button {
  background-image: linear-gradient(0deg, #2175d9, #2789ff);
  width: 12rem;
  border-radius: 4px;
  color: #fff;
  padding: 10px;
  font-size: 13px;
  line-height: 1.2;
  letter-spacing: 0.29px;
  font-weight: 500;
  cursor: pointer;
}
div.tvAppIndex div.linkTvApp div.authCodeContainer span.timer {
  color: #fff;
  font-size: 16px;
}

div.tvAppIndex.rtl .tvActivationReverse .bottomContainer .inputContainer form button {
  margin-right: 0;
  margin-left: 10px;
}

div.livePlayer {
  width: 100%;
  padding: 120px 0 20px;
}
div.livePlayer div.videoContainer {
  width: 100%;
  position: relative;
}
div.livePlayer div.videoContainer video {
  display: block;
  width: 100%;
}
@media all and (min-width: 768px) {
  div.livePlayer div.videoContainer:hover div.bottom div.details {
    transform: translateY(0);
  }
}
div.livePlayer div.videoContainer div.bottom {
  width: 90%;
  position: absolute;
  bottom: 40px;
  left: 50%;
  transform: translateX(-50%);
  height: 200px;
  overflow: hidden;
}
@media all and (max-width: 768px) {
  div.livePlayer div.videoContainer div.bottom {
    position: relative;
    bottom: unset;
    left: unset;
    transform: unset;
    width: 100%;
    height: auto;
  }
}
div.livePlayer div.videoContainer div.bottom div.details {
  display: flex;
  justify-content: space-between;
  height: 100%;
  transform: translateY(200px);
  transition: 0.3s ease-in-out;
  background-color: rgba(41, 36, 35, 0.6);
  padding: 20px;
  border-radius: 8px;
}
@media all and (max-width: 768px) {
  div.livePlayer div.videoContainer div.bottom div.details {
    flex-direction: column;
    transform: unset;
  }
}
div.livePlayer div.videoContainer div.bottom div.details h1.heading {
  font-size: 28px;
  color: var(--white-color);
  margin-bottom: 10px;
}
@media all and (max-width: 768px) {
  div.livePlayer div.videoContainer div.bottom div.details h1.heading {
    font-size: 22px;
  }
}
div.livePlayer div.videoContainer div.bottom div.details h1.title {
  font-size: 20px;
  color: var(--text-hover);
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
div.livePlayer div.videoContainer div.bottom div.details span.time {
  font-size: 14px;
  color: var(--inner-text-color);
  display: inline-block;
  margin-bottom: 10px;
}
div.livePlayer div.videoContainer div.bottom div.details p.description {
  color: var(--white-color);
  font-size: 14px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2; /* number of lines to show */
  line-clamp: 2;
  -webkit-box-orient: vertical;
}
div.livePlayer div.videoContainer div.bottom div.details div.left {
  width: 48%;
  height: 100%;
}
@media all and (max-width: 768px) {
  div.livePlayer div.videoContainer div.bottom div.details div.left {
    width: 100%;
    margin-bottom: 20px;
  }
}
div.livePlayer div.videoContainer div.bottom div.details div.right {
  width: 48%;
  height: 100%;
}
@media all and (max-width: 768px) {
  div.livePlayer div.videoContainer div.bottom div.details div.right {
    width: 100%;
  }
}
div.livePlayer div.commentsContainer {
  width: 70%;
  margin: 0 auto;
  padding: 60px 0;
}
@media all and (max-width: 1200px) {
  div.livePlayer div.commentsContainer {
    width: 80%;
  }
}
@media all and (max-width: 768px) {
  div.livePlayer div.commentsContainer {
    width: 90%;
  }
}
@media all and (max-width: 640px) {
  div.livePlayer div.commentsContainer {
    width: 100%;
  }
}
div.livePlayer div.commentsContainer div.top div.head {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid var(--border-color);
  padding-bottom: 10px;
}
div.livePlayer div.commentsContainer div.top div.head span.commentsCount {
  color: var(--white-color);
  font-size: 22px;
}
@media all and (max-width: 640px) {
  div.livePlayer div.commentsContainer div.top div.head span.commentsCount {
    font-size: 16px;
  }
}
@media all and (max-width: 360px) {
  div.livePlayer div.commentsContainer div.top div.head span.commentsCount {
    font-size: 10px;
  }
}
div.livePlayer div.commentsContainer div.top div.head div.sortContainer select {
  background-color: transparent;
  border: 1px solid var(--border-color);
  padding: 10px 15px;
  color: var(--white-color);
  font-size: 16px;
  border-radius: 6px;
}
div.livePlayer div.commentsContainer div.top div.head div.sortContainer select option {
  color: var(--black-color);
}
div.livePlayer div.commentsContainer div.top div.textContainer {
  display: flex;
  padding: 40px 0 20px;
  border-bottom: 1px solid var(--border-color);
}
div.livePlayer div.commentsContainer div.top div.textContainer div.imageContainer {
  margin-right: 20px;
  width: 45px;
  height: 45px;
}
div.livePlayer div.commentsContainer div.top div.textContainer div.imageContainer img {
  height: 100%;
  width: 100%;
  border-radius: 50%;
}
div.livePlayer div.commentsContainer div.top div.textContainer div.imageContainer span.nameAvatar {
  width: 45px;
  height: 45px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--white-color);
  background-color: green;
  border-radius: 50%;
}
div.livePlayer div.commentsContainer div.top div.textContainer div.inputContainer {
  width: 90%;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}
div.livePlayer div.commentsContainer div.top div.textContainer div.inputContainer textarea {
  display: block;
  width: 100%;
  height: 45px;
  padding: 10px 15px;
  border-radius: 5px;
  margin-bottom: 20px;
  border: 1px solid #e1e8ed;
  resize: vertical;
}
div.livePlayer div.commentsContainer div.top div.textContainer div.inputContainer textarea:focus-visible {
  border: 3px solid var(--button-color) !important;
  outline: none;
}
div.livePlayer div.commentsContainer div.top div.textContainer div.inputContainer div.upload-featue {
  display: flex;
  justify-content: space-between;
  width: 100%;
}
div.livePlayer div.commentsContainer div.top div.textContainer div.inputContainer div.upload-featue div.selectedImage {
  width: 20%;
  height: 30%;
  margin-bottom: 20px;
}
div.livePlayer div.commentsContainer div.top div.textContainer div.inputContainer div.upload-featue div.selectedImage img {
  max-width: 50%;
  border-radius: 5px;
  height: auto;
}
div.livePlayer div.commentsContainer div.top div.textContainer div.inputContainer div.upload-featue div.selectedImage button.removeButton {
  position: absolute;
  background: white;
  border: none;
  border-radius: 50%;
  cursor: pointer;
  font-size: 20px;
  color: var(--button-color);
  width: 20px;
  height: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  transition: background-color 0.3s ease;
}
div.livePlayer div.commentsContainer div.top div.textContainer div.inputContainer div.upload-featue div.selectedImage button.removeButton:hover {
  background-color: #f2f2f2;
}
div.livePlayer div.commentsContainer div.top div.textContainer div.inputContainer div.upload-featue div.selectedImage button.removeButton svg {
  width: 15px;
  height: 15px;
  fill: var(--button-color);
}
div.livePlayer div.commentsContainer div.top div.textContainer div.inputContainer div.upload-featue div.actions {
  display: flex;
  justify-content: flex-end;
  align-items: flex-start;
}
div.livePlayer div.commentsContainer div.top div.textContainer div.inputContainer div.upload-featue div.actions .buttonContainer {
  display: flex;
  align-items: center;
  margin-right: 10px;
}
div.livePlayer div.commentsContainer div.top div.textContainer div.inputContainer div.upload-featue div.actions .buttonContainer label.customFileUpload {
  color: white;
  margin-right: 10px;
  font-weight: 600;
  padding: 7px 20px;
  border-radius: 6px;
  font-size: 14px;
  cursor: pointer;
  display: flex;
  align-items: center;
  border: 1px solid #1da1f2;
  transition: 0.4s ease-in-out;
}
div.livePlayer div.commentsContainer div.top div.textContainer div.inputContainer div.upload-featue div.actions .buttonContainer label.customFileUpload:hover {
  background-color: transparent;
  color: var(--white-color);
}
div.livePlayer div.commentsContainer div.top div.textContainer div.inputContainer div.upload-featue div.actions .buttonContainer input[type=file] {
  display: none;
}
div.livePlayer div.commentsContainer div.top div.textContainer div.inputContainer div.upload-featue div.actions .buttonContainer button.giphyButton {
  background: none;
  color: white;
  font-weight: 600;
  padding: 7px 20px;
  border-radius: 6px;
  font-size: 14px;
  cursor: pointer;
  display: flex;
  align-items: center;
  border: 1px solid #1da1f2;
  transition: 0.4s ease-in-out;
}
div.livePlayer div.commentsContainer div.top div.textContainer div.inputContainer div.upload-featue div.actions .buttonContainer button.giphyButton:hover {
  background-color: transparent;
  color: var(--white-color);
}
@media all and (max-width: 480px) {
  div.livePlayer div.commentsContainer div.top div.textContainer div.inputContainer div.upload-featue div.actions .buttonContainer button.giphyButton {
    margin-left: -10px;
  }
}
div.livePlayer div.commentsContainer div.top div.textContainer div.inputContainer div.upload-featue div.actions button.submit {
  background-color: var(--button-color);
  color: var(--white-color);
  font-weight: 600;
  padding: 10px 20px;
  border-radius: 6px;
  font-size: 14px;
  cursor: pointer;
  border: 1px solid var(--button-color);
  transition: 0.4s ease-in-out;
}
div.livePlayer div.commentsContainer div.top div.textContainer div.inputContainer div.upload-featue div.actions button.submit:hover {
  background-color: transparent;
  color: var(--white-color);
}
@media all and (max-width: 768px) {
  div.livePlayer div.commentsContainer div.top div.textContainer div.inputContainer div.upload-featue div.actions button.submit {
    margin-left: -5px;
  }
}
@media (max-width: 1200px) {
  div.livePlayer div.commentsContainer div.top div.textContainer div.inputContainer {
    width: 90%;
  }
  div.livePlayer div.commentsContainer div.top div.textContainer div.inputContainer textarea {
    height: 40px;
    padding: 8px 12px;
  }
  div.livePlayer div.commentsContainer div.top div.textContainer div.inputContainer div.upload-featue {
    flex-direction: column;
  }
  div.livePlayer div.commentsContainer div.top div.textContainer div.inputContainer div.upload-featue .selectedImage {
    width: 100%;
  }
  div.livePlayer div.commentsContainer div.top div.textContainer div.inputContainer div.upload-featue .actions {
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
  }
  div.livePlayer div.commentsContainer div.top div.textContainer div.inputContainer div.upload-featue .actions .buttonContainer {
    margin-bottom: 10px;
    width: 100%;
  }
  div.livePlayer div.commentsContainer div.top div.textContainer div.inputContainer div.upload-featue .actions .buttonContainer label.customFileUpload,
  div.livePlayer div.commentsContainer div.top div.textContainer div.inputContainer div.upload-featue .actions .buttonContainer button.giphyButton {
    width: 50%;
    margin-bottom: 10px;
    text-align: center;
    justify-content: center;
  }
  div.livePlayer div.commentsContainer div.top div.textContainer div.inputContainer div.upload-featue .actions button.submit {
    width: 100%;
    text-align: center;
  }
}
@media (max-width: 768px) {
  div.livePlayer div.commentsContainer div.top div.textContainer div.inputContainer {
    width: 90%;
  }
  div.livePlayer div.commentsContainer div.top div.textContainer div.inputContainer textarea {
    height: 35px;
    padding: 6px 10px;
  }
  div.livePlayer div.commentsContainer div.top div.textContainer div.inputContainer div.upload-featue {
    flex-direction: column;
  }
  div.livePlayer div.commentsContainer div.top div.textContainer div.inputContainer div.upload-featue .selectedImage {
    width: 100%;
  }
  div.livePlayer div.commentsContainer div.top div.textContainer div.inputContainer div.upload-featue .actions {
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
  }
  div.livePlayer div.commentsContainer div.top div.textContainer div.inputContainer div.upload-featue .actions .buttonContainer {
    flex-direction: column;
    width: 100%;
    text-align: center;
  }
  div.livePlayer div.commentsContainer div.top div.textContainer div.inputContainer div.upload-featue .actions .buttonContainer label.customFileUpload,
  div.livePlayer div.commentsContainer div.top div.textContainer div.inputContainer div.upload-featue .actions .buttonContainer button.giphyButton {
    width: 100%;
    margin-right: 10px;
    margin-bottom: 10px;
    text-align: center;
    justify-content: center;
  }
  div.livePlayer div.commentsContainer div.top div.textContainer div.inputContainer div.upload-featue .actions button.submit {
    width: 100%;
    text-align: center;
  }
}
@media (max-width: 480px) {
  div.livePlayer div.commentsContainer div.top div.textContainer div.inputContainer textarea {
    height: 30px;
    padding: 4px 8px;
  }
  div.livePlayer div.commentsContainer div.top div.textContainer div.inputContainer div.upload-featue {
    flex-direction: column;
  }
  div.livePlayer div.commentsContainer div.top div.textContainer div.inputContainer div.upload-featue .selectedImage {
    width: 100%;
  }
  div.livePlayer div.commentsContainer div.top div.textContainer div.inputContainer div.upload-featue .actions {
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
  }
  div.livePlayer div.commentsContainer div.top div.textContainer div.inputContainer div.upload-featue .actions .buttonContainer {
    flex-direction: column;
    width: 100%;
  }
  div.livePlayer div.commentsContainer div.top div.textContainer div.inputContainer div.upload-featue .actions .buttonContainer label.customFileUpload,
  div.livePlayer div.commentsContainer div.top div.textContainer div.inputContainer div.upload-featue .actions .buttonContainer button.giphyButton {
    width: 100%;
    margin-right: 0;
    margin-bottom: 5px;
    text-align: center;
    justify-content: center;
  }
  div.livePlayer div.commentsContainer div.top div.textContainer div.inputContainer div.upload-featue .actions button.submit {
    width: 100%;
    text-align: center;
  }
}
div.livePlayer div.commentsContainer div.bottom div.comments div.eachComment {
  border-bottom: 1px solid var(--border-color);
}
div.livePlayer div.commentsContainer div.bottom div.comments div.eachComment:last-child {
  border: none;
}
div.livePlayer div.commentsContainer div.bottom div.comments div.eachComment div.containers {
  padding: 40px 0 25px;
}
div.livePlayer div.commentsContainer div.bottom div.comments div.eachComment div.containers div.replyMessagesContainer {
  padding: 40px 0 0;
}
div.livePlayer div.commentsContainer div.bottom div.comments div.eachComment div.containers div.replyMessagesContainer div.containers {
  padding: 0 0 0;
  display: flex;
  border: none;
}
div.livePlayer div.commentsContainer div.bottom div.comments div.eachComment div.containers div.commentItem {
  display: flex;
}
div.livePlayer div.commentsContainer div.bottom div.comments div.eachComment div.containers div.commentItem:last-child {
  border: none;
}
div.livePlayer div.commentsContainer div.bottom div.comments div.eachComment div.containers div.commentItem div.left {
  width: 45px;
  margin-right: 20px;
}
div.livePlayer div.commentsContainer div.bottom div.comments div.eachComment div.containers div.commentItem div.left div.profileImageContainer {
  width: 100%;
  height: 45px;
}
@media all and (max-width: 640px) {
  div.livePlayer div.commentsContainer div.bottom div.comments div.eachComment div.containers div.commentItem div.left div.profileImageContainer {
    height: 41px;
  }
}
div.livePlayer div.commentsContainer div.bottom div.comments div.eachComment div.containers div.commentItem div.left div.profileImageContainer img {
  width: 100%;
  display: block;
  height: 100%;
  border-radius: 50%;
}
div.livePlayer div.commentsContainer div.bottom div.comments div.eachComment div.containers div.commentItem div.left span.nameAvatar {
  width: 100%;
  height: 45px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--white-color);
  background-color: green;
  border-radius: 50%;
}
div.livePlayer div.commentsContainer div.bottom div.comments div.eachComment div.containers div.commentItem div.right {
  width: 90%;
}
@media all and (max-width: 640px) {
  div.livePlayer div.commentsContainer div.bottom div.comments div.eachComment div.containers div.commentItem div.right {
    width: 96%;
  }
}
div.livePlayer div.commentsContainer div.bottom div.comments div.eachComment div.containers div.commentItem div.right h1.name {
  font-size: 16px;
  color: var(--white-color);
}
div.livePlayer div.commentsContainer div.bottom div.comments div.eachComment div.containers div.commentItem div.right span.time {
  font-size: 13px;
  color: var(--inner-text-color);
  font-weight: 600;
  display: inline-block;
  margin-bottom: 20px;
}
div.livePlayer div.commentsContainer div.bottom div.comments div.eachComment div.containers div.commentItem div.right p.comment {
  font-size: 16px;
  color: var(--white-color);
  margin-bottom: 20px;
}
div.livePlayer div.commentsContainer div.bottom div.comments div.eachComment div.containers div.commentItem div.right div.comment-image-container {
  margin-top: 10px;
  margin-bottom: 30px;
  width: 20%;
  height: auto;
}
div.livePlayer div.commentsContainer div.bottom div.comments div.eachComment div.containers div.commentItem div.right div.comment-image-container img {
  max-width: 50%;
  height: auto;
  border-radius: 8px;
}
div.livePlayer div.commentsContainer div.bottom div.comments div.eachComment div.containers div.commentItem div.right div.actions {
  display: flex;
}
div.livePlayer div.commentsContainer div.bottom div.comments div.eachComment div.containers div.commentItem div.right div.actions span.item {
  display: flex;
  align-items: center;
  margin-right: 20px;
  font-size: 11px;
  color: var(--inner-text-color);
  transition: 0.4s ease-in-out;
  cursor: pointer;
}
div.livePlayer div.commentsContainer div.bottom div.comments div.eachComment div.containers div.commentItem div.right div.actions span.item:hover {
  opacity: 0.6;
}
div.livePlayer div.commentsContainer div.bottom div.comments div.eachComment div.containers div.commentItem div.right div.actions span.item:last-child {
  margin-right: 0;
}
div.livePlayer div.commentsContainer div.bottom div.comments div.eachComment div.containers div.commentItem div.right div.actions span.item span svg {
  width: 15px !important;
  height: 15px !important;
  color: rgb(190, 190, 187);
  fill: rgb(190, 190, 187);
  margin-right: 5px;
}
div.livePlayer div.customModal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}
div.livePlayer div.customModal .modalContent {
  background: #fff;
  border-radius: 10px;
  max-height: 80%;
  max-width: 90%;
  overflow-y: auto;
  padding: 20px;
  position: relative;
}
div.livePlayer div.customModal .modalContent .closeButton {
  position: absolute;
  top: -3px;
  right: 5px;
  background: none;
  border: none;
  font-size: 24px;
  cursor: pointer;
}

div.podcastModal {
  direction: ltr !important;
  unicode-bidi: embed !important;
}
div.podcastModal .rhap_container {
  background-color: #303030 !important;
  border-radius: 5px;
}
div.podcastModal .rhap_time {
  color: #a29b9b !important;
}
div.podcastModal div.overlayModal {
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.7);
  z-index: 99;
  display: flex;
  justify-content: center;
  align-items: center;
}
div.podcastModal div.closeIconContainer {
  position: fixed;
  top: 20px;
  inset-inline-end: 30px;
  z-index: 999;
  cursor: pointer;
}
div.podcastModal div.closeIconContainer svg {
  width: 20px !important;
  height: 20px !important;
}
div.podcastModal div.contents {
  z-index: 999;
  text-align: center;
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  background-color: var(--bg-color);
  width: 40%;
  margin: 0 auto;
  border-radius: 6px;
  display: flex;
  flex-direction: column;
}
@media all and (max-width: 1400px) {
  div.podcastModal div.contents {
    width: 30%;
  }
}
@media all and (max-width: 1200px) {
  div.podcastModal div.contents {
    width: 60%;
  }
}
@media all and (max-width: 768px) {
  div.podcastModal div.contents {
    width: 75%;
  }
}
@media all and (max-width: 480px) {
  div.podcastModal div.contents {
    width: 90%;
  }
}
div.podcastModal div.contents div.podcastContainer {
  display: flex;
  align-items: center;
  flex-direction: column;
}
div.podcastModal div.contents div.podcastContainer div.left {
  width: 100%;
}
@media all and (max-width: 980px) {
  div.podcastModal div.contents div.podcastContainer div.left {
    width: 100%;
    margin-inline-end: 0;
  }
}
div.podcastModal div.contents div.podcastContainer div.left div.imageContainer {
  width: 100%;
}
div.podcastModal div.contents div.podcastContainer div.left div.imageContainer img {
  width: 100%;
  display: block;
  border-radius: 5px;
}
div.podcastModal div.contents div.podcastContainer div.right {
  width: 100%;
}
@media all and (max-width: 980px) {
  div.podcastModal div.contents div.podcastContainer div.right {
    width: 100%;
  }
}
div.podcastModal div.contents div.podcastContainer div.right h1.title {
  color: var(--text-color);
  font-size: 20px;
  margin-bottom: 10px;
}
@media all and (max-width: 640px) {
  div.podcastModal div.contents div.podcastContainer div.right div.audioContainer {
    width: 100%;
  }
}
div.podcastModal div.contents div.podcastContainer div.right div.audioContainer audio {
  width: 100%;
  display: block;
}

div.affliatePage {
  padding: 30px 0 0;
  background-color: #fff;
}
div.affliatePage div.top {
  padding: 30px 0;
}
div.affliatePage div.top div.logoSection {
  width: 70%;
  margin: 0 auto;
}
@media all and (min-width: 980px) {
  div.affliatePage div.top div.logoSection {
    width: 30%;
  }
}
div.affliatePage div.top div.logoSection h1.image {
  width: 100%;
}
div.affliatePage div.top div.logoSection h1.image img {
  width: 100%;
  display: block;
}
div.affliatePage div.top h1 {
  font-size: 24px;
  text-align: center;
}
div.affliatePage div.bottom {
  background-color: #022230;
  padding: 136px 20px;
  border-top-left-radius: 91%;
  border-top-right-radius: 0;
}
div.affliatePage div.bottom div.buttons {
  margin-left: 40px;
}
div.affliatePage div.bottom div.buttons div.buttonContainer {
  text-align: center;
}
div.affliatePage div.bottom div.buttons div.buttonContainer button {
  padding: 10px 20px;
  font-size: 15px;
  border-radius: 30px;
  width: 10rem;
  color: #fff;
}
div.affliatePage div.bottom div.buttons div.buttonContainer button.useApp {
  background-color: #45c4af;
  margin-bottom: 20px;
  box-shadow: rgba(69, 196, 175, 0.35) 0px 5px 15px;
}
div.affliatePage div.bottom div.buttons div.buttonContainer button.web {
  box-shadow: rgba(0, 0, 0, 0.03) 0px 16px 24px;
  background-color: rgba(255, 255, 255, 0.3);
  -webkit-backdrop-filter: blur(8px);
          backdrop-filter: blur(8px);
}

div.affliatePage.rtl .bottom .buttons {
  margin-left: 0;
  margin-right: 40px;
}

div.openAppContainer {
  padding: 15px;
  position: fixed;
  bottom: 10px;
  width: 100%;
  background-color: var(--button-color);
  z-index: 100000000000000000;
  border-radius: 10px;
  width: 98%;
  margin: 0 auto;
  transform: translateX(-50%);
  left: 50%;
}
div.openAppContainer h1 {
  font-size: 14px;
  color: #fff;
  margin-bottom: 12px;
}
div.openAppContainer p {
  font-size: 12px;
  color: #fff;
  margin-bottom: 10px;
}
div.openAppContainer div.buttons {
  display: flex;
  justify-content: space-between;
}
div.openAppContainer div.buttons button.notNow {
  border: 1px solid #fff;
  background: transparent;
  color: #fff;
  padding: 6px 12px;
  border-radius: 30px;
  font-weight: 700;
  font-size: 12px;
}
div.openAppContainer div.buttons button.openApp {
  border: 1px solid #fff;
  background: #fff;
  color: #000;
  padding: 6px 12px;
  border-radius: 30px;
  font-weight: 700;
  font-size: 12px;
}

div.scheduleDetailsModal div.overlayModal {
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  background-color: rgba(32, 32, 38, 0.8);
  z-index: 9999;
  display: flex;
  justify-content: center;
  align-items: center;
}
div.scheduleDetailsModal div.contents {
  z-index: 99999;
  position: fixed;
  top: 50%;
  left: 50%;
  background-color: var(--bg-color);
  width: 50%;
  border-radius: 15px;
  display: flex;
  flex-direction: column;
  transform: translate(-50%, -50%);
}
div.scheduleDetailsModal div.contents div.top {
  position: relative;
  width: 100%;
  height: 269px;
  background-size: cover;
  background-position: top;
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
  background-repeat: no-repeat;
}
div.scheduleDetailsModal div.contents div.top div.closeIcon {
  position: absolute;
  top: 10px;
  inset-inline-end: 10px;
  filter: drop-shadow(0 0 2px rgba(0, 0, 0, 0.5));
  cursor: pointer;
}
div.scheduleDetailsModal div.contents div.top div.closeIcon svg {
  color: var(--white-color);
  width: 20px !important;
  height: 20px !important;
}
div.scheduleDetailsModal div.contents div.bottom {
  background-color: var(--secondary-bg-color);
  padding: 40px 30px;
  border-bottom-left-radius: 15px;
  border-bottom-right-radius: 15px;
  height: 300px;
  overflow-y: scroll;
}
div.scheduleDetailsModal div.contents div.bottom::-webkit-scrollbar {
  display: block;
}
div.scheduleDetailsModal div.contents div.bottom::-webkit-scrollbar {
  width: 5px;
  border-radius: 30px;
}
div.scheduleDetailsModal div.contents div.bottom::-webkit-scrollbar-thumb {
  background-color: var(--bg-color);
  border-radius: 30px;
}
div.scheduleDetailsModal div.contents div.bottom::-webkit-scrollbar-track {
  background-color: var(--grey-color);
}
div.scheduleDetailsModal div.contents div.bottom div.channelLogo {
  width: 80px;
  height: 80px;
  background-color: rgba(150, 153, 163, 0.16);
  border-radius: 6px;
  margin-bottom: 10px;
}
div.scheduleDetailsModal div.contents div.bottom div.channelLogo div.logoContainer {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
div.scheduleDetailsModal div.contents div.bottom div.channelLogo div.logoContainer img {
  display: block;
  width: 40px;
  height: 100%;
  -o-object-fit: contain;
     object-fit: contain;
}
div.scheduleDetailsModal div.contents div.bottom div.metaData {
  color: var(--text-color);
}
div.scheduleDetailsModal div.contents div.bottom div.metaData h1.title {
  font-size: 30px;
  margin-bottom: 10px;
}
div.scheduleDetailsModal div.contents div.bottom div.metaData span.startTime, div.scheduleDetailsModal div.contents div.bottom div.metaData span.endTime {
  color: var(--inner-text-color);
  font-weight: 700;
  font-size: 15px;
  margin-bottom: 10px;
  display: inline-block;
}
div.scheduleDetailsModal div.contents div.bottom div.metaData div.additionalInfo span.caption {
  background-color: var(--white-color);
  color: var(--black-color);
  font-size: 8px;
  padding: 2px 4px;
  font-weight: 700;
  border-radius: 5px;
  outline: none;
  border: none;
  margin-inline-end: 15px;
}
div.scheduleDetailsModal div.contents div.bottom div.metaData div.additionalInfo span.rating {
  background-color: hsla(226, 7%, 61%, 0.32);
  border-radius: 3px;
  color: var(--white-color);
  font-size: 12px;
  padding: 1px 8px;
}
div.scheduleDetailsModal div.contents div.bottom div.metaData p.description {
  margin-top: 10px;
  font-size: 15px;
}

div.liveChannelsContainer {
  background-color: var(--black-color);
  position: relative;
  direction: ltr !important;
  text-align: left !important;
}
div.liveChannelsContainer div.topContainer {
  display: flex;
  justify-content: space-between;
  position: relative;
  max-height: 580px;
}
@media all and (max-width: 1200px) {
  div.liveChannelsContainer div.topContainer {
    max-height: 508px;
  }
}
@media all and (max-width: 980px) {
  div.liveChannelsContainer div.topContainer {
    max-height: 785px;
  }
}
div.liveChannelsContainer div.topContainer div.wrapper {
  display: flex;
  justify-content: space-between;
  padding-top: 100px;
}
@media all and (max-width: 980px) {
  div.liveChannelsContainer div.topContainer div.wrapper {
    flex-direction: column-reverse;
  }
}
div.liveChannelsContainer div.topContainer div.bgImage {
  padding: 200px 0;
  width: 100%;
  background-size: 100%;
  background-repeat: no-repeat;
  background-position: center;
  height: 100%;
  max-height: 800px;
  position: absolute;
  z-index: 3;
  bottom: 0;
  filter: brightness(0.1);
}
@media all and (max-width: 1200px) {
  div.liveChannelsContainer div.topContainer div.bgImage {
    padding: 200px 0 150px;
  }
}
div.liveChannelsContainer div.topContainer div.gradient {
  position: absolute;
  left: 0;
  right: 0;
  bottom: -20px;
  z-index: 4;
  height: 500px;
  width: 100%;
  background: linear-gradient(180deg, rgba(0, 0, 0, 0.01), #000 94.15%);
}
@media all and (max-width: 980px) {
  div.liveChannelsContainer div.topContainer div.gradient {
    height: 465px;
    bottom: 162px;
  }
}
@media all and (max-width: 768px) {
  div.liveChannelsContainer div.topContainer div.gradient {
    bottom: 211px;
  }
}
@media all and (max-width: 640px) {
  div.liveChannelsContainer div.topContainer div.gradient {
    bottom: 284px;
  }
}
div.liveChannelsContainer div.topContainer div.bannerContainer {
  position: relative;
  margin-bottom: 60px;
  width: 55%;
  margin-inline-end: 30px;
}
@media all and (max-width: 980px) {
  div.liveChannelsContainer div.topContainer div.bannerContainer {
    width: 100%;
  }
}
div.liveChannelsContainer div.topContainer div.bannerContainer div.info {
  color: var(--text-color);
  position: relative;
  z-index: 9;
}
div.liveChannelsContainer div.topContainer div.bannerContainer div.info div.liveLabel {
  background-color: #f52d2d;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 3px 3px;
  width: 4.5rem;
  border-radius: 3px;
  margin-bottom: 10px;
}
div.liveChannelsContainer div.topContainer div.bannerContainer div.info div.liveLabel span {
  font-weight: 600;
  font-size: 11px;
}
div.liveChannelsContainer div.topContainer div.bannerContainer div.info div.liveLabel svg {
  width: 16px !important;
  height: 16px !important;
  margin-inline-end: 6px;
}
div.liveChannelsContainer div.topContainer div.bannerContainer div.info div.channelLogo {
  width: 80px;
  height: 80px;
  margin-bottom: 10px;
}
div.liveChannelsContainer div.topContainer div.bannerContainer div.info div.channelLogo img {
  display: block;
  width: 100%;
  height: 100%;
  -o-object-fit: contain;
     object-fit: contain;
}
div.liveChannelsContainer div.topContainer div.bannerContainer div.info h1.title {
  font-size: 30px;
  margin-bottom: 15px;
}
div.liveChannelsContainer div.topContainer div.bannerContainer div.info div.additionalInfo {
  display: flex;
  align-items: center;
  margin-bottom: 15px;
}
div.liveChannelsContainer div.topContainer div.bannerContainer div.info div.additionalInfo h6.timeLeft {
  color: var(--white-color);
  font-size: 15px;
  margin-inline-end: 15px;
}
div.liveChannelsContainer div.topContainer div.bannerContainer div.info div.additionalInfo span.caption {
  background-color: var(--white-color);
  color: var(--black-color);
  font-size: 10px;
  padding: 2px 4px;
  font-weight: 700;
  border-radius: 3px;
  outline: none;
  border: none;
  margin-inline-end: 15px;
}
div.liveChannelsContainer div.topContainer div.bannerContainer div.info div.additionalInfo span.rating {
  background-color: hsla(226, 7%, 61%, 0.32);
  border-radius: 4px;
  color: var(--white-color);
  color: var(--text-color);
  font-size: 12px;
  font-weight: 700;
  padding: 1px 8px;
}
div.liveChannelsContainer div.topContainer div.bannerContainer div.info p.description {
  max-width: 100%;
  font-size: 15px;
}
div.liveChannelsContainer div.topContainer div.bannerContainer div.info p.description span.readMore,
div.liveChannelsContainer div.topContainer div.bannerContainer div.info p.description span.readLess {
  color: var(--button-color);
  margin-inline-start: 6px;
  cursor: pointer;
}
div.liveChannelsContainer div.topContainer div.bannerContainer div.info p.description span.readMore:hover,
div.liveChannelsContainer div.topContainer div.bannerContainer div.info p.description span.readLess:hover {
  text-decoration: underline;
}
div.liveChannelsContainer div.topContainer div.videoContainer {
  inset-inline-end: 40px;
  z-index: 9;
  width: 70%;
  top: 90px;
  border-radius: 15px;
}
@media all and (max-width: 1600px) {
  div.liveChannelsContainer div.topContainer div.videoContainer {
    width: 70%;
  }
}
@media all and (max-width: 1400px) {
  div.liveChannelsContainer div.topContainer div.videoContainer {
    width: 60%;
  }
}
@media all and (max-width: 1200px) {
  div.liveChannelsContainer div.topContainer div.videoContainer {
    width: 60%;
  }
}
@media all and (max-width: 980px) {
  div.liveChannelsContainer div.topContainer div.videoContainer {
    margin-bottom: 20px;
    width: 100%;
  }
}
div.liveChannelsContainer div.topContainer div.videoContainer div.video-js {
  height: 408px;
  border-radius: 15px;
}
@media all and (max-width: 1200px) {
  div.liveChannelsContainer div.topContainer div.videoContainer div.video-js {
    height: 242px;
  }
}
@media all and (max-width: 980px) {
  div.liveChannelsContainer div.topContainer div.videoContainer div.video-js {
    height: 300px;
  }
}
@media all and (max-width: 480px) {
  div.liveChannelsContainer div.topContainer div.videoContainer div.video-js {
    height: 200px;
  }
}
div.liveChannelsContainer div.topContainer div.videoContainer video {
  border-radius: 15px;
}
div.liveChannelsContainer div.topContainer div.videoContainer div.videoImageContainer {
  width: 100%;
  height: 100%;
  background: var(--bg-color);
}
div.liveChannelsContainer div.topContainer div.videoContainer div.videoImageContainer div.bottomGradient {
  position: absolute;
  bottom: 0;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  z-index: 1;
  height: 92%;
  background-position: top;
  background-size: cover;
  background-repeat: no-repeat;
  background: linear-gradient(78.83deg, rgb(16, 20, 31) 0%, rgba(16, 20, 31, 0) 61.46%), linear-gradient(360deg, rgb(16, 20, 31) 0%, rgba(16, 20, 31, 0) 15.22%, rgba(16, 20, 31, 0.4) 100%);
}
div.liveChannelsContainer div.topContainer div.videoContainer div.videoImageContainer div.banner-image {
  height: 100%;
}
div.liveChannelsContainer div.topContainer div.videoContainer div.videoImageContainer div.banner-image img {
  width: 100%;
  height: 100%;
}
div.liveChannelsContainer div.banner-text {
  height: 75px;
  background-color: var(--bg-color);
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 6px;
  margin: 40px 5px 0px;
}
@media all and (max-width: 1200px) {
  div.liveChannelsContainer div.banner-text {
    margin: 20px 5px 0px;
  }
}
@media all and (max-width: 980px) {
  div.liveChannelsContainer div.banner-text {
    margin: 5px;
  }
}
div.liveChannelsContainer div.banner-text h2 {
  font-size: 20px;
  color: var(--text-color);
  z-index: 9;
  font-weight: 600;
}
@media all and (max-width: 640px) {
  div.liveChannelsContainer div.banner-text h2 {
    font-size: 16px;
    margin-inline-start: 15px;
  }
}
@media all and (max-width: 360px) {
  div.liveChannelsContainer div.banner-text h2 {
    font-size: 13px;
  }
}
div.liveChannelsContainer div.banner-text button {
  display: flex;
  padding: 18px 20px;
  border-radius: 4px;
  align-items: center;
  font-size: 18px;
  font-weight: 700;
  cursor: pointer;
  margin-inline-start: 2%;
  transition: 0.2s ease-in-out;
  justify-content: center;
  position: relative;
  width: 10rem;
  background-color: var(--button-color);
  color: var(--text-color);
  height: 3rem;
  z-index: 9;
}
@media all and (max-width: 640px) {
  div.liveChannelsContainer div.banner-text button {
    margin-inline-end: 3%;
  }
}
div.liveChannelsContainer div.banner-text button:hover {
  transform: scale(1.0476190476, 1.0357142857);
  background-color: var(--button-hover);
}
div.liveChannelsContainer div.wrapper {
  overflow: hidden;
}
div.liveChannelsContainer div.wrapper div.categories {
  margin-bottom: 30px;
  overflow: scroll;
  -ms-overflow-style: none;
  /* IE and Edge */
  scrollbar-width: none;
  /* Firefox */
}
div.liveChannelsContainer div.wrapper div.categories::-webkit-scrollbar {
  display: none;
}
div.liveChannelsContainer div.wrapper div.categories div.buttons {
  width: 1200px;
}
div.liveChannelsContainer div.wrapper div.categories div.buttons button {
  background-color: var(--black-color);
  padding: 5px 10px;
  color: var(--white-color);
  margin-inline-end: 10px;
  font-weight: 600;
  font-size: 13px;
  border-radius: 30px;
  cursor: pointer;
}
div.liveChannelsContainer div.wrapper div.categories div.buttons button.active {
  background-color: var(--white-color);
  color: var(--black-color);
}
div.liveChannelsContainer div.wrapper div.categories div.buttons button:last-child {
  margin-inline-end: 0;
}
div.liveChannelsContainer div.wrapper div.scheduleTime {
  width: 90%;
  margin: 0 0 6px auto;
  display: flex;
  align-items: center;
  position: relative;
}
div.liveChannelsContainer div.wrapper div.scheduleTime div.time {
  border-inline-start: 2px solid var(--border-color);
  padding-inline-start: 10px;
  width: 100%;
}
div.liveChannelsContainer div.wrapper div.scheduleTime div.time span {
  color: var(--inner-text-color);
  font-size: 10px;
  font-weight: 700;
}
div.liveChannelsContainer div.wrapper div.scheduleContainer {
  position: relative;
}
div.liveChannelsContainer div.wrapper div.scheduleContainer div.rightGradient {
  position: absolute;
  top: 0;
  bottom: 0;
  inset-inline-end: 0;
  width: 10rem;
  z-index: 9;
  background: linear-gradient(95deg, rgba(0, 0, 0, 0), #000 101.15%);
}
div.liveChannelsContainer div.wrapper div.scheduleContainer div.bottomGradient {
  position: absolute;
  bottom: 0;
  inset-inline-end: 0;
  width: 10rem;
  z-index: 9;
  background: linear-gradient(180deg, rgba(0, 0, 0, 0.01), #000 62.15%);
}
div.liveChannelsContainer div.wrapper div.scheduleContainer div.channel {
  display: flex;
  align-items: center;
  width: 3000px;
  height: 75px;
  margin-bottom: 4px;
}
div.liveChannelsContainer div.wrapper div.scheduleContainer div.channel.showTimeline div.right div.scheduledItem.active div.progress div.currentTime {
  position: absolute;
  top: -26px;
  inset-inline-end: 0;
  background-color: #f52d2d;
  display: flex !important;
  align-items: center;
  justify-content: center;
  padding: 3px 3px;
  width: 5rem;
  border-radius: 3px;
  color: var(--white-color);
}
div.liveChannelsContainer div.wrapper div.scheduleContainer div.channel.showTimeline div.right div.scheduledItem.active div.progress div.currentTime span {
  font-weight: 600;
  font-size: 11px;
}
div.liveChannelsContainer div.wrapper div.scheduleContainer div.channel.showTimeline div.right div.scheduledItem.active div.progress div.currentTime div.icon {
  line-height: 0;
}
div.liveChannelsContainer div.wrapper div.scheduleContainer div.channel.showTimeline div.right div.scheduledItem.active div.progress div.currentTime div.icon svg {
  width: 16px !important;
  height: 16px !important;
  margin-inline-end: 6px;
}
div.liveChannelsContainer div.wrapper div.scheduleContainer div.channel div.left {
  width: 5%;
  margin-inline-end: 4px;
  height: 100%;
}
div.liveChannelsContainer div.wrapper div.scheduleContainer div.channel div.left div.currentPlayingCard {
  width: 100%;
  height: 100%;
}
div.liveChannelsContainer div.wrapper div.scheduleContainer div.channel div.left div.currentPlayingCard div.imageContainer {
  width: 100%;
  height: 100%;
}
div.liveChannelsContainer div.wrapper div.scheduleContainer div.channel div.left div.currentPlayingCard div.imageContainer img {
  display: block;
  width: 100%;
  border-radius: 4px;
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
}
div.liveChannelsContainer div.wrapper div.scheduleContainer div.channel div.right {
  display: flex;
  align-items: center;
  width: 95%;
  height: 100%;
  position: relative;
}
div.liveChannelsContainer div.wrapper div.scheduleContainer div.channel div.right div.scheduledItem {
  background-color: rgb(25, 25, 25);
  padding: 25px 20px;
  border-radius: 4px;
  margin-inline-end: 4px;
  height: 100%;
  align-items: center;
  position: relative;
  cursor: pointer;
}
div.liveChannelsContainer div.wrapper div.scheduleContainer div.channel div.right div.scheduledItem.active {
  border: 2px solid var(--text-hover);
}
div.liveChannelsContainer div.wrapper div.scheduleContainer div.channel div.right div.scheduledItem.active div.progress {
  display: block;
  position: absolute;
  background: linear-gradient(95deg, rgba(25, 25, 25, 0), #363434 101.15%);
  width: 10%;
  top: 0;
  bottom: 0;
  inset-inline-start: 0;
  z-index: 9;
}
div.liveChannelsContainer div.wrapper div.scheduleContainer div.channel div.right div.scheduledItem.active div.progress div.currentTime {
  display: none;
}
div.liveChannelsContainer div.wrapper div.scheduleContainer div.channel div.right div.scheduledItem:hover div.metaData {
  display: flex;
}
div.liveChannelsContainer div.wrapper div.scheduleContainer div.channel div.right div.scheduledItem div.progress {
  display: none;
}
div.liveChannelsContainer div.wrapper div.scheduleContainer div.channel div.right div.scheduledItem h1 {
  font-size: 16px;
  color: var(--white-color);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  position: relative;
  z-index: 9;
}
div.liveChannelsContainer div.wrapper div.scheduleContainer div.channel div.right div.scheduledItem div.metaData {
  display: none;
  justify-content: space-between;
}
div.liveChannelsContainer div.wrapper div.scheduleContainer div.channel div.right div.scheduledItem div.metaData p.description {
  position: absolute;
  z-index: 99;
  color: var(--inner-text-color);
  font-size: 14px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 90%;
}
div.liveChannelsContainer div.wrapper div.scheduleContainer div.channel div.right div.scheduledItem div.metaData div.infoIcon {
  text-align: end;
  position: absolute;
  z-index: 9999;
  inset-inline-end: 10px;
  top: 10px;
}
div.liveChannelsContainer div.wrapper div.scheduleContainer div.channel div.right div.scheduledItem div.metaData div.infoIcon svg {
  color: var(--inner-text-color);
  width: 20px !important;
  height: 20px !important;
}
div.liveChannelsContainer div.categoryAll {
  padding: 1px 0px 20px 0px;
  background-color: var(--black-color);
}
div.liveChannelsContainer div.categoryAll div.categoryContainer {
  border: 1px solid var(--border-color);
  border-radius: 2px;
  width: 20rem;
  background: transparent;
  padding: 15px 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  color: var(--text-color);
  cursor: pointer;
  margin-top: 2%;
  margin-inline-start: 20px;
}
@media all and (max-width: 980px) {
  div.liveChannelsContainer div.categoryAll div.categoryContainer {
    margin-top: 5%;
    margin-inline-start: 2px;
  }
}
@media all and (max-width: 480px) {
  div.liveChannelsContainer div.categoryAll div.categoryContainer {
    width: 98%;
    margin-top: 6%;
    margin-inline-start: 4px;
  }
}
@media all and (max-width: 360px) {
  div.liveChannelsContainer div.categoryAll div.categoryContainer {
    width: 97%;
    margin-top: 8%;
    margin-inline-start: 5px;
    margin-inline-end: 5px;
  }
}
div.liveChannelsContainer div.categoryAll div.categoryContainer svg {
  width: 26px !important;
  height: 26px !important;
  transition: 0.3s ease-in-out;
}
div.liveChannelsContainer div.categoryAll div.categoryContainer svg.active {
  transform: rotate(-180deg);
}
div.liveChannelsContainer div.categoryAll div.categoryContainer div.list {
  position: absolute;
  top: 55px;
  width: 100%;
  left: 0;
  right: 0;
  z-index: 9;
  cursor: pointer;
  height: 150px;
  overflow-y: scroll;
  border: 1px solid var(--border-color);
  border-bottom-right-radius: 8px;
  border-bottom-left-radius: 8px;
  display: none;
}
div.liveChannelsContainer div.categoryAll div.categoryContainer div.list::-webkit-scrollbar {
  width: 5px;
  border-radius: 30px;
}
div.liveChannelsContainer div.categoryAll div.categoryContainer div.list::-webkit-scrollbar-thumb {
  background-color: var(--bg-color);
  border-radius: 30px;
}
div.liveChannelsContainer div.categoryAll div.categoryContainer div.list::-webkit-scrollbar-track {
  background-color: var(--grey-color);
}
div.liveChannelsContainer div.categoryAll div.categoryContainer div.list.active {
  display: block;
  z-index: 99999;
}
div.liveChannelsContainer div.categoryAll div.categoryContainer div.list div.item {
  width: 100%;
  padding: 15px 10px;
  border-bottom: 1px solid var(--border-color);
  background-color: #1c1f29;
}
div.liveChannelsContainer div.categoryAll div.categoryContainer div.list div.item:last-child {
  border-bottom-right-radius: 8px;
  border-bottom-left-radius: 8px;
}
div.liveChannelsContainer div.categoryAll div.categoryContainer div.list div.item span {
  font-size: 16px;
  color: var(--grey-color);
}
div.liveChannelsContainer div.categoryAll div.categoryContainer div.list div.item span:hover {
  color: var(--white-color);
}
div.liveChannelsContainer div.category-paymet-btn {
  position: absolute;
  background: transparent;
  padding-top: 42px;
}
@media all and (max-width: 360px) {
  div.liveChannelsContainer div.category-paymet-btn {
    position: initial;
    background: transparent;
    padding-top: 0px;
  }
}

div.liveChannelsContainer.rtl .topContainer {
  direction: rtl;
  text-align: right;
}
div.liveChannelsContainer.rtl .categoryAll {
  direction: rtl;
  text-align: right;
}

div.languageModal div.overlayModal {
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  background-color: rgba(32, 32, 38, 0.8);
  z-index: 99;
  display: flex;
  justify-content: center;
  align-items: center;
}
div.languageModal div.overlayModal.root {
  background: var(--bg-color);
}
div.languageModal div.closeIconContainer {
  position: fixed;
  top: 20px;
  inset-inline-end: 30px;
  z-index: 999;
  cursor: pointer;
}
div.languageModal div.closeIconContainer svg {
  width: 20px !important;
  height: 20px !important;
}
div.languageModal div.contents {
  z-index: 999;
  text-align: center;
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  background-color: var(--white-color);
  width: 30%;
  margin: 0 auto;
  padding: 25px 25px 25px;
  border-radius: 6px;
  display: flex;
  flex-direction: column;
}
@media all and (max-width: 1200px) {
  div.languageModal div.contents {
    padding: 30px;
    width: 40%;
  }
}
@media all and (max-width: 980px) {
  div.languageModal div.contents {
    width: 50%;
  }
}
@media all and (max-width: 768px) {
  div.languageModal div.contents {
    width: 60%;
  }
}
@media all and (max-width: 640px) {
  div.languageModal div.contents {
    width: 90%;
  }
}
@media all and (max-width: 480px) {
  div.languageModal div.contents {
    width: 90%;
  }
}
div.languageModal div.contents h1 {
  color: var(--button-color);
  font-size: 22px;
  margin-bottom: 10px;
}
div.languageModal div.contents div.inputContainer {
  width: 100%;
  margin-bottom: 20px;
}
div.languageModal div.contents div.inputContainer select {
  width: 100%;
  padding: 12px 10px;
  outline: none;
  border: 1px solid rgb(231, 238, 239);
  color: var(--button-color);
}
div.languageModal div.contents div.inputContainer select option {
  padding: 12px;
}
div.languageModal div.contents div.buttonContainer button {
  width: 100%;
  display: block;
  padding: 10px;
  background-color: var(--button-color);
  color: var(--white-color);
  cursor: pointer;
  border-radius: 5px;
  border: 1px solid var(--button-color);
  transition: 0.4s ease-in-out;
}
div.languageModal div.contents div.buttonContainer button:hover {
  background-color: transparent;
  color: var(--button-color);
}

div.eventDetailsScreen {
  padding: 100px 0 0;
  position: relative;
}
div.eventDetailsScreen div.eventEndedOverlay {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.8);
  z-index: 9;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
}
div.eventDetailsScreen div.eventEndedOverlay h2 {
  font-size: 30px;
  color: var(--white-color);
  margin-bottom: 10px;
}
@media all and (max-width: 640px) {
  div.eventDetailsScreen div.eventEndedOverlay h2 {
    font-size: 22px;
  }
}
div.eventDetailsScreen div.eventEndedOverlay p {
  font-size: 20px;
  color: var(--secondary-color);
  cursor: pointer;
}
@media all and (max-width: 980px) {
  div.eventDetailsScreen div.eventEndedOverlay p {
    text-decoration: underline;
  }
}
@media all and (max-width: 640px) {
  div.eventDetailsScreen div.eventEndedOverlay p {
    font-size: 16px;
  }
}
div.eventDetailsScreen div.eventEndedOverlay p:hover {
  text-decoration: underline;
}
div.eventDetailsScreen .swiper-button-next {
  inset-inline-end: -9px !important;
  top: 45%;
}
@media all and (min-width: 1200px) {
  div.eventDetailsScreen .swiper-button-next {
    inset-inline-end: 0 !important;
  }
}
div.eventDetailsScreen .swiper-button-prev {
  inset-inline-start: -9px !important;
  top: 45%;
}
@media all and (min-width: 1200px) {
  div.eventDetailsScreen .swiper-button-prev {
    inset-inline-start: 0 !important;
  }
}
div.eventDetailsScreen div.wrapper {
  background-color: var(--bg-color);
  position: relative;
  z-index: 8;
}
div.eventDetailsScreen div.bgImageContainer {
  display: block;
  position: fixed;
  top: 0;
  height: 100%;
  z-index: 7;
  width: 100%;
}
div.eventDetailsScreen div.bgImageContainer div.gradient {
  position: absolute;
  bottom: 0;
  z-index: 8;
  width: 100%;
  height: 100%;
  background-position: top;
  background-size: cover;
  background-repeat: no-repeat;
  background: linear-gradient(78.83deg, #10141f, rgba(16, 20, 31, 0) 260.46%), linear-gradient(1turn, #10141f, rgba(16, 20, 31, 0) 48.44%, rgba(16, 20, 31, 0.4)), rgba(16, 20, 31, 0.4);
}
div.eventDetailsScreen div.bgImageContainer img {
  width: 100%;
  display: block;
}
div.eventDetailsScreen div.videoContainer {
  background-color: #000;
  width: 100%;
}
div.eventDetailsScreen div.videoContainer.heightIncrease {
  height: 38em;
}
@media all and (max-width: 1200px) {
  div.eventDetailsScreen div.videoContainer.heightIncrease {
    height: 25em;
  }
}
div.eventDetailsScreen div.videoContainer div.gradientTop {
  position: absolute;
  top: 0;
  z-index: 8;
  width: 100%;
  height: 20%;
  background-position: top;
  background-size: cover;
  background-repeat: no-repeat;
  background: linear-gradient(0deg, rgba(16, 20, 31, 0), rgba(16, 20, 31, 0.2) 19%, rgba(16, 20, 31, 0.4) 39%, rgba(16, 20, 31, 0.8));
}
div.eventDetailsScreen div.videoContainer div.player {
  position: relative;
  z-index: 9;
  width: 100%;
  display: block;
}
div.eventDetailsScreen div.videoContainer div.player div.closeIcon {
  display: none;
}
div.eventDetailsScreen div.videoContainer div.player div.nowPlaying {
  position: absolute;
  top: 20px;
  inset-inline-end: 20px;
  z-index: 7;
}
div.eventDetailsScreen div.videoContainer div.player div.nowPlaying h1 {
  color: var(--text-color);
}
@media all and (max-width: 1200px) {
  div.eventDetailsScreen div.videoContainer div.player div.nowPlaying h1 {
    font-size: 23px;
  }
}
@media all and (max-width: 980px) {
  div.eventDetailsScreen div.videoContainer div.player div.nowPlaying h1 {
    font-size: 20px;
  }
}
@media all and (max-width: 768px) {
  div.eventDetailsScreen div.videoContainer div.player div.nowPlaying h1 {
    font-size: 18px;
  }
}
@media all and (max-width: 640px) {
  div.eventDetailsScreen div.videoContainer div.player div.nowPlaying h1 {
    font-size: 16px;
  }
}
@media all and (max-width: 480px) {
  div.eventDetailsScreen div.videoContainer div.player div.nowPlaying h1 {
    font-size: 14px;
  }
}
div.eventDetailsScreen div.videoContainer div.player.pipMode {
  position: fixed;
  top: 110px;
  width: 22%;
  inset-inline-end: 0;
  z-index: 999999999;
  height: 200px;
}
@media all and (max-width: 768px) {
  div.eventDetailsScreen div.videoContainer div.player.pipMode {
    width: 40%;
  }
}
@media all and (max-width: 640px) {
  div.eventDetailsScreen div.videoContainer div.player.pipMode {
    width: 50%;
  }
}
@media all and (max-width: 480px) {
  div.eventDetailsScreen div.videoContainer div.player.pipMode {
    width: 68%;
  }
}
div.eventDetailsScreen div.videoContainer div.player.pipMode video {
  -o-object-fit: cover;
     object-fit: cover;
  height: 100%;
}
div.eventDetailsScreen div.videoContainer div.player.pipMode .video-js {
  padding: 0;
  height: 100%;
}
div.eventDetailsScreen div.videoContainer div.player.pipMode div.gradientTop {
  display: none;
}
div.eventDetailsScreen div.videoContainer div.player.pipMode div.nowPlaying {
  display: none;
}
div.eventDetailsScreen div.videoContainer div.player.pipMode:hover div.closeIcon {
  position: absolute;
  top: 10px;
  inset-inline-start: 10px;
  z-index: 9;
  background-color: var(--bg-color);
  height: 25px;
  width: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
}
div.eventDetailsScreen div.videoContainer div.player.pipMode:hover div.closeIcon svg {
  color: var(--text-color);
}
div.eventDetailsScreen div.videoContainer div.player.pipMode:hover div.gradientTop {
  display: block;
}
div.eventDetailsScreen div.videoContainer video {
  display: block;
  width: 100%;
}
div.eventDetailsScreen div.details {
  position: relative;
  z-index: 8;
  display: flex;
  padding: 30px 0 130px;
}
@media all and (max-width: 980px) {
  div.eventDetailsScreen div.details {
    flex-direction: column;
  }
}
div.eventDetailsScreen div.details div.watchNow {
  width: 100%;
  margin-top: 15px;
}
div.eventDetailsScreen div.details div.watchNow button {
  width: 100%;
  display: block;
  background: var(--button-color);
  padding: 15px 10px;
  font-size: 15px;
  color: var(--text-color);
  font-weight: 600;
  border-radius: 8px;
  cursor: pointer;
  transition: 0.3s ease-in-out;
}
div.eventDetailsScreen div.details div.watchNow button:hover {
  background: var(--button-hover);
}
div.eventDetailsScreen div.details div.addtoList {
  width: 100%;
  margin-top: 15px;
}
div.eventDetailsScreen div.details div.addtoList button {
  width: 100%;
  display: block;
  background-color: rgba(150, 153, 163, 0.16);
  padding: 15px 10px;
  font-size: 15px;
  color: var(--text-color);
  font-weight: 600;
  border-radius: 8px;
  cursor: pointer;
  transition: 0.3s ease-in-out;
}
div.eventDetailsScreen div.details div.addtoList button:hover {
  background-color: rgba(203, 203, 208, 0.16);
}
div.eventDetailsScreen div.details div.buttons {
  margin-top: 15px;
  display: flex;
  width: 100%;
}
div.eventDetailsScreen div.details div.buttons div.buttonContainerOne {
  width: 70%;
  position: relative;
  margin-inline-end: 10px;
}
@media all and (max-width: 980px) {
  div.eventDetailsScreen div.details div.buttons div.buttonContainerOne {
    width: 90%;
  }
}
@media all and (max-width: 640px) {
  div.eventDetailsScreen div.details div.buttons div.buttonContainerOne {
    width: 80%;
  }
}
@media all and (max-width: 480px) {
  div.eventDetailsScreen div.details div.buttons div.buttonContainerOne {
    width: 75%;
  }
}
div.eventDetailsScreen div.details div.buttons div.buttonContainerOne div.shareContainer {
  position: absolute;
  bottom: -126px;
  inset-inline-start: -20px;
  z-index: 9;
  background-color: #1c1f29;
  border: 1px solid var(--border-color);
  width: 232px;
  border-radius: 8px;
  display: none;
}
@media all and (max-width: 1200px) {
  div.eventDetailsScreen div.details div.buttons div.buttonContainerOne div.shareContainer {
    width: 200px;
  }
}
@media all and (max-width: 980px) {
  div.eventDetailsScreen div.details div.buttons div.buttonContainerOne div.shareContainer {
    width: 232px;
    inset-inline-start: 50%;
    transform: translateX(-50%);
  }
}
@media all and (max-width: 480px) {
  div.eventDetailsScreen div.details div.buttons div.buttonContainerOne div.shareContainer {
    width: 100%;
  }
}
div.eventDetailsScreen div.details div.buttons div.buttonContainerOne div.shareContainer.active {
  display: block;
}
div.eventDetailsScreen div.details div.buttons div.buttonContainerOne div.shareContainer::after {
  content: "";
  top: -9px;
  width: 0;
  height: 0;
  border-color: transparent transparent rgba(240, 241, 245, 0.16);
  border-style: solid;
  border-width: 0 8px 8px;
  position: absolute;
  inset-inline-start: 50%;
  transform: translateX(-50%);
}
@media all and (max-width: 1200px) {
  div.eventDetailsScreen div.details div.buttons div.buttonContainerOne div.shareContainer::after {
    inset-inline-start: 40%;
  }
}
@media all and (max-width: 980px) {
  div.eventDetailsScreen div.details div.buttons div.buttonContainerOne div.shareContainer::after {
    inset-inline-start: 50%;
  }
}
div.eventDetailsScreen div.details div.buttons div.buttonContainerOne div.shareContainer div.facebookContainer button.facebook {
  display: flex !important;
  justify-content: space-between !important;
  padding: 15px !important;
  color: var(--text-color) !important;
  cursor: pointer !important;
  border-bottom: 1px solid var(--border-color) !important;
  width: 100% !important;
}
div.eventDetailsScreen div.details div.buttons div.buttonContainerOne div.shareContainer div.facebookContainer button.facebook div.icon {
  width: 10%;
}
div.eventDetailsScreen div.details div.buttons div.buttonContainerOne div.shareContainer div.facebookContainer button.facebook div.icon svg {
  width: 20px !important;
  height: 20px !important;
  color: var(--grey-color);
  transition: 0.3s ease-in-out;
}
div.eventDetailsScreen div.details div.buttons div.buttonContainerOne div.shareContainer div.facebookContainer button.facebook div.icon svg:hover {
  color: var(--text-color);
}
div.eventDetailsScreen div.details div.buttons div.buttonContainerOne div.shareContainer div.facebookContainer div.left {
  width: 10%;
}
div.eventDetailsScreen div.details div.buttons div.buttonContainerOne div.shareContainer div.facebookContainer div.right svg {
  width: 20px !important;
  height: 20px !important;
  color: var(--grey-color);
  transition: 0.3s ease-in-out;
}
div.eventDetailsScreen div.details div.buttons div.buttonContainerOne div.shareContainer div.facebookContainer div.right svg:hover {
  color: var(--text-color);
}
div.eventDetailsScreen div.details div.buttons div.buttonContainerOne div.shareContainer div.twitterContainer button.twitter {
  display: flex !important;
  justify-content: space-between !important;
  padding: 15px !important;
  color: var(--text-color) !important;
  cursor: pointer !important;
  width: 100% !important;
}
div.eventDetailsScreen div.details div.buttons div.buttonContainerOne div.shareContainer div.twitterContainer button.twitter div.icon {
  width: 10%;
}
div.eventDetailsScreen div.details div.buttons div.buttonContainerOne div.shareContainer div.twitterContainer button.twitter div.icon svg {
  width: 20px !important;
  height: 20px !important;
  color: var(--grey-color);
  transition: 0.3s ease-in-out;
}
div.eventDetailsScreen div.details div.buttons div.buttonContainerOne div.shareContainer div.twitterContainer button.twitter div.icon svg:hover {
  color: var(--text-color);
}
div.eventDetailsScreen div.details div.buttons div.buttonContainerOne div.shareContainer div.twitterContainer div.left {
  width: 10%;
}
div.eventDetailsScreen div.details div.buttons div.buttonContainerOne div.shareContainer div.twitterContainer div.right svg {
  width: 20px !important;
  height: 20px !important;
  color: var(--grey-color);
  transition: 0.3s ease-in-out;
}
div.eventDetailsScreen div.details div.buttons div.buttonContainerOne div.shareContainer div.twitterContainer div.right svg:hover {
  color: var(--text-color);
}
div.eventDetailsScreen div.details div.buttons div.buttonContainerOne button.share {
  display: block;
  background-color: rgba(150, 153, 163, 0.16);
  padding: 15px 10px;
  font-size: 15px;
  color: var(--text-color);
  font-weight: 600;
  border-radius: 8px;
  width: 100%;
  cursor: pointer;
  transition: 0.3s ease-in-out;
}
div.eventDetailsScreen div.details div.buttons div.buttonContainerOne button.share:hover {
  background-color: rgba(203, 203, 208, 0.16);
}
div.eventDetailsScreen div.details div.buttons div.buttonContainerTwo {
  width: 30%;
  position: relative;
}
@media all and (max-width: 980px) {
  div.eventDetailsScreen div.details div.buttons div.buttonContainerTwo {
    width: 10%;
  }
}
@media all and (max-width: 640px) {
  div.eventDetailsScreen div.details div.buttons div.buttonContainerTwo {
    width: 18%;
  }
}
@media all and (max-width: 480px) {
  div.eventDetailsScreen div.details div.buttons div.buttonContainerTwo {
    width: 25%;
  }
}
div.eventDetailsScreen div.details div.buttons div.buttonContainerTwo button.more {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(150, 153, 163, 0.16);
  padding: 15px 10px;
  font-size: 15px;
  color: var(--text-color);
  border-radius: 8px;
  width: 100%;
  cursor: pointer;
  transition: 0.3s ease-in-out;
}
div.eventDetailsScreen div.details div.buttons div.buttonContainerTwo button.more svg {
  width: 20px !important;
  height: 20px !important;
}
div.eventDetailsScreen div.details div.buttons div.buttonContainerTwo button.more:hover {
  background-color: rgba(203, 203, 208, 0.16);
}
div.eventDetailsScreen div.details div.buttons div.buttonContainerTwo div.reportProblemContainer {
  position: absolute;
  bottom: -66px;
  inset-inline-end: 0;
  z-index: 9;
  background-color: #1c1f29;
  border: 1px solid var(--border-color);
  width: 200px;
  border-radius: 8px;
  padding: 15px;
  display: none;
  cursor: pointer;
}
div.eventDetailsScreen div.details div.buttons div.buttonContainerTwo div.reportProblemContainer.active {
  display: block;
}
@media all and (max-width: 1200px) {
  div.eventDetailsScreen div.details div.buttons div.buttonContainerTwo div.reportProblemContainer {
    width: 164px;
  }
}
@media all and (max-width: 980px) {
  div.eventDetailsScreen div.details div.buttons div.buttonContainerTwo div.reportProblemContainer {
    width: 200px;
  }
}
div.eventDetailsScreen div.details div.buttons div.buttonContainerTwo div.reportProblemContainer span {
  color: var(--text-color);
}
div.eventDetailsScreen div.details div.buttons div.buttonContainerTwo div.reportProblemContainer::after {
  content: "";
  top: -9px;
  width: 0;
  height: 0;
  border-color: transparent transparent rgba(240, 241, 245, 0.16);
  border-style: solid;
  border-width: 0 8px 8px;
  position: absolute;
  inset-inline-end: 8%;
  transform: translateX(-50%);
}
@media all and (max-width: 1200px) {
  div.eventDetailsScreen div.details div.buttons div.buttonContainerTwo div.reportProblemContainer::after {
    inset-inline-end: 5%;
  }
}
@media all and (max-width: 980px) {
  div.eventDetailsScreen div.details div.buttons div.buttonContainerTwo div.reportProblemContainer::after {
    inset-inline-end: 8%;
  }
}
div.eventDetailsScreen div.details div.subscriptions {
  margin-top: 20px;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
}
div.eventDetailsScreen div.details div.subscriptions div.items {
  cursor: pointer;
  margin-inline-end: 10px;
  background-color: var(--yellow-color);
  padding: 10px 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 13px;
  font-weight: 600;
  color: var(--text-color);
  margin-bottom: 20px;
  border-radius: 30px;
}
div.eventDetailsScreen div.details div.subscriptions div.items.free {
  background-color: var(--success-color);
}
div.eventDetailsScreen div.details div.leftContainer {
  width: 27%;
  margin-inline-end: 40px;
}
@media all and (max-width: 980px) {
  div.eventDetailsScreen div.details div.leftContainer {
    display: none;
  }
}
div.eventDetailsScreen div.details div.leftContainer div.showCardDetails {
  width: 100%;
  position: sticky;
  top: 0;
}
div.eventDetailsScreen div.details div.leftContainer div.showCardDetails div.imageContainer {
  width: 100%;
}
div.eventDetailsScreen div.details div.leftContainer div.showCardDetails div.imageContainer img {
  width: 100%;
  display: block;
  border-radius: 5px;
}
div.eventDetailsScreen div.details div.rightContainer {
  width: 100%;
  overflow-y: scroll;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}
div.eventDetailsScreen div.details div.rightContainer::-webkit-scrollbar {
  display: none;
}
div.eventDetailsScreen div.details div.rightContainer div.showMetadata {
  border-bottom: 1px solid var(--border-color);
  padding-bottom: 40px;
  width: 100%;
}
div.eventDetailsScreen div.details div.rightContainer div.showMetadata h1.name {
  font-size: 58px;
  font-weight: 700;
  color: var(--text-color);
}
@media all and (max-width: 1200px) {
  div.eventDetailsScreen div.details div.rightContainer div.showMetadata h1.name {
    font-size: 48px;
  }
}
@media all and (max-width: 640px) {
  div.eventDetailsScreen div.details div.rightContainer div.showMetadata h1.name {
    font-size: 35px;
  }
}
div.eventDetailsScreen div.details div.rightContainer div.showMetadata div.data {
  display: flex;
  margin-bottom: 6px;
}
div.eventDetailsScreen div.details div.rightContainer div.showMetadata div.data span {
  font-size: 18px;
  color: var(--inner-text-color);
}
@media all and (max-width: 640px) {
  div.eventDetailsScreen div.details div.rightContainer div.showMetadata div.data span {
    font-size: 14px;
  }
}
div.eventDetailsScreen div.details div.rightContainer div.showMetadata div.data span.year {
  margin-inline-end: 6px;
}
div.eventDetailsScreen div.details div.rightContainer div.showMetadata div.data span.duration {
  margin-inline-end: 8px;
}
div.eventDetailsScreen div.details div.rightContainer div.showMetadata div.data span.duration.showDot::before {
  content: "·";
  margin-inline-end: 6px;
}
div.eventDetailsScreen div.details div.rightContainer div.showMetadata div.data span.duration.ltr {
  direction: ltr !important;
  unicode-bidi: embed !important;
}
div.eventDetailsScreen div.details div.rightContainer div.showMetadata div.data span.certificate {
  display: block;
  padding: 4px 6px;
  background-color: rgba(150, 153, 163, 0.32);
  font-size: 11px;
  font-weight: 700;
  color: var(--text-color);
  border-radius: 4px;
}
@media all and (max-width: 640px) {
  div.eventDetailsScreen div.details div.rightContainer div.showMetadata div.data span.certificate {
    font-size: 10px;
  }
}
div.eventDetailsScreen div.details div.rightContainer div.showMetadata div.genres {
  margin-bottom: 10px;
}
div.eventDetailsScreen div.details div.rightContainer div.showMetadata div.genres span {
  font-size: 18px;
  color: var(--inner-text-color);
  margin-inline-end: 6px;
}
@media all and (max-width: 640px) {
  div.eventDetailsScreen div.details div.rightContainer div.showMetadata div.genres span {
    font-size: 14px;
  }
}
div.eventDetailsScreen div.details div.rightContainer div.showMetadata div.genres span::before {
  content: "·";
  margin-inline-end: 6px;
}
div.eventDetailsScreen div.details div.rightContainer div.showMetadata div.genres span:first-child::before {
  display: none;
}
div.eventDetailsScreen div.details div.rightContainer div.showMetadata div.genres span:last-child {
  margin-inline-end: 0;
}
div.eventDetailsScreen div.details div.rightContainer div.showMetadata div.watchNow {
  display: none;
}
@media all and (max-width: 980px) {
  div.eventDetailsScreen div.details div.rightContainer div.showMetadata div.watchNow {
    display: block;
  }
}
div.eventDetailsScreen div.details div.rightContainer div.showMetadata div.addtoList {
  display: none;
}
@media all and (max-width: 980px) {
  div.eventDetailsScreen div.details div.rightContainer div.showMetadata div.addtoList {
    display: block;
  }
}
div.eventDetailsScreen div.details div.rightContainer div.showMetadata div.buttons {
  display: none;
  margin-bottom: 20px;
}
@media all and (max-width: 980px) {
  div.eventDetailsScreen div.details div.rightContainer div.showMetadata div.buttons {
    display: flex;
  }
}
div.eventDetailsScreen div.details div.rightContainer div.showMetadata p.description {
  font-size: 15px;
  color: var(--text-color);
  line-height: 1.4em;
  letter-spacing: 0.3px;
  max-width: 100%;
}
@media all and (max-width: 1200px) {
  div.eventDetailsScreen div.details div.rightContainer div.showMetadata p.description {
    max-width: 100%;
  }
}
div.eventDetailsScreen div.details div.rightContainer div.showMetadata div.info {
  margin-top: 20px;
}
div.eventDetailsScreen div.details div.rightContainer div.showMetadata div.info div.starring {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
}
div.eventDetailsScreen div.details div.rightContainer div.showMetadata div.info div.starring div.left {
  width: 15%;
}
@media all and (max-width: 768px) {
  div.eventDetailsScreen div.details div.rightContainer div.showMetadata div.info div.starring div.left {
    width: 20%;
  }
}
@media all and (max-width: 640px) {
  div.eventDetailsScreen div.details div.rightContainer div.showMetadata div.info div.starring div.left {
    width: 25%;
  }
}
@media all and (max-width: 480px) {
  div.eventDetailsScreen div.details div.rightContainer div.showMetadata div.info div.starring div.left {
    width: 30%;
  }
}
@media all and (max-width: 360px) {
  div.eventDetailsScreen div.details div.rightContainer div.showMetadata div.info div.starring div.left {
    width: 33%;
  }
}
div.eventDetailsScreen div.details div.rightContainer div.showMetadata div.info div.starring div.left h6 {
  font-size: 16px;
  font-weight: 400;
  color: var(--inner-text-color);
}
div.eventDetailsScreen div.details div.rightContainer div.showMetadata div.info div.starring div.right {
  width: 85%;
}
@media all and (max-width: 980px) {
  div.eventDetailsScreen div.details div.rightContainer div.showMetadata div.info div.starring div.right {
    width: 75%;
  }
}
@media all and (max-width: 640px) {
  div.eventDetailsScreen div.details div.rightContainer div.showMetadata div.info div.starring div.right {
    width: 70%;
  }
}
@media all and (max-width: 480px) {
  div.eventDetailsScreen div.details div.rightContainer div.showMetadata div.info div.starring div.right {
    width: 65%;
  }
}
@media all and (max-width: 360px) {
  div.eventDetailsScreen div.details div.rightContainer div.showMetadata div.info div.starring div.right {
    width: 55%;
  }
}
div.eventDetailsScreen div.details div.rightContainer div.showMetadata div.info div.starring div.right ul.names {
  display: flex;
  flex-wrap: wrap;
}
div.eventDetailsScreen div.details div.rightContainer div.showMetadata div.info div.starring div.right ul.names li {
  font-size: 16px;
  font-weight: 600;
  margin-inline-end: 8px;
  color: var(--text-color);
}
div.eventDetailsScreen div.details div.rightContainer div.showMetadata div.info div.starring div.right ul.names li:last-child {
  margin-inline-end: 0;
}
@media all and (max-width: 480px) {
  div.eventDetailsScreen div.details div.rightContainer div.showMetadata div.info div.starring div.right ul.names li {
    font-size: 14px;
  }
}
div.eventDetailsScreen div.details div.rightContainer div.showMetadata div.info div.director {
  display: flex;
  justify-content: space-between;
}
div.eventDetailsScreen div.details div.rightContainer div.showMetadata div.info div.director div.left {
  width: 15%;
}
@media all and (max-width: 768px) {
  div.eventDetailsScreen div.details div.rightContainer div.showMetadata div.info div.director div.left {
    width: 20%;
  }
}
@media all and (max-width: 640px) {
  div.eventDetailsScreen div.details div.rightContainer div.showMetadata div.info div.director div.left {
    width: 25%;
  }
}
@media all and (max-width: 480px) {
  div.eventDetailsScreen div.details div.rightContainer div.showMetadata div.info div.director div.left {
    width: 30%;
  }
}
@media all and (max-width: 360px) {
  div.eventDetailsScreen div.details div.rightContainer div.showMetadata div.info div.director div.left {
    width: 33%;
  }
}
div.eventDetailsScreen div.details div.rightContainer div.showMetadata div.info div.director div.left h6 {
  font-size: 16px;
  font-weight: 400;
  color: var(--inner-text-color);
}
div.eventDetailsScreen div.details div.rightContainer div.showMetadata div.info div.director div.right {
  width: 85%;
}
@media all and (max-width: 980px) {
  div.eventDetailsScreen div.details div.rightContainer div.showMetadata div.info div.director div.right {
    width: 75%;
  }
}
@media all and (max-width: 640px) {
  div.eventDetailsScreen div.details div.rightContainer div.showMetadata div.info div.director div.right {
    width: 70%;
  }
}
@media all and (max-width: 480px) {
  div.eventDetailsScreen div.details div.rightContainer div.showMetadata div.info div.director div.right {
    width: 65%;
  }
}
@media all and (max-width: 360px) {
  div.eventDetailsScreen div.details div.rightContainer div.showMetadata div.info div.director div.right {
    width: 55%;
  }
}
div.eventDetailsScreen div.details div.rightContainer div.showMetadata div.info div.director div.right h6 {
  font-size: 16px;
  font-weight: 600;
  margin-inline-end: 8px;
  color: var(--text-color);
}
@media all and (max-width: 480px) {
  div.eventDetailsScreen div.details div.rightContainer div.showMetadata div.info div.director div.right h6 {
    font-size: 14px;
  }
}
div.ideaBizSubscribeModal div.overlay {
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.7);
  z-index: 99;
  display: flex;
  justify-content: center;
  align-items: center;
}
div.ideaBizSubscribeModal div.closeIconContainer {
  position: fixed;
  top: 20px;
  inset-inline-end: 30px;
  z-index: 999;
  cursor: pointer;
}
div.ideaBizSubscribeModal div.closeIconContainer svg {
  width: 20px !important;
  height: 20px !important;
}
div.ideaBizSubscribeModal div.modalContainer {
  z-index: 999;
  text-align: center;
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  background-color: var(--bg-color);
  width: 40%;
  margin: 0 auto;
  padding: 50px 50px 50px;
  border-radius: 6px;
  display: flex;
  flex-direction: column;
  /* Firefox */
}
@media all and (max-width: 1200px) {
  div.ideaBizSubscribeModal div.modalContainer {
    padding: 30px;
  }
}
@media all and (max-width: 768px) {
  div.ideaBizSubscribeModal div.modalContainer {
    width: 60%;
  }
}
@media all and (max-width: 480px) {
  div.ideaBizSubscribeModal div.modalContainer {
    width: 90%;
  }
}
div.ideaBizSubscribeModal div.modalContainer input {
  outline: none;
  border-radius: 5px;
  font-size: 14px;
  background: transparent;
  text-align: start;
  padding: 10px 15px;
  border: 1px solid var(--inner-text-color);
  caret-color: var(--text-color);
  margin-bottom: 10px;
  color: var(--text-color);
  display: block;
  width: 100%;
}
div.ideaBizSubscribeModal div.modalContainer input:focus {
  border: 1px solid var(--text-color);
}
@media all and (max-width: 640px) {
  div.ideaBizSubscribeModal div.modalContainer input {
    padding: 16px 15px;
  }
}
div.ideaBizSubscribeModal div.modalContainer input::-webkit-outer-spin-button,
div.ideaBizSubscribeModal div.modalContainer input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
div.ideaBizSubscribeModal div.modalContainer input[type=number] {
  -moz-appearance: textfield;
}
div.ideaBizSubscribeModal div.modalContainer div.buttonContainer {
  display: flex;
  padding: 18px 20px;
  border-radius: 4px;
  align-items: center;
  margin-bottom: 15px;
  cursor: pointer;
  transition: 0.2s ease-in-out;
  justify-content: center;
  position: relative;
  width: 10rem;
  margin: 30px auto 0;
}
@media all and (max-width: 640px) {
  div.ideaBizSubscribeModal div.modalContainer div.buttonContainer {
    width: 10rem;
    padding: 10px 20px;
  }
}
@media all and (max-width: 480px) {
  div.ideaBizSubscribeModal div.modalContainer div.buttonContainer {
    width: 10rem;
  }
}
div.ideaBizSubscribeModal div.modalContainer div.buttonContainer:hover div.background {
  transform: scale(1.0476190476, 1.0357142857);
}
div.ideaBizSubscribeModal div.modalContainer div.buttonContainer div.background {
  background: var(--text-hover);
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border-radius: 4px;
  transition: 0.1s ease-in-out;
  z-index: 1;
}
div.ideaBizSubscribeModal div.modalContainer div.buttonContainer button {
  background: transparent;
  font-size: 15px;
  color: var(--text-color);
  font-weight: 600;
  position: relative;
  z-index: 2;
  cursor: pointer;
}
div.ideaBizSubscribeModal div.modalContainer h1.heading {
  font-size: 20px;
  color: var(--text-color);
  margin-bottom: 20px;
}
div.ideaBizSubscribeModal div.modalContainer div.phoneContainer div.top {
  padding-bottom: 20px;
  border-bottom: 1px solid var(--border-color);
}
div.ideaBizSubscribeModal div.modalContainer div.phoneContainer div.top p {
  color: var(--text-color);
  font-weight: 700;
}
div.ideaBizSubscribeModal div.modalContainer div.phoneContainer div.top p.subHeading {
  margin-bottom: 10px;
}
div.ideaBizSubscribeModal div.modalContainer div.phoneContainer div.bottom {
  margin-top: 20px;
}
div.ideaBizSubscribeModal div.modalContainer div.phoneContainer div.bottom label {
  font-size: 16px;
  margin-bottom: 10px;
  color: var(--text-color);
  display: block;
  text-align: start;
}
div.ideaBizSubscribeModal div.modalContainer div.phoneContainer div.bottom select {
  width: 100%;
  background-color: transparent;
  color: var(--text-color);
  padding: 10px 15px;
  margin-bottom: 15px;
  border-radius: 5px;
}
div.ideaBizSubscribeModal div.modalContainer div.otpContainer input {
  width: 50%;
  margin: 0 auto;
  padding: 15px;
}
div.ideaBizSubscribeModal div.modalContainer div.confirmContainer div.buttons {
  display: flex;
  justify-content: center;
  margin-top: 10px;
}
div.ideaBizSubscribeModal div.modalContainer div.confirmContainer div.buttons div.buttonContainer {
  margin: 0 20px;
}
div.ideaBizSubscribeModal div.modalContainer div.confirmContainer div.buttons div.buttonContainer:last-child {
  margin: 0 0;
}
div.ideaBizSubscribeModal div.modalContainer div.confirmContainer div.buttons div.buttonContainer div.background.greenBg {
  background-color: var(--success-color);
}

div.topBanner {
  width: 100%;
  margin: 0 auto;
  padding: 15px 20px 30px;
  z-index: 9;
  position: relative;
  background-color: var(--custom-top-banner-bg-color);
}
div.topBanner div.head {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
@media all and (max-width: 980px) {
  div.topBanner div.head {
    flex-direction: column;
  }
}
@media all and (max-width: 768px) {
  div.topBanner div.head {
    display: none;
  }
}
div.topBanner div.head span.contact {
  color: var(--text-color);
}
div.topBanner div.head span.contact span.icon {
  margin-right: 10px;
  display: inline-block;
}
div.topBanner div.head div.social {
  display: flex;
  align-items: center;
}
@media all and (max-width: 980px) {
  div.topBanner div.head div.social {
    margin-top: 15px;
  }
}
div.topBanner div.head div.social span.label {
  margin-right: 10px;
  color: var(--text-color);
  font-weight: 600;
}
div.topBanner div.head div.social div.icons {
  display: flex;
  cursor: pointer;
}
div.topBanner div.head div.social div.icons span.icon {
  width: 35px;
  height: 35px;
  background-color: var(--white-color);
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 5px;
}
div.topBanner div.head div.social div.icons span.icon:last-child {
  margin-right: 0;
}
div.topBanner div.body {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 40px auto 0;
}
@media all and (max-width: 768px) {
  div.topBanner div.body {
    margin-top: 0;
    flex-direction: column;
  }
}
div.topBanner div.body div.imageContainer {
  width: 50%;
  margin-right: 150px;
  cursor: pointer;
}
@media all and (max-width: 1200px) {
  div.topBanner div.body div.imageContainer {
    width: 60%;
  }
}
@media all and (max-width: 980px) {
  div.topBanner div.body div.imageContainer {
    width: 80%;
    margin-right: 48px;
  }
}
@media all and (max-width: 768px) {
  div.topBanner div.body div.imageContainer {
    margin-right: 0;
    margin-bottom: 15px;
  }
}
div.topBanner div.body div.imageContainer img {
  width: 100%;
  display: block;
}
div.topBanner div.body div.searchContainer {
  width: 20%;
}
@media all and (max-width: 980px) {
  div.topBanner div.body div.searchContainer {
    width: 41%;
  }
}
@media all and (max-width: 768px) {
  div.topBanner div.body div.searchContainer {
    width: 60%;
  }
}
@media all and (max-width: 480px) {
  div.topBanner div.body div.searchContainer {
    width: 90%;
  }
}
div.topBanner div.body div.searchContainer input {
  padding: 6px 20px;
  border-radius: 30px;
  outline: none;
  border: none;
  display: block;
  width: 100%;
}
div.topBanner div.body div.searchContainer input::-moz-placeholder {
  font-weight: 700;
  font-size: 15px;
}
div.topBanner div.body div.searchContainer input::placeholder {
  font-weight: 700;
  font-size: 15px;
}

div.topBanner.rtl .head .contact span.icon {
  margin-right: 0;
  margin-left: 10px;
}
div.topBanner.rtl .head .social span.label {
  margin-right: 0;
  margin-left: 10px;
}
div.topBanner.rtl .head .social .icons span.icon {
  margin-right: 0;
  margin-left: 5px;
}
div.topBanner.rtl .head .social .icons span.icon:last-child {
  margin-left: 0;
}
div.topBanner.rtl .body div.imageContainer {
  margin-right: 0;
  margin-left: 150px;
}
@media all and (max-width: 980px) {
  div.topBanner.rtl .body div.imageContainer {
    margin-right: 0;
    margin-left: 48px;
  }
}
@media all and (max-width: 768px) {
  div.topBanner.rtl .body div.imageContainer {
    margin-left: 0;
  }
}

div.customHeaderComponent {
  background-color: var(--custom-header-bg-color);
  position: sticky;
  top: 0;
  z-index: 99;
  height: 70px;
  display: flex;
  align-items: center;
}
div.customHeaderComponent div.wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 100%;
}
@media all and (max-width: 1200px) {
  div.customHeaderComponent div.wrapper {
    width: 98%;
  }
}
@media all and (max-width: 980px) {
  div.customHeaderComponent div.left {
    display: flex;
    align-items: center;
  }
}
div.customHeaderComponent div.left div.hamburgerMenuIconContainer {
  display: none;
  margin-inline-end: 15px;
}
@media all and (max-width: 980px) {
  div.customHeaderComponent div.left div.hamburgerMenuIconContainer {
    display: block;
  }
  div.customHeaderComponent div.left div.hamburgerMenuIconContainer svg {
    width: 30px !important;
    height: 30px !important;
    color: var(--white-color);
    stroke: var(--white-color) !important;
    fill: var(--white-color) !important;
  }
}
div.customHeaderComponent div.left h1.logo {
  width: 100px;
  cursor: pointer;
}
div.customHeaderComponent div.left h1.logo img {
  width: 100%;
  display: block;
}
div.customHeaderComponent div.center {
  height: 100%;
}
@media all and (max-width: 980px) {
  div.customHeaderComponent div.center {
    display: none;
  }
}
div.customHeaderComponent div.center ul.navlinks {
  display: flex;
  align-items: center;
  height: 100%;
}
div.customHeaderComponent div.center ul.navlinks li.link {
  list-style: none;
  cursor: pointer;
  color: var(--text-color);
  font-weight: 500;
  padding: 0 20px;
  height: 100%;
  display: flex;
  align-items: center;
  transition: 0.3s ease-in-out;
  font-size: 17px;
  position: relative;
}
@media all and (max-width: 1200px) {
  div.customHeaderComponent div.center ul.navlinks li.link {
    padding: 0 11px;
  }
}
div.customHeaderComponent div.center ul.navlinks li.link i {
  margin-inline-start: 10px;
}
div.customHeaderComponent div.center ul.navlinks li.link:last-child {
  margin-inline-end: 0;
}
div.customHeaderComponent div.center ul.navlinks li.link:hover {
  background-color: var(--custom-top-banner-bg-color);
}
div.customHeaderComponent div.center ul.navlinks li.link.active {
  background-color: var(--custom-top-banner-bg-color);
}
div.customHeaderComponent div.center ul.navlinks li.link.allShows:hover ul.childNavs {
  display: block;
}
div.customHeaderComponent div.center ul.navlinks li.link.account ul.childNavs {
  width: 12rem;
}
div.customHeaderComponent div.center ul.navlinks li.link.account:hover ul.childNavs {
  display: block;
}
div.customHeaderComponent div.center ul.navlinks li.link.products:hover ul.childNavs {
  display: block;
}
div.customHeaderComponent div.center ul.navlinks li.link ul.childNavs {
  position: absolute;
  top: 100%;
  width: 15rem;
  background-color: var(--custom-header-bg-color);
  z-index: 99;
  inset-inline-start: 0;
  overflow-y: scroll;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
  display: none;
  animation: fadeIn 0.4s ease-in-out;
}
div.customHeaderComponent div.center ul.navlinks li.link ul.childNavs::-webkit-scrollbar {
  display: none;
}
div.customHeaderComponent div.center ul.navlinks li.link ul.childNavs.scroll {
  height: 400px;
  -ms-overflow-style: unset; /* IE and Edge */
  scrollbar-width: unset; /* Firefox */
}
div.customHeaderComponent div.center ul.navlinks li.link ul.childNavs.scroll::-webkit-scrollbar {
  display: block;
}
div.customHeaderComponent div.center ul.navlinks li.link ul.childNavs.scroll::-webkit-scrollbar {
  width: 5px;
  border-radius: 30px;
}
div.customHeaderComponent div.center ul.navlinks li.link ul.childNavs.scroll::-webkit-scrollbar-thumb {
  background-color: var(--custom-top-banner-bg-color);
  border-radius: 30px;
}
div.customHeaderComponent div.center ul.navlinks li.link ul.childNavs.scroll::-webkit-scrollbar-track {
  background-color: var(--custom-header-bg-color);
}
div.customHeaderComponent div.center ul.navlinks li.link ul.childNavs li.childLink {
  border: 1px solid var(--white-color);
  border-right: none;
  border-left: none;
  border-top: none;
  padding: 10px 10px;
}
div.customHeaderComponent div.center ul.navlinks li.link ul.childNavs li.childLink:last-child {
  border-bottom: none;
}
div.customHeaderComponent div.center ul.navlinks li.link ul.childNavs li.childLink:hover {
  background-color: var(--custom-top-banner-bg-color);
}
div.customHeaderComponent div.right {
  height: 100%;
  background-color: #ee0909;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 150px;
  cursor: pointer;
}
@media all and (max-width: 1200px) {
  div.customHeaderComponent div.right {
    width: 130px;
  }
}
div.customHeaderComponent div.right div.liveLogo {
  width: 35px;
}
div.customHeaderComponent div.right div.liveLogo img {
  display: block;
  width: 100%;
}
div.customHeaderComponent div.right span.live {
  font-weight: 900;
  font-size: 22px;
  color: var(--white-color);
}
@media all and (max-width: 1200px) {
  div.customHeaderComponent div.right span.live {
    font-size: 18px;
  }
}
div.customHeaderComponent div.hambergurMenu {
  display: none;
}
div.customHeaderComponent div.hambergurMenu.showMenu {
  background-color: var(--white-color);
  position: absolute;
  top: 100%;
  width: 100%;
}
@media all and (max-width: 980px) {
  div.customHeaderComponent div.hambergurMenu.showMenu {
    display: block;
  }
}
div.customHeaderComponent div.hambergurMenu.showMenu ul.navlinksResponsive li.link {
  padding: 10px 15px;
}
div.customHeaderComponent div.hambergurMenu.showMenu ul.navlinksResponsive li.link.active {
  background-color: var(--custom-top-banner-bg-color);
}
div.customHeaderComponent div.hambergurMenu.showMenu ul.navlinksResponsive li.link.active span {
  color: var(--white-color);
}
div.customHeaderComponent div.hambergurMenu.showMenu ul.navlinksResponsive li.link.active ul.childLinks {
  display: block;
}
div.customHeaderComponent div.hambergurMenu.showMenu ul.navlinksResponsive li.link.active div.expandLink i {
  color: var(--white-color);
}
div.customHeaderComponent div.hambergurMenu.showMenu ul.navlinksResponsive li.link:hover {
  background-color: var(--custom-top-banner-bg-color);
}
div.customHeaderComponent div.hambergurMenu.showMenu ul.navlinksResponsive li.link:hover span {
  color: var(--white-color);
}
div.customHeaderComponent div.hambergurMenu.showMenu ul.navlinksResponsive li.link:hover div.expandLink i {
  color: var(--white-color);
}
div.customHeaderComponent div.hambergurMenu.showMenu ul.navlinksResponsive li.link span {
  color: var(--custom-top-banner-bg-color);
  font-weight: 600;
}
div.customHeaderComponent div.hambergurMenu.showMenu ul.navlinksResponsive li.link div.expandLink {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
div.customHeaderComponent div.hambergurMenu.showMenu ul.navlinksResponsive li.link ul.childLinks {
  overflow-y: scroll;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
  animation: fadeIn 0.4s ease-in-out;
  display: none;
}
div.customHeaderComponent div.hambergurMenu.showMenu ul.navlinksResponsive li.link ul.childLinks::-webkit-scrollbar {
  display: none;
}
div.customHeaderComponent div.hambergurMenu.showMenu ul.navlinksResponsive li.link ul.childLinks.scroll {
  height: 200px;
  -ms-overflow-style: unset; /* IE and Edge */
  scrollbar-width: unset; /* Firefox */
}
div.customHeaderComponent div.hambergurMenu.showMenu ul.navlinksResponsive li.link ul.childLinks.scroll::-webkit-scrollbar {
  display: block;
}
div.customHeaderComponent div.hambergurMenu.showMenu ul.navlinksResponsive li.link ul.childLinks.scroll::-webkit-scrollbar {
  width: 5px;
  border-radius: 30px;
}
div.customHeaderComponent div.hambergurMenu.showMenu ul.navlinksResponsive li.link ul.childLinks.scroll::-webkit-scrollbar-thumb {
  background-color: var(--custom-header-bg-color);
  border-radius: 30px;
}
div.customHeaderComponent div.hambergurMenu.showMenu ul.navlinksResponsive li.link ul.childLinks.scroll::-webkit-scrollbar-track {
  background-color: var(--custom-top-banner-bg-color);
}
div.customHeaderComponent div.hambergurMenu.showMenu ul.navlinksResponsive li.link ul.childLinks li.childLink {
  padding: 10px 15px;
  border-bottom: 1px solid var(--white-color);
  background-color: var(--custom-top-banner-bg-color);
}
div.customHeaderComponent div.hambergurMenu.showMenu ul.navlinksResponsive li.link ul.childLinks li.childLink:first-child {
  padding: 20px 15px 10px;
}
div.customHeaderComponent div.hambergurMenu.showMenu ul.navlinksResponsive li.link ul.childLinks li.childLink:last-child {
  border-bottom: none;
}
div.customHeaderComponent div.hambergurMenu.showMenu ul.navlinksResponsive li.link ul.childLinks li.childLink span {
  color: var(--white-color);
}

div.emailForm h2.emailSignInCloseButton {
  text-align: end !important;
  width: 100%;
  background: transparent;
}
div.emailForm h2.emailSignInCloseButton .closeButton {
  background: none;
  border: none;
  padding: 0;
  cursor: pointer;
}
div.emailForm div.MuiPaper-root {
  background-color: var(--bg-color);
  padding: 30px;
  width: 33% !important;
}
@media all and (max-width: 1400px) {
  div.emailForm div.MuiPaper-root {
    width: 38% !important;
  }
}
@media all and (max-width: 1200px) {
  div.emailForm div.MuiPaper-root {
    width: 45% !important;
  }
}
@media all and (max-width: 980px) {
  div.emailForm div.MuiPaper-root {
    width: 55% !important;
  }
}
@media all and (max-width: 768px) {
  div.emailForm div.MuiPaper-root {
    width: 65% !important;
  }
}
@media all and (max-width: 640px) {
  div.emailForm div.MuiPaper-root {
    width: 85% !important;
  }
}
@media all and (max-width: 480px) {
  div.emailForm div.MuiPaper-root {
    width: 100% !important;
    padding: 10px 0;
    margin: 20px !important;
  }
}
div.emailForm div.MuiPaper-root h1 {
  width: 110px;
  margin: 0 auto 10px;
}
div.emailForm div.MuiPaper-root h1 img {
  display: block;
  width: 100%;
}
div.emailForm div.MuiPaper-root h2 {
  text-align: center;
  color: var(--text-color);
}
div.emailForm div.MuiPaper-root form label {
  display: block;
  color: var(--text-color);
  font-size: 14px;
  margin-bottom: 6px;
}
div.emailForm div.MuiPaper-root form input {
  display: block;
  padding: 12px 20px;
  outline: none;
  border: none;
  border-radius: 5px;
  width: 100%;
  margin-bottom: 10px;
}
div.emailForm div.MuiPaper-root form div.buttonContainer {
  display: flex;
  padding: 15px 20px;
  border-radius: 4px;
  align-items: center;
  margin-bottom: 15px;
  cursor: pointer;
  transition: 0.2s ease-in-out;
  justify-content: center;
  position: relative;
}
@media all and (max-width: 480px) {
  div.emailForm div.MuiPaper-root form div.buttonContainer {
    padding: 10px 1px;
  }
}
div.emailForm div.MuiPaper-root form div.buttonContainer:hover div.background {
  transform: scale(1.0476190476, 1.0357142857);
}
div.emailForm div.MuiPaper-root form div.buttonContainer div.background {
  background: var(--button-color);
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border-radius: 4px;
  transition: 0.1s ease-in-out;
}
div.emailForm div.MuiPaper-root form div.buttonContainer button {
  background: transparent;
  font-size: 15px;
  color: var(--white-color);
  font-weight: 600;
  z-index: 9;
  cursor: pointer;
}

div.verifyEmailModal div.MuiPaper-root {
  background-color: var(--bg-color);
  padding: 20px;
  width: 33%;
}
@media all and (max-width: 1400px) {
  div.verifyEmailModal div.MuiPaper-root {
    width: 38%;
  }
}
@media all and (max-width: 1200px) {
  div.verifyEmailModal div.MuiPaper-root {
    width: 45%;
  }
}
@media all and (max-width: 980px) {
  div.verifyEmailModal div.MuiPaper-root {
    width: 55%;
  }
}
@media all and (max-width: 768px) {
  div.verifyEmailModal div.MuiPaper-root {
    width: 65%;
  }
}
@media all and (max-width: 640px) {
  div.verifyEmailModal div.MuiPaper-root {
    width: 85%;
  }
}
@media all and (max-width: 480px) {
  div.verifyEmailModal div.MuiPaper-root {
    width: 95%;
    padding: 0;
    margin: 20px !important;
  }
}
div.verifyEmailModal div.MuiPaper-root div.top {
  text-align: center;
}
div.verifyEmailModal div.MuiPaper-root div.top svg {
  width: 70px !important;
  height: 70px !important;
}
@media all and (max-width: 480px) {
  div.verifyEmailModal div.MuiPaper-root div.top svg {
    width: 50px !important;
    height: 50px !important;
  }
}
div.verifyEmailModal div.MuiPaper-root div.bottom {
  color: var(--text-color);
  text-align: center;
}
div.verifyEmailModal div.MuiPaper-root div.bottom div.heading h1 {
  font-size: 22px;
  margin-bottom: 10px;
}
@media all and (max-width: 480px) {
  div.verifyEmailModal div.MuiPaper-root div.bottom div.heading h1 {
    font-size: 18px;
  }
}
div.verifyEmailModal div.MuiPaper-root div.bottom p {
  font-size: 15px;
}
@media all and (max-width: 480px) {
  div.verifyEmailModal div.MuiPaper-root div.bottom p {
    font-size: 14px;
  }
}
div.verifyEmailModal div.MuiPaper-root div.bottom p span {
  color: rgb(100, 180, 241);
}
@media all and (max-width: 480px) {
  div.verifyEmailModal div.MuiPaper-root div.bottom p span {
    font-size: 14px;
  }
}

div.loginRequestModal {
  text-align: center;
}
div.loginRequestModal div.MuiPaper-root {
  background-color: var(--bg-color);
  padding: 30px;
  width: 33%;
}
@media all and (max-width: 1400px) {
  div.loginRequestModal div.MuiPaper-root {
    width: 38%;
  }
}
@media all and (max-width: 1200px) {
  div.loginRequestModal div.MuiPaper-root {
    width: 45%;
  }
}
@media all and (max-width: 980px) {
  div.loginRequestModal div.MuiPaper-root {
    width: 55%;
  }
}
@media all and (max-width: 768px) {
  div.loginRequestModal div.MuiPaper-root {
    width: 65%;
  }
}
@media all and (max-width: 640px) {
  div.loginRequestModal div.MuiPaper-root {
    width: 85%;
  }
}
@media all and (max-width: 480px) {
  div.loginRequestModal div.MuiPaper-root {
    width: 95%;
    padding: 0;
    margin: 20px !important;
  }
}
div.loginRequestModal div.MuiPaper-root span.MuiSkeleton-root {
  background-color: rgba(255, 255, 255, 0.11) !important;
}
div.loginRequestModal div.MuiPaper-root span.MuiSkeleton-root::after {
  background: linear-gradient(90deg, transparent, rgba(0, 0, 0, 0.24), transparent) !important;
}
div.loginRequestModal h4 {
  font-size: 17px;
  margin-bottom: 8px;
}
div.loginRequestModal div.requestDetails {
  background-color: var(--secondary-bg-color);
  padding: 15px 70px 15px 10px;
  border-radius: 4px;
  margin-bottom: 20px;
}
@media all and (max-width: 480px) {
  div.loginRequestModal div.requestDetails {
    padding: 15px 13px 15px 10px;
  }
}
div.loginRequestModal div.requestDetails div.oneRow {
  display: flex;
  justify-content: space-between;
}
div.loginRequestModal div.requestDetails div.oneRow span {
  color: var(--white-color);
  margin-bottom: 8px;
  font-size: 15px;
}
div.loginRequestModal div.requestDetails div.oneRow span.label {
  font-style: italic;
}
div.loginRequestModal p {
  color: var(--text-color);
  margin-bottom: 10px;
  font-size: 14px;
}
div.loginRequestModal div.buttonContainer {
  display: flex;
  padding: 15px 20px;
  border-radius: 4px;
  align-items: center;
  margin-bottom: 15px;
  cursor: pointer;
  transition: 0.2s ease-in-out;
  justify-content: center;
  position: relative;
}
@media all and (max-width: 480px) {
  div.loginRequestModal div.buttonContainer {
    padding: 10px 1px;
  }
}
div.loginRequestModal div.buttonContainer:hover div.background {
  transform: scale(1.0476190476, 1.0357142857);
}
div.loginRequestModal div.buttonContainer div.background {
  background: var(--button-color);
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border-radius: 4px;
  transition: 0.1s ease-in-out;
}
div.loginRequestModal div.buttonContainer button {
  background: transparent;
  font-size: 15px;
  color: var(--white-color);
  font-weight: 600;
  z-index: 9;
  cursor: pointer;
}

div.appLoginModal div.MuiPaper-root {
  background-color: var(--bg-color);
  padding: 20px;
  width: 33% !important;
}
@media all and (max-width: 1400px) {
  div.appLoginModal div.MuiPaper-root {
    width: 38% !important;
  }
}
@media all and (max-width: 1200px) {
  div.appLoginModal div.MuiPaper-root {
    width: 45% !important;
  }
}
@media all and (max-width: 980px) {
  div.appLoginModal div.MuiPaper-root {
    width: 55% !important;
  }
}
@media all and (max-width: 768px) {
  div.appLoginModal div.MuiPaper-root {
    width: 65% !important;
  }
}
@media all and (max-width: 640px) {
  div.appLoginModal div.MuiPaper-root {
    width: 85% !important;
  }
}
@media all and (max-width: 480px) {
  div.appLoginModal div.MuiPaper-root {
    width: 95% !important;
    padding: 0;
    margin: 20px !important;
  }
}
div.appLoginModal div.MuiPaper-root p {
  color: var(--text-color);
  text-align: center;
  font-size: 16px;
  margin-bottom: 10px;
}
@media all and (max-width: 480px) {
  div.appLoginModal div.MuiPaper-root p {
    font-size: 14px;
  }
}
div.appLoginModal div.MuiPaper-root div.buttonContainer {
  display: flex;
  padding: 15px 20px;
  border-radius: 4px;
  align-items: center;
  margin-bottom: 15px;
  cursor: pointer;
  transition: 0.2s ease-in-out;
  justify-content: center;
  position: relative;
}
@media all and (max-width: 480px) {
  div.appLoginModal div.MuiPaper-root div.buttonContainer {
    padding: 10px 1px;
  }
}
div.appLoginModal div.MuiPaper-root div.buttonContainer:hover div.background {
  transform: scale(1.0476190476, 1.0357142857);
}
div.appLoginModal div.MuiPaper-root div.buttonContainer div.background {
  background: var(--button-color);
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border-radius: 4px;
  transition: 0.1s ease-in-out;
}
div.appLoginModal div.MuiPaper-root div.buttonContainer button {
  background: transparent;
  font-size: 15px;
  color: var(--white-color);
  font-weight: 600;
  z-index: 9;
  cursor: pointer;
}
div.appLoginModal div.MuiPaper-root span {
  color: var(--white-color);
  text-align: center;
  font-size: 14px;
  display: block;
}

div.magazineCardContainer {
  width: 100%;
  position: relative;
}
div.magazineCardContainer div.premiumStatus div.premium {
  position: absolute;
  top: 6px;
  left: 6px;
  z-index: 8;
  background-color: rgba(8, 25, 43, 0.65);
  width: 35px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 3px;
}
@media all and (max-width: 1200px) {
  div.magazineCardContainer div.premiumStatus div.premium {
    width: 26px;
  }
}
div.magazineCardContainer div.premiumStatus div.premium svg {
  width: 18px !important;
  height: 18px !important;
}
@media all and (max-width: 1200px) {
  div.magazineCardContainer div.premiumStatus div.premium svg {
    width: 12px !important;
    heigth: 12px !important;
  }
}
div.magazineCardContainer.showContents {
  cursor: pointer;
}
div.magazineCardContainer.showContents:hover div.imageContainer div.extraActions {
  visibility: visible;
  animation: fadeIn 0.2s ease-in-out forwards;
}
div.magazineCardContainer.showContents:hover div.imageContainer img {
  transform: scale(1.1);
}
div.magazineCardContainer.showContents:hover div.metaData h1.title {
  color: var(--text-hover);
}
div.magazineCardContainer div.imageContainer {
  width: 100%;
  display: block;
  position: relative;
  overflow: hidden;
  border-radius: 3px;
}
div.magazineCardContainer div.imageContainer img {
  width: 100%;
  display: block;
  border-radius: 3px;
  transition: 0.2s ease-in-out;
}
div.magazineCardContainer div.imageContainer img.landscape {
  min-height: 130px;
  background: black;
}
div.magazineCardContainer div.imageContainer img.portrait {
  min-height: 420px;
  background: black;
}
div.magazineCardContainer div.imageContainer div.continueWatchingBar {
  position: absolute;
  bottom: 0;
  height: 6px;
  background-color: var(--text-color);
  width: 100%;
}
div.magazineCardContainer div.imageContainer div.continueWatchingBar div.line {
  height: 100%;
  background-color: var(--button-hover);
}
div.magazineCardContainer div.imageContainer div.extraActions {
  visibility: hidden;
  animation: fadeOut 0.2s ease-in-out forwards;
}
div.magazineCardContainer div.imageContainer div.extraActions div.showCardOverlay {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  background-color: var(--overlay);
  z-index: 8;
}
div.magazineCardContainer div.imageContainer div.extraActions div.playIconContainer {
  position: absolute;
  z-index: 9;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border: 3px solid var(--text-color);
  display: flex;
  justify-content: center;
  align-items: center;
  width: 60px;
  height: 60px;
  border-radius: 50%;
  transition: 0.3s ease-in-out;
  cursor: pointer;
}
@media all and (min-width: 320px) {
  div.magazineCardContainer div.imageContainer div.extraActions div.playIconContainer {
    width: 30px;
    height: 30px;
  }
  div.magazineCardContainer div.imageContainer div.extraActions div.playIconContainer svg {
    width: 13px !important;
    height: 25px !important;
  }
}
@media all and (min-width: 320px) {
  div.magazineCardContainer div.imageContainer div.extraActions div.playIconContainer {
    width: 50px;
    height: 50px;
  }
}
div.magazineCardContainer div.imageContainer div.extraActions div.playIconContainer:hover {
  border-color: var(--text-hover);
}
div.magazineCardContainer div.imageContainer div.extraActions div.playIconContainer:hover svg {
  color: var(--text-hover);
}
div.magazineCardContainer div.imageContainer div.extraActions div.playIconContainer svg {
  width: 13px !important;
  height: 13px !important;
  color: var(--text-color);
  transition: 0.3s ease-in-out;
}
@media all and (min-width: 320px) {
  div.magazineCardContainer div.imageContainer div.extraActions div.playIconContainer svg {
    width: 13px !important;
    height: 13px !important;
  }
}
@media all and (min-width: 480px) {
  div.magazineCardContainer div.imageContainer div.extraActions div.playIconContainer svg {
    width: 25px !important;
    height: 18px !important;
  }
}
div.magazineCardContainer div.imageContainer div.extraActions div.addToList {
  position: absolute;
  bottom: 3px;
  width: 96%;
  z-index: 9;
  left: 50%;
  border-radius: 8px;
  transform: translateX(-50%);
  padding: 6px;
  text-align: center;
  box-shadow: rgb(62, 1, 1) 0px 16px 24px;
  background-color: rgb(49, 46, 4);
  -webkit-backdrop-filter: blur(8px);
          backdrop-filter: blur(8px);
}
@media all and (min-width: 320px) {
  div.magazineCardContainer div.imageContainer div.extraActions div.addToList {
    padding: 0;
    border-radius: 2px;
    width: 97%;
  }
  div.magazineCardContainer div.imageContainer div.extraActions div.addToList span {
    font-size: 8px;
  }
}
@media all and (min-width: 480px) {
  div.magazineCardContainer div.imageContainer div.extraActions div.addToList {
    padding: 3px;
  }
  div.magazineCardContainer div.imageContainer div.extraActions div.addToList span {
    font-size: 10px;
  }
}
div.magazineCardContainer div.imageContainer div.extraActions div.addToList:hover span {
  color: var(--text-hover);
}
div.magazineCardContainer div.imageContainer div.extraActions div.addToList span {
  color: var(--text-color);
  font-size: 10px;
  transition: 0.3s ease-in-out;
}
@media all and (min-width: 480px) {
  div.magazineCardContainer div.imageContainer div.extraActions div.addToList span {
    font-size: 11px;
  }
}
div.magazineCardContainer div.metaData {
  margin-top: 10px;
  color: var(--inner-text-color);
}
div.magazineCardContainer div.metaData h1.title {
  color: var(--text-color);
  font-size: 16px;
  transition: 0.3s ease-in-out;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}
@media all and (min-width: 320px) {
  div.magazineCardContainer div.metaData h1.title {
    font-size: 12px;
  }
}
@media all and (min-width: 640px) {
  div.magazineCardContainer div.metaData h1.title {
    font-size: 14px;
  }
}
div.magazineCardContainer div.metaData div.buttons {
  display: flex;
  justify-content: center;
  margin-bottom: 10px;
  margin-top: 10px;
}
div.magazineCardContainer div.metaData div.buttons span.material-symbols-outlined {
  font-weight: 300 !important;
  font-size: 20px !important;
}
div.magazineCardContainer div.metaData div.buttons button {
  color: var(--white-color);
  background-color: var(--button-color);
  width: 50%;
  height: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 2px;
  cursor: pointer;
  font-size: 10px;
}
div.magazineCardContainer div.metaData div.buttons button.play {
  background-color: var(--white-color);
  margin-right: 4px;
  transition: 0.2s ease-in-out;
}
div.magazineCardContainer div.metaData div.buttons button.play:hover {
  background-color: var(--primary-btn-hover);
}
div.magazineCardContainer div.metaData div.buttons button:hover {
  background-color: var(--text-hover);
}
div.magazineCardContainer div.metaData div.buttons div.left {
  display: flex;
  align-items: center;
}
div.magazineCardContainer div.metaData div.datas {
  display: flex;
  justify-content: space-between;
}
@media all and (max-width: 980px) {
  div.magazineCardContainer div.metaData div.datas {
    display: none;
  }
}
div.magazineCardContainer div.metaData div.datas div.left span {
  font-size: 12px;
  margin-right: 6px;
}
div.magazineCardContainer div.metaData div.datas div.left span.duration.showDot::before {
  content: "·";
  margin-right: 6px;
}
div.magazineCardContainer div.metaData div.datas div.left span:last-child {
  margin-right: 0;
}
div.magazineCardContainer div.metaData div.datas div.right span {
  font-size: 14px;
  display: inline-block;
  padding: 1px 6px;
  background-color: rgba(150, 153, 163, 0.32);
  font-size: 12px;
  font-weight: 700;
  color: var(--text-color);
  border-radius: 4px;
}
@media all and (min-width: 320px) {
  div.magazineCardContainer div.metaData div.datas div.right span.certificate {
    font-size: 9px;
  }
}
div.magazineCardContainer div.metaData div.genres {
  font-size: 12px;
  display: flex;
  flex-wrap: wrap;
}
@media all and (min-width: 320px) {
  div.magazineCardContainer div.metaData div.genres {
    display: none;
  }
}
@media all and (min-width: 768px) {
  div.magazineCardContainer div.metaData div.genres {
    display: flex;
  }
}
div.magazineCardContainer div.metaData div.genres span {
  margin-right: 6px;
}
div.magazineCardContainer div.metaData div.genres span:last-child {
  margin-right: 0;
}
div.magazineCardContainer div.metaData div.genres span:first-child::before {
  display: none;
}
div.magazineCardContainer div.metaData div.genres span::before {
  content: "·";
  margin-right: 6px;
}
div.magazineCardContainer div.metaData p.description {
  font-size: 12px;
  overflow: hidden;
  text-overflow: ellipsis;
  height: 30px;
  display: -webkit-box;
  -webkit-line-clamp: 2; /* number of lines to show */
  line-clamp: 2;
  -webkit-box-orient: vertical;
}

div.magazineCardContainer.rtl .premiumStatus .premium {
  left: 0;
  right: 6px;
}
div.magazineCardContainer.rtl .metaData .buttons button.play {
  margin-right: 0;
  margin-left: 4px;
}
div.magazineCardContainer.rtl .metaData .datas div.left span {
  margin-right: 0;
  margin-left: 6px;
}
div.magazineCardContainer.rtl .metaData .datas div.left span.duration.showDot::before {
  margin-right: 0;
  margin-left: 6px;
}
div.magazineCardContainer.rtl .metaData .datas div.left span:last-child {
  margin-left: 0;
}
div.magazineCardContainer.rtl .metaData .genres span {
  margin-right: 0;
  margin-left: 6px;
}
div.magazineCardContainer.rtl .metaData .genres span:last-child {
  margin-left: 0;
}
div.magazineCardContainer.rtl .metaData .genres span::before {
  margin-right: 0;
  margin-left: 6px;
}

div.videoPlayerContainer {
  position: relative;
  padding: 100px 0 0;
}
div.videoPlayerContainer div.arrowContainer {
  width: 5%;
  cursor: pointer;
}
div.videoPlayerContainer div.arrowContainer h3 {
  width: 100%;
  background: transparent;
  cursor: pointer;
}
div.videoPlayerContainer div.arrowContainer h3 svg {
  width: 30px;
  height: 100%;
  fill: white !important;
}
div.videoPlayerContainer div.arrowContainer:hover {
  cursor: pointer;
  transform: scale(1.2);
}
div.videoPlayerContainer div.playerContainer {
  width: 90%;
  margin: 0 auto;
  background-color: var(--black-color);
  position: relative;
}
div.videoPlayerContainer div.playerContainer div.donationContainer div.bannerContainer {
  position: relative;
}
div.videoPlayerContainer div.playerContainer div.donationContainer div.bannerContainer div.overlay {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.6);
}
div.videoPlayerContainer div.playerContainer div.donationContainer div.bannerContainer div.donate {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 70%;
  text-align: center;
}
@media all and (max-width: 1200px) {
  div.videoPlayerContainer div.playerContainer div.donationContainer div.bannerContainer div.donate {
    width: 80%;
  }
}
@media all and (max-width: 768px) {
  div.videoPlayerContainer div.playerContainer div.donationContainer div.bannerContainer div.donate {
    width: 100%;
  }
}
div.videoPlayerContainer div.playerContainer div.donationContainer div.bannerContainer div.donate h1 {
  text-align: center;
  color: var(--white-color);
  font-weight: 400;
  margin-bottom: 10px;
}
@media all and (max-width: 1200px) {
  div.videoPlayerContainer div.playerContainer div.donationContainer div.bannerContainer div.donate h1 {
    font-size: 25px;
  }
}
@media all and (max-width: 980px) {
  div.videoPlayerContainer div.playerContainer div.donationContainer div.bannerContainer div.donate h1 {
    font-size: 20px;
  }
}
@media all and (max-width: 640px) {
  div.videoPlayerContainer div.playerContainer div.donationContainer div.bannerContainer div.donate h1 {
    font-size: 14px;
  }
}
div.videoPlayerContainer div.playerContainer div.donationContainer div.bannerContainer div.donate div.buttonContainer button {
  background-color: var(--button-color);
  color: var(--white-color);
  font-weight: 600;
  padding: 10px 20px;
  border-radius: 6px;
  font-size: 14px;
  cursor: pointer;
  border: 1px solid var(--button-color);
  transition: 0.4s ease-in-out;
  width: 12rem;
}
div.videoPlayerContainer div.playerContainer div.donationContainer div.bannerContainer div.donate div.buttonContainer button:hover {
  background-color: transparent;
}
@media all and (max-width: 640px) {
  div.videoPlayerContainer div.playerContainer div.donationContainer div.bannerContainer div.donate div.buttonContainer button {
    width: 8rem;
    padding: 8px 20px;
    font-size: 13px;
  }
}
@media all and (max-width: 480px) {
  div.videoPlayerContainer div.playerContainer div.donationContainer div.bannerContainer div.donate div.buttonContainer button {
    width: 6rem;
    padding: 5px 20px;
    font-size: 12px;
  }
}
div.videoPlayerContainer div.playerContainer div.donationContainer div.bannerContainer img {
  display: block;
  width: 100%;
}
div.videoPlayerContainer div.multiAudio {
  padding: 10px 0;
  width: 90%;
  margin: 0 auto;
}
div.videoPlayerContainer div.multiAudio div.selectContainer {
  width: 40%;
}
@media all and (max-width: 480px) {
  div.videoPlayerContainer div.multiAudio div.selectContainer {
    width: 100%;
  }
}
div.videoPlayerContainer video {
  height: 100% !important;
  display: block !important;
  width: 100% !important;
}
div.videoPlayerContainer div.upNextContainer {
  width: 90%;
  margin: 20px auto;
}
div.videoPlayerContainer div.upNextContainer h1.heading {
  text-align: left;
  color: var(--text-color);
  display: flex;
  width: 90%;
  margin: 0 auto;
  flex-wrap: wrap;
}
@media all and (max-width: 980px) {
  div.videoPlayerContainer div.upNextContainer h1.heading {
    width: 100%;
  }
}
@media all and (max-width: 768px) {
  div.videoPlayerContainer div.upNextContainer h1.heading {
    font-size: 24px;
  }
}
div.videoPlayerContainer div.upNextContainer div.upNext {
  display: flex;
  width: 90%;
  margin: 0 auto;
  flex-wrap: wrap;
  margin-top: 20px;
}
@media all and (max-width: 980px) {
  div.videoPlayerContainer div.upNextContainer div.upNext {
    width: 100%;
  }
}
div.videoPlayerContainer div.upNextContainer div.upNext div.item {
  width: 32.5%;
  margin-right: 10px;
}
div.videoPlayerContainer div.upNextContainer div.upNext div.item:last-child {
  margin-right: 0;
  margin-bottom: 0;
}
@media all and (max-width: 1200px) {
  div.videoPlayerContainer div.upNextContainer div.upNext div.item {
    width: 32%;
  }
}
@media all and (max-width: 768px) {
  div.videoPlayerContainer div.upNextContainer div.upNext div.item {
    width: 48.5%;
    margin-bottom: 20px;
  }
  div.videoPlayerContainer div.upNextContainer div.upNext div.item:nth-child(2n) {
    margin-right: 0;
  }
}
@media all and (max-width: 480px) {
  div.videoPlayerContainer div.upNextContainer div.upNext div.item {
    width: 100%;
    margin-right: 0;
  }
  div.videoPlayerContainer div.upNextContainer div.upNext div.item:nth-child(2n) {
    margin-right: 0;
  }
}
div.videoPlayerContainer div.upNextContainer div.upNext div.itemPortrait {
  width: 15%;
  margin-right: 10px;
  margin-bottom: 20px;
}
div.videoPlayerContainer div.upNextContainer div.upNext div.itemPortrait:last-child {
  margin-right: 0;
  margin-bottom: 0;
}
@media all and (max-width: 980px) {
  div.videoPlayerContainer div.upNextContainer div.upNext div.itemPortrait {
    width: 20%;
  }
}
@media all and (max-width: 768px) {
  div.videoPlayerContainer div.upNextContainer div.upNext div.itemPortrait {
    width: 23%;
  }
}
@media all and (max-width: 640px) {
  div.videoPlayerContainer div.upNextContainer div.upNext div.itemPortrait {
    width: 30%;
  }
}
@media all and (max-width: 480px) {
  div.videoPlayerContainer div.upNextContainer div.upNext div.itemPortrait {
    width: 46%;
  }
}
@media all and (max-width: 360px) {
  div.videoPlayerContainer div.upNextContainer div.upNext div.itemPortrait {
    width: 45%;
  }
}
div.videoPlayerContainer div.videoMetaData {
  width: 90%;
  margin: 0 auto;
  padding-top: 30px;
}
div.videoPlayerContainer div.videoMetaData div.head {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}
@media all and (max-width: 980px) {
  div.videoPlayerContainer div.videoMetaData div.head {
    flex-direction: column;
    align-items: flex-start;
  }
}
div.videoPlayerContainer div.videoMetaData div.head h1.videoTitle {
  color: var(--text-color);
  font-size: 30px;
}
div.videoPlayerContainer div.videoMetaData div.head div.buttonContainer {
  display: flex;
}
@media all and (max-width: 980px) {
  div.videoPlayerContainer div.videoMetaData div.head div.buttonContainer {
    justify-content: flex-start;
    margin-top: 10px;
  }
}
div.videoPlayerContainer div.videoMetaData div.head div.buttonContainer button {
  background-color: var(--button-color);
  color: var(--white-color);
  font-weight: 600;
  padding: 10px 20px;
  border-radius: 6px;
  font-size: 14px;
  cursor: pointer;
  border: 1px solid var(--button-color);
  transition: 0.4s ease-in-out;
}
div.videoPlayerContainer div.videoMetaData div.head div.buttonContainer button:hover {
  background-color: transparent;
}
div.videoPlayerContainer div.videoMetaData div.head div.buttonContainer button.myList {
  margin-right: 20px;
}
div.videoPlayerContainer div.videoMetaData div.categoriesAndRating {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}
div.videoPlayerContainer div.videoMetaData div.categoriesAndRating div.categories span.category {
  font-size: 16px;
  color: var(--inner-text-color);
  cursor: pointer;
}
div.videoPlayerContainer div.videoMetaData div.categoriesAndRating div.categories span.category::after {
  content: "•";
  margin: 0 5px 0 10px;
}
div.videoPlayerContainer div.videoMetaData div.categoriesAndRating div.categories span.category:last-child::after {
  display: none;
}
div.videoPlayerContainer div.videoMetaData div.categoriesAndRating div.categories span.category:hover {
  text-decoration: underline;
}
div.videoPlayerContainer div.videoMetaData div.categoriesAndRating span.rating {
  margin-left: 10px;
  padding: 2px 6px;
  background-color: var(--grey-color);
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  font-weight: 600;
  border-radius: 3px;
}
div.videoPlayerContainer div.videoMetaData p.description {
  color: var(--inner-text-color);
  margin-bottom: 20px;
}
div.videoPlayerContainer div.videoMetaData div.extraInfoContainer div.metaItem {
  margin-bottom: 20px;
}
div.videoPlayerContainer div.videoMetaData div.extraInfoContainer div.metaItem h6.subHeading {
  font-size: 16px;
  color: var(--white-color);
  margin-bottom: 10px;
}
div.videoPlayerContainer div.videoMetaData div.extraInfoContainer div.metaItem div.items {
  width: 30%;
}
div.videoPlayerContainer div.videoMetaData div.extraInfoContainer div.metaItem div.items.oneRow {
  display: flex;
  width: 60%;
  flex-wrap: wrap;
}
div.videoPlayerContainer div.videoMetaData div.extraInfoContainer div.metaItem div.items.oneRow span.item {
  margin-right: 10px;
  margin-bottom: 0;
  display: block;
  color: var(--inner-text-color);
  cursor: pointer;
}
div.videoPlayerContainer div.videoMetaData div.extraInfoContainer div.metaItem div.items.oneRow span.item:last-child span.comma {
  display: none;
}
div.videoPlayerContainer div.videoMetaData div.extraInfoContainer div.metaItem div.items span.itemInfo {
  cursor: pointer;
}
div.videoPlayerContainer div.videoMetaData div.extraInfoContainer div.metaItem div.items div.item {
  display: flex;
  justify-content: space-between;
  color: var(--inner-text-color);
  margin-bottom: 6px;
}
div.videoPlayerContainer div.videoMetaData div.extraInfoContainer div.metaItem div.items div.item:last-child {
  margin-bottom: 0;
}
div.videoPlayerContainer span.line {
  height: 1px;
  background-color: var(--border-color);
  width: 100%;
  display: block;
  margin-top: 70px;
}
div.videoPlayerContainer div.files {
  width: 90%;
  margin: 0 auto;
}
div.videoPlayerContainer div.commentsContainer {
  width: 70%;
  margin: 0 auto;
  padding: 60px 0;
}
@media all and (max-width: 1200px) {
  div.videoPlayerContainer div.commentsContainer {
    width: 80%;
  }
}
@media all and (max-width: 768px) {
  div.videoPlayerContainer div.commentsContainer {
    width: 90%;
  }
}
@media all and (max-width: 640px) {
  div.videoPlayerContainer div.commentsContainer {
    width: 100%;
  }
}
div.videoPlayerContainer div.commentsContainer div.top div.head {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid var(--border-color);
  padding-bottom: 10px;
}
div.videoPlayerContainer div.commentsContainer div.top div.head span.commentsCount {
  color: var(--white-color);
  font-size: 22px;
}
@media all and (max-width: 640px) {
  div.videoPlayerContainer div.commentsContainer div.top div.head span.commentsCount {
    font-size: 16px;
  }
}
div.videoPlayerContainer div.commentsContainer div.top div.head div.sortContainer select {
  background-color: transparent;
  border: 1px solid var(--border-color);
  padding: 10px 15px;
  color: var(--white-color);
  font-size: 16px;
  border-radius: 6px;
}
div.videoPlayerContainer div.commentsContainer div.top div.head div.sortContainer select option {
  color: var(--black-color);
}
div.videoPlayerContainer div.commentsContainer div.top div.textContainer {
  display: flex;
  padding: 40px 0 20px;
  border-bottom: 1px solid var(--border-color);
}
div.videoPlayerContainer div.commentsContainer div.top div.textContainer div.imageContainer {
  margin-right: 20px;
  width: 45px;
  height: 45px;
}
div.videoPlayerContainer div.commentsContainer div.top div.textContainer div.imageContainer img {
  height: 100%;
  width: 100%;
  border-radius: 50%;
}
div.videoPlayerContainer div.commentsContainer div.top div.textContainer div.imageContainer span.nameAvatar {
  width: 45px;
  height: 45px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--white-color);
  background-color: green;
  border-radius: 50%;
}
div.videoPlayerContainer div.commentsContainer div.top div.textContainer div.inputContainer {
  width: 90%;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}
div.videoPlayerContainer div.commentsContainer div.top div.textContainer div.inputContainer textarea {
  display: block;
  width: 100%;
  height: 45px;
  padding: 10px 15px;
  border-radius: 5px;
  margin-bottom: 20px;
  border: 1px solid #e1e8ed;
  resize: vertical;
}
div.videoPlayerContainer div.commentsContainer div.top div.textContainer div.inputContainer textarea:focus-visible {
  border: 3px solid var(--button-color) !important;
  outline: none;
}
div.videoPlayerContainer div.commentsContainer div.top div.textContainer div.inputContainer div.upload-featue {
  display: flex;
  justify-content: space-between;
  width: 100%;
}
div.videoPlayerContainer div.commentsContainer div.top div.textContainer div.inputContainer div.upload-featue div.selectedImage {
  width: 20%;
  height: 30%;
  margin-bottom: 20px;
}
div.videoPlayerContainer div.commentsContainer div.top div.textContainer div.inputContainer div.upload-featue div.selectedImage img {
  max-width: 50%;
  border-radius: 5px;
  height: auto;
}
div.videoPlayerContainer div.commentsContainer div.top div.textContainer div.inputContainer div.upload-featue div.selectedImage button.removeButton {
  position: absolute;
  background: white;
  border: none;
  border-radius: 50%;
  cursor: pointer;
  font-size: 20px;
  color: var(--button-color);
  width: 20px;
  height: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  transition: background-color 0.3s ease;
}
div.videoPlayerContainer div.commentsContainer div.top div.textContainer div.inputContainer div.upload-featue div.selectedImage button.removeButton:hover {
  background-color: #f2f2f2;
}
div.videoPlayerContainer div.commentsContainer div.top div.textContainer div.inputContainer div.upload-featue div.selectedImage button.removeButton svg {
  width: 15px;
  height: 15px;
  fill: var(--button-color);
}
div.videoPlayerContainer div.commentsContainer div.top div.textContainer div.inputContainer div.upload-featue div.actions {
  display: flex;
  justify-content: flex-end;
  align-items: flex-start;
}
div.videoPlayerContainer div.commentsContainer div.top div.textContainer div.inputContainer div.upload-featue div.actions .buttonContainer {
  display: flex;
  align-items: center;
  margin-right: 10px;
}
div.videoPlayerContainer div.commentsContainer div.top div.textContainer div.inputContainer div.upload-featue div.actions .buttonContainer label.customFileUpload {
  color: white;
  margin-right: 10px;
  font-weight: 600;
  padding: 7px 20px;
  border-radius: 6px;
  font-size: 14px;
  cursor: pointer;
  display: flex;
  align-items: center;
  border: 1px solid #1da1f2;
  transition: 0.4s ease-in-out;
}
div.videoPlayerContainer div.commentsContainer div.top div.textContainer div.inputContainer div.upload-featue div.actions .buttonContainer label.customFileUpload:hover {
  background-color: transparent;
  color: var(--white-color);
}
div.videoPlayerContainer div.commentsContainer div.top div.textContainer div.inputContainer div.upload-featue div.actions .buttonContainer input[type=file] {
  display: none;
}
div.videoPlayerContainer div.commentsContainer div.top div.textContainer div.inputContainer div.upload-featue div.actions .buttonContainer button.giphyButton {
  background: none;
  color: white;
  font-weight: 600;
  padding: 7px 20px;
  border-radius: 6px;
  font-size: 14px;
  cursor: pointer;
  display: flex;
  align-items: center;
  border: 1px solid #1da1f2;
  transition: 0.4s ease-in-out;
}
div.videoPlayerContainer div.commentsContainer div.top div.textContainer div.inputContainer div.upload-featue div.actions .buttonContainer button.giphyButton:hover {
  background-color: transparent;
  color: var(--white-color);
}
div.videoPlayerContainer div.commentsContainer div.top div.textContainer div.inputContainer div.upload-featue div.actions button.submit {
  background-color: var(--button-color);
  color: var(--white-color);
  font-weight: 600;
  padding: 10px 20px;
  border-radius: 6px;
  font-size: 14px;
  cursor: pointer;
  border: 1px solid var(--button-color);
  transition: 0.4s ease-in-out;
}
div.videoPlayerContainer div.commentsContainer div.top div.textContainer div.inputContainer div.upload-featue div.actions button.submit:hover {
  background-color: transparent;
  color: var(--white-color);
}
@media (max-width: 1200px) {
  div.videoPlayerContainer div.commentsContainer div.top div.textContainer div.inputContainer {
    width: 90%;
  }
  div.videoPlayerContainer div.commentsContainer div.top div.textContainer div.inputContainer textarea {
    height: 40px;
    padding: 8px 12px;
  }
  div.videoPlayerContainer div.commentsContainer div.top div.textContainer div.inputContainer div.upload-featue {
    flex-direction: column;
  }
  div.videoPlayerContainer div.commentsContainer div.top div.textContainer div.inputContainer div.upload-featue .selectedImage {
    width: 100%;
  }
  div.videoPlayerContainer div.commentsContainer div.top div.textContainer div.inputContainer div.upload-featue .actions {
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
  }
  div.videoPlayerContainer div.commentsContainer div.top div.textContainer div.inputContainer div.upload-featue .actions .buttonContainer {
    margin-bottom: 10px;
    width: 100%;
  }
  div.videoPlayerContainer div.commentsContainer div.top div.textContainer div.inputContainer div.upload-featue .actions .buttonContainer label.customFileUpload,
  div.videoPlayerContainer div.commentsContainer div.top div.textContainer div.inputContainer div.upload-featue .actions .buttonContainer button.giphyButton {
    width: 50%;
    margin-bottom: 10px;
    text-align: center;
    justify-content: center;
  }
  div.videoPlayerContainer div.commentsContainer div.top div.textContainer div.inputContainer div.upload-featue .actions button.submit {
    width: 100%;
    text-align: center;
  }
}
@media (max-width: 768px) {
  div.videoPlayerContainer div.commentsContainer div.top div.textContainer div.inputContainer {
    width: 90%;
  }
  div.videoPlayerContainer div.commentsContainer div.top div.textContainer div.inputContainer textarea {
    height: 35px;
    padding: 6px 10px;
  }
  div.videoPlayerContainer div.commentsContainer div.top div.textContainer div.inputContainer div.upload-featue {
    flex-direction: column;
  }
  div.videoPlayerContainer div.commentsContainer div.top div.textContainer div.inputContainer div.upload-featue .selectedImage {
    width: 100%;
  }
  div.videoPlayerContainer div.commentsContainer div.top div.textContainer div.inputContainer div.upload-featue .actions {
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
  }
  div.videoPlayerContainer div.commentsContainer div.top div.textContainer div.inputContainer div.upload-featue .actions .buttonContainer {
    flex-direction: column;
    width: 100%;
    text-align: center;
  }
  div.videoPlayerContainer div.commentsContainer div.top div.textContainer div.inputContainer div.upload-featue .actions .buttonContainer label.customFileUpload,
  div.videoPlayerContainer div.commentsContainer div.top div.textContainer div.inputContainer div.upload-featue .actions .buttonContainer button.giphyButton {
    width: 100%;
    margin-right: 10px;
    margin-bottom: 10px;
    text-align: center;
    justify-content: center;
  }
  div.videoPlayerContainer div.commentsContainer div.top div.textContainer div.inputContainer div.upload-featue .actions button.submit {
    width: 100%;
    text-align: center;
  }
}
@media (max-width: 480px) {
  div.videoPlayerContainer div.commentsContainer div.top div.textContainer div.inputContainer textarea {
    height: 30px;
    padding: 4px 8px;
  }
  div.videoPlayerContainer div.commentsContainer div.top div.textContainer div.inputContainer div.upload-featue {
    flex-direction: column;
  }
  div.videoPlayerContainer div.commentsContainer div.top div.textContainer div.inputContainer div.upload-featue .selectedImage {
    width: 100%;
  }
  div.videoPlayerContainer div.commentsContainer div.top div.textContainer div.inputContainer div.upload-featue .actions {
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
  }
  div.videoPlayerContainer div.commentsContainer div.top div.textContainer div.inputContainer div.upload-featue .actions .buttonContainer {
    flex-direction: column;
    width: 100%;
  }
  div.videoPlayerContainer div.commentsContainer div.top div.textContainer div.inputContainer div.upload-featue .actions .buttonContainer label.customFileUpload,
  div.videoPlayerContainer div.commentsContainer div.top div.textContainer div.inputContainer div.upload-featue .actions .buttonContainer button.giphyButton {
    width: 100%;
    margin-right: 0;
    margin-bottom: 5px;
    text-align: center;
    justify-content: center;
  }
  div.videoPlayerContainer div.commentsContainer div.top div.textContainer div.inputContainer div.upload-featue .actions button.submit {
    width: 100%;
    text-align: center;
  }
}
div.videoPlayerContainer div.commentsContainer div.bottom div.comments div.eachComment {
  border-bottom: 1px solid var(--border-color);
}
div.videoPlayerContainer div.commentsContainer div.bottom div.comments div.eachComment:last-child {
  border: none;
}
div.videoPlayerContainer div.commentsContainer div.bottom div.comments div.eachComment div.containers {
  padding: 40px 0 25px;
}
div.videoPlayerContainer div.commentsContainer div.bottom div.comments div.eachComment div.containers div.replyMessagesContainer {
  padding: 40px 0 0;
}
div.videoPlayerContainer div.commentsContainer div.bottom div.comments div.eachComment div.containers div.replyMessagesContainer div.containers {
  padding: 0 0 0;
  display: flex;
  border: none;
}
div.videoPlayerContainer div.commentsContainer div.bottom div.comments div.eachComment div.containers div.commentItem {
  display: flex;
}
div.videoPlayerContainer div.commentsContainer div.bottom div.comments div.eachComment div.containers div.commentItem:last-child {
  border: none;
}
div.videoPlayerContainer div.commentsContainer div.bottom div.comments div.eachComment div.containers div.commentItem div.left {
  width: 45px;
  margin-right: 20px;
}
div.videoPlayerContainer div.commentsContainer div.bottom div.comments div.eachComment div.containers div.commentItem div.left div.profileImageContainer {
  width: 100%;
  height: 45px;
}
@media all and (max-width: 640px) {
  div.videoPlayerContainer div.commentsContainer div.bottom div.comments div.eachComment div.containers div.commentItem div.left div.profileImageContainer {
    height: 41px;
  }
}
div.videoPlayerContainer div.commentsContainer div.bottom div.comments div.eachComment div.containers div.commentItem div.left div.profileImageContainer img {
  width: 100%;
  display: block;
  height: 100%;
  border-radius: 50%;
}
div.videoPlayerContainer div.commentsContainer div.bottom div.comments div.eachComment div.containers div.commentItem div.left span.nameAvatar {
  width: 100%;
  height: 45px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--white-color);
  background-color: green;
  border-radius: 50%;
}
div.videoPlayerContainer div.commentsContainer div.bottom div.comments div.eachComment div.containers div.commentItem div.right {
  width: 90%;
}
@media all and (max-width: 640px) {
  div.videoPlayerContainer div.commentsContainer div.bottom div.comments div.eachComment div.containers div.commentItem div.right {
    width: 96%;
  }
}
div.videoPlayerContainer div.commentsContainer div.bottom div.comments div.eachComment div.containers div.commentItem div.right h1.name {
  font-size: 16px;
  color: var(--white-color);
}
div.videoPlayerContainer div.commentsContainer div.bottom div.comments div.eachComment div.containers div.commentItem div.right span.time {
  font-size: 13px;
  color: var(--inner-text-color);
  font-weight: 600;
  display: inline-block;
  margin-bottom: 20px;
}
div.videoPlayerContainer div.commentsContainer div.bottom div.comments div.eachComment div.containers div.commentItem div.right p.comment {
  font-size: 16px;
  color: var(--white-color);
  margin-bottom: 20px;
}
div.videoPlayerContainer div.commentsContainer div.bottom div.comments div.eachComment div.containers div.commentItem div.right div.comment-image-container {
  margin-top: 10px;
  margin-bottom: 30px;
  width: 20%;
  height: auto;
}
div.videoPlayerContainer div.commentsContainer div.bottom div.comments div.eachComment div.containers div.commentItem div.right div.comment-image-container img {
  max-width: 50%;
  height: auto;
  border-radius: 8px;
}
div.videoPlayerContainer div.commentsContainer div.bottom div.comments div.eachComment div.containers div.commentItem div.right div.actions {
  display: flex;
}
div.videoPlayerContainer div.commentsContainer div.bottom div.comments div.eachComment div.containers div.commentItem div.right div.actions span.item {
  display: flex;
  align-items: center;
  margin-right: 20px;
  font-size: 11px;
  color: var(--inner-text-color);
  transition: 0.4s ease-in-out;
  cursor: pointer;
}
div.videoPlayerContainer div.commentsContainer div.bottom div.comments div.eachComment div.containers div.commentItem div.right div.actions span.item:hover {
  opacity: 0.6;
}
div.videoPlayerContainer div.commentsContainer div.bottom div.comments div.eachComment div.containers div.commentItem div.right div.actions span.item:last-child {
  margin-right: 0;
}
div.videoPlayerContainer div.commentsContainer div.bottom div.comments div.eachComment div.containers div.commentItem div.right div.actions span.item span svg {
  width: 15px !important;
  height: 15px !important;
  color: rgb(190, 190, 187);
  fill: rgb(190, 190, 187);
  margin-right: 5px;
}
div.videoPlayerContainer div.customModal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}
div.videoPlayerContainer div.customModal .modalContent {
  background: #fff;
  border-radius: 10px;
  max-height: 80%;
  max-width: 90%;
  overflow-y: auto;
  padding: 20px;
  position: relative;
}
div.videoPlayerContainer div.customModal .modalContent .closeButton {
  position: absolute;
  top: -3px;
  right: 5px;
  background: none;
  border: none;
  font-size: 24px;
  cursor: pointer;
}

div.LayoutsContainer {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}
div.LayoutsContainer div.routesContainer {
  flex: 1 0 auto;
}
div.LayoutsContainer div.resubscriptionInfo {
  display: flex;
  align-items: center;
  z-index: 99;
  width: 100%;
  height: auto;
  position: fixed;
  bottom: 0;
  background: #fff;
}
div.LayoutsContainer div.resubscriptionInfo .resubscribeMessage {
  width: 85%;
  background: #fff;
  color: red;
  padding: 5px;
  text-align: center;
  font-weight: 600;
  margin: 5px;
}
div.LayoutsContainer div.resubscriptionInfo .resubscribeButton {
  width: 15%;
  background-color: #000;
  color: #fff;
  padding: 5px;
  text-align: center;
  cursor: pointer;
  font-weight: 600;
  margin: 2px;
  transition: background-color 0.3s ease, filter 0.3s ease;
  display: flex;
  align-items: center;
  justify-content: center;
}
div.LayoutsContainer div.resubscriptionInfo .resubscribeButton:hover {
  filter: brightness(2);
}
@media all and (max-width: 768px) {
  div.LayoutsContainer div.resubscriptionInfo .resubscribeMessage {
    font-size: 10px;
    width: 100%;
  }
  div.LayoutsContainer div.resubscriptionInfo .resubscribeButton {
    width: 40%;
    font-size: small;
  }
}
@media all and (max-width: 640px) {
  div.LayoutsContainer div.resubscriptionInfo {
    flex-direction: column;
  }
  div.LayoutsContainer div.resubscriptionInfo .resubscribeButton {
    width: 85%;
  }
}

div.showallPage {
  padding: 100px 0 40px;
}
div.showallPage div.topSection {
  padding-bottom: 60px;
  position: absolute;
  top: 84px;
  z-index: 8;
  inset-inline-start: 60px;
}
@media all and (max-width: 640px) {
  div.showallPage div.topSection {
    top: 50px;
    z-index: 8;
    inset-inline-start: 20px;
  }
}
div.showallPage div.topSection h1.heading {
  font-size: 26px;
  margin-bottom: 10px;
  margin-top: 20px;
  color: var(--text-color);
}
@media all and (max-width: 640px) {
  div.showallPage div.topSection h1.heading {
    font-size: 22px;
    margin-top: 50px;
  }
}
div.showallPage div.topSection p.desc {
  font-size: 18px;
  color: var(--inner-text-color);
}
@media all and (max-width: 640px) {
  div.showallPage div.topSection p.desc {
    font-size: 14px;
  }
}
div.showallPage div.bgImageContainer {
  width: 100%;
  display: block;
  height: 508px;
  position: relative;
}
@media all and (max-width: 768px) {
  div.showallPage div.bgImageContainer {
    height: 556px;
  }
}
div.showallPage div.bgImageContainer img {
  display: block;
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
}
@media all and (max-width: 768px) {
  div.showallPage div.bgImageContainer img {
    -o-object-fit: cover;
       object-fit: cover;
  }
}
div.showallPage div.bottomGradient {
  position: absolute;
  bottom: 0;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  z-index: 1;
  height: 100%;
  background-position: top;
  background-size: cover;
  background-repeat: no-repeat;
  background: linear-gradient(78.83deg, rgb(16, 20, 31) 0%, rgba(16, 20, 31, 0) 61.46%), linear-gradient(360deg, rgb(16, 20, 31) 0%, rgba(16, 20, 31, 0) 48.44%, rgba(16, 20, 31, 0.4) 100%);
}
div.showallPage div.bottomSection {
  margin-top: 40px;
}
div.showallPage div.bottomSection div.itemsContainer {
  display: flex;
  flex-wrap: wrap;
}
div.showallPage div.bottomSection div.itemsContainer div.items.rowCount3 {
  width: 32.5%;
  margin-inline-end: 13px;
  margin-bottom: 30px;
}
div.showallPage div.bottomSection div.itemsContainer div.items.rowCount3:nth-child(3n) {
  margin-inline-end: 0;
}
@media all and (max-width: 1200px) {
  div.showallPage div.bottomSection div.itemsContainer div.items.rowCount3 {
    width: 32%;
  }
}
@media all and (min-width: 981px) {
  div.showallPage div.bottomSection div.itemsContainer div.items.rowCount3.landscape:hover div.showCardContainer .square {
    position: absolute;
    z-index: 9999999;
    transform: translateX(60px) translateY(-60px) scale(1.1);
  }
  div.showallPage div.bottomSection div.itemsContainer div.items.rowCount3.landscape:hover div.showCardContainer .square div.metaData {
    display: block;
    visibility: visible !important;
    animation: fadeIn 0.3s ease-in-out;
  }
  div.showallPage div.bottomSection div.itemsContainer div.items.rowCount3.landscape:hover:nth-child(3n) div.showCardContainer div.square {
    transform: translateX(-60px) translateY(-60px) scale(1.1);
  }
}
@media all and (max-width: 980px) {
  div.showallPage div.bottomSection div.itemsContainer div.items.rowCount3.landscape {
    width: 24.3%;
    margin-inline-end: 6px;
    margin-bottom: 40px;
  }
  div.showallPage div.bottomSection div.itemsContainer div.items.rowCount3.landscape:nth-child(6n) {
    margin-inline-end: 6px;
  }
  div.showallPage div.bottomSection div.itemsContainer div.items.rowCount3.landscape:nth-child(4n) {
    margin-inline-end: 0;
  }
}
@media all and (max-width: 768px) {
  div.showallPage div.bottomSection div.itemsContainer div.items.rowCount3.landscape {
    width: 32.3%;
  }
  div.showallPage div.bottomSection div.itemsContainer div.items.rowCount3.landscape:nth-child(4n) {
    margin-inline-end: 6px;
  }
  div.showallPage div.bottomSection div.itemsContainer div.items.rowCount3.landscape:nth-child(3n) {
    margin-inline-end: 0;
  }
}
@media all and (max-width: 640px) {
  div.showallPage div.bottomSection div.itemsContainer div.items.rowCount3.landscape {
    width: 48.2%;
  }
  div.showallPage div.bottomSection div.itemsContainer div.items.rowCount3.landscape:nth-child(3n) {
    margin-inline-end: 6px;
  }
  div.showallPage div.bottomSection div.itemsContainer div.items.rowCount3.landscape:nth-child(2n) {
    margin-inline-end: 0;
  }
}
@media all and (max-width: 480px) {
  div.showallPage div.bottomSection div.itemsContainer div.items.rowCount3.landscape {
    width: 100%;
    margin-inline-end: 0;
  }
  div.showallPage div.bottomSection div.itemsContainer div.items.rowCount3.landscape:nth-child(6n) {
    margin-inline-end: 0;
  }
  div.showallPage div.bottomSection div.itemsContainer div.items.rowCount3.landscape:nth-child(4n) {
    margin-inline-end: 0;
  }
  div.showallPage div.bottomSection div.itemsContainer div.items.rowCount3.landscape:nth-child(3n) {
    margin-inline-end: 0;
  }
  div.showallPage div.bottomSection div.itemsContainer div.items.rowCount3.landscape:nth-child(2n) {
    margin-inline-end: 0;
  }
}
@media all and (max-width: 480px) and (max-width: 360px) {
  div.showallPage div.bottomSection div.itemsContainer div.items.rowCount3.landscape {
    width: 100%;
    margin-inline-end: 0;
  }
  div.showallPage div.bottomSection div.itemsContainer div.items.rowCount3.landscape:nth-child(3n) {
    margin-inline-end: 0;
  }
}
@media all and (min-width: 981px) {
  div.showallPage div.bottomSection div.itemsContainer div.items.rowCount3.portrait:hover div.showCardContainer .square {
    position: absolute;
    z-index: 9999999;
    transform: translateX(60px) translateY(-60px) scale(1.2);
  }
  div.showallPage div.bottomSection div.itemsContainer div.items.rowCount3.portrait:hover div.showCardContainer .square div.metaData {
    display: block;
    visibility: visible !important;
    animation: fadeIn 0.3s ease-in-out;
  }
  div.showallPage div.bottomSection div.itemsContainer div.items.rowCount3.portrait:hover:nth-child(3n) div.showCardContainer div.square {
    transform: translateX(-60px) translateY(-60px) scale(1.2);
  }
}
@media all and (min-width: 981px) and (max-width: 1300px) {
  div.showallPage div.bottomSection div.itemsContainer div.items.rowCount3.portrait:hover:nth-child(6n) div.showCardContainer div.square {
    transform: translateX(-60px) translateY(-60px) scale(1.2);
  }
  div.showallPage div.bottomSection div.itemsContainer div.items.rowCount3.portrait:hover:nth-child(7n) div.showCardContainer div.square {
    transform: translateX(60px) translateY(-60px) scale(1.2);
  }
}
@media all and (min-width: 981px) {
  div.showallPage div.bottomSection div.itemsContainer div.items.rowCount3.portrait:hover:last-child div.showCardContainer div.square {
    transform: translateX(-60px) translateY(-60px) scale(1.2);
  }
}
@media all and (max-width: 980px) {
  div.showallPage div.bottomSection div.itemsContainer div.items.rowCount3.portrait {
    width: 24%;
    margin-inline-end: 6px;
    margin-bottom: 40px;
  }
  div.showallPage div.bottomSection div.itemsContainer div.items.rowCount3.portrait:nth-child(5n) {
    margin-inline-end: 6px;
  }
  div.showallPage div.bottomSection div.itemsContainer div.items.rowCount3.portrait:nth-child(4n) {
    margin-inline-end: 0;
  }
  div.showallPage div.bottomSection div.itemsContainer div.items.rowCount3.portrait:nth-child(6n) {
    margin-inline-end: 6px;
  }
}
@media all and (max-width: 768px) {
  div.showallPage div.bottomSection div.itemsContainer div.items.rowCount3.portrait {
    width: 32%;
  }
  div.showallPage div.bottomSection div.itemsContainer div.items.rowCount3.portrait:nth-child(4n) {
    margin-inline-end: 6px;
  }
  div.showallPage div.bottomSection div.itemsContainer div.items.rowCount3.portrait:nth-child(3n) {
    margin-inline-end: 0;
  }
}
@media all and (max-width: 640px) {
  div.showallPage div.bottomSection div.itemsContainer div.items.rowCount3.portrait {
    width: 48%;
  }
  div.showallPage div.bottomSection div.itemsContainer div.items.rowCount3.portrait:nth-child(3n) {
    margin-inline-end: 6px;
  }
  div.showallPage div.bottomSection div.itemsContainer div.items.rowCount3.portrait:nth-child(2n) {
    margin-inline-end: 0;
  }
}
div.showallPage div.bottomSection div.itemsContainer div.items.rowCount4 {
  width: 24%;
  margin-inline-end: 13px;
  margin-bottom: 30px;
}
div.showallPage div.bottomSection div.itemsContainer div.items.rowCount4:nth-child(4n) {
  margin-inline-end: 0;
}
@media all and (max-width: 2600px) {
  div.showallPage div.bottomSection div.itemsContainer div.items.rowCount4 {
    margin-inline-end: 30px;
  }
}
@media all and (max-width: 2500px) {
  div.showallPage div.bottomSection div.itemsContainer div.items.rowCount4 {
    width: 24.5%;
    margin-inline-end: 13px;
  }
}
@media all and (max-width: 2200px) {
  div.showallPage div.bottomSection div.itemsContainer div.items.rowCount4 {
    width: 24.4%;
  }
}
@media all and (max-width: 1768px) {
  div.showallPage div.bottomSection div.itemsContainer div.items.rowCount4 {
    width: 24.3%;
  }
}
@media all and (max-width: 1600px) {
  div.showallPage div.bottomSection div.itemsContainer div.items.rowCount4 {
    width: 24.2%;
  }
}
@media all and (max-width: 1400px) {
  div.showallPage div.bottomSection div.itemsContainer div.items.rowCount4 {
    width: 24.1%;
  }
}
@media all and (max-width: 1200px) {
  div.showallPage div.bottomSection div.itemsContainer div.items.rowCount4 {
    width: 24.5%;
    margin-inline-end: 6px;
  }
}
@media all and (min-width: 981px) {
  div.showallPage div.bottomSection div.itemsContainer div.items.rowCount4.landscape:hover div.showCardContainer .square {
    position: absolute;
    z-index: 9999999;
    transform: translateX(60px) translateY(-60px) scale(1.1);
  }
  div.showallPage div.bottomSection div.itemsContainer div.items.rowCount4.landscape:hover div.showCardContainer .square div.metaData {
    display: block;
    visibility: visible !important;
    animation: fadeIn 0.3s ease-in-out;
  }
  div.showallPage div.bottomSection div.itemsContainer div.items.rowCount4.landscape:hover:nth-child(4n) div.showCardContainer div.square {
    transform: translateX(-60px) translateY(-60px) scale(1.1);
  }
}
@media all and (max-width: 980px) {
  div.showallPage div.bottomSection div.itemsContainer div.items.rowCount4.landscape {
    width: 24.3%;
    margin-inline-end: 6px;
    margin-bottom: 40px;
  }
  div.showallPage div.bottomSection div.itemsContainer div.items.rowCount4.landscape:nth-child(6n) {
    margin-inline-end: 6px;
  }
  div.showallPage div.bottomSection div.itemsContainer div.items.rowCount4.landscape:nth-child(4n) {
    margin-inline-end: 0;
  }
}
@media all and (max-width: 768px) {
  div.showallPage div.bottomSection div.itemsContainer div.items.rowCount4.landscape {
    width: 32.3%;
  }
  div.showallPage div.bottomSection div.itemsContainer div.items.rowCount4.landscape:nth-child(4n) {
    margin-inline-end: 6px;
  }
  div.showallPage div.bottomSection div.itemsContainer div.items.rowCount4.landscape:nth-child(3n) {
    margin-inline-end: 0;
  }
}
@media all and (max-width: 640px) {
  div.showallPage div.bottomSection div.itemsContainer div.items.rowCount4.landscape {
    width: 48.2%;
  }
  div.showallPage div.bottomSection div.itemsContainer div.items.rowCount4.landscape:nth-child(3n) {
    margin-inline-end: 6px;
  }
  div.showallPage div.bottomSection div.itemsContainer div.items.rowCount4.landscape:nth-child(2n) {
    margin-inline-end: 0;
  }
}
@media all and (max-width: 480px) {
  div.showallPage div.bottomSection div.itemsContainer div.items.rowCount4.landscape {
    width: 100%;
    margin-inline-end: 0;
  }
  div.showallPage div.bottomSection div.itemsContainer div.items.rowCount4.landscape:nth-child(6n) {
    margin-inline-end: 0;
  }
  div.showallPage div.bottomSection div.itemsContainer div.items.rowCount4.landscape:nth-child(4n) {
    margin-inline-end: 0;
  }
  div.showallPage div.bottomSection div.itemsContainer div.items.rowCount4.landscape:nth-child(3n) {
    margin-inline-end: 0;
  }
  div.showallPage div.bottomSection div.itemsContainer div.items.rowCount4.landscape:nth-child(2n) {
    margin-inline-end: 0;
  }
}
@media all and (max-width: 480px) and (max-width: 360px) {
  div.showallPage div.bottomSection div.itemsContainer div.items.rowCount4.landscape {
    width: 100%;
    margin-inline-end: 0;
  }
  div.showallPage div.bottomSection div.itemsContainer div.items.rowCount4.landscape:nth-child(3n) {
    margin-inline-end: 0;
  }
}
@media all and (min-width: 981px) {
  div.showallPage div.bottomSection div.itemsContainer div.items.rowCount4.portrait:hover div.showCardContainer .square {
    position: absolute;
    z-index: 9999999;
    transform: translateX(60px) translateY(-60px) scale(1.2);
  }
  div.showallPage div.bottomSection div.itemsContainer div.items.rowCount4.portrait:hover div.showCardContainer .square div.metaData {
    display: block;
    visibility: visible !important;
    animation: fadeIn 0.3s ease-in-out;
  }
  div.showallPage div.bottomSection div.itemsContainer div.items.rowCount4.portrait:hover:nth-child(4n) div.showCardContainer div.square {
    transform: translateX(-60px) translateY(-60px) scale(1.2);
  }
}
@media all and (min-width: 981px) and (max-width: 1300px) {
  div.showallPage div.bottomSection div.itemsContainer div.items.rowCount4.portrait:hover:nth-child(6n) div.showCardContainer div.square {
    transform: translateX(-60px) translateY(-60px) scale(1.2);
  }
  div.showallPage div.bottomSection div.itemsContainer div.items.rowCount4.portrait:hover:nth-child(7n) div.showCardContainer div.square {
    transform: translateX(60px) translateY(-60px) scale(1.2);
  }
}
@media all and (min-width: 981px) {
  div.showallPage div.bottomSection div.itemsContainer div.items.rowCount4.portrait:hover:last-child div.showCardContainer div.square {
    transform: translateX(-60px) translateY(-60px) scale(1.2);
  }
}
@media all and (max-width: 980px) {
  div.showallPage div.bottomSection div.itemsContainer div.items.rowCount4.portrait {
    width: 24%;
    margin-inline-end: 6px;
    margin-bottom: 40px;
  }
  div.showallPage div.bottomSection div.itemsContainer div.items.rowCount4.portrait:nth-child(5n) {
    margin-inline-end: 6px;
  }
  div.showallPage div.bottomSection div.itemsContainer div.items.rowCount4.portrait:nth-child(4n) {
    margin-inline-end: 0;
  }
  div.showallPage div.bottomSection div.itemsContainer div.items.rowCount4.portrait:nth-child(6n) {
    margin-inline-end: 6px;
  }
}
@media all and (max-width: 768px) {
  div.showallPage div.bottomSection div.itemsContainer div.items.rowCount4.portrait {
    width: 32%;
  }
  div.showallPage div.bottomSection div.itemsContainer div.items.rowCount4.portrait:nth-child(4n) {
    margin-inline-end: 6px;
  }
  div.showallPage div.bottomSection div.itemsContainer div.items.rowCount4.portrait:nth-child(3n) {
    margin-inline-end: 0;
  }
}
@media all and (max-width: 640px) {
  div.showallPage div.bottomSection div.itemsContainer div.items.rowCount4.portrait {
    width: 48%;
  }
  div.showallPage div.bottomSection div.itemsContainer div.items.rowCount4.portrait:nth-child(3n) {
    margin-inline-end: 6px;
  }
  div.showallPage div.bottomSection div.itemsContainer div.items.rowCount4.portrait:nth-child(2n) {
    margin-inline-end: 0;
  }
}
div.showallPage div.bottomSection div.itemsContainer div.items.rowCount5 {
  width: 19%;
  margin-inline-end: 13px;
  margin-bottom: 30px;
}
div.showallPage div.bottomSection div.itemsContainer div.items.rowCount5:nth-child(5n) {
  margin-inline-end: 0;
}
@media all and (max-width: 1200px) {
  div.showallPage div.bottomSection div.itemsContainer div.items.rowCount5 {
    width: 18.5%;
  }
}
@media all and (min-width: 981px) {
  div.showallPage div.bottomSection div.itemsContainer div.items.rowCount5.landscape:hover div.showCardContainer .square {
    position: absolute;
    z-index: 9999999;
    transform: translateX(60px) translateY(-60px) scale(1.1);
  }
  div.showallPage div.bottomSection div.itemsContainer div.items.rowCount5.landscape:hover div.showCardContainer .square div.metaData {
    display: block;
    visibility: visible !important;
    animation: fadeIn 0.3s ease-in-out;
  }
  div.showallPage div.bottomSection div.itemsContainer div.items.rowCount5.landscape:hover:nth-child(5n) div.showCardContainer div.square {
    transform: translateX(-60px) translateY(-60px) scale(1.1);
  }
}
@media all and (max-width: 980px) {
  div.showallPage div.bottomSection div.itemsContainer div.items.rowCount5.landscape {
    width: 24.3%;
    margin-inline-end: 6px;
    margin-bottom: 40px;
  }
  div.showallPage div.bottomSection div.itemsContainer div.items.rowCount5.landscape:nth-child(6n) {
    margin-inline-end: 6px;
  }
  div.showallPage div.bottomSection div.itemsContainer div.items.rowCount5.landscape:nth-child(4n) {
    margin-inline-end: 0;
  }
}
@media all and (max-width: 768px) {
  div.showallPage div.bottomSection div.itemsContainer div.items.rowCount5.landscape {
    width: 32.3%;
  }
  div.showallPage div.bottomSection div.itemsContainer div.items.rowCount5.landscape:nth-child(4n) {
    margin-inline-end: 6px;
  }
  div.showallPage div.bottomSection div.itemsContainer div.items.rowCount5.landscape:nth-child(3n) {
    margin-inline-end: 0;
  }
}
@media all and (max-width: 640px) {
  div.showallPage div.bottomSection div.itemsContainer div.items.rowCount5.landscape {
    width: 48.2%;
  }
  div.showallPage div.bottomSection div.itemsContainer div.items.rowCount5.landscape:nth-child(3n) {
    margin-inline-end: 6px;
  }
  div.showallPage div.bottomSection div.itemsContainer div.items.rowCount5.landscape:nth-child(2n) {
    margin-inline-end: 0;
  }
}
@media all and (max-width: 480px) {
  div.showallPage div.bottomSection div.itemsContainer div.items.rowCount5.landscape {
    width: 100%;
    margin-inline-end: 0;
  }
  div.showallPage div.bottomSection div.itemsContainer div.items.rowCount5.landscape:nth-child(6n) {
    margin-inline-end: 0;
  }
  div.showallPage div.bottomSection div.itemsContainer div.items.rowCount5.landscape:nth-child(4n) {
    margin-inline-end: 0;
  }
  div.showallPage div.bottomSection div.itemsContainer div.items.rowCount5.landscape:nth-child(3n) {
    margin-inline-end: 0;
  }
  div.showallPage div.bottomSection div.itemsContainer div.items.rowCount5.landscape:nth-child(2n) {
    margin-inline-end: 0;
  }
}
@media all and (max-width: 480px) and (max-width: 360px) {
  div.showallPage div.bottomSection div.itemsContainer div.items.rowCount5.landscape {
    width: 100%;
    margin-inline-end: 0;
  }
  div.showallPage div.bottomSection div.itemsContainer div.items.rowCount5.landscape:nth-child(3n) {
    margin-inline-end: 0;
  }
}
@media all and (min-width: 981px) {
  div.showallPage div.bottomSection div.itemsContainer div.items.rowCount5.portrait:hover div.showCardContainer .square {
    position: absolute;
    z-index: 9999999;
    transform: translateX(60px) translateY(-60px) scale(1.2);
  }
  div.showallPage div.bottomSection div.itemsContainer div.items.rowCount5.portrait:hover div.showCardContainer .square div.metaData {
    display: block;
    visibility: visible !important;
    animation: fadeIn 0.3s ease-in-out;
  }
  div.showallPage div.bottomSection div.itemsContainer div.items.rowCount5.portrait:hover:nth-child(5n) div.showCardContainer div.square {
    transform: translateX(-60px) translateY(-60px) scale(1.2);
  }
}
@media all and (min-width: 981px) and (max-width: 1300px) {
  div.showallPage div.bottomSection div.itemsContainer div.items.rowCount5.portrait:hover:nth-child(6n) div.showCardContainer div.square {
    transform: translateX(-60px) translateY(-60px) scale(1.2);
  }
  div.showallPage div.bottomSection div.itemsContainer div.items.rowCount5.portrait:hover:nth-child(7n) div.showCardContainer div.square {
    transform: translateX(60px) translateY(-60px) scale(1.2);
  }
}
@media all and (min-width: 981px) {
  div.showallPage div.bottomSection div.itemsContainer div.items.rowCount5.portrait:hover:last-child div.showCardContainer div.square {
    transform: translateX(-60px) translateY(-60px) scale(1.2);
  }
}
@media all and (max-width: 980px) {
  div.showallPage div.bottomSection div.itemsContainer div.items.rowCount5.portrait {
    width: 24%;
    margin-inline-end: 6px;
    margin-bottom: 40px;
  }
  div.showallPage div.bottomSection div.itemsContainer div.items.rowCount5.portrait:nth-child(5n) {
    margin-inline-end: 6px;
  }
  div.showallPage div.bottomSection div.itemsContainer div.items.rowCount5.portrait:nth-child(4n) {
    margin-inline-end: 0;
  }
  div.showallPage div.bottomSection div.itemsContainer div.items.rowCount5.portrait:nth-child(6n) {
    margin-inline-end: 6px;
  }
}
@media all and (max-width: 768px) {
  div.showallPage div.bottomSection div.itemsContainer div.items.rowCount5.portrait {
    width: 32%;
  }
  div.showallPage div.bottomSection div.itemsContainer div.items.rowCount5.portrait:nth-child(4n) {
    margin-inline-end: 6px;
  }
  div.showallPage div.bottomSection div.itemsContainer div.items.rowCount5.portrait:nth-child(3n) {
    margin-inline-end: 0;
  }
}
@media all and (max-width: 640px) {
  div.showallPage div.bottomSection div.itemsContainer div.items.rowCount5.portrait {
    width: 48%;
  }
  div.showallPage div.bottomSection div.itemsContainer div.items.rowCount5.portrait:nth-child(3n) {
    margin-inline-end: 6px;
  }
  div.showallPage div.bottomSection div.itemsContainer div.items.rowCount5.portrait:nth-child(2n) {
    margin-inline-end: 0;
  }
}
div.showallPage div.bottomSection div.itemsContainer div.items.rowCount6 {
  width: 15.8%;
  margin-inline-end: 13px;
  margin-bottom: 30px;
}
div.showallPage div.bottomSection div.itemsContainer div.items.rowCount6:nth-child(6n) {
  margin-inline-end: 0;
}
@media all and (max-width: 1400px) {
  div.showallPage div.bottomSection div.itemsContainer div.items.rowCount6 {
    width: 15.5%;
  }
}
@media all and (max-width: 1200px) {
  div.showallPage div.bottomSection div.itemsContainer div.items.rowCount6 {
    width: 15%;
  }
}
@media all and (min-width: 981px) {
  div.showallPage div.bottomSection div.itemsContainer div.items.rowCount6.landscape:hover div.showCardContainer .square {
    position: absolute;
    z-index: 9999999;
    transform: translateX(60px) translateY(-60px) scale(1.1);
  }
  div.showallPage div.bottomSection div.itemsContainer div.items.rowCount6.landscape:hover div.showCardContainer .square div.metaData {
    display: block;
    visibility: visible !important;
    animation: fadeIn 0.3s ease-in-out;
  }
  div.showallPage div.bottomSection div.itemsContainer div.items.rowCount6.landscape:hover:nth-child(6n) div.showCardContainer div.square {
    transform: translateX(-60px) translateY(-60px) scale(1.1);
  }
}
@media all and (max-width: 980px) {
  div.showallPage div.bottomSection div.itemsContainer div.items.rowCount6.landscape {
    width: 24.3%;
    margin-inline-end: 6px;
    margin-bottom: 40px;
  }
  div.showallPage div.bottomSection div.itemsContainer div.items.rowCount6.landscape:nth-child(6n) {
    margin-inline-end: 6px;
  }
  div.showallPage div.bottomSection div.itemsContainer div.items.rowCount6.landscape:nth-child(4n) {
    margin-inline-end: 0;
  }
}
@media all and (max-width: 768px) {
  div.showallPage div.bottomSection div.itemsContainer div.items.rowCount6.landscape {
    width: 32.3%;
  }
  div.showallPage div.bottomSection div.itemsContainer div.items.rowCount6.landscape:nth-child(4n) {
    margin-inline-end: 6px;
  }
  div.showallPage div.bottomSection div.itemsContainer div.items.rowCount6.landscape:nth-child(3n) {
    margin-inline-end: 0;
  }
}
@media all and (max-width: 640px) {
  div.showallPage div.bottomSection div.itemsContainer div.items.rowCount6.landscape {
    width: 48.2%;
  }
  div.showallPage div.bottomSection div.itemsContainer div.items.rowCount6.landscape:nth-child(3n) {
    margin-inline-end: 6px;
  }
  div.showallPage div.bottomSection div.itemsContainer div.items.rowCount6.landscape:nth-child(2n) {
    margin-inline-end: 0;
  }
}
@media all and (max-width: 480px) {
  div.showallPage div.bottomSection div.itemsContainer div.items.rowCount6.landscape {
    width: 100%;
    margin-inline-end: 0;
  }
  div.showallPage div.bottomSection div.itemsContainer div.items.rowCount6.landscape:nth-child(6n) {
    margin-inline-end: 0;
  }
  div.showallPage div.bottomSection div.itemsContainer div.items.rowCount6.landscape:nth-child(4n) {
    margin-inline-end: 0;
  }
  div.showallPage div.bottomSection div.itemsContainer div.items.rowCount6.landscape:nth-child(3n) {
    margin-inline-end: 0;
  }
  div.showallPage div.bottomSection div.itemsContainer div.items.rowCount6.landscape:nth-child(2n) {
    margin-inline-end: 0;
  }
}
@media all and (max-width: 480px) and (max-width: 360px) {
  div.showallPage div.bottomSection div.itemsContainer div.items.rowCount6.landscape {
    width: 100%;
    margin-inline-end: 0;
  }
  div.showallPage div.bottomSection div.itemsContainer div.items.rowCount6.landscape:nth-child(3n) {
    margin-inline-end: 0;
  }
}
@media all and (min-width: 981px) {
  div.showallPage div.bottomSection div.itemsContainer div.items.rowCount6.portrait:hover div.showCardContainer .square {
    position: absolute;
    z-index: 9999999;
    transform: translateX(60px) translateY(-60px) scale(1.2);
  }
  div.showallPage div.bottomSection div.itemsContainer div.items.rowCount6.portrait:hover div.showCardContainer .square div.metaData {
    display: block;
    visibility: visible !important;
    animation: fadeIn 0.3s ease-in-out;
  }
  div.showallPage div.bottomSection div.itemsContainer div.items.rowCount6.portrait:hover:nth-child(6n) div.showCardContainer div.square {
    transform: translateX(-60px) translateY(-60px) scale(1.2);
  }
}
@media all and (min-width: 981px) and (max-width: 1300px) {
  div.showallPage div.bottomSection div.itemsContainer div.items.rowCount6.portrait:hover:nth-child(6n) div.showCardContainer div.square {
    transform: translateX(-60px) translateY(-60px) scale(1.2);
  }
  div.showallPage div.bottomSection div.itemsContainer div.items.rowCount6.portrait:hover:nth-child(7n) div.showCardContainer div.square {
    transform: translateX(60px) translateY(-60px) scale(1.2);
  }
}
@media all and (min-width: 981px) {
  div.showallPage div.bottomSection div.itemsContainer div.items.rowCount6.portrait:hover:last-child div.showCardContainer div.square {
    transform: translateX(-60px) translateY(-60px) scale(1.2);
  }
}
@media all and (max-width: 980px) {
  div.showallPage div.bottomSection div.itemsContainer div.items.rowCount6.portrait {
    width: 24%;
    margin-inline-end: 6px;
    margin-bottom: 40px;
  }
  div.showallPage div.bottomSection div.itemsContainer div.items.rowCount6.portrait:nth-child(5n) {
    margin-inline-end: 6px;
  }
  div.showallPage div.bottomSection div.itemsContainer div.items.rowCount6.portrait:nth-child(4n) {
    margin-inline-end: 0;
  }
  div.showallPage div.bottomSection div.itemsContainer div.items.rowCount6.portrait:nth-child(6n) {
    margin-inline-end: 6px;
  }
}
@media all and (max-width: 768px) {
  div.showallPage div.bottomSection div.itemsContainer div.items.rowCount6.portrait {
    width: 32%;
  }
  div.showallPage div.bottomSection div.itemsContainer div.items.rowCount6.portrait:nth-child(4n) {
    margin-inline-end: 6px;
  }
  div.showallPage div.bottomSection div.itemsContainer div.items.rowCount6.portrait:nth-child(3n) {
    margin-inline-end: 0;
  }
}
@media all and (max-width: 640px) {
  div.showallPage div.bottomSection div.itemsContainer div.items.rowCount6.portrait {
    width: 48%;
  }
  div.showallPage div.bottomSection div.itemsContainer div.items.rowCount6.portrait:nth-child(3n) {
    margin-inline-end: 6px;
  }
  div.showallPage div.bottomSection div.itemsContainer div.items.rowCount6.portrait:nth-child(2n) {
    margin-inline-end: 0;
  }
}
div.showallPage div.bottomSection div.itemsContainer div.items.rowCount7 {
  width: 13.9%;
  margin-inline-end: 6px;
  margin-bottom: 30px;
}
div.showallPage div.bottomSection div.itemsContainer div.items.rowCount7:nth-child(7n) {
  margin-inline-end: 0;
}
@media all and (max-width: 1200px) {
  div.showallPage div.bottomSection div.itemsContainer div.items.rowCount7 {
    width: 19.3%;
  }
}
@media all and (max-width: 2600px) {
  div.showallPage div.bottomSection div.itemsContainer div.items.rowCount7.portrait {
    width: 14%;
    margin-inline-end: 6.5px;
  }
}
@media all and (max-width: 2500px) {
  div.showallPage div.bottomSection div.itemsContainer div.items.rowCount7.portrait {
    width: 14%;
    margin-inline-end: 5.5px;
  }
}
@media all and (max-width: 2200px) {
  div.showallPage div.bottomSection div.itemsContainer div.items.rowCount7.portrait {
    width: 13.9%;
    margin-inline-end: 6.5px;
  }
}
@media all and (max-width: 1980px) {
  div.showallPage div.bottomSection div.itemsContainer div.items.rowCount7.portrait {
    width: 14%;
    margin-inline-end: 4.5px;
  }
}
@media all and (max-width: 1768px) {
  div.showallPage div.bottomSection div.itemsContainer div.items.rowCount7.portrait {
    width: 14%;
    margin-inline-end: 4px;
  }
}
@media all and (max-width: 1600px) {
  div.showallPage div.bottomSection div.itemsContainer div.items.rowCount7.portrait {
    width: 13.7%;
    margin-inline-end: 7.5px;
  }
}
@media all and (max-width: 1400px) {
  div.showallPage div.bottomSection div.itemsContainer div.items.rowCount7.portrait {
    width: 14%;
    margin-inline-end: 3px;
  }
}
@media all and (max-width: 1200px) {
  div.showallPage div.bottomSection div.itemsContainer div.items.rowCount7.portrait {
    width: 13.6%;
    margin-inline-end: 6.1px;
  }
}
@media all and (min-width: 981px) {
  div.showallPage div.bottomSection div.itemsContainer div.items.rowCount7.portrait:hover div.showCardContainer .square {
    position: absolute;
    z-index: 9999999;
    transform: translateX(60px) translateY(-60px) scale(1.2);
  }
  div.showallPage div.bottomSection div.itemsContainer div.items.rowCount7.portrait:hover div.showCardContainer .square div.metaData {
    display: block;
    visibility: visible !important;
    animation: fadeIn 0.3s ease-in-out;
  }
  div.showallPage div.bottomSection div.itemsContainer div.items.rowCount7.portrait:hover:nth-child(7n) div.showCardContainer div.square {
    transform: translateX(-60px) translateY(-60px) scale(1.2);
  }
}
@media all and (min-width: 981px) and (max-width: 1300px) {
  div.showallPage div.bottomSection div.itemsContainer div.items.rowCount7.portrait:hover:nth-child(6n) div.showCardContainer div.square {
    transform: translateX(-60px) translateY(-60px) scale(1.2);
  }
  div.showallPage div.bottomSection div.itemsContainer div.items.rowCount7.portrait:hover:nth-child(7n) div.showCardContainer div.square {
    transform: translateX(60px) translateY(-60px) scale(1.2);
  }
}
@media all and (min-width: 981px) {
  div.showallPage div.bottomSection div.itemsContainer div.items.rowCount7.portrait:hover:last-child div.showCardContainer div.square {
    transform: translateX(-60px) translateY(-60px) scale(1.2);
  }
}
@media all and (max-width: 980px) {
  div.showallPage div.bottomSection div.itemsContainer div.items.rowCount7.portrait {
    width: 24%;
    margin-inline-end: 6px;
    margin-bottom: 40px;
  }
  div.showallPage div.bottomSection div.itemsContainer div.items.rowCount7.portrait:nth-child(5n) {
    margin-inline-end: 6px;
  }
  div.showallPage div.bottomSection div.itemsContainer div.items.rowCount7.portrait:nth-child(4n) {
    margin-inline-end: 0;
  }
  div.showallPage div.bottomSection div.itemsContainer div.items.rowCount7.portrait:nth-child(6n) {
    margin-inline-end: 6px;
  }
}
@media all and (max-width: 768px) {
  div.showallPage div.bottomSection div.itemsContainer div.items.rowCount7.portrait {
    width: 32%;
  }
  div.showallPage div.bottomSection div.itemsContainer div.items.rowCount7.portrait:nth-child(4n) {
    margin-inline-end: 6px;
  }
  div.showallPage div.bottomSection div.itemsContainer div.items.rowCount7.portrait:nth-child(3n) {
    margin-inline-end: 0;
  }
}
@media all and (max-width: 640px) {
  div.showallPage div.bottomSection div.itemsContainer div.items.rowCount7.portrait {
    width: 48%;
  }
  div.showallPage div.bottomSection div.itemsContainer div.items.rowCount7.portrait:nth-child(3n) {
    margin-inline-end: 6px;
  }
  div.showallPage div.bottomSection div.itemsContainer div.items.rowCount7.portrait:nth-child(2n) {
    margin-inline-end: 0;
  }
}
div.showallPage div.bottomSection div.itemsContainer div.items.rowCount8 {
  width: 12%;
  margin-inline-end: 8px;
  margin-bottom: 30px;
}
div.showallPage div.bottomSection div.itemsContainer div.items.rowCount8:nth-child(8n) {
  margin-inline-end: 0;
}
@media all and (max-width: 1200px) {
  div.showallPage div.bottomSection div.itemsContainer div.items.rowCount8 {
    width: 12%;
    margin-inline-end: 6px;
  }
}
@media all and (min-width: 981px) {
  div.showallPage div.bottomSection div.itemsContainer div.items.rowCount8.landscape:hover div.showCardContainer .square {
    position: absolute;
    z-index: 9999999;
    transform: translateX(60px) translateY(-60px) scale(1.1);
  }
  div.showallPage div.bottomSection div.itemsContainer div.items.rowCount8.landscape:hover div.showCardContainer .square div.metaData {
    display: block;
    visibility: visible !important;
    animation: fadeIn 0.3s ease-in-out;
  }
  div.showallPage div.bottomSection div.itemsContainer div.items.rowCount8.landscape:hover:nth-child(5n) div.showCardContainer div.square {
    transform: translateX(-60px) translateY(-60px) scale(1.1);
  }
}
@media all and (max-width: 980px) {
  div.showallPage div.bottomSection div.itemsContainer div.items.rowCount8.landscape {
    width: 24.3%;
    margin-inline-end: 6px;
    margin-bottom: 40px;
  }
  div.showallPage div.bottomSection div.itemsContainer div.items.rowCount8.landscape:nth-child(6n) {
    margin-inline-end: 6px;
  }
  div.showallPage div.bottomSection div.itemsContainer div.items.rowCount8.landscape:nth-child(4n) {
    margin-inline-end: 0;
  }
}
@media all and (max-width: 768px) {
  div.showallPage div.bottomSection div.itemsContainer div.items.rowCount8.landscape {
    width: 32.3%;
  }
  div.showallPage div.bottomSection div.itemsContainer div.items.rowCount8.landscape:nth-child(4n) {
    margin-inline-end: 6px;
  }
  div.showallPage div.bottomSection div.itemsContainer div.items.rowCount8.landscape:nth-child(3n) {
    margin-inline-end: 0;
  }
}
@media all and (max-width: 640px) {
  div.showallPage div.bottomSection div.itemsContainer div.items.rowCount8.landscape {
    width: 48.2%;
  }
  div.showallPage div.bottomSection div.itemsContainer div.items.rowCount8.landscape:nth-child(3n) {
    margin-inline-end: 6px;
  }
  div.showallPage div.bottomSection div.itemsContainer div.items.rowCount8.landscape:nth-child(2n) {
    margin-inline-end: 0;
  }
}
@media all and (max-width: 480px) {
  div.showallPage div.bottomSection div.itemsContainer div.items.rowCount8.landscape {
    width: 100%;
    margin-inline-end: 0;
  }
  div.showallPage div.bottomSection div.itemsContainer div.items.rowCount8.landscape:nth-child(6n) {
    margin-inline-end: 0;
  }
  div.showallPage div.bottomSection div.itemsContainer div.items.rowCount8.landscape:nth-child(4n) {
    margin-inline-end: 0;
  }
  div.showallPage div.bottomSection div.itemsContainer div.items.rowCount8.landscape:nth-child(3n) {
    margin-inline-end: 0;
  }
  div.showallPage div.bottomSection div.itemsContainer div.items.rowCount8.landscape:nth-child(2n) {
    margin-inline-end: 0;
  }
}
@media all and (max-width: 480px) and (max-width: 360px) {
  div.showallPage div.bottomSection div.itemsContainer div.items.rowCount8.landscape {
    width: 100%;
    margin-inline-end: 0;
  }
  div.showallPage div.bottomSection div.itemsContainer div.items.rowCount8.landscape:nth-child(3n) {
    margin-inline-end: 0;
  }
}
@media all and (max-width: 2600px) {
  div.showallPage div.bottomSection div.itemsContainer div.items.rowCount8.portrait {
    width: 12.1%;
    margin-inline-end: 10px;
  }
  div.showallPage div.bottomSection div.itemsContainer div.items.rowCount8.portrait:nth-child(8n) {
    margin-inline-end: 0px;
  }
}
@media all and (max-width: 2500px) {
  div.showallPage div.bottomSection div.itemsContainer div.items.rowCount8.portrait {
    width: 12%;
    margin-inline-end: 11px;
  }
}
@media all and (max-width: 2200px) {
  div.showallPage div.bottomSection div.itemsContainer div.items.rowCount8.portrait {
    width: 12.2%;
    margin-inline-end: 6px;
  }
}
@media all and (max-width: 1980px) {
  div.showallPage div.bottomSection div.itemsContainer div.items.rowCount8.portrait {
    width: 12%;
    margin-inline-end: 9px;
  }
}
@media all and (max-width: 1768px) {
  div.showallPage div.bottomSection div.itemsContainer div.items.rowCount8.portrait {
    width: 11.9%;
    margin-inline-end: 10px;
  }
}
@media all and (max-width: 1600px) {
  div.showallPage div.bottomSection div.itemsContainer div.items.rowCount8.portrait {
    width: 12%;
    margin-inline-end: 7px;
  }
}
@media all and (max-width: 1400px) {
  div.showallPage div.bottomSection div.itemsContainer div.items.rowCount8.portrait {
    width: 12%;
    margin-inline-end: 6px;
  }
}
@media all and (max-width: 1200px) {
  div.showallPage div.bottomSection div.itemsContainer div.items.rowCount8.portrait {
    width: 12%;
    margin-inline-end: 5px;
  }
}
@media all and (min-width: 981px) {
  div.showallPage div.bottomSection div.itemsContainer div.items.rowCount8.portrait:hover div.showCardContainer .square {
    position: absolute;
    z-index: 9999999;
    transform: translateX(60px) translateY(-60px) scale(1.2);
  }
  div.showallPage div.bottomSection div.itemsContainer div.items.rowCount8.portrait:hover div.showCardContainer .square div.metaData {
    display: block;
    visibility: visible !important;
    animation: fadeIn 0.3s ease-in-out;
  }
  div.showallPage div.bottomSection div.itemsContainer div.items.rowCount8.portrait:hover:nth-child(8n) div.showCardContainer div.square {
    transform: translateX(-60px) translateY(-60px) scale(1.2);
  }
}
@media all and (max-width: 980px) {
  div.showallPage div.bottomSection div.itemsContainer div.items.rowCount8.portrait {
    width: 24%;
    margin-inline-end: 6px;
    margin-bottom: 40px;
  }
}
@media all and (max-width: 768px) {
  div.showallPage div.bottomSection div.itemsContainer div.items.rowCount8.portrait {
    width: 32%;
  }
  div.showallPage div.bottomSection div.itemsContainer div.items.rowCount8.portrait:nth-child(4n) {
    margin-inline-end: 6px;
  }
  div.showallPage div.bottomSection div.itemsContainer div.items.rowCount8.portrait:nth-child(3n) {
    margin-inline-end: 0;
  }
}
@media all and (max-width: 640px) {
  div.showallPage div.bottomSection div.itemsContainer div.items.rowCount8.portrait {
    width: 48%;
  }
  div.showallPage div.bottomSection div.itemsContainer div.items.rowCount8.portrait:nth-child(3n) {
    margin-inline-end: 6px;
  }
  div.showallPage div.bottomSection div.itemsContainer div.items.rowCount8.portrait:nth-child(2n) {
    margin-inline-end: 0;
  }
}
@media all and (max-width: 980px) {
  div.showallPage div.bottomSection div.itemsContainer div.items {
    width: 24%;
    margin-inline-end: 6px;
    margin-bottom: 40px;
  }
  div.showallPage div.bottomSection div.itemsContainer div.items:nth-child(5n) {
    margin-inline-end: 6px;
  }
  div.showallPage div.bottomSection div.itemsContainer div.items:nth-child(4n) {
    margin-inline-end: 0;
  }
  div.showallPage div.bottomSection div.itemsContainer div.items:nth-child(6n) {
    margin-inline-end: 6px;
  }
}
@media all and (max-width: 640px) {
  div.showallPage div.bottomSection div.itemsContainer div.items {
    width: 48%;
  }
  div.showallPage div.bottomSection div.itemsContainer div.items:nth-child(3n) {
    margin-inline-end: 6px;
  }
  div.showallPage div.bottomSection div.itemsContainer div.items:nth-child(2n) {
    margin-inline-end: 0;
  }
}
@media all and (max-width: 980px) {
  div.showallPage div.bottomSection div.itemsContainer div.items.portrait {
    width: 23.5%;
    margin-inline-end: 13px;
    margin-bottom: 40px;
  }
  div.showallPage div.bottomSection div.itemsContainer div.items.portrait:nth-child(4n) {
    margin-inline-end: 0;
  }
  div.showallPage div.bottomSection div.itemsContainer div.items.portrait:nth-child(5n) {
    margin-inline-end: 13px;
  }
}
@media all and (max-width: 768px) {
  div.showallPage div.bottomSection div.itemsContainer div.items.portrait {
    width: 23%;
  }
  div.showallPage div.bottomSection div.itemsContainer div.items.portrait:nth-child(4n) {
    margin-inline-end: 0;
  }
  div.showallPage div.bottomSection div.itemsContainer div.items.portrait:nth-child(5n) {
    margin-inline-end: 13px;
  }
}
@media all and (max-width: 640px) {
  div.showallPage div.bottomSection div.itemsContainer div.items.portrait {
    width: 31%;
  }
  div.showallPage div.bottomSection div.itemsContainer div.items.portrait:nth-child(4n) {
    margin-inline-end: 13px;
  }
  div.showallPage div.bottomSection div.itemsContainer div.items.portrait:nth-child(3n) {
    margin-inline-end: 0;
  }
}
@media all and (max-width: 480px) {
  div.showallPage div.bottomSection div.itemsContainer div.items.portrait {
    width: 47%;
  }
  div.showallPage div.bottomSection div.itemsContainer div.items.portrait:nth-child(3n) {
    margin-inline-end: 13px;
  }
  div.showallPage div.bottomSection div.itemsContainer div.items.portrait:nth-child(2n) {
    margin-inline-end: 0;
  }
}
@media all and (max-width: 980px) {
  div.showallPage div.bottomSection div.itemsContainer div.items.landscape {
    width: 32%;
    margin-inline-end: 13px;
    margin-bottom: 40px;
  }
  div.showallPage div.bottomSection div.itemsContainer div.items.landscape:nth-child(3n) {
    margin-inline-end: 0;
  }
}
@media all and (max-width: 768px) {
  div.showallPage div.bottomSection div.itemsContainer div.items.landscape {
    width: 47.6%;
  }
  div.showallPage div.bottomSection div.itemsContainer div.items.landscape:nth-child(2n) {
    margin-inline-end: 0;
  }
  div.showallPage div.bottomSection div.itemsContainer div.items.landscape:nth-child(3n) {
    margin-inline-end: 13px;
  }
}
@media all and (max-width: 640px) {
  div.showallPage div.bottomSection div.itemsContainer div.items.landscape {
    width: 48%;
  }
  div.showallPage div.bottomSection div.itemsContainer div.items.landscape:nth-child(3n) {
    margin-inline-end: 13px;
  }
  div.showallPage div.bottomSection div.itemsContainer div.items.landscape:nth-child(2n) {
    margin-inline-end: 0;
  }
}
@media all and (max-width: 480px) {
  div.showallPage div.bottomSection div.itemsContainer div.items.landscape {
    width: 47%;
  }
  div.showallPage div.bottomSection div.itemsContainer div.items.landscape:nth-child(3n) {
    margin-inline-end: 13px;
  }
  div.showallPage div.bottomSection div.itemsContainer div.items.landscape:nth-child(2n) {
    margin-inline-end: 0;
  }
}

div.autoplayComponent {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  background-color: #000;
  height: 100%;
  z-index: 9;
}
div.autoplayComponent div.content {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 100%;
}
div.autoplayComponent div.content div.progressBar {
  width: 100px;
  height: 100px;
}
div.autoplayComponent div.content span.timer {
  display: inline-block;
  margin-top: 10px;
  font-weight: 600;
  color: var(--white-color);
}

div.noscheduleContainer div.overlayModal {
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.7);
  z-index: 99;
  display: flex;
  justify-content: center;
  align-items: center;
}
div.noscheduleContainer div.closeIconContainer {
  position: fixed;
  top: 20px;
  inset-inline-end: 30px;
  z-index: 999;
  cursor: pointer;
}
div.noscheduleContainer div.closeIconContainer svg {
  width: 25px !important;
  height: 25px !important;
  stroke: var(--text-color) !important;
}
div.noscheduleContainer div.contents {
  z-index: 999;
  text-align: center;
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  background-color: var(--bg-color);
  width: 40%;
  margin: 0 auto;
  padding: 60px 60px 60px;
  border-radius: 6px;
  display: flex;
  flex-direction: column;
}
@media all and (max-width: 1200px) {
  div.noscheduleContainer div.contents {
    padding: 30px;
  }
}
@media all and (max-width: 768px) {
  div.noscheduleContainer div.contents {
    width: 60%;
  }
}
@media all and (max-width: 480px) {
  div.noscheduleContainer div.contents {
    width: 90%;
  }
}
div.noscheduleContainer div.contents h1 {
  color: var(--inner-text-color);
  font-size: 22px;
}

div.blankpageContainer {
  height: 100vh;
}

div.socialLoginModal div.overlayModal {
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.7);
  z-index: 999999;
  display: flex;
  justify-content: center;
  align-items: center;
}
div.socialLoginModal div.closeIconContainer {
  position: fixed;
  top: 20px;
  inset-inline-end: 30px;
  z-index: 9999999;
}
div.socialLoginModal div.closeIconContainer svg {
  width: 20px !important;
  height: 20px !important;
}
div.socialLoginModal div.contents {
  z-index: 9999999;
  text-align: center;
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  background-color: var(--bg-color);
  width: 40%;
  margin: 0 auto;
  padding: 60px 60px 60px;
  display: flex;
  flex-direction: column;
}
@media all and (max-width: 1200px) {
  div.socialLoginModal div.contents {
    padding: 30px;
  }
}
@media all and (max-width: 768px) {
  div.socialLoginModal div.contents {
    width: 80%;
  }
}
@media all and (max-width: 480px) {
  div.socialLoginModal div.contents {
    width: 90%;
  }
}
div.socialLoginModal div.contents h1.error {
  font-size: 20px;
  color: #E72A31;
  margin-bottom: 10px;
}
div.socialLoginModal div.contents p {
  color: var(--white-color);
  font-size: 16px;
}
div.socialLoginModal div.contents h1 {
  color: var(--white-color);
  font-size: 18px;
}
@media all and (max-width: 768px) {
  div.socialLoginModal div.contents h1 {
    font-size: 16px;
  }
}
div.socialLoginModal div.contents div.buttonContainer {
  margin-top: 20px;
  display: flex;
  justify-content: center;
}
div.socialLoginModal div.contents div.buttonContainer button {
  background-color: var(--button-color);
  padding: 10px 12px;
  color: var(--text-color);
  font-size: 13px;
  cursor: pointer;
  width: 10rem;
  border-radius: 5px;
}
div.socialLoginModal div.contents div.buttonContainer button:first-child {
  margin-inline-end: 6px;
}

.iq-top-ten-block-slider {
  margin: 0 -0.9375em;
}
.iq-top-ten-block-slider .swiper-wrapper {
  margin: 0;
}
.iq-top-ten-block-slider .swiper-button.swiper-button-next {
  inset-inline-end: 2rem;
}
.iq-top-ten-block-slider .swiper-button.swiper-button-prev {
  inset-inline-start: 2rem;
}

.iq-top-ten-block .block-image {
  padding: 0 0.375rem;
}
.iq-top-ten-block .block-image .cardContainer {
  position: relative;
}
.iq-top-ten-block .block-image .cardContainer .overly-images {
  position: relative;
  z-index: 1;
  display: block;
}
.iq-top-ten-block .block-image .cardContainer .overly-images img {
  width: 100%;
  transition: all 0.6s ease 0s;
  border-radius: 2%;
}
.iq-top-ten-block .block-image .cardContainer .top-ten-numbers {
  position: absolute;
  bottom: -60px;
  inset-inline-end: 0;
  font-weight: 800;
  font-size: 8em;
  transition: all 0.4s ease-out;
  z-index: 2;
}
@media (max-width: 991px) {
  .iq-top-ten-block .block-image .cardContainer .top-ten-numbers {
    font-size: 6.5rem;
  }
}
@media (max-width: 767px) {
  .iq-top-ten-block .block-image .cardContainer .top-ten-numbers {
    font-size: 4.5rem !important;
    bottom: -40px;
  }
}
.iq-top-ten-block .block-image .cardContainer .top-ten-numbers {
  color: transparent;
  background-clip: text;
  -webkit-background-clip: text;
  line-height: normal;
  background-image: url("/src/assets/Images/texure.webp");
}
.iq-top-ten-block .block-image .cardContainer .top-ten-numbers-extra {
  color: var(--black-color);
  background-color: transparent;
  text-shadow: -2px -2px 0 white, 2px -2px 0 white, -2px 2px 0 white, 2px 2px 0 white;
  padding: 2px 4px;
  border-radius: 4px;
}
.iq-top-ten-block .block-image .metaData h1.title {
  margin-top: 10px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 100%;
  padding: 0 10px;
  color: var(--text-color);
  font-size: 15px;
  cursor: pointer;
  position: relative;
}
.iq-top-ten-block .block-image .metaData h1.title:hover {
  color: var(--text-hover);
}
.iq-top-ten-block .block-image .metaData h1.title::after {
  content: attr(title);
  position: absolute;
  bottom: 100%;
  left: 50%;
  transform: translateX(-50%);
  background: rgba(0, 0, 0, 0.7);
  color: white;
  padding: 5px 10px;
  border-radius: 5px;
  white-space: nowrap;
  opacity: 0;
  pointer-events: none;
  transition: opacity 0.3s;
  z-index: 9999;
  font-size: 0.9rem;
}
.iq-top-ten-block .block-image .metaData h1.title:hover::after {
  opacity: 1;
}
@media (max-width: 1980px) {
  .iq-top-ten-block .block-image .metaData h1.title {
    font-size: 14px;
  }
}
.iq-top-ten-block:hover .block-image .top-ten-numbers {
  bottom: -50px;
}
.iq-top-ten-block.top-ten-portrait .block-image, .iq-top-ten-block.top-ten-landscape .block-image {
  padding: 0 0.275rem;
}

@media (max-width: 767px) {
  .iq-top-ten-block .block-image {
    padding: 0 0.4687rem;
  }
  .iq-top-ten-block .top-ten-numbers {
    font-size: 4.5rem !important;
    bottom: -40px;
  }
  .iq-top-ten-block-slider .swiper-button.swiper-button-next {
    inset-inline-end: 1rem;
  }
  .iq-top-ten-block-slider .swiper-button.swiper-button-prev {
    inset-inline-start: 1rem;
  }
}
div.top-ten-block {
  overflow-x: hidden;
  padding: 15px 0 75px;
  border-bottom: 1px solid var(--border-color);
}
@media (max-width: 1200px) {
  div.top-ten-block {
    padding: 15px 0;
  }
}

.sectioncontainer .wrapper:hover .swiper-button-next::after {
  opacity: 1;
}
.sectioncontainer .wrapper:hover .swiper-button-prev::after {
  opacity: 1;
}
.sectioncontainer .wrapper .swiper {
  overflow: visible;
}
.sectioncontainer .wrapper .swiper.landscape .swiper-button-next {
  height: 100%;
  inset-inline-end: -4%;
  color: white;
  top: 9%;
  width: 10%;
  background: linear-gradient(-97deg, rgb(28, 31, 41) 0.98%, rgba(28, 31, 41, 0.4) 100.23%, rgba(28, 31, 41, 0.2) 100.16%, rgba(28, 31, 41, 0.0001) 52.12%);
}
@media all and (max-width: 2600px) {
  .sectioncontainer .wrapper .swiper.landscape .swiper-button-next {
    top: 6%;
    width: 5% !important;
    inset-inline-end: -5%;
  }
}
@media all and (max-width: 1600px) {
  .sectioncontainer .wrapper .swiper.landscape .swiper-button-next {
    top: 9%;
  }
}
@media all and (max-width: 1200px) {
  .sectioncontainer .wrapper .swiper.landscape .swiper-button-next {
    top: 13%;
  }
}
@media all and (max-width: 980px) {
  .sectioncontainer .wrapper .swiper.landscape .swiper-button-next {
    inset-inline-end: -9%;
    width: 9% !important;
    top: 6%;
  }
}
@media all and (max-width: 480px) {
  .sectioncontainer .wrapper .swiper.landscape .swiper-button-next {
    top: 9%;
    width: 10% !important;
  }
}
.sectioncontainer .wrapper .swiper.landscape .swiper-button-prev {
  inset-inline-end: -4%;
  height: 100%;
  color: white;
  width: 6%;
  top: 9%;
  background: linear-gradient(-97deg, rgb(28, 31, 41) 0.98%, rgba(28, 31, 41, 0.4) 100.23%, rgba(28, 31, 41, 0.2) 100.16%, rgba(28, 31, 41, 0.0001) 52.12%);
}
.sectioncontainer .wrapper .swiper.landscape .swiper-button-prev .svg {
  height: 20px;
  align-items: center;
  color: white;
}
@media all and (max-width: 2600px) {
  .sectioncontainer .wrapper .swiper.landscape .swiper-button-prev {
    top: 6%;
    width: 5% !important;
    inset-inline-start: -5%;
  }
}
@media all and (max-width: 1600px) {
  .sectioncontainer .wrapper .swiper.landscape .swiper-button-prev {
    top: 9%;
  }
}
@media all and (max-width: 1200px) {
  .sectioncontainer .wrapper .swiper.landscape .swiper-button-prev {
    top: 13%;
  }
}
@media all and (max-width: 980px) {
  .sectioncontainer .wrapper .swiper.landscape .swiper-button-prev {
    inset-inline-start: -9%;
    width: 9% !important;
    top: 6%;
  }
}
@media all and (max-width: 480px) {
  .sectioncontainer .wrapper .swiper.landscape .swiper-button-prev {
    top: 9%;
    width: 10% !important;
  }
}
.sectioncontainer .wrapper .swiper.portrait .swiper-button-next {
  inset-inline-end: -5%;
  height: 100%;
  width: 4% !important;
  background: linear-gradient(-97deg, rgb(28, 31, 41) 0.98%, rgba(28, 31, 41, 0.4) 100.23%, rgba(28, 31, 41, 0.2) 100.16%, rgba(28, 31, 41, 0.0001) 52.12%);
}
@media all and (max-width: 320px) {
  .sectioncontainer .wrapper .swiper.portrait .swiper-button-next {
    width: 9% !important;
  }
}
@media all and (max-width: 480px) {
  .sectioncontainer .wrapper .swiper.portrait .swiper-button-next {
    width: 8% !important;
  }
}
@media all and (max-width: 640px) {
  .sectioncontainer .wrapper .swiper.portrait .swiper-button-next {
    width: 9% !important;
    inset-inline-end: -9% !important;
  }
}
@media all and (max-width: 980px) {
  .sectioncontainer .wrapper .swiper.portrait .swiper-button-next {
    display: flex;
    width: 4%;
    inset-inline-end: -9% !important;
    top: 6% !important;
  }
}
@media all and (max-width: 1200px) {
  .sectioncontainer .wrapper .swiper.portrait .swiper-button-next {
    width: 4% !important;
    top: 9% !important;
  }
}
@media all and (max-width: 1400px) {
  .sectioncontainer .wrapper .swiper.portrait .swiper-button-next {
    top: 9% !important;
  }
}
@media all and (max-width: 1600px) {
  .sectioncontainer .wrapper .swiper.portrait .swiper-button-next {
    top: 7% !important;
  }
}
@media all and (max-width: 1768px) {
  .sectioncontainer .wrapper .swiper.portrait .swiper-button-next {
    top: 4% !important;
  }
}
.sectioncontainer .wrapper .swiper.portrait .swiper-button-prev {
  inset-inline-start: -5%;
  height: 100%;
  width: 4% !important;
  background: linear-gradient(-97deg, rgb(28, 31, 41) 0.98%, rgba(28, 31, 41, 0.4) 100.23%, rgba(28, 31, 41, 0.2) 100.16%, rgba(28, 31, 41, 0.0001) 52.12%);
}
@media all and (max-width: 320px) {
  .sectioncontainer .wrapper .swiper.portrait .swiper-button-prev {
    width: 9% !important;
  }
}
@media all and (max-width: 480px) {
  .sectioncontainer .wrapper .swiper.portrait .swiper-button-prev {
    width: 8% !important;
  }
}
@media all and (max-width: 640px) {
  .sectioncontainer .wrapper .swiper.portrait .swiper-button-prev {
    width: 9% !important;
    inset-inline-start: -9% !important;
  }
}
@media all and (max-width: 980px) {
  .sectioncontainer .wrapper .swiper.portrait .swiper-button-prev {
    display: flex;
    width: 4%;
    inset-inline-start: -9%;
    top: 5% !important;
  }
}
@media all and (max-width: 1200px) {
  .sectioncontainer .wrapper .swiper.portrait .swiper-button-prev {
    width: 4% !important;
    top: 9% !important;
  }
}
@media all and (max-width: 1400px) {
  .sectioncontainer .wrapper .swiper.portrait .swiper-button-prev {
    top: 9% !important;
  }
}
@media all and (max-width: 1600px) {
  .sectioncontainer .wrapper .swiper.portrait .swiper-button-prev {
    top: 7% !important;
  }
}
@media all and (max-width: 1768px) {
  .sectioncontainer .wrapper .swiper.portrait .swiper-button-prev {
    top: 4% !important;
  }
}
.sectioncontainer .wrapper .heading-container {
  display: inline;
  align-items: center;
  cursor: pointer;
}
.sectioncontainer .wrapper .heading-container.hover {
  color: var(--text-hover);
}
.sectioncontainer .wrapper .heading-container h4.topHeading {
  font-size: 24px;
  margin-inline-end: 10px;
  color: var(--text-color);
  transition: 0.3s ease-in-out;
  display: inline-block;
  margin-bottom: 20px;
}
.sectioncontainer .wrapper .heading-container h4.topHeading:hover {
  color: var(--text-hover);
}
.sectioncontainer .wrapper .heading-container h4.topHeading span {
  font-size: 24px;
  display: inline-block;
  color: var(--text-color);
  height: 20px !important;
  width: 20px !important;
  transition: color 0.3s ease-in-out;
  vertical-align: sub;
  margin-inline-start: 10px;
}
.sectioncontainer .wrapper .heading-container h4.topHeading span:hover, .sectioncontainer .wrapper .heading-container h4.topHeading span:focus {
  color: var(--text-hover);
}
.sectioncontainer .wrapper .heading-container h4.topHeading:hover, .sectioncontainer .wrapper .heading-container h4.topHeading:focus {
  color: var(--text-hover);
}
.sectioncontainer .wrapper .heading-container .show-all {
  color: var(--text-hover);
  font-size: medium;
}
.sectioncontainer .wrapper .heading-container .show-all:hover {
  color: var(--text-color);
}
.sectioncontainer .wrapper .text-decoration-none:hover {
  color: var(--text-hover);
}
.sectioncontainer .wrapper .text-decoration-none svg:hover {
  color: var(--text-hover);
}

div.playlistCardContainer {
  width: 100%;
  position: relative;
  margin-top: 20px;
  margin-inline-end: 10px;
}
div.playlistCardContainer:nth-child(3n) {
  margin-inline-end: 0px;
}
div.playlistCardContainer div.showsSubscriptions {
  margin-top: 10px;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
}
@media all and (max-width: 1200px) {
  div.playlistCardContainer div.showsSubscriptions {
    display: none;
  }
}
div.playlistCardContainer div.showsSubscriptions div.items {
  width: 5rem;
  margin-inline-end: 10px;
  background-color: var(--yellow-color);
  padding: 4px 0;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 10px;
  font-weight: 600;
  color: var(--text-color);
  margin-bottom: 10px;
  border-radius: 30px;
}
div.playlistCardContainer div.showsSubscriptions div.items.free {
  background-color: var(--success-color);
}
@media all and (max-width: 1200px) {
  div.playlistCardContainer div.showsSubscriptions div.items {
    font-size: 8px;
    width: 4rem;
  }
}
div.playlistCardContainer div.premiumStatus div.premium {
  position: absolute;
  top: 6px;
  inset-inline-start: 6px;
  z-index: 8;
  background-color: rgba(8, 25, 43, 0.65);
  width: 35px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 3px;
}
@media all and (max-width: 1200px) {
  div.playlistCardContainer div.premiumStatus div.premium {
    width: 26px;
  }
}
div.playlistCardContainer div.premiumStatus div.premium svg {
  width: 18px !important;
  height: 18px !important;
}
@media all and (max-width: 1200px) {
  div.playlistCardContainer div.premiumStatus div.premium svg {
    width: 12px !important;
    heigth: 12px !important;
  }
}
div.playlistCardContainer div.premiumStatus div.free {
  position: absolute;
  top: 6px;
  inset-inline-start: 6px;
  z-index: 8;
  width: 50px;
}
@media all and (max-width: 1200px) {
  div.playlistCardContainer div.premiumStatus div.free {
    width: 30px;
  }
}
div.playlistCardContainer div.premiumStatus div.free img {
  display: block;
  width: 100%;
}
div.playlistCardContainer.showContents {
  cursor: pointer;
}
div.playlistCardContainer.showContents:hover div.imageContainer div.extraActions {
  visibility: visible;
  animation: fadeIn 0.2s ease-in-out forwards;
}
div.playlistCardContainer.showContents:hover div.imageContainer img {
  transform: scale(1.1);
}
div.playlistCardContainer.showContents:hover div.metaData h1.title {
  color: var(--text-hover);
  overflow: hidden !important;
  text-overflow: ellipsis !important;
}
div.playlistCardContainer div.imageContainer {
  width: 100%;
  display: block;
  position: relative;
  overflow: hidden;
  border-radius: 3px;
}
div.playlistCardContainer div.imageContainer img {
  width: 100%;
  display: block;
  border-radius: 3px;
  transition: 0.2s ease-in-out;
}
div.playlistCardContainer div.imageContainer img.landscape {
  min-height: 130px;
  background: black;
}
div.playlistCardContainer div.imageContainer img.portrait {
  min-height: 420px;
  background: black;
}
div.playlistCardContainer div.imageContainer div.continueWatchingBar {
  position: absolute;
  bottom: 0;
  height: 6px;
  background-color: var(--text-color);
  width: 100%;
}
div.playlistCardContainer div.imageContainer div.continueWatchingBar div.line {
  height: 100%;
  background-color: var(--button-hover);
}
div.playlistCardContainer div.imageContainer div.extraActions {
  visibility: hidden;
  animation: fadeOut 0.2s ease-in-out forwards;
}
div.playlistCardContainer div.imageContainer div.extraActions div.showCardOverlay {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  background-color: var(--overlay);
  z-index: 8;
}
div.playlistCardContainer div.imageContainer div.extraActions div.playIconContainer {
  position: absolute;
  z-index: 9;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border: 3px solid var(--text-color);
  display: flex;
  justify-content: center;
  align-items: center;
  width: 60px;
  height: 60px;
  border-radius: 50%;
  transition: 0.3s ease-in-out;
  cursor: pointer;
}
@media all and (min-width: 320px) {
  div.playlistCardContainer div.imageContainer div.extraActions div.playIconContainer {
    width: 30px;
    height: 30px;
  }
  div.playlistCardContainer div.imageContainer div.extraActions div.playIconContainer svg {
    width: 13px !important;
    height: 25px !important;
  }
}
@media all and (min-width: 320px) {
  div.playlistCardContainer div.imageContainer div.extraActions div.playIconContainer {
    width: 50px;
    height: 50px;
  }
}
div.playlistCardContainer div.imageContainer div.extraActions div.playIconContainer:hover {
  border-color: var(--text-hover);
}
div.playlistCardContainer div.imageContainer div.extraActions div.playIconContainer:hover svg {
  color: var(--text-hover);
}
div.playlistCardContainer div.imageContainer div.extraActions div.playIconContainer svg {
  width: 13px !important;
  height: 13px !important;
  color: var(--text-color);
  transition: 0.3s ease-in-out;
}
@media all and (min-width: 320px) {
  div.playlistCardContainer div.imageContainer div.extraActions div.playIconContainer svg {
    width: 13px !important;
    height: 13px !important;
  }
}
@media all and (min-width: 480px) {
  div.playlistCardContainer div.imageContainer div.extraActions div.playIconContainer svg {
    width: 25px !important;
    height: 18px !important;
  }
}
div.playlistCardContainer div.imageContainer div.extraActions div.addToList {
  position: absolute;
  bottom: 3px;
  width: 96%;
  z-index: 9;
  left: 50%;
  border-radius: 8px;
  transform: translateX(-50%);
  padding: 6px;
  text-align: center;
  box-shadow: rgba(62, 1, 1, 0.03) 0px 16px 24px;
  background-color: rgba(49, 46, 46, 0.3);
  -webkit-backdrop-filter: blur(8px);
          backdrop-filter: blur(8px);
}
@media all and (min-width: 320px) {
  div.playlistCardContainer div.imageContainer div.extraActions div.addToList {
    padding: 0;
    border-radius: 2px;
    width: 97%;
  }
  div.playlistCardContainer div.imageContainer div.extraActions div.addToList span {
    font-size: 8px;
  }
}
@media all and (min-width: 480px) {
  div.playlistCardContainer div.imageContainer div.extraActions div.addToList {
    padding: 3px;
  }
  div.playlistCardContainer div.imageContainer div.extraActions div.addToList span {
    font-size: 10px;
  }
}
div.playlistCardContainer div.imageContainer div.extraActions div.addToList:hover span {
  color: var(--text-hover);
}
div.playlistCardContainer div.imageContainer div.extraActions div.addToList span {
  color: var(--text-color);
  font-size: 10px;
  transition: 0.3s ease-in-out;
}
@media all and (min-width: 480px) {
  div.playlistCardContainer div.imageContainer div.extraActions div.addToList span {
    font-size: 11px;
  }
}
div.playlistCardContainer div.metaData {
  color: var(--text-color);
  background-color: #2e2e2e;
  padding: 20px;
  border-color: #2e2e2e;
}
@media all and (min-width: 980px) {
  div.playlistCardContainer div.metaData {
    padding: 10px;
  }
}
div.playlistCardContainer div.metaData h1.title {
  color: var(--text-color);
  font-size: 25px;
  transition: 0.3s ease-in-out;
  overflow: hidden !important;
  text-overflow: ellipsis !important;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  white-space: nowrap;
}
@media all and (max-width: 1200px) {
  div.playlistCardContainer div.metaData h1.title {
    font-size: 20px;
    overflow: hidden !important;
    text-overflow: ellipsis !important;
  }
}
@media all and (max-width: 980px) {
  div.playlistCardContainer div.metaData h1.title {
    font-size: 20px;
  }
}
@media all and (min-width: 640px) {
  div.playlistCardContainer div.metaData h1.title {
    font-size: 25px;
  }
}
div.playlistCardContainer div.metaData div.datas {
  display: flex;
  justify-content: space-between;
  overflow: hidden !important;
  text-overflow: ellipsis !important;
}
div.playlistCardContainer div.metaData div.datas div.left span {
  font-size: 12px;
  margin-inline-end: 6px;
}
div.playlistCardContainer div.metaData div.datas div.left span.duration::before {
  margin-inline-end: 6px;
}
div.playlistCardContainer div.metaData div.datas div.left span:last-child {
  margin-inline-end: 0;
}
div.playlistCardContainer div.metaData div.datas div.right span {
  font-size: 14px;
  display: inline-block;
  padding: 1px 6px;
  background-color: rgba(150, 153, 163, 0.32);
  font-size: 12px;
  font-weight: 700;
  color: var(--text-color);
  border-radius: 4px;
}
@media all and (min-width: 320px) {
  div.playlistCardContainer div.metaData div.datas div.right span.certificate {
    font-size: 9px;
  }
}
div.playlistCardContainer div.metaData div.genres {
  font-size: 12px;
  display: flex;
  flex-wrap: wrap;
}
@media all and (min-width: 320px) {
  div.playlistCardContainer div.metaData div.genres {
    display: none;
  }
}
@media all and (min-width: 768px) {
  div.playlistCardContainer div.metaData div.genres {
    display: flex;
  }
}
div.playlistCardContainer div.metaData div.genres span {
  margin-inline-end: 6px;
}
div.playlistCardContainer div.metaData div.genres span:last-child {
  margin-inline-end: 0;
}
div.playlistCardContainer div.metaData div.genres span:first-child::before {
  display: none;
}
div.playlistCardContainer div.metaData div.genres span::before {
  content: "·";
  margin-inline-end: 6px;
}
div.playlistCardContainer div.metaData p.description {
  font-size: 15px;
  font-weight: 400;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2; /* number of lines to show */
  line-clamp: 2;
  -webkit-box-orient: vertical;
}
@media all and (max-width: 980px) {
  div.playlistCardContainer div.metaData p.description {
    font-size: 13px;
  }
}

div.featuredBannerType2 {
  position: relative;
  margin-top: 97px;
  min-height: 472px;
  overflow: hidden;
}
@media all and (max-width: 1400px) {
  div.featuredBannerType2 {
    min-height: 472px;
  }
}
@media all and (max-width: 1200px) {
  div.featuredBannerType2 {
    min-height: 342px;
  }
}
@media all and (max-width: 980px) {
  div.featuredBannerType2 {
    min-height: 457px;
  }
}
@media all and (max-width: 480px) {
  div.featuredBannerType2 {
    min-height: 400px;
  }
}
div.featuredBannerType2 div.loading {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  width: 100%;
  overflow: hidden;
}
div.featuredBannerType2 .swiper .swiper-slide-active h1.title {
  animation: slideLeft 0.5s ease-in-out;
}
div.featuredBannerType2 .swiper .swiper-slide-active p.description {
  animation: slideLeft 0.5s ease-in-out;
}
div.featuredBannerType2 .swiper .swiper-slide-active span.duration {
  animation: slideLeft 0.5s ease-in-out;
}
div.featuredBannerType2 .swiper .swiper-slide-active div.buttonContainer {
  animation: slideLeft 0.7s ease-in-out;
}
div.featuredBannerType2:hover .swiper-button-next, div.featuredBannerType2:hover .swiper-button-prev {
  opacity: 1;
}
div.featuredBannerType2 .swiper-button-next {
  opacity: 0;
  right: 0 !important;
  transform: translateY(-50%);
  width: 60px !important;
  background: rgb(0, 0, 0);
  background: linear-gradient(281deg, rgba(0, 0, 0, 0.1853991597) 0%, rgba(0, 0, 0, 0.5607492997) 31%, rgba(0, 0, 0, 0) 91%, rgba(105, 105, 105, 0.0957633053) 100%, rgba(0, 0, 0, 0) 100%);
  align-items: center;
  color: var(--swiper-theme-color);
  cursor: pointer;
  display: flex;
  height: 44px;
  justify-content: center;
  margin-top: -22px;
  position: absolute;
  top: 50%;
  width: 27px;
  z-index: 10;
}
@media all and (max-width: 980px) {
  div.featuredBannerType2 .swiper-button-next {
    display: none;
  }
}
div.featuredBannerType2 .swiper-button-next::after {
  background-color: transparent !important;
  color: #fff;
  font-size: 25px !important;
}
div.featuredBannerType2 .swiper-button-prev {
  opacity: 0;
  left: 0 !important;
  transform: translateY(-50%);
  width: 60px !important;
  background: rgb(0, 0, 0);
  background: linear-gradient(281deg, rgba(0, 0, 0, 0.1853991597) 0%, rgba(0, 0, 0, 0.5607492997) 31%, rgba(0, 0, 0, 0) 91%, rgba(105, 105, 105, 0.0957633053) 100%, rgba(0, 0, 0, 0) 100%);
  align-items: center;
  color: var(--swiper-theme-color);
  cursor: pointer;
  display: flex;
  height: 44px;
  justify-content: center;
  margin-top: -22px;
  position: absolute;
  top: 50%;
  width: 27px;
  z-index: 10;
}
@media all and (max-width: 980px) {
  div.featuredBannerType2 .swiper-button-prev {
    display: none;
  }
}
div.featuredBannerType2 .swiper-button-prev::after {
  background: rgb(0, 0, 0);
  background: linear-gradient(281deg, rgba(0, 0, 0, 0.1853991597) 0%, rgba(0, 0, 0, 0.5607492997) 31%, rgba(0, 0, 0, 0) 91%, rgba(105, 105, 105, 0.0957633053) 100%, rgba(0, 0, 0, 0) 100%);
  color: #fff;
  font-size: 25px !important;
}
div.featuredBannerType2 div.bannerContainer {
  display: flex;
  align-items: center;
  background-color: var(--black-color);
  justify-content: space-between;
  animation: fadeIn 0.4s ease-in-out;
}
div.featuredBannerType2 div.bannerContainer .fadeGradientBottom {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 20%;
  z-index: 1;
  background: linear-gradient(to top, rgb(16, 20, 31) 0%, rgba(0, 0, 0, 0.7) 30%, rgba(0, 0, 0, 0) 100%);
  pointer-events: none;
}
@media all and (max-width: 980px) {
  div.featuredBannerType2 div.bannerContainer .fadeGradientBottom {
    display: none;
  }
}
@media all and (max-width: 980px) {
  div.featuredBannerType2 div.bannerContainer {
    flex-direction: column-reverse;
  }
}
div.featuredBannerType2 div.bannerContainer div.left {
  width: 35%;
  height: 100%;
  padding-left: 60px;
  padding-top: 30px;
  z-index: 9;
}
@media all and (max-width: 980px) {
  div.featuredBannerType2 div.bannerContainer div.left {
    width: 100%;
  }
}
@media all and (max-width: 768px) {
  div.featuredBannerType2 div.bannerContainer div.left {
    padding-left: 30px;
    padding-right: 30px;
  }
}
@media all and (max-width: 480px) {
  div.featuredBannerType2 div.bannerContainer div.left {
    padding-top: 100px;
  }
}
@media all and (max-width: 480px) {
  div.featuredBannerType2 div.bannerContainer div.left {
    padding-top: 105px;
  }
}
div.featuredBannerType2 div.bannerContainer div.left div.contents {
  position: absolute;
  width: 40%;
  transform: translateY(-50%);
  top: 50%;
}
@media all and (max-width: 1280px) {
  div.featuredBannerType2 div.bannerContainer div.left div.contents {
    width: 45%;
  }
}
@media all and (max-width: 980px) {
  div.featuredBannerType2 div.bannerContainer div.left div.contents {
    position: relative;
    width: 100%;
  }
}
div.featuredBannerType2 div.bannerContainer div.left h1.title {
  font-size: 40px;
  color: var(--text-color);
  margin-bottom: 10px;
  font-family: Gilroy-SemiBold !important;
}
@media all and (max-width: 1300px) {
  div.featuredBannerType2 div.bannerContainer div.left h1.title {
    font-size: 36px;
  }
}
@media all and (max-width: 480px) {
  div.featuredBannerType2 div.bannerContainer div.left h1.title {
    font-size: 27px;
  }
}
div.featuredBannerType2 div.bannerContainer div.left span.duration {
  color: var(--inner-text-color);
  display: inline-block;
  margin-bottom: 15px;
}
div.featuredBannerType2 div.bannerContainer div.left p.description {
  color: var(--text-color);
  margin-bottom: 20px;
  font-family: Gilroy-medium;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
@media all and (max-width: 640px) {
  div.featuredBannerType2 div.bannerContainer div.left p.description {
    display: none;
  }
}
div.featuredBannerType2 div.bannerContainer div.left div.buttonsWrapper {
  display: flex;
}
div.featuredBannerType2 div.bannerContainer div.left div.buttonContainer {
  display: flex;
  padding: 18px 20px;
  border-radius: 4px;
  align-items: center;
  margin-bottom: 15px;
  cursor: pointer;
  transition: 0.2s ease-in-out;
  justify-content: center;
  position: relative;
  width: 12rem;
  margin-right: 10px;
}
@media all and (max-width: 1280px) {
  div.featuredBannerType2 div.bannerContainer div.left div.buttonContainer {
    width: 11rem;
    padding: 13px 19px;
  }
}
@media all and (max-width: 480px) {
  div.featuredBannerType2 div.bannerContainer div.left div.buttonContainer {
    width: 8rem;
    padding: 9px 19px;
  }
}
div.featuredBannerType2 div.bannerContainer div.left div.buttonContainer:hover div.background {
  transform: scale(1.0476190476, 1.0357142857);
}
div.featuredBannerType2 div.bannerContainer div.left div.buttonContainer div.background {
  background: var(--button-color);
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border-radius: 4px;
  transition: 0.1s ease-in-out;
}
div.featuredBannerType2 div.bannerContainer div.left div.buttonContainer div.Trailerbackground {
  background: var(--secondary-bg-color);
}
div.featuredBannerType2 div.bannerContainer div.left div.buttonContainer div.Trailerbackground .button {
  color: white !important;
}
div.featuredBannerType2 div.bannerContainer div.left div.buttonContainer button {
  background: transparent;
  font-size: 15px;
  color: black;
  font-weight: 600;
  z-index: 1;
  cursor: pointer;
}
div.featuredBannerType2 div.bannerContainer div.left div.buttonContainer button.watchNowButton {
  color: var(--black-color);
}
div.featuredBannerType2 div.bannerContainer div.left div.buttonContainer button.trailerButton {
  color: var(--white-color);
}
div.featuredBannerType2 div.bannerContainer div.right {
  width: 62%;
}
@media all and (max-width: 980px) {
  div.featuredBannerType2 div.bannerContainer div.right {
    width: 100%;
  }
}
div.featuredBannerType2 div.bannerContainer div.right div.fadeGradient {
  position: absolute;
  left: 27%;
  width: 30%;
  height: 100%;
  background: linear-gradient(89deg, rgb(0, 0, 0) 45%, rgba(0, 0, 0, 0.6083683473) 68%, rgba(0, 0, 0, 0) 91%, rgba(0, 0, 0, 0) 100%, rgba(105, 105, 105, 0.0957633053) 100%);
}
@media all and (max-width: 980px) {
  div.featuredBannerType2 div.bannerContainer div.right div.fadeGradient {
    left: 0;
    width: 100%;
    background: linear-gradient(0deg, rgb(0, 0, 0) 45%, rgba(0, 0, 0, 0.6083683473) 68%, rgba(0, 0, 0, 0) 91%, rgba(0, 0, 0, 0) 100%, rgba(105, 105, 105, 0.0957633053) 100%);
    bottom: 143px;
    height: 31%;
  }
}
@media all and (max-width: 768px) {
  div.featuredBannerType2 div.bannerContainer div.right div.fadeGradient {
    height: 38%;
  }
}
@media all and (max-width: 480px) {
  div.featuredBannerType2 div.bannerContainer div.right div.fadeGradient {
    height: 38%;
    bottom: 74px;
  }
}
div.featuredBannerType2 div.bannerContainer div.right div.imageContainer {
  width: 100%;
}
div.featuredBannerType2 div.bannerContainer div.right div.imageContainer.loading {
  height: 600px;
  background-color: var(--black-color);
}
div.featuredBannerType2 div.bannerContainer div.right div.imageContainer img {
  width: 100%;
  display: block;
}

@keyframes shimmer {
  100% {
    transform: translateX(100%);
  }
}
@keyframes slideLeft {
  0% {
    transform: translateX(43px);
  }
  100% {
    transform: translateX(0);
  }
}
div.featuredBannerType2.rtl {
  direction: ltr;
  text-align: left;
}
div.featuredBannerType2.rtl div.bannerContainer {
  direction: rtl;
  text-align: right;
}
div.featuredBannerType2.rtl div.bannerContainer .fadeGradientBottom {
  left: unset;
  right: 0;
  background: linear-gradient(to top, rgb(16, 20, 31) 0%, rgba(0, 0, 0, 0.7) 30%, rgba(0, 0, 0, 0) 100%);
}
div.featuredBannerType2.rtl div.bannerContainer div.left {
  padding-left: unset;
  padding-right: 60px;
}
div.featuredBannerType2.rtl div.bannerContainer div.left div.buttonContainer {
  margin-right: unset;
  margin-left: 10px;
}
div.featuredBannerType2.rtl div.bannerContainer div.right div.fadeGradient {
  left: unset;
  right: 27%;
  background: linear-gradient(269deg, rgb(0, 0, 0) 45%, rgba(0, 0, 0, 0.6083683473) 68%, rgba(0, 0, 0, 0) 91%, rgba(0, 0, 0, 0) 100%, rgba(105, 105, 105, 0.0957633053) 100%);
}
@media all and (max-width: 980px) {
  div.featuredBannerType2.rtl div.bannerContainer div.right div.fadeGradient {
    left: unset;
    right: 0;
    background: linear-gradient(0deg, rgb(0, 0, 0) 45%, rgba(0, 0, 0, 0.6083683473) 68%, rgba(0, 0, 0, 0) 91%, rgba(0, 0, 0, 0) 100%, rgba(105, 105, 105, 0.0957633053) 100%);
  }
}

div.b2bdetailsScreen {
  padding: 100px 0 0;
  position: relative;
}
div.b2bdetailsScreen div.expired-b2b {
  padding: 120px !important;
  background: var(--default-bg-color);
  display: flex;
  justify-content: center;
  display: flex;
  flex-direction: column-reverse;
  justify-content: center;
  align-items: center;
}
div.b2bdetailsScreen div.expired-b2b span {
  color: red;
}
div.b2bdetailsScreen div.expired-b2b div.warningText {
  padding-bottom: 20px !important;
}
div.b2bdetailsScreen div.expired-b2b div.warningIcon {
  line-height: 0;
  padding-inline-end: 10px;
}
div.b2bdetailsScreen div.expired-b2b div.warningIcon svg {
  width: 30px !important;
  height: 30px !important;
  fill: white;
}
div.b2bdetailsScreen .swiper-button-next {
  inset-inline-end: -9px !important;
  top: 45%;
}
@media all and (min-width: 1200px) {
  div.b2bdetailsScreen .swiper-button-next {
    inset-inline-end: 0 !important;
  }
}
div.b2bdetailsScreen .swiper-button-prev {
  inset-inline-start: -9px !important;
  top: 45%;
}
@media all and (min-width: 1200px) {
  div.b2bdetailsScreen .swiper-button-prev {
    inset-inline-start: 0 !important;
  }
}
div.b2bdetailsScreen div.wrapper {
  position: relative;
  z-index: 8;
}
div.b2bdetailsScreen div.bgImageContainer {
  display: block;
  position: fixed;
  top: 0;
  height: 100%;
  z-index: 7;
  width: 100%;
}
div.b2bdetailsScreen div.bgImageContainer div.gradient {
  position: absolute;
  bottom: 0;
  z-index: 8;
  width: 100%;
  height: 100%;
  background-position: top;
  background-size: cover;
  background-repeat: no-repeat;
  background: linear-gradient(78.83deg, #10141f, rgba(16, 20, 31, 0) 260.46%), linear-gradient(1turn, #10141f, rgba(16, 20, 31, 0) 48.44%, rgba(16, 20, 31, 0.4)), rgba(16, 20, 31, 0.4);
}
div.b2bdetailsScreen div.bgImageContainer img {
  width: 100%;
  display: block;
}
div.b2bdetailsScreen div.trailer {
  width: 90%;
  margin: 0 auto;
}
div.b2bdetailsScreen div.trailer div.trailerVideoPlayer {
  margin-bottom: 40px;
}
div.b2bdetailsScreen div.trailer div.videoTitle {
  padding: 0 0 30px;
}
div.b2bdetailsScreen div.trailer div.videoTitle h1.title {
  color: white;
}
div.b2bdetailsScreen div.trailer div.videoTitle div.trailerDownload {
  width: 15%;
  margin-top: 15px;
}
div.b2bdetailsScreen div.trailer div.videoTitle div.trailerDownload button {
  width: 100%;
  display: block;
  background-color: rgb(255, 255, 255);
  padding: 15px 10px;
  font-size: 15px;
  color: black;
  font-weight: 600;
  border-radius: 8px;
  cursor: pointer;
  transition: 0.3s ease-in-out;
}
@media screen and (max-width: 980px) {
  div.b2bdetailsScreen div.trailer div.videoTitle div.trailerDownload button {
    width: 88px;
    height: 39px;
    padding: 0;
  }
}
@media screen and (max-width: 640px) {
  div.b2bdetailsScreen div.trailer div.videoTitle h1.title {
    font-size: 0.9rem;
  }
}
div.b2bdetailsScreen div.videoContainer {
  background-color: #000;
  width: 100%;
}
div.b2bdetailsScreen div.videoContainer.heightIncrease {
  height: 38em;
}
@media all and (max-width: 1200px) {
  div.b2bdetailsScreen div.videoContainer.heightIncrease {
    height: 25em;
  }
}
div.b2bdetailsScreen div.videoContainer div.gradientTop {
  position: absolute;
  top: 0;
  z-index: 8;
  width: 100%;
  height: 20%;
  background-position: top;
  background-size: cover;
  background-repeat: no-repeat;
  background: linear-gradient(0deg, rgba(16, 20, 31, 0), rgba(16, 20, 31, 0.2) 19%, rgba(16, 20, 31, 0.4) 39%, rgba(16, 20, 31, 0.8));
}
div.b2bdetailsScreen div.videoContainer div.player {
  position: relative;
  z-index: 9;
  width: 100%;
  display: block;
}
div.b2bdetailsScreen div.videoContainer div.player div.closeIcon {
  display: none;
}
div.b2bdetailsScreen div.videoContainer div.player div.nowPlaying {
  position: absolute;
  top: 20px;
  inset-inline-end: 20px;
  z-index: 7;
}
div.b2bdetailsScreen div.videoContainer div.player div.nowPlaying h1 {
  color: var(--text-color);
}
@media all and (max-width: 1200px) {
  div.b2bdetailsScreen div.videoContainer div.player div.nowPlaying h1 {
    font-size: 23px;
  }
}
@media all and (max-width: 980px) {
  div.b2bdetailsScreen div.videoContainer div.player div.nowPlaying h1 {
    font-size: 20px;
  }
}
@media all and (max-width: 768px) {
  div.b2bdetailsScreen div.videoContainer div.player div.nowPlaying h1 {
    font-size: 18px;
  }
}
@media all and (max-width: 640px) {
  div.b2bdetailsScreen div.videoContainer div.player div.nowPlaying h1 {
    font-size: 16px;
  }
}
@media all and (max-width: 480px) {
  div.b2bdetailsScreen div.videoContainer div.player div.nowPlaying h1 {
    font-size: 14px;
  }
}
div.b2bdetailsScreen div.videoContainer div.player.pipMode {
  position: fixed;
  top: 101.5px;
  width: 20%;
  inset-inline-end: 50px;
  z-index: 999999999;
  height: 160px;
}
@media all and (max-width: 768px) {
  div.b2bdetailsScreen div.videoContainer div.player.pipMode {
    width: 40%;
  }
}
@media all and (max-width: 640px) {
  div.b2bdetailsScreen div.videoContainer div.player.pipMode {
    width: 50%;
  }
}
@media all and (max-width: 480px) {
  div.b2bdetailsScreen div.videoContainer div.player.pipMode {
    width: 68%;
  }
}
div.b2bdetailsScreen div.videoContainer div.player.pipMode video {
  -o-object-fit: cover;
     object-fit: cover;
  height: 100%;
}
div.b2bdetailsScreen div.videoContainer div.player.pipMode .video-js {
  padding: 0;
  height: 100%;
}
div.b2bdetailsScreen div.videoContainer div.player.pipMode div.gradientTop {
  display: none;
}
div.b2bdetailsScreen div.videoContainer div.player.pipMode div.nowPlaying {
  display: none;
}
div.b2bdetailsScreen div.videoContainer div.player.pipMode:hover div.closeIcon {
  position: absolute;
  top: 10px;
  inset-inline-start: 10px;
  z-index: 9;
  background-color: var(--bg-color);
  height: 25px;
  width: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
}
div.b2bdetailsScreen div.videoContainer div.player.pipMode:hover div.closeIcon svg {
  color: var(--text-color);
}
div.b2bdetailsScreen div.videoContainer div.player.pipMode:hover div.gradientTop {
  display: block;
}
div.b2bdetailsScreen div.videoContainer video {
  display: block;
  width: 100%;
}
div.b2bdetailsScreen div.details {
  position: relative;
  z-index: 8;
  display: flex;
  padding: 30px 0 130px;
}
@media all and (max-width: 980px) {
  div.b2bdetailsScreen div.details {
    flex-direction: column;
  }
}
div.b2bdetailsScreen div.details div.reportProblemContainer {
  position: absolute;
  bottom: -99px;
  z-index: 9;
  background-color: #1c1f29;
  border: 1px solid var(--border-color);
  width: 100%;
  border-radius: 8px;
  padding: 15px;
  display: none;
  cursor: pointer;
}
div.b2bdetailsScreen div.details div.reportProblemContainer.active {
  display: block;
}
@media all and (max-width: 1200px) {
  div.b2bdetailsScreen div.details div.reportProblemContainer {
    width: 100%;
  }
}
@media all and (max-width: 980px) {
  div.b2bdetailsScreen div.details div.reportProblemContainer {
    width: 100%;
    position: relative;
    bottom: 0;
    margin-bottom: 10px;
    margin-top: 10px;
  }
}
div.b2bdetailsScreen div.details div.reportProblemContainer div.modal-content {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
div.b2bdetailsScreen div.details div.reportProblemContainer div.closeIcon {
  position: absolute;
  top: 4px;
  inset-inline-end: 3px;
  z-index: 9;
  background-color: var(--bg-color);
  height: 25px;
  width: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
}
div.b2bdetailsScreen div.details div.reportProblemContainer div.closeIcon svg {
  color: var(--text-color);
}
div.b2bdetailsScreen div.details div.reportProblemContainer span {
  color: var(--text-color);
  margin: 3px;
}
div.b2bdetailsScreen div.details div.reportProblemContainer span:hover {
  color: #a9a9a9;
}
div.b2bdetailsScreen div.details div.reportProblemContainer::after {
  content: "";
  top: -9px;
  width: 0;
  height: 0;
  border-color: transparent transparent rgba(240, 241, 245, 0.16);
  border-style: solid;
  border-width: 0 8px 8px;
  position: absolute;
  inset-inline-end: 46%;
  transform: translateX(-50%);
}
@media all and (max-width: 1200px) {
  div.b2bdetailsScreen div.details div.reportProblemContainer::after {
    inset-inline-end: 43%;
  }
}
@media all and (max-width: 980px) {
  div.b2bdetailsScreen div.details div.reportProblemContainer::after {
    inset-inline-end: 49%;
  }
}
div.b2bdetailsScreen div.details div.watchNow {
  width: 100%;
  margin-top: 15px;
}
div.b2bdetailsScreen div.details div.watchNow button {
  width: 100%;
  display: block;
  background: var(--button-color);
  padding: 15px 10px;
  font-size: 15px;
  color: var(--text-color);
  font-weight: 600;
  border-radius: 8px;
  cursor: pointer;
  transition: 0.3s ease-in-out;
}
div.b2bdetailsScreen div.details div.watchNow button:hover {
  background: var(--button-hover);
}
div.b2bdetailsScreen div.details div.trailerWatchNowButton {
  color: #10141f;
  background-color: #10141f;
  width: 100%;
  margin-top: 15px;
}
div.b2bdetailsScreen div.details div.trailerWatchNowButton button {
  width: 100%;
  display: block;
  background-color: rgba(150, 153, 163, 0.16);
  padding: 15px 10px;
  font-size: 15px;
  color: var(--text-color);
  font-weight: 600;
  border-radius: 8px;
  cursor: pointer;
  transition: 0.3s ease-in-out;
}
div.b2bdetailsScreen div.details div.trailerWatchNowButton button:hover {
  background: var(--button-hover);
}
div.b2bdetailsScreen div.details div.addtoList {
  width: 100%;
  margin-top: 15px;
}
div.b2bdetailsScreen div.details div.addtoList button {
  width: 100%;
  display: block;
  background-color: rgb(255, 255, 255);
  padding: 15px 10px;
  font-size: 15px;
  color: black;
  font-weight: 600;
  border-radius: 8px;
  cursor: pointer;
  transition: 0.3s ease-in-out;
}
div.b2bdetailsScreen div.details div.buttons {
  margin-top: 15px;
  display: flex;
  width: 100%;
}
div.b2bdetailsScreen div.details div.buttons div.buttonContainerOne {
  width: 70%;
  position: relative;
  margin-inline-end: 10px;
}
@media all and (max-width: 980px) {
  div.b2bdetailsScreen div.details div.buttons div.buttonContainerOne {
    width: 90%;
  }
}
@media all and (max-width: 640px) {
  div.b2bdetailsScreen div.details div.buttons div.buttonContainerOne {
    width: 80%;
  }
}
@media all and (max-width: 480px) {
  div.b2bdetailsScreen div.details div.buttons div.buttonContainerOne {
    width: 75%;
  }
}
div.b2bdetailsScreen div.details div.buttons div.buttonContainerOne div.shareContainer {
  position: absolute;
  bottom: -126px;
  inset-inline-start: -20px;
  z-index: 9;
  background-color: #1c1f29;
  border: 1px solid var(--border-color);
  width: 232px;
  border-radius: 8px;
  display: none;
}
@media all and (max-width: 1200px) {
  div.b2bdetailsScreen div.details div.buttons div.buttonContainerOne div.shareContainer {
    width: 200px;
  }
}
@media all and (max-width: 980px) {
  div.b2bdetailsScreen div.details div.buttons div.buttonContainerOne div.shareContainer {
    width: 232px;
    inset-inline-start: 50%;
    transform: translateX(-50%);
  }
}
@media all and (max-width: 480px) {
  div.b2bdetailsScreen div.details div.buttons div.buttonContainerOne div.shareContainer {
    width: 100%;
  }
}
div.b2bdetailsScreen div.details div.buttons div.buttonContainerOne div.shareContainer.active {
  display: block;
}
div.b2bdetailsScreen div.details div.buttons div.buttonContainerOne div.shareContainer::after {
  content: "";
  top: -9px;
  width: 0;
  height: 0;
  border-color: transparent transparent rgba(240, 241, 245, 0.16);
  border-style: solid;
  border-width: 0 8px 8px;
  position: absolute;
  inset-inline-start: 50%;
  transform: translateX(-50%);
}
@media all and (max-width: 1200px) {
  div.b2bdetailsScreen div.details div.buttons div.buttonContainerOne div.shareContainer::after {
    inset-inline-start: 40%;
  }
}
@media all and (max-width: 980px) {
  div.b2bdetailsScreen div.details div.buttons div.buttonContainerOne div.shareContainer::after {
    inset-inline-start: 50%;
  }
}
div.b2bdetailsScreen div.details div.buttons div.buttonContainerOne div.shareContainer div.facebookContainer button.facebook {
  display: flex !important;
  justify-content: space-between !important;
  padding: 15px !important;
  color: var(--text-color) !important;
  cursor: pointer !important;
  border-bottom: 1px solid var(--border-color) !important;
  width: 100% !important;
}
div.b2bdetailsScreen div.details div.buttons div.buttonContainerOne div.shareContainer div.facebookContainer button.facebook div.icon {
  width: 10%;
}
div.b2bdetailsScreen div.details div.buttons div.buttonContainerOne div.shareContainer div.facebookContainer button.facebook div.icon svg {
  width: 20px !important;
  height: 20px !important;
  color: var(--grey-color);
  transition: 0.3s ease-in-out;
}
div.b2bdetailsScreen div.details div.buttons div.buttonContainerOne div.shareContainer div.facebookContainer button.facebook div.icon svg:hover {
  color: var(--text-color);
}
div.b2bdetailsScreen div.details div.buttons div.buttonContainerOne div.shareContainer div.facebookContainer div.left {
  width: 10%;
}
div.b2bdetailsScreen div.details div.buttons div.buttonContainerOne div.shareContainer div.facebookContainer div.right svg {
  width: 20px !important;
  height: 20px !important;
  color: var(--grey-color);
  transition: 0.3s ease-in-out;
}
div.b2bdetailsScreen div.details div.buttons div.buttonContainerOne div.shareContainer div.facebookContainer div.right svg:hover {
  color: var(--text-color);
}
div.b2bdetailsScreen div.details div.buttons div.buttonContainerOne div.shareContainer div.twitterContainer button.twitter {
  display: flex !important;
  justify-content: space-between !important;
  padding: 15px !important;
  color: var(--text-color) !important;
  cursor: pointer !important;
  width: 100% !important;
}
div.b2bdetailsScreen div.details div.buttons div.buttonContainerOne div.shareContainer div.twitterContainer button.twitter div.icon {
  width: 10%;
}
div.b2bdetailsScreen div.details div.buttons div.buttonContainerOne div.shareContainer div.twitterContainer button.twitter div.icon svg {
  width: 20px !important;
  height: 20px !important;
  color: var(--grey-color);
  transition: 0.3s ease-in-out;
}
div.b2bdetailsScreen div.details div.buttons div.buttonContainerOne div.shareContainer div.twitterContainer button.twitter div.icon svg:hover {
  color: var(--text-color);
}
div.b2bdetailsScreen div.details div.buttons div.buttonContainerOne div.shareContainer div.twitterContainer div.left {
  width: 10%;
}
div.b2bdetailsScreen div.details div.buttons div.buttonContainerOne div.shareContainer div.twitterContainer div.right svg {
  width: 20px !important;
  height: 20px !important;
  color: var(--grey-color);
  transition: 0.3s ease-in-out;
}
div.b2bdetailsScreen div.details div.buttons div.buttonContainerOne div.shareContainer div.twitterContainer div.right svg:hover {
  color: var(--text-color);
}
div.b2bdetailsScreen div.details div.buttons div.buttonContainerOne button.share {
  display: block;
  background-color: rgba(150, 153, 163, 0.16);
  padding: 15px 10px;
  font-size: 15px;
  color: var(--text-color);
  font-weight: 600;
  border-radius: 8px;
  width: 100%;
  cursor: pointer;
  transition: 0.3s ease-in-out;
}
div.b2bdetailsScreen div.details div.buttons div.buttonContainerOne button.share:hover {
  background-color: rgba(203, 203, 208, 0.16);
}
div.b2bdetailsScreen div.details div.buttons div.buttonContainerTwo {
  width: 30%;
  position: relative;
}
@media all and (max-width: 980px) {
  div.b2bdetailsScreen div.details div.buttons div.buttonContainerTwo {
    width: 10%;
  }
}
@media all and (max-width: 640px) {
  div.b2bdetailsScreen div.details div.buttons div.buttonContainerTwo {
    width: 18%;
  }
}
@media all and (max-width: 480px) {
  div.b2bdetailsScreen div.details div.buttons div.buttonContainerTwo {
    width: 25%;
  }
}
div.b2bdetailsScreen div.details div.buttons div.buttonContainerTwo button.more {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(150, 153, 163, 0.16);
  padding: 15px 10px;
  font-size: 15px;
  color: var(--text-color);
  border-radius: 8px;
  width: 100%;
  cursor: pointer;
  transition: 0.3s ease-in-out;
}
div.b2bdetailsScreen div.details div.buttons div.buttonContainerTwo button.more svg {
  width: 20px !important;
  height: 20px !important;
}
div.b2bdetailsScreen div.details div.buttons div.buttonContainerTwo button.more:hover {
  background-color: rgba(203, 203, 208, 0.16);
}
div.b2bdetailsScreen div.details div.subscriptions {
  margin-top: 20px;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
}
div.b2bdetailsScreen div.details div.subscriptions div.items {
  cursor: pointer;
  margin-inline-end: 10px;
  background-color: var(--yellow-color);
  padding: 10px 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 13px;
  font-weight: 600;
  color: var(--text-color);
  margin-bottom: 20px;
  border-radius: 30px;
}
div.b2bdetailsScreen div.details div.subscriptions div.items.free {
  background-color: var(--success-color);
}
div.b2bdetailsScreen div.details div.leftContainer {
  width: 27%;
  margin-inline-end: 40px;
}
@media all and (max-width: 980px) {
  div.b2bdetailsScreen div.details div.leftContainer {
    display: none;
  }
}
div.b2bdetailsScreen div.details div.leftContainer div.showCardDetails {
  width: 100%;
  position: sticky;
  top: 0;
}
div.b2bdetailsScreen div.details div.leftContainer div.showCardDetails div.imageContainer {
  width: 100%;
}
div.b2bdetailsScreen div.details div.leftContainer div.showCardDetails div.imageContainer img {
  width: 100%;
  display: block;
  border-radius: 5px;
}
div.b2bdetailsScreen div.details div.rightContainer {
  width: 100%;
  overflow-y: scroll;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}
div.b2bdetailsScreen div.details div.rightContainer::-webkit-scrollbar {
  display: none;
}
div.b2bdetailsScreen div.details div.rightContainer div.showMetadata {
  border-bottom: 1px solid var(--border-color);
  padding-bottom: 40px;
  width: 100%;
}
div.b2bdetailsScreen div.details div.rightContainer div.showMetadata h1.name {
  font-size: 58px;
  font-weight: 700;
  color: var(--text-color);
}
@media all and (max-width: 1200px) {
  div.b2bdetailsScreen div.details div.rightContainer div.showMetadata h1.name {
    font-size: 48px;
  }
}
@media all and (max-width: 640px) {
  div.b2bdetailsScreen div.details div.rightContainer div.showMetadata h1.name {
    font-size: 35px;
  }
}
div.b2bdetailsScreen div.details div.rightContainer div.showMetadata div.data {
  display: flex;
  margin-bottom: 6px;
}
div.b2bdetailsScreen div.details div.rightContainer div.showMetadata div.data span {
  font-size: 18px;
  color: var(--inner-text-color);
}
@media all and (max-width: 640px) {
  div.b2bdetailsScreen div.details div.rightContainer div.showMetadata div.data span {
    font-size: 14px;
  }
}
div.b2bdetailsScreen div.details div.rightContainer div.showMetadata div.data span.year {
  margin-inline-end: 6px;
}
div.b2bdetailsScreen div.details div.rightContainer div.showMetadata div.data span.duration {
  margin-inline-end: 8px;
}
div.b2bdetailsScreen div.details div.rightContainer div.showMetadata div.data span.duration.showDot::before {
  content: "·";
  margin-inline-end: 6px;
}
div.b2bdetailsScreen div.details div.rightContainer div.showMetadata div.data span.certificate {
  display: block;
  padding: 4px 6px;
  background-color: rgba(150, 153, 163, 0.32);
  font-size: 11px;
  font-weight: 700;
  color: var(--text-color);
  border-radius: 4px;
}
@media all and (max-width: 640px) {
  div.b2bdetailsScreen div.details div.rightContainer div.showMetadata div.data span.certificate {
    font-size: 10px;
  }
}
div.b2bdetailsScreen div.details div.rightContainer div.showMetadata div.genres {
  margin-bottom: 10px;
}
div.b2bdetailsScreen div.details div.rightContainer div.showMetadata div.genres span {
  font-size: 18px;
  color: var(--inner-text-color);
  margin-inline-end: 6px;
}
@media all and (max-width: 640px) {
  div.b2bdetailsScreen div.details div.rightContainer div.showMetadata div.genres span {
    font-size: 14px;
  }
}
div.b2bdetailsScreen div.details div.rightContainer div.showMetadata div.genres span::before {
  content: "·";
  margin-inline-end: 6px;
}
div.b2bdetailsScreen div.details div.rightContainer div.showMetadata div.genres span:first-child::before {
  display: none;
}
div.b2bdetailsScreen div.details div.rightContainer div.showMetadata div.genres span:last-child {
  margin-inline-end: 0;
}
div.b2bdetailsScreen div.details div.rightContainer div.showMetadata div.watchNow {
  display: none;
}
@media all and (max-width: 980px) {
  div.b2bdetailsScreen div.details div.rightContainer div.showMetadata div.watchNow {
    display: block;
  }
}
div.b2bdetailsScreen div.details div.rightContainer div.showMetadata div.trailerWatchNowButton {
  display: none;
}
@media all and (max-width: 980px) {
  div.b2bdetailsScreen div.details div.rightContainer div.showMetadata div.trailerWatchNowButton {
    display: block;
  }
}
div.b2bdetailsScreen div.details div.rightContainer div.showMetadata div.addtoList {
  display: none;
}
@media all and (max-width: 980px) {
  div.b2bdetailsScreen div.details div.rightContainer div.showMetadata div.addtoList {
    display: block;
  }
}
div.b2bdetailsScreen div.details div.rightContainer div.showMetadata div.buttons {
  display: none;
  margin-bottom: 20px;
}
@media all and (max-width: 980px) {
  div.b2bdetailsScreen div.details div.rightContainer div.showMetadata div.buttons {
    display: flex;
  }
}
div.b2bdetailsScreen div.details div.rightContainer div.showMetadata p.description {
  font-size: 15px;
  color: var(--text-color);
  line-height: 1.4em;
  letter-spacing: 0.3px;
  max-width: 100%;
}
@media all and (max-width: 1200px) {
  div.b2bdetailsScreen div.details div.rightContainer div.showMetadata p.description {
    max-width: 100%;
  }
}
div.b2bdetailsScreen div.details div.rightContainer div.showMetadata div.info {
  margin-top: 20px;
}
div.b2bdetailsScreen div.details div.rightContainer div.showMetadata div.info div.starring {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
}
div.b2bdetailsScreen div.details div.rightContainer div.showMetadata div.info div.starring div.left {
  width: 15%;
}
@media all and (max-width: 768px) {
  div.b2bdetailsScreen div.details div.rightContainer div.showMetadata div.info div.starring div.left {
    width: 20%;
  }
}
@media all and (max-width: 640px) {
  div.b2bdetailsScreen div.details div.rightContainer div.showMetadata div.info div.starring div.left {
    width: 25%;
  }
}
@media all and (max-width: 480px) {
  div.b2bdetailsScreen div.details div.rightContainer div.showMetadata div.info div.starring div.left {
    width: 30%;
  }
}
@media all and (max-width: 360px) {
  div.b2bdetailsScreen div.details div.rightContainer div.showMetadata div.info div.starring div.left {
    width: 33%;
  }
}
div.b2bdetailsScreen div.details div.rightContainer div.showMetadata div.info div.starring div.left h6 {
  font-size: 16px;
  font-weight: 400;
  color: var(--inner-text-color);
}
div.b2bdetailsScreen div.details div.rightContainer div.showMetadata div.info div.starring div.right {
  width: 85%;
}
@media all and (max-width: 980px) {
  div.b2bdetailsScreen div.details div.rightContainer div.showMetadata div.info div.starring div.right {
    width: 75%;
  }
}
@media all and (max-width: 640px) {
  div.b2bdetailsScreen div.details div.rightContainer div.showMetadata div.info div.starring div.right {
    width: 70%;
  }
}
@media all and (max-width: 480px) {
  div.b2bdetailsScreen div.details div.rightContainer div.showMetadata div.info div.starring div.right {
    width: 65%;
  }
}
@media all and (max-width: 360px) {
  div.b2bdetailsScreen div.details div.rightContainer div.showMetadata div.info div.starring div.right {
    width: 55%;
  }
}
div.b2bdetailsScreen div.details div.rightContainer div.showMetadata div.info div.starring div.right ul.names {
  display: flex;
  flex-wrap: wrap;
}
div.b2bdetailsScreen div.details div.rightContainer div.showMetadata div.info div.starring div.right ul.names li {
  font-size: 16px;
  font-weight: 600;
  margin-inline-end: 8px;
  color: var(--text-color);
}
div.b2bdetailsScreen div.details div.rightContainer div.showMetadata div.info div.starring div.right ul.names li:last-child {
  margin-inline-end: 0;
}
@media all and (max-width: 480px) {
  div.b2bdetailsScreen div.details div.rightContainer div.showMetadata div.info div.starring div.right ul.names li {
    font-size: 14px;
  }
}
div.b2bdetailsScreen div.details div.rightContainer div.showMetadata div.info div.director {
  display: flex;
  justify-content: space-between;
}
div.b2bdetailsScreen div.details div.rightContainer div.showMetadata div.info div.director div.left {
  width: 15%;
}
@media all and (max-width: 768px) {
  div.b2bdetailsScreen div.details div.rightContainer div.showMetadata div.info div.director div.left {
    width: 20%;
  }
}
@media all and (max-width: 640px) {
  div.b2bdetailsScreen div.details div.rightContainer div.showMetadata div.info div.director div.left {
    width: 25%;
  }
}
@media all and (max-width: 480px) {
  div.b2bdetailsScreen div.details div.rightContainer div.showMetadata div.info div.director div.left {
    width: 30%;
  }
}
@media all and (max-width: 360px) {
  div.b2bdetailsScreen div.details div.rightContainer div.showMetadata div.info div.director div.left {
    width: 33%;
  }
}
div.b2bdetailsScreen div.details div.rightContainer div.showMetadata div.info div.director div.left h6 {
  font-size: 16px;
  font-weight: 400;
  color: var(--inner-text-color);
}
div.b2bdetailsScreen div.details div.rightContainer div.showMetadata div.info div.director div.right {
  width: 85%;
}
@media all and (max-width: 980px) {
  div.b2bdetailsScreen div.details div.rightContainer div.showMetadata div.info div.director div.right {
    width: 75%;
  }
}
@media all and (max-width: 640px) {
  div.b2bdetailsScreen div.details div.rightContainer div.showMetadata div.info div.director div.right {
    width: 70%;
  }
}
@media all and (max-width: 480px) {
  div.b2bdetailsScreen div.details div.rightContainer div.showMetadata div.info div.director div.right {
    width: 65%;
  }
}
@media all and (max-width: 360px) {
  div.b2bdetailsScreen div.details div.rightContainer div.showMetadata div.info div.director div.right {
    width: 55%;
  }
}
div.b2bdetailsScreen div.details div.rightContainer div.showMetadata div.info div.director div.right h6 {
  font-size: 16px;
  font-weight: 600;
  margin-inline-end: 8px;
  color: var(--text-color);
}
@media all and (max-width: 480px) {
  div.b2bdetailsScreen div.details div.rightContainer div.showMetadata div.info div.director div.right h6 {
    font-size: 14px;
  }
}
div.b2bdetailsScreen div.downloadPlaylist {
  width: 18%;
  z-index: 15;
  top: 630px;
  inset-inline-end: 40px;
  position: absolute;
}
div.b2bdetailsScreen div.downloadPlaylist button {
  width: 80%;
  display: block;
  background-color: var(--button-color);
  border: 1px solid var(--button-color);
  padding: 15px 10px;
  font-size: 15px;
  color: var(--text-color);
  font-weight: 600;
  border-radius: 8px;
  cursor: pointer;
  transition: 0.3s ease-in-out;
}
div.b2bdetailsScreen div.downloadPlaylist button:hover {
  background-color: transparent;
}
@media (max-width: 2440px) {
  div.b2bdetailsScreen div.downloadPlaylist {
    width: 10%;
    top: 679px;
    inset-inline-start: 127px;
  }
}
@media (max-width: 1280px) {
  div.b2bdetailsScreen div.downloadPlaylist {
    width: 13%;
    inset-inline-start: 104px;
  }
}
@media (max-width: 980px) {
  div.b2bdetailsScreen div.downloadPlaylist {
    width: 14%;
    top: 485px;
    inset-inline-start: 577px;
  }
}
@media (max-width: 768px) {
  div.b2bdetailsScreen div.downloadPlaylist {
    width: 17%;
    inset-inline-start: 93px;
    top: 752px;
  }
  div.b2bdetailsScreen div.downloadPlaylist button {
    width: 68%;
    font-size: 12px;
    font-weight: 300;
  }
}
@media (max-width: 640px) {
  div.b2bdetailsScreen div.downloadPlaylist {
    width: 21%;
    inset-inline-end: 104px;
  }
}
@media (max-width: 480px) {
  div.b2bdetailsScreen div.downloadPlaylist {
    width: 26%;
    top: 620px;
    inset-inline-start: 59px;
  }
}
@media (max-width: 360px) {
  div.b2bdetailsScreen div.downloadPlaylist {
    width: 64%;
    inset-inline-start: 100px;
    top: 586px;
  }
}
div.b2bdetailsScreen div.progressBarVideo {
  width: 39px;
  height: 52px;
  inset-inline-start: 214px;
  z-index: 10px;
  position: absolute;
  top: 249px;
}
@media (max-width: 2440px) {
  div.b2bdetailsScreen div.progressBarVideo {
    top: 236px;
    width: 37px;
    inset-inline-start: 115px;
  }
}
@media (max-width: 1280px) {
  div.b2bdetailsScreen div.progressBarVideo {
    top: 203px;
    inset-inline-start: 79px;
  }
}
@media (max-width: 980px) {
  div.b2bdetailsScreen div.progressBarVideo {
    display: block;
    width: 100px;
  }
}
@media (max-width: 768px) {
  div.b2bdetailsScreen div.progressBarVideo {
    top: 757px;
    inset-inline-start: 94px;
  }
}
@media (max-width: 640px) {
  div.b2bdetailsScreen div.progressBarVideo {
    top: 731px;
  }
}
@media (max-width: 480px) {
  div.b2bdetailsScreen div.progressBarVideo {
    top: 628px;
    inset-inline-start: 53px;
    width: 24px;
  }
}
@media (max-width: 360px) {
  div.b2bdetailsScreen div.progressBarVideo {
    top: 600px;
    width: 20px;
  }
}
div.b2bdetailsScreen div.downloadButton {
  width: 6%;
  padding: 6px;
  margin-inline-start: 263px;
}
@media (max-width: 2440px) {
  div.b2bdetailsScreen div.downloadButton {
    margin-inline-start: 0px;
    width: 6%;
  }
}
@media (max-width: 1280px) {
  div.b2bdetailsScreen div.downloadButton {
    top: 240px;
    inset-inline-start: 65px;
  }
}
@media (max-width: 980px) {
  div.b2bdetailsScreen div.downloadButton {
    width: 100px;
    margin-inline-start: 320px;
  }
}
@media (max-width: 768px) {
  div.b2bdetailsScreen div.downloadButton {
    margin-inline-start: 200px;
  }
}
@media (max-width: 640px) {
  div.b2bdetailsScreen div.downloadButton {
    width: 70px;
  }
}
@media (max-width: 480px) {
  div.b2bdetailsScreen div.downloadButton {
    top: 628px;
    inset-inline-start: 53px;
    width: 24px;
  }
}
@media (max-width: 360px) {
  div.b2bdetailsScreen div.downloadButton {
    top: 600px;
    width: 20px;
  }
}
div.b2bdetailsScreen div.videoContainerPlaylist {
  background-color: #000;
  width: 35%;
  z-index: 10;
  top: 230px;
  inset-inline-end: 100px;
  position: absolute;
}
@media (max-width: 2440px) {
  div.b2bdetailsScreen div.videoContainerPlaylist {
    width: 32%;
    top: 398px;
    inset-inline-end: 118px;
  }
}
@media (max-width: 1280px) {
  div.b2bdetailsScreen div.videoContainerPlaylist {
    width: 40%;
    top: 366px;
    inset-inline-end: 67px;
  }
}
@media (max-width: 980px) {
  div.b2bdetailsScreen div.videoContainerPlaylist {
    width: 45%;
    top: 247px;
    inset-inline-end: 254px;
  }
}
@media (max-width: 768px) {
  div.b2bdetailsScreen div.videoContainerPlaylist {
    width: 70%;
    top: 254px;
    inset-inline-start: 89px;
  }
}
@media (max-width: 640px) {
  div.b2bdetailsScreen div.videoContainerPlaylist {
    width: 73%;
    top: 258px;
    inset-inline-start: 80px;
  }
}
@media (max-width: 480px) {
  div.b2bdetailsScreen div.videoContainerPlaylist {
    width: 75%;
    top: 239px;
    inset-inline-start: 59px;
  }
}
@media (max-width: 360px) {
  div.b2bdetailsScreen div.videoContainerPlaylist {
    width: 78%;
    inset-inline-start: 35px;
  }
}
div.b2bdetailsScreen div.videoContainerPlaylist.heightIncrease {
  height: 28em;
}
@media all and (max-width: 1200px) {
  div.b2bdetailsScreen div.videoContainerPlaylist.heightIncrease {
    height: 25em;
  }
}
div.b2bdetailsScreen div.videoContainerPlaylist div.gradientTop {
  position: absolute;
  top: 0;
  z-index: 8;
  width: 100%;
  height: 20%;
  background-position: top;
  background-size: cover;
  background-repeat: no-repeat;
  background: linear-gradient(0deg, rgba(16, 20, 31, 0), rgba(16, 20, 31, 0.2) 19%, rgba(16, 20, 31, 0.4) 39%, rgba(16, 20, 31, 0.8));
}
div.b2bdetailsScreen div.videoContainerPlaylist div.playListplayer {
  position: relative;
  z-index: 9;
  width: 100%;
  display: block;
}
div.b2bdetailsScreen div.videoContainerPlaylist div.playListplayer div.closeIcon {
  display: none;
}
div.b2bdetailsScreen div.videoContainerPlaylist div.playListplayer div.nowPlayingPlaylilst {
  position: absolute;
  top: 20px;
  inset-inline-end: 20px;
  z-index: 7;
}
div.b2bdetailsScreen div.videoContainerPlaylist div.playListplayer div.nowPlayingPlaylilst h1 {
  color: var(--text-color);
}
@media (max-width: 2440px) {
  div.b2bdetailsScreen div.videoContainerPlaylist div.playListplayer div.nowPlayingPlaylilst h1 {
    font-size: 14px;
  }
}
@media all and (max-width: 1280px) {
  div.b2bdetailsScreen div.videoContainerPlaylist div.playListplayer div.nowPlayingPlaylilst h1 {
    font-size: 12px;
  }
}
@media all and (max-width: 980px) {
  div.b2bdetailsScreen div.videoContainerPlaylist div.playListplayer div.nowPlayingPlaylilst h1 {
    font-size: 10px;
  }
}
@media all and (max-width: 768px) {
  div.b2bdetailsScreen div.videoContainerPlaylist div.playListplayer div.nowPlayingPlaylilst h1 {
    font-size: 10px;
  }
}
@media all and (max-width: 640px) {
  div.b2bdetailsScreen div.videoContainerPlaylist div.playListplayer div.nowPlayingPlaylilst h1 {
    font-size: 8px;
  }
}
@media all and (max-width: 480px) {
  div.b2bdetailsScreen div.videoContainerPlaylist div.playListplayer div.nowPlayingPlaylilst h1 {
    font-size: 6px;
  }
}
div.b2bdetailsScreen div.videoContainerPlaylist div.playListplayer.pipMode {
  position: fixed;
  top: 110px;
  width: 22%;
  inset-inline-end: 15px;
  z-index: 999999999;
  height: 200px;
}
@media all and (max-width: 768px) {
  div.b2bdetailsScreen div.videoContainerPlaylist div.playListplayer.pipMode {
    width: 40%;
  }
}
@media all and (max-width: 640px) {
  div.b2bdetailsScreen div.videoContainerPlaylist div.playListplayer.pipMode {
    width: 50%;
  }
}
@media all and (max-width: 480px) {
  div.b2bdetailsScreen div.videoContainerPlaylist div.playListplayer.pipMode {
    width: 68%;
  }
}
div.b2bdetailsScreen div.videoContainerPlaylist div.playListplayer.pipMode video {
  -o-object-fit: cover;
     object-fit: cover;
  height: 100%;
}
div.b2bdetailsScreen div.videoContainerPlaylist div.playListplayer.pipMode .video-js {
  padding: 0;
  height: 100%;
}
div.b2bdetailsScreen div.videoContainerPlaylist div.playListplayer.pipMode div.gradientTop {
  display: none;
}
div.b2bdetailsScreen div.videoContainerPlaylist div.playListplayer.pipMode div.nowPlaying {
  display: none;
}
div.b2bdetailsScreen div.videoContainerPlaylist div.playListplayer.pipMode:hover div.closeIcon {
  position: absolute;
  top: 10px;
  inset-inline-start: 10px;
  z-index: 9;
  background-color: var(--bg-color);
  height: 25px;
  width: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
}
div.b2bdetailsScreen div.videoContainerPlaylist div.playListplayer.pipMode:hover div.closeIcon svg {
  color: var(--text-color);
}
div.b2bdetailsScreen div.videoContainerPlaylist div.playListplayer.pipMode:hover div.gradientTop {
  display: block;
}
div.b2bdetailsScreen div.videoContainerPlaylist video {
  display: block;
  width: 100%;
}
div.playListPage {
  padding: 130px 0 40px;
}
div.playListPage div.headingSection {
  padding-bottom: 60px;
  position: absolute;
  top: 84px;
  z-index: 8;
  inset-inline-start: 60px;
}
@media all and (max-width: 640px) {
  div.playListPage div.headingSection {
    top: 50px;
    z-index: 8;
    inset-inline-start: 20px;
  }
}
div.playListPage div.headingSection h1.heading {
  font-size: 40px;
  margin-bottom: 10px;
  margin-top: 20px;
  color: var(--text-color);
  overflow: hidden;
  text-overflow: ellipsis;
}
@media all and (max-width: 640px) {
  div.playListPage div.headingSection h1.heading {
    font-size: 22px;
    margin-top: 50px;
  }
}
div.playListPage div.headingSection p.desc {
  font-size: 18px;
  color: var(--inner-text-color);
  overflow: hidden;
  text-overflow: ellipsis;
}
@media all and (max-width: 640px) {
  div.playListPage div.headingSection p.desc {
    font-size: 14px;
  }
}
div.playListPage div.headingSection-wrap {
  padding-bottom: 60px;
  width: 55%;
  position: absolute;
  top: 84px;
  z-index: 8;
  inset-inline-start: 60px;
}
@media (max-width: 1280px) {
  div.playListPage div.headingSection-wrap {
    width: 40%;
    inset-inline-end: 100px;
    top: 63px;
  }
}
@media (max-width: 980px) {
  div.playListPage div.headingSection-wrap {
    width: 80%;
    top: 231px;
    inset-inline-end: 107px;
  }
}
@media (max-width: 768px) {
  div.playListPage div.headingSection-wrap {
    padding-bottom: 60px;
    position: absolute;
    top: 298px;
    z-index: 8;
    inset-inline-start: 39px;
    width: 82%;
  }
}
@media (max-width: 640px) {
  div.playListPage div.headingSection-wrap {
    top: 250px;
    inset-inline-start: 39px;
  }
}
@media (max-width: 480px) {
  div.playListPage div.headingSection-wrap {
    top: 200px;
    inset-inline-start: 22px;
  }
}
@media (max-width: 360px) {
  div.playListPage div.headingSection-wrap {
    top: 163px;
    inset-inline-start: 22px;
  }
}
div.playListPage div.headingSection-wrap h1.heading {
  font-size: 40px;
  margin-bottom: 10px;
  margin-top: 20px;
  color: var(--text-color);
  overflow: hidden;
  text-overflow: ellipsis;
}
@media all and (max-width: 640px) {
  div.playListPage div.headingSection-wrap h1.heading {
    font-size: 18px;
    margin-top: 50px;
  }
}
div.playListPage div.headingSection-wrap p.desc {
  font-size: 16px;
  color: var(--inner-text-color);
  overflow: hidden;
  text-overflow: ellipsis;
}
@media (max-width: 2440px) {
  div.playListPage div.headingSection-wrap p.desc {
    font-size: 16px;
  }
}
@media (max-width: 1280px) {
  div.playListPage div.headingSection-wrap p.desc {
    font-size: 12px;
  }
}
@media (max-width: 768px) {
  div.playListPage div.headingSection-wrap p.desc {
    font-size: 9px;
  }
}
@media all and (max-width: 640px) {
  div.playListPage div.headingSection-wrap p.desc {
    font-size: 8px;
  }
}
@media all and (max-width: 480px) {
  div.playListPage div.headingSection-wrap p.desc {
    font-size: 6px;
  }
}
@media all and (max-width: 360px) {
  div.playListPage div.headingSection-wrap p.desc {
    font-size: 4px;
  }
}
div.playListPage div.backgroundContainer {
  width: 100%;
  display: block;
  height: 508px;
  position: relative;
}
@media all and (max-width: 768px) {
  div.playListPage div.backgroundContainer {
    height: 556px;
  }
}
div.playListPage div.backgroundContainer img {
  display: block;
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
}
@media all and (max-width: 768px) {
  div.playListPage div.backgroundContainer img {
    -o-object-fit: cover;
       object-fit: cover;
  }
}
div.playListPage div.bottomGradient {
  position: absolute;
  bottom: 0;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  z-index: 1;
  height: 100%;
  background-position: top;
  background-size: cover;
  background-repeat: no-repeat;
  background: linear-gradient(78.83deg, rgb(16, 20, 31) 0%, rgba(16, 20, 31, 0) 61.46%), linear-gradient(360deg, rgb(16, 20, 31) 0%, rgba(16, 20, 31, 0) 48.44%, rgba(16, 20, 31, 0.4) 100%);
}
div.playListPage div.playlistBody div.playlistContainer {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
}
div.playListPage div.playlistBody div.playlistContainer div.playlistItems {
  width: 30%;
  margin-bottom: 30px;
  margin-inline-end: 20px;
}
div.playListPage div.playlistBody div.playlistContainer div.playlistItems:nth-child(3n) {
  margin-inline-end: 0px;
}
@media all and (max-width: 2600px) {
  div.playListPage div.playlistBody div.playlistContainer div.playlistItems {
    margin-inline-end: 30px;
    width: 15.5%;
  }
  div.playListPage div.playlistBody div.playlistContainer div.playlistItems:nth-child(3n) {
    margin-inline-end: 30px;
  }
  div.playListPage div.playlistBody div.playlistContainer div.playlistItems:nth-child(6n) {
    margin-inline-end: 0px;
  }
}
@media all and (max-width: 2500px) {
  div.playListPage div.playlistBody div.playlistContainer div.playlistItems {
    width: 15.8%;
    margin-inline-end: 20px;
  }
  div.playListPage div.playlistBody div.playlistContainer div.playlistItems:nth-child(3n) {
    margin-inline-end: 20px;
  }
  div.playListPage div.playlistBody div.playlistContainer div.playlistItems:nth-child(6n) {
    margin-inline-end: 0px;
  }
}
@media all and (max-width: 2200px) {
  div.playListPage div.playlistBody div.playlistContainer div.playlistItems {
    width: 19%;
  }
  div.playListPage div.playlistBody div.playlistContainer div.playlistItems:nth-child(5n) {
    margin-inline-end: 0px;
  }
  div.playListPage div.playlistBody div.playlistContainer div.playlistItems:nth-child(6n) {
    margin-inline-end: 20px;
  }
}
@media all and (max-width: 1980px) {
  div.playListPage div.playlistBody div.playlistContainer div.playlistItems {
    width: 23.7%;
  }
  div.playListPage div.playlistBody div.playlistContainer div.playlistItems:nth-child(4n) {
    margin-inline-end: 0px;
  }
  div.playListPage div.playlistBody div.playlistContainer div.playlistItems:nth-child(5n) {
    margin-inline-end: 20px;
  }
}
@media all and (max-width: 1768px) {
  div.playListPage div.playlistBody div.playlistContainer div.playlistItems {
    width: 23.8%;
  }
  div.playListPage div.playlistBody div.playlistContainer div.playlistItems:nth-child(4n) {
    margin-inline-end: 0px;
  }
}
@media all and (max-width: 1600px) {
  div.playListPage div.playlistBody div.playlistContainer div.playlistItems {
    width: 23.8%;
  }
  div.playListPage div.playlistBody div.playlistContainer div.playlistItems:nth-child(4n) {
    margin-inline-end: 0px;
  }
}
@media all and (max-width: 1400px) {
  div.playListPage div.playlistBody div.playlistContainer div.playlistItems {
    width: 31.5%;
  }
  div.playListPage div.playlistBody div.playlistContainer div.playlistItems:nth-child(3n) {
    margin-inline-end: 0px;
  }
  div.playListPage div.playlistBody div.playlistContainer div.playlistItems:nth-child(4n) {
    margin-inline-end: 20px;
  }
}
@media all and (max-width: 1200px) {
  div.playListPage div.playlistBody div.playlistContainer div.playlistItems {
    width: 31.3%;
  }
  div.playListPage div.playlistBody div.playlistContainer div.playlistItems:nth-child(3n) {
    margin-inline-end: 0px;
  }
  div.playListPage div.playlistBody div.playlistContainer div.playlistItems:nth-child(4n) {
    margin-inline-end: 20px;
  }
}
@media all and (max-width: 1080px) {
  div.playListPage div.playlistBody div.playlistContainer div.playlistItems {
    width: 31%;
    margin-inline-end: 20px;
  }
  div.playListPage div.playlistBody div.playlistContainer div.playlistItems:nth-child(5n) {
    margin-inline-end: 20px;
  }
  div.playListPage div.playlistBody div.playlistContainer div.playlistItems:nth-child(3n) {
    margin-inline-end: 0px;
  }
  div.playListPage div.playlistBody div.playlistContainer div.playlistItems:nth-child(2n) {
    margin-inline-end: 20px;
  }
}
@media all and (max-width: 980px) {
  div.playListPage div.playlistBody div.playlistContainer div.playlistItems {
    width: 47.8%;
    margin-inline-end: 25px;
  }
  div.playListPage div.playlistBody div.playlistContainer div.playlistItems:nth-child(5n) {
    margin-inline-end: 25px;
  }
  div.playListPage div.playlistBody div.playlistContainer div.playlistItems:nth-child(3n) {
    margin-inline-end: 25px;
  }
  div.playListPage div.playlistBody div.playlistContainer div.playlistItems:nth-child(2n) {
    margin-inline-end: 0px;
  }
}
@media all and (max-width: 768px) {
  div.playListPage div.playlistBody div.playlistContainer div.playlistItems {
    width: 47.8%;
    margin-inline-end: 20px;
  }
  div.playListPage div.playlistBody div.playlistContainer div.playlistItems:nth-child(5n) {
    margin-inline-end: 20px;
  }
  div.playListPage div.playlistBody div.playlistContainer div.playlistItems:nth-child(3n) {
    margin-inline-end: 20px;
  }
  div.playListPage div.playlistBody div.playlistContainer div.playlistItems:nth-child(2n) {
    margin-inline-end: 0px;
  }
}
@media all and (max-width: 640px) {
  div.playListPage div.playlistBody div.playlistContainer div.playlistItems {
    width: 99%;
    margin-bottom: 10px;
    margin-inline-end: 0px;
  }
  div.playListPage div.playlistBody div.playlistContainer div.playlistItems:nth-child(3n) {
    margin-inline-end: 0px;
  }
  div.playListPage div.playlistBody div.playlistContainer div.playlistItems:nth-child(5n) {
    margin-inline-end: 0px;
  }
}
div.playListPage div.playlistBody div.playlistContainer div.playlistItemsPortrait {
  width: 15%;
  margin-bottom: 30px;
  margin-inline-end: 20px;
  margin-inline-start: 20px;
}
@media all and (max-width: 2600px) {
  div.playListPage div.playlistBody div.playlistContainer div.playlistItemsPortrait {
    width: 14.9%;
  }
}
@media all and (max-width: 2500px) {
  div.playListPage div.playlistBody div.playlistContainer div.playlistItemsPortrait {
    width: 14.6%;
  }
}
@media all and (max-width: 2200px) {
  div.playListPage div.playlistBody div.playlistContainer div.playlistItemsPortrait {
    width: 17.8%;
  }
}
@media all and (max-width: 1980px) {
  div.playListPage div.playlistBody div.playlistContainer div.playlistItemsPortrait {
    width: 17.5%;
  }
}
@media all and (max-width: 1768px) {
  div.playListPage div.playlistBody div.playlistContainer div.playlistItemsPortrait {
    width: 17.5%;
  }
}
@media all and (max-width: 1600px) {
  div.playListPage div.playlistBody div.playlistContainer div.playlistItemsPortrait {
    width: 16.8%;
  }
}
@media all and (max-width: 1400px) {
  div.playListPage div.playlistBody div.playlistContainer div.playlistItemsPortrait {
    width: 16.3%;
  }
}
@media all and (max-width: 1200px) {
  div.playListPage div.playlistBody div.playlistContainer div.playlistItemsPortrait {
    width: 20.9%;
  }
}
@media all and (max-width: 1080px) {
  div.playListPage div.playlistBody div.playlistContainer div.playlistItemsPortrait {
    width: 20.5%;
  }
}
@media all and (max-width: 980px) {
  div.playListPage div.playlistBody div.playlistContainer div.playlistItemsPortrait {
    width: 27.8%;
  }
}
@media all and (max-width: 768px) {
  div.playListPage div.playlistBody div.playlistContainer div.playlistItemsPortrait {
    width: 26.8%;
  }
}
@media all and (max-width: 640px) {
  div.playListPage div.playlistBody div.playlistContainer div.playlistItemsPortrait {
    width: 90%;
    margin: 0 auto 10px auto;
  }
}

div.shorts {
  height: 100vh;
  overflow: hidden;
  background-color: var(--bg-color);
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 38px;
}
@media (max-width: 480px) {
  div.shorts {
    margin-top: 0px;
  }
}
div.shorts__videos {
  height: 87vh;
  -o-object-fit: cover;
     object-fit: cover;
  max-width: 400px;
  aspect-ratio: 9/16;
  overflow-y: scroll;
  scroll-snap-type: y mandatory;
  -ms-overflow-style: none;
  scrollbar-width: none;
}
div.shorts__videos::-webkit-scrollbar {
  display: none;
}
@media (max-width: 1200px) {
  div.shorts__videos {
    width: 47%;
  }
}
@media (max-width: 1024px) {
  div.shorts__videos {
    width: 50%;
  }
}
@media (max-width: 768px) {
  div.shorts__videos {
    width: 80%;
  }
}
@media (max-width: 480px) {
  div.shorts__videos {
    width: 100%;
    height: 87vh;
  }
}
@media (max-width: 360px) {
  div.shorts__videos {
    width: 100%;
    height: 89vh;
  }
}
div.shorts div.loder-div {
  display: flex;
  justify-content: center;
  align-items: center;
}
div.shorts div.loder-div .loader {
  width: 48px;
  height: 48px;
  border: 5px solid #FFF;
  border-bottom-color: transparent;
  border-radius: 50%;
  display: inline-block;
  box-sizing: border-box;
  animation: rotation 1s linear infinite;
}
@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
div.shorts div.video-container {
  display: flex;
}
div.shorts div.video-container div.video-shorts {
  position: relative;
  width: 100%;
  height: 87.5vh;
  margin-top: 10px;
  margin-bottom: 10px;
  scroll-snap-align: start;
  overflow: hidden;
  background-color: #000;
  /* Mobile-specific styles */
}
div.shorts div.video-container div.video-shorts__player {
  -o-object-fit: cover;
     object-fit: cover;
  width: 100%;
  height: 100%;
  border-radius: 10px;
  aspect-ratio: 9/16;
}
div.shorts div.video-container div.video-shorts .video-js {
  pointer-events: auto; /* Ensure touch events are enabled */
}
div.shorts div.video-container div.video-shorts .video-overlay {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 2;
  pointer-events: auto; /* Ensure touch events are enabled */
  transition: opacity 0.3s ease-in-out;
}
div.shorts div.video-container div.video-shorts .hidden {
  opacity: 0;
  visibility: hidden; /* Ensure the element is not clickable */
  pointer-events: none;
}
div.shorts div.video-container div.video-shorts .show {
  opacity: 1;
  visibility: visible;
}
div.shorts div.video-container div.video-shorts .pause-icon, div.shorts div.video-container div.video-shorts .play-icon {
  width: 60px;
  height: 60px;
  background-color: rgba(128, 128, 128, 0.5);
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
div.shorts div.video-container div.video-shorts .pause-icon::before, div.shorts div.video-container div.video-shorts .play-icon::before {
  content: "▶"; /* Unicode for play triangle */
  font-size: 30px;
  color: white;
}
div.shorts div.video-container div.video-shorts .pause-icon::before {
  content: "❚❚"; /* Unicode for pause bars */
}
@media (max-width: 768px) {
  div.shorts div.video-container div.video-shorts .pause-icon, div.shorts div.video-container div.video-shorts .play-icon {
    width: 60px;
    height: 60px;
  }
  div.shorts div.video-container div.video-shorts .pause-icon::before, div.shorts div.video-container div.video-shorts .play-icon::before {
    font-size: 25px;
  }
}
@media (max-width: 640px) {
  div.shorts div.video-container div.video-shorts .pause-icon, div.shorts div.video-container div.video-shorts .play-icon {
    width: 40px;
    height: 40px;
  }
  div.shorts div.video-container div.video-shorts .pause-icon::before, div.shorts div.video-container div.video-shorts .play-icon::before {
    font-size: 15px;
  }
}
@media (max-width: 480px) {
  div.shorts div.video-container div.video-shorts .pause-icon, div.shorts div.video-container div.video-shorts .play-icon {
    width: 30px;
    height: 30px;
  }
  div.shorts div.video-container div.video-shorts .pause-icon::before, div.shorts div.video-container div.video-shorts .play-icon::before {
    font-size: 10px;
  }
}
div.shorts div.video-container div.video-shorts .shortsVideoTop {
  position: absolute;
  min-width: -moz-fit-content;
  min-width: fit-content;
  top: 10px;
  width: 100%;
  display: flex;
  justify-content: space-between;
}
div.shorts div.video-container div.video-shorts .shortsVideoTopIcon {
  display: flex;
  width: 40%;
  justify-content: space-evenly;
}
div.shorts div.video-container div.video-shorts .shortsVideoTopIcon > .MuiSvgIcon-root {
  font-size: 32px;
  padding: 10px;
}
div.shorts div.video-container div.video-shorts .shortsVideoSideIcons {
  position: absolute;
  right: 8px;
  bottom: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
div.shorts div.video-container div.video-shorts .shortsVideoSideIcon {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 15px;
  cursor: pointer;
}
div.shorts div.video-container div.video-shorts .shortsVideoSideIcon > .MuiSvgIcon-root {
  background: rgba(255, 255, 255, 0.3);
  border-radius: 50%;
  padding: 15px;
  font-size: 43px;
  width: 45px;
  height: 45px;
  cursor: pointer;
}
div.shorts div.video-container div.video-shorts .shortsVideoSideIcon > p {
  color: white;
  font-size: 14px;
  margin-top: 5px;
}
div.shorts div.video-container div.video-shorts .shortDetails {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 0 15px;
  position: relative;
}
div.shorts div.video-container div.video-shorts .shortDetails p {
  margin: 0;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
div.shorts div.video-container div.video-shorts .show-more-button {
  color: #00f; /* Adjust color as needed */
  cursor: pointer;
  font-size: 14px;
  margin-top: 5px;
}
div.shorts div.video-container div.video-shorts .shortsBottom {
  display: flex;
  position: absolute;
  flex-direction: column;
  bottom: 10px;
  width: 84%;
  padding: 0 10px;
}
div.shorts div.video-container div.video-shorts .shortsDesc {
  display: flex;
  padding: 10px;
}
div.shorts div.video-container div.video-shorts .shortsDesc > p {
  color: white;
  font-size: 14px;
}
div.shorts div.video-container div.video-shorts .MuiSvgIcon-root {
  cursor: pointer;
  color: white;
}
div.shorts div.video-container div.video-shorts .shortDetails {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 0 58px;
  position: relative;
}
div.shorts div.video-container div.video-shorts .shortDetails p {
  margin: 0;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  color: #fff;
}
div.shorts div.video-container div.video-shorts .show-more-button {
  color: #00f; /* Adjust color as needed */
  cursor: pointer;
  font-size: 14px;
  margin-top: 5px;
}
div.shorts div.video-container div.video-shorts .shortDetails > button {
  padding: 7px;
  background-color: rgba(177, 177, 177, 0.609);
  border: none;
  outline: none;
  color: white;
  font-weight: bold;
  border-radius: 3px;
  cursor: pointer;
}
div.shorts div.video-container div.video-shorts .shortDetails > button:hover {
  background-color: rgb(255, 0, 0);
}
div.shorts div.video-container div.video-shorts .shortsVideoSideIcon > .MuiSvgIcon-root:hover {
  color: blue;
}
div.shorts div.video-container div.video-shorts .shortsDesc > .ticker {
  height: -moz-fit-content;
  height: fit-content;
  width: 100%;
  max-width: 250px;
}
div.shorts div.video-container div.video-shorts .description {
  color: white;
}
div.shorts div.video-container div.share-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
}
div.shorts div.video-container div.share-modal-overlay .share-modal-content {
  background: #202020;
  border-radius: 12px;
  padding: 20px;
  max-width: 400px;
  width: 100%;
  top: 260px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  text-align: center;
  position: relative;
  color: white;
}
div.shorts div.video-container div.share-modal-overlay .share-modal-close {
  position: absolute;
  top: 10px;
  right: 10px;
  border: none;
  background: none;
  font-size: 24px;
  cursor: pointer;
  color: white;
}
div.shorts div.video-container div.share-modal-overlay .share-options {
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
}
div.shorts div.video-container div.share-modal-overlay .share-button {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  color: #fff;
  font-size: 20px;
  margin: 0 10px;
  transition: opacity 0.3s;
  border: none;
  cursor: pointer;
}
div.shorts div.video-container div.share-modal-overlay .share-button:hover {
  opacity: 0.8;
}
div.shorts div.video-container div.share-modal-overlay .facebook {
  background: #3b5998;
}
div.shorts div.video-container div.share-modal-overlay .twitter {
  background: #1da1f2;
}
div.shorts div.video-container div.share-modal-overlay .share-url-container {
  display: flex;
  margin-top: 15px;
}
div.shorts div.video-container div.share-modal-overlay .share-url-input {
  flex-grow: 1;
  padding: 10px;
  border: 1px solid #444;
  border-radius: 4px 0 0 4px;
  background: #333;
  color: white;
}
div.shorts div.video-container div.share-modal-overlay .copy-button {
  padding: 10px 15px;
  background: #1da1f2;
  color: white;
  border: none;
  border-radius: 0 4px 4px 0;
  cursor: pointer;
  transition: background 0.3s;
}
div.shorts div.video-container div.share-modal-overlay .copy-button:hover {
  background: #0c8de4;
}
div.shorts div.video-container div.comment-modal {
  position: fixed;
  top: 76px;
  width: 28%;
  right: 8%;
  height: 88vh;
  background-color: #212121;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}
@media (max-width: 1400px) {
  div.shorts div.video-container div.comment-modal {
    width: 28%;
    right: 9%;
  }
}
@media all and (max-width: 1200px) {
  div.shorts div.video-container div.comment-modal {
    width: 60%;
  }
}
@media all and (max-width: 768px) {
  div.shorts div.video-container div.comment-modal {
    width: 80%;
  }
}
@media all and (max-width: 640px) {
  div.shorts div.video-container div.comment-modal {
    width: 100%;
    right: 0;
    top: 58px;
    height: 88vh;
  }
}
div.shorts div.video-container div.comment-modal div.comment-modal-content {
  display: flex;
  flex-direction: column;
  height: 100%;
}
div.shorts div.video-container div.comment-modal div.comment-modal-content .comment-modal-header {
  padding: 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #e0e0e0;
}
div.shorts div.video-container div.comment-modal div.comment-modal-content .comment-modal-header .header-actions {
  display: flex;
  align-items: center;
}
div.shorts div.video-container div.comment-modal div.comment-modal-content .comment-modal-header .header-actions .sort-button {
  position: relative;
  margin-right: 15px;
}
div.shorts div.video-container div.comment-modal div.comment-modal-content .comment-modal-header .header-actions .sort-button button {
  background: transparent;
  border: none;
  color: var(--white-color);
  font-size: 14px;
  cursor: pointer;
  display: flex;
  align-items: center;
}
div.shorts div.video-container div.comment-modal div.comment-modal-content .comment-modal-header .header-actions .sort-button button .sort-icon {
  margin-left: 5px;
  font-size: 10px;
}
div.shorts div.video-container div.comment-modal div.comment-modal-content .comment-modal-header .header-actions .sort-button .sort-dropdown {
  position: absolute;
  top: 100%;
  right: 0;
  background-color: #282828;
  border-radius: 4px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.3);
  z-index: 1000;
}
div.shorts div.video-container div.comment-modal div.comment-modal-content .comment-modal-header .header-actions .sort-button .sort-dropdown div {
  padding: 10px 15px;
  color: var(--white-color);
  cursor: pointer;
  white-space: nowrap;
}
div.shorts div.video-container div.comment-modal div.comment-modal-content .comment-modal-header .header-actions .sort-button .sort-dropdown div:hover {
  background-color: rgba(255, 255, 255, 0.1);
}
@media all and (max-width: 1200px) {
  div.shorts div.video-container div.comment-modal div.comment-modal-content .comment-modal-header {
    width: 100%;
  }
}
@media all and (max-width: 768px) {
  div.shorts div.video-container div.comment-modal div.comment-modal-content .comment-modal-header {
    width: 100%;
  }
}
@media all and (max-width: 640px) {
  div.shorts div.video-container div.comment-modal div.comment-modal-content .comment-modal-header {
    width: 100%;
  }
}
div.shorts div.video-container div.comment-modal div.comment-modal-content .comment-modal-header h2 {
  font-size: 20px;
  font-weight: 600;
  margin: 0;
  color: var(--white-color);
}
div.shorts div.video-container div.comment-modal div.comment-modal-content .close-modal {
  background: transparent;
  border: none;
  cursor: pointer;
  font-size: 24px;
  color: var(--white-color);
}
div.shorts div.video-container div.comment-modal div.comment-modal-content div.comment-modal-list {
  flex-grow: 1;
  overflow-x: hidden;
  overflow-y: auto;
  padding: 16px;
}
div.shorts div.video-container div.comment-modal div.comment-modal-content div.comment-modal-list div.loder-div {
  display: flex;
  justify-content: center;
  align-items: center;
}
div.shorts div.video-container div.comment-modal div.comment-modal-content div.comment-modal-list div.loder-div .loader {
  width: 48px;
  height: 48px;
  border: 5px solid #FFF;
  border-bottom-color: transparent;
  border-radius: 50%;
  display: inline-block;
  box-sizing: border-box;
  animation: rotation 1s linear infinite;
}
@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
div.shorts div.video-container div.comment-modal div.comment-modal-content div.comment-modal-list div.bottom div.comments div.eachComment {
  border-bottom: 1px solid var(--border-color);
}
div.shorts div.video-container div.comment-modal div.comment-modal-content div.comment-modal-list div.bottom div.comments div.eachComment:last-child {
  border: none;
}
div.shorts div.video-container div.comment-modal div.comment-modal-content div.comment-modal-list div.bottom div.comments div.eachComment div.containers {
  padding: 10px 0 25px;
}
div.shorts div.video-container div.comment-modal div.comment-modal-content div.comment-modal-list div.bottom div.comments div.eachComment div.containers div.replyMessagesContainer {
  padding: 40px 0 0;
}
div.shorts div.video-container div.comment-modal div.comment-modal-content div.comment-modal-list div.bottom div.comments div.eachComment div.containers div.replyMessagesContainer div.containers {
  padding: 0 0 0;
  display: flex;
  border: none;
}
div.shorts div.video-container div.comment-modal div.comment-modal-content div.comment-modal-list div.bottom div.comments div.eachComment div.containers div.commentItem {
  display: flex;
}
div.shorts div.video-container div.comment-modal div.comment-modal-content div.comment-modal-list div.bottom div.comments div.eachComment div.containers div.commentItem:last-child {
  border: none;
}
div.shorts div.video-container div.comment-modal div.comment-modal-content div.comment-modal-list div.bottom div.comments div.eachComment div.containers div.commentItem div.left {
  width: 45px;
  margin-right: 20px;
}
div.shorts div.video-container div.comment-modal div.comment-modal-content div.comment-modal-list div.bottom div.comments div.eachComment div.containers div.commentItem div.left div.profileImageContainer {
  width: 100%;
  height: 45px;
}
@media all and (max-width: 640px) {
  div.shorts div.video-container div.comment-modal div.comment-modal-content div.comment-modal-list div.bottom div.comments div.eachComment div.containers div.commentItem div.left div.profileImageContainer {
    height: 41px;
  }
}
div.shorts div.video-container div.comment-modal div.comment-modal-content div.comment-modal-list div.bottom div.comments div.eachComment div.containers div.commentItem div.left div.profileImageContainer img {
  width: 100%;
  display: block;
  height: 100%;
  border-radius: 50%;
}
div.shorts div.video-container div.comment-modal div.comment-modal-content div.comment-modal-list div.bottom div.comments div.eachComment div.containers div.commentItem div.left span.nameAvatar {
  width: 100%;
  height: 45px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--white-color);
  background-color: green;
  border-radius: 50%;
}
div.shorts div.video-container div.comment-modal div.comment-modal-content div.comment-modal-list div.bottom div.comments div.eachComment div.containers div.commentItem div.right {
  width: 90%;
}
@media all and (max-width: 640px) {
  div.shorts div.video-container div.comment-modal div.comment-modal-content div.comment-modal-list div.bottom div.comments div.eachComment div.containers div.commentItem div.right {
    width: 96%;
  }
}
div.shorts div.video-container div.comment-modal div.comment-modal-content div.comment-modal-list div.bottom div.comments div.eachComment div.containers div.commentItem div.right h1.name {
  font-size: 16px;
  color: var(--white-color);
}
div.shorts div.video-container div.comment-modal div.comment-modal-content div.comment-modal-list div.bottom div.comments div.eachComment div.containers div.commentItem div.right span.time {
  font-size: 13px;
  color: var(--inner-text-color);
  font-weight: 600;
  display: inline-block;
  margin-bottom: 20px;
}
div.shorts div.video-container div.comment-modal div.comment-modal-content div.comment-modal-list div.bottom div.comments div.eachComment div.containers div.commentItem div.right p.comment {
  font-size: 16px;
  color: var(--white-color);
  margin-bottom: 20px;
  white-space: normal;
  overflow-wrap: break-word;
  word-break: break-word;
  line-height: 1.5;
  max-width: 100%;
}
div.shorts div.video-container div.comment-modal div.comment-modal-content div.comment-modal-list div.bottom div.comments div.eachComment div.containers div.commentItem div.right div.comment-image-container {
  margin-top: 10px;
  margin-bottom: 30px;
  width: 30%;
  height: auto;
}
div.shorts div.video-container div.comment-modal div.comment-modal-content div.comment-modal-list div.bottom div.comments div.eachComment div.containers div.commentItem div.right div.comment-image-container img {
  max-width: 100%;
  height: auto;
  border-radius: 8px;
}
div.shorts div.video-container div.comment-modal div.comment-modal-content div.comment-modal-list div.bottom div.comments div.eachComment div.containers div.commentItem div.right div.actions {
  display: flex;
}
div.shorts div.video-container div.comment-modal div.comment-modal-content div.comment-modal-list div.bottom div.comments div.eachComment div.containers div.commentItem div.right div.actions span.item {
  display: flex;
  align-items: center;
  margin-right: 20px;
  font-size: 11px;
  color: var(--inner-text-color);
  transition: 0.4s ease-in-out;
  cursor: pointer;
}
div.shorts div.video-container div.comment-modal div.comment-modal-content div.comment-modal-list div.bottom div.comments div.eachComment div.containers div.commentItem div.right div.actions span.item:hover {
  opacity: 0.6;
}
div.shorts div.video-container div.comment-modal div.comment-modal-content div.comment-modal-list div.bottom div.comments div.eachComment div.containers div.commentItem div.right div.actions span.item:last-child {
  margin-right: 0;
}
div.shorts div.video-container div.comment-modal div.comment-modal-content div.comment-modal-list div.bottom div.comments div.eachComment div.containers div.commentItem div.right div.actions span.item span svg {
  width: 15px !important;
  height: 15px !important;
  color: rgb(190, 190, 187);
  fill: rgb(190, 190, 187);
  margin-right: 5px;
}
div.shorts div.video-container div.comment-modal div.comment-modal-content div.comment-modal-list div.commentsContainer {
  width: 70%;
  margin: 0 auto;
  padding: 60px 0;
}
@media all and (max-width: 1200px) {
  div.shorts div.video-container div.comment-modal div.comment-modal-content div.comment-modal-list div.commentsContainer {
    width: 80%;
  }
}
@media all and (max-width: 768px) {
  div.shorts div.video-container div.comment-modal div.comment-modal-content div.comment-modal-list div.commentsContainer {
    width: 90%;
  }
}
@media all and (max-width: 640px) {
  div.shorts div.video-container div.comment-modal div.comment-modal-content div.comment-modal-list div.commentsContainer {
    width: 100%;
  }
}
div.shorts div.video-container div.comment-modal div.comment-modal-content div.comment-modal-list div.commentsContainer div.top div.head {
  display: none;
}
div.shorts div.video-container div.comment-modal div.comment-modal-content div.comment-modal-list div.commentsContainer div.top div.textContainer {
  display: none;
}
div.shorts div.video-container div.comment-modal div.comment-modal-content div.comment-modal-list div.commentsContainer div.bottom div.comments div.eachComment {
  border-bottom: 1px solid var(--border-color);
}
div.shorts div.video-container div.comment-modal div.comment-modal-content div.comment-modal-list div.commentsContainer div.bottom div.comments div.eachComment:last-child {
  border: none;
}
div.shorts div.video-container div.comment-modal div.comment-modal-content div.comment-modal-list div.commentsContainer div.bottom div.comments div.eachComment div.containers {
  padding: 40px 0 25px;
}
div.shorts div.video-container div.comment-modal div.comment-modal-content div.comment-modal-list div.commentsContainer div.bottom div.comments div.eachComment div.containers div.replyMessagesContainer {
  padding: 40px 0 0;
}
div.shorts div.video-container div.comment-modal div.comment-modal-content div.comment-modal-list div.commentsContainer div.bottom div.comments div.eachComment div.containers div.replyMessagesContainer div.containers {
  padding: 0 0 0;
  display: flex;
  border: none;
}
div.shorts div.video-container div.comment-modal div.comment-modal-content div.comment-modal-list div.commentsContainer div.bottom div.comments div.eachComment div.containers div.commentItem {
  display: flex;
}
div.shorts div.video-container div.comment-modal div.comment-modal-content div.comment-modal-list div.commentsContainer div.bottom div.comments div.eachComment div.containers div.commentItem:last-child {
  border: none;
}
div.shorts div.video-container div.comment-modal div.comment-modal-content div.comment-modal-list div.commentsContainer div.bottom div.comments div.eachComment div.containers div.commentItem div.left {
  width: 45px;
  margin-right: 20px;
}
div.shorts div.video-container div.comment-modal div.comment-modal-content div.comment-modal-list div.commentsContainer div.bottom div.comments div.eachComment div.containers div.commentItem div.left div.profileImageContainer {
  width: 100%;
  height: 45px;
}
@media all and (max-width: 640px) {
  div.shorts div.video-container div.comment-modal div.comment-modal-content div.comment-modal-list div.commentsContainer div.bottom div.comments div.eachComment div.containers div.commentItem div.left div.profileImageContainer {
    height: 41px;
  }
}
div.shorts div.video-container div.comment-modal div.comment-modal-content div.comment-modal-list div.commentsContainer div.bottom div.comments div.eachComment div.containers div.commentItem div.left div.profileImageContainer img {
  width: 100%;
  display: block;
  height: 100%;
  border-radius: 50%;
}
div.shorts div.video-container div.comment-modal div.comment-modal-content div.comment-modal-list div.commentsContainer div.bottom div.comments div.eachComment div.containers div.commentItem div.left span.nameAvatar {
  width: 100%;
  height: 45px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--white-color);
  background-color: green;
  border-radius: 50%;
}
div.shorts div.video-container div.comment-modal div.comment-modal-content div.comment-modal-list div.commentsContainer div.bottom div.comments div.eachComment div.containers div.commentItem div.right {
  width: 90%;
}
@media all and (max-width: 640px) {
  div.shorts div.video-container div.comment-modal div.comment-modal-content div.comment-modal-list div.commentsContainer div.bottom div.comments div.eachComment div.containers div.commentItem div.right {
    width: 96%;
  }
}
div.shorts div.video-container div.comment-modal div.comment-modal-content div.comment-modal-list div.commentsContainer div.bottom div.comments div.eachComment div.containers div.commentItem div.right h1.name {
  font-size: 16px;
  color: var(--white-color);
}
div.shorts div.video-container div.comment-modal div.comment-modal-content div.comment-modal-list div.commentsContainer div.bottom div.comments div.eachComment div.containers div.commentItem div.right span.time {
  font-size: 13px;
  color: var(--inner-text-color);
  font-weight: 600;
  display: inline-block;
  margin-bottom: 20px;
}
div.shorts div.video-container div.comment-modal div.comment-modal-content div.comment-modal-list div.commentsContainer div.bottom div.comments div.eachComment div.containers div.commentItem div.right p.comment {
  font-size: 16px;
  color: var(--white-color);
  margin-bottom: 20px;
  margin-bottom: 20px;
  white-space: normal;
  overflow-wrap: break-word;
  word-break: break-word;
  line-height: 1.5;
  max-width: 100%;
}
div.shorts div.video-container div.comment-modal div.comment-modal-content div.comment-modal-list div.commentsContainer div.bottom div.comments div.eachComment div.containers div.commentItem div.right div.actions {
  display: flex;
}
div.shorts div.video-container div.comment-modal div.comment-modal-content div.comment-modal-list div.commentsContainer div.bottom div.comments div.eachComment div.containers div.commentItem div.right div.actions span.item {
  display: flex;
  align-items: center;
  margin-right: 20px;
  font-size: 11px;
  color: var(--inner-text-color);
  transition: 0.4s ease-in-out;
  cursor: pointer;
}
div.shorts div.video-container div.comment-modal div.comment-modal-content div.comment-modal-list div.commentsContainer div.bottom div.comments div.eachComment div.containers div.commentItem div.right div.actions span.item:hover {
  opacity: 0.6;
}
div.shorts div.video-container div.comment-modal div.comment-modal-content div.comment-modal-list div.commentsContainer div.bottom div.comments div.eachComment div.containers div.commentItem div.right div.actions span.item:last-child {
  margin-right: 0;
}
div.shorts div.video-container div.comment-modal div.comment-modal-content div.comment-modal-list div.commentsContainer div.bottom div.comments div.eachComment div.containers div.commentItem div.right div.actions span.item span svg {
  width: 15px !important;
  height: 15px !important;
  color: rgb(190, 190, 187);
  fill: rgb(190, 190, 187);
  margin-right: 5px;
}
div.shorts div.video-container div.comment-modal div.comment-modal-content .comment-item {
  display: flex;
  margin-bottom: 16px;
}
div.shorts div.video-container div.comment-modal div.comment-modal-content .comment-avatar {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: #e0e0e0;
  margin-right: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: bold;
}
div.shorts div.video-container div.comment-modal div.comment-modal-content .comment-body {
  flex: 1;
}
div.shorts div.video-container div.comment-modal div.comment-modal-content .comment-author {
  font-weight: 500;
  margin-bottom: 4px;
}
div.shorts div.video-container div.comment-modal div.comment-modal-content .comment-text {
  margin-bottom: 4px;
}
div.shorts div.video-container div.comment-modal div.comment-modal-content .comment-actions {
  display: flex;
  align-items: center;
  color: #606060;
  font-size: 14px;
}
div.shorts div.video-container div.comment-modal div.comment-modal-content .comment-like {
  margin-right: 8px;
}
div.shorts div.video-container div.comment-modal div.comment-modal-content .comment-replies {
  color: #065fd4;
  cursor: pointer;
}
div.shorts div.video-container div.comment-modal div.comment-modal-content .comment-modal-footer {
  padding: 16px;
  border-top: 1px solid var(--border-color);
}
div.shorts div.video-container div.comment-modal div.comment-modal-content .comment-modal-footer .comment-input-wrapper {
  display: flex;
  align-items: flex-start;
}
div.shorts div.video-container div.comment-modal div.comment-modal-content .comment-modal-footer .comment-input-wrapper span.nameAvatar {
  width: 10%;
  height: 45px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--white-color);
  background-color: green;
  border-radius: 50%;
}
div.shorts div.video-container div.comment-modal div.comment-modal-content .comment-modal-footer .comment-input-wrapper .user-avatar {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  margin-right: 12px;
}
div.shorts div.video-container div.comment-modal div.comment-modal-content .comment-modal-footer .comment-input-wrapper .comment-input-container {
  flex-grow: 1;
}
div.shorts div.video-container div.comment-modal div.comment-modal-content .comment-modal-footer .comment-input-wrapper .comment-input-container textarea {
  display: block;
  width: 100%;
  height: 45px;
  padding: 10px 15px;
  border-radius: 5px;
  margin-bottom: 20px;
  border: 1px solid #e1e8ed;
  resize: vertical !important;
}
div.shorts div.video-container div.comment-modal div.comment-modal-content .comment-modal-footer .comment-input-wrapper .comment-input-container textarea:focus-visible {
  border: 3px solid var(--button-color) !important;
  outline: none;
  resize: vertical;
}
div.shorts div.video-container div.comment-modal div.comment-modal-content .comment-modal-footer .comment-input-wrapper .comment-input-container div.upload-featue {
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: flex-start;
}
div.shorts div.video-container div.comment-modal div.comment-modal-content .comment-modal-footer .comment-input-wrapper .comment-input-container div.upload-featue div.selectedImage {
  width: 55%;
  height: 30%;
  margin-bottom: 20px;
}
div.shorts div.video-container div.comment-modal div.comment-modal-content .comment-modal-footer .comment-input-wrapper .comment-input-container div.upload-featue div.selectedImage img {
  max-width: 55%;
  border-radius: 5px;
  height: auto;
}
div.shorts div.video-container div.comment-modal div.comment-modal-content .comment-modal-footer .comment-input-wrapper .comment-input-container div.upload-featue div.selectedImage button.removeButton {
  position: absolute;
  background: white;
  border: none;
  border-radius: 50%;
  cursor: pointer;
  font-size: 20px;
  color: var(--button-color);
  width: 20px;
  height: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  transition: background-color 0.3s ease;
}
div.shorts div.video-container div.comment-modal div.comment-modal-content .comment-modal-footer .comment-input-wrapper .comment-input-container div.upload-featue div.selectedImage button.removeButton:hover {
  background-color: #f2f2f2;
}
div.shorts div.video-container div.comment-modal div.comment-modal-content .comment-modal-footer .comment-input-wrapper .comment-input-container div.upload-featue div.selectedImage button.removeButton svg {
  width: 15px;
  height: 15px;
  fill: var(--button-color);
}
div.shorts div.video-container div.comment-modal div.comment-modal-content .comment-modal-footer .comment-input-wrapper .comment-input-container div.upload-featue div.actions {
  display: flex;
  justify-content: flex-end;
  align-items: flex-start;
}
div.shorts div.video-container div.comment-modal div.comment-modal-content .comment-modal-footer .comment-input-wrapper .comment-input-container div.upload-featue div.actions .buttonContainer {
  display: flex;
  align-items: center;
  margin-right: 10px;
}
div.shorts div.video-container div.comment-modal div.comment-modal-content .comment-modal-footer .comment-input-wrapper .comment-input-container div.upload-featue div.actions .buttonContainer label.customFileUpload {
  background-color: #1da1f2;
  color: white;
  margin-right: 10px;
  font-weight: 600;
  padding: 7px 20px;
  border-radius: 6px;
  font-size: 14px;
  cursor: pointer;
  display: flex;
  align-items: center;
  border: 1px solid #1da1f2;
  transition: 0.4s ease-in-out;
}
div.shorts div.video-container div.comment-modal div.comment-modal-content .comment-modal-footer .comment-input-wrapper .comment-input-container div.upload-featue div.actions .buttonContainer label.customFileUpload:hover {
  background-color: transparent;
  color: var(--white-color);
}
div.shorts div.video-container div.comment-modal div.comment-modal-content .comment-modal-footer .comment-input-wrapper .comment-input-container div.upload-featue div.actions .buttonContainer input[type=file] {
  display: none;
}
div.shorts div.video-container div.comment-modal div.comment-modal-content .comment-modal-footer .comment-input-wrapper .comment-input-container div.upload-featue div.actions .buttonContainer button.giphyButton {
  background-color: #1da1f2;
  color: white;
  font-weight: 600;
  padding: 7px 20px;
  border-radius: 6px;
  font-size: 14px;
  cursor: pointer;
  display: flex;
  align-items: center;
  border: 1px solid #1da1f2;
  transition: 0.4s ease-in-out;
}
div.shorts div.video-container div.comment-modal div.comment-modal-content .comment-modal-footer .comment-input-wrapper .comment-input-container div.upload-featue div.actions .buttonContainer button.giphyButton:hover {
  background-color: transparent;
  color: var(--white-color);
}
div.shorts div.video-container div.comment-modal div.comment-modal-content .comment-modal-footer .comment-input-wrapper .comment-input-container div.upload-featue div.actions button.submit {
  background-color: var(--button-color);
  color: var(--white-color);
  font-weight: 600;
  padding: 10px 20px;
  border-radius: 6px;
  font-size: 14px;
  cursor: pointer;
  border: 1px solid var(--button-color);
  transition: 0.4s ease-in-out;
}
div.shorts div.video-container div.comment-modal div.comment-modal-content .comment-modal-footer .comment-input-wrapper .comment-input-container div.upload-featue div.actions button.submit:hover {
  background-color: transparent;
  color: var(--white-color);
}
div.shorts div.video-container div.comment-modal div.comment-modal-content .comment-modal-footer .comment-input-wrapper .comment-input-container .add-comment {
  width: 100%;
  padding: 12px;
  border: 1px solid var(--border-color);
  border-radius: 4px;
  font-size: 14px;
  background-color: var(--input-bg-color);
  color: var(--text-color);
  margin-bottom: 8px;
}
div.shorts div.video-container div.comment-modal div.comment-modal-content .comment-modal-footer .comment-input-wrapper .comment-input-container .add-comment:focus {
  outline: none;
  border-color: var(--focus-color);
}
div.shorts div.video-container div.comment-modal div.comment-modal-content .comment-modal-footer .comment-input-wrapper .comment-input-container .comment-actions {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
div.shorts div.video-container div.comment-modal div.comment-modal-content .comment-modal-footer .comment-input-wrapper .comment-input-container .comment-actions label.customFileUpload {
  width: 50%;
  text-align: center;
  border-radius: 50%;
  transition: background-color 0.2s;
  border: 1px solid #1da1f2;
  padding: 10px;
  margin: 10px;
}
div.shorts div.video-container div.comment-modal div.comment-modal-content .comment-modal-footer .comment-input-wrapper .comment-input-container .comment-actions .action-button {
  background: none;
  cursor: pointer;
  color: var(--icon-color);
  padding: 10px;
  border-radius: 50%;
  transition: background-color 0.2s;
  border: 1px solid #1da1f2;
}
div.shorts div.video-container div.comment-modal div.comment-modal-content .comment-modal-footer .comment-input-wrapper .comment-input-container .comment-actions .action-button:hover {
  background-color: var(--hover-bg-color);
}
div.shorts div.video-container div.comment-modal div.comment-modal-content .comment-modal-footer .comment-input-wrapper .comment-input-container .comment-actions .submit-button {
  background: none;
  color: var(--button-text-color);
  border: none;
  padding: 8px 16px;
  border-radius: 18px;
  font-weight: bold;
  cursor: pointer;
  transition: background-color 0.2s;
}
div.shorts div.video-container div.comment-modal div.comment-modal-content .comment-modal-footer .comment-input-wrapper .comment-input-container .comment-actions .submit-button:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}
div.shorts div.video-container div.comment-modal div.comment-modal-content .comment-modal-footer .comment-input-wrapper .comment-input-container .comment-actions .submit-button:not(:disabled):hover {
  background-color: var(--button-hover-color);
}
div.shorts div.video-container div.comment-modal div.comment-modal-content .comment-modal-footer .image-preview {
  margin-top: 12px;
  position: relative;
  display: inline-block;
}
div.shorts div.video-container div.comment-modal div.comment-modal-content .comment-modal-footer .image-preview img {
  max-width: 100%;
  max-height: 200px;
  border-radius: 4px;
}
div.shorts div.video-container div.comment-modal div.comment-modal-content .comment-modal-footer .image-preview .remove-preview {
  position: absolute;
  top: 4px;
  right: 4px;
  background-color: rgba(0, 0, 0, 0.6);
  color: white;
  border: none;
  border-radius: 50%;
  padding: 4px;
  cursor: pointer;
}
div.shorts div.video-container div.comment-modal div.comment-modal-content .add-comment {
  width: 100%;
  padding: 8px;
  border: none;
  border-bottom: 1px solid #e0e0e0;
  font-size: 14px;
}
@keyframes slideInFromLeft {
  0% {
    transform: translateX(-100%);
    opacity: 0;
  }
  100% {
    transform: translateX(0);
    opacity: 1;
  }
}
div.shorts div.video-container div.customModal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}
div.shorts div.video-container div.customModal .modalContent {
  background: white;
  padding: 20px;
  border-radius: 10px;
  position: relative;
  max-width: 90%;
  max-height: 80%;
  overflow-y: auto;
}
div.shorts div.video-container div.customModal .modalContent .closeButton {
  position: absolute;
  top: -6px;
  right: 2px;
  background: none;
  border: none;
  font-size: 24px;
  cursor: pointer;
}

.video-js {
  display: block;
  vertical-align: top;
  box-sizing: border-box;
  color: #fff;
  background-color: #000;
  position: relative;
  padding: 0;
  font-size: 10px;
  line-height: 1;
  font-weight: normal;
  font-style: normal;
  font-family: Arial, Helvetica, sans-serif;
  word-break: initial;
  height: 100% !important;
  width: 100% !important;
}

div.shorts.rtl .video-container .video-shorts .shortsVideoSideIcons {
  right: unset;
  left: 8px;
}
div.shorts.rtl .video-container div.share-modal-overlay {
  left: unset;
  right: 0;
}
div.shorts.rtl .video-container div.share-modal-overlay .share-modal-close {
  right: unset;
  left: 10px;
}
div.shorts.rtl .video-container div.comment-modal {
  right: unset;
  left: 8%;
}
@media (max-width: 1400px) {
  div.shorts.rtl .video-container div.comment-modal {
    right: unset;
    left: 9%;
  }
}
@media all and (max-width: 640px) {
  div.shorts.rtl .video-container div.comment-modal {
    right: unset;
    left: 0;
  }
}
div.shorts.rtl .video-container div.comment-modal div.comment-modal-content .comment-modal-header .header-actions .sort-button {
  margin-right: 0;
  margin-left: 15px;
}
div.shorts.rtl .video-container div.comment-modal div.comment-modal-content .comment-modal-header .header-actions .sort-button button .sort-icon {
  margin-left: 0;
  margin-right: 5px;
}
div.shorts.rtl .video-container div.comment-modal div.comment-modal-content .comment-modal-list div.bottom div.comments div.eachComment div.containers div.commentItem div.left {
  margin-right: 0;
  margin-left: 20px;
}
div.shorts.rtl .video-container div.comment-modal div.comment-modal-content .comment-modal-list div.bottom div.comments div.eachComment div.containers div.commentItem div.right div.actions span.item {
  margin-right: 0;
  margin-left: 20px;
}
div.shorts.rtl .video-container div.comment-modal div.comment-modal-content .comment-modal-list div.bottom div.comments div.eachComment div.containers div.commentItem div.right div.actions span.item svg {
  margin-right: 0;
  margin-left: 5px;
}
div.shorts.rtl .video-container div.comment-modal div.comment-modal-content .comment-avatar {
  margin-right: 0;
  margin-left: 12px;
}
div.shorts.rtl .video-container div.comment-modal div.comment-modal-content .comment-like {
  margin-right: 0;
  margin-left: 8px;
}
div.shorts.rtl .video-container div.comment-modal div.comment-modal-content .comment-modal-footer .comment-input-wrapper .user-avatar {
  margin-right: 0;
  margin-left: 12px;
}
div.shorts.rtl .video-container div.comment-modal div.comment-modal-content .comment-modal-footer .comment-input-wrapper .comment-input-container .upload-featue div.actions .buttonContainer {
  margin-right: 0;
  margin-left: 10px;
}
div.shorts.rtl .video-container div.comment-modal div.comment-modal-content .comment-modal-footer .comment-input-wrapper .comment-input-container .upload-featue div.actions .buttonContainer label.customFileUpload {
  margin-right: 0;
  margin-left: 10px;
}
div.shorts.rtl .video-container div.comment-modal div.comment-modal-content .comment-modal-footer .image-preview .remove-preview {
  right: 0;
  left: 4px;
}
div.shorts.rtl .video-container div.customModal {
  left: unset;
  right: 0;
}
div.shorts.rtl .video-container div.customModal .modalContent .closeButton {
  right: 0;
  left: 2px;
}

div.shortsCardContainer {
  width: 100%;
  position: relative;
}
div.shortsCardContainer.showContents {
  cursor: pointer;
}
div.shortsCardContainer.showContents div.premiumStatus div.premium {
  position: absolute;
  top: 6px;
  left: 6px;
  z-index: 8;
  background-color: rgba(8, 25, 43, 0.65);
  width: 35px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 3px;
}
@media all and (max-width: 1200px) {
  div.shortsCardContainer.showContents div.premiumStatus div.premium {
    width: 26px;
  }
}
div.shortsCardContainer.showContents div.premiumStatus div.premium svg {
  width: 18px !important;
  height: 18px !important;
}
@media all and (max-width: 1200px) {
  div.shortsCardContainer.showContents div.premiumStatus div.premium svg {
    width: 12px !important;
    heigth: 12px !important;
  }
}
div.shortsCardContainer.showContents div.premiumStatus div.free {
  position: absolute;
  top: 6px;
  left: 6px;
  z-index: 8;
  width: 50px;
}
@media all and (max-width: 1200px) {
  div.shortsCardContainer.showContents div.premiumStatus div.free {
    width: 30px;
  }
}
div.shortsCardContainer.showContents div.premiumStatus div.free img {
  display: block;
  width: 100%;
}
div.shortsCardContainer.showContents div.premiumStatus div.lock {
  position: absolute;
  top: 6px;
  left: 6px;
  z-index: 8;
  width: 20px;
}
@media all and (max-width: 1200px) {
  div.shortsCardContainer.showContents div.premiumStatus div.lock {
    width: 15px;
  }
}
div.shortsCardContainer.showContents div.premiumStatus div.lock img {
  display: block;
  width: 100%;
}
div.shortsCardContainer.showContents:hover div.imageContainer img {
  transform: scale(1.1);
}
div.shortsCardContainer.showContents:hover div.imageContainer div.extraActions {
  visibility: visible;
  opacity: 1;
  animation: fadeIn 0.2s ease-in-out forwards;
}
div.shortsCardContainer.showContents:hover div.imageContainer div.extraActions div.showCardOverlay {
  background-color: var(--overlay);
  z-index: 8;
}
div.shortsCardContainer.showContents:hover div.imageContainer div.extraActions div.showCardOverlay div.block-social-info {
  position: absolute;
  bottom: 2.2rem;
  z-index: 999;
  display: flex;
  justify-content: space-between;
  opacity: 1;
  transition: opacity 0.2s ease-in-out;
  background: transparent;
  padding: 10px 20px;
  width: 100%;
}
div.shortsCardContainer.showContents:hover div.imageContainer div.extraActions div.showCardOverlay div.block-social-info .share-wrapper {
  position: absolute;
  top: auto;
  right: 0;
  left: 10%;
  bottom: 80%;
  width: 2.375rem;
  height: auto;
  display: none;
  padding: 10px 260x;
  background-color: #191919;
}
div.shortsCardContainer.showContents:hover div.imageContainer div.extraActions div.showCardOverlay div.block-social-info .share-wrapper div.share-boxs div.overflow-hidden {
  display: grid;
  justify-content: center;
  margin-top: 20%;
}
div.shortsCardContainer.showContents:hover div.imageContainer div.extraActions div.showCardOverlay div.block-social-info .share .share-wrapper {
  display: none; /* Hide until hover */
}
div.shortsCardContainer.showContents:hover div.imageContainer div.extraActions div.showCardOverlay div.block-social-info .share:hover .share-wrapper {
  display: inline-block;
  bottom: 100%;
  left: 8%;
  width: 15%;
}
@media (min-width: 320px) {
  div.shortsCardContainer.showContents:hover div.imageContainer div.extraActions div.showCardOverlay div.block-social-info .share:hover .share-wrapper {
    width: 30%;
  }
}
@media (min-width: 360px) {
  div.shortsCardContainer.showContents:hover div.imageContainer div.extraActions div.showCardOverlay div.block-social-info .share:hover .share-wrapper {
    width: 26%;
    left: 11%;
  }
}
@media (min-width: 375px) {
  div.shortsCardContainer.showContents:hover div.imageContainer div.extraActions div.showCardOverlay div.block-social-info .share:hover .share-wrapper {
    width: 26%;
  }
}
@media (min-width: 425px) {
  div.shortsCardContainer.showContents:hover div.imageContainer div.extraActions div.showCardOverlay div.block-social-info .share:hover .share-wrapper {
    width: 23%;
  }
}
@media (min-width: 480px) {
  div.shortsCardContainer.showContents:hover div.imageContainer div.extraActions div.showCardOverlay div.block-social-info .share:hover .share-wrapper {
    width: 31%;
  }
}
@media (min-width: 640px) {
  div.shortsCardContainer.showContents:hover div.imageContainer div.extraActions div.showCardOverlay div.block-social-info .share:hover .share-wrapper {
    left: 8%;
    width: 23%;
  }
}
@media (min-width: 768px) {
  div.shortsCardContainer.showContents:hover div.imageContainer div.extraActions div.showCardOverlay div.block-social-info .share:hover .share-wrapper {
    width: 25%;
    left: 9%;
  }
}
@media (min-width: 980px) {
  div.shortsCardContainer.showContents:hover div.imageContainer div.extraActions div.showCardOverlay div.block-social-info .share:hover .share-wrapper {
    width: 20%;
    left: 9%;
  }
}
@media (min-width: 1024px) {
  div.shortsCardContainer.showContents:hover div.imageContainer div.extraActions div.showCardOverlay div.block-social-info .share:hover .share-wrapper {
    width: 18%;
  }
}
@media (min-width: 1200px) {
  div.shortsCardContainer.showContents:hover div.imageContainer div.extraActions div.showCardOverlay div.block-social-info .share:hover .share-wrapper {
    width: 19%;
  }
}
@media (min-width: 1400px) {
  div.shortsCardContainer.showContents:hover div.imageContainer div.extraActions div.showCardOverlay div.block-social-info .share:hover .share-wrapper {
    width: 15%;
    left: 8%;
  }
}
@media (min-width: 2560px) {
  div.shortsCardContainer.showContents:hover div.imageContainer div.extraActions div.showCardOverlay div.block-social-info .share:hover .share-wrapper {
    width: 9%;
    left: 6%;
  }
}
div.shortsCardContainer.showContents:hover div.imageContainer div.extraActions div.showCardOverlay div.block-social-info .music-play-lists {
  display: flex;
  width: 100%;
}
div.shortsCardContainer.showContents:hover div.imageContainer div.extraActions div.showCardOverlay div.block-social-info .music-play-lists li {
  width: 22%;
  height: 40px;
  margin-bottom: 0.4375rem;
  border-radius: 50%;
  background-color: transparent;
  border: 1px solid var(--white);
  color: white !important;
  transition: all 0.3s ease;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 0.3s ease;
}
@media (max-width: 2600px) {
  div.shortsCardContainer.showContents:hover div.imageContainer div.extraActions div.showCardOverlay div.block-social-info .music-play-lists li {
    height: 45px;
    width: 15%;
  }
}
@media (max-width: 1980px) {
  div.shortsCardContainer.showContents:hover div.imageContainer div.extraActions div.showCardOverlay div.block-social-info .music-play-lists li {
    width: 20%;
  }
}
@media (max-width: 1600px) {
  div.shortsCardContainer.showContents:hover div.imageContainer div.extraActions div.showCardOverlay div.block-social-info .music-play-lists li {
    width: 25%;
  }
}
@media (max-width: 1400px) {
  div.shortsCardContainer.showContents:hover div.imageContainer div.extraActions div.showCardOverlay div.block-social-info .music-play-lists li {
    width: 28%;
  }
}
@media (max-width: 1200px) {
  div.shortsCardContainer.showContents:hover div.imageContainer div.extraActions div.showCardOverlay div.block-social-info .music-play-lists li {
    height: 40px;
  }
}
@media (max-width: 980px) {
  div.shortsCardContainer.showContents:hover div.imageContainer div.extraActions div.showCardOverlay div.block-social-info .music-play-lists li {
    width: 30%;
    height: 35px;
  }
}
@media (max-width: 768px) {
  div.shortsCardContainer.showContents:hover div.imageContainer div.extraActions div.showCardOverlay div.block-social-info .music-play-lists li {
    height: 30px;
  }
}
@media (max-width: 480px) {
  div.shortsCardContainer.showContents:hover div.imageContainer div.extraActions div.showCardOverlay div.block-social-info .music-play-lists li {
    width: 45%;
  }
}
div.shortsCardContainer.showContents:hover div.imageContainer div.extraActions div.showCardOverlay div.block-social-info .music-play-lists li span.shareIcon {
  border-radius: 50%;
  width: 100%;
  height: 100%;
  border: 1px solid white !important;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
}
div.shortsCardContainer.showContents:hover div.imageContainer div.extraActions div.showCardOverlay div.block-social-info .music-play-lists li span.shareIcon svg {
  fill: white !important;
  width: 70%;
}
div.shortsCardContainer.showContents:hover div.imageContainer div.extraActions div.showCardOverlay div.block-social-info .music-play-lists li:hover {
  background-color: var(--button-color);
  border: none;
}
div.shortsCardContainer.showContents:hover div.imageContainer div.extraActions div.showCardOverlay div.block-social-info .music-play-lists li .share > span {
  width: 100%;
  height: 100%;
  display: inline-block;
  font-size: 1rem;
  line-height: 1.625rem;
}
div.shortsCardContainer.showContents:hover div.imageContainer div.extraActions div.showCardOverlay div.block-social-info .music-play-lists li i {
  color: var(--bs-white);
  font-size: 0.945em;
  line-height: 1.625rem;
  cursor: pointer;
}
div.shortsCardContainer.showContents:hover div.imageContainer div.extraActions div.showCardOverlay div.block-social-info .music-play-lists li i:hover {
  color: var(--button-color);
}
div.shortsCardContainer.showContents:hover div.imageContainer div.extraActions div.showCardOverlay div.block-social-info div.iq-button {
  margin-left: auto;
  display: flex;
  justify-content: flex-end;
  background-color: var(--button-color);
  width: 22%;
  height: 40px;
  border-radius: 50%;
  justify-content: center;
  align-items: center;
}
@media (max-width: 2600px) {
  div.shortsCardContainer.showContents:hover div.imageContainer div.extraActions div.showCardOverlay div.block-social-info div.iq-button {
    height: 45px;
    width: 15%;
  }
}
@media (max-width: 1980px) {
  div.shortsCardContainer.showContents:hover div.imageContainer div.extraActions div.showCardOverlay div.block-social-info div.iq-button {
    width: 20%;
  }
}
@media (max-width: 1600px) {
  div.shortsCardContainer.showContents:hover div.imageContainer div.extraActions div.showCardOverlay div.block-social-info div.iq-button {
    width: 25%;
  }
}
@media (max-width: 1400px) {
  div.shortsCardContainer.showContents:hover div.imageContainer div.extraActions div.showCardOverlay div.block-social-info div.iq-button {
    width: 28%;
  }
}
@media (max-width: 1200px) {
  div.shortsCardContainer.showContents:hover div.imageContainer div.extraActions div.showCardOverlay div.block-social-info div.iq-button {
    height: 40px;
  }
}
@media (max-width: 980px) {
  div.shortsCardContainer.showContents:hover div.imageContainer div.extraActions div.showCardOverlay div.block-social-info div.iq-button {
    width: 30%;
    height: 35px;
  }
}
@media (max-width: 768px) {
  div.shortsCardContainer.showContents:hover div.imageContainer div.extraActions div.showCardOverlay div.block-social-info div.iq-button {
    height: 30px;
  }
}
@media (max-width: 480px) {
  div.shortsCardContainer.showContents:hover div.imageContainer div.extraActions div.showCardOverlay div.block-social-info div.iq-button {
    width: 45%;
  }
}
div.shortsCardContainer.showContents:hover div.imageContainer div.extraActions div.showCardOverlay div.block-social-info div.iq-button:focus {
  background-color: transparent;
  width: 20%;
  border-radius: 50%;
  border: 2px solid var(--white);
}
div.shortsCardContainer.showContents:hover div.imageContainer div.extraActions div.showCardOverlay div.block-social-info:focus {
  background-color: transparent;
  width: 20%;
  border-radius: 50%;
  border: 2px solid var(--white);
}
div.shortsCardContainer.showContents:hover div.imageContainer div.extraActions div.showCardOverlay div.block-social-info div.iq-button:hover {
  color: gray;
  border-radius: 50%;
  background-color: var(--button-hover);
}
div.shortsCardContainer.showContents:hover div.imageContainer div.extraActions div.showCardOverlay div.block-social-info div.iq-button:hover:focus {
  background-color: gray;
}
div.shortsCardContainer.showContents:hover div.metaData h1.title {
  color: var(--text-hover);
}
div.shortsCardContainer div.imageContainer {
  width: 100%;
  position: relative;
  overflow: hidden;
  border-radius: 3px;
}
div.shortsCardContainer div.imageContainer img {
  width: 100%;
  height: 25rem;
  display: block;
  border-radius: 3px;
  transition: transform 0.2s ease-in-out;
}
@media (max-width: 980px) {
  div.shortsCardContainer div.imageContainer img {
    height: 20rem;
  }
}
@media (max-width: 768px) {
  div.shortsCardContainer div.imageContainer img {
    height: 15rem;
  }
}
@media (max-width: 480px) {
  div.shortsCardContainer div.imageContainer img {
    height: 12rem;
  }
}
div.shortsCardContainer div.imageContainer img.landscape {
  min-height: 130px;
  background: black;
}
div.shortsCardContainer div.imageContainer img.portrait {
  min-height: 420px;
  background: black;
}
div.shortsCardContainer div.imageContainer div.extraActions {
  visibility: hidden;
  opacity: 0;
  transition: opacity 0.2s ease-in-out;
}
div.shortsCardContainer div.imageContainer div.extraActions div.showCardOverlay {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  background-color: var(--overlay);
  z-index: 8;
}
div.shortsCardContainer div.metaData {
  margin-top: 10px;
  color: var(--inner-text-color);
}
div.shortsCardContainer div.metaData h1.title {
  color: var(--text-color);
  font-size: 16px;
  transition: color 0.3s ease;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}
@media (max-width: 320px) {
  div.shortsCardContainer div.metaData h1.title {
    font-size: 12px;
  }
}
@media (max-width: 640px) {
  div.shortsCardContainer div.metaData h1.title {
    font-size: 12px;
  }
}
div.shortsCardContainer div.metaData div.datas {
  display: flex;
  justify-content: space-between;
}
@media (max-width: 980px) {
  div.shortsCardContainer div.metaData div.datas {
    display: none;
  }
}
div.shortsCardContainer div.metaData div.datas div.left span {
  font-size: 12px;
  margin-right: 6px;
}
div.shortsCardContainer div.metaData div.datas div.left span.duration.ltr {
  direction: ltr !important;
  unicode-bidi: embed !important;
}
div.shortsCardContainer div.metaData div.datas div.left span.duration.showDot::before {
  content: "·";
  margin-right: 6px;
}
div.shortsCardContainer div.metaData div.datas div.left span:last-child {
  margin-right: 0;
}

div.shortsCardContainer.rtl.showContents div.premiumStatus div.premium {
  left: 0;
  right: 6px;
}
div.shortsCardContainer.rtl.showContents div.premiumStatus div.free {
  left: 0;
  right: 6px;
}
div.shortsCardContainer.rtl.showContents div.premiumStatus div.lock {
  left: 0;
  right: 6px;
}
div.shortsCardContainer.rtl.showContents:hover div.imageContainer div.extraActions div.showCardOverlay div.block-social-info .share-wrapper {
  left: 0;
  right: 10%;
}
div.shortsCardContainer.rtl.showContents:hover div.imageContainer div.extraActions div.showCardOverlay div.block-social-info .share:hover .share-wrapper {
  left: 0;
  right: 8%;
}
@media (min-width: 360px) {
  div.shortsCardContainer.rtl.showContents:hover div.imageContainer div.extraActions div.showCardOverlay div.block-social-info .share:hover .share-wrapper {
    left: 0;
    right: 11%;
  }
}
@media (min-width: 640px) {
  div.shortsCardContainer.rtl.showContents:hover div.imageContainer div.extraActions div.showCardOverlay div.block-social-info .share:hover .share-wrapper {
    left: 0;
    right: 8%;
  }
}
@media (min-width: 768px) {
  div.shortsCardContainer.rtl.showContents:hover div.imageContainer div.extraActions div.showCardOverlay div.block-social-info .share:hover .share-wrapper {
    left: 0;
    right: 9%;
  }
}
@media (min-width: 980px) {
  div.shortsCardContainer.rtl.showContents:hover div.imageContainer div.extraActions div.showCardOverlay div.block-social-info .share:hover .share-wrapper {
    left: 0;
    right: 9%;
  }
}
@media (min-width: 1400px) {
  div.shortsCardContainer.rtl.showContents:hover div.imageContainer div.extraActions div.showCardOverlay div.block-social-info .share:hover .share-wrapper {
    left: 0;
    right: 8%;
  }
}
@media (min-width: 2560px) {
  div.shortsCardContainer.rtl.showContents:hover div.imageContainer div.extraActions div.showCardOverlay div.block-social-info .share:hover .share-wrapper {
    left: 0;
    right: 6%;
  }
}
div.shortsCardContainer.rtl .metaData .datas div.left span {
  margin-right: 0;
  margin-left: 6px;
}
div.shortsCardContainer.rtl .metaData .datas div.left span.duration.showDot::before {
  margin-right: 0;
  margin-left: 6px;
}
div.shortsCardContainer.rtl .metaData .datas div.left span:last-child {
  margin-left: 0;
}

div.replyField {
  display: flex;
  width: 100%;
  padding-top: 30px;
  animation: fadeIn 0.4s ease-in-out;
}
div.replyField.child {
  width: 92%;
  margin: 0 0 0 auto;
}
div.replyField div.left {
  margin-inline-end: 20px;
}
div.replyField div.left div.imageContainer {
  width: 45px;
  height: 45px;
}
div.replyField div.left div.imageContainer img {
  width: 100%;
  display: block;
  height: 100%;
  border-radius: 50%;
}
div.replyField div.left div.imageContainer span.nameAvatar {
  width: 100%;
  height: 45px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--white-color);
  background-color: green;
  border-radius: 50%;
}
div.replyField div.right {
  width: 100%;
}
div.replyField div.right div.inputContainer {
  width: 90%;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}
div.replyField div.right div.inputContainer textarea {
  display: block;
  width: 100%;
  height: 45px;
  padding: 10px 15px;
  border-radius: 5px;
  margin-bottom: 20px;
  border: 1px solid #e1e8ed;
  resize: vertical;
}
div.replyField div.right div.inputContainer textarea:focus-visible {
  border: 3px solid var(--button-color) !important;
  outline: none;
}
div.replyField div.right div.inputContainer div.upload-featue {
  display: flex;
  justify-content: space-between;
  width: 100%;
}
div.replyField div.right div.inputContainer div.upload-featue div.selectedImageReply {
  width: 20%;
  height: 30%;
  margin-bottom: 20px;
}
div.replyField div.right div.inputContainer div.upload-featue div.selectedImageReply img {
  max-width: 50%;
  border-radius: 5px;
  height: auto;
}
div.replyField div.right div.inputContainer div.upload-featue div.selectedImageReply button.removeButton {
  position: absolute;
  background: white;
  border: none;
  border-radius: 50%;
  cursor: pointer;
  font-size: 20px;
  color: var(--button-color);
  width: 20px;
  height: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  transition: background-color 0.3s ease;
}
div.replyField div.right div.inputContainer div.upload-featue div.selectedImageReply button.removeButton:hover {
  background-color: #f2f2f2;
}
div.replyField div.right div.inputContainer div.upload-featue div.selectedImageReply button.removeButton svg {
  width: 15px;
  height: 15px;
  fill: var(--button-color);
}
div.replyField div.right div.inputContainer div.upload-featue div.actions {
  display: flex;
  justify-content: flex-end;
  align-items: flex-start;
}
div.replyField div.right div.inputContainer div.upload-featue div.actions div.buttonContainer {
  display: flex;
  align-items: center;
}
div.replyField div.right div.inputContainer div.upload-featue div.actions div.buttonContainer button.cancel {
  background-color: var(--grey-color);
  border: 1px solid var(--grey-color);
  margin-inline-end: 10px;
  color: var(--white-color);
  font-weight: 600;
  padding: 10px 20px;
  border-radius: 6px;
  font-size: 14px;
  cursor: pointer;
  margin-bottom: 10px;
  transition: 0.4s ease-in-out;
}
div.replyField div.right div.inputContainer div.upload-featue div.actions div.buttonContainer button.cancel:hover {
  background-color: transparent;
}
div.replyField div.right div.inputContainer div.upload-featue div.actions div.buttonContainer label.customFileUploadReply {
  color: white;
  margin-inline-end: 10px;
  font-weight: 600;
  padding: 7px 20px;
  border-radius: 6px;
  font-size: 14px;
  margin-bottom: 10px;
  cursor: pointer;
  display: flex;
  align-items: center;
  border: 1px solid #1da1f2;
  transition: 0.4s ease-in-out;
}
div.replyField div.right div.inputContainer div.upload-featue div.actions div.buttonContainer label.customFileUploadReply:hover {
  background-color: transparent;
  color: var(--white-color);
}
div.replyField div.right div.inputContainer div.upload-featue div.actions div.buttonContainer input[type=file] {
  display: none;
}
div.replyField div.right div.inputContainer div.upload-featue div.actions div.buttonContainer button.giphyButton {
  background: none;
  color: white;
  margin-bottom: 10px;
  font-weight: 600;
  padding: 7px 20px;
  border-radius: 6px;
  font-size: 14px;
  margin-inline-end: 10px;
  cursor: pointer;
  display: flex;
  align-items: center;
  border: 1px solid #1da1f2;
  transition: 0.4s ease-in-out;
}
div.replyField div.right div.inputContainer div.upload-featue div.actions div.buttonContainer button.giphyButton:hover {
  background-color: transparent;
  color: var(--white-color);
}
div.replyField div.right div.inputContainer div.upload-featue div.actions button.submit {
  background-color: var(--button-color);
  color: var(--white-color);
  font-weight: 600;
  margin-bottom: 10px;
  padding: 10px 20px;
  border-radius: 6px;
  font-size: 14px;
  cursor: pointer;
  border: 1px solid var(--button-color);
  transition: 0.4s ease-in-out;
}
div.replyField div.right div.inputContainer div.upload-featue div.actions button.submit:hover {
  background-color: transparent;
  color: var(--white-color);
}
@media (max-width: 1200px) {
  div.replyField div.right div.inputContainer {
    width: 90%;
  }
  div.replyField div.right div.inputContainer textarea {
    height: 40px;
    padding: 8px 12px;
  }
  div.replyField div.right div.inputContainer div.upload-featue {
    flex-direction: column;
  }
  div.replyField div.right div.inputContainer div.upload-featue .selectedImageReply {
    width: 100%;
  }
  div.replyField div.right div.inputContainer div.upload-featue .actions {
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
  }
  div.replyField div.right div.inputContainer div.upload-featue .actions .buttonContainer {
    margin-bottom: 10px;
    width: 100%;
  }
  div.replyField div.right div.inputContainer div.upload-featue .actions .buttonContainer label.customFileUploadReply,
  div.replyField div.right div.inputContainer div.upload-featue .actions .buttonContainer button.giphyButton {
    width: 20%;
    text-align: center;
    justify-content: center;
  }
  div.replyField div.right div.inputContainer div.upload-featue .actions button.cancel {
    width: 20%;
  }
  div.replyField div.right div.inputContainer div.upload-featue .actions button.submit {
    width: 40%;
    text-align: center;
  }
}
@media (max-width: 768px) {
  div.replyField div.right div.inputContainer {
    width: 90%;
  }
  div.replyField div.right div.inputContainer textarea {
    height: 35px;
    padding: 6px 10px;
  }
  div.replyField div.right div.inputContainer div.upload-featue {
    flex-direction: column;
  }
  div.replyField div.right div.inputContainer div.upload-featue .selectedImageReply {
    width: 100%;
  }
  div.replyField div.right div.inputContainer div.upload-featue .actions {
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
  }
  div.replyField div.right div.inputContainer div.upload-featue .actions .buttonContainer {
    flex-direction: column-reverse;
    width: 100%;
    text-align: center;
  }
  div.replyField div.right div.inputContainer div.upload-featue .actions .buttonContainer label.customFileUploadReply,
  div.replyField div.right div.inputContainer div.upload-featue .actions .buttonContainer button.giphyButton {
    width: 100%;
    margin-inline-end: 10px;
    text-align: center;
    justify-content: center;
  }
  div.replyField div.right div.inputContainer div.upload-featue .actions button.cancel {
    width: 100%;
  }
  div.replyField div.right div.inputContainer div.upload-featue .actions button.submit {
    width: 100%;
    text-align: center;
  }
}
@media (max-width: 480px) {
  div.replyField div.right div.inputContainer textarea {
    height: 30px;
    padding: 4px 8px;
  }
  div.replyField div.right div.inputContainer div.upload-featue {
    flex-direction: column;
  }
  div.replyField div.right div.inputContainer div.upload-featue .selectedImageReply {
    width: 100%;
  }
  div.replyField div.right div.inputContainer div.upload-featue .actions {
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
  }
  div.replyField div.right div.inputContainer div.upload-featue .actions .buttonContainer {
    flex-direction: column-reverse;
    width: 100%;
  }
  div.replyField div.right div.inputContainer div.upload-featue .actions .buttonContainer button.submit {
    width: 100%;
    text-align: center;
  }
  div.replyField div.right div.inputContainer div.upload-featue .actions .buttonContainer label.customFileUploadReply,
  div.replyField div.right div.inputContainer div.upload-featue .actions .buttonContainer button.giphyButton {
    width: 100%;
    margin-inline-end: 0;
    margin-bottom: 5px;
    text-align: center;
    justify-content: center;
  }
  div.replyField div.right div.inputContainer div.upload-featue .actions .buttonContainer button.cancel {
    width: 100%;
  }
}

div.replyFieldShorts {
  display: flex;
  width: 100%;
  padding-top: 30px;
  animation: fadeIn 0.4s ease-in-out;
}
div.replyFieldShorts.child {
  width: 92%;
  margin: 0 0 0 auto;
}
div.replyFieldShorts div.left {
  margin-right: 20px;
}
div.replyFieldShorts div.left div.imageContainer {
  width: 45px;
  height: 45px;
}
div.replyFieldShorts div.left div.imageContainer img {
  width: 100%;
  display: block;
  height: 100%;
  border-radius: 50%;
}
div.replyFieldShorts div.left div.imageContainer span.nameAvatar {
  width: 100%;
  height: 45px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--white-color);
  background-color: green;
  border-radius: 50%;
}
div.replyFieldShorts div.right {
  width: 100%;
}
div.replyFieldShorts div.right div.inputContainer textarea {
  display: block;
  width: 100%;
  height: 45px;
  padding: 10px 15px;
  border-radius: 5px;
  margin-bottom: 15px !important;
  border: 1px solid #e1e8ed;
  resize: vertical;
}
div.replyFieldShorts div.right div.inputContainer textarea:focus-visible {
  border: 3px solid var(--button-color) !important;
  outline: none;
}
div.replyFieldShorts div.right div.inputContainer div.upload-featue {
  display: flex;
  justify-content: space-between;
  width: 100%;
}
div.replyFieldShorts div.right div.inputContainer div.upload-featue div.selectedImageReply {
  width: 55%;
  height: 30%;
  position: relative;
}
div.replyFieldShorts div.right div.inputContainer div.upload-featue div.selectedImageReply img {
  max-width: 100%;
  border-radius: 5px;
  height: auto;
}
div.replyFieldShorts div.right div.inputContainer div.upload-featue div.selectedImageReply button.removeButton {
  position: absolute;
  background: white;
  border: none;
  border-radius: 50%;
  cursor: pointer;
  font-size: 20px;
  color: var(--button-color);
  width: 20px;
  height: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  transition: background-color 0.3s ease;
}
div.replyFieldShorts div.right div.inputContainer div.upload-featue div.selectedImageReply button.removeButton:hover {
  background-color: #f2f2f2;
}
div.replyFieldShorts div.right div.inputContainer div.upload-featue div.selectedImageReply button.removeButton svg {
  width: 15px;
  height: 15px;
  fill: var(--button-color);
}
div.replyFieldShorts div.right div.inputContainer div.upload-featue div.actions {
  display: flex;
  justify-content: flex-end;
  align-items: flex-start;
}
div.replyFieldShorts div.right div.inputContainer div.upload-featue div.actions div.buttonContainer {
  display: flex;
  align-items: center;
}
div.replyFieldShorts div.right div.inputContainer div.upload-featue div.actions div.buttonContainer label.customFileUploadReply {
  background: none;
  padding: 5px;
  border: 1px solid #1da1f2;
  color: white;
  margin-right: 10px;
  font-weight: 600;
  border-radius: 6px;
  font-size: 14px;
  cursor: pointer;
  display: flex;
  align-items: center;
  transition: 0.4s ease-in-out;
}
div.replyFieldShorts div.right div.inputContainer div.upload-featue div.actions div.buttonContainer label.customFileUploadReply:hover {
  background-color: transparent;
  color: var(--white-color);
}
div.replyFieldShorts div.right div.inputContainer div.upload-featue div.actions div.buttonContainer button.cancel {
  background: none;
  border: none !important;
  margin-right: 10px;
  color: var(--white-color);
  font-weight: 600;
  border-radius: 6px;
  font-size: 14px;
  cursor: pointer;
  transition: 0.4s ease-in-out;
}
div.replyFieldShorts div.right div.inputContainer div.upload-featue div.actions div.buttonContainer button.cancel:hover {
  background-color: transparent;
}
div.replyFieldShorts div.right div.inputContainer div.upload-featue div.actions div.buttonContainer input[type=file] {
  display: none;
}
div.replyFieldShorts div.right div.inputContainer div.upload-featue div.actions div.buttonContainer button.giphyButton {
  background: none;
  color: white;
  border: 1px solid #1da1f2;
  margin-right: 10px;
  font-weight: 600;
  border-radius: 6px;
  font-size: 14px;
  padding: 5px;
  margin-right: 10px !important;
  cursor: pointer;
  display: flex;
  align-items: center;
  transition: 0.4s ease-in-out;
}
div.replyFieldShorts div.right div.inputContainer div.upload-featue div.actions div.buttonContainer button.giphyButton:hover {
  background-color: transparent;
  color: var(--white-color);
}
div.replyFieldShorts div.right div.inputContainer div.upload-featue div.actions button.submit {
  background: none;
  font-weight: 600;
  border: none !important;
  border-radius: 6px;
  font-size: 14px;
  cursor: pointer;
  transition: 0.4s ease-in-out;
}
div.replyFieldShorts div.right div.inputContainer div.upload-featue div.actions button.submit:hover {
  background-color: transparent;
  color: var(--white-color);
}
div.replyFieldShorts div.right div.inputContainer div.actions {
  display: flex;
  justify-content: flex-end;
}
div.replyFieldShorts div.right div.inputContainer div.actions p {
  font-size: 14px;
  color: var(--text-color);
}
div.replyFieldShorts div.right div.inputContainer div.actions p span {
  color: var(--grey-color);
  cursor: pointer;
}
div.replyFieldShorts div.right div.inputContainer div.actions p span:hover {
  text-decoration: underline;
}
div.replyFieldShorts div.right div.inputContainer div.actions div.buttonContainer {
  display: flex;
  align-items: center;
}
div.replyFieldShorts div.right div.inputContainer div.actions div.buttonContainer label.customFileUploadReply {
  background: none;
  border: 1px solid #1da1f2;
  color: white;
  margin-right: 10px;
  font-weight: 600;
  border-radius: 6px;
  font-size: 14px;
  cursor: pointer;
  display: flex;
  align-items: center;
  transition: 0.4s ease-in-out;
}
div.replyFieldShorts div.right div.inputContainer div.actions div.buttonContainer label.customFileUploadReply:hover {
  background-color: transparent;
  color: var(--white-color);
}
div.replyFieldShorts div.right div.inputContainer div.actions div.buttonContainer button.cancel {
  background: none;
  border: none !important;
  margin-right: 10px;
  color: var(--white-color);
  font-weight: 600;
  border-radius: 6px;
  font-size: 14px;
  cursor: pointer;
  transition: 0.4s ease-in-out;
}
div.replyFieldShorts div.right div.inputContainer div.actions div.buttonContainer button.cancel:hover {
  background-color: transparent;
}
div.replyFieldShorts div.right div.inputContainer div.actions div.buttonContainer input[type=file] {
  display: none;
}
div.replyFieldShorts div.right div.inputContainer div.actions div.buttonContainer button.giphyButton {
  background: none;
  color: white;
  border: 1px solid #1da1f2;
  font-weight: 600;
  border-radius: 6px;
  font-size: 14px;
  margin-right: 10px !important;
  cursor: pointer;
  display: flex;
  align-items: center;
  transition: 0.4s ease-in-out;
}
div.replyFieldShorts div.right div.inputContainer div.actions div.buttonContainer button.giphyButton:hover {
  background-color: transparent;
  color: var(--white-color);
}

div.replyFieldShorts.rtl div.left {
  margin-right: 0;
  margin-left: 20px;
}
div.replyFieldShorts.rtl .right .inputContainer .upload-featue div.actions div.buttonContainer label.customFileUploadReply {
  margin-right: 0;
  margin-left: 10px;
}
div.replyFieldShorts.rtl .right .inputContainer .upload-featue div.actions div.buttonContainer button.cancel {
  margin-right: 0;
  margin-left: 10px;
}
div.replyFieldShorts.rtl .right .inputContainer .upload-featue div.actions div.buttonContainer button.giphyButton {
  margin-right: 0;
  margin-left: 10px !important;
}

div.trailerPage {
  position: relative;
  padding: 100px 0 0;
}
div.trailerPage div.wrapper div.trailer {
  width: 90%;
  margin: 0 auto;
}
div.trailerPage div.wrapper div.trailer div.trailerVideoPlayer {
  margin-bottom: 40px;
}
div.trailerPage div.wrapper div.trailer div.trailerPagecontent {
  padding: 0 0 30px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
div.trailerPage div.wrapper div.trailer div.trailerPagecontent div.videoTitle h1.title {
  color: white;
}
@media screen and (max-width: 640px) {
  div.trailerPage div.wrapper div.trailer div.trailerPagecontent div.videoTitle h1.title {
    font-size: 1.5rem;
  }
}
div.trailerPage div.wrapper div.trailer div.trailerPagecontent div.watchButton button {
  width: 100%;
  display: block;
  background: var(--button-color);
  padding: 15px 25px;
  font-size: 15px;
  color: var(--black-color);
  font-weight: 600;
  border-radius: 8px;
  cursor: pointer;
  transition: 0.1s ease-in-out;
}
div.trailerPage div.wrapper div.trailer div.trailerPagecontent div.watchButton button:hover {
  transform: scale(1.05);
}
@media screen and (max-width: 980px) {
  div.trailerPage div.wrapper div.trailer div.trailerPagecontent {
    flex-direction: column;
    align-items: flex-start;
  }
  div.trailerPage div.wrapper div.trailer div.trailerPagecontent div.videoTitle {
    margin-bottom: 20px;
  }
  div.trailerPage div.wrapper div.trailer div.trailerPagecontent div.watchButton {
    width: 100%;
  }
}
div.trailerPage div.wrapper div.trailer div.trailerDescription {
  color: var(--inner-text-color);
  margin-bottom: 30px;
}

div.trailerModal div.overlayModal {
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.7);
  z-index: 9999;
  display: flex;
  justify-content: center;
  align-items: center;
}
div.trailerModal div.head {
  position: fixed;
  z-index: 9999;
  color: var(--white-color);
  padding: 0 20px;
  display: flex;
  justify-content: space-between;
  width: 100%;
  top: 20px;
  inset-inline-start: 0;
}
div.trailerModal div.head.gaia {
  top: 20%;
  padding-inline-end: 25%;
  padding-inline-start: 25%;
}
@media (max-width: 768px) {
  div.trailerModal div.head.gaia {
    padding-inline-end: 15%;
    padding-inline-start: 15%;
  }
  div.trailerModal div.head.gaia .left .heading {
    font-size: 10px;
  }
  div.trailerModal div.head.gaia .left .title {
    font-size: 18px;
  }
  div.trailerModal div.head.gaia .left .director {
    font-size: 10px;
  }
  div.trailerModal div.head.gaia .left .year {
    font-size: 10px;
  }
  div.trailerModal div.head.gaia .right {
    height: 40px;
    width: 40px;
  }
  div.trailerModal div.head.gaia .right .closeIcon svg {
    height: 25px !important;
    width: 25px !important;
  }
}
@media (max-width: 480px) {
  div.trailerModal div.head.gaia {
    padding-inline-end: 3%;
    padding-inline-start: 3%;
  }
}
@media (max-width: 360px) {
  div.trailerModal div.head.gaia {
    top: 25%;
  }
}
@media (max-width: 320px) {
  div.trailerModal div.head.gaia {
    top: 27%;
  }
}
div.trailerModal div.head div.left h4.heading {
  font-size: 13px;
}
div.trailerModal div.head div.left h1.title {
  font-size: 22px;
  text-transform: uppercase;
}
div.trailerModal div.head div.left span.director {
  font-weight: 700;
  font-size: 14px;
  margin-inline-end: 5px;
}
div.trailerModal div.head div.left span.year {
  font-weight: normal;
  font-size: 13px;
}
div.trailerModal div.head div.right {
  width: 50px;
  height: 50px;
}
div.trailerModal div.head div.right div.closeIcon {
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  transform: translate(10px, 10px);
  color: white;
}
div.trailerModal div.head div.right div.closeIcon svg {
  stroke: var(--white-color) !important;
  height: 30px !important;
  width: 30px !important;
}
div.trailerModal div.head div.right:hover {
  cursor: pointer;
  transform: scale(1.3);
}
div.trailerModal div.head div.right:hover svg {
  stroke: white;
}
div.trailerModal div.trailerPlayer {
  z-index: 9999;
  text-align: center;
  position: fixed;
  left: 50%;
  top: 55%;
  transform: translate(-50%, -50%);
  width: 50%;
  margin: 0 auto;
}
@media all and (max-width: 1400px) {
  div.trailerModal div.trailerPlayer {
    top: 50%;
    width: 60%;
  }
}
@media all and (max-width: 1200px) {
  div.trailerModal div.trailerPlayer {
    top: 50%;
    width: 60%;
  }
}
@media all and (max-width: 980px) {
  div.trailerModal div.trailerPlayer {
    top: 45%;
    width: 70%;
  }
}
@media all and (max-width: 480px) {
  div.trailerModal div.trailerPlayer {
    width: 95%;
  }
}
div.trailerModal div.trailerPlayer video {
  width: 100%;
  display: block;
}

div.ratingsAndReview div.overlayModal {
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.7);
  z-index: 99;
  display: flex;
  justify-content: center;
  align-items: center;
}
div.ratingsAndReview div.container {
  z-index: 999;
  text-align: center;
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  background-color: var(--bg-color);
  width: 40%;
  margin: 0 auto;
  display: flex;
  padding: 20px 0;
  flex-direction: column;
  border-radius: 15px;
}
@media all and (max-width: 1200px) {
  div.ratingsAndReview div.container {
    padding: 30px;
    width: 50%;
  }
}
@media all and (max-width: 980px) {
  div.ratingsAndReview div.container {
    width: 60%;
  }
}
@media all and (max-width: 768px) {
  div.ratingsAndReview div.container {
    width: 70%;
  }
}
@media all and (max-width: 640px) {
  div.ratingsAndReview div.container {
    width: 90%;
    padding: 20px 10px;
  }
}
div.ratingsAndReview div.container div.closeIconContainer {
  position: absolute;
  inset-inline-end: 20px;
  top: 20px;
  cursor: pointer;
}
div.ratingsAndReview div.container h1.heading {
  font-size: 25px;
  margin-bottom: 6px;
  color: var(--text-color);
}
div.ratingsAndReview div.container p {
  font-size: 12px;
  margin-bottom: 20px;
}
div.ratingsAndReview div.container p span {
  font-weight: 600;
  margin-inline-end: 6px;
}
div.ratingsAndReview div.container div.ratings {
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  margin-bottom: 20px;
  position: relative;
}
div.ratingsAndReview div.container div.ratings div.star {
  margin-inline-end: 10px;
  transition: 0.3s ease-in-out;
  cursor: pointer;
}
div.ratingsAndReview div.container div.ratings div.star.noMargin {
  margin-inline-end: 0;
}
div.ratingsAndReview div.container div.ratings div.star:hover {
  transform: scale(1.3);
}
div.ratingsAndReview div.container div.ratings div.star.active svg {
  fill: #fbe725;
}
div.ratingsAndReview div.container div.ratings div.star svg {
  width: 30px !important;
  height: 30px !important;
  fill: var(--grey-color);
}
div.ratingsAndReview div.container div.ratings div.ratingValidation {
  padding: 3px 15px;
  background-color: #fbe725;
  position: absolute;
  bottom: -30px;
  left: 50%;
  transform: translateX(-50%);
}
div.ratingsAndReview div.container div.ratings div.ratingValidation p {
  font-size: 12px;
  font-weight: 600;
  margin-bottom: 0;
}
div.ratingsAndReview div.container div.review {
  border-top: 1px solid var(--border-color);
  padding: 30px 20px;
}
div.ratingsAndReview div.container div.review textarea {
  width: 100%;
  border: none;
  outline: none;
  min-height: 100px;
  resize: none;
  border-radius: 6px;
  padding: 10px;
  background-color: rgba(255, 255, 255, 0.8);
}
div.ratingsAndReview div.container div.bottom {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 20px;
}
@media all and (max-width: 640px) {
  div.ratingsAndReview div.container div.bottom {
    padding: 0 10px;
  }
}
div.ratingsAndReview div.container div.bottom div.left span.remainingWords {
  font-size: 12px;
  color: var(--grey-color);
}
div.ratingsAndReview div.container div.bottom div.right div.buttonContainer button {
  background-color: var(--button-color);
  padding: 10px 12px;
  color: var(--white-color);
  font-size: 13px;
  cursor: pointer;
  width: 8rem;
}
div.ratingsAndReview div.container div.bottom div.right div.buttonContainer button:disabled,
div.ratingsAndReview div.container div.bottom div.right div.buttonContainer button[disabled] {
  background-color: var(--disabled-btn-color);
  cursor: not-allowed;
  color: var(--grey-color);
}

div.newContainer {
  direction: ltr;
  text-align: left;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding-inline-start: 4.5%;
  padding-inline-end: 4.5%;
  padding-bottom: 30px;
  padding-top: 25px;
  margin-top: 20px;
  margin-bottom: 20px;
  background: linear-gradient(281deg, var(--gradient-right-color), var(--gradient-left-color));
}
@media ((max-width: 640px) and (min-width: 360px)) {
  div.newContainer {
    padding-top: 40px;
  }
}
div.newContainer .wrapper {
  width: 100% !important;
  margin: 0 auto;
}
@media ((max-width: 2600px) and (min-width: 1980px)) {
  div.newContainer .wrapper {
    width: 77% !important;
  }
}
div.newContainer .wrapper .swiper {
  width: 100%;
}
div.newContainer .wrapper .swiper:hover {
  z-index: 999999;
}
div.newContainer .wrapper .swiper.items.portrait.rowCount3 .swiper-button-next, div.newContainer .wrapper .swiper.items.portrait.rowCount3 .swiper-button-prev {
  margin-top: -40px;
  height: 110%;
}
@media (max-width: 2600px) {
  div.newContainer .wrapper .swiper.items.portrait.rowCount3 .swiper-button-next {
    padding-right: 35%;
    right: -35% !important;
  }
}
@media (max-width: 2600px) {
  div.newContainer .wrapper .swiper.items.portrait.rowCount3 .swiper-button-next::after {
    left: -11% !important;
  }
}
@media (max-width: 1768px) {
  div.newContainer .wrapper .swiper.items.portrait.rowCount3 .swiper-button-next::after {
    left: -8% !important;
  }
}
@media (max-width: 1600px) {
  div.newContainer .wrapper .swiper.items.portrait.rowCount3 .swiper-button-next::after {
    left: -5% !important;
  }
}
@media (max-width: 1199px) {
  div.newContainer .wrapper .swiper.items.portrait.rowCount3 .swiper-button-next::after {
    left: -3% !important;
  }
}
@media (max-width: 2600px) {
  div.newContainer .wrapper .swiper.items.portrait.rowCount3 .swiper-button-prev {
    padding-left: 35%;
    left: -35% !important;
  }
}
@media (max-width: 2600px) {
  div.newContainer .wrapper .swiper.items.portrait.rowCount3 .swiper-button-prev::after {
    right: -11% !important;
  }
}
@media (max-width: 1768px) {
  div.newContainer .wrapper .swiper.items.portrait.rowCount3 .swiper-button-prev::after {
    right: -8% !important;
  }
}
@media (max-width: 1600px) {
  div.newContainer .wrapper .swiper.items.portrait.rowCount3 .swiper-button-prev::after {
    right: -5% !important;
  }
}
@media (max-width: 1199px) {
  div.newContainer .wrapper .swiper.items.portrait.rowCount3 .swiper-button-prev::after {
    right: -4% !important;
  }
}
div.newContainer .wrapper .swiper.items.portrait.rowCount4 .swiper-button-next, div.newContainer .wrapper .swiper.items.portrait.rowCount4 .swiper-button-prev {
  margin-top: -35px;
  height: 110%;
}
@media (max-width: 2600px) {
  div.newContainer .wrapper .swiper.items.portrait.rowCount4 .swiper-button-next::after, div.newContainer .wrapper .swiper.items.portrait.rowCount4 .swiper-button-prev::after {
    top: 42% !important;
  }
}
@media (max-width: 1980px) {
  div.newContainer .wrapper .swiper.items.portrait.rowCount4 .swiper-button-next::after, div.newContainer .wrapper .swiper.items.portrait.rowCount4 .swiper-button-prev::after {
    top: 45% !important;
  }
}
@media (max-width: 1200px) {
  div.newContainer .wrapper .swiper.items.portrait.rowCount4 .swiper-button-next::after, div.newContainer .wrapper .swiper.items.portrait.rowCount4 .swiper-button-prev::after {
    top: 49% !important;
  }
}
@media (max-width: 2600px) {
  div.newContainer .wrapper .swiper.items.portrait.rowCount4 .swiper-button-next {
    padding-right: 35%;
    right: -35% !important;
  }
}
@media (max-width: 2600px) {
  div.newContainer .wrapper .swiper.items.portrait.rowCount4 .swiper-button-next::after {
    left: -4% !important;
  }
}
@media (max-width: 1400px) {
  div.newContainer .wrapper .swiper.items.portrait.rowCount4 .swiper-button-next::after {
    left: -5% !important;
  }
}
@media (max-width: 1200px) {
  div.newContainer .wrapper .swiper.items.portrait.rowCount4 .swiper-button-next::after {
    left: -3% !important;
  }
}
@media (max-width: 2600px) {
  div.newContainer .wrapper .swiper.items.portrait.rowCount4 .swiper-button-prev {
    padding-left: 35%;
    left: -35% !important;
  }
}
@media (max-width: 2600px) {
  div.newContainer .wrapper .swiper.items.portrait.rowCount4 .swiper-button-prev::after {
    right: -5% !important;
  }
}
@media (max-width: 1400px) {
  div.newContainer .wrapper .swiper.items.portrait.rowCount4 .swiper-button-prev::after {
    right: -6% !important;
  }
}
@media (max-width: 1200px) {
  div.newContainer .wrapper .swiper.items.portrait.rowCount4 .swiper-button-prev::after {
    right: -4% !important;
  }
}
div.newContainer .wrapper .swiper.items.portrait.rowCount5 .swiper-button-next, div.newContainer .wrapper .swiper.items.portrait.rowCount5 .swiper-button-prev {
  margin-top: -35px;
  height: 110%;
}
@media (max-width: 480px) {
  div.newContainer .wrapper .swiper.items.portrait.rowCount5 .swiper-button-next, div.newContainer .wrapper .swiper.items.portrait.rowCount5 .swiper-button-prev {
    margin-top: -60px;
  }
}
@media (width: 980px) {
  div.newContainer .wrapper .swiper.items.portrait.rowCount5 .swiper-button-next, div.newContainer .wrapper .swiper.items.portrait.rowCount5 .swiper-button-prev {
    height: 115%;
  }
}
@media (max-width: 1400px) {
  div.newContainer .wrapper .swiper.items.portrait.rowCount5 .swiper-button-next::after, div.newContainer .wrapper .swiper.items.portrait.rowCount5 .swiper-button-prev::after {
    top: 47% !important;
  }
}
@media (max-width: 480px) {
  div.newContainer .wrapper .swiper.items.portrait.rowCount5 .swiper-button-next::after, div.newContainer .wrapper .swiper.items.portrait.rowCount5 .swiper-button-prev::after {
    top: 45% !important;
  }
}
@media (max-width: 360px) {
  div.newContainer .wrapper .swiper.items.portrait.rowCount5 .swiper-button-next::after, div.newContainer .wrapper .swiper.items.portrait.rowCount5 .swiper-button-prev::after {
    top: 45% !important;
  }
}
@media (max-width: 2600px) {
  div.newContainer .wrapper .swiper.items.portrait.rowCount5 .swiper-button-next {
    padding-right: 35%;
    right: -35% !important;
  }
}
@media (max-width: 2600px) {
  div.newContainer .wrapper .swiper.items.portrait.rowCount5 .swiper-button-next::after {
    left: -4% !important;
  }
}
@media (max-width: 980px) {
  div.newContainer .wrapper .swiper.items.portrait.rowCount5 .swiper-button-next::after {
    left: -4% !important;
  }
}
@media (max-width: 768px) {
  div.newContainer .wrapper .swiper.items.portrait.rowCount5 .swiper-button-next::after {
    left: -5% !important;
  }
}
@media (max-width: 640px) {
  div.newContainer .wrapper .swiper.items.portrait.rowCount5 .swiper-button-next::after {
    left: -7% !important;
  }
}
@media (max-width: 480px) {
  div.newContainer .wrapper .swiper.items.portrait.rowCount5 .swiper-button-next::after {
    left: -8% !important;
  }
}
@media (max-width: 360px) {
  div.newContainer .wrapper .swiper.items.portrait.rowCount5 .swiper-button-next::after {
    left: -10% !important;
  }
}
@media (max-width: 2600px) {
  div.newContainer .wrapper .swiper.items.portrait.rowCount5 .swiper-button-prev {
    padding-left: 35%;
    left: -35% !important;
  }
}
@media (max-width: 2600px) {
  div.newContainer .wrapper .swiper.items.portrait.rowCount5 .swiper-button-prev::after {
    right: -4% !important;
  }
}
@media (max-width: 980px) {
  div.newContainer .wrapper .swiper.items.portrait.rowCount5 .swiper-button-prev::after {
    right: -5% !important;
  }
}
@media (max-width: 768px) {
  div.newContainer .wrapper .swiper.items.portrait.rowCount5 .swiper-button-prev::after {
    right: -6% !important;
  }
}
@media (max-width: 640px) {
  div.newContainer .wrapper .swiper.items.portrait.rowCount5 .swiper-button-prev::after {
    right: -8% !important;
  }
}
@media (max-width: 480px) {
  div.newContainer .wrapper .swiper.items.portrait.rowCount5 .swiper-button-prev::after {
    right: -9% !important;
  }
}
@media (max-width: 360px) {
  div.newContainer .wrapper .swiper.items.portrait.rowCount5 .swiper-button-prev::after {
    right: -12% !important;
  }
}
div.newContainer .wrapper .swiper.items.portrait.rowCount6 .swiper-button-next, div.newContainer .wrapper .swiper.items.portrait.rowCount6 .swiper-button-prev {
  margin-top: -30px;
  height: 108%;
}
@media (max-width: 2600px) {
  div.newContainer .wrapper .swiper.items.portrait.rowCount6 .swiper-button-next::after, div.newContainer .wrapper .swiper.items.portrait.rowCount6 .swiper-button-prev::after {
    top: 42% !important;
  }
}
@media (max-width: 1199px) {
  div.newContainer .wrapper .swiper.items.portrait.rowCount6 .swiper-button-next::after, div.newContainer .wrapper .swiper.items.portrait.rowCount6 .swiper-button-prev::after {
    top: 47% !important;
  }
}
@media (max-width: 2600px) {
  div.newContainer .wrapper .swiper.items.portrait.rowCount6 .swiper-button-next {
    padding-right: 35%;
    right: -35% !important;
  }
}
@media (max-width: 2600px) {
  div.newContainer .wrapper .swiper.items.portrait.rowCount6 .swiper-button-next::after {
    left: -4% !important;
  }
}
@media (max-width: 1400px) {
  div.newContainer .wrapper .swiper.items.portrait.rowCount6 .swiper-button-next::after {
    left: -5% !important;
  }
}
@media (max-width: 1199px) {
  div.newContainer .wrapper .swiper.items.portrait.rowCount6 .swiper-button-next::after {
    left: -4% !important;
  }
}
@media (max-width: 2600px) {
  div.newContainer .wrapper .swiper.items.portrait.rowCount6 .swiper-button-prev {
    padding-left: 35%;
    left: -35% !important;
  }
}
@media (max-width: 2600px) {
  div.newContainer .wrapper .swiper.items.portrait.rowCount6 .swiper-button-prev::after {
    right: -4% !important;
  }
}
@media (max-width: 1400px) {
  div.newContainer .wrapper .swiper.items.portrait.rowCount6 .swiper-button-prev::after {
    right: -5% !important;
  }
}
@media (max-width: 1199px) {
  div.newContainer .wrapper .swiper.items.portrait.rowCount6 .swiper-button-prev::after {
    right: -3% !important;
  }
}
div.newContainer .wrapper .swiper.items.portrait.rowCount7 .swiper-button-next, div.newContainer .wrapper .swiper.items.portrait.rowCount7 .swiper-button-prev {
  height: 110%;
}
@media (max-width: 1199px) {
  div.newContainer .wrapper .swiper.items.portrait.rowCount7 .swiper-button-next::after, div.newContainer .wrapper .swiper.items.portrait.rowCount7 .swiper-button-prev::after {
    top: 48% !important;
  }
}
@media (max-width: 2600px) {
  div.newContainer .wrapper .swiper.items.portrait.rowCount7 .swiper-button-next {
    padding-right: 35%;
    right: -35% !important;
  }
}
@media (max-width: 2600px) {
  div.newContainer .wrapper .swiper.items.portrait.rowCount7 .swiper-button-next::after {
    left: -4% !important;
  }
}
@media (max-width: 1199px) {
  div.newContainer .wrapper .swiper.items.portrait.rowCount7 .swiper-button-next::after {
    left: -3% !important;
  }
}
@media (max-width: 2600px) {
  div.newContainer .wrapper .swiper.items.portrait.rowCount7 .swiper-button-prev {
    padding-left: 35%;
    left: -35% !important;
  }
}
@media (max-width: 2600px) {
  div.newContainer .wrapper .swiper.items.portrait.rowCount7 .swiper-button-prev::after {
    right: -4% !important;
  }
}
@media (max-width: 1199px) {
  div.newContainer .wrapper .swiper.items.portrait.rowCount7 .swiper-button-prev::after {
    right: -3% !important;
  }
}
div.newContainer .wrapper .swiper.items.portrait.rowCount8 .swiper-button-next, div.newContainer .wrapper .swiper.items.portrait.rowCount8 .swiper-button-prev {
  height: 105%;
}
@media (max-width: 1400px) {
  div.newContainer .wrapper .swiper.items.portrait.rowCount8 .swiper-button-next, div.newContainer .wrapper .swiper.items.portrait.rowCount8 .swiper-button-prev {
    height: 110%;
  }
}
@media (max-width: 1199px) {
  div.newContainer .wrapper .swiper.items.portrait.rowCount8 .swiper-button-next::after, div.newContainer .wrapper .swiper.items.portrait.rowCount8 .swiper-button-prev::after {
    top: 47% !important;
  }
}
@media (max-width: 2600px) {
  div.newContainer .wrapper .swiper.items.portrait.rowCount8 .swiper-button-next {
    padding-right: 35%;
    right: -35% !important;
  }
}
@media (max-width: 2600px) {
  div.newContainer .wrapper .swiper.items.portrait.rowCount8 .swiper-button-next::after {
    left: -4% !important;
  }
}
@media (max-width: 1199px) {
  div.newContainer .wrapper .swiper.items.portrait.rowCount8 .swiper-button-next::after {
    left: -3.5% !important;
  }
}
@media (max-width: 2600px) {
  div.newContainer .wrapper .swiper.items.portrait.rowCount8 .swiper-button-prev {
    padding-left: 35%;
    left: -35% !important;
  }
}
@media (max-width: 2600px) {
  div.newContainer .wrapper .swiper.items.portrait.rowCount8 .swiper-button-prev::after {
    right: -4% !important;
  }
}
@media (max-width: 1199px) {
  div.newContainer .wrapper .swiper.items.portrait.rowCount8 .swiper-button-prev::after {
    right: -3.5% !important;
  }
}
div.newContainer .wrapper .swiper.items.landscape.rowCount3 .swiper-button-next, div.newContainer .wrapper .swiper.items.landscape.rowCount3 .swiper-button-prev {
  height: 110%;
}
@media (max-width: 1200px) {
  div.newContainer .wrapper .swiper.items.landscape.rowCount3 .swiper-button-next::after, div.newContainer .wrapper .swiper.items.landscape.rowCount3 .swiper-button-prev::after {
    top: 45% !important;
  }
}
@media (max-width: 1199px) {
  div.newContainer .wrapper .swiper.items.landscape.rowCount3 .swiper-button-next::after, div.newContainer .wrapper .swiper.items.landscape.rowCount3 .swiper-button-prev::after {
    top: 50% !important;
  }
}
@media (max-width: 2600px) {
  div.newContainer .wrapper .swiper.items.landscape.rowCount3 .swiper-button-next {
    padding-right: 35%;
    right: -35% !important;
  }
}
@media (max-width: 2600px) {
  div.newContainer .wrapper .swiper.items.landscape.rowCount3 .swiper-button-next::after {
    left: -3% !important;
  }
}
@media (max-width: 1768px) {
  div.newContainer .wrapper .swiper.items.landscape.rowCount3 .swiper-button-next::after {
    left: -4% !important;
  }
}
@media (max-width: 1199px) {
  div.newContainer .wrapper .swiper.items.landscape.rowCount3 .swiper-button-next::after {
    left: -3.5% !important;
  }
}
@media (max-width: 2600px) {
  div.newContainer .wrapper .swiper.items.landscape.rowCount3 .swiper-button-prev {
    padding-right: 35%;
    left: -35% !important;
  }
}
@media (max-width: 2600px) {
  div.newContainer .wrapper .swiper.items.landscape.rowCount3 .swiper-button-prev::after {
    right: -4% !important;
  }
}
@media (max-width: 1768px) {
  div.newContainer .wrapper .swiper.items.landscape.rowCount3 .swiper-button-prev::after {
    right: -5% !important;
  }
}
@media (max-width: 1199px) {
  div.newContainer .wrapper .swiper.items.landscape.rowCount3 .swiper-button-prev::after {
    right: -4% !important;
  }
}
div.newContainer .wrapper .swiper.items.landscape.rowCount4 .swiper-button-next, div.newContainer .wrapper .swiper.items.landscape.rowCount4 .swiper-button-prev {
  margin-top: -35px;
  height: 120%;
}
@media (max-width: 1199px) {
  div.newContainer .wrapper .swiper.items.landscape.rowCount4 .swiper-button-next, div.newContainer .wrapper .swiper.items.landscape.rowCount4 .swiper-button-prev {
    height: 125%;
  }
}
@media (max-width: 2600px) {
  div.newContainer .wrapper .swiper.items.landscape.rowCount4 .swiper-button-next::after, div.newContainer .wrapper .swiper.items.landscape.rowCount4 .swiper-button-prev::after {
    top: 45% !important;
  }
}
@media (max-width: 1980px) {
  div.newContainer .wrapper .swiper.items.landscape.rowCount4 .swiper-button-next::after, div.newContainer .wrapper .swiper.items.landscape.rowCount4 .swiper-button-prev::after {
    top: 45% !important;
  }
}
@media (max-width: 1400px) {
  div.newContainer .wrapper .swiper.items.landscape.rowCount4 .swiper-button-next::after, div.newContainer .wrapper .swiper.items.landscape.rowCount4 .swiper-button-prev::after {
    top: 48% !important;
  }
}
@media (max-width: 1199px) {
  div.newContainer .wrapper .swiper.items.landscape.rowCount4 .swiper-button-next::after, div.newContainer .wrapper .swiper.items.landscape.rowCount4 .swiper-button-prev::after {
    top: 55% !important;
  }
}
@media (max-width: 2600px) {
  div.newContainer .wrapper .swiper.items.landscape.rowCount4 .swiper-button-next {
    padding-right: 35%;
    right: -35% !important;
  }
}
@media (max-width: 2600px) {
  div.newContainer .wrapper .swiper.items.landscape.rowCount4 .swiper-button-next::after {
    left: -4% !important;
  }
}
@media (max-width: 1400px) {
  div.newContainer .wrapper .swiper.items.landscape.rowCount4 .swiper-button-next::after {
    left: -5% !important;
  }
}
@media (max-width: 1200px) {
  div.newContainer .wrapper .swiper.items.landscape.rowCount4 .swiper-button-next::after {
    left: -3% !important;
  }
}
@media (max-width: 2600px) {
  div.newContainer .wrapper .swiper.items.landscape.rowCount4 .swiper-button-prev {
    padding-left: 35%;
    left: -35% !important;
  }
}
@media (max-width: 2600px) {
  div.newContainer .wrapper .swiper.items.landscape.rowCount4 .swiper-button-prev::after {
    right: -5% !important;
  }
}
@media (max-width: 1400px) {
  div.newContainer .wrapper .swiper.items.landscape.rowCount4 .swiper-button-prev::after {
    right: -6% !important;
  }
}
@media (max-width: 1200px) {
  div.newContainer .wrapper .swiper.items.landscape.rowCount4 .swiper-button-prev::after {
    right: -4% !important;
  }
}
div.newContainer .wrapper .swiper.items.landscape.rowCount5 .swiper-button-next, div.newContainer .wrapper .swiper.items.landscape.rowCount5 .swiper-button-prev {
  height: 120%;
}
@media (max-width: 479px) {
  div.newContainer .wrapper .swiper.items.landscape.rowCount5 .swiper-button-next, div.newContainer .wrapper .swiper.items.landscape.rowCount5 .swiper-button-prev {
    margin-top: -60px;
  }
}
@media (max-width: 360px) {
  div.newContainer .wrapper .swiper.items.landscape.rowCount5 .swiper-button-next, div.newContainer .wrapper .swiper.items.landscape.rowCount5 .swiper-button-prev {
    height: 130%;
  }
}
div.newContainer .wrapper .swiper.items.landscape.rowCount5 .swiper-button-next::after, div.newContainer .wrapper .swiper.items.landscape.rowCount5 .swiper-button-prev::after {
  top: 43%;
}
@media (max-width: 2600px) {
  div.newContainer .wrapper .swiper.items.landscape.rowCount5 .swiper-button-next::after, div.newContainer .wrapper .swiper.items.landscape.rowCount5 .swiper-button-prev::after {
    top: 40%;
  }
}
@media (max-width: 1980px) {
  div.newContainer .wrapper .swiper.items.landscape.rowCount5 .swiper-button-next::after, div.newContainer .wrapper .swiper.items.landscape.rowCount5 .swiper-button-prev::after {
    top: 40%;
  }
}
@media (max-width: 1200px) {
  div.newContainer .wrapper .swiper.items.landscape.rowCount5 .swiper-button-next::after, div.newContainer .wrapper .swiper.items.landscape.rowCount5 .swiper-button-prev::after {
    top: 41%;
  }
}
@media (max-width: 1199px) {
  div.newContainer .wrapper .swiper.items.landscape.rowCount5 .swiper-button-next::after, div.newContainer .wrapper .swiper.items.landscape.rowCount5 .swiper-button-prev::after {
    top: 46%;
  }
}
@media (max-width: 980px) {
  div.newContainer .wrapper .swiper.items.landscape.rowCount5 .swiper-button-next::after, div.newContainer .wrapper .swiper.items.landscape.rowCount5 .swiper-button-prev::after {
    top: 45% !important;
  }
}
@media (max-width: 768px) {
  div.newContainer .wrapper .swiper.items.landscape.rowCount5 .swiper-button-next::after, div.newContainer .wrapper .swiper.items.landscape.rowCount5 .swiper-button-prev::after {
    top: 43% !important;
  }
}
@media (max-width: 479px) {
  div.newContainer .wrapper .swiper.items.landscape.rowCount5 .swiper-button-next::after, div.newContainer .wrapper .swiper.items.landscape.rowCount5 .swiper-button-prev::after {
    top: 51% !important;
  }
}
@media (max-width: 360px) {
  div.newContainer .wrapper .swiper.items.landscape.rowCount5 .swiper-button-next::after, div.newContainer .wrapper .swiper.items.landscape.rowCount5 .swiper-button-prev::after {
    top: 55% !important;
  }
}
@media (max-width: 2600px) {
  div.newContainer .wrapper .swiper.items.landscape.rowCount5 .swiper-button-next {
    padding-right: 35%;
    right: -35% !important;
  }
}
@media (max-width: 2600px) {
  div.newContainer .wrapper .swiper.items.landscape.rowCount5 .swiper-button-next::after {
    left: -4% !important;
  }
}
@media (max-width: 1200px) {
  div.newContainer .wrapper .swiper.items.landscape.rowCount5 .swiper-button-next::after {
    left: -6% !important;
  }
}
@media (max-width: 1199px) {
  div.newContainer .wrapper .swiper.items.landscape.rowCount5 .swiper-button-next::after {
    left: -3% !important;
  }
}
@media (max-width: 980px) {
  div.newContainer .wrapper .swiper.items.landscape.rowCount5 .swiper-button-next::after {
    left: -4% !important;
  }
}
@media (max-width: 768px) {
  div.newContainer .wrapper .swiper.items.landscape.rowCount5 .swiper-button-next::after {
    left: -5% !important;
  }
}
@media (max-width: 640px) {
  div.newContainer .wrapper .swiper.items.landscape.rowCount5 .swiper-button-next::after {
    left: -7% !important;
  }
}
@media (max-width: 479px) {
  div.newContainer .wrapper .swiper.items.landscape.rowCount5 .swiper-button-next::after {
    left: -8% !important;
  }
}
@media (max-width: 360px) {
  div.newContainer .wrapper .swiper.items.landscape.rowCount5 .swiper-button-next::after {
    left: -11% !important;
  }
}
@media (max-width: 2600px) {
  div.newContainer .wrapper .swiper.items.landscape.rowCount5 .swiper-button-prev {
    padding-left: 35%;
    left: -35% !important;
  }
}
@media (max-width: 2600px) {
  div.newContainer .wrapper .swiper.items.landscape.rowCount5 .swiper-button-prev::after {
    right: -4% !important;
  }
}
@media (max-width: 1200px) {
  div.newContainer .wrapper .swiper.items.landscape.rowCount5 .swiper-button-prev::after {
    right: -6% !important;
  }
}
@media (max-width: 1199px) {
  div.newContainer .wrapper .swiper.items.landscape.rowCount5 .swiper-button-prev::after {
    right: -3% !important;
  }
}
@media (max-width: 980px) {
  div.newContainer .wrapper .swiper.items.landscape.rowCount5 .swiper-button-prev::after {
    right: -5% !important;
  }
}
@media (max-width: 768px) {
  div.newContainer .wrapper .swiper.items.landscape.rowCount5 .swiper-button-prev::after {
    right: -6% !important;
  }
}
@media (max-width: 640px) {
  div.newContainer .wrapper .swiper.items.landscape.rowCount5 .swiper-button-prev::after {
    right: -8% !important;
  }
}
@media (max-width: 479px) {
  div.newContainer .wrapper .swiper.items.landscape.rowCount5 .swiper-button-prev::after {
    right: -9% !important;
  }
}
@media (max-width: 360px) {
  div.newContainer .wrapper .swiper.items.landscape.rowCount5 .swiper-button-prev::after {
    right: -13% !important;
  }
}
div.newContainer .wrapper .swiper.items.landscape.rowCount6 .swiper-button-next, div.newContainer .wrapper .swiper.items.landscape.rowCount6 .swiper-button-prev {
  height: 120%;
}
@media (max-width: 1199px) {
  div.newContainer .wrapper .swiper.items.landscape.rowCount6 .swiper-button-next, div.newContainer .wrapper .swiper.items.landscape.rowCount6 .swiper-button-prev {
    height: 125%;
  }
}
@media (max-width: 1768px) {
  div.newContainer .wrapper .swiper.items.landscape.rowCount6 .swiper-button-next::after, div.newContainer .wrapper .swiper.items.landscape.rowCount6 .swiper-button-prev::after {
    top: 42% !important;
  }
}
@media (max-width: 1199px) {
  div.newContainer .wrapper .swiper.items.landscape.rowCount6 .swiper-button-next::after, div.newContainer .wrapper .swiper.items.landscape.rowCount6 .swiper-button-prev::after {
    top: 48% !important;
  }
}
@media (max-width: 2600px) {
  div.newContainer .wrapper .swiper.items.landscape.rowCount6 .swiper-button-next {
    padding-right: 35%;
    right: -35% !important;
  }
}
@media (max-width: 2600px) {
  div.newContainer .wrapper .swiper.items.landscape.rowCount6 .swiper-button-next::after {
    left: -4% !important;
  }
}
@media (max-width: 2600px) {
  div.newContainer .wrapper .swiper.items.landscape.rowCount6 .swiper-button-prev {
    padding-left: 35%;
    left: -35% !important;
  }
}
@media (max-width: 2600px) {
  div.newContainer .wrapper .swiper.items.landscape.rowCount6 .swiper-button-prev::after {
    right: -4% !important;
  }
}
div.newContainer .wrapper .swiper.items.landscape.rowCount7 .swiper-button-next, div.newContainer .wrapper .swiper.items.landscape.rowCount7 .swiper-button-prev {
  height: 125%;
}
@media (max-width: 1199px) {
  div.newContainer .wrapper .swiper.items.landscape.rowCount7 .swiper-button-next, div.newContainer .wrapper .swiper.items.landscape.rowCount7 .swiper-button-prev {
    height: 130%;
  }
}
@media (max-width: 1768px) {
  div.newContainer .wrapper .swiper.items.landscape.rowCount7 .swiper-button-next::after, div.newContainer .wrapper .swiper.items.landscape.rowCount7 .swiper-button-prev::after {
    top: 38% !important;
  }
}
@media (max-width: 1199px) {
  div.newContainer .wrapper .swiper.items.landscape.rowCount7 .swiper-button-next::after, div.newContainer .wrapper .swiper.items.landscape.rowCount7 .swiper-button-prev::after {
    top: 45% !important;
  }
}
@media (max-width: 2600px) {
  div.newContainer .wrapper .swiper.items.landscape.rowCount7 .swiper-button-next {
    padding-right: 35%;
    right: -35% !important;
  }
}
@media (max-width: 2600px) {
  div.newContainer .wrapper .swiper.items.landscape.rowCount7 .swiper-button-next::after {
    left: -3% !important;
  }
}
@media (max-width: 1768px) {
  div.newContainer .wrapper .swiper.items.landscape.rowCount7 .swiper-button-next::after {
    left: -4% !important;
  }
}
@media (max-width: 2600px) {
  div.newContainer .wrapper .swiper.items.landscape.rowCount7 .swiper-button-prev {
    padding-left: 35%;
    left: -35% !important;
  }
}
@media (max-width: 2600px) {
  div.newContainer .wrapper .swiper.items.landscape.rowCount7 .swiper-button-prev::after {
    right: -4% !important;
  }
}
@media (max-width: 1768px) {
  div.newContainer .wrapper .swiper.items.landscape.rowCount7 .swiper-button-prev::after {
    right: -5% !important;
  }
}
div.newContainer .wrapper .swiper.items.landscape.rowCount8 .swiper-button-next, div.newContainer .wrapper .swiper.items.landscape.rowCount8 .swiper-button-prev {
  height: 110%;
}
@media (max-width: 1400px) {
  div.newContainer .wrapper .swiper.items.landscape.rowCount8 .swiper-button-next, div.newContainer .wrapper .swiper.items.landscape.rowCount8 .swiper-button-prev {
    height: 130%;
  }
}
@media (max-width: 1200px) {
  div.newContainer .wrapper .swiper.items.landscape.rowCount8 .swiper-button-next, div.newContainer .wrapper .swiper.items.landscape.rowCount8 .swiper-button-prev {
    height: 140%;
  }
}
@media (max-width: 1768px) {
  div.newContainer .wrapper .swiper.items.landscape.rowCount8 .swiper-button-next::after, div.newContainer .wrapper .swiper.items.landscape.rowCount8 .swiper-button-prev::after {
    top: 41% !important;
  }
}
@media (max-width: 1400px) {
  div.newContainer .wrapper .swiper.items.landscape.rowCount8 .swiper-button-next::after, div.newContainer .wrapper .swiper.items.landscape.rowCount8 .swiper-button-prev::after {
    top: 35% !important;
  }
}
@media (max-width: 1199px) {
  div.newContainer .wrapper .swiper.items.landscape.rowCount8 .swiper-button-next::after, div.newContainer .wrapper .swiper.items.landscape.rowCount8 .swiper-button-prev::after {
    top: 46% !important;
  }
}
@media (max-width: 2600px) {
  div.newContainer .wrapper .swiper.items.landscape.rowCount8 .swiper-button-next {
    padding-right: 35%;
    right: -35% !important;
  }
}
@media (max-width: 2600px) {
  div.newContainer .wrapper .swiper.items.landscape.rowCount8 .swiper-button-next::after {
    left: -3.5% !important;
  }
}
@media (max-width: 2600px) {
  div.newContainer .wrapper .swiper.items.landscape.rowCount8 .swiper-button-prev {
    padding-left: 35%;
    left: -35% !important;
  }
}
@media (max-width: 2600px) {
  div.newContainer .wrapper .swiper.items.landscape.rowCount8 .swiper-button-prev::after {
    right: -4% !important;
  }
}
div.newContainer .wrapper .swiper .swiper-slide {
  display: flex;
  justify-content: center;
  align-items: center;
}
div.newContainer .wrapper .swiper .swiper-slide.swiper-slide-active {
  transition: transform 0.3s ease;
}
div.newContainer .wrapper .swiper .swiper-slide.swiper-slide-active:hover {
  transform: translateX(15%);
}
div.newContainer .wrapper .swiper .swiper-slide.swiper-slide.translate-last {
  transition: transform 0.3s ease;
}
div.newContainer .wrapper .swiper .swiper-slide.swiper-slide.translate-last:hover {
  transform: translateX(-16%);
}
div.newContainer .wrapper .swiper .swiper-slide:hover {
  z-index: 9999;
}
div.newContainer .wrapper .swiper .swiper-button-next,
div.newContainer .wrapper .swiper .swiper-button-prev {
  height: 100%;
  color: white;
  width: 5% !important;
}
div.newContainer .wrapper .swiper .swiper-button-next .svg,
div.newContainer .wrapper .swiper .swiper-button-prev .svg {
  height: 60px;
  align-items: center;
  color: white;
  box-shadow: 0 6px 20px rgba(0, 0, 0, 0.2);
}
div.newContainer .wrapper .swiper .swiper-button-next::after,
div.newContainer .wrapper .swiper .swiper-button-prev::after {
  font-size: 18px;
  font-weight: bolder;
  color: var(--text-color);
  position: absolute;
  z-index: 10;
}
div.newContainer .wrapper .swiper .swiper-button-next::after:hover,
div.newContainer .wrapper .swiper .swiper-button-prev::after:hover {
  background-color: var(--button-hover);
  transform: scale(1.3);
}
div.newContainer .wrapper .swiper .swiper-button-next {
  right: -5% !important;
  background: var(--gradient-right-color);
}
div.newContainer .wrapper .swiper .swiper-button-next::after {
  left: -21%;
}
div.newContainer .wrapper .swiper .swiper-button-prev {
  left: -5% !important;
  background: var(--gradient-left-color);
}
div.newContainer .wrapper .swiper .swiper-button-prev::after {
  right: -27%;
}
div.newContainer .wrapper .swiper .swiper-button-disabled {
  opacity: 0;
  pointer-events: none;
}
div.newContainer div.header-row {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 20px;
}
div.newContainer div.header-row div.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
div.newContainer div.header-row div.header h1 {
  font-size: 28px;
  color: var(--white-color);
}
div.newContainer div.header-row div.view-all {
  font-family: "Avenir-Medium", sans-serif;
  font-size: 12px;
  color: var(--white-color);
  cursor: pointer;
  display: flex;
  align-items: center;
}
@media (max-width: 768px) {
  div.newContainer div.header-row div.view-all {
    display: none;
  }
}
div.newContainer div.header-row div.view-all .arrow-icon {
  margin-inline-start: 10px;
}
div.newContainer div.header-row div.view-all .arrow-icon path {
  fill: var(--white-color);
}
div.newContainer div.view-all-mobile {
  display: none;
}
@media (max-width: 768px) {
  div.newContainer div.view-all-mobile {
    font-family: "Avenir-Medium", sans-serif;
    color: var(--white-color);
    font-size: 16px;
    margin-top: 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
  }
  div.newContainer div.view-all-mobile .arrow-icon {
    margin-inline-start: 10px;
  }
  div.newContainer div.view-all-mobile .arrow-icon path {
    fill: var(--white-color);
  }
}

div.newContainer.ltr {
  direction: ltr;
  text-align: left;
}
div.newContainer.ltr .header-row {
  direction: rtl;
  text-align: right;
}
div.newContainer.ltr .header-row .view-all .arrow-icon {
  transform: rotate(180deg);
}
div.newContainer.ltr .view-all-mobile {
  direction: rtl;
  text-align: right;
}
@media (max-width: 768px) {
  div.newContainer.ltr .view-all-mobile .arrow-icon {
    transform: rotate(180deg);
  }
}

div.newContainer.rtl {
  direction: rtl;
  text-align: right;
}
div.newContainer.rtl .wrapper .swiper-slide.swiper-slide-active:hover {
  transform: translateX(-15%);
}
div.newContainer.rtl .wrapper .swiper-slide.swiper-slide.translate-last:hover {
  transform: translateX(16%);
}
div.newContainer.rtl .header-row .view-all .arrow-icon {
  transform: rotate(180deg);
}
@media (max-width: 768px) {
  div.newContainer.rtl .view-all-mobile .arrow-icon {
    transform: rotate(180deg);
  }
}

div.gaiaFeatured {
  position: relative;
  display: flex;
  flex-direction: column;
  color: white;
  width: 100%;
  max-width: 100vw;
  height: 600px;
  box-sizing: border-box;
  transition: min-height 0.3s ease;
  margin-bottom: 20px;
}
@media (max-width: 768px) {
  div.gaiaFeatured {
    height: 400px;
  }
}
div.gaiaFeatured .swiper {
  width: 100%;
  overflow: hidden;
  height: 600px;
}
div.gaiaFeatured .swiper-slide {
  width: 100%;
  opacity: 0.4;
}
div.gaiaFeatured .swiper-slide-active {
  opacity: 1;
}
div.gaiaFeatured div.bgImageContainer {
  min-width: 100%;
  position: relative;
  overflow: hidden;
}
div.gaiaFeatured div.bgImageContainer img {
  display: block;
  width: 100%;
  -o-object-fit: cover;
     object-fit: cover;
  height: 600px;
}
@media (max-width: 768px) {
  div.gaiaFeatured div.bgImageContainer img {
    height: 400px;
  }
}
div.gaiaFeatured div.contents {
  position: absolute;
  bottom: 20%;
  width: 100%;
  padding: 10px;
  padding-top: 20px;
  padding-bottom: 20px;
  padding-left: 3%;
  z-index: 2;
  background: radial-gradient(ellipse at left, rgba(0, 0, 0, 0.5) 0%, rgba(0, 0, 0, 0.3) 55%, rgba(0, 0, 0, 0.1) 68%, rgba(255, 255, 255, 0) 75%);
  background-size: 100% 100%;
  background-position: 100% 0%;
}
@media (max-width: 768px) {
  div.gaiaFeatured div.contents {
    bottom: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    margin-left: 0;
    padding: 0;
    background: linear-gradient(to top, rgba(0, 0, 0, 0.9) 0%, rgba(0, 0, 0, 0.7) 50%, rgba(0, 0, 0, 0.5) 90%, rgba(0, 0, 0, 0.2) 95%, rgba(0, 0, 0, 0) 100%);
  }
}
div.gaiaFeatured div.contents div.categoryName {
  font-size: 18px;
  font-family: "Avenir-Black", sans-serif;
  margin-bottom: 20px;
  padding-left: 12px;
  border-left: 3px solid var(--button-color);
}
@media (max-width: 767px) {
  div.gaiaFeatured div.contents div.categoryName {
    border-left: none;
  }
}
div.gaiaFeatured div.contents div.categoryName.hidden {
  display: none;
}
div.gaiaFeatured div.contents div.showCast {
  font-family: "Avenir-Black", sans-serif;
  color: var(--inner-text-color);
  text-decoration: none;
  font-size: 14px;
  font-weight: bold;
  margin-bottom: 5px;
  max-width: 30%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
@media (max-width: 768px) {
  div.gaiaFeatured div.contents div.showCast {
    font-size: 16px;
    margin-bottom: 0px;
  }
}
@media (max-width: 480px) {
  div.gaiaFeatured div.contents div.showCast {
    display: none;
  }
}
div.gaiaFeatured div.contents div.showCast span {
  margin-right: 10px;
}
div.gaiaFeatured div.contents div.showCast.hidden {
  display: none;
}
div.gaiaFeatured div.contents h1.title {
  font-size: 30px;
  margin-bottom: 15px;
  width: 45%;
}
@media (max-width: 767px) {
  div.gaiaFeatured div.contents h1.title {
    width: auto;
    font-size: 24px;
  }
}
div.gaiaFeatured div.contents div.details {
  margin-bottom: 10px;
}
div.gaiaFeatured div.contents div.details .director {
  font-size: 16px;
  margin-right: 15px;
}
div.gaiaFeatured div.contents div.details .director span {
  margin-right: 10px;
}
div.gaiaFeatured div.contents div.details .year {
  font-size: 16px;
}
div.gaiaFeatured div.contents div.details .year span {
  margin-right: 10px;
}
div.gaiaFeatured div.contents div.details.hidden {
  display: none;
}
div.gaiaFeatured div.contents p.description {
  font-size: 18px;
  margin-bottom: 15px;
  line-height: 1.5;
  max-width: 50%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
@media (max-width: 767px) {
  div.gaiaFeatured div.contents p.description {
    display: none;
  }
}
div.gaiaFeatured div.contents div.rating {
  margin-bottom: 15px;
}
div.gaiaFeatured div.contents div.rating .duration {
  font-size: 16px;
  margin-right: 15px;
}
div.gaiaFeatured div.contents div.rating .duration span {
  margin-right: 10px;
}
div.gaiaFeatured div.contents div.rating .duration.ltr {
  direction: ltr !important;
  unicode-bidi: embed !important;
}
div.gaiaFeatured div.contents div.rating .certificate {
  display: inline-block;
  padding: 1px 6px;
  background-color: rgba(150, 153, 163, 0.32);
  font-size: 14px;
  font-weight: 700;
  border-radius: 4px;
}
div.gaiaFeatured div.contents div.rating.hidden {
  display: none;
}
div.gaiaFeatured div.contents div.buttonContainer {
  display: flex;
  align-items: center;
  gap: 25px;
}
@media (max-width: 768px) {
  div.gaiaFeatured div.contents div.buttonContainer {
    margin-bottom: 4%;
  }
}
@media (max-width: 480px) {
  div.gaiaFeatured div.contents div.buttonContainer {
    margin-bottom: 5%;
  }
}
div.gaiaFeatured div.contents div.buttonContainer button {
  display: flex;
  align-items: center;
  background: transparent;
  border: none;
  color: white;
  font-size: 16px;
  cursor: pointer;
  white-space: nowrap;
  max-width: -moz-fit-content;
  max-width: fit-content;
  transition: fill 0.3s ease, color 0.5s ease;
}
@media (max-width: 767px) {
  div.gaiaFeatured div.contents div.buttonContainer button {
    font-size: 14px;
  }
}
div.gaiaFeatured div.contents div.buttonContainer button svg {
  margin-right: 5px;
  width: 40px;
  color: var(--button-color);
}
@media (max-width: 767px) {
  div.gaiaFeatured div.contents div.buttonContainer button svg {
    width: 30px;
  }
}
div.gaiaFeatured div.contents div.buttonContainer button:hover {
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
}
div.gaiaFeatured div.contents div.buttonContainer .watchNow:hover circle {
  transition: fill 0.3s ease, color 0.5s ease;
  fill: var(--button-hover);
}
div.gaiaFeatured div.contents div.buttonContainer .addToPlaylist:hover circle {
  transition: fill 0.3s ease, color 0.5s ease;
  stroke: var(--button-hover);
}
div.gaiaFeatured div.contents div.buttonContainer .addToPlaylist:hover path {
  transition: fill 0.3s ease, color 0.5s ease;
  fill: var(--button-hover);
}
div.gaiaFeatured div.contents div.buttonContainer .moredetails:hover path {
  fill: var(--button-hover);
}
@media (max-width: 767px) {
  div.gaiaFeatured div.contents div.buttonContainer .moredetails {
    display: none;
  }
}

div.gaiaFeatured.rtl .contents {
  background: radial-gradient(ellipse at right, rgba(0, 0, 0, 0.5) 0%, rgba(0, 0, 0, 0.3) 55%, rgba(0, 0, 0, 0.1) 68%, rgba(255, 255, 255, 0) 75%);
  padding-left: 0;
  padding-right: 3%;
}
div.gaiaFeatured.rtl .contents .categoryName {
  padding-left: 0;
  padding-right: 12px;
  border-left: none;
  border-right: 3px solid var(--button-color);
}
@media (max-width: 768px) {
  div.gaiaFeatured.rtl .contents .categoryName {
    border-right: none;
  }
}
div.gaiaFeatured.rtl .contents .rating .duration {
  margin-right: 0px;
  margin-left: 15px;
}
div.gaiaFeatured.rtl .contents .buttonContainer button svg {
  margin-right: 0;
  margin-left: 5px;
}

@keyframes slideUp {
  0% {
    transform: translateY(0);
    opacity: 0;
  }
  50% {
    transform: translateY(15px);
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
}
div.gaia-header-container {
  background: linear-gradient(180deg, rgba(16, 20, 31, 0.8) 0.98%, rgba(16, 20, 31, 0.4) 59.23%, rgba(16, 20, 31, 0.2) 78.16%, rgba(16, 20, 31, 0.0001) 96.12%);
  padding: 25px 0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 60px;
  z-index: 99;
  position: absolute;
}
div.gaia-header-container div.wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 90%;
}
div.gaia-header-container div.wrapper div.leftContainer {
  display: flex;
  align-items: center;
  margin-inline-start: -30px;
}
@media (max-width: 768px) {
  div.gaia-header-container div.wrapper div.leftContainer {
    margin-inline-start: -20px;
  }
}
@media (max-width: 640px) {
  div.gaia-header-container div.wrapper div.leftContainer {
    margin-inline-start: -10px;
  }
}
@media (max-width: 480px) {
  div.gaia-header-container div.wrapper div.leftContainer {
    margin-inline-start: -5px;
  }
}
@media (max-width: 360px) {
  div.gaia-header-container div.wrapper div.leftContainer {
    margin-inline-start: 0px;
  }
}
div.gaia-header-container div.wrapper div.leftContainer div.menuIconContainer {
  display: none;
  cursor: pointer;
  margin-inline-end: 25px;
}
@media screen and (max-width: 1200px) {
  div.gaia-header-container div.wrapper div.leftContainer div.menuIconContainer {
    display: inline-block;
    width: 24px;
    height: 24px;
    fill: white;
  }
}
div.gaia-header-container div.wrapper div.leftContainer div.logoContainer h1 {
  width: 100px;
}
@media all and (max-width: 480px) {
  div.gaia-header-container div.wrapper div.leftContainer div.logoContainer h1 {
    width: 75px;
  }
}
@media all and (max-width: 360px) {
  div.gaia-header-container div.wrapper div.leftContainer div.logoContainer h1 {
    width: 75px;
  }
}
div.gaia-header-container div.wrapper div.leftContainer div.logoContainer h1 img {
  display: block;
  width: 100%;
  -o-object-fit: contain;
     object-fit: contain;
  aspect-ratio: 2;
}
@media all and (max-width: 1200px) {
  div.gaia-header-container div.wrapper div.leftContainer ul.hide.hide {
    display: none;
  }
}
div.gaia-header-container div.wrapper div.leftContainer ul.browse {
  display: flex;
  align-items: center;
  margin-inline-start: 80px;
}
div.gaia-header-container div.wrapper div.leftContainer ul.browse li {
  cursor: pointer;
  margin-inline-end: 25px;
  font-size: 16px;
  color: white;
  padding: 15px 0;
  position: relative;
  text-decoration: none;
  z-index: 100;
  transition: color 0.3s ease, font-weight 0.3s ease;
}
div.gaia-header-container div.wrapper div.leftContainer ul.browse li:hover {
  color: var(--text-hover);
}
div.gaia-header-container div.wrapper div.leftContainer ul.browse li a {
  color: var(--text-color);
  text-decoration: none;
  font-size: 16px;
  padding: 15px 0;
  transition: color 0.3s ease, font-weight 0.3s ease;
}
@media (max-width: 1070px) {
  div.gaia-header-container div.wrapper div.leftContainer ul.browse li a {
    font-size: 12px;
  }
}
div.gaia-header-container div.wrapper div.leftContainer ul.browse li a.selected {
  font-weight: bold;
}
div.gaia-header-container div.wrapper div.leftContainer ul.browse li.mainMenu {
  cursor: pointer;
  padding: 0 15px;
}
div.gaia-header-container div.wrapper div.leftContainer ul.browse li.mainMenu:nth-child(2) {
  padding: 0 15px 0 0px;
}
div.gaia-header-container div.wrapper div.leftContainer ul.browse li.mainMenu:nth-last-child(2) {
  padding: 0 0px 0 15px;
}
div.gaia-header-container div.wrapper div.leftContainer ul.browse li.mainMenu.active {
  padding-bottom: 60px;
  margin-top: 60px;
}
div.gaia-header-container div.wrapper div.leftContainer ul.browse li.mainMenu.active span {
  color: var(--text-hover);
}
div.gaia-header-container div.wrapper div.leftContainer ul.browse li.mainMenu span {
  font-size: 14px;
  font-weight: 900;
  transition: 0.3s ease-in-out;
  color: #fff;
}
@media screen and (max-width: 1200px) {
  div.gaia-header-container div.wrapper div.leftContainer ul.browse {
    display: none;
  }
}
div.gaia-header-container div.wrapper div.rightContainer {
  display: flex;
  align-items: center;
  margin-inline-end: -20px;
  gap: 30px;
}
@media (max-width: 768px) {
  div.gaia-header-container div.wrapper div.rightContainer {
    margin-inline-end: -20px;
  }
}
@media (max-width: 640px) {
  div.gaia-header-container div.wrapper div.rightContainer {
    margin-inline-end: -10px;
  }
}
@media (max-width: 480px) {
  div.gaia-header-container div.wrapper div.rightContainer {
    margin-inline-end: -5px;
  }
}
@media (max-width: 360px) {
  div.gaia-header-container div.wrapper div.rightContainer {
    margin-inline-end: 0px;
  }
}
div.gaia-header-container div.wrapper div.rightContainer .searchIcon {
  width: 25px;
  height: 25px;
  color: var(--white-color);
  cursor: pointer;
}
@media (max-width: 1200px) {
  div.gaia-header-container div.wrapper div.rightContainer .searchIcon {
    display: none;
  }
}
div.gaia-header-container div.wrapper div.rightContainer div.languageDropdown {
  position: relative;
  cursor: pointer;
  display: inline-block;
  font-size: 16px;
  color: white;
  padding: 15px 0;
}
@media (max-width: 1200px) {
  div.gaia-header-container div.wrapper div.rightContainer div.languageDropdown {
    display: none;
  }
}
div.gaia-header-container div.wrapper div.rightContainer div.languageDropdown:hover {
  color: var(--text-hover);
}
div.gaia-header-container div.wrapper div.rightContainer div.languageDropdown div.language {
  font-size: 16px;
  font-weight: normal;
  transition: color 0.3s ease, font-weight 0.3s ease;
}
div.gaia-header-container div.wrapper div.rightContainer div.languageDropdown div.languageOptions {
  position: absolute;
  top: 100%;
  inset-inline-start: 0;
  min-width: 210px;
  background-color: var(--bg-color);
  padding-top: 15px;
  padding-bottom: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  z-index: 100;
}
div.gaia-header-container div.wrapper div.rightContainer div.languageDropdown div.languageOptions::before {
  content: "";
  position: absolute;
  top: 0;
  inset-inline-start: 0;
  width: 100%;
  height: 4px;
  background: linear-gradient(281deg, var(--gradient-right-color), var(--gradient-left-color));
}
div.gaia-header-container div.wrapper div.rightContainer div.languageDropdown div.languageOptions div.optionsList {
  display: flex;
  flex-direction: column;
}
div.gaia-header-container div.wrapper div.rightContainer div.languageDropdown div.languageOptions div.optionsList div.languageOption {
  font-size: 16px;
  padding: 8px 12px;
  color: var(--text-color);
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
  white-space: nowrap;
}
div.gaia-header-container div.wrapper div.rightContainer div.languageDropdown div.languageOptions div.optionsList div.languageOption[data-selected=true] {
  color: var(--gradient-left-color);
}
div.gaia-header-container div.wrapper div.rightContainer div.languageDropdown div.languageOptions div.optionsList div.languageOption[data-selected=true]:after {
  content: "✔";
  margin-inline-start: auto;
}
div.gaia-header-container div.wrapper div.rightContainer div.languageDropdown div.languageOptions div.optionsList div.languageOption:hover {
  color: var(--gradient-left-color);
}
div.gaia-header-container div.wrapper div.rightContainer div.userInfo {
  display: flex;
  align-items: center;
  cursor: pointer;
}
div.gaia-header-container div.wrapper div.rightContainer div.userInfo.active svg {
  transform: rotate(-180deg);
}
div.gaia-header-container div.wrapper div.rightContainer div.userInfo h4 {
  color: var(--text-color);
  font-size: 14px;
  margin-inline-start: 5px;
  margin-top: 5px;
}
div.gaia-header-container div.wrapper div.rightContainer div.userInfo h4.active {
  padding-top: 60px;
  padding-bottom: 60px;
}
div.gaia-header-container div.wrapper div.rightContainer div.userInfo h4 div.profileImageContainer img {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  -o-object-fit: cover;
     object-fit: cover;
}
div.gaia-header-container div.wrapper div.rightContainer div.userInfo h4 span.nameAvatar {
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--white-color);
  background-color: var(--grey-color);
  border-radius: 50%;
}
div.gaia-header-container div.wrapper div.rightContainer div.userInfo svg {
  color: var(--text-color) !important;
  width: 22px !important;
  height: 22px !important;
  transition: 0.3s ease-in-out;
}
div.gaia-header-container div.wrapper div.rightContainer div.buttonContainer {
  display: flex;
}
@media all and (max-width: 980px) {
  div.gaia-header-container div.wrapper div.rightContainer div.buttonContainer {
    margin-inline-start: 18px;
  }
}
@media all and (max-width: 480px) {
  div.gaia-header-container div.wrapper div.rightContainer div.buttonContainer {
    margin-inline-start: 15px;
  }
}
@media all and (max-width: 360px) {
  div.gaia-header-container div.wrapper div.rightContainer div.buttonContainer {
    margin-inline-start: 7px;
  }
}
div.gaia-header-container div.wrapper div.rightContainer div.buttonContainer button.register {
  background-color: #fff;
  padding: 10px 0;
  width: 6rem;
  border-radius: 30px;
  font-size: 14px;
  font-weight: 700;
  margin-inline-end: 30px;
  cursor: pointer;
}
@media all and (max-width: 768px) {
  div.gaia-header-container div.wrapper div.rightContainer div.buttonContainer button.register {
    padding: 11px 0;
    margin-inline-end: 18px;
  }
}
@media all and (max-width: 360px) {
  div.gaia-header-container div.wrapper div.rightContainer div.buttonContainer button.register {
    padding: 8px 0;
    width: 5rem;
    font-size: 12px;
    margin-inline-end: 10px;
  }
}
div.gaia-header-container div.wrapper div.rightContainer div.buttonContainer button.signIn {
  background-color: transparent;
  font-weight: 700;
  font-size: 14px;
  color: white;
  cursor: pointer;
}
@media all and (max-width: 360px) {
  div.gaia-header-container div.wrapper div.rightContainer div.buttonContainer button.signIn {
    font-size: 12px;
  }
}

div.userInfoContainerGaia {
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.2);
  border: 1px solid #f2f2f2;
  background-color: #f2f2f2;
  width: 310px;
  position: absolute;
  top: 60px;
  inset-inline-end: 3%;
  padding: 20px;
  display: none;
  z-index: 9999;
}
div.userInfoContainerGaia.active {
  display: block;
}
div.userInfoContainerGaia::before {
  content: "";
  position: absolute;
  top: 0;
  inset-inline-start: 0;
  width: 100%;
  height: 125px;
  background-color: #fff;
  z-index: -1; /* Top part with white background */
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
}
div.userInfoContainerGaia::after {
  content: "";
  position: absolute;
  top: -10px;
  inset-inline-start: 85%;
  transform: translateX(-50%);
  width: 0;
  height: 0;
  border-inline-start: 10px solid transparent;
  border-inline-end: 10px solid transparent;
  border-bottom: 10px solid #fff;
}
div.userInfoContainerGaia div.top {
  padding: 10px 20px 0;
}
div.userInfoContainerGaia div.top ul a li {
  padding: 15px 0;
  cursor: pointer;
}
div.userInfoContainerGaia div.top ul a li.user {
  display: flex;
  align-items: center;
  gap: 10px;
}
div.userInfoContainerGaia div.top ul a li div.profileImageContainer img {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  -o-object-fit: cover;
     object-fit: cover;
}
div.userInfoContainerGaia div.top ul a li div.nameAvatar {
  font-size: 12px;
}
div.userInfoContainerGaia div.top ul a li div.nameAvatar img {
  width: 30px;
}
div.userInfoContainerGaia div.top ul a li span.nameAvatar {
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--white-color);
  background-color: var(--grey-color);
  border-radius: 50%;
  margin-top: 20px;
}
div.userInfoContainerGaia div.top ul a li span.userName {
  padding-top: 20px;
  color: var(--inner-text-color);
  font-family: "Avenir-Black", sans-serif;
  font-weight: 900;
  font-size: 12px;
}
div.userInfoContainerGaia div.top ul a li:first-child {
  padding-top: 0;
}
div.userInfoContainerGaia div.top ul a li:last-child {
  border-bottom: none;
}
div.userInfoContainerGaia div.top ul a li span {
  margin-bottom: 20px;
  font-size: 14px;
  text-transform: none;
  color: var(--inner-text-color);
  transition: color 0.3s ease, font-weight 0.3s ease;
}
div.userInfoContainerGaia div.top ul a li span:hover {
  color: var(--text-hover);
}
div.userInfoContainerGaia div.top ul a .account {
  margin-top: -10px;
  margin-bottom: 20px;
}
div.userInfoContainerGaia div.top ul a:last-child {
  border: none;
}

div.browseMoreGaia {
  display: none;
}
@media all and (max-width: 1200px) {
  div.browseMoreGaia {
    display: none;
  }
}
div.browseMoreGaia .browseMoreContainerNew {
  position: absolute;
  top: 50px;
  inset-inline-start: 10%;
  background-color: var(--bg-color);
  border-radius: 6px;
  z-index: 999;
  max-height: 500px;
  overflow-y: auto;
  width: 300px;
  padding-bottom: 10px;
  border: 1px solid var(--bg-color);
  box-shadow: 0px 0px 4px 2px rgba(0, 0, 0, 0.2);
  scrollbar-width: none;
  -ms-overflow-style: none;
  animation: fadeIn 0.7s ease-in-out;
}
div.browseMoreGaia .browseMoreContainerNew::-webkit-scrollbar {
  width: 0;
  background: transparent;
}
div.browseMoreGaia .browseMoreContainerNew h1.categoryName {
  font-size: 14px;
  color: var(--grey-color);
  margin: 3% 2%;
  padding-bottom: 10px;
}
div.browseMoreGaia .browseMoreContainerNew li {
  padding-inline-start: 0;
}
div.browseMoreGaia .browseMoreContainerNew li li {
  position: relative;
  height: 40px;
  cursor: pointer;
  overflow: hidden;
  transition: transform 0.3s ease-in-out;
}
div.browseMoreGaia .browseMoreContainerNew li li:hover {
  transform: scale(1.05);
}
div.browseMoreGaia .browseMoreContainerNew li li:hover span {
  color: var(--text-color);
  top: 9px;
  inset-inline-start: 10px;
}
div.browseMoreGaia .browseMoreContainerNew li li:hover .shadeGradient {
  opacity: 0.8;
}
div.browseMoreGaia .browseMoreContainerNew li li:hover::after {
  opacity: 1;
}
div.browseMoreGaia .browseMoreContainerNew li li span {
  font-size: 14px;
  color: var(--text-color);
  z-index: 2;
  position: relative;
  transition: color 0.3s ease-in-out;
  margin-top: 3%;
  margin-inline-start: 2%;
}
div.browseMoreGaia .browseMoreContainerNew li li .shadeGradient {
  position: absolute;
  top: 0;
  inset-inline-end: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(270deg, rgba(var(--buttonColorRgb), 0) 0%, var(--buttonColorRgb) 100%);
  opacity: 0;
  pointer-events: none;
  transition: opacity 0.3s ease-in-out;
  z-index: 1;
}
div.browseMoreGaia .browseMoreContainerNew li li::after {
  content: "";
  position: absolute;
  top: 0;
  inset-inline-start: 0;
  width: 2px;
  height: 100%;
  background-color: var(--buttonSelection);
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
  z-index: 3;
}
div.browseMoreGaia .browseMoreContainerNew div.categoryContainer {
  padding: 15px 25px 0;
}

div.browseMoreGaia.active {
  display: block;
}
@media all and (max-width: 1200px) {
  div.browseMoreGaia.active {
    display: none;
  }
}

div.browseMoreResponsiveGaia {
  display: none;
}
@media (max-width: 1200px) {
  div.browseMoreResponsiveGaia {
    display: block;
  }
}
div.browseMoreResponsiveGaia div.container {
  position: absolute;
  inset-inline-start: -100%;
  width: 320px;
  background-color: var(--bg-color);
  padding: 15px 20px;
  z-index: 9999;
  margin-top: 60px;
  overflow: scroll;
  transition: 0.5s ease-in-out;
  border: 1px solid var(--bg-color);
  box-shadow: 0px 0px 4px 2px rgba(0, 0, 0, 0.2);
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}
div.browseMoreResponsiveGaia div.container::-webkit-scrollbar {
  display: none;
}
@media all and (max-width: 1200px) {
  div.browseMoreResponsiveGaia div.container {
    inset-inline-start: 0;
  }
}
@media all and (max-width: 480px) {
  div.browseMoreResponsiveGaia div.container {
    width: 100%;
  }
}
div.browseMoreResponsiveGaia div.container div.inputContainer2 {
  display: flex;
  align-items: center;
  background-color: var(--black-color);
  transition: border-color 0.5s;
  border-radius: 60px;
  color: white;
  padding: 10px;
  cursor: pointer;
  margin-inline-start: -10px;
  margin-bottom: 20px;
}
div.browseMoreResponsiveGaia div.container div.inputContainer2:hover {
  border: 1px solid var(--grey-color);
}
div.browseMoreResponsiveGaia div.container div.inputContainer2 input {
  background-color: transparent;
  border: none;
  color: var(--text-color);
  margin-inline-start: 10px;
  outline: none;
  width: 250px;
  font-size: 14px;
  font-family: "Avenir-Roman", sans-serif;
}
div.browseMoreResponsiveGaia div.container div.inputContainer2 input::-moz-placeholder {
  color: var(--grey-color);
}
div.browseMoreResponsiveGaia div.container div.inputContainer2 input::placeholder {
  color: var(--grey-color);
}
div.browseMoreResponsiveGaia div.container div.inputContainer2 svg {
  margin-inline-start: 2px;
  fill: var(--black-color);
  width: 20px;
  height: 20px;
}
div.browseMoreResponsiveGaia div.container div.head {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
div.browseMoreResponsiveGaia div.container div.head div.left h1 {
  color: var(--text-color);
  font-size: 16px;
}
div.browseMoreResponsiveGaia div.container div.head div.right svg {
  color: #fff;
  width: 23px !important;
  height: 23px !important;
}
div.browseMoreResponsiveGaia div.container div.top {
  margin-top: 20px;
}
div.browseMoreResponsiveGaia div.container div.top h1.categoryName {
  font-size: 16px;
  margin-bottom: 20px;
  color: var(--text-hover);
}
div.browseMoreResponsiveGaia div.container div.top ul li {
  margin-bottom: 15px;
}
div.browseMoreResponsiveGaia div.container div.top ul li span {
  font-size: 16px;
  color: var(--text-color);
  cursor: pointer;
}
div.browseMoreResponsiveGaia div.container div.top ul li span:hover {
  color: var(--text-hover);
}
div.browseMoreResponsiveGaia div.container div.center {
  padding: 30px 0;
}
div.browseMoreResponsiveGaia div.container div.center h1.categoryName {
  font-size: 16px;
  margin-bottom: 20px;
  color: var(--text-color);
}
div.browseMoreResponsiveGaia div.container div.center ul li {
  margin-bottom: 15px;
}
div.browseMoreResponsiveGaia div.container div.center ul li span {
  font-size: 16px;
  color: var(--text-color);
  cursor: pointer;
}
div.browseMoreResponsiveGaia div.container div.center ul li span:hover {
  color: var(--text-hover);
}
div.browseMoreResponsiveGaia div.container div.bottom {
  padding: 30px 0;
}
div.browseMoreResponsiveGaia div.container div.bottom h1.categoryName {
  font-size: 16px;
  margin-bottom: 20px;
  color: var(--text-color);
}
div.browseMoreResponsiveGaia div.container div.bottom ul li {
  margin-bottom: 15px;
}
div.browseMoreResponsiveGaia div.container div.bottom ul li span {
  font-size: 16px;
  color: var(--text-color);
  cursor: pointer;
}
div.browseMoreResponsiveGaia div.container div.bottom ul li span:hover {
  color: var(--text-hover);
}
div.browseMoreResponsiveGaia div.container div.languageDropdown {
  position: relative;
  cursor: pointer;
  display: inline-block;
  font-size: 16px;
  color: white;
  padding: 15px 0;
}
div.browseMoreResponsiveGaia div.container div.languageDropdown:hover {
  color: var(--text-hover);
}
div.browseMoreResponsiveGaia div.container div.languageDropdown h1.language {
  font-size: 16px;
  font-weight: bold;
  transition: color 0.3s ease, font-weight 0.3s ease;
}
div.browseMoreResponsiveGaia div.container div.languageDropdown div.languageOptions {
  position: absolute;
  top: 100%;
  inset-inline-start: 0;
  min-width: 210px;
  background-color: var(--bg-color);
  padding-top: 15px;
  padding-bottom: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  z-index: 100;
}
div.browseMoreResponsiveGaia div.container div.languageDropdown div.languageOptions::before {
  content: "";
  position: absolute;
  top: 0;
  inset-inline-start: 0;
  width: 100%;
  height: 4px;
  background: linear-gradient(281deg, var(--gradient-right-color), var(--gradient-left-color));
}
div.browseMoreResponsiveGaia div.container div.languageDropdown div.languageOptions div.optionsList {
  display: flex;
  flex-direction: column;
}
div.browseMoreResponsiveGaia div.container div.languageDropdown div.languageOptions div.optionsList div.languageOption {
  font-size: 16px;
  padding: 8px 12px;
  color: var(--text-color);
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
  white-space: nowrap;
}
div.browseMoreResponsiveGaia div.container div.languageDropdown div.languageOptions div.optionsList div.languageOption[data-selected=true] {
  color: var(--gradient-left-color);
}
div.browseMoreResponsiveGaia div.container div.languageDropdown div.languageOptions div.optionsList div.languageOption[data-selected=true]:after {
  content: "✔";
  margin-inline-start: auto;
}
div.browseMoreResponsiveGaia div.container div.languageDropdown div.languageOptions div.optionsList div.languageOption:hover {
  color: var(--gradient-left-color);
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes fadeOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
.searchModal {
  position: fixed;
  top: 0;
  inset-inline-start: 0;
  width: 100%;
  height: 90px;
  background-color: #1F252E;
  gap: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
}
@media (max-width: 1200px) {
  .searchModal {
    display: none;
  }
}
.searchModal div.overlay {
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  background-color: var(--overlay);
  z-index: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  animation: fadeIn 0.3s ease-in-out;
}
.searchModal .modalContent {
  position: relative;
  display: flex;
  align-items: center;
  background-color: #1F252E;
  transition: border-color 0.5s;
  border-radius: 60px;
  color: white;
  padding: 10px;
  cursor: pointer;
  width: 87%;
  height: 70%;
  z-index: 2;
}
.searchModal .modalContent input {
  background-color: transparent;
  border: none;
  color: var(--white-color);
  margin-inline-start: 10px;
  outline: none;
  width: 100%;
  font-size: 20px;
  font-family: "Avenir-Roman", sans-serif;
}
.searchModal .modalContent input::-moz-placeholder {
  color: var(--grey-color);
}
.searchModal .modalContent input::placeholder {
  color: var(--grey-color);
}
.searchModal .modalContent svg {
  margin-inline-start: 10px;
  fill: var(--black-color);
  width: 25px;
  height: 25px;
}
.searchModal .cancelButton {
  color: white;
  cursor: pointer;
  font-size: 20px;
  z-index: 2;
}

div.gaiaCategoriesContainer {
  direction: ltr;
  text-align: left;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding-inline-start: 4.5%;
  padding-inline-end: 4.5%;
  padding-bottom: 20px;
  padding-top: 20px;
  z-index: 10;
  max-width: 1980px;
  margin: 0 auto;
}
@media ((max-width: 640px) and (min-width: 360px)) {
  div.gaiaCategoriesContainer {
    padding-top: 40px;
  }
}
div.gaiaCategoriesContainer .wrapper {
  width: 100%;
}
div.gaiaCategoriesContainer .wrapper .swiper {
  width: 100%;
}
div.gaiaCategoriesContainer .wrapper .swiper:hover {
  z-index: 999999;
}
div.gaiaCategoriesContainer .wrapper .swiper .swiper-slide {
  display: flex;
  justify-content: center;
  align-items: center;
}
div.gaiaCategoriesContainer .wrapper .swiper .swiper-slide.swiper-slide-active {
  transition: transform 0.3s ease;
}
div.gaiaCategoriesContainer .wrapper .swiper .swiper-slide.swiper-slide-active:hover {
  transform: translateX(15%);
}
@media (max-width: 480px) {
  div.gaiaCategoriesContainer .wrapper .swiper .swiper-slide.swiper-slide-active:hover {
    transform: none;
  }
}
div.gaiaCategoriesContainer .wrapper .swiper .swiper-slide.swiper-slide.translate-last {
  transition: transform 0.3s ease;
}
div.gaiaCategoriesContainer .wrapper .swiper .swiper-slide.swiper-slide.translate-last:hover {
  transform: translateX(-16%);
}
@media (max-width: 480px) {
  div.gaiaCategoriesContainer .wrapper .swiper .swiper-slide.swiper-slide.translate-last:hover {
    transform: none;
  }
}
div.gaiaCategoriesContainer .wrapper .swiper .swiper-slide:hover {
  z-index: 99999;
}
div.gaiaCategoriesContainer .wrapper .swiper .swiper-slide.no-transform.swiper-slide-active:hover, div.gaiaCategoriesContainer .wrapper .swiper .swiper-slide.no-transform.translate-last:hover {
  transform: none;
}
div.gaiaCategoriesContainer .wrapper .swiper .swiper-button-next,
div.gaiaCategoriesContainer .wrapper .swiper .swiper-button-prev {
  height: 100%;
  color: white;
  width: 5% !important;
  background: var(--bg-color);
}
div.gaiaCategoriesContainer .wrapper .swiper .swiper-button-next .svg,
div.gaiaCategoriesContainer .wrapper .swiper .swiper-button-prev .svg {
  height: 60px;
  align-items: center;
  color: white;
}
div.gaiaCategoriesContainer .wrapper .swiper .swiper-button-next::after,
div.gaiaCategoriesContainer .wrapper .swiper .swiper-button-prev::after {
  font-size: 18px;
  font-weight: bolder;
  color: var(--text-color);
  position: absolute;
  z-index: 10;
  box-shadow: 0 6px 20px rgba(0, 0, 0, 0.2);
}
div.gaiaCategoriesContainer .wrapper .swiper .swiper-button-next::after:hover,
div.gaiaCategoriesContainer .wrapper .swiper .swiper-button-prev::after:hover {
  background-color: var(--button-hover);
  transform: scale(1.3);
}
div.gaiaCategoriesContainer .wrapper .swiper .swiper-button-next {
  right: -5% !important;
}
div.gaiaCategoriesContainer .wrapper .swiper .swiper-button-next::after {
  left: -21%;
}
div.gaiaCategoriesContainer .wrapper .swiper .swiper-button-prev {
  left: -5% !important;
}
div.gaiaCategoriesContainer .wrapper .swiper .swiper-button-prev::after {
  right: -27%;
}
div.gaiaCategoriesContainer .wrapper .swiper .swiper-button-disabled {
  opacity: 0;
  pointer-events: none;
}
div.gaiaCategoriesContainer .wrapper .swiper.items.portrait.rowCount3 .swiper-button-next, div.gaiaCategoriesContainer .wrapper .swiper.items.portrait.rowCount3 .swiper-button-prev {
  margin-top: -40px;
  height: 110%;
}
@media (max-width: 2600px) {
  div.gaiaCategoriesContainer .wrapper .swiper.items.portrait.rowCount3 .swiper-button-next {
    padding-right: 35%;
    right: -35% !important;
  }
}
@media (max-width: 2600px) {
  div.gaiaCategoriesContainer .wrapper .swiper.items.portrait.rowCount3 .swiper-button-next::after {
    left: -11% !important;
  }
}
@media (max-width: 1768px) {
  div.gaiaCategoriesContainer .wrapper .swiper.items.portrait.rowCount3 .swiper-button-next::after {
    left: -8% !important;
  }
}
@media (max-width: 1600px) {
  div.gaiaCategoriesContainer .wrapper .swiper.items.portrait.rowCount3 .swiper-button-next::after {
    left: -5% !important;
  }
}
@media (max-width: 1199px) {
  div.gaiaCategoriesContainer .wrapper .swiper.items.portrait.rowCount3 .swiper-button-next::after {
    left: -3% !important;
  }
}
@media (max-width: 2600px) {
  div.gaiaCategoriesContainer .wrapper .swiper.items.portrait.rowCount3 .swiper-button-prev {
    padding-left: 35%;
    left: -35% !important;
  }
}
@media (max-width: 2600px) {
  div.gaiaCategoriesContainer .wrapper .swiper.items.portrait.rowCount3 .swiper-button-prev::after {
    right: -11% !important;
  }
}
@media (max-width: 1768px) {
  div.gaiaCategoriesContainer .wrapper .swiper.items.portrait.rowCount3 .swiper-button-prev::after {
    right: -8% !important;
  }
}
@media (max-width: 1600px) {
  div.gaiaCategoriesContainer .wrapper .swiper.items.portrait.rowCount3 .swiper-button-prev::after {
    right: -5% !important;
  }
}
@media (max-width: 1199px) {
  div.gaiaCategoriesContainer .wrapper .swiper.items.portrait.rowCount3 .swiper-button-prev::after {
    right: -4% !important;
  }
}
div.gaiaCategoriesContainer .wrapper .swiper.items.portrait.rowCount4 .swiper-button-next, div.gaiaCategoriesContainer .wrapper .swiper.items.portrait.rowCount4 .swiper-button-prev {
  margin-top: -35px;
  height: 110%;
}
@media (max-width: 2600px) {
  div.gaiaCategoriesContainer .wrapper .swiper.items.portrait.rowCount4 .swiper-button-next::after, div.gaiaCategoriesContainer .wrapper .swiper.items.portrait.rowCount4 .swiper-button-prev::after {
    top: 42% !important;
  }
}
@media (max-width: 1980px) {
  div.gaiaCategoriesContainer .wrapper .swiper.items.portrait.rowCount4 .swiper-button-next::after, div.gaiaCategoriesContainer .wrapper .swiper.items.portrait.rowCount4 .swiper-button-prev::after {
    top: 45% !important;
  }
}
@media (max-width: 1200px) {
  div.gaiaCategoriesContainer .wrapper .swiper.items.portrait.rowCount4 .swiper-button-next::after, div.gaiaCategoriesContainer .wrapper .swiper.items.portrait.rowCount4 .swiper-button-prev::after {
    top: 49% !important;
  }
}
@media (max-width: 2600px) {
  div.gaiaCategoriesContainer .wrapper .swiper.items.portrait.rowCount4 .swiper-button-next {
    padding-right: 35%;
    right: -35% !important;
  }
}
@media (max-width: 2600px) {
  div.gaiaCategoriesContainer .wrapper .swiper.items.portrait.rowCount4 .swiper-button-next::after {
    left: -4% !important;
  }
}
@media (max-width: 1400px) {
  div.gaiaCategoriesContainer .wrapper .swiper.items.portrait.rowCount4 .swiper-button-next::after {
    left: -5% !important;
  }
}
@media (max-width: 1200px) {
  div.gaiaCategoriesContainer .wrapper .swiper.items.portrait.rowCount4 .swiper-button-next::after {
    left: -3% !important;
  }
}
@media (max-width: 2600px) {
  div.gaiaCategoriesContainer .wrapper .swiper.items.portrait.rowCount4 .swiper-button-prev {
    padding-left: 35%;
    left: -35% !important;
  }
}
@media (max-width: 2600px) {
  div.gaiaCategoriesContainer .wrapper .swiper.items.portrait.rowCount4 .swiper-button-prev::after {
    right: -5% !important;
  }
}
@media (max-width: 1400px) {
  div.gaiaCategoriesContainer .wrapper .swiper.items.portrait.rowCount4 .swiper-button-prev::after {
    right: -6% !important;
  }
}
@media (max-width: 1200px) {
  div.gaiaCategoriesContainer .wrapper .swiper.items.portrait.rowCount4 .swiper-button-prev::after {
    right: -4% !important;
  }
}
div.gaiaCategoriesContainer .wrapper .swiper.items.portrait.rowCount5 .swiper-button-next, div.gaiaCategoriesContainer .wrapper .swiper.items.portrait.rowCount5 .swiper-button-prev {
  margin-top: -35px;
  height: 110%;
}
@media (max-width: 480px) {
  div.gaiaCategoriesContainer .wrapper .swiper.items.portrait.rowCount5 .swiper-button-next, div.gaiaCategoriesContainer .wrapper .swiper.items.portrait.rowCount5 .swiper-button-prev {
    margin-top: -60px;
  }
}
@media (width: 980px) {
  div.gaiaCategoriesContainer .wrapper .swiper.items.portrait.rowCount5 .swiper-button-next, div.gaiaCategoriesContainer .wrapper .swiper.items.portrait.rowCount5 .swiper-button-prev {
    height: 115%;
  }
}
@media (max-width: 1400px) {
  div.gaiaCategoriesContainer .wrapper .swiper.items.portrait.rowCount5 .swiper-button-next::after, div.gaiaCategoriesContainer .wrapper .swiper.items.portrait.rowCount5 .swiper-button-prev::after {
    top: 47% !important;
  }
}
@media (max-width: 480px) {
  div.gaiaCategoriesContainer .wrapper .swiper.items.portrait.rowCount5 .swiper-button-next::after, div.gaiaCategoriesContainer .wrapper .swiper.items.portrait.rowCount5 .swiper-button-prev::after {
    top: 45% !important;
  }
}
@media (max-width: 360px) {
  div.gaiaCategoriesContainer .wrapper .swiper.items.portrait.rowCount5 .swiper-button-next::after, div.gaiaCategoriesContainer .wrapper .swiper.items.portrait.rowCount5 .swiper-button-prev::after {
    top: 45% !important;
  }
}
@media (max-width: 2600px) {
  div.gaiaCategoriesContainer .wrapper .swiper.items.portrait.rowCount5 .swiper-button-next {
    padding-right: 35%;
    right: -35% !important;
  }
}
@media (max-width: 2600px) {
  div.gaiaCategoriesContainer .wrapper .swiper.items.portrait.rowCount5 .swiper-button-next::after {
    left: -4% !important;
  }
}
@media (max-width: 980px) {
  div.gaiaCategoriesContainer .wrapper .swiper.items.portrait.rowCount5 .swiper-button-next::after {
    left: -4% !important;
  }
}
@media (max-width: 768px) {
  div.gaiaCategoriesContainer .wrapper .swiper.items.portrait.rowCount5 .swiper-button-next::after {
    left: -5% !important;
  }
}
@media (max-width: 640px) {
  div.gaiaCategoriesContainer .wrapper .swiper.items.portrait.rowCount5 .swiper-button-next::after {
    left: -7% !important;
  }
}
@media (max-width: 480px) {
  div.gaiaCategoriesContainer .wrapper .swiper.items.portrait.rowCount5 .swiper-button-next::after {
    left: -8% !important;
  }
}
@media (max-width: 360px) {
  div.gaiaCategoriesContainer .wrapper .swiper.items.portrait.rowCount5 .swiper-button-next::after {
    left: -10% !important;
  }
}
@media (max-width: 2600px) {
  div.gaiaCategoriesContainer .wrapper .swiper.items.portrait.rowCount5 .swiper-button-prev {
    padding-left: 35%;
    left: -35% !important;
  }
}
@media (max-width: 2600px) {
  div.gaiaCategoriesContainer .wrapper .swiper.items.portrait.rowCount5 .swiper-button-prev::after {
    right: -4% !important;
  }
}
@media (max-width: 980px) {
  div.gaiaCategoriesContainer .wrapper .swiper.items.portrait.rowCount5 .swiper-button-prev::after {
    right: -5% !important;
  }
}
@media (max-width: 768px) {
  div.gaiaCategoriesContainer .wrapper .swiper.items.portrait.rowCount5 .swiper-button-prev::after {
    right: -6% !important;
  }
}
@media (max-width: 640px) {
  div.gaiaCategoriesContainer .wrapper .swiper.items.portrait.rowCount5 .swiper-button-prev::after {
    right: -8% !important;
  }
}
@media (max-width: 480px) {
  div.gaiaCategoriesContainer .wrapper .swiper.items.portrait.rowCount5 .swiper-button-prev::after {
    right: -9% !important;
  }
}
@media (max-width: 360px) {
  div.gaiaCategoriesContainer .wrapper .swiper.items.portrait.rowCount5 .swiper-button-prev::after {
    right: -12% !important;
  }
}
div.gaiaCategoriesContainer .wrapper .swiper.items.portrait.rowCount6 .swiper-button-next, div.gaiaCategoriesContainer .wrapper .swiper.items.portrait.rowCount6 .swiper-button-prev {
  margin-top: -30px;
  height: 110%;
}
@media (max-width: 2600px) {
  div.gaiaCategoriesContainer .wrapper .swiper.items.portrait.rowCount6 .swiper-button-next::after, div.gaiaCategoriesContainer .wrapper .swiper.items.portrait.rowCount6 .swiper-button-prev::after {
    top: 42% !important;
  }
}
@media (max-width: 1199px) {
  div.gaiaCategoriesContainer .wrapper .swiper.items.portrait.rowCount6 .swiper-button-next::after, div.gaiaCategoriesContainer .wrapper .swiper.items.portrait.rowCount6 .swiper-button-prev::after {
    top: 47% !important;
  }
}
@media (max-width: 2600px) {
  div.gaiaCategoriesContainer .wrapper .swiper.items.portrait.rowCount6 .swiper-button-next {
    padding-right: 35%;
    right: -35% !important;
  }
}
@media (max-width: 2600px) {
  div.gaiaCategoriesContainer .wrapper .swiper.items.portrait.rowCount6 .swiper-button-next::after {
    left: -4% !important;
  }
}
@media (max-width: 1400px) {
  div.gaiaCategoriesContainer .wrapper .swiper.items.portrait.rowCount6 .swiper-button-next::after {
    left: -5% !important;
  }
}
@media (max-width: 1199px) {
  div.gaiaCategoriesContainer .wrapper .swiper.items.portrait.rowCount6 .swiper-button-next::after {
    left: -4% !important;
  }
}
@media (max-width: 2600px) {
  div.gaiaCategoriesContainer .wrapper .swiper.items.portrait.rowCount6 .swiper-button-prev {
    padding-left: 35%;
    left: -35% !important;
  }
}
@media (max-width: 2600px) {
  div.gaiaCategoriesContainer .wrapper .swiper.items.portrait.rowCount6 .swiper-button-prev::after {
    right: -4% !important;
  }
}
@media (max-width: 1400px) {
  div.gaiaCategoriesContainer .wrapper .swiper.items.portrait.rowCount6 .swiper-button-prev::after {
    right: -5% !important;
  }
}
@media (max-width: 1199px) {
  div.gaiaCategoriesContainer .wrapper .swiper.items.portrait.rowCount6 .swiper-button-prev::after {
    right: -3% !important;
  }
}
div.gaiaCategoriesContainer .wrapper .swiper.items.portrait.rowCount7 .swiper-button-next, div.gaiaCategoriesContainer .wrapper .swiper.items.portrait.rowCount7 .swiper-button-prev {
  height: 110%;
}
@media (max-width: 1199px) {
  div.gaiaCategoriesContainer .wrapper .swiper.items.portrait.rowCount7 .swiper-button-next::after, div.gaiaCategoriesContainer .wrapper .swiper.items.portrait.rowCount7 .swiper-button-prev::after {
    top: 48% !important;
  }
}
@media (max-width: 2600px) {
  div.gaiaCategoriesContainer .wrapper .swiper.items.portrait.rowCount7 .swiper-button-next {
    padding-right: 35%;
    right: -35% !important;
  }
}
@media (max-width: 2600px) {
  div.gaiaCategoriesContainer .wrapper .swiper.items.portrait.rowCount7 .swiper-button-next::after {
    left: -4% !important;
  }
}
@media (max-width: 1199px) {
  div.gaiaCategoriesContainer .wrapper .swiper.items.portrait.rowCount7 .swiper-button-next::after {
    left: -3% !important;
  }
}
@media (max-width: 2600px) {
  div.gaiaCategoriesContainer .wrapper .swiper.items.portrait.rowCount7 .swiper-button-prev {
    padding-left: 35%;
    left: -35% !important;
  }
}
@media (max-width: 2600px) {
  div.gaiaCategoriesContainer .wrapper .swiper.items.portrait.rowCount7 .swiper-button-prev::after {
    right: -4% !important;
  }
}
@media (max-width: 1199px) {
  div.gaiaCategoriesContainer .wrapper .swiper.items.portrait.rowCount7 .swiper-button-prev::after {
    right: -3% !important;
  }
}
div.gaiaCategoriesContainer .wrapper .swiper.items.portrait.rowCount8 .swiper-button-next, div.gaiaCategoriesContainer .wrapper .swiper.items.portrait.rowCount8 .swiper-button-prev {
  height: 110%;
}
@media (max-width: 1199px) {
  div.gaiaCategoriesContainer .wrapper .swiper.items.portrait.rowCount8 .swiper-button-next::after, div.gaiaCategoriesContainer .wrapper .swiper.items.portrait.rowCount8 .swiper-button-prev::after {
    top: 47% !important;
  }
}
@media (max-width: 2600px) {
  div.gaiaCategoriesContainer .wrapper .swiper.items.portrait.rowCount8 .swiper-button-next {
    padding-right: 35%;
    right: -35% !important;
  }
}
@media (max-width: 2600px) {
  div.gaiaCategoriesContainer .wrapper .swiper.items.portrait.rowCount8 .swiper-button-next::after {
    left: -4% !important;
  }
}
@media (max-width: 1199px) {
  div.gaiaCategoriesContainer .wrapper .swiper.items.portrait.rowCount8 .swiper-button-next::after {
    left: -3.5% !important;
  }
}
@media (max-width: 2600px) {
  div.gaiaCategoriesContainer .wrapper .swiper.items.portrait.rowCount8 .swiper-button-prev {
    padding-left: 35%;
    left: -35% !important;
  }
}
@media (max-width: 2600px) {
  div.gaiaCategoriesContainer .wrapper .swiper.items.portrait.rowCount8 .swiper-button-prev::after {
    right: -4% !important;
  }
}
@media (max-width: 1199px) {
  div.gaiaCategoriesContainer .wrapper .swiper.items.portrait.rowCount8 .swiper-button-prev::after {
    right: -3.5% !important;
  }
}
div.gaiaCategoriesContainer .wrapper .swiper.items.landscape.rowCount3 .swiper-button-next, div.gaiaCategoriesContainer .wrapper .swiper.items.landscape.rowCount3 .swiper-button-prev {
  height: 110%;
}
@media (max-width: 1200px) {
  div.gaiaCategoriesContainer .wrapper .swiper.items.landscape.rowCount3 .swiper-button-next::after, div.gaiaCategoriesContainer .wrapper .swiper.items.landscape.rowCount3 .swiper-button-prev::after {
    top: 45% !important;
  }
}
@media (max-width: 1199px) {
  div.gaiaCategoriesContainer .wrapper .swiper.items.landscape.rowCount3 .swiper-button-next::after, div.gaiaCategoriesContainer .wrapper .swiper.items.landscape.rowCount3 .swiper-button-prev::after {
    top: 50% !important;
  }
}
@media (max-width: 2600px) {
  div.gaiaCategoriesContainer .wrapper .swiper.items.landscape.rowCount3 .swiper-button-next {
    padding-right: 35%;
    right: -35% !important;
  }
}
@media (max-width: 2600px) {
  div.gaiaCategoriesContainer .wrapper .swiper.items.landscape.rowCount3 .swiper-button-next::after {
    left: -3% !important;
  }
}
@media (max-width: 1768px) {
  div.gaiaCategoriesContainer .wrapper .swiper.items.landscape.rowCount3 .swiper-button-next::after {
    left: -4% !important;
  }
}
@media (max-width: 1199px) {
  div.gaiaCategoriesContainer .wrapper .swiper.items.landscape.rowCount3 .swiper-button-next::after {
    left: -3.5% !important;
  }
}
@media (max-width: 2600px) {
  div.gaiaCategoriesContainer .wrapper .swiper.items.landscape.rowCount3 .swiper-button-prev {
    padding-right: 35%;
    left: -35% !important;
  }
}
@media (max-width: 2600px) {
  div.gaiaCategoriesContainer .wrapper .swiper.items.landscape.rowCount3 .swiper-button-prev::after {
    right: -4% !important;
  }
}
@media (max-width: 1768px) {
  div.gaiaCategoriesContainer .wrapper .swiper.items.landscape.rowCount3 .swiper-button-prev::after {
    right: -5% !important;
  }
}
@media (max-width: 1199px) {
  div.gaiaCategoriesContainer .wrapper .swiper.items.landscape.rowCount3 .swiper-button-prev::after {
    right: -4% !important;
  }
}
div.gaiaCategoriesContainer .wrapper .swiper.items.landscape.rowCount4 .swiper-button-next, div.gaiaCategoriesContainer .wrapper .swiper.items.landscape.rowCount4 .swiper-button-prev {
  margin-top: -35px;
  height: 120%;
}
@media (max-width: 1199px) {
  div.gaiaCategoriesContainer .wrapper .swiper.items.landscape.rowCount4 .swiper-button-next, div.gaiaCategoriesContainer .wrapper .swiper.items.landscape.rowCount4 .swiper-button-prev {
    height: 125%;
  }
}
@media (max-width: 2600px) {
  div.gaiaCategoriesContainer .wrapper .swiper.items.landscape.rowCount4 .swiper-button-next::after, div.gaiaCategoriesContainer .wrapper .swiper.items.landscape.rowCount4 .swiper-button-prev::after {
    top: 45% !important;
  }
}
@media (max-width: 1980px) {
  div.gaiaCategoriesContainer .wrapper .swiper.items.landscape.rowCount4 .swiper-button-next::after, div.gaiaCategoriesContainer .wrapper .swiper.items.landscape.rowCount4 .swiper-button-prev::after {
    top: 45% !important;
  }
}
@media (max-width: 1400px) {
  div.gaiaCategoriesContainer .wrapper .swiper.items.landscape.rowCount4 .swiper-button-next::after, div.gaiaCategoriesContainer .wrapper .swiper.items.landscape.rowCount4 .swiper-button-prev::after {
    top: 48% !important;
  }
}
@media (max-width: 1199px) {
  div.gaiaCategoriesContainer .wrapper .swiper.items.landscape.rowCount4 .swiper-button-next::after, div.gaiaCategoriesContainer .wrapper .swiper.items.landscape.rowCount4 .swiper-button-prev::after {
    top: 55% !important;
  }
}
@media (max-width: 2600px) {
  div.gaiaCategoriesContainer .wrapper .swiper.items.landscape.rowCount4 .swiper-button-next {
    padding-right: 35%;
    right: -35% !important;
  }
}
@media (max-width: 2600px) {
  div.gaiaCategoriesContainer .wrapper .swiper.items.landscape.rowCount4 .swiper-button-next::after {
    left: -4% !important;
  }
}
@media (max-width: 1400px) {
  div.gaiaCategoriesContainer .wrapper .swiper.items.landscape.rowCount4 .swiper-button-next::after {
    left: -5% !important;
  }
}
@media (max-width: 1200px) {
  div.gaiaCategoriesContainer .wrapper .swiper.items.landscape.rowCount4 .swiper-button-next::after {
    left: -3% !important;
  }
}
@media (max-width: 2600px) {
  div.gaiaCategoriesContainer .wrapper .swiper.items.landscape.rowCount4 .swiper-button-prev {
    padding-left: 35%;
    left: -35% !important;
  }
}
@media (max-width: 2600px) {
  div.gaiaCategoriesContainer .wrapper .swiper.items.landscape.rowCount4 .swiper-button-prev::after {
    right: -5% !important;
  }
}
@media (max-width: 1400px) {
  div.gaiaCategoriesContainer .wrapper .swiper.items.landscape.rowCount4 .swiper-button-prev::after {
    right: -6% !important;
  }
}
@media (max-width: 1200px) {
  div.gaiaCategoriesContainer .wrapper .swiper.items.landscape.rowCount4 .swiper-button-prev::after {
    right: -4% !important;
  }
}
div.gaiaCategoriesContainer .wrapper .swiper.items.landscape.rowCount5 .swiper-button-next, div.gaiaCategoriesContainer .wrapper .swiper.items.landscape.rowCount5 .swiper-button-prev {
  height: 120%;
}
@media (max-width: 479px) {
  div.gaiaCategoriesContainer .wrapper .swiper.items.landscape.rowCount5 .swiper-button-next, div.gaiaCategoriesContainer .wrapper .swiper.items.landscape.rowCount5 .swiper-button-prev {
    margin-top: -60px;
  }
}
@media (max-width: 360px) {
  div.gaiaCategoriesContainer .wrapper .swiper.items.landscape.rowCount5 .swiper-button-next, div.gaiaCategoriesContainer .wrapper .swiper.items.landscape.rowCount5 .swiper-button-prev {
    height: 130%;
  }
}
div.gaiaCategoriesContainer .wrapper .swiper.items.landscape.rowCount5 .swiper-button-next::after, div.gaiaCategoriesContainer .wrapper .swiper.items.landscape.rowCount5 .swiper-button-prev::after {
  top: 43%;
}
@media (max-width: 2600px) {
  div.gaiaCategoriesContainer .wrapper .swiper.items.landscape.rowCount5 .swiper-button-next::after, div.gaiaCategoriesContainer .wrapper .swiper.items.landscape.rowCount5 .swiper-button-prev::after {
    top: 40%;
  }
}
@media (max-width: 1980px) {
  div.gaiaCategoriesContainer .wrapper .swiper.items.landscape.rowCount5 .swiper-button-next::after, div.gaiaCategoriesContainer .wrapper .swiper.items.landscape.rowCount5 .swiper-button-prev::after {
    top: 40%;
  }
}
@media (max-width: 1200px) {
  div.gaiaCategoriesContainer .wrapper .swiper.items.landscape.rowCount5 .swiper-button-next::after, div.gaiaCategoriesContainer .wrapper .swiper.items.landscape.rowCount5 .swiper-button-prev::after {
    top: 41%;
  }
}
@media (max-width: 1199px) {
  div.gaiaCategoriesContainer .wrapper .swiper.items.landscape.rowCount5 .swiper-button-next::after, div.gaiaCategoriesContainer .wrapper .swiper.items.landscape.rowCount5 .swiper-button-prev::after {
    top: 46%;
  }
}
@media (max-width: 980px) {
  div.gaiaCategoriesContainer .wrapper .swiper.items.landscape.rowCount5 .swiper-button-next::after, div.gaiaCategoriesContainer .wrapper .swiper.items.landscape.rowCount5 .swiper-button-prev::after {
    top: 45% !important;
  }
}
@media (max-width: 768px) {
  div.gaiaCategoriesContainer .wrapper .swiper.items.landscape.rowCount5 .swiper-button-next::after, div.gaiaCategoriesContainer .wrapper .swiper.items.landscape.rowCount5 .swiper-button-prev::after {
    top: 43% !important;
  }
}
@media (max-width: 479px) {
  div.gaiaCategoriesContainer .wrapper .swiper.items.landscape.rowCount5 .swiper-button-next::after, div.gaiaCategoriesContainer .wrapper .swiper.items.landscape.rowCount5 .swiper-button-prev::after {
    top: 51% !important;
  }
}
@media (max-width: 360px) {
  div.gaiaCategoriesContainer .wrapper .swiper.items.landscape.rowCount5 .swiper-button-next::after, div.gaiaCategoriesContainer .wrapper .swiper.items.landscape.rowCount5 .swiper-button-prev::after {
    top: 55% !important;
  }
}
@media (max-width: 2600px) {
  div.gaiaCategoriesContainer .wrapper .swiper.items.landscape.rowCount5 .swiper-button-next {
    padding-right: 35%;
    right: -35% !important;
  }
}
@media (max-width: 2600px) {
  div.gaiaCategoriesContainer .wrapper .swiper.items.landscape.rowCount5 .swiper-button-next::after {
    left: -4% !important;
  }
}
@media (max-width: 1200px) {
  div.gaiaCategoriesContainer .wrapper .swiper.items.landscape.rowCount5 .swiper-button-next::after {
    left: -6% !important;
  }
}
@media (max-width: 1199px) {
  div.gaiaCategoriesContainer .wrapper .swiper.items.landscape.rowCount5 .swiper-button-next::after {
    left: -3% !important;
  }
}
@media (max-width: 980px) {
  div.gaiaCategoriesContainer .wrapper .swiper.items.landscape.rowCount5 .swiper-button-next::after {
    left: -4% !important;
  }
}
@media (max-width: 768px) {
  div.gaiaCategoriesContainer .wrapper .swiper.items.landscape.rowCount5 .swiper-button-next::after {
    left: -5% !important;
  }
}
@media (max-width: 640px) {
  div.gaiaCategoriesContainer .wrapper .swiper.items.landscape.rowCount5 .swiper-button-next::after {
    left: -7% !important;
  }
}
@media (max-width: 479px) {
  div.gaiaCategoriesContainer .wrapper .swiper.items.landscape.rowCount5 .swiper-button-next::after {
    left: -8% !important;
  }
}
@media (max-width: 360px) {
  div.gaiaCategoriesContainer .wrapper .swiper.items.landscape.rowCount5 .swiper-button-next::after {
    left: -11% !important;
  }
}
@media (max-width: 2600px) {
  div.gaiaCategoriesContainer .wrapper .swiper.items.landscape.rowCount5 .swiper-button-prev {
    padding-left: 35%;
    left: -35% !important;
  }
}
@media (max-width: 2600px) {
  div.gaiaCategoriesContainer .wrapper .swiper.items.landscape.rowCount5 .swiper-button-prev::after {
    right: -4% !important;
  }
}
@media (max-width: 1200px) {
  div.gaiaCategoriesContainer .wrapper .swiper.items.landscape.rowCount5 .swiper-button-prev::after {
    right: -6% !important;
  }
}
@media (max-width: 1199px) {
  div.gaiaCategoriesContainer .wrapper .swiper.items.landscape.rowCount5 .swiper-button-prev::after {
    right: -3% !important;
  }
}
@media (max-width: 980px) {
  div.gaiaCategoriesContainer .wrapper .swiper.items.landscape.rowCount5 .swiper-button-prev::after {
    right: -5% !important;
  }
}
@media (max-width: 768px) {
  div.gaiaCategoriesContainer .wrapper .swiper.items.landscape.rowCount5 .swiper-button-prev::after {
    right: -6% !important;
  }
}
@media (max-width: 640px) {
  div.gaiaCategoriesContainer .wrapper .swiper.items.landscape.rowCount5 .swiper-button-prev::after {
    right: -8% !important;
  }
}
@media (max-width: 479px) {
  div.gaiaCategoriesContainer .wrapper .swiper.items.landscape.rowCount5 .swiper-button-prev::after {
    right: -9% !important;
  }
}
@media (max-width: 360px) {
  div.gaiaCategoriesContainer .wrapper .swiper.items.landscape.rowCount5 .swiper-button-prev::after {
    right: -13% !important;
  }
}
div.gaiaCategoriesContainer .wrapper .swiper.items.landscape.rowCount6 .swiper-button-next, div.gaiaCategoriesContainer .wrapper .swiper.items.landscape.rowCount6 .swiper-button-prev {
  height: 120%;
}
@media (max-width: 1199px) {
  div.gaiaCategoriesContainer .wrapper .swiper.items.landscape.rowCount6 .swiper-button-next, div.gaiaCategoriesContainer .wrapper .swiper.items.landscape.rowCount6 .swiper-button-prev {
    height: 125%;
  }
}
@media (max-width: 1768px) {
  div.gaiaCategoriesContainer .wrapper .swiper.items.landscape.rowCount6 .swiper-button-next::after, div.gaiaCategoriesContainer .wrapper .swiper.items.landscape.rowCount6 .swiper-button-prev::after {
    top: 42% !important;
  }
}
@media (max-width: 1199px) {
  div.gaiaCategoriesContainer .wrapper .swiper.items.landscape.rowCount6 .swiper-button-next::after, div.gaiaCategoriesContainer .wrapper .swiper.items.landscape.rowCount6 .swiper-button-prev::after {
    top: 48% !important;
  }
}
@media (max-width: 2600px) {
  div.gaiaCategoriesContainer .wrapper .swiper.items.landscape.rowCount6 .swiper-button-next {
    padding-right: 35%;
    right: -35% !important;
  }
}
@media (max-width: 2600px) {
  div.gaiaCategoriesContainer .wrapper .swiper.items.landscape.rowCount6 .swiper-button-next::after {
    left: -4% !important;
  }
}
@media (max-width: 2600px) {
  div.gaiaCategoriesContainer .wrapper .swiper.items.landscape.rowCount6 .swiper-button-prev {
    padding-left: 35%;
    left: -35% !important;
  }
}
@media (max-width: 2600px) {
  div.gaiaCategoriesContainer .wrapper .swiper.items.landscape.rowCount6 .swiper-button-prev::after {
    right: -4% !important;
  }
}
div.gaiaCategoriesContainer .wrapper .swiper.items.landscape.rowCount7 .swiper-button-next, div.gaiaCategoriesContainer .wrapper .swiper.items.landscape.rowCount7 .swiper-button-prev {
  height: 125%;
}
@media (max-width: 1199px) {
  div.gaiaCategoriesContainer .wrapper .swiper.items.landscape.rowCount7 .swiper-button-next, div.gaiaCategoriesContainer .wrapper .swiper.items.landscape.rowCount7 .swiper-button-prev {
    height: 130%;
  }
}
@media (max-width: 1768px) {
  div.gaiaCategoriesContainer .wrapper .swiper.items.landscape.rowCount7 .swiper-button-next::after, div.gaiaCategoriesContainer .wrapper .swiper.items.landscape.rowCount7 .swiper-button-prev::after {
    top: 38% !important;
  }
}
@media (max-width: 1199px) {
  div.gaiaCategoriesContainer .wrapper .swiper.items.landscape.rowCount7 .swiper-button-next::after, div.gaiaCategoriesContainer .wrapper .swiper.items.landscape.rowCount7 .swiper-button-prev::after {
    top: 45% !important;
  }
}
@media (max-width: 2600px) {
  div.gaiaCategoriesContainer .wrapper .swiper.items.landscape.rowCount7 .swiper-button-next {
    padding-right: 35%;
    right: -35% !important;
  }
}
@media (max-width: 2600px) {
  div.gaiaCategoriesContainer .wrapper .swiper.items.landscape.rowCount7 .swiper-button-next::after {
    left: -3% !important;
  }
}
@media (max-width: 1768px) {
  div.gaiaCategoriesContainer .wrapper .swiper.items.landscape.rowCount7 .swiper-button-next::after {
    left: -4% !important;
  }
}
@media (max-width: 2600px) {
  div.gaiaCategoriesContainer .wrapper .swiper.items.landscape.rowCount7 .swiper-button-prev {
    padding-left: 35%;
    left: -35% !important;
  }
}
@media (max-width: 2600px) {
  div.gaiaCategoriesContainer .wrapper .swiper.items.landscape.rowCount7 .swiper-button-prev::after {
    right: -4% !important;
  }
}
@media (max-width: 1768px) {
  div.gaiaCategoriesContainer .wrapper .swiper.items.landscape.rowCount7 .swiper-button-prev::after {
    right: -5% !important;
  }
}
div.gaiaCategoriesContainer .wrapper .swiper.items.landscape.rowCount8 .swiper-button-next, div.gaiaCategoriesContainer .wrapper .swiper.items.landscape.rowCount8 .swiper-button-prev {
  height: 125%;
}
@media (max-width: 1400px) {
  div.gaiaCategoriesContainer .wrapper .swiper.items.landscape.rowCount8 .swiper-button-next, div.gaiaCategoriesContainer .wrapper .swiper.items.landscape.rowCount8 .swiper-button-prev {
    height: 130%;
  }
}
@media (max-width: 1200px) {
  div.gaiaCategoriesContainer .wrapper .swiper.items.landscape.rowCount8 .swiper-button-next, div.gaiaCategoriesContainer .wrapper .swiper.items.landscape.rowCount8 .swiper-button-prev {
    height: 140%;
  }
}
@media (max-width: 1768px) {
  div.gaiaCategoriesContainer .wrapper .swiper.items.landscape.rowCount8 .swiper-button-next::after, div.gaiaCategoriesContainer .wrapper .swiper.items.landscape.rowCount8 .swiper-button-prev::after {
    top: 41% !important;
  }
}
@media (max-width: 1400px) {
  div.gaiaCategoriesContainer .wrapper .swiper.items.landscape.rowCount8 .swiper-button-next::after, div.gaiaCategoriesContainer .wrapper .swiper.items.landscape.rowCount8 .swiper-button-prev::after {
    top: 35% !important;
  }
}
@media (max-width: 1199px) {
  div.gaiaCategoriesContainer .wrapper .swiper.items.landscape.rowCount8 .swiper-button-next::after, div.gaiaCategoriesContainer .wrapper .swiper.items.landscape.rowCount8 .swiper-button-prev::after {
    top: 46% !important;
  }
}
@media (max-width: 2600px) {
  div.gaiaCategoriesContainer .wrapper .swiper.items.landscape.rowCount8 .swiper-button-next {
    padding-right: 35%;
    right: -35% !important;
  }
}
@media (max-width: 2600px) {
  div.gaiaCategoriesContainer .wrapper .swiper.items.landscape.rowCount8 .swiper-button-next::after {
    left: -3.5% !important;
  }
}
@media (max-width: 2600px) {
  div.gaiaCategoriesContainer .wrapper .swiper.items.landscape.rowCount8 .swiper-button-prev {
    padding-left: 35%;
    left: -35% !important;
  }
}
@media (max-width: 2600px) {
  div.gaiaCategoriesContainer .wrapper .swiper.items.landscape.rowCount8 .swiper-button-prev::after {
    right: -4% !important;
  }
}
div.gaiaCategoriesContainer div.header-row {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 30px;
}
div.gaiaCategoriesContainer div.header-row div.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
div.gaiaCategoriesContainer div.header-row div.header h1 {
  font-size: 28px;
  color: var(--text-color);
}
@media (max-width: 768px) {
  div.gaiaCategoriesContainer div.header-row div.header h1 {
    font-size: 22px;
  }
}
div.gaiaCategoriesContainer div.header-row div.view-all {
  font-family: "Avenir-Medium", sans-serif;
  font-size: 12px;
  color: var(--text-color);
  cursor: pointer;
  display: flex;
  align-items: center;
}
@media (max-width: 768px) {
  div.gaiaCategoriesContainer div.header-row div.view-all {
    display: none;
  }
}
div.gaiaCategoriesContainer div.header-row div.view-all .arrow-icon {
  margin-inline-start: 10px;
}
div.gaiaCategoriesContainer div.view-all-mobile {
  display: none;
}
@media (max-width: 768px) {
  div.gaiaCategoriesContainer div.view-all-mobile {
    font-family: "Avenir-Medium", sans-serif;
    color: var(--text-color);
    font-size: 16px;
    margin-top: 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
  }
}

div.gaiaCategoriesContainer.ltr {
  direction: ltr;
  text-align: left;
}
div.gaiaCategoriesContainer.ltr .header-row {
  direction: rtl;
  text-align: right;
}
div.gaiaCategoriesContainer.ltr .header-row .view-all .arrow-icon {
  transform: rotate(180deg);
}
div.gaiaCategoriesContainer.ltr .view-all-mobile {
  direction: rtl;
  text-align: right;
}
@media (max-width: 768px) {
  div.gaiaCategoriesContainer.ltr .view-all-mobile .arrow-icon {
    transform: rotate(180deg);
  }
}

div.gaiaCategoriesContainer.rtl {
  direction: rtl;
  text-align: right;
}
div.gaiaCategoriesContainer.rtl .wrapper .swiper-slide.swiper-slide-active:hover {
  transform: translateX(-15%);
}
@media (max-width: 480px) {
  div.gaiaCategoriesContainer.rtl .wrapper .swiper-slide.swiper-slide-active:hover {
    transform: none;
  }
}
div.gaiaCategoriesContainer.rtl .wrapper .swiper-slide.swiper-slide.translate-last:hover {
  transform: translateX(16%);
}
@media (max-width: 480px) {
  div.gaiaCategoriesContainer.rtl .wrapper .swiper-slide.swiper-slide.translate-last:hover {
    transform: none;
  }
}
div.gaiaCategoriesContainer.rtl .wrapper .swiper-slide.no-transform.swiper-slide-active:hover, div.gaiaCategoriesContainer.rtl .wrapper .swiper-slide.no-transform.translate-last:hover {
  transform: none;
}
div.gaiaCategoriesContainer.rtl .header-row .view-all .arrow-icon {
  transform: rotate(180deg);
}
@media (max-width: 768px) {
  div.gaiaCategoriesContainer.rtl .view-all-mobile .arrow-icon {
    transform: rotate(180deg);
  }
}

div.gaiaShowCardContainer {
  width: 100%;
  cursor: pointer;
  transition: transform 0.5s ease, box-shadow 0.5s ease;
  overflow: visible;
  z-index: 10;
  position: relative;
}
div.gaiaShowCardContainer:hover {
  transform: scale(1.3);
  box-shadow: 0 6px 20px rgba(0, 0, 0, 0.2);
  z-index: 9999999999;
}
@media (max-width: 768px) {
  div.gaiaShowCardContainer:hover {
    transform: scale(1.1);
  }
}
@media (max-width: 480px) {
  div.gaiaShowCardContainer:hover {
    transform: scale(1);
  }
}
div.gaiaShowCardContainer div.imageContainer {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  opacity: 1;
  transition: opacity 0.3s ease;
}
div.gaiaShowCardContainer div.imageContainer.hidden {
  pointer-events: none;
}
div.gaiaShowCardContainer div.imageContainer img {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
}
div.gaiaShowCardContainer div.imageContainer div.premiumStatus div.premium {
  position: absolute;
  top: 6px;
  left: 6px;
  z-index: 8;
  background-color: rgba(8, 25, 43, 0.65);
  width: 35px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 3px;
}
@media all and (max-width: 1200px) {
  div.gaiaShowCardContainer div.imageContainer div.premiumStatus div.premium {
    width: 26px;
  }
}
div.gaiaShowCardContainer div.imageContainer div.premiumStatus div.premium svg {
  width: 18px !important;
  height: 18px !important;
}
@media all and (max-width: 1200px) {
  div.gaiaShowCardContainer div.imageContainer div.premiumStatus div.premium svg {
    width: 12px !important;
    height: 12px !important;
  }
}
div.gaiaShowCardContainer div.imageContainer div.premiumStatus div.free {
  position: absolute;
  top: 6px;
  left: 6px;
  z-index: 8;
  width: 50px;
}
@media all and (max-width: 1200px) {
  div.gaiaShowCardContainer div.imageContainer div.premiumStatus div.free {
    width: 30px;
  }
}
div.gaiaShowCardContainer div.imageContainer div.premiumStatus div.free img {
  display: block;
  width: 100%;
}
div.gaiaShowCardContainer div.imageContainer div.premiumStatus div.lock {
  position: absolute;
  top: 6px;
  left: 6px;
  z-index: 8;
  width: 20px;
}
@media all and (max-width: 1200px) {
  div.gaiaShowCardContainer div.imageContainer div.premiumStatus div.lock {
    width: 15px;
  }
}
div.gaiaShowCardContainer div.imageContainer div.premiumStatus div.lock img {
  display: block;
  width: 100%;
}
div.gaiaShowCardContainer div.imageContainer div.continueWatchingBar {
  width: 100%;
  height: 10px;
  position: absolute;
  bottom: 0;
  left: 0;
  z-index: 1;
  box-shadow: 0 -4px 4px 0 rgba(0, 0, 0, 0.25);
  background-color: #4a4a4a;
}
div.gaiaShowCardContainer div.imageContainer div.continueWatchingBar div.line {
  height: 100%;
  background-color: var(--button-color);
}
div.gaiaShowCardContainer div.hover-content {
  position: absolute;
  cursor: default;
  top: 0;
  left: 0;
  width: 100%;
  height: auto;
  background: #fff;
  color: #000;
  box-sizing: border-box;
  opacity: 0;
  transform: translateY(20px);
  pointer-events: none;
  transition: opacity 0.3s ease, transform 0.3s ease;
  overflow: visible;
  z-index: 9999;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
}
div.gaiaShowCardContainer div.hover-content.visible {
  opacity: 1;
  transform: translateY(0);
  pointer-events: all;
}
div.gaiaShowCardContainer div.hover-content img {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
  cursor: pointer;
}
div.gaiaShowCardContainer div.hover-content div.premiumStatus div.premium {
  position: absolute;
  top: 6px;
  left: 6px;
  z-index: 8;
  background-color: rgba(8, 25, 43, 0.65);
  width: 35px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 3px;
}
@media all and (max-width: 1200px) {
  div.gaiaShowCardContainer div.hover-content div.premiumStatus div.premium {
    width: 26px;
  }
}
div.gaiaShowCardContainer div.hover-content div.premiumStatus div.premium svg {
  width: 18px !important;
  height: 18px !important;
}
@media all and (max-width: 1200px) {
  div.gaiaShowCardContainer div.hover-content div.premiumStatus div.premium svg {
    width: 12px !important;
    height: 12px !important;
  }
}
div.gaiaShowCardContainer div.hover-content div.premiumStatus div.free {
  position: absolute;
  top: 6px;
  left: 6px;
  z-index: 8;
  width: 50px;
}
@media all and (max-width: 1200px) {
  div.gaiaShowCardContainer div.hover-content div.premiumStatus div.free {
    width: 30px;
  }
}
div.gaiaShowCardContainer div.hover-content div.premiumStatus div.free img {
  display: block;
  width: 100%;
}
div.gaiaShowCardContainer div.hover-content div.premiumStatus div.lock {
  position: absolute;
  top: 6px;
  left: 6px;
  z-index: 8;
  width: 20px;
}
@media all and (max-width: 1200px) {
  div.gaiaShowCardContainer div.hover-content div.premiumStatus div.lock {
    width: 15px;
  }
}
div.gaiaShowCardContainer div.hover-content div.premiumStatus div.lock img {
  display: block;
  width: 100%;
}
div.gaiaShowCardContainer div.hover-content div.continueWatchingBar {
  width: 100%;
  height: 10px;
  position: relative;
  margin-top: -10px;
  z-index: 1;
  box-shadow: 0 -4px 4px 0 rgba(0, 0, 0, 0.25);
  background-color: #4a4a4a;
}
div.gaiaShowCardContainer div.hover-content div.continueWatchingBar div.line {
  height: 100%;
  background-color: var(--button-color);
}
div.gaiaShowCardContainer div.hover-content div.extraActions {
  display: flex;
  gap: 10px;
  padding-left: 5px;
}
div.gaiaShowCardContainer div.hover-content div.extraActions button {
  width: 45px;
  height: 45px;
  display: flex;
  align-items: center;
  background: transparent;
  border: none;
  color: #000;
  font-size: 12px;
  cursor: pointer;
}
div.gaiaShowCardContainer div.hover-content div.extraActions button svg {
  margin-right: 5px;
  overflow: visible;
  width: 45px;
  height: 45px;
}
div.gaiaShowCardContainer div.hover-content div.extraActions button:hover {
  color: var(--text-hover);
}
div.gaiaShowCardContainer div.hover-content div.extraActions button.playIconContainer {
  padding-left: 1%;
}
div.gaiaShowCardContainer div.hover-content div.extraActions button.playIconContainer:hover circle {
  transition: fill 0.3s ease, color 0.5s ease;
  fill: var(--button-hover);
}
div.gaiaShowCardContainer div.hover-content div.extraActions button.addToList {
  margin-top: 2px;
  margin-left: auto;
  padding-right: 1%;
  width: 45px;
  height: 45px;
}
div.gaiaShowCardContainer div.hover-content div.extraActions button.addToList circle {
  stroke: #989595;
}
div.gaiaShowCardContainer div.hover-content div.extraActions button.addToList path {
  fill: #989595;
}
div.gaiaShowCardContainer div.hover-content div.extraActions button.addToList:hover circle {
  transition: fill 0.3s ease, color 0.5s ease;
  stroke: var(--button-hover);
}
div.gaiaShowCardContainer div.hover-content div.extraActions button.addToList:hover path {
  transition: fill 0.3s ease, color 0.5s ease;
  fill: var(--button-hover);
}
div.gaiaShowCardContainer div.hover-content h1.title {
  padding-left: 4%;
  font-size: 17px;
  margin-bottom: 2px;
  cursor: pointer;
}
div.gaiaShowCardContainer div.hover-content h1.title:hover {
  color: var(--text-hover);
}
div.gaiaShowCardContainer div.hover-content div.episode {
  display: flex;
  padding-left: 4%;
  margin-bottom: 5px;
  font-size: 11px;
  color: #4a4a4a;
}
div.gaiaShowCardContainer div.hover-content div.episode span {
  text-align: left;
}
div.gaiaShowCardContainer div.hover-content div.episode.hidden {
  display: none;
}
div.gaiaShowCardContainer div.hover-content div.duration {
  padding-left: 4%;
  display: flex;
  margin-bottom: 8px;
  font-size: 10px;
  color: #4a4a4a;
}
div.gaiaShowCardContainer div.hover-content div.duration span.duration {
  text-align: left;
}
div.gaiaShowCardContainer div.hover-content div.duration span.duration.ltr {
  direction: ltr !important;
  unicode-bidi: embed !important;
}
div.gaiaShowCardContainer div.hover-content div.duration span.certificate {
  padding-left: 4%;
  white-space: nowrap;
}

div.gaiaShowCardContainer.rtl .hover-content .extraActions {
  padding-left: 0;
  padding-right: 5px;
}
div.gaiaShowCardContainer.rtl .hover-content .extraActions button svg {
  margin-right: 0;
  margin-left: 5px;
}
div.gaiaShowCardContainer.rtl .hover-content .extraActions .playIconContainer {
  padding-left: 0;
  padding-right: 1%;
}
div.gaiaShowCardContainer.rtl .hover-content .extraActions .addToList {
  padding-left: 1%;
  padding-right: 0;
  margin-left: 0;
  margin-right: auto;
}
div.gaiaShowCardContainer.rtl .hover-content .title {
  padding-left: 0;
  padding-right: 4%;
}
div.gaiaShowCardContainer.rtl .hover-content .episode {
  padding-left: 0;
  padding-right: 4%;
}
div.gaiaShowCardContainer.rtl .hover-content .duration {
  padding-left: 0;
  padding-right: 2%;
}
div.gaiaShowCardContainer.rtl .hover-content .duration .duration {
  text-align: right;
}
div.gaiaShowCardContainer.rtl .hover-content .duration span.certificate {
  padding-left: 0;
  padding-right: 4%;
}

div.gaiaHomepage {
  overflow-x: hidden;
  overflow-y: hidden;
  padding-bottom: 215px;
}
div.gaiaHomepage .swiper {
  overflow: visible;
}
div.gaiaHomepage .swiper-button-disabled {
  display: none !important;
}
div.gaiaHomepage .swiper-button-next {
  right: -82px;
  height: 100%;
  width: 6%;
  top: 5%;
  background: linear-gradient(-97deg, rgb(28, 31, 41) 0.98%, rgba(28, 31, 41, 0.4) 100.23%, rgba(28, 31, 41, 0.2) 100.16%, rgba(28, 31, 41, 0.0001) 52.12%);
}
@media all and (min-width: 320px) {
  div.gaiaHomepage .swiper-button-next {
    display: flex;
    right: -26px;
    width: 9%;
    top: 11%;
  }
}
@media all and (min-width: 360px) {
  div.gaiaHomepage .swiper-button-next {
    top: 9%;
    right: -42px;
  }
}
@media all and (min-width: 480px) {
  div.gaiaHomepage .swiper-button-next {
    top: 7%;
    width: 5%;
    right: -51px;
  }
}
@media all and (min-width: 640px) {
  div.gaiaHomepage .swiper-button-next {
    top: 5%;
    right: -60px;
  }
}
@media all and (min-width: 768px) {
  div.gaiaHomepage .swiper-button-next {
    width: 4%;
    right: -80px;
  }
}
@media all and (min-width: 980px) {
  div.gaiaHomepage .swiper-button-next {
    width: 4%;
    right: -47px;
  }
}
@media all and (min-width: 1200px) {
  div.gaiaHomepage .swiper-button-next {
    right: -57px;
  }
}
@media all and (min-width: 1400px) {
  div.gaiaHomepage .swiper-button-next {
    right: -67px;
  }
}
@media all and (min-width: 1600px) {
  div.gaiaHomepage .swiper-button-next {
    right: -70px;
  }
}
@media all and (min-width: 1768px) {
  div.gaiaHomepage .swiper-button-next {
    right: -79px;
  }
}
div.gaiaHomepage .swiper-button-prev {
  left: -77px;
  height: 100%;
  width: 5%;
  top: 5%;
  background: linear-gradient(-97deg, rgb(28, 31, 41) 0.98%, rgba(28, 31, 41, 0.4) 100.23%, rgba(28, 31, 41, 0.2) 100.16%, rgba(28, 31, 41, 0.0001) 52.12%);
}
@media all and (min-width: 320px) {
  div.gaiaHomepage .swiper-button-prev {
    display: flex;
    left: -26px;
    width: 9%;
    top: 11%;
  }
}
@media all and (min-width: 360px) {
  div.gaiaHomepage .swiper-button-prev {
    top: 9%;
    left: -42px;
  }
}
@media all and (min-width: 480px) {
  div.gaiaHomepage .swiper-button-prev {
    top: 7%;
    width: 5%;
    left: -51px;
  }
}
@media all and (min-width: 640px) {
  div.gaiaHomepage .swiper-button-prev {
    top: 5%;
    left: -60px;
  }
}
@media all and (min-width: 768px) {
  div.gaiaHomepage .swiper-button-prev {
    width: 4%;
    left: -80px;
  }
}
@media all and (min-width: 980px) {
  div.gaiaHomepage .swiper-button-prev {
    width: 4%;
    left: -47px;
  }
}
@media all and (min-width: 1200px) {
  div.gaiaHomepage .swiper-button-prev {
    left: -57px;
  }
}
@media all and (min-width: 1400px) {
  div.gaiaHomepage .swiper-button-prev {
    left: -67px;
  }
}
@media all and (min-width: 1600px) {
  div.gaiaHomepage .swiper-button-prev {
    left: -70px;
  }
}
@media all and (min-width: 1768px) {
  div.gaiaHomepage .swiper-button-prev {
    left: -79px;
  }
}
div.gaiaHomepage .section-slider .swiper-button-next {
  right: -50px;
  width: 10%;
  background-color: white;
}
div.gaiaHomepage .section-slider .swiper-button-prev {
  left: -50px;
  background-color: white;
}

.gaiaSectionSlider {
  max-width: 1980px;
  margin: 0 auto;
}
.gaiaSectionSlider .wrapper:hover .swiper-button-next::after {
  opacity: 1;
}
.gaiaSectionSlider .wrapper:hover .swiper-button-prev::after {
  opacity: 1;
}
@media ((max-width: 2600px) and (min-width: 1981px)) {
  .gaiaSectionSlider .wrapper .swiper .swiper-button-next,
  .gaiaSectionSlider .wrapper .swiper .swiper-button-prev {
    color: white;
    width: 5% !important;
    background: var(--bg-color) !important;
  }
  .gaiaSectionSlider .wrapper .swiper .swiper-button-next .svg,
  .gaiaSectionSlider .wrapper .swiper .swiper-button-prev .svg {
    height: 60px;
    align-items: center;
    color: white;
  }
  .gaiaSectionSlider .wrapper .swiper .swiper-button-next::after,
  .gaiaSectionSlider .wrapper .swiper .swiper-button-prev::after {
    font-size: 18px;
    font-weight: bolder;
    color: var(--text-color);
    position: absolute;
    z-index: 10;
    box-shadow: 0 6px 20px rgba(0, 0, 0, 0.2);
  }
  .gaiaSectionSlider .wrapper .swiper .swiper-button-next::after:hover,
  .gaiaSectionSlider .wrapper .swiper .swiper-button-prev::after:hover {
    background-color: var(--button-hover);
    transform: scale(1.3);
  }
  .gaiaSectionSlider .wrapper .swiper .swiper-button-next {
    inset-inline-end: -5% !important;
  }
  .gaiaSectionSlider .wrapper .swiper .swiper-button-next::after {
    inset-inline-start: -21%;
  }
  .gaiaSectionSlider .wrapper .swiper .swiper-button-prev {
    inset-inline-start: -5% !important;
  }
  .gaiaSectionSlider .wrapper .swiper .swiper-button-prev::after {
    inset-inline-end: -27%;
  }
  .gaiaSectionSlider .wrapper .swiper .swiper-button-disabled {
    opacity: 0;
    pointer-events: none;
  }
}
.gaiaSectionSlider .wrapper .swiper.landscape .swiper-button-next {
  height: 100%;
  inset-inline-end: -4%;
  color: white;
  top: 8%;
  width: 4.5% !important;
  background: linear-gradient(-97deg, rgb(28, 31, 41) 0.98%, rgba(28, 31, 41, 0.4) 100.23%, rgba(28, 31, 41, 0.2) 100.16%, rgba(28, 31, 41, 0.0001) 52.12%);
}
@media ((max-width: 2600px) and (min-width: 1981px)) {
  .gaiaSectionSlider .wrapper .swiper.landscape .swiper-button-next {
    margin-top: -35px;
    height: 110%;
    padding-inline-end: 35%;
    inset-inline-end: -35% !important;
  }
}
@media (max-width: 1980px) {
  .gaiaSectionSlider .wrapper .swiper.landscape .swiper-button-next {
    width: 5% !important;
    inset-inline-end: -5%;
  }
}
@media all and (max-width: 1600px) {
  .gaiaSectionSlider .wrapper .swiper.landscape .swiper-button-next {
    top: 8%;
    inset-inline-end: -5%;
    width: 5% !important;
  }
}
@media all and (max-width: 1200px) {
  .gaiaSectionSlider .wrapper .swiper.landscape .swiper-button-next {
    top: 13%;
    inset-inline-end: -5%;
  }
}
@media all and (max-width: 980px) {
  .gaiaSectionSlider .wrapper .swiper.landscape .swiper-button-next {
    inset-inline-end: -9%;
    width: 9% !important;
    top: 6%;
  }
}
@media all and (max-width: 480px) {
  .gaiaSectionSlider .wrapper .swiper.landscape .swiper-button-next {
    top: 9%;
    width: 10% !important;
  }
}
@media ((max-width: 2600px) and (min-width: 1981px)) {
  .gaiaSectionSlider .wrapper .swiper.landscape .swiper-button-next::after {
    inset-inline-start: -4% !important;
  }
}
.gaiaSectionSlider .wrapper .swiper.landscape .swiper-button-prev {
  inset-inline-end: -4%;
  height: 100%;
  color: white;
  width: 4.5% !important;
  top: 8%;
  background: linear-gradient(-97deg, rgb(28, 31, 41) 0.98%, rgba(28, 31, 41, 0.4) 100.23%, rgba(28, 31, 41, 0.2) 100.16%, rgba(28, 31, 41, 0.0001) 52.12%);
}
.gaiaSectionSlider .wrapper .swiper.landscape .swiper-button-prev .svg {
  height: 20px;
  align-items: center;
  color: white;
}
@media ((max-width: 2600px) and (min-width: 1981px)) {
  .gaiaSectionSlider .wrapper .swiper.landscape .swiper-button-prev {
    margin-top: -35px;
    height: 110%;
    padding-inline-start: 35%;
    inset-inline-start: -35% !important;
  }
}
@media all and (max-width: 1600px) {
  .gaiaSectionSlider .wrapper .swiper.landscape .swiper-button-prev {
    top: 8%;
    width: 4.5% !important;
  }
}
@media all and (max-width: 1200px) {
  .gaiaSectionSlider .wrapper .swiper.landscape .swiper-button-prev {
    top: 13%;
    width: 5% !important;
  }
}
@media all and (max-width: 980px) {
  .gaiaSectionSlider .wrapper .swiper.landscape .swiper-button-prev {
    inset-inline-start: -9%;
    width: 9% !important;
    top: 6%;
  }
}
@media all and (max-width: 480px) {
  .gaiaSectionSlider .wrapper .swiper.landscape .swiper-button-prev {
    top: 9%;
    width: 10% !important;
  }
}
@media ((max-width: 2600px) and (min-width: 1981px)) {
  .gaiaSectionSlider .wrapper .swiper.landscape .swiper-button-prev::after {
    inset-inline-end: -5% !important;
  }
}
.gaiaSectionSlider .wrapper .swiper.portrait .swiper-button-next {
  margin-top: -4% !important;
  inset-inline-end: -5%;
  height: 120%;
  width: 4% !important;
  background: linear-gradient(-97deg, rgb(28, 31, 41) 0.98%, rgba(28, 31, 41, 0.4) 100.23%, rgba(28, 31, 41, 0.2) 100.16%, rgba(28, 31, 41, 0.0001) 52.12%);
}
@media ((max-width: 2600px) and (min-width: 1981px)) {
  .gaiaSectionSlider .wrapper .swiper.portrait .swiper-button-next {
    padding-inline-end: 35%;
    inset-inline-end: -35% !important;
    margin-top: -35px;
    height: 130% !important;
  }
}
@media (min-width: 1981px) and (max-width: 2600px) {
  .gaiaSectionSlider .wrapper .swiper.portrait .swiper-button-next::after {
    inset-inline-start: -4% !important;
  }
}
@media (max-width: 1980px) {
  .gaiaSectionSlider .wrapper .swiper.portrait .swiper-button-next {
    width: 5% !important;
  }
}
@media all and (max-width: 1768px) {
  .gaiaSectionSlider .wrapper .swiper.portrait .swiper-button-next {
    top: 6% !important;
  }
}
@media all and (max-width: 1600px) {
  .gaiaSectionSlider .wrapper .swiper.portrait .swiper-button-next {
    top: 7% !important;
  }
}
@media all and (max-width: 1400px) {
  .gaiaSectionSlider .wrapper .swiper.portrait .swiper-button-next {
    top: 10% !important;
  }
}
@media all and (max-width: 1200px) {
  .gaiaSectionSlider .wrapper .swiper.portrait .swiper-button-next {
    width: 5% !important;
    top: 11% !important;
    height: 100%;
  }
}
@media all and (max-width: 980px) {
  .gaiaSectionSlider .wrapper .swiper.portrait .swiper-button-next {
    display: flex;
    width: 9% !important;
    inset-inline-end: -9% !important;
    top: 10% !important;
    height: 110%;
  }
}
@media all and (max-width: 640px) {
  .gaiaSectionSlider .wrapper .swiper.portrait .swiper-button-next {
    width: 9% !important;
    inset-inline-end: -9% !important;
  }
}
@media all and (max-width: 480px) {
  .gaiaSectionSlider .wrapper .swiper.portrait .swiper-button-next {
    width: 8% !important;
    top: 4% !important;
  }
}
@media all and (max-width: 320px) {
  .gaiaSectionSlider .wrapper .swiper.portrait .swiper-button-next {
    width: 9% !important;
  }
}
.gaiaSectionSlider .wrapper .swiper.portrait .swiper-button-prev {
  margin-top: -4% !important;
  inset-inline-start: -5%;
  height: 120%;
  width: 4% !important;
  background: linear-gradient(-97deg, rgb(28, 31, 41) 0.98%, rgba(28, 31, 41, 0.4) 100.23%, rgba(28, 31, 41, 0.2) 100.16%, rgba(28, 31, 41, 0.0001) 52.12%);
}
@media ((max-width: 2600px) and (min-width: 1981px)) {
  .gaiaSectionSlider .wrapper .swiper.portrait .swiper-button-prev {
    margin-top: -35px;
    padding-inline-start: 35%;
    inset-inline-start: -35% !important;
    height: 130% !important;
  }
}
@media (max-width: 1980px) {
  .gaiaSectionSlider .wrapper .swiper.portrait .swiper-button-prev {
    width: 5% !important;
  }
}
@media all and (max-width: 1768px) {
  .gaiaSectionSlider .wrapper .swiper.portrait .swiper-button-prev {
    top: 6% !important;
  }
}
@media all and (max-width: 1600px) {
  .gaiaSectionSlider .wrapper .swiper.portrait .swiper-button-prev {
    top: 8% !important;
  }
}
@media all and (max-width: 1400px) {
  .gaiaSectionSlider .wrapper .swiper.portrait .swiper-button-prev {
    top: 10% !important;
  }
}
@media all and (max-width: 1200px) {
  .gaiaSectionSlider .wrapper .swiper.portrait .swiper-button-prev {
    width: 5% !important;
    top: 11% !important;
    height: 100%;
  }
}
@media all and (max-width: 980px) {
  .gaiaSectionSlider .wrapper .swiper.portrait .swiper-button-prev {
    display: flex;
    width: 9% !important;
    inset-inline-start: -9%;
    top: 10% !important;
    height: 110%;
  }
}
@media all and (max-width: 640px) {
  .gaiaSectionSlider .wrapper .swiper.portrait .swiper-button-prev {
    width: 9% !important;
    inset-inline-start: -9% !important;
  }
}
@media all and (max-width: 480px) {
  .gaiaSectionSlider .wrapper .swiper.portrait .swiper-button-prev {
    width: 8% !important;
    top: 4% !important;
  }
}
@media all and (max-width: 320px) {
  .gaiaSectionSlider .wrapper .swiper.portrait .swiper-button-prev {
    width: 9% !important;
  }
}
@media ((max-width: 2600px) and (min-width: 1981px)) {
  .gaiaSectionSlider .wrapper .swiper.portrait .swiper-button-prev::after {
    inset-inline-end: -4% !important;
  }
}
.gaiaSectionSlider .wrapper .heading-container {
  display: inline;
  align-items: center;
  cursor: pointer;
}
.gaiaSectionSlider .wrapper .heading-container.hover {
  color: var(--text-hover);
}
.gaiaSectionSlider .wrapper .heading-container h4.topHeading {
  font-size: 24px;
  margin-inline-end: 10px;
  color: var(--text-color);
  transition: 0.3s ease-in-out;
  display: inline-block;
  margin-bottom: 20px;
}
.gaiaSectionSlider .wrapper .heading-container h4.topHeading:hover {
  color: var(--text-hover);
}
.gaiaSectionSlider .wrapper .heading-container h4.topHeading span {
  font-size: 24px;
  display: inline-block;
  color: var(--text-color);
  height: 20px !important;
  width: 20px !important;
  transition: color 0.3s ease-in-out;
  vertical-align: sub;
  margin-inline-start: 10px;
}
.gaiaSectionSlider .wrapper .heading-container h4.topHeading span:hover, .gaiaSectionSlider .wrapper .heading-container h4.topHeading span:focus {
  color: var(--text-hover);
}
.gaiaSectionSlider .wrapper .heading-container h4.topHeading:hover, .gaiaSectionSlider .wrapper .heading-container h4.topHeading:focus {
  color: var(--text-hover);
}
.gaiaSectionSlider .wrapper .heading-container .show-all {
  color: var(--text-hover);
  font-size: medium;
}
.gaiaSectionSlider .wrapper .heading-container .show-all:hover {
  color: var(--text-color);
}
.gaiaSectionSlider .wrapper .text-decoration-none:hover {
  color: var(--text-hover);
}
.gaiaSectionSlider .wrapper .text-decoration-none svg:hover {
  color: var(--text-hover);
}

.moreTopics-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding-left: 4.5%;
  padding-right: 4.5%;
  padding-bottom: 20px;
  padding-top: 20px;
  z-index: 10;
  max-width: 1980px;
  margin: 0 auto;
  color: var(--text-color);
}
.moreTopics-container .header {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 40px;
}
.moreTopics-container .header .title {
  font-size: 32px;
  transition: margin-inline-start 0.3s;
}
.moreTopics-container .header .view-all {
  font-size: 12px;
  text-decoration: none;
  color: var(--text-color);
  cursor: pointer;
  display: flex;
  align-items: center;
}
.moreTopics-container .header .view-all.gaia {
  font-family: "Avenir-Medium", sans-serif;
}
.moreTopics-container .header .view-all.template_9 {
  font-family: "Gilroy-Medium";
}
@media (max-width: 768px) {
  .moreTopics-container .header .view-all {
    display: none;
  }
}
.moreTopics-container .header .view-all:hover {
  text-decoration: underline;
}
.moreTopics-container .header .view-all .arrow-icon {
  margin-inline-start: 10px;
}
.moreTopics-container .topicsList {
  width: 100%;
  list-style: none;
  display: flex;
  white-space: nowrap;
  margin-bottom: 40px;
}
.moreTopics-container .topicsList.rtl {
  direction: rtl;
  text-align: right;
}
.moreTopics-container .topicsList.ltr {
  direction: ltr;
  text-align: left;
}
.moreTopics-container .topicsList .swiper-slide {
  width: auto;
}
.moreTopics-container .topicsList .swiper-slide span {
  font-size: 16px;
  color: var(--text-color);
  background: none;
  border: none;
  cursor: pointer;
  transition: color 0.3s, -webkit-text-decoration 0.3s;
  transition: color 0.3s, text-decoration 0.3s;
  transition: color 0.3s, text-decoration 0.3s, -webkit-text-decoration 0.3s;
}
.moreTopics-container .topicsList .swiper-slide span:hover {
  color: var(--grey-color);
}
.moreTopics-container .topicsList .swiper-slide span.active {
  font-weight: bold;
  text-decoration: underline;
  text-decoration-color: var(--button-color);
  text-underline-offset: 6px;
  text-decoration-thickness: 4px;
}
.moreTopics-container .topicsList .swiper-slide span.active.gaia {
  font-family: "Avenir-Black", sans-serif;
}
.moreTopics-container .topicsList .swiper-button-next,
.moreTopics-container .topicsList .swiper-button-prev {
  height: 300%;
  color: white;
  width: 6% !important;
  background: var(--bg-color);
}
@media (max-width: 2600px) {
  .moreTopics-container .topicsList .swiper-button-next,
  .moreTopics-container .topicsList .swiper-button-prev {
    width: 32% !important;
  }
}
.moreTopics-container .topicsList .swiper-button-next .svg,
.moreTopics-container .topicsList .swiper-button-prev .svg {
  height: 60px;
  align-items: center;
  color: white;
}
.moreTopics-container .topicsList .swiper-button-next::after,
.moreTopics-container .topicsList .swiper-button-prev::after {
  font-size: 18px;
  font-weight: bolder;
  color: var(--text-color);
  position: absolute;
  z-index: 10;
  box-shadow: 0 6px 20px rgba(0, 0, 0, 0.2);
  top: 13%;
}
@media (max-width: 1199px) {
  .moreTopics-container .topicsList .swiper-button-next::after,
  .moreTopics-container .topicsList .swiper-button-prev::after {
    top: 30%;
  }
}
.moreTopics-container .topicsList .swiper-button-next::after:hover,
.moreTopics-container .topicsList .swiper-button-prev::after:hover {
  background-color: var(--button-hover);
  transform: scale(1.3);
}
.moreTopics-container .topicsList .swiper-button-next {
  right: -3.5%;
  margin-right: -2%;
}
@media (max-width: 2600px) {
  .moreTopics-container .topicsList .swiper-button-next {
    margin-right: -24.5%;
  }
}
@media (max-width: 2600px) {
  .moreTopics-container .topicsList .swiper-button-next::after {
    margin-right: 80% !important;
  }
}
.moreTopics-container .topicsList .swiper-button-prev {
  left: -4%;
  margin-left: -2%;
}
@media (max-width: 2600px) {
  .moreTopics-container .topicsList .swiper-button-prev {
    margin-left: -24.5%;
  }
}
.moreTopics-container .topicsList .swiper-button-prev::after {
  margin-left: 88%;
}
.moreTopics-container .mainCards {
  width: 100%;
}
.moreTopics-container .mainCards.hidden {
  display: none;
}
.moreTopics-container .mainCards .topRow {
  display: flex;
  margin-bottom: 20px;
}
.moreTopics-container .mainCards .topRow .topCard {
  flex: 1;
  margin-inline-end: 15px;
}
.moreTopics-container .mainCards .topRow .topCard.landscape {
  max-width: 25%;
}
.moreTopics-container .mainCards .topRow .topCard.portrait {
  max-width: 20%;
}
.moreTopics-container .mainCards .topRow .topCard:last-child {
  margin-inline-end: 0;
}
.moreTopics-container .mainCards .topRow .topCard:hover {
  overflow: visible;
  z-index: 99999;
  position: relative;
}
.moreTopics-container .mainCards .swiper {
  width: 100%;
}
.moreTopics-container .mainCards .swiper:hover {
  z-index: 999999;
}
.moreTopics-container .mainCards .swiper .swiper-slide {
  display: flex;
  justify-content: center;
  align-items: center;
}
.moreTopics-container .mainCards .swiper .swiper-slide:hover {
  z-index: 99999;
}
.moreTopics-container .mainCards .swiper .swiper-button-next,
.moreTopics-container .mainCards .swiper .swiper-button-prev {
  height: 112%;
  color: white;
  width: 5% !important;
  background: var(--bg-color);
}
@media (width: 980px) {
  .moreTopics-container .mainCards .swiper .swiper-button-next,
  .moreTopics-container .mainCards .swiper .swiper-button-prev {
    height: 115%;
  }
}
@media (max-width: 480px) {
  .moreTopics-container .mainCards .swiper .swiper-button-next,
  .moreTopics-container .mainCards .swiper .swiper-button-prev {
    height: 110%;
    margin-top: -6%;
  }
}
@media (max-width: 360px) {
  .moreTopics-container .mainCards .swiper .swiper-button-next,
  .moreTopics-container .mainCards .swiper .swiper-button-prev {
    height: 135%;
    margin-top: -15%;
  }
}
.moreTopics-container .mainCards .swiper .swiper-button-next .svg,
.moreTopics-container .mainCards .swiper .swiper-button-prev .svg {
  height: 60px;
  align-items: center;
  color: white;
}
.moreTopics-container .mainCards .swiper .swiper-button-next::after,
.moreTopics-container .mainCards .swiper .swiper-button-prev::after {
  font-size: 18px;
  font-weight: bolder;
  color: var(--text-color);
  position: absolute;
  z-index: 10;
  box-shadow: 0 6px 20px rgba(0, 0, 0, 0.2);
}
.moreTopics-container .mainCards .swiper .swiper-button-next::after:hover,
.moreTopics-container .mainCards .swiper .swiper-button-prev::after:hover {
  background-color: var(--button-hover);
  transform: scale(1.3);
}
.moreTopics-container .mainCards .swiper .swiper-button-next {
  right: -5%;
}
.moreTopics-container .mainCards .swiper .swiper-button-next::after {
  left: -21%;
}
@media (max-width: 480px) {
  .moreTopics-container .mainCards .swiper .swiper-button-next::after {
    left: -55%;
  }
}
@media (max-width: 360px) {
  .moreTopics-container .mainCards .swiper .swiper-button-next::after {
    left: -105%;
  }
}
.moreTopics-container .mainCards .swiper .swiper-button-prev {
  left: -5%;
}
.moreTopics-container .mainCards .swiper .swiper-button-prev::after {
  right: -27%;
}
@media (max-width: 480px) {
  .moreTopics-container .mainCards .swiper .swiper-button-prev::after {
    right: -60%;
  }
}
@media (max-width: 360px) {
  .moreTopics-container .mainCards .swiper .swiper-button-prev::after {
    right: -100%;
  }
}
.moreTopics-container .mainCards .swiper .swiper-button-disabled {
  opacity: 0;
  pointer-events: none;
}
.moreTopics-container .mainCards .swiper.portrait .swiper-button-next, .moreTopics-container .mainCards .swiper.portrait .swiper-button-prev {
  margin-top: -35px;
  height: 110%;
}
@media (max-width: 1400px) {
  .moreTopics-container .mainCards .swiper.portrait .swiper-button-next, .moreTopics-container .mainCards .swiper.portrait .swiper-button-prev {
    height: 112%;
  }
}
@media (max-width: 1200px) {
  .moreTopics-container .mainCards .swiper.portrait .swiper-button-next, .moreTopics-container .mainCards .swiper.portrait .swiper-button-prev {
    height: 115%;
  }
}
@media (max-width: 1199px) {
  .moreTopics-container .mainCards .swiper.portrait .swiper-button-next, .moreTopics-container .mainCards .swiper.portrait .swiper-button-prev {
    height: 120%;
  }
}
@media (max-width: 980px) {
  .moreTopics-container .mainCards .swiper.portrait .swiper-button-next, .moreTopics-container .mainCards .swiper.portrait .swiper-button-prev {
    height: 115%;
  }
}
@media (max-width: 979px) {
  .moreTopics-container .mainCards .swiper.portrait .swiper-button-next, .moreTopics-container .mainCards .swiper.portrait .swiper-button-prev {
    height: 112%;
  }
}
@media (max-width: 767px) {
  .moreTopics-container .mainCards .swiper.portrait .swiper-button-next, .moreTopics-container .mainCards .swiper.portrait .swiper-button-prev {
    height: 108%;
  }
}
@media (max-width: 479px) {
  .moreTopics-container .mainCards .swiper.portrait .swiper-button-next, .moreTopics-container .mainCards .swiper.portrait .swiper-button-prev {
    margin-top: -60px;
    height: 105%;
  }
}
@media (max-width: 1768px) {
  .moreTopics-container .mainCards .swiper.portrait .swiper-button-next::after, .moreTopics-container .mainCards .swiper.portrait .swiper-button-prev::after {
    top: 42% !important;
  }
}
@media (max-width: 1400px) {
  .moreTopics-container .mainCards .swiper.portrait .swiper-button-next::after, .moreTopics-container .mainCards .swiper.portrait .swiper-button-prev::after {
    top: 36% !important;
  }
}
@media (max-width: 980px) {
  .moreTopics-container .mainCards .swiper.portrait .swiper-button-next::after, .moreTopics-container .mainCards .swiper.portrait .swiper-button-prev::after {
    top: 47% !important;
  }
}
@media (max-width: 479px) {
  .moreTopics-container .mainCards .swiper.portrait .swiper-button-next::after, .moreTopics-container .mainCards .swiper.portrait .swiper-button-prev::after {
    top: 47% !important;
  }
}
@media (max-width: 360px) {
  .moreTopics-container .mainCards .swiper.portrait .swiper-button-next::after, .moreTopics-container .mainCards .swiper.portrait .swiper-button-prev::after {
    top: 46% !important;
  }
}
@media (max-width: 2600px) {
  .moreTopics-container .mainCards .swiper.portrait .swiper-button-next {
    margin-right: -20%;
    width: 25% !important;
  }
}
@media (max-width: 2600px) {
  .moreTopics-container .mainCards .swiper.portrait .swiper-button-next::after {
    left: -4% !important;
  }
}
@media (max-width: 1400px) {
  .moreTopics-container .mainCards .swiper.portrait .swiper-button-next::after {
    left: -7% !important;
  }
}
@media (max-width: 1199px) {
  .moreTopics-container .mainCards .swiper.portrait .swiper-button-next::after {
    left: -4% !important;
  }
}
@media (max-width: 980px) {
  .moreTopics-container .mainCards .swiper.portrait .swiper-button-next::after {
    left: -4% !important;
  }
}
@media (max-width: 768px) {
  .moreTopics-container .mainCards .swiper.portrait .swiper-button-next::after {
    left: -5% !important;
  }
}
@media (max-width: 640px) {
  .moreTopics-container .mainCards .swiper.portrait .swiper-button-next::after {
    left: -7% !important;
  }
}
@media (max-width: 480px) {
  .moreTopics-container .mainCards .swiper.portrait .swiper-button-next::after {
    left: -8% !important;
  }
}
@media (max-width: 360px) {
  .moreTopics-container .mainCards .swiper.portrait .swiper-button-next::after {
    left: -10% !important;
  }
}
@media (max-width: 2600px) {
  .moreTopics-container .mainCards .swiper.portrait .swiper-button-prev {
    padding-left: 35%;
    left: -35% !important;
  }
}
@media (max-width: 2600px) {
  .moreTopics-container .mainCards .swiper.portrait .swiper-button-prev::after {
    right: -4% !important;
  }
}
@media (max-width: 1400px) {
  .moreTopics-container .mainCards .swiper.portrait .swiper-button-prev::after {
    right: -5% !important;
  }
}
@media (max-width: 1199px) {
  .moreTopics-container .mainCards .swiper.portrait .swiper-button-prev::after {
    right: -3% !important;
  }
}
@media (max-width: 980px) {
  .moreTopics-container .mainCards .swiper.portrait .swiper-button-prev::after {
    right: -5% !important;
  }
}
@media (max-width: 979px) {
  .moreTopics-container .mainCards .swiper.portrait .swiper-button-prev::after {
    right: -4% !important;
  }
}
@media (max-width: 768px) {
  .moreTopics-container .mainCards .swiper.portrait .swiper-button-prev::after {
    right: -6% !important;
  }
}
@media (max-width: 640px) {
  .moreTopics-container .mainCards .swiper.portrait .swiper-button-prev::after {
    right: -6% !important;
  }
}
@media (max-width: 480px) {
  .moreTopics-container .mainCards .swiper.portrait .swiper-button-prev::after {
    right: -9% !important;
  }
}
@media (max-width: 360px) {
  .moreTopics-container .mainCards .swiper.portrait .swiper-button-prev::after {
    right: -12% !important;
  }
}
.moreTopics-container .mainCards .swiper.landscape .swiper-button-next, .moreTopics-container .mainCards .swiper.landscape .swiper-button-prev {
  height: 120%;
}
@media (max-width: 1199px) {
  .moreTopics-container .mainCards .swiper.landscape .swiper-button-next, .moreTopics-container .mainCards .swiper.landscape .swiper-button-prev {
    height: 125%;
  }
}
@media (max-width: 479px) {
  .moreTopics-container .mainCards .swiper.landscape .swiper-button-next, .moreTopics-container .mainCards .swiper.landscape .swiper-button-prev {
    margin-top: -60px;
  }
}
@media (max-width: 360px) {
  .moreTopics-container .mainCards .swiper.landscape .swiper-button-next, .moreTopics-container .mainCards .swiper.landscape .swiper-button-prev {
    height: 130%;
  }
}
.moreTopics-container .mainCards .swiper.landscape .swiper-button-next::after, .moreTopics-container .mainCards .swiper.landscape .swiper-button-prev::after {
  top: 43%;
}
@media (max-width: 2600px) {
  .moreTopics-container .mainCards .swiper.landscape .swiper-button-next::after, .moreTopics-container .mainCards .swiper.landscape .swiper-button-prev::after {
    top: 30%;
  }
}
@media (max-width: 1980px) {
  .moreTopics-container .mainCards .swiper.landscape .swiper-button-next::after, .moreTopics-container .mainCards .swiper.landscape .swiper-button-prev::after {
    top: 30%;
  }
}
@media (max-width: 1400px) {
  .moreTopics-container .mainCards .swiper.landscape .swiper-button-next::after, .moreTopics-container .mainCards .swiper.landscape .swiper-button-prev::after {
    top: 26%;
  }
}
@media (max-width: 1200px) {
  .moreTopics-container .mainCards .swiper.landscape .swiper-button-next::after, .moreTopics-container .mainCards .swiper.landscape .swiper-button-prev::after {
    top: 25%;
  }
}
@media (max-width: 1199px) {
  .moreTopics-container .mainCards .swiper.landscape .swiper-button-next::after, .moreTopics-container .mainCards .swiper.landscape .swiper-button-prev::after {
    top: 27%;
  }
}
@media (max-width: 980px) {
  .moreTopics-container .mainCards .swiper.landscape .swiper-button-next::after, .moreTopics-container .mainCards .swiper.landscape .swiper-button-prev::after {
    top: 34% !important;
  }
}
@media (max-width: 768px) {
  .moreTopics-container .mainCards .swiper.landscape .swiper-button-next::after, .moreTopics-container .mainCards .swiper.landscape .swiper-button-prev::after {
    top: 35% !important;
  }
}
@media (max-width: 479px) {
  .moreTopics-container .mainCards .swiper.landscape .swiper-button-next::after, .moreTopics-container .mainCards .swiper.landscape .swiper-button-prev::after {
    top: 43% !important;
  }
}
@media (max-width: 360px) {
  .moreTopics-container .mainCards .swiper.landscape .swiper-button-next::after, .moreTopics-container .mainCards .swiper.landscape .swiper-button-prev::after {
    top: 44% !important;
  }
}
@media (max-width: 2600px) {
  .moreTopics-container .mainCards .swiper.landscape .swiper-button-next {
    padding-right: 35%;
    right: -35% !important;
  }
}
@media (max-width: 2600px) {
  .moreTopics-container .mainCards .swiper.landscape .swiper-button-next::after {
    left: -4% !important;
  }
}
@media (max-width: 1200px) {
  .moreTopics-container .mainCards .swiper.landscape .swiper-button-next::after {
    left: -6% !important;
  }
}
@media (max-width: 1199px) {
  .moreTopics-container .mainCards .swiper.landscape .swiper-button-next::after {
    left: -3% !important;
  }
}
@media (max-width: 980px) {
  .moreTopics-container .mainCards .swiper.landscape .swiper-button-next::after {
    left: -4% !important;
  }
}
@media (max-width: 768px) {
  .moreTopics-container .mainCards .swiper.landscape .swiper-button-next::after {
    left: -5% !important;
  }
}
@media (max-width: 640px) {
  .moreTopics-container .mainCards .swiper.landscape .swiper-button-next::after {
    left: -7% !important;
  }
}
@media (max-width: 479px) {
  .moreTopics-container .mainCards .swiper.landscape .swiper-button-next::after {
    left: -8% !important;
  }
}
@media (max-width: 360px) {
  .moreTopics-container .mainCards .swiper.landscape .swiper-button-next::after {
    left: -11% !important;
  }
}
@media (max-width: 2600px) {
  .moreTopics-container .mainCards .swiper.landscape .swiper-button-prev {
    padding-left: 35%;
    left: -35% !important;
  }
}
@media (max-width: 2600px) {
  .moreTopics-container .mainCards .swiper.landscape .swiper-button-prev::after {
    right: -4% !important;
  }
}
@media (max-width: 1200px) {
  .moreTopics-container .mainCards .swiper.landscape .swiper-button-prev::after {
    right: -6% !important;
  }
}
@media (max-width: 1199px) {
  .moreTopics-container .mainCards .swiper.landscape .swiper-button-prev::after {
    right: -3% !important;
  }
}
@media (max-width: 980px) {
  .moreTopics-container .mainCards .swiper.landscape .swiper-button-prev::after {
    right: -5% !important;
  }
}
@media (max-width: 768px) {
  .moreTopics-container .mainCards .swiper.landscape .swiper-button-prev::after {
    right: -5% !important;
  }
}
@media (max-width: 640px) {
  .moreTopics-container .mainCards .swiper.landscape .swiper-button-prev::after {
    right: -8% !important;
  }
}
@media (max-width: 479px) {
  .moreTopics-container .mainCards .swiper.landscape .swiper-button-prev::after {
    right: -9% !important;
  }
}
@media (max-width: 360px) {
  .moreTopics-container .mainCards .swiper.landscape .swiper-button-prev::after {
    right: -13% !important;
  }
}
.moreTopics-container .mainCards .template-10 {
  width: 100%;
}
.moreTopics-container .mainCards .template-10:hover {
  z-index: 999999;
}
.moreTopics-container .mainCards .template-10 .swiper-slide {
  display: flex;
  justify-content: center;
  align-items: center;
}
.moreTopics-container .mainCards .template-10 .swiper-slide.swiper-slide-active {
  transition: transform 0.3s ease;
}
.moreTopics-container .mainCards .template-10 .swiper-slide.swiper-slide-active:hover {
  transform: translateX(15%);
}
.moreTopics-container .mainCards .template-10 .swiper-slide.swiper-slide.translate-last {
  transition: transform 0.3s ease;
}
.moreTopics-container .mainCards .template-10 .swiper-slide.swiper-slide.translate-last:hover {
  transform: translateX(-16%);
}
.moreTopics-container .mainCards .template-10 .swiper-slide:hover {
  z-index: 99999;
}
.moreTopics-container .mainCards .template-10 .swiper-button-next,
.moreTopics-container .mainCards .template-10 .swiper-button-prev {
  height: 112%;
  color: white;
  width: 5% !important;
  background: var(--bg-color);
}
@media (width: 980px) {
  .moreTopics-container .mainCards .template-10 .swiper-button-next,
  .moreTopics-container .mainCards .template-10 .swiper-button-prev {
    height: 115%;
  }
}
@media (max-width: 480px) {
  .moreTopics-container .mainCards .template-10 .swiper-button-next,
  .moreTopics-container .mainCards .template-10 .swiper-button-prev {
    height: 110%;
    margin-top: -6%;
  }
}
@media (max-width: 360px) {
  .moreTopics-container .mainCards .template-10 .swiper-button-next,
  .moreTopics-container .mainCards .template-10 .swiper-button-prev {
    height: 135%;
    margin-top: -15%;
  }
}
.moreTopics-container .mainCards .template-10 .swiper-button-next .svg,
.moreTopics-container .mainCards .template-10 .swiper-button-prev .svg {
  height: 60px;
  align-items: center;
  color: white;
}
.moreTopics-container .mainCards .template-10 .swiper-button-next::after,
.moreTopics-container .mainCards .template-10 .swiper-button-prev::after {
  font-size: 18px;
  font-weight: bolder;
  color: var(--text-color);
  position: absolute;
  z-index: 10;
  box-shadow: 0 6px 20px rgba(0, 0, 0, 0.2);
}
.moreTopics-container .mainCards .template-10 .swiper-button-next::after:hover,
.moreTopics-container .mainCards .template-10 .swiper-button-prev::after:hover {
  background-color: var(--button-hover);
  transform: scale(1.3);
}
.moreTopics-container .mainCards .template-10 .swiper-button-next {
  right: -5%;
}
.moreTopics-container .mainCards .template-10 .swiper-button-next::after {
  left: -21%;
}
@media (max-width: 480px) {
  .moreTopics-container .mainCards .template-10 .swiper-button-next::after {
    left: -55%;
  }
}
@media (max-width: 360px) {
  .moreTopics-container .mainCards .template-10 .swiper-button-next::after {
    left: -105%;
  }
}
.moreTopics-container .mainCards .template-10 .swiper-button-prev {
  left: -5%;
}
.moreTopics-container .mainCards .template-10 .swiper-button-prev::after {
  right: -27%;
}
@media (max-width: 480px) {
  .moreTopics-container .mainCards .template-10 .swiper-button-prev::after {
    right: -60%;
  }
}
@media (max-width: 360px) {
  .moreTopics-container .mainCards .template-10 .swiper-button-prev::after {
    right: -100%;
  }
}
.moreTopics-container .mainCards .template-10 .swiper-button-disabled {
  opacity: 0;
  pointer-events: none;
}
.moreTopics-container .mainCards .template-10.portrait .swiper-button-next, .moreTopics-container .mainCards .template-10.portrait .swiper-button-prev {
  margin-top: -35px;
  height: 110%;
}
@media (max-width: 1400px) {
  .moreTopics-container .mainCards .template-10.portrait .swiper-button-next, .moreTopics-container .mainCards .template-10.portrait .swiper-button-prev {
    height: 112%;
  }
}
@media (max-width: 1200px) {
  .moreTopics-container .mainCards .template-10.portrait .swiper-button-next, .moreTopics-container .mainCards .template-10.portrait .swiper-button-prev {
    height: 115%;
  }
}
@media (max-width: 1199px) {
  .moreTopics-container .mainCards .template-10.portrait .swiper-button-next, .moreTopics-container .mainCards .template-10.portrait .swiper-button-prev {
    height: 120%;
  }
}
@media (max-width: 980px) {
  .moreTopics-container .mainCards .template-10.portrait .swiper-button-next, .moreTopics-container .mainCards .template-10.portrait .swiper-button-prev {
    height: 115%;
  }
}
@media (max-width: 979px) {
  .moreTopics-container .mainCards .template-10.portrait .swiper-button-next, .moreTopics-container .mainCards .template-10.portrait .swiper-button-prev {
    height: 112%;
  }
}
@media (max-width: 767px) {
  .moreTopics-container .mainCards .template-10.portrait .swiper-button-next, .moreTopics-container .mainCards .template-10.portrait .swiper-button-prev {
    height: 108%;
  }
}
@media (max-width: 479px) {
  .moreTopics-container .mainCards .template-10.portrait .swiper-button-next, .moreTopics-container .mainCards .template-10.portrait .swiper-button-prev {
    margin-top: -60px;
    height: 105%;
  }
}
@media (max-width: 1768px) {
  .moreTopics-container .mainCards .template-10.portrait .swiper-button-next::after, .moreTopics-container .mainCards .template-10.portrait .swiper-button-prev::after {
    top: 46% !important;
  }
}
@media (max-width: 1400px) {
  .moreTopics-container .mainCards .template-10.portrait .swiper-button-next::after, .moreTopics-container .mainCards .template-10.portrait .swiper-button-prev::after {
    top: 47% !important;
  }
}
@media (max-width: 480px) {
  .moreTopics-container .mainCards .template-10.portrait .swiper-button-next::after, .moreTopics-container .mainCards .template-10.portrait .swiper-button-prev::after {
    top: 45% !important;
  }
}
@media (max-width: 360px) {
  .moreTopics-container .mainCards .template-10.portrait .swiper-button-next::after, .moreTopics-container .mainCards .template-10.portrait .swiper-button-prev::after {
    top: 45% !important;
  }
}
@media (max-width: 2600px) {
  .moreTopics-container .mainCards .template-10.portrait .swiper-button-next {
    margin-right: -20%;
    width: 25% !important;
  }
}
@media (max-width: 2600px) {
  .moreTopics-container .mainCards .template-10.portrait .swiper-button-next::after {
    left: -4% !important;
  }
}
@media (max-width: 1400px) {
  .moreTopics-container .mainCards .template-10.portrait .swiper-button-next::after {
    left: -7% !important;
  }
}
@media (max-width: 1199px) {
  .moreTopics-container .mainCards .template-10.portrait .swiper-button-next::after {
    left: -4% !important;
  }
}
@media (max-width: 980px) {
  .moreTopics-container .mainCards .template-10.portrait .swiper-button-next::after {
    left: -4% !important;
  }
}
@media (max-width: 768px) {
  .moreTopics-container .mainCards .template-10.portrait .swiper-button-next::after {
    left: -5% !important;
  }
}
@media (max-width: 640px) {
  .moreTopics-container .mainCards .template-10.portrait .swiper-button-next::after {
    left: -7% !important;
  }
}
@media (max-width: 480px) {
  .moreTopics-container .mainCards .template-10.portrait .swiper-button-next::after {
    left: -8% !important;
  }
}
@media (max-width: 360px) {
  .moreTopics-container .mainCards .template-10.portrait .swiper-button-next::after {
    left: -10% !important;
  }
}
@media (max-width: 2600px) {
  .moreTopics-container .mainCards .template-10.portrait .swiper-button-prev {
    padding-left: 35%;
    left: -35% !important;
  }
}
@media (max-width: 2600px) {
  .moreTopics-container .mainCards .template-10.portrait .swiper-button-prev::after {
    right: -4% !important;
  }
}
@media (max-width: 1400px) {
  .moreTopics-container .mainCards .template-10.portrait .swiper-button-prev::after {
    right: -5% !important;
  }
}
@media (max-width: 1199px) {
  .moreTopics-container .mainCards .template-10.portrait .swiper-button-prev::after {
    right: -3% !important;
  }
}
@media (max-width: 980px) {
  .moreTopics-container .mainCards .template-10.portrait .swiper-button-prev::after {
    right: -5% !important;
  }
}
@media (max-width: 979px) {
  .moreTopics-container .mainCards .template-10.portrait .swiper-button-prev::after {
    right: -4% !important;
  }
}
@media (max-width: 768px) {
  .moreTopics-container .mainCards .template-10.portrait .swiper-button-prev::after {
    right: -6% !important;
  }
}
@media (max-width: 640px) {
  .moreTopics-container .mainCards .template-10.portrait .swiper-button-prev::after {
    right: -6% !important;
  }
}
@media (max-width: 480px) {
  .moreTopics-container .mainCards .template-10.portrait .swiper-button-prev::after {
    right: -9% !important;
  }
}
@media (max-width: 360px) {
  .moreTopics-container .mainCards .template-10.portrait .swiper-button-prev::after {
    right: -12% !important;
  }
}
.moreTopics-container .mainCards .template-10.landscape .swiper-button-next, .moreTopics-container .mainCards .template-10.landscape .swiper-button-prev {
  height: 120%;
}
@media (max-width: 1199px) {
  .moreTopics-container .mainCards .template-10.landscape .swiper-button-next, .moreTopics-container .mainCards .template-10.landscape .swiper-button-prev {
    height: 125%;
  }
}
@media (max-width: 479px) {
  .moreTopics-container .mainCards .template-10.landscape .swiper-button-next, .moreTopics-container .mainCards .template-10.landscape .swiper-button-prev {
    margin-top: -60px;
  }
}
@media (max-width: 360px) {
  .moreTopics-container .mainCards .template-10.landscape .swiper-button-next, .moreTopics-container .mainCards .template-10.landscape .swiper-button-prev {
    height: 130%;
  }
}
.moreTopics-container .mainCards .template-10.landscape .swiper-button-next::after, .moreTopics-container .mainCards .template-10.landscape .swiper-button-prev::after {
  top: 43%;
}
@media (max-width: 2600px) {
  .moreTopics-container .mainCards .template-10.landscape .swiper-button-next::after, .moreTopics-container .mainCards .template-10.landscape .swiper-button-prev::after {
    top: 40%;
  }
}
@media (max-width: 1980px) {
  .moreTopics-container .mainCards .template-10.landscape .swiper-button-next::after, .moreTopics-container .mainCards .template-10.landscape .swiper-button-prev::after {
    top: 40%;
  }
}
@media (max-width: 1200px) {
  .moreTopics-container .mainCards .template-10.landscape .swiper-button-next::after, .moreTopics-container .mainCards .template-10.landscape .swiper-button-prev::after {
    top: 41%;
  }
}
@media (max-width: 1199px) {
  .moreTopics-container .mainCards .template-10.landscape .swiper-button-next::after, .moreTopics-container .mainCards .template-10.landscape .swiper-button-prev::after {
    top: 46%;
  }
}
@media (max-width: 980px) {
  .moreTopics-container .mainCards .template-10.landscape .swiper-button-next::after, .moreTopics-container .mainCards .template-10.landscape .swiper-button-prev::after {
    top: 45% !important;
  }
}
@media (max-width: 768px) {
  .moreTopics-container .mainCards .template-10.landscape .swiper-button-next::after, .moreTopics-container .mainCards .template-10.landscape .swiper-button-prev::after {
    top: 43% !important;
  }
}
@media (max-width: 479px) {
  .moreTopics-container .mainCards .template-10.landscape .swiper-button-next::after, .moreTopics-container .mainCards .template-10.landscape .swiper-button-prev::after {
    top: 51% !important;
  }
}
@media (max-width: 360px) {
  .moreTopics-container .mainCards .template-10.landscape .swiper-button-next::after, .moreTopics-container .mainCards .template-10.landscape .swiper-button-prev::after {
    top: 55% !important;
  }
}
@media (max-width: 2600px) {
  .moreTopics-container .mainCards .template-10.landscape .swiper-button-next {
    padding-right: 35%;
    right: -35% !important;
  }
}
@media (max-width: 2600px) {
  .moreTopics-container .mainCards .template-10.landscape .swiper-button-next::after {
    left: -4% !important;
  }
}
@media (max-width: 1200px) {
  .moreTopics-container .mainCards .template-10.landscape .swiper-button-next::after {
    left: -6% !important;
  }
}
@media (max-width: 1199px) {
  .moreTopics-container .mainCards .template-10.landscape .swiper-button-next::after {
    left: -3% !important;
  }
}
@media (max-width: 980px) {
  .moreTopics-container .mainCards .template-10.landscape .swiper-button-next::after {
    left: -4% !important;
  }
}
@media (max-width: 768px) {
  .moreTopics-container .mainCards .template-10.landscape .swiper-button-next::after {
    left: -5% !important;
  }
}
@media (max-width: 640px) {
  .moreTopics-container .mainCards .template-10.landscape .swiper-button-next::after {
    left: -7% !important;
  }
}
@media (max-width: 479px) {
  .moreTopics-container .mainCards .template-10.landscape .swiper-button-next::after {
    left: -8% !important;
  }
}
@media (max-width: 360px) {
  .moreTopics-container .mainCards .template-10.landscape .swiper-button-next::after {
    left: -11% !important;
  }
}
@media (max-width: 2600px) {
  .moreTopics-container .mainCards .template-10.landscape .swiper-button-prev {
    padding-left: 35%;
    left: -35% !important;
  }
}
@media (max-width: 2600px) {
  .moreTopics-container .mainCards .template-10.landscape .swiper-button-prev::after {
    right: -4% !important;
  }
}
@media (max-width: 1200px) {
  .moreTopics-container .mainCards .template-10.landscape .swiper-button-prev::after {
    right: -6% !important;
  }
}
@media (max-width: 1199px) {
  .moreTopics-container .mainCards .template-10.landscape .swiper-button-prev::after {
    right: -3% !important;
  }
}
@media (max-width: 980px) {
  .moreTopics-container .mainCards .template-10.landscape .swiper-button-prev::after {
    right: -5% !important;
  }
}
@media (max-width: 768px) {
  .moreTopics-container .mainCards .template-10.landscape .swiper-button-prev::after {
    right: -5% !important;
  }
}
@media (max-width: 640px) {
  .moreTopics-container .mainCards .template-10.landscape .swiper-button-prev::after {
    right: -8% !important;
  }
}
@media (max-width: 479px) {
  .moreTopics-container .mainCards .template-10.landscape .swiper-button-prev::after {
    right: -9% !important;
  }
}
@media (max-width: 360px) {
  .moreTopics-container .mainCards .template-10.landscape .swiper-button-prev::after {
    right: -13% !important;
  }
}
.moreTopics-container .view-all-mobile {
  display: none;
}
@media (max-width: 768px) {
  .moreTopics-container .view-all-mobile {
    color: var(--text-color);
    font-size: 16px;
    margin-top: 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
  }
  .moreTopics-container .view-all-mobile.gaia {
    font-family: "Avenir-Medium", sans-serif;
  }
  .moreTopics-container .view-all-mobile.template_9 {
    font-family: "Gilroy-Medium";
  }
}

.moreTopics-container.rtl .mainCards .template-10 .swiper-slide.swiper-slide-active:hover {
  transform: translateX(-15%);
}
.moreTopics-container.rtl .mainCards .template-10 .swiper-slide.swiper-slide.translate-last:hover {
  transform: translateX(16%);
}
.moreTopics-container.rtl .header .view-all .arrow-icon {
  transform: rotate(180deg);
}
.moreTopics-container.rtl .view-all-mobile .arrow-icon {
  transform: rotate(180deg);
}

.moreTopics-container.ltr .header .view-all .arrow-icon {
  transform: rotate(180deg);
}
.moreTopics-container.ltr .mainCards .template-10, .moreTopics-container.ltr .mainCards .swiper {
  direction: ltr;
  text-align: left;
}
.moreTopics-container.ltr .view-all-mobile .arrow-icon {
  transform: rotate(180deg);
}

.moreTopics-container.template-9 {
  padding-bottom: 40px;
  padding-left: unset;
  padding-right: unset;
  max-width: none;
  width: 92%;
  overflow-y: clip;
  position: relative;
}
@media (max-width: 980px) {
  .moreTopics-container.template-9 {
    width: 85%;
  }
}
.moreTopics-container.template-9::after {
  content: "";
  position: absolute;
  bottom: 0;
  left: -100%;
  right: -100%;
  border-bottom: 1px solid var(--border-color);
  z-index: 999;
  overflow: visible;
}
.moreTopics-container.template-9 .header {
  width: unset;
}
.moreTopics-container.template-9 .header .title {
  font-size: 24px;
  font-family: "Gilroy-Medium";
  margin-inline-end: 10px;
}
.moreTopics-container.template-9 .header .show_all {
  font-family: "Gilroy-Medium";
  display: inline-block;
  color: var(--text-hover);
  height: 20px !important;
  width: 20px !important;
  transition: color 0.3s ease-in-out;
  vertical-align: sub;
  margin-inline-start: 10px;
  font-size: medium;
  white-space: nowrap;
}
.moreTopics-container.template-9 .header .show_all:hover, .moreTopics-container.template-9 .header .show_all:focus {
  color: var(--text-color);
}

div.gaiaDetailsScreen {
  padding-top: 60px;
  position: relative;
}
div.gaiaDetailsScreen .swiper-button-next {
  right: -9px !important;
  top: 45%;
}
@media all and (min-width: 1200px) {
  div.gaiaDetailsScreen .swiper-button-next {
    right: 0 !important;
  }
}
div.gaiaDetailsScreen .swiper-button-prev {
  left: -9px !important;
  top: 45%;
}
@media all and (min-width: 1200px) {
  div.gaiaDetailsScreen .swiper-button-prev {
    left: 0 !important;
  }
}
div.gaiaDetailsScreen div.details {
  position: relative;
  z-index: 8;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
div.gaiaDetailsScreen div.details div.topContainer {
  width: 100%;
  margin-right: 40px;
  margin-bottom: 20px;
  display: flex;
  max-height: 700px;
}
@media (max-width: 768px) {
  div.gaiaDetailsScreen div.details div.topContainer {
    max-height: none;
  }
}
div.gaiaDetailsScreen div.details div.topContainer div.imageContainer {
  position: relative;
  width: 100%;
  overflow: hidden;
  /* Blinking effect */
  /* Optional: Add hover styles for better interactivity */
}
@media (max-width: 768px) {
  div.gaiaDetailsScreen div.details div.topContainer div.imageContainer {
    height: 600px;
  }
}
@media (max-width: 480px) {
  div.gaiaDetailsScreen div.details div.topContainer div.imageContainer {
    height: 500px;
  }
}
@media (max-width: 360px) {
  div.gaiaDetailsScreen div.details div.topContainer div.imageContainer {
    height: 450px;
  }
}
div.gaiaDetailsScreen div.details div.topContainer div.imageContainer .trailerPlaceholder {
  position: absolute;
  bottom: 20px;
  inset-inline-end: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  z-index: 10;
}
div.gaiaDetailsScreen div.details div.topContainer div.imageContainer .trailerPlaceholder.hidden {
  display: none;
}
div.gaiaDetailsScreen div.details div.topContainer div.imageContainer .trailerPlaceholder div.gradientTop {
  position: absolute;
  top: 0;
  z-index: 8;
  width: 100%;
  height: 20%;
  background-position: top;
  background-size: cover;
  background-repeat: no-repeat;
  background: linear-gradient(0deg, rgba(16, 20, 31, 0), rgba(16, 20, 31, 0.2) 19%, rgba(16, 20, 31, 0.4) 39%, rgba(16, 20, 31, 0.8));
}
div.gaiaDetailsScreen div.details div.topContainer div.imageContainer .trailerPlaceholder .imageWrapper {
  position: relative;
  max-width: 250px;
  max-height: 150px;
  border-radius: 10px;
  overflow: hidden;
}
@media (max-width: 768px) {
  div.gaiaDetailsScreen div.details div.topContainer div.imageContainer .trailerPlaceholder .imageWrapper {
    max-width: 150px;
    max-height: 100px;
  }
}
div.gaiaDetailsScreen div.details div.topContainer div.imageContainer .trailerPlaceholder .imageWrapper.hidden {
  display: none;
}
div.gaiaDetailsScreen div.details div.topContainer div.imageContainer .trailerPlaceholder .imageWrapper img {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
  display: block;
}
@media (max-width: 480px) {
  div.gaiaDetailsScreen div.details div.topContainer div.imageContainer .trailerPlaceholder .imageWrapper img {
    display: none;
  }
}
div.gaiaDetailsScreen div.details div.topContainer div.imageContainer .trailerPlaceholder .imageWrapper::after {
  content: "";
  position: absolute;
  top: 0;
  inset-inline-start: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(to bottom, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.8));
  z-index: 1;
}
div.gaiaDetailsScreen div.details div.topContainer div.imageContainer .trailerPlaceholder .imageWrapper h2 {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 16px;
  color: white;
  text-align: center;
  padding: 5px 10px;
  border-radius: 5px;
  z-index: 2;
}
@media (max-width: 480px) {
  div.gaiaDetailsScreen div.details div.topContainer div.imageContainer .trailerPlaceholder .imageWrapper h2 {
    top: auto;
    left: auto;
    transform: none;
    position: relative;
    font-size: 12px;
  }
}
div.gaiaDetailsScreen div.details div.topContainer div.imageContainer .trailerPlaceholder .imageWrapper h2 svg {
  color: var(--button-color);
}
div.gaiaDetailsScreen div.details div.topContainer div.imageContainer .trailerOverlay {
  position: fixed;
  top: 0;
  inset-inline-start: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  z-index: 1000;
}
div.gaiaDetailsScreen div.details div.topContainer div.imageContainer .trailerOverlay .closeIcon1 {
  position: relative;
  cursor: pointer;
  height: 25px;
  width: 25px;
  top: 22%;
  inset-inline-start: 74%;
}
div.gaiaDetailsScreen div.details div.topContainer div.imageContainer .trailerOverlay .closeIcon1 svg {
  color: var(--white-color);
}
@media (max-width: 768px) {
  div.gaiaDetailsScreen div.details div.topContainer div.imageContainer .trailerOverlay .closeIcon1 {
    inset-inline-start: 82%;
  }
}
@media (max-width: 480px) {
  div.gaiaDetailsScreen div.details div.topContainer div.imageContainer .trailerOverlay .closeIcon1 {
    inset-inline-start: 94%;
  }
}
@media (max-width: 360px) {
  div.gaiaDetailsScreen div.details div.topContainer div.imageContainer .trailerOverlay .closeIcon1 {
    inset-inline-start: 92%;
    top: 25%;
  }
}
@media (max-width: 320px) {
  div.gaiaDetailsScreen div.details div.topContainer div.imageContainer .trailerOverlay .closeIcon1 {
    inset-inline-start: 91%;
    top: 27%;
  }
}
div.gaiaDetailsScreen div.details div.topContainer div.imageContainer .trailerOverlay .trailer {
  position: relative;
  top: 22%;
  padding-right: 25%;
  padding-left: 25%;
}
@media (max-width: 768px) {
  div.gaiaDetailsScreen div.details div.topContainer div.imageContainer .trailerOverlay .trailer {
    padding-right: 15%;
    padding-left: 15%;
  }
}
@media (max-width: 480px) {
  div.gaiaDetailsScreen div.details div.topContainer div.imageContainer .trailerOverlay .trailer {
    padding-right: 3%;
    padding-left: 3%;
  }
}
@media (max-width: 360px) {
  div.gaiaDetailsScreen div.details div.topContainer div.imageContainer .trailerOverlay .trailer {
    top: 25%;
  }
}
@media (max-width: 320px) {
  div.gaiaDetailsScreen div.details div.topContainer div.imageContainer .trailerOverlay .trailer {
    top: 27%;
  }
}
div.gaiaDetailsScreen div.details div.topContainer div.imageContainer .trailerOverlay .trailer video {
  width: 100%;
  border-radius: 10px;
}
div.gaiaDetailsScreen div.details div.topContainer div.imageContainer div.player {
  position: relative;
  z-index: 9;
  width: 100%;
  display: block;
}
div.gaiaDetailsScreen div.details div.topContainer div.imageContainer div.player div.closeIcon2 {
  display: none;
}
div.gaiaDetailsScreen div.details div.topContainer div.imageContainer div.player.pipMode {
  position: fixed;
  top: 110px;
  width: 22%;
  right: 0;
  z-index: 999999999;
  height: 200px;
}
@media all and (max-width: 768px) {
  div.gaiaDetailsScreen div.details div.topContainer div.imageContainer div.player.pipMode {
    width: 40%;
  }
}
@media all and (max-width: 640px) {
  div.gaiaDetailsScreen div.details div.topContainer div.imageContainer div.player.pipMode {
    width: 50%;
  }
}
@media all and (max-width: 480px) {
  div.gaiaDetailsScreen div.details div.topContainer div.imageContainer div.player.pipMode {
    width: 68%;
  }
}
div.gaiaDetailsScreen div.details div.topContainer div.imageContainer div.player.pipMode video {
  -o-object-fit: cover;
     object-fit: cover;
  height: 100%;
}
div.gaiaDetailsScreen div.details div.topContainer div.imageContainer div.player.pipMode div.gradientTop {
  display: none;
}
div.gaiaDetailsScreen div.details div.topContainer div.imageContainer div.player.pipMode:hover div.closeIcon2 {
  cursor: pointer;
  position: absolute;
  top: 10px;
  inset-inline-start: 10px;
  z-index: 9;
  height: 25px;
  width: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
}
div.gaiaDetailsScreen div.details div.topContainer div.imageContainer div.player.pipMode:hover div.closeIcon2 svg {
  color: var(--bg-color);
}
div.gaiaDetailsScreen div.details div.topContainer div.imageContainer div.player.pipMode:hover div.gradientTop {
  display: block;
}
div.gaiaDetailsScreen div.details div.topContainer div.imageContainer button.items {
  position: absolute;
  top: 0;
  inset-inline-start: 0;
  cursor: pointer;
  background: none;
  border: none;
  padding: 0;
  font-size: 15px;
  color: var(--yellow-color);
  animation: blink 1.5s infinite;
  text-decoration: none;
  z-index: 999;
  margin-left: 3%;
  margin-top: 2%;
}
@keyframes blink {
  0%, 100% {
    opacity: 1;
  }
  50% {
    opacity: 0.5;
  }
}
div.gaiaDetailsScreen div.details div.topContainer div.imageContainer button.items:hover {
  text-decoration: underline;
  opacity: 1; /* Ensure full opacity on hover */
}
div.gaiaDetailsScreen div.details div.topContainer div.imageContainer div.showMetadata {
  padding-left: 3%;
  width: 50%;
  position: absolute;
  z-index: 2;
  color: white;
  top: 50%;
  transform: translateY(-50%);
}
@media (max-width: 980px) {
  div.gaiaDetailsScreen div.details div.topContainer div.imageContainer div.showMetadata {
    padding-left: 2%;
    padding-right: 2%;
  }
}
@media (max-width: 768px) {
  div.gaiaDetailsScreen div.details div.topContainer div.imageContainer div.showMetadata {
    bottom: 0;
    transform: none;
  }
}
@media (max-width: 480px) {
  div.gaiaDetailsScreen div.details div.topContainer div.imageContainer div.showMetadata {
    top: 35%;
  }
}
div.gaiaDetailsScreen div.details div.topContainer div.imageContainer div.showMetadata div.categoryName {
  font-size: 16px;
  margin-bottom: 20px;
  padding-left: 12px;
  border-left: 3px solid var(--button-color);
}
div.gaiaDetailsScreen div.details div.topContainer div.imageContainer div.showMetadata div.categoryName.hidden {
  display: none;
}
@media (max-width: 1600px) {
  div.gaiaDetailsScreen div.details div.topContainer div.imageContainer div.showMetadata div.categoryName {
    font-size: 14px;
  }
}
@media (max-width: 1200px) {
  div.gaiaDetailsScreen div.details div.topContainer div.imageContainer div.showMetadata div.categoryName {
    font-size: 12px;
  }
}
@media (max-width: 980px) {
  div.gaiaDetailsScreen div.details div.topContainer div.imageContainer div.showMetadata div.categoryName {
    font-size: 10px;
  }
}
div.gaiaDetailsScreen div.details div.topContainer div.imageContainer div.showMetadata h1.name {
  font-size: 44px;
  padding-bottom: 10px;
}
@media (max-width: 1600px) {
  div.gaiaDetailsScreen div.details div.topContainer div.imageContainer div.showMetadata h1.name {
    font-size: 36px;
  }
}
@media all and (max-width: 1200px) {
  div.gaiaDetailsScreen div.details div.topContainer div.imageContainer div.showMetadata h1.name {
    font-size: 28px;
  }
}
@media (max-width: 980px) {
  div.gaiaDetailsScreen div.details div.topContainer div.imageContainer div.showMetadata h1.name {
    font-size: 18px;
  }
}
@media (max-width: 768px) {
  div.gaiaDetailsScreen div.details div.topContainer div.imageContainer div.showMetadata h1.name {
    width: 200%;
  }
}
@media (max-width: 480px) {
  div.gaiaDetailsScreen div.details div.topContainer div.imageContainer div.showMetadata h1.name {
    font-size: 15px;
  }
}
div.gaiaDetailsScreen div.details div.topContainer div.imageContainer div.showMetadata p.description {
  font-size: 16px;
  line-height: 1.4em;
  letter-spacing: 0.3px;
  max-width: 100%;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  padding-bottom: 20px;
}
@media (max-width: 1600px) {
  div.gaiaDetailsScreen div.details div.topContainer div.imageContainer div.showMetadata p.description {
    font-size: 14px;
  }
}
@media all and (max-width: 1200px) {
  div.gaiaDetailsScreen div.details div.topContainer div.imageContainer div.showMetadata p.description {
    font-size: 12px;
  }
}
@media (max-width: 980px) {
  div.gaiaDetailsScreen div.details div.topContainer div.imageContainer div.showMetadata p.description {
    font-size: 10px;
  }
}
@media (max-width: 768px) {
  div.gaiaDetailsScreen div.details div.topContainer div.imageContainer div.showMetadata p.description {
    max-width: none;
    width: 200%;
  }
}
@media (max-width: 480px) {
  div.gaiaDetailsScreen div.details div.topContainer div.imageContainer div.showMetadata p.description {
    font-size: 8px;
    padding-bottom: 10px;
  }
}
div.gaiaDetailsScreen div.details div.topContainer div.imageContainer div.showMetadata span.see-more {
  color: white;
  cursor: pointer;
  font-weight: bold;
  text-decoration: underline;
}
div.gaiaDetailsScreen div.details div.topContainer div.imageContainer div.showMetadata span.see-more:hover {
  color: var(--button-color);
}
div.gaiaDetailsScreen div.details div.topContainer div.imageContainer div.showMetadata div.spanning {
  font-size: 21px;
  padding-bottom: 20px;
}
@media (max-width: 1600px) {
  div.gaiaDetailsScreen div.details div.topContainer div.imageContainer div.showMetadata div.spanning {
    font-size: 18px;
  }
}
@media (max-width: 1200px) {
  div.gaiaDetailsScreen div.details div.topContainer div.imageContainer div.showMetadata div.spanning {
    font-size: 16px;
  }
}
@media (max-width: 980px) {
  div.gaiaDetailsScreen div.details div.topContainer div.imageContainer div.showMetadata div.spanning {
    font-size: 14px;
  }
}
@media (max-width: 768px) {
  div.gaiaDetailsScreen div.details div.topContainer div.imageContainer div.showMetadata div.spanning {
    width: 200%;
  }
}
@media all and (max-width: 640px) {
  div.gaiaDetailsScreen div.details div.topContainer div.imageContainer div.showMetadata div.spanning {
    font-size: 14px;
  }
}
@media (max-width: 480px) {
  div.gaiaDetailsScreen div.details div.topContainer div.imageContainer div.showMetadata div.spanning {
    font-size: 10px;
    padding-bottom: 10px;
  }
}
div.gaiaDetailsScreen div.details div.topContainer div.imageContainer div.showMetadata div.spanning span {
  padding-inline-end: 20px;
}
div.gaiaDetailsScreen div.details div.topContainer div.imageContainer div.showMetadata div.spanning span.duration.ltr {
  direction: ltr !important;
  unicode-bidi: embed !important;
}
div.gaiaDetailsScreen div.details div.topContainer div.imageContainer div.showMetadata div.spanning span.certificate {
  padding: 4px 6px;
  margin-inline-end: 20px;
  background-color: rgba(150, 153, 163, 0.32);
  font-size: 11px;
  font-weight: 700;
  color: var(--white-color);
  border-radius: 4px;
}
@media all and (max-width: 640px) {
  div.gaiaDetailsScreen div.details div.topContainer div.imageContainer div.showMetadata div.spanning span.certificate {
    font-size: 10px;
  }
}
div.gaiaDetailsScreen div.details div.topContainer div.imageContainer div.showMetadata div.genres {
  display: flex;
  flex-wrap: nowrap;
  overflow-x: auto;
  overflow-y: hidden;
  gap: 10px;
  align-items: center;
  width: 100%;
  max-width: 100%;
  white-space: nowrap;
  box-sizing: border-box;
  margin-bottom: 30px;
  padding-bottom: 5px;
  scrollbar-width: thin; /* Set thin scrollbar */
  scrollbar-color: rgba(58, 56, 56, 0.5) transparent;
  -ms-overflow-style: none; /* IE and Edge */
}
div.gaiaDetailsScreen div.details div.topContainer div.imageContainer div.showMetadata div.genres ::-webkit-scrollbar {
  display: none; /* Chrome, Safari, and Edge */
}
@media (max-width: 768px) {
  div.gaiaDetailsScreen div.details div.topContainer div.imageContainer div.showMetadata div.genres {
    max-width: none;
    width: 200%;
  }
}
@media (max-width: 640px) {
  div.gaiaDetailsScreen div.details div.topContainer div.imageContainer div.showMetadata div.genres {
    margin-bottom: 8px;
  }
}
div.gaiaDetailsScreen div.details div.topContainer div.imageContainer div.showMetadata div.genres span {
  background: hsla(0, 0%, 100%, 0.21);
  font-family: "Avenir-Medium", sans-serif;
  padding: 5px 20px;
  font-size: 16px;
  border-radius: 25px;
  border-width: 0;
  cursor: pointer;
  transition: background-color 0.3s ease;
  white-space: nowrap;
}
@media (max-width: 1600px) {
  div.gaiaDetailsScreen div.details div.topContainer div.imageContainer div.showMetadata div.genres span {
    font-size: 14px;
  }
}
@media (max-width: 1200px) {
  div.gaiaDetailsScreen div.details div.topContainer div.imageContainer div.showMetadata div.genres span {
    font-size: 12px;
  }
}
@media (max-width: 980px) {
  div.gaiaDetailsScreen div.details div.topContainer div.imageContainer div.showMetadata div.genres span {
    font-size: 10px;
  }
}
@media (max-width: 480px) {
  div.gaiaDetailsScreen div.details div.topContainer div.imageContainer div.showMetadata div.genres span {
    font-size: 8px;
  }
}
div.gaiaDetailsScreen div.details div.topContainer div.imageContainer div.showMetadata div.genres span:hover {
  background-color: var(--grey-color);
}
div.gaiaDetailsScreen div.details div.topContainer div.imageContainer div.showMetadata div.watchNow {
  width: 30%;
  position: absolute;
  z-index: 2;
}
@media (max-width: 768px) {
  div.gaiaDetailsScreen div.details div.topContainer div.imageContainer div.showMetadata div.watchNow {
    width: 100%;
  }
}
@media (max-width: 480px) {
  div.gaiaDetailsScreen div.details div.topContainer div.imageContainer div.showMetadata div.watchNow {
    width: 192%;
  }
}
div.gaiaDetailsScreen div.details div.topContainer div.imageContainer div.showMetadata div.watchNow button {
  font-family: "Avenir-Black", sans-serif;
  display: block;
  background: var(--button-color);
  padding: 15px 10px;
  font-size: 24px;
  color: var(--white-color) !important;
  width: 100%;
  cursor: pointer;
  transition: 0.3s ease-in-out;
}
@media (max-width: 1600px) {
  div.gaiaDetailsScreen div.details div.topContainer div.imageContainer div.showMetadata div.watchNow button {
    font-size: 20px;
  }
}
@media (max-width: 1200px) {
  div.gaiaDetailsScreen div.details div.topContainer div.imageContainer div.showMetadata div.watchNow button {
    font-size: 16px;
  }
}
@media (max-width: 980px) {
  div.gaiaDetailsScreen div.details div.topContainer div.imageContainer div.showMetadata div.watchNow button {
    font-size: 14px;
  }
}
div.gaiaDetailsScreen div.details div.topContainer div.imageContainer div.showMetadata div.watchNow button:hover {
  background: var(--button-hover);
}
div.gaiaDetailsScreen div.details div.topContainer div.imageContainer div.showMetadata div.subscriptions {
  margin-top: 10%;
  white-space: nowrap;
  overflow-x: auto;
  overflow-y: hidden;
  width: 200%;
  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none; /* IE and Edge */
}
div.gaiaDetailsScreen div.details div.topContainer div.imageContainer div.showMetadata div.subscriptions ::-webkit-scrollbar {
  display: none; /* Chrome, Safari, and Edge */
}
@media (max-width: 1768px) {
  div.gaiaDetailsScreen div.details div.topContainer div.imageContainer div.showMetadata div.subscriptions {
    margin-top: 11%;
  }
}
@media (max-width: 1400px) {
  div.gaiaDetailsScreen div.details div.topContainer div.imageContainer div.showMetadata div.subscriptions {
    margin-top: 12%;
  }
}
@media (max-width: 1200px) {
  div.gaiaDetailsScreen div.details div.topContainer div.imageContainer div.showMetadata div.subscriptions {
    margin-top: 13%;
  }
}
@media (max-width: 980px) {
  div.gaiaDetailsScreen div.details div.topContainer div.imageContainer div.showMetadata div.subscriptions {
    margin-top: 17%;
  }
}
@media (max-width: 768px) {
  div.gaiaDetailsScreen div.details div.topContainer div.imageContainer div.showMetadata div.subscriptions {
    font-size: 14px;
    margin-top: 20%;
  }
}
@media (max-width: 640px) {
  div.gaiaDetailsScreen div.details div.topContainer div.imageContainer div.showMetadata div.subscriptions {
    margin-top: 17%;
  }
}
@media (max-width: 480px) {
  div.gaiaDetailsScreen div.details div.topContainer div.imageContainer div.showMetadata div.subscriptions {
    margin-top: 30%;
  }
}
@media (max-width: 360px) {
  div.gaiaDetailsScreen div.details div.topContainer div.imageContainer div.showMetadata div.subscriptions {
    font-size: 10px;
    margin-top: 35%;
  }
}
div.gaiaDetailsScreen div.details div.topContainer div.imageContainer div.showMetadata div.subscriptions div.package-item {
  margin-left: 0px;
  margin-right: 10px;
  border-radius: 5px;
}
div.gaiaDetailsScreen div.details div.topContainer div.imageContainer img {
  display: block;
  width: 80%;
  border-radius: 5px;
  -o-object-fit: cover;
     object-fit: cover;
  float: right;
  max-height: 700px;
}
@media (max-width: 768px) {
  div.gaiaDetailsScreen div.details div.topContainer div.imageContainer img {
    width: 100%;
  }
}
div.gaiaDetailsScreen div.details div.topContainer div.imageContainer::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 80%;
  height: 100%;
  background: linear-gradient(to left, transparent, rgba(0, 0, 0, 0.3) 20%, rgba(0, 0, 0, 0.4) 40%, rgba(0, 0, 0, 0.95) 60%, rgb(0, 0, 0) 80%, rgb(0, 0, 0));
  z-index: 1;
}
@media (max-width: 768px) {
  div.gaiaDetailsScreen div.details div.topContainer div.imageContainer::before {
    width: 100%;
    background: linear-gradient(to bottom, transparent, rgba(0, 0, 0, 0) 20%, rgba(0, 0, 0, 0.9) 40%, rgb(0, 0, 0) 60%, rgb(0, 0, 0) 80%, rgb(0, 0, 0));
  }
}
div.gaiaDetailsScreen div.details div.bottomContainer {
  padding-top: 20px;
  padding-left: 3.5%;
  padding-right: 3.5%;
  width: 100%;
  color: var(--text-color);
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}
div.gaiaDetailsScreen div.details div.bottomContainer::-webkit-scrollbar {
  display: none;
}
div.gaiaDetailsScreen div.details div.bottomContainer div.sectionToggle {
  display: flex;
  flex-direction: row;
  gap: 50px;
  position: relative;
  margin-bottom: 30px;
}
div.gaiaDetailsScreen div.details div.bottomContainer div.sectionToggle::after {
  content: "";
  position: absolute;
  bottom: -20px;
  left: 0;
  right: 0;
  height: 1px;
  background-color: var(--grey-color);
}
div.gaiaDetailsScreen div.details div.bottomContainer div.sectionToggle h1 {
  font-size: 19px;
  cursor: pointer;
  font-family: "Avenir-Roman", sans-serif;
  position: relative;
}
div.gaiaDetailsScreen div.details div.bottomContainer div.sectionToggle h1.selected {
  font-family: "Avenir-Black", sans-serif;
}
div.gaiaDetailsScreen div.details div.bottomContainer div.sectionToggle h1.selected::after {
  content: "";
  position: absolute;
  bottom: -20px;
  left: 0;
  right: 0;
  height: 6px;
  background-color: var(--button-color);
}
div.gaiaDetailsScreen div.details div.bottomContainer div.left {
  padding-top: 15px;
  padding-right: 3%;
  padding-bottom: 100px;
}
div.gaiaDetailsScreen div.details div.bottomContainer div.left div.buttons {
  margin-bottom: 40px;
  display: flex;
  align-items: center;
  gap: 25px;
}
@media (max-width: 768px) {
  div.gaiaDetailsScreen div.details div.bottomContainer div.left div.buttons {
    margin-bottom: 4%;
  }
}
@media (max-width: 480px) {
  div.gaiaDetailsScreen div.details div.bottomContainer div.left div.buttons {
    margin-bottom: 5%;
    flex-direction: column;
    flex-flow: wrap;
  }
}
div.gaiaDetailsScreen div.details div.bottomContainer div.left div.buttons button {
  display: flex;
  align-items: center;
  background: transparent;
  border: none;
  color: var(--text-color);
  font-size: 16px;
  cursor: pointer;
  white-space: nowrap;
  max-width: -moz-fit-content;
  max-width: fit-content;
  transition: fill 0.3s ease, color 0.5s ease;
}
@media (max-width: 767px) {
  div.gaiaDetailsScreen div.details div.bottomContainer div.left div.buttons button {
    font-size: 14px;
  }
}
div.gaiaDetailsScreen div.details div.bottomContainer div.left div.buttons button svg {
  margin-right: 5px;
  width: 40px;
  color: var(--button-color);
}
@media (max-width: 767px) {
  div.gaiaDetailsScreen div.details div.bottomContainer div.left div.buttons button svg {
    width: 30px;
  }
}
div.gaiaDetailsScreen div.details div.bottomContainer div.left div.buttons button:hover {
  color: var(--button-hover);
}
div.gaiaDetailsScreen div.details div.bottomContainer div.left div.buttons button.addtoList circle {
  transition: fill 0.3s ease, color 0.5s ease;
  fill: var(--button-color);
  stroke: none;
}
div.gaiaDetailsScreen div.details div.bottomContainer div.left div.buttons button.addtoList path {
  transition: fill 0.3s ease, color 0.5s ease;
  stroke: var(--bg-color);
  fill: var(--bg-color);
}
div.gaiaDetailsScreen div.details div.bottomContainer div.left div.buttons button.addtoList:hover circle {
  transition: fill 0.3s ease, color 0.5s ease;
  fill: var(--button-hover);
}
div.gaiaDetailsScreen div.details div.bottomContainer div.left div.buttons button.addtoList:hover path {
  transition: fill 0.3s ease, color 0.5s ease;
}
div.gaiaDetailsScreen div.details div.bottomContainer div.left div.buttons div.shareWrapper {
  position: relative;
}
div.gaiaDetailsScreen div.details div.bottomContainer div.left div.buttons div.shareWrapper button.share svg {
  height: 35px;
}
div.gaiaDetailsScreen div.details div.bottomContainer div.left div.buttons div.shareWrapper button.share path {
  transition: fill 0.3s ease, color 0.5s ease;
  fill: var(--button-color);
}
div.gaiaDetailsScreen div.details div.bottomContainer div.left div.buttons div.shareWrapper button.share:hover path {
  transition: fill 0.3s ease, color 0.5s ease;
  fill: var(--button-hover);
}
div.gaiaDetailsScreen div.details div.bottomContainer div.left div.buttons div.shareWrapper div.shareContainer {
  z-index: 9;
  position: absolute;
  border: 1px solid var(--border-color);
  width: 150px;
  border-radius: 8px;
  display: none;
}
@media all and (max-width: 980px) {
  div.gaiaDetailsScreen div.details div.bottomContainer div.left div.buttons div.shareWrapper div.shareContainer {
    left: 50%;
    transform: translateX(-50%);
  }
}
div.gaiaDetailsScreen div.details div.bottomContainer div.left div.buttons div.shareWrapper div.shareContainer.active {
  display: block;
  background-color: var(--bg-color);
  z-index: 10;
}
div.gaiaDetailsScreen div.details div.bottomContainer div.left div.buttons div.shareWrapper div.shareContainer div.facebookContainer button.facebook {
  display: flex !important;
  justify-content: space-between !important;
  padding: 15px !important;
  color: var(--text-color) !important;
  cursor: pointer !important;
  border-bottom: 1px solid var(--border-color) !important;
  width: 100% !important;
}
div.gaiaDetailsScreen div.details div.bottomContainer div.left div.buttons div.shareWrapper div.shareContainer div.facebookContainer button.facebook div.icon {
  width: 10%;
}
div.gaiaDetailsScreen div.details div.bottomContainer div.left div.buttons div.shareWrapper div.shareContainer div.facebookContainer button.facebook div.icon svg {
  width: 20px !important;
  height: 20px !important;
  color: var(--button-color);
  transition: 0.3s ease-in-out;
}
div.gaiaDetailsScreen div.details div.bottomContainer div.left div.buttons div.shareWrapper div.shareContainer div.facebookContainer button.facebook div.icon svg:hover {
  color: var(--button-hover);
}
div.gaiaDetailsScreen div.details div.bottomContainer div.left div.buttons div.shareWrapper div.shareContainer div.facebookContainer div.left {
  width: 10%;
}
div.gaiaDetailsScreen div.details div.bottomContainer div.left div.buttons div.shareWrapper div.shareContainer div.facebookContainer div.right svg {
  width: 20px !important;
  height: 20px !important;
  color: var(--grey-color);
  transition: 0.3s ease-in-out;
}
div.gaiaDetailsScreen div.details div.bottomContainer div.left div.buttons div.shareWrapper div.shareContainer div.facebookContainer div.right svg:hover {
  color: var(--text-color);
}
div.gaiaDetailsScreen div.details div.bottomContainer div.left div.buttons div.shareWrapper div.shareContainer div.twitterContainer button.twitter {
  display: flex !important;
  justify-content: space-between !important;
  padding: 15px !important;
  color: var(--text-color) !important;
  cursor: pointer !important;
  width: 100% !important;
}
div.gaiaDetailsScreen div.details div.bottomContainer div.left div.buttons div.shareWrapper div.shareContainer div.twitterContainer button.twitter div.icon {
  width: 10%;
}
div.gaiaDetailsScreen div.details div.bottomContainer div.left div.buttons div.shareWrapper div.shareContainer div.twitterContainer button.twitter div.icon svg {
  width: 20px !important;
  height: 20px !important;
  color: var(--button-color);
  transition: 0.3s ease-in-out;
}
div.gaiaDetailsScreen div.details div.bottomContainer div.left div.buttons div.shareWrapper div.shareContainer div.twitterContainer button.twitter div.icon svg:hover {
  color: var(--button-hover);
}
div.gaiaDetailsScreen div.details div.bottomContainer div.left div.buttons div.shareWrapper div.shareContainer div.twitterContainer div.left {
  width: 10%;
}
div.gaiaDetailsScreen div.details div.bottomContainer div.left div.buttons div.shareWrapper div.shareContainer div.twitterContainer div.right svg {
  width: 20px !important;
  height: 20px !important;
  color: var(--grey-color);
  transition: 0.3s ease-in-out;
}
div.gaiaDetailsScreen div.details div.bottomContainer div.left div.buttons div.shareWrapper div.shareContainer div.twitterContainer div.right svg:hover {
  color: var(--text-color);
}
div.gaiaDetailsScreen div.details div.bottomContainer div.left div.buttons button.reportProblemContainer {
  right: 0;
  z-index: 9;
  width: 200px;
  border-radius: 8px;
  cursor: pointer;
}
div.gaiaDetailsScreen div.details div.bottomContainer div.left div.buttons button.reportProblemContainer:hover svg path {
  fill: var(--button-hover);
}
div.gaiaDetailsScreen div.details div.bottomContainer div.left div.buttons button.reportProblemContainer svg {
  height: 30px;
}
@media (max-width: 767px) {
  div.gaiaDetailsScreen div.details div.bottomContainer div.left div.buttons button.reportProblemContainer svg {
    height: 25px;
  }
}
div.gaiaDetailsScreen div.details div.bottomContainer div.left div.buttons button.reportProblemContainer svg path {
  fill: var(--button-color);
}
div.gaiaDetailsScreen div.details div.bottomContainer div.left div.buttons button.reportProblemContainer span {
  color: var(--text-color);
}
div.gaiaDetailsScreen div.details div.bottomContainer div.left div.metadata p.description {
  font-size: 16px;
  color: var(--text-color);
  line-height: 1.4em;
  letter-spacing: 0.3px;
  max-width: 100%;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  overflow: hidden;
  text-overflow: ellipsis;
}
@media all and (max-width: 1200px) {
  div.gaiaDetailsScreen div.details div.bottomContainer div.left div.metadata p.description {
    max-width: 100%;
  }
}
div.gaiaDetailsScreen div.details div.bottomContainer div.left div.metadata div.info {
  margin-top: 30px;
}
div.gaiaDetailsScreen div.details div.bottomContainer div.left div.metadata div.info div.starring {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
}
div.gaiaDetailsScreen div.details div.bottomContainer div.left div.metadata div.info div.starring span.left {
  width: 15%;
}
@media all and (max-width: 768px) {
  div.gaiaDetailsScreen div.details div.bottomContainer div.left div.metadata div.info div.starring span.left {
    width: 20%;
  }
}
@media all and (max-width: 640px) {
  div.gaiaDetailsScreen div.details div.bottomContainer div.left div.metadata div.info div.starring span.left {
    width: 25%;
  }
}
@media all and (max-width: 480px) {
  div.gaiaDetailsScreen div.details div.bottomContainer div.left div.metadata div.info div.starring span.left {
    width: 30%;
  }
}
@media all and (max-width: 360px) {
  div.gaiaDetailsScreen div.details div.bottomContainer div.left div.metadata div.info div.starring span.left {
    width: 33%;
  }
}
div.gaiaDetailsScreen div.details div.bottomContainer div.left div.metadata div.info div.starring span.left h6 {
  font-size: 16px;
  color: var(--inner-text-color);
}
div.gaiaDetailsScreen div.details div.bottomContainer div.left div.metadata div.info div.starring span.right {
  width: 85%;
}
@media all and (max-width: 980px) {
  div.gaiaDetailsScreen div.details div.bottomContainer div.left div.metadata div.info div.starring span.right {
    width: 75%;
  }
}
@media all and (max-width: 640px) {
  div.gaiaDetailsScreen div.details div.bottomContainer div.left div.metadata div.info div.starring span.right {
    width: 70%;
  }
}
@media all and (max-width: 480px) {
  div.gaiaDetailsScreen div.details div.bottomContainer div.left div.metadata div.info div.starring span.right {
    width: 65%;
  }
}
@media all and (max-width: 360px) {
  div.gaiaDetailsScreen div.details div.bottomContainer div.left div.metadata div.info div.starring span.right {
    width: 55%;
  }
}
div.gaiaDetailsScreen div.details div.bottomContainer div.left div.metadata div.info div.starring span.right ul.names {
  display: flex;
  flex-wrap: wrap;
}
div.gaiaDetailsScreen div.details div.bottomContainer div.left div.metadata div.info div.starring span.right ul.names li {
  font-size: 16px;
  margin-right: 8px;
  color: var(--text-color);
}
div.gaiaDetailsScreen div.details div.bottomContainer div.left div.metadata div.info div.starring span.right ul.names li:last-child {
  margin-right: 0;
}
@media all and (max-width: 480px) {
  div.gaiaDetailsScreen div.details div.bottomContainer div.left div.metadata div.info div.starring span.right ul.names li {
    font-size: 14px;
  }
}
div.gaiaDetailsScreen div.details div.bottomContainer div.left div.metadata div.director {
  display: flex;
  justify-content: space-between;
  margin-bottom: 30px;
}
div.gaiaDetailsScreen div.details div.bottomContainer div.left div.metadata div.director span.left {
  width: 15%;
}
@media all and (max-width: 768px) {
  div.gaiaDetailsScreen div.details div.bottomContainer div.left div.metadata div.director span.left {
    width: 20%;
  }
}
@media all and (max-width: 640px) {
  div.gaiaDetailsScreen div.details div.bottomContainer div.left div.metadata div.director span.left {
    width: 25%;
  }
}
@media all and (max-width: 480px) {
  div.gaiaDetailsScreen div.details div.bottomContainer div.left div.metadata div.director span.left {
    width: 30%;
  }
}
@media all and (max-width: 360px) {
  div.gaiaDetailsScreen div.details div.bottomContainer div.left div.metadata div.director span.left {
    width: 33%;
  }
}
div.gaiaDetailsScreen div.details div.bottomContainer div.left div.metadata div.director span.left h6 {
  font-size: 16px;
  color: var(--inner-text-color);
}
div.gaiaDetailsScreen div.details div.bottomContainer div.left div.metadata div.director span.right {
  width: 85%;
}
@media all and (max-width: 980px) {
  div.gaiaDetailsScreen div.details div.bottomContainer div.left div.metadata div.director span.right {
    width: 75%;
  }
}
@media all and (max-width: 640px) {
  div.gaiaDetailsScreen div.details div.bottomContainer div.left div.metadata div.director span.right {
    width: 70%;
  }
}
@media all and (max-width: 480px) {
  div.gaiaDetailsScreen div.details div.bottomContainer div.left div.metadata div.director span.right {
    width: 65%;
  }
}
@media all and (max-width: 360px) {
  div.gaiaDetailsScreen div.details div.bottomContainer div.left div.metadata div.director span.right {
    width: 55%;
  }
}
div.gaiaDetailsScreen div.details div.bottomContainer div.left div.metadata div.director span.right h6 {
  font-size: 16px;
  margin-right: 8px;
  color: var(--text-color);
}
@media all and (max-width: 480px) {
  div.gaiaDetailsScreen div.details div.bottomContainer div.left div.metadata div.director span.right h6 {
    font-size: 14px;
  }
}
div.gaiaDetailsScreen div.details div.bottomContainer div.left div.metadata div.userRatings {
  display: flex;
  margin-bottom: 10px;
}
div.gaiaDetailsScreen div.details div.bottomContainer div.left div.metadata div.userRatings div.star.active svg {
  fill: #fbe725;
}
div.gaiaDetailsScreen div.details div.bottomContainer div.left div.metadata div.userRatings div.star svg {
  width: 23px !important;
  height: 23px !important;
  fill: var(--grey-color);
  transition: 0.3s ease-in-out;
}
div.gaiaDetailsScreen div.details div.bottomContainer div.left div.metadata div.addRating {
  width: 10px;
  cursor: pointer;
  margin-bottom: 10px;
}
div.gaiaDetailsScreen div.details div.bottomContainer div.left div.metadata div.addRating:hover svg {
  fill: var(--grey-color);
}
div.gaiaDetailsScreen div.details div.bottomContainer div.left div.metadata div.addRating svg {
  width: 27px !important;
  height: 27px !important;
  fill: var(--button-color);
  transition: 0.3s ease-in-out;
}
div.gaiaDetailsScreen div.details div.bottomContainer div.left div.metadata div.categories {
  padding: 30px 0px;
  background-color: transparent !important;
}
@media all and (max-width: 768px) {
  div.gaiaDetailsScreen div.details div.bottomContainer div.left div.metadata div.categories {
    padding: 30px 30px;
  }
}
div.gaiaDetailsScreen div.details div.bottomContainer div.left div.metadata div.categories h1.heading {
  font-size: 25px;
  color: var(--text-color);
  margin-bottom: 20px;
  text-transform: capitalize;
}
div.gaiaDetailsScreen div.details div.bottomContainer div.left div.metadata div.categories div.categoryLists ul {
  display: flex;
  flex-wrap: wrap;
}
div.gaiaDetailsScreen div.details div.bottomContainer div.left div.metadata div.categories div.categoryLists ul li.list {
  display: flex;
  margin-inline-end: 10px;
  width: 24%;
  background-color: var(--bg-color);
  margin-bottom: 10px;
  cursor: pointer;
}
div.gaiaDetailsScreen div.details div.bottomContainer div.left div.metadata div.categories div.categoryLists ul li.list:nth-child(4n) {
  margin-inline-end: 0;
}
@media all and (max-width: 1200px) {
  div.gaiaDetailsScreen div.details div.bottomContainer div.left div.metadata div.categories div.categoryLists ul li.list {
    width: 32.6%;
  }
  div.gaiaDetailsScreen div.details div.bottomContainer div.left div.metadata div.categories div.categoryLists ul li.list:nth-child(3n) {
    margin-inline-end: 0;
  }
  div.gaiaDetailsScreen div.details div.bottomContainer div.left div.metadata div.categories div.categoryLists ul li.list:nth-child(4n) {
    margin-inline-end: 10px;
  }
}
@media all and (max-width: 980px) {
  div.gaiaDetailsScreen div.details div.bottomContainer div.left div.metadata div.categories div.categoryLists ul li.list {
    width: 49%;
  }
  div.gaiaDetailsScreen div.details div.bottomContainer div.left div.metadata div.categories div.categoryLists ul li.list:nth-child(3n) {
    margin-inline-end: 10px;
  }
  div.gaiaDetailsScreen div.details div.bottomContainer div.left div.metadata div.categories div.categoryLists ul li.list:nth-child(2n) {
    margin-inline-end: 0;
  }
}
@media all and (max-width: 640px) {
  div.gaiaDetailsScreen div.details div.bottomContainer div.left div.metadata div.categories div.categoryLists ul li.list {
    width: 100%;
    margin-inline-end: 0;
  }
  div.gaiaDetailsScreen div.details div.bottomContainer div.left div.metadata div.categories div.categoryLists ul li.list:nth-child(3n) {
    margin-inline-end: 0;
  }
  div.gaiaDetailsScreen div.details div.bottomContainer div.left div.metadata div.categories div.categoryLists ul li.list:nth-child(2n) {
    margin-inline-end: 0;
  }
}
div.gaiaDetailsScreen div.details div.bottomContainer div.left div.metadata div.categories div.categoryLists ul li.list div.left div.imageContainer {
  width: 70px;
}
div.gaiaDetailsScreen div.details div.bottomContainer div.left div.metadata div.categories div.categoryLists ul li.list div.left div.imageContainer img {
  display: block;
  width: 100%;
}
div.gaiaDetailsScreen div.details div.bottomContainer div.left div.metadata div.categories div.categoryLists ul li.list div.right {
  padding: 10px;
}
div.gaiaDetailsScreen div.details div.bottomContainer div.left div.metadata div.categories div.categoryLists ul li.list div.right h1.title {
  font-size: 14px;
  color: var(--text-color);
}
div.gaiaDetailsScreen div.details div.bottomContainer div.left div.metadata div.aboutSection {
  background-color: transparent !important;
  padding: 30px 0px;
}
@media all and (max-width: 768px) {
  div.gaiaDetailsScreen div.details div.bottomContainer div.left div.metadata div.aboutSection {
    padding: 30px 30px;
  }
}
div.gaiaDetailsScreen div.details div.bottomContainer div.left div.metadata div.aboutSection h1.heading {
  font-size: 25px;
  color: var(--text-color);
  margin-bottom: 20px;
}
div.gaiaDetailsScreen div.details div.bottomContainer div.left div.metadata div.aboutSection div.aboutLists {
  display: flex;
  flex-wrap: wrap;
}
div.gaiaDetailsScreen div.details div.bottomContainer div.left div.metadata div.aboutSection div.aboutLists ul {
  flex-direction: column;
  width: 100%;
}
div.gaiaDetailsScreen div.details div.bottomContainer div.left div.metadata div.aboutSection div.aboutLists ul div.aboutContainer {
  background-color: transparent;
  width: 100%;
  justify-content: flex-start;
  display: flex;
  flex-direction: column;
}
div.gaiaDetailsScreen div.details div.bottomContainer div.left div.metadata div.aboutSection div.aboutLists ul div.aboutContainer li.aboutlist {
  width: 100%;
  background-color: var(--white-color);
  margin-inline-end: 7px;
  cursor: pointer;
}
div.gaiaDetailsScreen div.details div.bottomContainer div.left div.metadata div.aboutSection div.aboutLists ul div.aboutContainer li.aboutlist:hover div.info h1.name {
  color: var(--grey-color);
}
div.gaiaDetailsScreen div.details div.bottomContainer div.left div.metadata div.aboutSection div.aboutLists ul div.aboutContainer li.aboutlist:nth-child(8n) {
  margin-inline-end: 0;
}
div.gaiaDetailsScreen div.details div.bottomContainer div.left div.metadata div.aboutSection div.aboutLists ul div.aboutContainer li.aboutlist:last-child {
  margin-inline-end: 0 !important;
}
@media all and (max-width: 2600px) {
  div.gaiaDetailsScreen div.details div.bottomContainer div.left div.metadata div.aboutSection div.aboutLists ul div.aboutContainer li.aboutlist {
    width: 12%;
  }
  div.gaiaDetailsScreen div.details div.bottomContainer div.left div.metadata div.aboutSection div.aboutLists ul div.aboutContainer li.aboutlist:nth-child(7n) {
    margin-inline-end: 0;
  }
  div.gaiaDetailsScreen div.details div.bottomContainer div.left div.metadata div.aboutSection div.aboutLists ul div.aboutContainer li.aboutlist:nth-child(8n) {
    margin-inline-end: 7px;
  }
}
@media all and (max-width: 1400px) {
  div.gaiaDetailsScreen div.details div.bottomContainer div.left div.metadata div.aboutSection div.aboutLists ul div.aboutContainer li.aboutlist {
    width: 14%;
  }
  div.gaiaDetailsScreen div.details div.bottomContainer div.left div.metadata div.aboutSection div.aboutLists ul div.aboutContainer li.aboutlist:nth-child(7n) {
    margin-inline-end: 0;
  }
  div.gaiaDetailsScreen div.details div.bottomContainer div.left div.metadata div.aboutSection div.aboutLists ul div.aboutContainer li.aboutlist:nth-child(8n) {
    margin-inline-end: 7px;
  }
}
@media all and (max-width: 1200px) {
  div.gaiaDetailsScreen div.details div.bottomContainer div.left div.metadata div.aboutSection div.aboutLists ul div.aboutContainer li.aboutlist {
    width: 19%;
  }
  div.gaiaDetailsScreen div.details div.bottomContainer div.left div.metadata div.aboutSection div.aboutLists ul div.aboutContainer li.aboutlist:nth-child(7n) {
    margin-inline-end: 7px;
  }
  div.gaiaDetailsScreen div.details div.bottomContainer div.left div.metadata div.aboutSection div.aboutLists ul div.aboutContainer li.aboutlist:nth-child(6n) {
    margin-inline-end: 0;
  }
}
@media all and (max-width: 980px) {
  div.gaiaDetailsScreen div.details div.bottomContainer div.left div.metadata div.aboutSection div.aboutLists ul div.aboutContainer li.aboutlist {
    width: 24%;
  }
  div.gaiaDetailsScreen div.details div.bottomContainer div.left div.metadata div.aboutSection div.aboutLists ul div.aboutContainer li.aboutlist:nth-child(4n) {
    margin-inline-end: 0;
  }
  div.gaiaDetailsScreen div.details div.bottomContainer div.left div.metadata div.aboutSection div.aboutLists ul div.aboutContainer li.aboutlist:nth-child(6n) {
    margin-inline-end: 7px;
  }
}
@media all and (max-width: 768px) {
  div.gaiaDetailsScreen div.details div.bottomContainer div.left div.metadata div.aboutSection div.aboutLists ul div.aboutContainer li.aboutlist {
    width: 32%;
  }
  div.gaiaDetailsScreen div.details div.bottomContainer div.left div.metadata div.aboutSection div.aboutLists ul div.aboutContainer li.aboutlist:nth-child(4n) {
    margin-inline-end: 0;
  }
  div.gaiaDetailsScreen div.details div.bottomContainer div.left div.metadata div.aboutSection div.aboutLists ul div.aboutContainer li.aboutlist:nth-child(6n) {
    margin-inline-end: 7px;
  }
}
@media all and (max-width: 480px) {
  div.gaiaDetailsScreen div.details div.bottomContainer div.left div.metadata div.aboutSection div.aboutLists ul div.aboutContainer li.aboutlist {
    width: 49%;
  }
  div.gaiaDetailsScreen div.details div.bottomContainer div.left div.metadata div.aboutSection div.aboutLists ul div.aboutContainer li.aboutlist:nth-child(3n) {
    margin-inline-end: 7px;
  }
  div.gaiaDetailsScreen div.details div.bottomContainer div.left div.metadata div.aboutSection div.aboutLists ul div.aboutContainer li.aboutlist:nth-child(2n) {
    margin-inline-end: 0;
  }
}
@media all and (max-width: 360px) {
  div.gaiaDetailsScreen div.details div.bottomContainer div.left div.metadata div.aboutSection div.aboutLists ul div.aboutContainer li.aboutlist {
    width: 99%;
  }
  div.gaiaDetailsScreen div.details div.bottomContainer div.left div.metadata div.aboutSection div.aboutLists ul div.aboutContainer li.aboutlist:nth-child(3n) {
    margin-inline-end: 7px;
  }
  div.gaiaDetailsScreen div.details div.bottomContainer div.left div.metadata div.aboutSection div.aboutLists ul div.aboutContainer li.aboutlist:nth-child(2n) {
    margin-inline-end: 0;
  }
}
div.gaiaDetailsScreen div.details div.bottomContainer div.left div.metadata div.aboutSection div.aboutLists ul div.aboutContainer li.aboutlist div.imageContainer {
  width: 100%;
  background-color: var(--white-color);
  margin-inline-end: 7px;
  position: relative;
  align-content: space-around;
}
div.gaiaDetailsScreen div.details div.bottomContainer div.left div.metadata div.aboutSection div.aboutLists ul div.aboutContainer li.aboutlist div.imageContainer img {
  width: 100%;
  align-items: center;
  display: block;
}
div.gaiaDetailsScreen div.details div.bottomContainer div.left div.metadata div.aboutSection div.aboutLists ul div.aboutContainer li.aboutlist div.imageContainer div.openurl {
  position: absolute;
  z-index: 99;
  margin-top: -44px;
  inset-inline-end: 0;
}
div.gaiaDetailsScreen div.details div.bottomContainer div.left div.metadata div.aboutSection div.aboutLists ul div.aboutContainer li.aboutlist div.imageContainer div.openurl svg {
  fill: #fff !important;
  width: 20px;
  stroke: #fff;
}
div.gaiaDetailsScreen div.details div.bottomContainer div.left div.metadata div.aboutSection div.aboutLists ul div.aboutContainer li.aboutlist div.info {
  padding: 10px 10px 30px;
  background: #fff;
  padding: 8px;
  min-height: 121px;
  max-height: 121px;
}
div.gaiaDetailsScreen div.details div.bottomContainer div.left div.metadata div.aboutSection div.aboutLists ul div.aboutContainer li.aboutlist div.info h1.title {
  font-size: 16px;
  color: var(--text-hover);
}
div.gaiaDetailsScreen div.details div.bottomContainer div.left div.metadata div.aboutSection div.aboutLists ul div.aboutContainer li.aboutlist div.info p.description {
  color: #666;
  font-weight: 400;
  font-size: 12px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  margin-top: 10px;
}
div.gaiaDetailsScreen div.details div.bottomContainer div.left div.metadata div.awardsAndFestivals {
  padding: 30px 0px 0;
  background-color: transparent !important;
  align-items: center;
}
div.gaiaDetailsScreen div.details div.bottomContainer div.left div.metadata div.awardsAndFestivals h1.heading {
  font-size: 25px;
  color: var(--text-color);
  margin-bottom: 20px;
}
div.gaiaDetailsScreen div.details div.bottomContainer div.left div.metadata div.awardsAndFestivals ul.lists {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  align-content: center;
}
div.gaiaDetailsScreen div.details div.bottomContainer div.left div.metadata div.awardsAndFestivals ul.lists li.list {
  margin-inline-end: 10px;
  width: 24%;
  margin-bottom: 20px;
  text-align: center;
  align-items: center;
}
div.gaiaDetailsScreen div.details div.bottomContainer div.left div.metadata div.awardsAndFestivals ul.lists li.list:nth-child(4n) {
  margin-inline-end: 0;
}
@media all and (max-width: 1200px) {
  div.gaiaDetailsScreen div.details div.bottomContainer div.left div.metadata div.awardsAndFestivals ul.lists li.list {
    width: 32.6%;
  }
  div.gaiaDetailsScreen div.details div.bottomContainer div.left div.metadata div.awardsAndFestivals ul.lists li.list:nth-child(3n) {
    margin-inline-end: 0;
  }
  div.gaiaDetailsScreen div.details div.bottomContainer div.left div.metadata div.awardsAndFestivals ul.lists li.list:nth-child(4n) {
    margin-inline-end: 10px;
  }
}
@media all and (max-width: 980px) {
  div.gaiaDetailsScreen div.details div.bottomContainer div.left div.metadata div.awardsAndFestivals ul.lists li.list {
    width: 49%;
  }
  div.gaiaDetailsScreen div.details div.bottomContainer div.left div.metadata div.awardsAndFestivals ul.lists li.list:nth-child(3n) {
    margin-inline-end: 10px;
  }
  div.gaiaDetailsScreen div.details div.bottomContainer div.left div.metadata div.awardsAndFestivals ul.lists li.list:nth-child(2n) {
    margin-inline-end: 0;
  }
}
@media all and (max-width: 640px) {
  div.gaiaDetailsScreen div.details div.bottomContainer div.left div.metadata div.awardsAndFestivals ul.lists li.list {
    width: 100%;
    margin-inline-end: 0;
  }
  div.gaiaDetailsScreen div.details div.bottomContainer div.left div.metadata div.awardsAndFestivals ul.lists li.list:nth-child(3n) {
    margin-inline-end: 0;
  }
  div.gaiaDetailsScreen div.details div.bottomContainer div.left div.metadata div.awardsAndFestivals ul.lists li.list:nth-child(2n) {
    margin-inline-end: 0;
  }
}
div.gaiaDetailsScreen div.details div.bottomContainer div.left div.metadata div.awardsAndFestivals ul.lists li.list div.imageContainer {
  margin: 0 auto 20px;
  width: 40px;
  filter: grayscale(100%);
  background: rgb(200, 200, 200);
  margin-bottom: 20px;
  align-items: center;
}
div.gaiaDetailsScreen div.details div.bottomContainer div.left div.metadata div.awardsAndFestivals ul.lists li.list div.imageContainer img {
  display: block;
  width: 100%;
}
div.gaiaDetailsScreen div.details div.bottomContainer div.left div.metadata div.awardsAndFestivals ul.lists li.list h1.title {
  color: var(--text-color);
  font-size: 14px;
  margin-bottom: 10px;
}
div.gaiaDetailsScreen div.details div.bottomContainer div.left div.metadata div.awardsAndFestivals ul.lists li.list div.descriptionAndYear {
  display: flex;
  justify-content: center;
}
div.gaiaDetailsScreen div.details div.bottomContainer div.left div.metadata div.awardsAndFestivals ul.lists li.list div.descriptionAndYear span.year {
  margin-inline-end: 6px;
  font-size: 13px;
  color: var(--grey-color);
  font-weight: normal;
}
div.gaiaDetailsScreen div.details div.bottomContainer div.left div.metadata div.awardsAndFestivals ul.lists li.list div.descriptionAndYear p.description {
  font-size: 13px;
  color: var(--grey-color);
  font-weight: normal;
}
div.gaiaDetailsScreen div.details div.bottomContainer div.left div.metadata div.castAndCrew {
  background-color: transparent !important;
  padding: 30px 0px;
}
@media all and (max-width: 768px) {
  div.gaiaDetailsScreen div.details div.bottomContainer div.left div.metadata div.castAndCrew {
    padding: 30px 30px;
  }
}
div.gaiaDetailsScreen div.details div.bottomContainer div.left div.metadata div.castAndCrew h1.heading {
  font-size: 25px;
  color: var(--text-color);
  margin-bottom: 20p;
}
div.gaiaDetailsScreen div.details div.bottomContainer div.left div.metadata div.castAndCrew ul.lists {
  display: flex;
  flex-wrap: wrap;
}
div.gaiaDetailsScreen div.details div.bottomContainer div.left div.metadata div.castAndCrew ul.lists li.list {
  width: 15%;
  background-color: var(--bg-color);
  margin-inline-end: 7px;
  cursor: pointer;
}
div.gaiaDetailsScreen div.details div.bottomContainer div.left div.metadata div.castAndCrew ul.lists li.list:hover div.info h1.name {
  color: var(--text-hover);
}
div.gaiaDetailsScreen div.details div.bottomContainer div.left div.metadata div.castAndCrew ul.lists li.list:nth-child(8n) {
  margin-inline-end: 0;
}
div.gaiaDetailsScreen div.details div.bottomContainer div.left div.metadata div.castAndCrew ul.lists li.list:last-child {
  margin-inline-end: 0 !important;
}
@media all and (max-width: 1400px) {
  div.gaiaDetailsScreen div.details div.bottomContainer div.left div.metadata div.castAndCrew ul.lists li.list {
    width: 13.7%;
  }
  div.gaiaDetailsScreen div.details div.bottomContainer div.left div.metadata div.castAndCrew ul.lists li.list:nth-child(7n) {
    margin-inline-end: 0;
  }
  div.gaiaDetailsScreen div.details div.bottomContainer div.left div.metadata div.castAndCrew ul.lists li.list:nth-child(8n) {
    margin-inline-end: 7px;
  }
}
@media all and (max-width: 1200px) {
  div.gaiaDetailsScreen div.details div.bottomContainer div.left div.metadata div.castAndCrew ul.lists li.list {
    width: 19%;
  }
  div.gaiaDetailsScreen div.details div.bottomContainer div.left div.metadata div.castAndCrew ul.lists li.list:nth-child(7n) {
    margin-inline-end: 7px;
  }
  div.gaiaDetailsScreen div.details div.bottomContainer div.left div.metadata div.castAndCrew ul.lists li.list:nth-child(6n) {
    margin-inline-end: 0;
  }
}
@media all and (max-width: 980px) {
  div.gaiaDetailsScreen div.details div.bottomContainer div.left div.metadata div.castAndCrew ul.lists li.list {
    width: 24%;
  }
  div.gaiaDetailsScreen div.details div.bottomContainer div.left div.metadata div.castAndCrew ul.lists li.list:nth-child(4n) {
    margin-inline-end: 0;
  }
  div.gaiaDetailsScreen div.details div.bottomContainer div.left div.metadata div.castAndCrew ul.lists li.list:nth-child(6n) {
    margin-inline-end: 7px;
  }
}
@media all and (max-width: 768px) {
  div.gaiaDetailsScreen div.details div.bottomContainer div.left div.metadata div.castAndCrew ul.lists li.list {
    width: 32.5%;
    margin-bottom: 2%;
  }
  div.gaiaDetailsScreen div.details div.bottomContainer div.left div.metadata div.castAndCrew ul.lists li.list:nth-child(4n) {
    margin-inline-end: 7px;
  }
  div.gaiaDetailsScreen div.details div.bottomContainer div.left div.metadata div.castAndCrew ul.lists li.list:nth-child(3n) {
    margin-inline-end: 0px;
  }
}
@media all and (max-width: 640px) {
  div.gaiaDetailsScreen div.details div.bottomContainer div.left div.metadata div.castAndCrew ul.lists li.list {
    width: 32%;
  }
  div.gaiaDetailsScreen div.details div.bottomContainer div.left div.metadata div.castAndCrew ul.lists li.list:nth-child(4n) {
    margin-inline-end: 7px;
  }
  div.gaiaDetailsScreen div.details div.bottomContainer div.left div.metadata div.castAndCrew ul.lists li.list:nth-child(3n) {
    margin-inline-end: 0px;
  }
}
@media all and (max-width: 480px) {
  div.gaiaDetailsScreen div.details div.bottomContainer div.left div.metadata div.castAndCrew ul.lists li.list {
    width: 48%;
  }
  div.gaiaDetailsScreen div.details div.bottomContainer div.left div.metadata div.castAndCrew ul.lists li.list:nth-child(3n) {
    margin-inline-end: 7px;
  }
  div.gaiaDetailsScreen div.details div.bottomContainer div.left div.metadata div.castAndCrew ul.lists li.list:nth-child(2n) {
    margin-inline-end: 0;
  }
}
@media all and (max-width: 360px) {
  div.gaiaDetailsScreen div.details div.bottomContainer div.left div.metadata div.castAndCrew ul.lists li.list {
    width: 99%;
  }
}
div.gaiaDetailsScreen div.details div.bottomContainer div.left div.metadata div.castAndCrew ul.lists li.list div.imageContainer {
  background: rgb(200, 200, 200);
  width: 100%;
}
div.gaiaDetailsScreen div.details div.bottomContainer div.left div.metadata div.castAndCrew ul.lists li.list div.imageContainer img {
  width: 100%;
  display: block;
}
div.gaiaDetailsScreen div.details div.bottomContainer div.left div.metadata div.castAndCrew ul.lists li.list div.info {
  padding: 10px 10px 20px;
  background-color: rgba(203, 203, 208, 0.16);
}
div.gaiaDetailsScreen div.details div.bottomContainer div.left div.metadata div.castAndCrew ul.lists li.list div.info h1.name {
  font-size: 14px;
  color: var(--text-color);
}
div.gaiaDetailsScreen div.details div.bottomContainer div.left div.metadata div.castAndCrew ul.lists li.list div.info p.role {
  font-size: 12px;
  color: var(--grey-color);
}
div.gaiaDetailsScreen div.details div.bottomContainer div.right {
  margin-bottom: 200px;
}
div.gaiaDetailsScreen.rtl .details .topContainer {
  margin-right: 0;
  margin-left: 40px;
}
div.gaiaDetailsScreen.rtl .details .topContainer .imageContainer::before {
  left: auto;
  right: 0;
  background: linear-gradient(to right, transparent, rgba(0, 0, 0, 0.3) 20%, rgba(0, 0, 0, 0.4) 40%, rgba(0, 0, 0, 0.95) 60%, rgb(0, 0, 0) 80%, rgb(0, 0, 0));
}
@media (max-width: 768px) {
  div.gaiaDetailsScreen.rtl .details .topContainer .imageContainer::before {
    width: 100%;
    background: linear-gradient(to bottom, transparent, rgba(0, 0, 0, 0) 20%, rgba(0, 0, 0, 0.9) 40%, rgb(0, 0, 0) 60%, rgb(0, 0, 0) 80%, rgb(0, 0, 0));
  }
}
div.gaiaDetailsScreen.rtl .details .topContainer .imageContainer img {
  float: left;
}
div.gaiaDetailsScreen.rtl .details .topContainer .imageContainer .showMetadata {
  padding-left: 0;
  padding-right: 3%;
}
div.gaiaDetailsScreen.rtl .details .topContainer .imageContainer .showMetadata .categoryName {
  padding-left: 0;
  padding-right: 12px;
  border-right: 3px solid var(--button-color);
  border-left: none;
}
div.gaiaDetailsScreen.rtl .details .topContainer .imageContainer .showMetadata .spanning span {
  padding-right: 0;
  padding-left: 20px;
}
div.gaiaDetailsScreen.rtl .details .bottomContainer .left {
  padding-right: 0;
  padding-left: 3%;
}
@media (max-width: 360px) {
  div.gaiaDetailsScreen.rtl .details .bottomContainer .left .buttons .shareWrapper .shareContainer {
    left: 40%;
  }
}
div.gaiaDetailsScreen.rtl .details .bottomContainer .left .buttons button svg {
  margin-right: 0;
  margin-left: 5px;
}

div.gaiaGenresContainer {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding-left: 4.5%;
  padding-right: 4.5%;
  padding-bottom: 20px;
  padding-top: 20px;
  z-index: 10;
  max-width: 1980px;
  margin: 0 auto;
}
@media ((max-width: 640px) and (min-width: 360px)) {
  div.gaiaGenresContainer {
    padding-top: 40px;
  }
}
div.gaiaGenresContainer .wrapper {
  width: 100%;
}
div.gaiaGenresContainer .wrapper .swiper {
  width: 100%;
}
div.gaiaGenresContainer .wrapper .swiper:hover {
  z-index: 999999;
}
div.gaiaGenresContainer .wrapper .swiper .swiper-slide {
  display: flex;
  justify-content: center;
  align-items: center;
}
div.gaiaGenresContainer .wrapper .swiper .swiper-slide:hover {
  z-index: 99999;
}
div.gaiaGenresContainer .wrapper .swiper .swiper-button-next,
div.gaiaGenresContainer .wrapper .swiper .swiper-button-prev {
  height: 100%;
  color: white;
  width: 5% !important;
  background: var(--bg-color);
}
div.gaiaGenresContainer .wrapper .swiper .swiper-button-next .svg,
div.gaiaGenresContainer .wrapper .swiper .swiper-button-prev .svg {
  height: 60px;
  align-items: center;
  color: white;
}
div.gaiaGenresContainer .wrapper .swiper .swiper-button-next::after,
div.gaiaGenresContainer .wrapper .swiper .swiper-button-prev::after {
  font-size: 18px;
  font-weight: bolder;
  color: var(--text-color);
  position: absolute;
  z-index: 10;
  box-shadow: 0 6px 20px rgba(0, 0, 0, 0.2);
}
div.gaiaGenresContainer .wrapper .swiper .swiper-button-next::after:hover,
div.gaiaGenresContainer .wrapper .swiper .swiper-button-prev::after:hover {
  background-color: var(--button-hover);
  transform: scale(1.3);
}
div.gaiaGenresContainer .wrapper .swiper .swiper-button-next {
  right: -5% !important;
}
div.gaiaGenresContainer .wrapper .swiper .swiper-button-next::after {
  left: -21%;
}
div.gaiaGenresContainer .wrapper .swiper .swiper-button-prev {
  left: -5% !important;
}
div.gaiaGenresContainer .wrapper .swiper .swiper-button-prev::after {
  right: -27%;
}
div.gaiaGenresContainer .wrapper .swiper .swiper-button-disabled {
  opacity: 0;
  pointer-events: none;
}
div.gaiaGenresContainer .wrapper .swiper.items .swiper-button-next, div.gaiaGenresContainer .wrapper .swiper.items .swiper-button-prev {
  margin-top: -35px;
  height: 110%;
}
@media (max-width: 480px) {
  div.gaiaGenresContainer .wrapper .swiper.items .swiper-button-next, div.gaiaGenresContainer .wrapper .swiper.items .swiper-button-prev {
    margin-top: -60px;
  }
}
@media (max-width: 2600px) {
  div.gaiaGenresContainer .wrapper .swiper.items .swiper-button-next::after, div.gaiaGenresContainer .wrapper .swiper.items .swiper-button-prev::after {
    top: 37%;
  }
}
@media (max-width: 980px) {
  div.gaiaGenresContainer .wrapper .swiper.items .swiper-button-next::after, div.gaiaGenresContainer .wrapper .swiper.items .swiper-button-prev::after {
    top: 38% !important;
  }
}
@media (max-width: 768px) {
  div.gaiaGenresContainer .wrapper .swiper.items .swiper-button-next::after, div.gaiaGenresContainer .wrapper .swiper.items .swiper-button-prev::after {
    top: 39% !important;
  }
}
@media (max-width: 480px) {
  div.gaiaGenresContainer .wrapper .swiper.items .swiper-button-next::after, div.gaiaGenresContainer .wrapper .swiper.items .swiper-button-prev::after {
    top: 47% !important;
  }
}
@media (max-width: 360px) {
  div.gaiaGenresContainer .wrapper .swiper.items .swiper-button-next::after, div.gaiaGenresContainer .wrapper .swiper.items .swiper-button-prev::after {
    top: 43% !important;
  }
}
@media (max-width: 2600px) {
  div.gaiaGenresContainer .wrapper .swiper.items .swiper-button-next {
    padding-right: 35%;
    right: -35% !important;
  }
}
@media (max-width: 2600px) {
  div.gaiaGenresContainer .wrapper .swiper.items .swiper-button-next::after {
    left: -4% !important;
  }
}
@media (max-width: 480px) {
  div.gaiaGenresContainer .wrapper .swiper.items .swiper-button-next::after {
    left: -5% !important;
  }
}
@media (max-width: 360px) {
  div.gaiaGenresContainer .wrapper .swiper.items .swiper-button-next::after {
    left: -11% !important;
  }
}
@media (max-width: 2600px) {
  div.gaiaGenresContainer .wrapper .swiper.items .swiper-button-prev {
    padding-left: 35%;
    left: -35% !important;
  }
}
@media (max-width: 2600px) {
  div.gaiaGenresContainer .wrapper .swiper.items .swiper-button-prev::after {
    right: -4% !important;
  }
}
@media (max-width: 480px) {
  div.gaiaGenresContainer .wrapper .swiper.items .swiper-button-prev::after {
    right: -5% !important;
  }
}
@media (max-width: 360px) {
  div.gaiaGenresContainer .wrapper .swiper.items .swiper-button-prev::after {
    right: -12% !important;
  }
}
div.gaiaGenresContainer div.header {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 30px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
div.gaiaGenresContainer div.header h1 {
  font-size: 28px;
  color: var(--text-color);
}
@media (max-width: 768px) {
  div.gaiaGenresContainer div.header h1 {
    font-size: 22px;
  }
}
div.gaiaGenresContainer div.category-name {
  cursor: pointer;
  transition: transform 0.3s ease;
  position: relative;
  width: 75%;
}
div.gaiaGenresContainer div.category-name:hover {
  transform: scale(1.1);
}
div.gaiaGenresContainer div.category-name div.image-container {
  width: 100%;
  height: 0;
  padding-bottom: 100%;
  position: relative;
  overflow: hidden;
  border-radius: 50%;
}
div.gaiaGenresContainer div.category-name div.image-container img {
  position: absolute;
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
}
div.gaiaGenresContainer div.category-name div.image-container.placeholder {
  background-color: var(--button-color);
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  position: relative;
  border-radius: 50%;
}
div.gaiaGenresContainer div.category-name .placeholder-text {
  position: absolute;
  top: 45%;
  color: var(--white-color);
  font-size: 16px;
  text-align: center;
}
div.gaiaGenresContainer div.category-name div.genre-name {
  color: var(--text-color);
  font-size: 16px;
  margin-top: 20px;
  text-align: center;
}
div.gaiaGenresContainer div.category-name div.genre-name.gaia {
  font-family: "Avenir-Black", sans-serif;
}
div.gaiaGenresContainer div.category-name div.genre-name.template_9 {
  font-family: "Gilroy-Medium";
  font-weight: bold;
}
div.gaiaGenresContainer div.category-name div.genre-name:hover {
  color: var(--button-hover);
}

div.gaiaGenresContainer.ltr {
  direction: ltr;
  text-align: left;
}
div.gaiaGenresContainer.ltr .header {
  direction: rtl;
  text-align: right;
}
div.gaiaGenresContainer.ltr .category-name {
  direction: rtl;
  text-align: right;
}

div.gaiaGenresContainer.rtl {
  direction: rtl;
  text-align: right;
}

div.gaiaGenresContainer.template-9 {
  border-bottom: 1px solid var(--border-color);
  padding-bottom: 75px;
  padding-left: unset;
  padding-right: unset;
  max-width: none;
}
div.gaiaGenresContainer.template-9 .header h1 {
  font-size: 24px;
  font-family: "Gilroy-Medium";
}
div.gaiaGenresContainer.template-9 .wrapper {
  width: 92%;
}
@media (max-width: 980px) {
  div.gaiaGenresContainer.template-9 .wrapper {
    width: 85%;
  }
}

div.downloadContainer div.overlayModal {
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.7);
  z-index: 9999;
  display: flex;
  justify-content: center;
  align-items: center;
}
div.downloadContainer div.contents {
  z-index: 9999;
  text-align: center;
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  background-color: var(--bg-color);
  width: 40%;
  margin: 0 auto;
  padding: 40px 40px 40px;
  border-radius: 6px;
  display: flex;
  flex-direction: column;
}
@media all and (max-width: 1200px) {
  div.downloadContainer div.contents {
    width: 95%;
    padding-top: 20px;
    padding-bottom: 20px;
  }
}
@media all and (max-width: 768px) {
  div.downloadContainer div.contents {
    width: 95%;
    padding-top: 10px;
    padding-bottom: 10px;
  }
}
@media all and (max-width: 480px) {
  div.downloadContainer div.contents {
    padding-top: 5px;
    padding-bottom: 5px;
  }
}
div.downloadContainer div.contents div.closeIconContainer {
  position: fixed;
  top: 20px;
  inset-inline-end: 30px;
  z-index: 9999;
  cursor: pointer;
}
div.downloadContainer div.contents div.closeIconContainer svg {
  width: 25px !important;
  height: 25px !important;
  stroke: var(--text-color) !important;
}
div.downloadContainer div.contents h1 {
  color: var(--error-color);
  font-size: 15px;
  text-align: center;
}
@media (max-width: 480px) {
  div.downloadContainer div.contents h1 {
    font-size: 12px;
  }
}
div.downloadContainer div.contents h2 {
  color: var(--inner-text-color);
  font-size: 13px;
  text-align: start;
}
@media (max-width: 480px) {
  div.downloadContainer div.contents h2 {
    font-size: 10px;
  }
}
div.downloadContainer div.contents p {
  color: var(--inner-text-color);
  font-size: 12px;
  text-align: start;
}
@media (max-width: 480px) {
  div.downloadContainer div.contents p {
    font-size: 9px;
  }
}
div.downloadContainer div.contents a.download-button {
  margin-top: 25px;
  padding: 10px 20px;
  background-color: var(--button-color);
  color: white;
  border: none;
  border-radius: 4px;
  font-size: 16px;
  cursor: pointer;
  transition: opacity 0.3s ease;
  align-self: center;
}
@media (max-width: 480px) {
  div.downloadContainer div.contents a.download-button {
    font-size: 12px;
  }
}
div.downloadContainer div.contents a.download-button:hover {
  background-color: var(--button-hover);
}
div.downloadContainer div.contents a.download-button:active {
  opacity: 0.8;
}

:root {
  --grey-color:#9699a3;
  --text-hover:#188fcb;
  --bg-color:#10141f;
  --secondary-color:#188fcb;
  --secondary-bg-color:#1c1f29;
  --overlay:rgba(16,20,31,.64);
  --text-color:#fff;
  --white-color:#fff;
  --border-color:rgba(150,153,163,.16);
  --inner-text-color:#9699a3;
  --success-color:#4BB543;
  --error-color:#E72A31;
  --yellow-color:#f8d406;
  --black-color:#000;
  --button-hover:#188fcb;
  --button-color:#188fcb;
  --custom-header-bg-color:#1151d3;
  --custom-top-banner-bg-color:#202f5b;
  --button-text-color:#000;
  --gradient-left-color:#01c599;
  --gradient-right-color:#0388cc;
  --swiper-button-color:rgb(16, 20, 31);
}

.wrapper {
  width: 92%;
  margin: 0 auto;
}
@media all and (max-width: 980px) {
  .wrapper {
    width: 85%;
  }
}

.swiper-button-next.swiper-button-disabled {
  display: none !important;
}

.swiper-button-prev.swiper-button-disabled {
  display: none !important;
}

.swiper-button-next {
  width: 45px !important;
}
.swiper-button-next::after {
  background: var(--swiper-button-color);
  border-radius: 50%;
  font-size: 16px;
  width: 45px;
  height: 45px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--inner-text-color);
}
@media all and (min-width: 320px) {
  .swiper-button-next::after {
    width: 25px;
    height: 25px;
    font-size: 12px !important;
  }
}
@media all and (min-width: 1200px) {
  .swiper-button-next::after {
    font-size: 16px !important;
    width: 45px;
    height: 45px;
  }
}

.swiper-button-prev {
  width: 45px !important;
}
.swiper-button-prev::after {
  background: var(--swiper-button-color);
  border-radius: 50%;
  font-size: 16px;
  width: 45px;
  height: 45px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--inner-text-color);
}
@media all and (min-width: 320px) {
  .swiper-button-prev::after {
    width: 25px;
    height: 25px;
    font-size: 12px !important;
  }
}
@media all and (min-width: 1200px) {
  .swiper-button-prev::after {
    font-size: 16px !important;
    width: 45px;
    height: 45px;
  }
}

span.error {
  font-size: 14px;
  color: #E72A31;
  display: block;
  margin-top: 10px;
  font-weight: normal !important;
}

div.timelineDate {
  color: var(--button-color);
  font-size: 13px;
  position: relative;
  right: 10px;
}

.css-czcuoc-TimelineDividers {
  position: relative !important;
}
.css-czcuoc-TimelineDividers span.currentTime {
  position: absolute;
  bottom: 20px;
  background-color: var(--button-color);
  color: var(--white-color);
  font-size: 12px;
  padding: 3px;
  border-radius: 5px;
  width: 4rem;
}
.css-czcuoc-TimelineDividers span.currentTime::before {
  height: 30rem;
  content: " ";
  width: 2px;
  background-color: var(--button-color);
  display: block;
  position: absolute;
  top: 20px;
  bottom: 0;
  left: 50%;
}

div#main {
  position: relative;
}

div.customHeader div#main div.header-container {
  position: sticky !important;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes fadeOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}/*# sourceMappingURL=app.css.map */