div.billingAndSubscription { 
  padding: 130px 0;
  width: 80%;
  margin: 0 auto;
    @media all and (max-width:1200px){
        width: 100%;
    }
	div.wrapper { 

        h1.heading { 
            font-size: 24px;
            color: var(--text-color);
            margin-bottom: 30px;
        }
		div.billingDetails { 

            margin-bottom: 30px;
			h6.subHeading { 
                font-size: 14px;
                color: var(--inner-text-color);
                font-weight: normal;
                letter-spacing: .3px;
                margin-bottom: 20px;
			}

			div.billingDetailsContainer { 
                border: 3px solid var(--border-color);
                padding: 20px;
                display: flex;
                justify-content: space-between;

				span.head { 
                    display: block;
                    color: var(--inner-text-color);
                    margin-bottom: 10px;
				}

				span.value { 
                    display: block;
                    color: var(--text-color);
                    font-size: 14px;
                    &.bold{
                        font-weight: 600;
                        margin-bottom: 20px;
                    }
				}

                .billingButtonContainer{
                        display: flex;
                    padding: 18px 20px;
                    border-radius: 4px;
                    align-items: center;
                    margin-bottom: 15px;
                    cursor: pointer;
                    transition: 0.2s ease-in-out;
                    justify-content: center;
                    position: relative;
                    max-width: 20rem;
                    margin: 30px auto 0;

                    &:hover{
                        div.background{
        
                            transform: scale(1.0476190476,1.0357142857);
                        }
                    }
                        div.background{
                            background: var(--text-hover);
                            position: absolute;
                            top: 0;
                            left: 0;
                            right: 0;
                            bottom: 0;
                            border-radius: 4px;
                            transition: 0.1s ease-in-out;
                            z-index: 1;
        
                        }
                        button { 
                            background: transparent;
                            font-size: 15px;
                            color: var( --text-color);
                            font-weight: 600;
                            position: relative;
                            z-index: 2;
                            cursor: pointer;
        
        
                        }
                    }
                    @media all and (max-width:768px){
                        flex-direction: column;
                    }
			}
		}

		div.subscriptionDetails { 

			

			ul.subscriptionContainer { 

				li.head { 
                    display: flex;
                    color: var(--inner-text-color);
                    font-size: 14px;
                    @media all and (max-width:768px){
                        display: none;
                    }
					
				}
                li{
                    border-bottom: 1px solid var(--border-color);
                    display: flex;
                    padding: 6px;
                    color: var(--text-color);
                    font-size: 14px;
                    
                    
                    div.left { 
                        display: flex;
                        width: 80%;
                        @media all and (max-width:1200px){
                            width: 100%;
                        }
                        @media all and (max-width:768px){
                            flex-direction: column;
                        }
						div.date { 
                            width: 17%;
                            @media all and (max-width:768px){
                                width: 100%;
                                margin-bottom: 10px;
                            }
						}

						div.transactionType { 
                            width: 25%;
                            @media all and (max-width:768px){
                                width: 100%;
                            }
                            
						}

						div.servicePeriod { 
                            width: 25%;
                            @media all and (max-width:768px){
                                width: 100%;
                                margin-bottom: 10px;
                            }
						}

						div.paymentMethod { 
                            width: 25%;
                            @media all and (max-width:768px){
                                width: 100%;
                                margin-bottom: 10px;
                            }
						}

						div.subscriptionName { 
                            width: 25%;
                            @media all and (max-width:980px){
                                width: 30%;
                            }
                            @media all and (max-width:768px){
                                width: 100%;
                            }
                            
						}
					}

					div.right { 
                        width: 20%;
                        @media all and (max-width:980px){
                            width: 8%;
                        }
                        text-align: right;
						div.total { 

						}
					}
                }

				li.body { 

					div.left { 

						div.date { 

						}

						div.transactionType { 

						}

						div.servicePeriod { 

						}

						div.paymentMethod { 

						}

						div.subscriptionName { 

						}
					}

					div.right { 

						div.total { 

						}
					}
				}
			}
		}

		div.bottom { 
            margin-top: 30px;
			p { 
                color: var(--inner-text-color);
                font-size: 12px;
			}

			div.buttonContainer { 
                display: flex;
            padding: 18px 20px;
            border-radius: 4px;
            align-items: center;
            margin-bottom: 15px;
            cursor: pointer;
            transition: 0.2s ease-in-out;
            justify-content: center;
            position: relative;
            width: 20rem;
            margin: 30px auto 0;
            @media all and (max-width:640px){
                width: 15rem;
            }
            @media all and (max-width:480px){
                width: 10rem;
            }
            &:hover{
                div.background{

                    transform: scale(1.0476190476,1.0357142857);
                }
            }
                div.background{
                    background: var(--text-hover);
                    position: absolute;
                    top: 0;
                    left: 0;
                    right: 0;
                    bottom: 0;
                    border-radius: 4px;
                    transition: 0.1s ease-in-out;
                    z-index: 1;

                }
                button { 
                    background: transparent;
                    font-size: 15px;
                    color: var( --text-color);
                    font-weight: 600;
                    position: relative;
                    z-index: 2;
                    cursor: pointer;


                }
            }
		}
	}
}