header.accountsPageHeader{
    height: 100px;
    position: fixed;
        top: 0;
        left: 50%;
        transform: translateX(-50%);
        display: flex;
    align-items: center;
    width: 95%;
    justify-content: space-between;
    margin: 0 auto;
    
        
        div.left{
            div.logoContainer{
                h1{
                    width: 120px;
                    @media all and (max-width:480px){
                      width: 72px;
                    }
                    @media all and (max-width:360px){
                      width: 72px;
                    }
                    img {
                      display: block;
                      width: 100%;
                    }
                }
            }
        }
        div.right{
            button{
                background: transparent;
                color: var(--text-color);
                font-size: 15px;
                font-weight: 600;
                cursor: pointer;
            }
        }
}