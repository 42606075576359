div.gaia-header-container {
    background: linear-gradient(
      180deg,
      rgba(16, 20, 31, 0.8) 0.98%,
      rgba(16, 20, 31, 0.4) 59.23%,
      rgba(16, 20, 31, 0.2) 78.16%,
      rgba(16, 20, 31, 0.0001) 96.12%
    );
    // font-family: "Avenir-Roman",sans-serif;
    padding: 25px 0;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 60px;
    z-index: 99;
    position: absolute;
  
    div.wrapper {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 90%;
      div.leftContainer {
        display: flex;
        align-items: center;
        margin-inline-start: -30px;
    
        @media (max-width: 768px){
          margin-inline-start: -20px;
        }
        @media (max-width: 640px){
          margin-inline-start:-10px;
        }
        @media (max-width: 480px){
          margin-inline-start:-5px;
        }
        @media (max-width: 360px){
          margin-inline-start:0px;
        }
        div.menuIconContainer {
          display: none;
          cursor: pointer;
          margin-inline-end: 25px;
            
          @media screen and (max-width: 1200px) {
            display: inline-block;
            width: 24px;
            height: 24px;
            fill: white;
            
          }
        }
        div.logoContainer {
          h1 {
            width: 100px;
            @media all and (max-width: 480px) {
              width: 75px;
            }
            @media all and (max-width: 360px) {
              width: 75px;
            }
            img {
              display: block;
              width: 100%;
              object-fit: contain;
              aspect-ratio: 2;
            }
          }
        }
        ul.hide {
          @media all and (max-width: 1200px) {
            &.hide {
              display: none;
            }
          }
        }
        ul.browse {
          display: flex;
          align-items: center;
          margin-inline-start: 80px;
          li {
            cursor: pointer;
            margin-inline-end: 25px;
            // font-family: 'Avenir-Roman', sans-serif;
            font-size: 16px;
            color: white;
            padding: 15px 0;
            position: relative;
            text-decoration: none;
            z-index: 100;
            transition: color 0.3s ease, font-weight 0.3s ease;
            &:hover {
              color: var(--text-hover);
            }
    
            a {
              color: var(--text-color);
              text-decoration: none;
              font-size: 16px;
              padding: 15px 0;
              transition: color 0.3s ease, font-weight 0.3s ease;
              @media (max-width: 1070px){
                font-size: 12px;
              }
  
              &.selected {
                // font-family: 'Avenir-Black', sans-serif;
                font-weight: bold;
              }
            }
          }
          li.mainMenu {
            cursor: pointer;
            padding: 0 15px;
  
            &:nth-child(2){
              padding: 0 15px 0 0px;
            }
  
            &:nth-last-child(2){
              padding: 0 0px 0 15px;
            }
            
            &.active {
              padding-bottom: 60px;
              margin-top: 60px;
              span {
                color: var(--text-hover);
              }
            }
            span {
              font-size: 14px;
              font-weight: 900;
              transition: 0.3s ease-in-out;
              color: #fff;
            }
          }
          @media screen and (max-width: 1200px) {
            display: none;
          }     
        }
      }
  
      div.rightContainer {
        display: flex;
        align-items: center;
        margin-inline-end: -20px;
        gap: 30px;
        @media (max-width: 768px){
          margin-inline-end: -20px;
        }
        @media (max-width: 640px){
          margin-inline-end:-10px;
        }
        @media (max-width: 480px){
          margin-inline-end:-5px;
        }
        @media (max-width: 360px){
          margin-inline-end:0px;
        }
        .searchIcon{
          width: 25px;
          height: 25px;
          color: var(--white-color);
          cursor: pointer;
          @media (max-width: 1200px){
            display: none;
          }
        }
        div.languageDropdown {
          position: relative;
          cursor: pointer;
          display: inline-block;
          font-size: 16px;
          color: white;
          padding: 15px 0;
          // transition: color 0.3s ease, font-weight 0.3s ease;
          @media(max-width: 1200px){
            display: none;
          }
        
          &:hover {
            color: var(--text-hover);
          }
        
          div.language {
            font-size: 16px;
            font-weight: normal;
            transition: color 0.3s ease, font-weight 0.3s ease;
          }
        
          div.languageOptions {
            position: absolute;
            top: 100%;
            inset-inline-start: 0;
            min-width: 210px;
            background-color: var(--bg-color);
            padding-top: 15px;
            padding-bottom: 10px;
            box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
            z-index: 100;

            &::before {
              content: "";
              position: absolute;
              top: 0;
              inset-inline-start: 0;
              width: 100%;
              height: 4px; 
              background: linear-gradient(281deg, var(--gradient-right-color), var(--gradient-left-color));
            }
        
            div.optionsList {
              display: flex;
              flex-direction: column;
        
              div.languageOption {
                font-size: 16px;
                padding: 8px 12px;
                color: var(--text-color);
                cursor: pointer;
                display: flex;
                justify-content: space-between;
                align-items: center;
                white-space: nowrap;

                &[data-selected="true"] {
                  color: var(--gradient-left-color);
                  
                  &:after {
                    content: "✔";
                    margin-inline-start: auto;
                  }
                }
        
                &:hover {
                  color: var(--gradient-left-color);
                }
              }
            }
          }
        }
        
        div.userInfo {
          display: flex;
          align-items: center;
          cursor: pointer;
          // padding: 0 40px;
          // @media all and (max-width: 360px) {
          //   padding: 0 21px;
          // }
          &.active {
            svg {
              transform: rotate(-180deg);
            }
          }
          h4 {
            color: var(--text-color);
            font-size: 14px;
            margin-inline-start: 5px;
            margin-top: 5px;
  
            &.active {
              padding-top: 60px;
              padding-bottom: 60px;
            }
  
            div.profileImageContainer{
              // font-size: 12px;
              img{
                width: 40px;
                height: 40px;
                border-radius: 50%;
                object-fit: cover;
              }
            }
            span.nameAvatar{
              width: 30px;
              height: 30px;
              display: flex;
              justify-content: center;
              align-items: center;
              color: var(--white-color);
              background-color: var(--grey-color);
              border-radius: 50%;
            }
          }
          svg {
            color: var(--text-color) !important;
            width: 22px !important;
            height: 22px !important;
            transition: 0.3s ease-in-out;
          }
        }
  
        div.buttonContainer {
          display: flex;
          // margin-inline-start: 30px;
          @media all and (max-width: 980px) {
            margin-inline-start: 18px;
          }
          @media all and (max-width: 480px) {
            margin-inline-start: 15px;
          }
          @media all and (max-width: 360px) {
            margin-inline-start: 7px;
          }
          button.register {
            background-color: #fff;
            padding: 10px 0;
            width: 6rem;
            border-radius: 30px;
            font-size: 14px;
            font-weight: 700;
            margin-inline-end: 30px;
            cursor: pointer;
            // font-family: "Avenir-Medium",sans-serif;
            @media all and (max-width: 768px) {
              padding: 11px 0;
              margin-inline-end: 18px;
            }
            @media all and (max-width: 360px) {
              padding: 8px 0;
              width: 5rem;
              font-size: 12px;
              margin-inline-end: 10px;
            }
          }
  
          button.signIn {
            background-color: transparent;
            font-weight: 700;
            font-size: 14px;
            // color: #fff;
            color: white;
            cursor: pointer;
            // font-family: "Avenir-Medium",sans-serif;
            @media all and (max-width: 360px) {
              font-size: 12px;
            }
          }
        }
      }
    }
  }
  div.userInfoContainerGaia {
    // position: absolute;
    // right: 30px;
    // width: 20rem;
    // top: 50px;
    // background-color: rgb(37, 42, 52);
    // border-radius: 8px;
    box-shadow: 0 0 20px rgba(0, 0, 0, .2);
    border: 1px solid #f2f2f2;
    background-color: #f2f2f2;
    width: 310px;
    position: absolute;
    // top: 60px;
    // right: 80px;
    top: 60px;
    inset-inline-end: 3%;
    padding: 20px;
  
    display: none;
    z-index: 9999;
    &.active {
      display: block;
    }
    &::before {
      content: '';
      position: absolute;
      top: 0;
      inset-inline-start: 0;
      width: 100%;
      height: 125px;
      background-color: #fff; 
      z-index: -1;/* Top part with white background */
      border-top-left-radius: 8px;
      border-top-right-radius: 8px;
    }
    &::after {
      content: '';
      position: absolute;
      top: -10px; 
      inset-inline-start: 85%; 
      transform: translateX(-50%);
      width: 0;
      height: 0;
      border-inline-start: 10px solid transparent;
      border-inline-end: 10px solid transparent;
      border-bottom: 10px solid #fff;
    }
    div.top {
      padding: 10px 20px 0;
  
      ul {
        a {
          li {
            padding: 15px 0;
            cursor: pointer;
            // font-family: "Avenir-Roman",sans-serif;
  
            &.user{
              display: flex; 
              align-items: center; 
              gap: 10px;
            }
            
            div.profileImageContainer{
              img{
                width: 40px;
                height: 40px;
                border-radius: 50%;
                object-fit: cover;
              }
            }
             div.nameAvatar{
              font-size: 12px;
              img{
                width: 30px;
              }
            }
            span.nameAvatar{
              width: 30px;
              height: 30px;
              display: flex;
              justify-content: center;
              align-items: center;
              color: var(--white-color);
              background-color: var(--grey-color);
              border-radius: 50%;
              margin-top: 20px;
            }
              span.userName{
                padding-top: 20px;
                // color:#5c738a;
                color: var(--inner-text-color);
                font-family: "Avenir-Black", sans-serif;
                font-weight: 900;
                font-size: 12px;
              }
  
            &:first-child {
              padding-top: 0;
            }
            &:last-child {
              border-bottom: none;
            }
            span {
              margin-bottom: 20px;
              font-size: 14px;
              text-transform: none;
              color: var(--inner-text-color);
              transition: color 0.3s ease, font-weight 0.3s ease;
  
              &:hover{
                color: var(--text-hover);
              }
            }
          }
          .account{
            margin-top: -10px;
            margin-bottom: 20px;
          }
          &:last-child {
            border: none;
          }
        }
      }
    }
  }
  
  div.browseMoreGaia {
    @media all and (max-width: 1200px) {
      display: none;
    }
    display: none;
  
    .browseMoreContainerNew {
      position: absolute;
      top: 50px;
      inset-inline-start: 10%;
      background-color: var(--bg-color);
      border-radius: 6px;
      z-index: 999;
      max-height: 500px;
      overflow-y: auto; 
      // min-width: 200px;
      width: 300px;
      padding-bottom: 10px;
      border: 1px solid var(--bg-color);
      box-shadow: 0px 0px 4px 2px rgba(0, 0, 0, 0.2);
        &::-webkit-scrollbar {
          width: 0;
          background: transparent;
        }
        scrollbar-width: none; 
        -ms-overflow-style: none;
      animation: fadeIn 0.7s ease-in-out;
    
      h1.categoryName {
        font-size: 14px;
        color: var(--grey-color);
        margin: 3% 2%;
        padding-bottom: 10px;
      }
    
      li {
        padding-inline-start: 0;
    
        li {
          position: relative;
          // margin-bottom: 5px;
          height: 40px;
          cursor: pointer;
          overflow: hidden;
          transition: transform 0.3s ease-in-out;
    
          &:hover {
            transform: scale(1.05);
    
            span {
              color: var(--text-color); 
              top: 9px;
              inset-inline-start: 10px;
            }
    
            .shadeGradient {
              opacity: 0.8; 
            }
            &::after {
              opacity: 1; 
            }
          }
    
          span {
            font-size: 14px;
            color: var(--text-color);
            z-index: 2; 
            position: relative; 
            transition: color 0.3s ease-in-out;
            margin-top: 3%;
            margin-inline-start: 2%;
          }
    
          .shadeGradient {
            position: absolute;
            top: 0;
            inset-inline-end: 0;
            width: 100%;
            height: 100%;
            background: linear-gradient(270deg, rgba(var(--buttonColorRgb), 0) 0%, var(--buttonColorRgb) 100%);
            opacity: 0;
            pointer-events: none;
            transition: opacity 0.3s ease-in-out;
            z-index: 1;
          }
  
  
        &::after {
          content: "";
          position: absolute;
          top: 0;
          inset-inline-start: 0;
          width: 2px;
          height: 100%;
          background-color:var(--buttonSelection); 
          opacity: 0;
          transition: opacity 0.3s ease-in-out;
          z-index: 3; 
        }
        }
      }
    
      div.categoryContainer {
        padding: 15px 25px 0;
      }
    }
  }
  div.browseMoreGaia.active {
    display: block;
    @media all and (max-width: 1200px) {
      display: none;
    }
  }
  
  div.browseMoreResponsiveGaia {
    display: none;
    @media(max-width: 1200px){
      display: block;
    }
    div.container {
      position: absolute;
      inset-inline-start: -100%;
      width: 320px;
      background-color: var(--bg-color);
      padding: 15px 20px;
      z-index: 9999;
      margin-top: 60px;
      overflow: scroll;
      transition: 0.5s ease-in-out;
      // border: 1px solid rgb(179, 176, 176);
      border: 1px solid var(--bg-color);
      box-shadow: 0px 0px 4px 2px rgba(0, 0, 0, 0.2);
      &::-webkit-scrollbar {
        display: none;
      }
      -ms-overflow-style: none; /* IE and Edge */
      scrollbar-width: none; /* Firefox */
      @media all and (max-width: 1200px) {
        // &.active {
          inset-inline-start: 0;
        // }
      }
  
      @media all and (max-width: 480px) {
        width: 100%;
      }
  
      div.inputContainer2{
        display: flex;
        align-items: center;
        background-color: var(--black-color);
        // background-color: rgb(233, 233, 233);
        transition: border-color .5s;
        border-radius: 60px;
        color: white;
        // color: var(--text-color);
        padding: 10px;
        cursor: pointer;
        margin-inline-start: -10px;
        margin-bottom: 20px;
  
        &:hover{
          border: 1px solid var(--grey-color);
        }
        input {
          background-color: transparent;
          border: none;
          // color: white;
          color: var(--text-color);
          margin-inline-start: 10px;
          outline: none;
          width: 250px;
          font-size: 14px;
          font-family: 'Avenir-Roman',sans-serif;
  
          &::placeholder {
            // color: white;
            color: var(--grey-color);
          }
        }
  
        svg {
          margin-inline-start: 2px;
          fill: var(--black-color);
          width: 20px;
          height: 20px;
        }
      }
  
      div.head {
        display: flex;
        justify-content: space-between;
        align-items: center;
  
        div.left {
          h1 {
            // font-family: 'Avenir-Black',sans-serif;
            color: var(--text-color);
            font-size: 16px;
          }
        }
        div.right {
          svg {
            color: #fff;
            width: 23px !important;
            height: 23px !important;
          }
        }
      }
      div.top {
        margin-top: 20px;
  
        h1.categoryName {
          font-size: 16px;
          margin-bottom: 20px;
          color: var(--text-hover);
          
        }
        ul {
          li {
            margin-bottom: 15px;
            span {
              font-size: 16px;
              color: var(--text-color);
              cursor: pointer;
  
              &:hover{
                color: var(--text-hover);
              }
            }
          }
        }
      }
      div.center {
        padding: 30px 0;
        h1.categoryName {
          font-size: 16px;
          margin-bottom: 20px;
          color: var(--text-color);
        }
        ul {
          li {
            margin-bottom: 15px;
            span {
              font-size: 16px;
              color: var(--text-color);
              cursor: pointer;
  
              &:hover{
                color: var(--text-hover);
              }
            }
          }
        }
      }
      div.bottom {
        padding: 30px 0;
        h1.categoryName {
          font-size: 16px;
          margin-bottom: 20px;
          color: var(--text-color);
        }
        ul {
          li {
            margin-bottom: 15px;
            span {
              font-size: 16px;
              color: var(--text-color);
              cursor: pointer;
  
              &:hover{
                color: var(--text-hover);
              }
            }
          }
        }
      }
      div.languageDropdown {
        position: relative;
        cursor: pointer;
        display: inline-block;
        font-size: 16px;
        color: white;
        padding: 15px 0;
        // transition: color 0.3s ease, font-weight 0.3s ease;
      
        &:hover {
          color: var(--text-hover);
        }
      
        h1.language {
          font-size: 16px;
          font-weight: bold;
          transition: color 0.3s ease, font-weight 0.3s ease;
        }
      
        div.languageOptions {
          position: absolute;
          top: 100%;
          inset-inline-start: 0;
          min-width: 210px;
          background-color: var(--bg-color);
          padding-top: 15px;
          padding-bottom: 10px;
          box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
          z-index: 100;

          &::before {
            content: "";
            position: absolute;
            top: 0;
            inset-inline-start: 0;
            width: 100%;
            height: 4px; 
            background: linear-gradient(281deg, var(--gradient-right-color), var(--gradient-left-color));
          }
      
          div.optionsList {
            display: flex;
            flex-direction: column;
      
            div.languageOption {
              font-size: 16px;
              padding: 8px 12px;
              color: var(--text-color);
              cursor: pointer;
              display: flex;
              justify-content: space-between;
              align-items: center;
              white-space: nowrap;

              &[data-selected="true"] {
                color: var(--gradient-left-color);
                
                &:after {
                  content: "✔";
                  margin-inline-start: auto;
                }
              }
      
              &:hover {
                color: var(--gradient-left-color);
              }
            }
          }
        }
      }
    }
  }
  
  @keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
  @keyframes fadeOut {
    0% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
  }
  .searchModal {
    position: fixed;
    top: 0;
    inset-inline-start: 0;
    width: 100%;
    height: 90px;
    // background-color: var(--bg-color);
    // background-color: #181D25;
    background-color: #1F252E;
    gap: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9999;

    @media(max-width: 1200px){
      display: none;
    }

    div.overlay {
      position: fixed;
      top: 0;
      bottom: 0;
      right: 0;
      left: 0;
      background-color: var(--overlay);
      z-index: 1;
      display: flex;
      justify-content: center;
      align-items: center;
      animation: fadeIn 0.3s ease-in-out;
    }

    .modalContent {
      position: relative;
      display: flex;
      align-items: center;
      // background-color: #181D25;
      background-color: #1F252E;
      transition: border-color .5s;
      border-radius: 60px;
      color: white;
      padding: 10px;
      cursor: pointer;
      width: 87%;
      height: 70%;
      z-index: 2;

      // &:hover{
      //   border: 1px solid var(--grey-color);
      // }
      input {
        background-color: transparent;
        border: none;
        color: var(--white-color);
        margin-inline-start: 10px;
        outline: none;
        width: 100%;
        font-size: 20px;
        font-family: 'Avenir-Roman',sans-serif;

        &::placeholder {
          color: var(--grey-color);
        }
      }

      svg {
        margin-inline-start: 10px;
        fill: var(--black-color);
        width: 25px;
        height: 25px;
      }
    }
    .cancelButton {
      color: white;
      cursor: pointer;
      font-size: 20px;
      z-index: 2;
    }
  }

  // div.gaia-header-container.rtl {
  //   .wrapper {
  //     .leftContainer {
  //       margin-left: 0;
  //       margin-right: -30px;
  //       @media (max-width: 768px){
  //         margin-left: 0;
  //         margin-right: -20px;
  //       }
  //       @media (max-width: 640px){
  //         margin-left: 0;
  //         margin-right:-10px;
  //       }
  //       @media (max-width: 480px){
  //         margin-left: 0;
  //         margin-right:-5px;
  //       }
  //       @media (max-width: 360px){
  //         margin-left: 0;
  //         margin-right:0px;
  //       }

  //       .menuIconContainer{
  //         margin-right: 0px;
  //         margin-left: 25px;
  //       }
    
  //       .browse{
  //         margin-left: 0;
  //         margin-right: 80px;

  //         li{
  //           margin-right: 0;
  //           margin-left: 25px;
  //         }
  //       }
  //     }
  //     .rightContainer {
  //       margin-right: 0;
  //       margin-left: -20px;
  //       @media (max-width: 768px){
  //         margin-right: 0;
  //         margin-left: -20px;
  //       }
  //       @media (max-width: 640px){
  //         margin-right: 0;
  //         margin-left:-10px;
  //       }
  //       @media (max-width: 480px){
  //         margin-right: 0;
  //         margin-left:-5px;
  //       }
  //       @media (max-width: 360px){
  //         margin-right: 0;
  //         margin-left:0px;
  //       }

  //       .userInfo{
  //         padding: 0px;
  //       }

  //       .languageDropdown{
  //         .languageOptions{
  //           .optionsList{
  //             .languageOption{
  //               &[data-selected="true"] {
  //                 &:after{
  //                   margin-left: 0;
  //                   margin-right: auto;
  //                 }
  //               }
  //             }
  //           }
  //         }
  //       }
    
  //       .buttonContainer{
  //         // gap: 30px;
  //         @media all and (max-width: 980px) {
  //           margin-left: 0;
  //           margin-right: 18px;
  //         }
  //         @media all and (max-width: 480px) {
  //           margin-left: 0;
  //           margin-right: 15px;
  //         }
  //         @media all and (max-width: 360px) {
  //           margin-left: 0;
  //           margin-right: 7px;
  //         }
  //         .register{
  //           margin-left: 30px;
  //           margin-right: 0;
  //           @media all and (max-width: 768px) {
  //             margin-right: 0;
  //             margin-left: 18px;
  //           }
  //           @media all and (max-width: 360px) {
  //             margin-right: 0;
  //             margin-left: 10px;
  //           }
  //         }
  //       }
  //     }
  //   }
  // }

  // div.searchModal.rtl{
  //   direction: rtl;
  //   text-align: right;

  //   .modalContent{
  //     input{
  //       margin-left: 0px;
  //       margin-right: 10px;
  //     }

  //     svg{
  //       margin-left: 0px;
  //       margin-right: 10px;
  //     }
  //   }
  // }

  // div.userInfoContainerGaia.rtl{
  //   right: auto;
  //   left: 3.2%;
  //   direction: rtl;
  //   text-align: right;
  //   &::after{
  //     left: 15%;
  //   }
  // }

  // div.browseMoreGaia.rtl{
  //   direction: rtl;
  //   text-align: right;
  //   .browseMoreContainerNew{
  //     left: auto;
  //     right: 10%;
  //     &::after{
  //       left: 85%;
  //     }
  //   }
  // }

  // div.browseMoreResponsiveGaia.rtl{
  //   direction: rtl;
  //   text-align: right;
  //   .container{
  //     &.active{
  //       @media(max-width: 1200px){
  //         left: auto !important;
  //         right: 0;
  //       }
  //     }

  //     .languageDropdown{
  //       .languageOptions{
  //         left: auto;
  //         .optionsList{
  //           .languageOption{
  //             &[data-selected="true"] {
  //               &:after{
  //                 margin-left: 0;
  //                 margin-right: auto;
  //               }
  //             }
  //           }
  //         }
  //       }
  //     }
  //   }
  // }