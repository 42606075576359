div.trailerPage {
    position: relative;
    padding: 100px 0 0;

    div.wrapper {

        div.trailer {
            width: 90%;
            margin: 0 auto;
            div.trailerVideoPlayer {
                margin-bottom: 40px;
            }

            div.trailerPagecontent {
                padding: 0 0 30px;
                display: flex;
                justify-content: space-between;
                align-items: center;

                div.videoTitle {
                    h1.title {
                        color: white;
                    }

                    @media screen and (max-width : 640px) {
                        h1.title {
                            font-size: 1.5rem;
                        }
                    }


                }

                div.watchButton {
                    
                    button {
                        width: 100%;
                        display: block;
                        background: var(--button-color);
                        padding: 15px 25px;
                        font-size: 15px;
                        color: var(--black-color);
                        font-weight: 600;
                        border-radius: 8px;
                        cursor: pointer;
                        transition: 0.1s ease-in-out;
                        &:hover {
                            transform: scale(1.05);
                        }
                    }
                }
                @media screen and (max-width: 980px) {
                    flex-direction: column;
                    align-items: flex-start;
                    div.videoTitle {
                        margin-bottom: 20px;
                    }
                    div.watchButton{
                        width: 100%;
                    }
                }

            }

            div.trailerDescription{
                color: var(--inner-text-color);
                margin-bottom: 30px;
            }

        }
    }

  
   

}
