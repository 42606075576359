div.gaiaGenresContainer {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding-left: 4.5%;
    padding-right: 4.5%;
    padding-bottom: 20px;
    padding-top: 20px;
    z-index: 10;
    max-width: 1980px;
    margin: 0 auto;
  
    @media ((max-width: 640px) and (min-width: 360px)){
      padding-top: 40px;
    }
  
    .wrapper{
      width: 100%;
    .swiper {
      width: 100%;
  
      &:hover{
        z-index: 999999;
      }
      .swiper-slide {
        display: flex;
        justify-content: center;
        align-items: center;
  
        &:hover {
          z-index: 99999;
        }
      }
      .swiper-button-next,
      .swiper-button-prev { 
        height: 100%;
        color: white;
        width: 5% !important;
        background: var(--bg-color);
  
        .svg {
          height: 60px;
          align-items: center;
          color: white;
        }
        &::after {
          font-size: 18px;
          font-weight: bolder;
          color: var(--text-color);
          position: absolute;
          z-index: 10; 
          box-shadow: 0 6px 20px rgba(0, 0, 0, 0.2);

          &:hover{
            background-color: var(--button-hover);
            transform: scale(1.3);
          }
        }
      }
      .swiper-button-next{
        right: -5% !important;
        &::after{
        left: -21%;}
      }
      .swiper-button-prev{
        left: -5% !important;
        &::after{
          right: -27%;
        }
      }
      .swiper-button-disabled {
        opacity: 0;
        pointer-events: none;
      }
      &.items{
            .swiper-button-next, .swiper-button-prev{
              margin-top: -35px;
              height: 110%;
              @media (max-width: 480px){
                margin-top: -60px;
              }
              &::after{
                @media(max-width: 2600px){
                  top: 37%;
                }
                @media (max-width: 980px){
                  top: 38% !important;
                }
                @media (max-width: 768px){
                  top: 39% !important;
                }
                @media (max-width: 480px){
                  top: 47% !important;
                }
                @media (max-width: 360px){
                  top: 43% !important;
                }
              }
            }
            .swiper-button-next{
              @media(max-width: 2600px){
                padding-right: 35%;
                right: -35% !important;
              }
              &::after{
                @media(max-width: 2600px){
                  left: -4% !important;
                }
                @media(max-width: 480px){
                  left: -5% !important;
                }
                @media(max-width: 360px){
                  left: -11% !important;
                }
              }
            }
            .swiper-button-prev{
              @media(max-width: 2600px){
                padding-left: 35%;
                left: -35% !important;
              }
              &::after{
                @media(max-width: 2600px){
                  right: -4% !important;
                }
                @media(max-width: 480px){
                  right: -5% !important;
                }
                @media(max-width: 360px){
                  right: -12% !important;
                }
              }
            }
  }
    }
  }
    div.header{
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding-bottom: 30px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      h1 {
        font-size: 28px;
        color: var(--text-color);
        @media (max-width: 768px){
          font-size: 22px;
        }
      }
  }
  div.category-name {
    cursor: pointer;
    transition: transform 0.3s ease;
    position: relative;
    width: 75%;
    
    &:hover {
      transform: scale(1.1);
    }
  
    div.image-container {
      width: 100%;
      height: 0;
      padding-bottom: 100%;
      position: relative;
      overflow: hidden;
      border-radius: 50%;

      img {
        position: absolute;
        width: 100%;
        height: 100%;
        object-fit: cover;
      }      
    }
    div.image-container.placeholder {
      background-color: var(--button-color);
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      position: relative;
      border-radius: 50%;
    }
    
    .placeholder-text {
      position: absolute;
      top: 45%;
      color: var(--white-color);
      font-size: 16px;
      text-align: center;
    }

    div.genre-name {
        color: var(--text-color);
        &.gaia{
        font-family: "Avenir-Black", sans-serif;
        }
        &.template_9{
          font-family: 'Gilroy-Medium';
          font-weight: bold;
        }
        font-size: 16px;
        margin-top: 20px;
        text-align: center;

        &:hover{
          // color: var(--button-color);
          color: var(--button-hover);
        }
      }
  }
    } 
  
div.gaiaGenresContainer.ltr{
  direction: ltr;
  text-align: left;
  .header{
    direction: rtl;
    text-align: right;
  }
  .category-name{
    direction: rtl;
    text-align: right;
  }
}

div.gaiaGenresContainer.rtl{
  direction: rtl;
  text-align: right;
}

div.gaiaGenresContainer.template-9{
  border-bottom: 1px solid var(--border-color);
  padding-bottom: 75px;
  padding-left: unset;
  padding-right: unset;
  max-width: none;
  .header{
    h1{
      font-size: 24px;
      font-family: 'Gilroy-Medium';
    }
  }
  .wrapper{
    width: 92%;
    @media(max-width: 980px){
      width: 85%;
    }
  }
}
