
div.failed {
    padding: 178px 0;
    div.failedContainer {
        background-color: #fff;
        width: 25%;
        margin: 0 auto;
        position: relative;
        padding: 70px 20px 30px;
        text-align: center;
        border-radius: 8px;
        @media all and (max-width:1200px){
                width: 31% ;
        }
        @media all and (max-width:980px){
                width: 41% ;
        }
        @media all and (max-width:768px){
                width: 45% ;
        }
        @media (max-width: 640px){
            width: 52%;
        }
        @media (max-width: 480px){
                width: 68%;
        }
        h5{
            color: #000;
            margin-bottom: 20px;
            font-size: 30px;
        }
        p{
            color: #636363;
            font-size: 15px;
            margin-bottom: 40px;
        }
        button{
            width: 100%;
            outline: none;
            border: 1px solid #e50914;
            background-color: #e50914;  
            color: #fff;
            padding: 10px 0;
            border-radius: 5px;
            transition: 0.3s ease-in-out;
            cursor: pointer;
            &:hover{
                background-color: transparent;
                color: #000;
             }
        }
        div.crossIcon {
            text-align: center;
            position: absolute;
            top: -35px;
            right: 0;
            left: 0;
            svg {
                padding: 20px;
                background-color: #e50914;
                border-radius: 50%;
            }
         }
    }
}



