div.header-container {
  position: fixed;
  top: 0;
  z-index: 99;
  width: 100%;
  background: linear-gradient(
    180deg,
    rgba(16, 20, 31, 0.8) 0.98%,
    rgba(16, 20, 31, 0.4) 59.23%,
    rgba(16, 20, 31, 0.2) 78.16%,
    rgba(16, 20, 31, 0.0001) 96.12%
  );
  height: 100px;
  display: flex;
  align-items: center;
  transition: 0.4s ease-out;

  &.background {
    background: var(--bg-color);
    // animation: fadeIn 0.6s ease-in-out;
  }
  // &.hidden{
  //   top: -80px;
  //   transition:  0.4s ease-out;
  // }
  div.wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    @media all and (max-width: 980px) {
      width: 97%;
    }
    div.leftContainer {
      display: flex;
      align-items: center;
      div.menuIconContainer {
        display: none;
        @media all and (max-width: 768px) {
          display: flex;
          margin-inline-end: 10px;
          background-color: rgba(150, 153, 163, 0.16);
          padding: 8px 12px;
          border-radius: 6px;
          svg {
            width: 25px !important;
            height: 25px !important;
            color: #fff;
          }
        }
      }
      div.logoContainer {
        h1 {
          width: 160px;
          @media all and (max-width: 480px) {
            width: 100px;
          }
          img {
            display: block;
            width: 100%;
            object-fit: contain;
            aspect-ratio: 2;
          }
        }
      }
      ul.hide {
        @media all and (max-width: 1200px) {
          &.hide {
            display: none;
          }
        }
      }
      ul.browse {
        display: flex;
        align-items: center;
        li {
          cursor: pointer;
          padding: 0 30px;
          &:nth-child(2){
            padding: 0;
          }
          &.active {
            padding-bottom: 60px;
            margin-top: 60px;
            span {
              color: var(--text-hover);
            }
          }
          span {
            font-size: 14px;
            font-weight: 900;
            transition: 0.3s ease-in-out;
            color: #fff;
          }
        }
        li.mainMenu {
          cursor: pointer;
          padding: 0 15px;

          &:nth-child(2){
            padding: 0 15px 0 0px;
          }

          &:nth-last-child(2){
            padding: 0 0px 0 15px;
          }
          
          &.active {
            padding-bottom: 60px;
            margin-top: 60px;
            span {
              color: var(--text-hover);
            }
          }
          span {
            font-size: 14px;
            font-weight: 900;
            transition: 0.3s ease-in-out;
            color: #fff;
          }
        }
        @media all and (max-width: 768px) {
          display: none;
        }
      }
    }

    div.rightContainer {
      display: flex;
      align-items: center;
      // justify-content: space-between;
      justify-content: flex-end;
      width: 56%;
      transition: 0.2s ease-in-out;
      @media all and (max-width: 1400px) {
        width: 66%;
      }
      &.increase {
        width: 90%;
      }
      @media all and (max-width: 1200px) {
        &.increase {
          width: 90%;
        }
      }
      @media all and (max-width: 980px) {
        width: 78%;
      }
      div.inputContainer {
        border: 1px solid #9e9fa4;
        padding: 12px 15px;
        display: flex;
        align-items: center;
        border-radius: 8px;
        width: 50%;
        justify-content: space-between;
        transition: 0.2s ease-in-out;
        margin-inline-end: 30px;
        cursor: pointer;
        &.showInput {
          &.showInput {
            width: 40%;
          }
        }
        @media all and (max-width: 1400px) {
          width: 44%;
        }
        @media all and (max-width: 1200px) {
          width: 8%;
          &.showInput {
            width: 70%;
            input {
              display: block;
            }
          }
        }
        @media all and (max-width: 980px) {
          width: 12%;
          justify-content: center;
          padding: 10px 8px;
          margin-inline-end: 18px;
          &.showInput {
            width: 57%;
            justify-content: space-between;
          }
        }
        @media all and (max-width: 768px) {
          display: none;
        }
        &:hover {
          border-color: #c5c5c8;
          input {
            &::placeholder {
              color: #fff;
            }
          }
        }
        svg {
          width: 25px !important;
          height: 25px !important;
          color: #fff !important;
          margin-inline-end: 20px;
          @media all and (max-width: 1200px) {
            margin-inline-end: 0;
          }
        }

        input {
          border: none;
          outline: none;
          width: 90%;
          background-color: transparent;
          font-size: 16px;
          color: #fff;
          @media all and (max-width: 1200px) {
            display: none;
          }
          &::placeholder {
            color: #9e9fa4;
            font-size: 16px;
            transition: 0.3s ease-in-out;
          }
        }
      }
      div.languageDropdown {
        position: relative;
        cursor: pointer;
        display: inline-block;
        font-size: 14px;
        color: white;
        padding: 15px 0;
        // transition: color 0.3s ease, font-weight 0.3s ease;
        @media(max-width: 768px){
          display: none;
        }
      
        &:hover {
          color: var(--text-hover);
        }
      
        div.language {
          font-size: 14px;
          font-weight: bold;
          // font-weight: normal;
          transition: color 0.3s ease, font-weight 0.3s ease;
        }
      
        div.languageOptions {
          position: absolute;
          top: 100%;
          inset-inline-start: 0;
          min-width: 210px;
          background-color: var(--bg-color);
          padding-top: 15px;
          padding-bottom: 10px;
          box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
          z-index: 9999;

          &::before {
            content: "";
            position: absolute;
            top: 0;
            inset-inline-start: 0;
            width: 100%;
            height: 4px; 
            background: linear-gradient(281deg, var(--gradient-right-color), var(--gradient-left-color));
          }
      
          div.optionsList {
            display: flex;
            flex-direction: column;
      
            div.languageOption {
              font-size: 14px;
              padding: 8px 12px;
              color: var(--text-color);
              cursor: pointer;
              display: flex;
              justify-content: space-between;
              align-items: center;
              white-space: nowrap;

              &[data-selected="true"] {
                color: var(--gradient-left-color);
                
                &:after {
                  content: "✔";
                  margin-inline-start: auto;
                }
              }
      
              &:hover {
                color: var(--gradient-left-color);
              }
            }
          }
        }
      }
      div.language {
        color: var(--white-color);
        cursor: pointer;
        @media all and (max-width: 768px) {
          display: none;
        }
        span {
          display: inline-block;
          margin-inline-start: 6px;
          font-size: 16px;
          font-weight: 500;
        }
      }
      div.userInfo {
        display: flex;
        align-items: center;
        cursor: pointer;
        padding: 0 40px;
        @media all and (max-width: 360px) {
          padding: 0 21px;
        }
        &.active {
          svg {
            transform: rotate(-180deg);
          }
        }
        h4 {
          color: var(--text-color);
          font-size: 14px;
          margin-inline-end: 10px;

          &.active {
            padding-top: 60px;
            padding-bottom: 60px;
          }
        }
        svg {
          color: var(--text-color) !important;
          width: 22px !important;
          height: 22px !important;
          transition: 0.3s ease-in-out;
        }
      }

      div.buttonContainer {
        display: flex;
        margin-inline-start: 30px;
        @media all and (max-width: 980px) {
          margin-inline-start: 18px;
        }
        @media all and (max-width: 480px) {
          margin-inline-start: 15px;
        }
        @media all and (max-width: 360px) {
          margin-inline-start: 7px;
        }
        button.register {
          background-color: #fff;
          padding: 13px 0;
          width: 6rem;
          border-radius: 8px;
          font-size: 14px;
          font-weight: 700;
          margin-inline-end: 30px;
          cursor: pointer;
          @media all and (max-width: 768px) {
            padding: 11px 0;
            margin-inline-end: 18px;
          }
          @media all and (max-width: 360px) {
            padding: 8px 0;
            width: 5rem;
            font-size: 12px;
            margin-inline-end: 10px;
          }
        }

        button.signIn {
          background-color: transparent;
          font-weight: 700;
          font-size: 14px;
          color: #fff;
          cursor: pointer;
          white-space: nowrap;
          @media all and (max-width: 360px) {
            font-size: 12px;
          }
        }
      }
    }
  }
}
div.userInfoContainer {
  position: fixed;
  inset-inline-end: 30px;
  width: 20rem;
  top: 100px;
  background-color: rgb(37, 42, 52);
  border-radius: 8px;

  display: none;
  z-index: 9999;
  &.active {
    display: block;
  }
  // @media all and (max-width: 1200px) {
  //   top: 15%;
  // }
  @media all and (max-width: 640px) {
    width: 84%;
  }
  div.top {
    padding: 20px 20px 0;

    ul {
      a {
        li {
          padding: 15px 0;
          cursor: pointer;
          &:first-child {
            padding-top: 0;
          }
          &:last-child {
            border-bottom: none;
          }
          span {
            color: var(--text-color);
            font-weight: 600;
            font-size: 15px;
          }
        }
        &:last-child {
          border: none;
        }
      }
    }
  }
  div.bottom {
    background: linear-gradient(180deg, #10141f, rgba(16, 20, 31, 0));
    padding: 15px;
    div.signOutContainer {
      display: flex;
      align-items: center;
      padding: 12px;
      background-color: var(--border-color);
      border-radius: 8px;
      cursor: pointer;
      svg {
        color: var(--text-color);
        width: 22px !important;
        height: 22px !important;
        margin-inline-end: 10px;
      }
      span {
        color: var(--text-color);
        font-weight: 600;
        font-size: 15px;
      }
    }
  }
}
div.overlayOne {
  &.active {
    @media all and (max-width: 1200px) {
      position: fixed;
      top: 0;
      bottom: 0;
      right: 0;
      left: 0;
      background-color: var(--overlay);
      z-index: 999;
      display: flex;
      justify-content: center;
      align-items: center;
      animation: fadeIn 0.3s ease-in-out;
    }
  }
}

div.overlayOne.active {
  display: flex;
}
div.browseMore {
  @media all and (max-width: 1200px) {
    display: none;
  }
  //  animation: fadeIn 0.3s ease-in-out ;
  display: none;

  div.overlay {
    position: fixed;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    background-color: var(--overlay);
    z-index: 9;
    display: flex;
    justify-content: center;
    align-items: center;
    animation: fadeIn 0.3s ease-in-out;
  }
  div.browseMoreContainer {
    animation: fadeIn 0.7s ease-in-out;

    position: fixed;
    // width: 70%;
    top: 100px;
    inset-inline-start: 10%;
    // transform: translateX(-50%);
    background-color: #fff;
    display: flex;
    border-radius: 6px;
    z-index: 999;
    @media all and (max-width: 2700px) {
      inset-inline-start: 7%;
      // top: 8%;
    }
    @media all and (max-width: 2500px) {
      inset-inline-start: 9.5%;
      // top: 9%;
    }
    @media all and (max-width: 1980px) {
      // top: 11%;
      inset-inline-start: 9.5%;

    }
    @media all and (max-width: 1768px) {
      // top: 13%;
      inset-inline-start: 9.5%;

    }
    &::after {
      content: "";
      top: 81px;
      /* width: 7px; */
      /* height: 4px; */
      border-color: transparent transparent #fff;
      border-style: solid;
      border-width: 0px 9px 15px;
      position: fixed;
      /* right: 2%; */
      /* transform: translateX(-580%); */
      inset-inline-start: 16.5%;
      @media all and (max-width: 2700px) {
        inset-inline-start: 10.5%;
        top: 7%;
      }
      @media all and (max-width: 2500px) {
        inset-inline-start: 11.5%;
        top: 8%;
      }
      @media all and (max-width: 1980px) {
        top: 10%;
        inset-inline-start: 13.5%;
      }
      @media all and (max-width: 1768px) {
        top: 12%;
  
      }
      @media all and (max-width: 1400px) {
        inset-inline-start: 17.5%;
  
      }
      // @media all and (max-width: 1900px) {
      //   top: 13%;
      //   left: 13.5%;
      // }
    }

    h1.categoryName {
      font-size: 14px;
      margin-bottom: 20px;
      color: var(--grey-color);
    }
    ul {
      display: flex;
      flex-direction: column;
      flex-wrap: wrap;
      li {
        margin-bottom: 8px;
        cursor: pointer;
        &:hover {
          span {
            color: var(--text-hover);
          }
        }
        span {
          font-size: 14px;
          color: #10141f;
          transition: 0.3s ease-in-out;
        }
      }
    }
    div.left {
      // width: 22%;
      padding: 30px 25px;
      overflow-y: scroll;
      height: 500px;

      &::-webkit-scrollbar {
        width: 8px;
      }

      &::-webkit-scrollbar-thumb {
        background-color: darkgrey;
        border-bottom-right-radius: 3px;
        border-top-right-radius: 3px;
        border-top-left-radius: 3px;
        border-bottom-left-radius: 3px;
      }
      &::-webkit-scrollbar-thumb:hover {
        background-color: rgb(125, 128, 128);
      }
    }
    div.center {
      width: 40%;
      background-color: #f0f3f5;
      padding: 30px 25px;
      div.sections {
        display: flex;
        justify-content: space-between;
        div.leftSection {
          width: 50%;
        }
        div.rightSection {
          width: 50%;
        }
      }
    }
    div.right {
      width: 40%;
      padding: 30px 25px;
    }
  }
 
  .browseMoreContainerNew {
    position: fixed;
    top: 100px;
    inset-inline-start: 10%;
    background-color: var(--bg-color);
    border-radius: 6px;
    z-index: 999;
    height: 500px;
    overflow-y: auto; 
      &::-webkit-scrollbar {
        width: 0;
        background: transparent;
      }
      scrollbar-width: none; 
      -ms-overflow-style: none;
    animation: fadeIn 0.7s ease-in-out;
  
    h1.categoryName {
      font-size: 14px;
      color: var(--grey-color);
      margin: 3% 2%;
    }
  
    li {
      padding-inline-start: 0;
  
      li {
        position: relative;
        margin-bottom: 8px;
        height: 40px;
        cursor: pointer;
        overflow: hidden;
        transition: transform 0.3s ease-in-out;
  
        &:hover {
          transform: scale(1.05);
  
          span {
            color: var(--bg-color); 
            top: 9px;
            inset-inline-start: 10px;
          }
  
          .shadeGradient {
            opacity: 0.8; 
          }
          &::after {
            opacity: 1; 
          }
        }
  
        span {
          font-size: 14px;
          color: var(--text-color);
          z-index: 2; 
          position: relative; 
          transition: color 0.3s ease-in-out;
          margin-top: 3%;
          margin-inline-start: 2%;
        }
  
        .shadeGradient {
          position: absolute;
          top: 0;
          inset-inline-end: 0;
          width: 100%;
          height: 100%;
          // background: linear-gradient(270deg, rgba(var(--buttonColorRgb), 0) 0%, var(--buttonColorRgb) 100%);
          opacity: 0;
          pointer-events: none;
          transition: opacity 0.3s ease-in-out;
          z-index: 1;
        }


      &::after {
        content: "";
        position: absolute;
        top: 0;
        inset-inline-start: 0;
        width: 2px;
        height: 100%;
        background-color:var(--buttonSelection); 
        opacity: 0;
        transition: opacity 0.3s ease-in-out;
        z-index: 3; 
      }
      }
    }
  
    div.categoryContainer {
      padding: 15px 25px 0;
    }
  }
  
  
}
div.browseMore.active {
  display: block;
  @media all and (max-width: 1200px) {
    display: none;
  }
  // animation: fadeIn 0.4s ease-in-out ;
}

div.browseMoreResponsive {
  div.container {
    position: fixed;
    inset-inline-start: -50%;
    width: 35%;
    background-color: var(--secondary-bg-color);
    padding: 35px 30px;
    z-index: 9999;
    top: 0;
    bottom: 0;
    overflow: scroll;
    transition: 0.5s ease-in-out;
    &::-webkit-scrollbar {
      display: none;
    }
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
    @media all and (max-width: 1200px) {
      &.active {
        inset-inline-start: 0;
      }
    }
    @media all and (max-width: 980px) {
      width: 45%;
    }
    @media all and (max-width: 768px) {
      width: 100%;
      inset-inline-start: -100%;
    }
    div.head {
      display: flex;
      justify-content: space-between;
      align-items: center;
      &.small {
        display: none;
      }
      @media all and (max-width: 768px) {
        &.large {
          display: none;
        }
        &.small {
          display: flex;
          justify-content: space-between;
          align-items: center;
          div.left {
            display: flex;
            align-items: center;
            width: 20%;
            svg {
              width: 22px !important;
              height: 22px !important;
              color: #fff;
              margin-inline-end: 10px;
            }
            span {
              color: #fff;
              font-size: 14px;
              font-weight: 700;
            }
          }
          div.right {
            width: 50%;
            display: flex;
            justify-content: end;
            &.increase {
              width: 100%;
            }
            div.inputContainer {
              border: 1px solid #9e9fa4;
              padding: 8px 10px;
              display: flex;
              align-items: center;
              border-radius: 8px;
              width: 3.5rem;
              justify-content: center;
              transition: 0.2s ease-in-out;
              cursor: pointer;
              input {
                display: none;
              }
              &.showInput {
                width: 100%;
                justify-content: space-between;
                input {
                  display: block;
                  outline: none;
                  border: none;
                  background-color: transparent;
                  width: 93%;
                  color: #fff;
                  font-size: 16px;
                }
              }
            }
          }
        }
      }
      div.left {
        h1 {
          color: var(--text-hover);
          font-size: 15px;
          font-weight: 700;
        }
      }
      div.right {
        svg {
          color: #fff;
          width: 23px !important;
          height: 23px !important;
        }
      }
    }
    div.language {
      color: var(--white-color);
      cursor: pointer;
      margin-top: 20px;
      display: flex;
      justify-content: flex-end;
      align-items: center;
      span {
        display: inline-block;
        margin-inline-start: 6px;
        font-size: 15px;
        font-weight: 500;
      }
    }
    div.top {
      margin-top: 20px;
      padding-bottom: 30px;
      border-bottom: 1px solid #3d3e43;

      h1.categoryName {
        font-size: 16px;
        margin-bottom: 20px;
        color: var(--grey-color);
        font-weight: 700;
        
      }
      ul {
        li {
          margin-bottom: 8px;
          span {
            font-size: 14px;
            color: #fff;
            cursor: pointer;

            &:hover{
              color: var(--button-color);
            }
          }
        }
      }
    }
    div.center {
      padding: 30px 0;
      border-bottom: 1px solid #3d3e43;
      h1.categoryName {
        font-size: 16px;
        margin-bottom: 20px;
        color: var(--grey-color);
        font-weight: 700;
      }
      ul {
        li {
          margin-bottom: 8px;
          span {
            font-size: 14px;
            color: #fff;
          }
        }
      }
    }
    div.bottom {
      padding: 30px 0;
      h1.categoryName {
        font-size: 16px;
        margin-bottom: 20px;
        color: var(--grey-color);
        font-weight: 700;
      }
      ul {
        li {
          margin-bottom: 8px;
          span {
            font-size: 14px;
            color: #fff;
          }
        }
      }
    }
    div.languageDropdown {
      position: relative;
      cursor: pointer;
      display: inline-block;
      font-size: 16px;
      color: white;
      padding: 15px 0;
      // transition: color 0.3s ease, font-weight 0.3s ease;
    
      &:hover {
        color: var(--text-hover);
      }
    
      h1.language {
        font-size: 16px;
        font-weight: bold;
        transition: color 0.3s ease, font-weight 0.3s ease;
      }
    
      div.languageOptions {
        position: absolute;
        top: 100%;
        inset-inline-start: 0;
        min-width: 210px;
        background-color: var(--bg-color);
        padding-top: 15px;
        padding-bottom: 10px;
        box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
        z-index: 100;

        &::before {
          content: "";
          position: absolute;
          top: 0;
          inset-inline-start: 0;
          width: 100%;
          height: 4px; 
          background: linear-gradient(281deg, var(--gradient-right-color), var(--gradient-left-color));
        }
    
        div.optionsList {
          display: flex;
          flex-direction: column;
    
          div.languageOption {
            font-size: 16px;
            padding: 8px 12px;
            color: var(--text-color);
            cursor: pointer;
            display: flex;
            justify-content: space-between;
            align-items: center;
            white-space: nowrap;

            &[data-selected="true"] {
              color: var(--gradient-left-color);
              
              &:after {
                content: "✔";
                margin-inline-start: auto;
              }
            }
    
            &:hover {
              color: var(--gradient-left-color);
            }
          }
        }
      }
    }
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes fadeOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
