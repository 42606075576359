div.searchPage{
    padding: 130px 0 40px;
    div.top{
        padding-bottom: 60px;
        border-bottom: 1px solid var(--border-color);
        h1.heading{
            font-size: 46px;
            margin-bottom: 10px;
            color: var(--text-color);
            @media all and (max-width:640px){
                font-size: 36px;
            }
        }
        p.desc{
            font-size: 18px;
            color: var(--inner-text-color);
            @media all and (max-width:640px){
                font-size: 14px;
            }
        }
    }
    div.bottom{
        margin-top: 40px;
        div.categories {
            display: flex;
            margin-bottom: 20px;
            button {
                background-color: transparent;
                outline: none;
                color: var(--white-color);
                padding: 8px 30px;
                border-radius: 30px;
                font-size: 18px;
                margin-inline-end: 10px;
                cursor: pointer;
                border:1px solid transparent;
                @media all and (max-width:1600px){
                    font-size: 15px;
                }
                @media all and (max-width:1200px){
                    font-size: 12px;
                }
                @media all and (max-width:768px){
                    padding: 8px 15px;
                }
                @media all and (max-width:640px){
                    padding: 8px 6px;
                }
                @media all and (max-width:480px){
                    padding: 8px 4px;
                    font-size: 8px;
                }
                @media all and (max-width:360px){
                    padding: 8px 2px;
                }
                &.gaia-categoryButton{
                  color: var(--text-color);
                  &:hover{
                    color: var(--white-color);
                  }
                }
                &:last-child{
                    margin-inline-end: 0;
                }
                &.active {
                border:1px solid var(--grey-color);
                }
                &.gaia-active{
                  border:1px solid var(--grey-color);
                  color: var(--text-color);
                }
                &:hover {
                    background-color:var(--button-hover);
                }
            }
           
        }
        div.itemsContainer{
            display: flex;
            flex-wrap: wrap;
            div.items{
                &.rowCount3{
                    width: 32.5%;
                    margin-inline-end: 13px;
                    margin-bottom: 30px;
                    &:nth-child(3n){
                        margin-inline-end: 0;
                    }
                    @media all and (max-width:1200px){
                        width: 32%;
                    }
                }
                &.rowCount4{
                    width: 24%;
                    margin-inline-end: 13px;
                    margin-bottom: 30px;
                    &:nth-child(4n){
                        margin-inline-end: 0;
                    }
                    @media all and (max-width:1200px){
                        width: 23.8%;
                    }
                }
                &.rowCount5{
                    width: 19%;
                    margin-inline-end: 13px;
                    margin-bottom: 30px;
                    &:nth-child(5n){
                        margin-inline-end: 0;
                    }
                    @media all and (max-width:1200px){
                        width: 18.5%;
                    }
                }
                &.rowCount6{
                    width: 15.8%;
                    margin-inline-end: 13px;
                    margin-bottom: 30px;
                    &:nth-child(6n){
                        margin-inline-end: 0;
                    }
                    @media all and (max-width:1400px){
                        width: 15.5%;
                    }
                    @media all and (max-width:1200px){
                        width: 15%;
                    }
                }
                &.rowCount7 {
                    width: 13.9%;
                    margin-inline-end: 6px;
                    margin-bottom: 30px;
                    &:nth-child(7n){
                        margin-inline-end: 0;
                    }
                    &.landscape {
                      @media all and (min-width: 981px) {
                        &:hover {
                          div.showCardContainer {
                            .square {
                              position: absolute;
                              z-index: 9999999;
                              transform: translateX(60px) translateY(-60px) scale(1.1);
                              div.metaData {
                                display: block;
                                visibility: visible !important;
                                animation: fadeIn 0.3s ease-in-out;
                              }
                            }
                          }
                          &:nth-child(7n) {
                            div.showCardContainer {
                              div.square {
                                transform: translateX(-60px) translateY(-60px) scale(1.1);
                              }
                            }
                          }
                         
                        }
                      }
          
                      
                      @media all and (max-width: 980px) {
                        width: 24.3%;
                        margin-inline-end: 6px;
                        margin-bottom: 40px;
                        &:nth-child(6n) {
                          margin-inline-end: 6px;
                        }
                        &:nth-child(4n) {
                          margin-inline-end: 0;
                        }
                      }
                      @media all and (max-width: 768px) {
                        width: 32.3%;
                        &:nth-child(4n) {
                          margin-inline-end: 6px;
                        }
                        &:nth-child(3n) {
                          margin-inline-end: 0;
                        }
                      }
                      @media all and (max-width: 640px) {
                        width: 48.2%;
          
                        &:nth-child(3n) {
                          margin-inline-end: 6px;
                        }
                        &:nth-child(2n) {
                          margin-inline-end: 0;
                        }
                      }
                      @media all and (max-width: 480px) {
                        width: 100%;
                        margin-inline-end: 0;
                        &:nth-child(6n) {
                          margin-inline-end: 0;
                        }
                        &:nth-child(4n) {
                          margin-inline-end: 0;
                        }
                        &:nth-child(3n) {
                          margin-inline-end: 0;
                        }
          
                        &:nth-child(2n) {
                          margin-inline-end: 0;
                        }
                        @media all and (max-width: 360px) {
                          width: 100%;
          
                          &:nth-child(3n) {
                            margin-inline-end: 0;
                          }
                          margin-inline-end: 0;
                        }
                      }
                    }
                    &.portrait {
                     @media all and (max-width: 2600px) {
                      width: 14%;
                      margin-inline-end: 7px;
                        &:nth-child(7n){
                          margin-inline-end: 0;
                      }
                      }
                      @media all and (max-width: 2500px) {
                        width: 14%;
                        margin-inline-end: 6.5px;
                      }
                      @media all and (max-width: 2200px) {
                        width: 14%;
                        margin-inline-end: 6px;
                      }
                      @media all and (max-width: 1980px) {
                        width: 14%;
                        margin-inline-end: 5px;
                      }
                      @media all and (max-width: 1768px) {
                        width: 14%;
                        margin-inline-end: 4.8px;
                      }
                      @media all and (max-width: 1600px) {
                        width: 13.8%;
                        margin-inline-end: 7px;
                      }
                      @media all and (max-width: 1400px) {
                        width: 14%;
                        margin-inline-end: 3px;
                      }
                      @media all and (max-width: 1200px) {
                        width: 13.7%;
                        margin-inline-end: 6.1px;
                      }
                      @media all and (min-width: 981px) {
                        &:hover {
                          div.showCardContainer {
                            .square {
                              position: absolute;
                              z-index: 9999999;
                              transform: translateX(60px) translateY(-60px) scale(1.2);
                              div.metaData {
                                display: block;
                                visibility: visible !important;
                                animation: fadeIn 0.3s ease-in-out;
                              }
                            }
                          }
                          &:nth-child(6n) {
                            div.showCardContainer {
                              div.square {
                                transform: translateX(-60px) translateY(-60px) scale(1.2);
                              }
                            }
                          }
                          @media all and (max-width: 1300px) {
                            &:nth-child(6n) {
                              div.showCardContainer {
                                div.square {
                                  transform: translateX(-60px) translateY(-60px)
                                    scale(1.2);
                                }
                              }
                            }
                            &:nth-child(7n) {
                              div.showCardContainer {
                                div.square {
                                  transform: translateX(60px) translateY(-60px) scale(1.2);
                                }
                              }
                            }
                          }
                          &:last-child {
                            div.showCardContainer {
                              div.square {
                                transform: translateX(-60px) translateY(-60px) scale(1.2);
                              }
                            }
                          }
                        }
                      }
                     
                      @media all and (max-width: 980px) {
                        width: 24%;
                        margin-inline-end: 6px;
                        margin-bottom: 40px;
                        &:nth-child(5n) {
                          margin-inline-end: 6px;
                        }
                        &:nth-child(4n) {
                          margin-inline-end: 0;
                        }
                        &:nth-child(6n) {
                          margin-inline-end: 6px;
                        }
                      }
                      @media all and (max-width: 768px) {
                        width: 32%;
                        &:nth-child(4n) {
                          margin-inline-end: 6px;
                        }
                        &:nth-child(3n) {
                          margin-inline-end: 0;
                        }
                      }
                      @media all and (max-width: 640px) {
                        width: 48%;
                        &:nth-child(3n) {
                          margin-inline-end: 6px;
                        }
                        &:nth-child(2n) {
                          margin-inline-end: 0;
                        }
                      }
                    }
                  }
                  &.rowCount8 {
                    width: 12%;
                    margin-inline-end: 8px;
                    margin-bottom: 30px;
                    &:nth-child(8n) {
                      margin-inline-end: 0;
                    }
                    
                    &.landscape {
                      @media all and (max-width: 2500px) {
                        width: 12%;
                        margin-inline-end: 10px;
                      }
                      @media all and (max-width: 2200px) {
                        width: 12%;
                        margin-inline-end: 9px;
                      }
                      @media all and (max-width: 1980px) {
                        width: 12%;
                        margin-inline-end: 8px;
                      }
                      @media all and (max-width: 1768px) {
                       width: 12%;
                      margin-inline-end: 7px;
                      }
                      @media all and (max-width: 1600px) {
                        width: 12%;
                        margin-inline-end: 6px;
                      }
                      @media all and (max-width: 1400px) {
                        width: 12%;
                        margin-inline-end: 5px;
                      }
                      @media all and (max-width: 1200px) {
                        width: 11.9%;
                        margin-inline-end: 5px;
                      }
                      @media all and (min-width: 981px) {
                        &:hover {
                          div.showCardContainer {
                            .square {
                              position: absolute;
                              z-index: 9999999;
                              transform: translateX(60px) translateY(-60px) scale(1.1);
                              div.metaData {
                                display: block;
                                visibility: visible !important;
                                animation: fadeIn 0.3s ease-in-out;
                              }
                            }
                          }
                          &:nth-child(5n) {
                            div.showCardContainer {
                              div.square {
                                transform: translateX(-60px) translateY(-60px) scale(1.1);
                              }
                            }
                          }
                         
                        }
                      }
          
                     
                      @media all and (max-width: 980px) {
                        width: 24.3%;
                        margin-inline-end: 6px;
                        margin-bottom: 40px;
                        &:nth-child(6n) {
                          margin-inline-end: 6px;
                        }
                        &:nth-child(4n) {
                          margin-inline-end: 0;
                        }
                      }
                      @media all and (max-width: 768px) {
                        width: 32.3%;
                        &:nth-child(4n) {
                          margin-inline-end: 6px;
                        }
                        &:nth-child(3n) {
                          margin-inline-end: 0;
                        }
                      }
                      @media all and (max-width: 640px) {
                        width: 48.2%;
          
                        &:nth-child(3n) {
                          margin-inline-end: 6px;
                        }
                        &:nth-child(2n) {
                          margin-inline-end: 0;
                        }
                      }
                      @media all and (max-width: 480px) {
                        width: 100%;
                        margin-inline-end: 0;
                        &:nth-child(6n) {
                          margin-inline-end: 0;
                        }
                        &:nth-child(4n) {
                          margin-inline-end: 0;
                        }
                        &:nth-child(3n) {
                          margin-inline-end: 0;
                        }
          
                        &:nth-child(2n) {
                          margin-inline-end: 0;
                        }
                        @media all and (max-width: 360px) {
                          width: 100%;
          
                          &:nth-child(3n) {
                            margin-inline-end: 0;
                          }
                          margin-inline-end: 0;
                        }
                      }
                    }
                    &.portrait {
                       width: 11%;
                       margin-inline-end: 21px;
                    @media all and (max-width: 2600px) {
                        width: 12%;
                        margin-inline-end: 13px;
                        &:nth-child(8n){
                          margin-inline-end: 0;
                      }
                      }
                      @media all and (max-width: 2500px) {
                        width: 12%;
                        margin-inline-end: 11px;
                      }
                      @media all and (max-width: 2200px) {
                        width: 12%;
                        margin-inline-end: 10px;
                      }
                      @media all and (max-width: 1980px) {
                        width: 12%;
                        margin-inline-end: 9px;
                      }
                      @media all and (max-width: 1768px) {
                       width: 12%;
                      margin-inline-end: 8px;
                      }
                      @media all and (max-width: 1600px) {
                        width: 12%;
                        margin-inline-end: 7px;
                      }
                      @media all and (max-width: 1400px) {
                        width: 12%;
                        margin-inline-end: 6px;
                      }
                      @media all and (max-width: 1200px) {
                        width: 11.9%;
                        margin-inline-end: 6px;
                      }
                      @media all and (min-width: 981px) {
                        &:hover {
                          div.showCardContainer {
                            .square {
                              position: absolute;
                              z-index: 9999999;
                              transform: translateX(60px) translateY(-60px) scale(1.2);
                              div.metaData {
                                display: block;
                                visibility: visible !important;
                                animation: fadeIn 0.3s ease-in-out;
                              }
                            }
                          }
                          &:nth-child(8n) {
                            div.showCardContainer {
                              div.square {
                                transform: translateX(-60px) translateY(-60px) scale(1.2);
                              }
                            }
                          }
                         
                          &:last-child {
                            div.showCardContainer {
                              div.square {
                                transform: translateX(-60px) translateY(-60px) scale(1.2);
                              }
                            }
                          }
                        }
                      }
                     
                      @media all and (max-width: 980px) {
                        width: 24%;
                        margin-inline-end: 6px;
                        margin-bottom: 40px;
                        &:nth-child(5n) {
                          margin-inline-end: 6px;
                        }
                        &:nth-child(4n) {
                          margin-inline-end: 0;
                        }
                        &:nth-child(6n) {
                          margin-inline-end: 6px;
                        }
                      }
                      @media all and (max-width: 768px) {
                        width: 32%;
                        &:nth-child(4n) {
                          margin-inline-end: 6px;
                        }
                        &:nth-child(3n) {
                          margin-inline-end: 0;
                        }
                      }
                      @media all and (max-width: 640px) {
                        width: 48%;
                        &:nth-child(3n) {
                          margin-inline-end: 6px;
                        }
                        &:nth-child(2n) {
                          margin-inline-end: 0;
                        }
                      }
                    }
                  }
                &.portrait{
                  
                    // @media all and (max-width:1400px){
                    //     width: 15.6%;
                    // }
                    // @media all and (max-width:1200px){
                    //     width: 18.8%;
                    //     &:nth-child(5n){
                    //         margin-inline-end: 0;
                    //     }
                    //    &:nth-child(6n){
                    //     margin-inline-end: 13px;
                    //    }
                    // }
                    @media all and (max-width:980px){
                        width:23.5%;
                        margin-inline-end: 13px;
                        margin-bottom: 40px;
                        &:nth-child(4n){
                            margin-inline-end: 0;
                        }
                       &:nth-child(5n){
                        margin-inline-end: 13px;
                       }
                    }
                    @media all and (max-width:768px){
                        width:23%;
                        &:nth-child(4n){
                            margin-inline-end: 0;
                        }
                       &:nth-child(5n){
                        margin-inline-end: 13px;
                       }
                    }
                    @media all and (max-width:640px){
                        width:31%;
                        
                       &:nth-child(4n){
                        margin-inline-end: 13px;
                       }
                       &:nth-child(3n){
                        margin-inline-end: 0;
                    }
                    }
                    @media all and (max-width:480px){
                        width:47%;
                        
                       &:nth-child(3n){
                        margin-inline-end: 13px;
                       }
                       &:nth-child(2n){
                        margin-inline-end: 0;
                    }
                    }
                }
                &.landscape{
                    // width: 32.8%;
               
                // &:nth-child(3n){
                //     margin-inline-end: 0;
                // }
                // @media all and (max-width:1400px){
                //     width: 32.5%;
                // }
                // @media all and (max-width:1200px){
                //     width:32%;
                // //     &:nth-child(4n){
                // //         margin-inline-end: 0;
                // //     }
                // //    &:nth-child(5n){
                // //     margin-inline-end: 13px;
                // //    }
                // }
                @media all and (max-width:980px){
                    width:32%;
                    margin-inline-end: 13px;
                margin-bottom: 40px;
                    &:nth-child(3n){
                        margin-inline-end: 0;
                    }
                    
                }
                @media all and (max-width:768px){
                    width:47.6%;
                    &:nth-child(2n){
                        margin-inline-end: 0;
                    }
                    &:nth-child(3n){
                        margin-inline-end: 13px;
                    }
                }
                @media all and (max-width:640px){
                    width:48%;
                    
                   &:nth-child(3n){
                    margin-inline-end: 13px;
                   }
                   &:nth-child(2n){
                    margin-inline-end: 0;
                }
                }
                @media all and (max-width:480px){
                    width:47%;
                    
                   &:nth-child(3n){
                    margin-inline-end: 13px;
                   }
                   &:nth-child(2n){
                    margin-inline-end: 0;
                }
                }
                }

            }

            div.shortsContainer {
              width: 19%;
              margin-inline-end: 1%;
              position: relative;
              @media all and (max-width:1200px){
                width: 24%;
              }
              @media all and (max-width:768px){
                width: 32.3%;
              }
              @media all and (max-width:480px){
                width: 49%;
              }
              &.showContents {
                cursor: pointer;
                width: 100%;
                &:hover {
                  div.imageContainer {
                    img {
                      transform: scale(1.1); 
                    }
            
                    div.extraActions {
                      visibility: visible;
                      opacity: 1; 
                      animation: fadeIn 0.2s ease-in-out forwards;
            
                      div.showCardOverlay {
                        background-color: var(--overlay); 
                        z-index: 8;
                        div.block-social-info {
                          position: absolute;
                          bottom: 2.2rem;
                          z-index: 999;
                          display: flex;
                          justify-content: space-between;
                          opacity: 1; 
                          transition: opacity 0.2s ease-in-out;
                          background: transparent;
                          padding: 10px 20px;
                          width: 100%;
                          .share-wrapper {
                            position: absolute;
                            top: auto;
                            inset-inline-end: 0;
                            inset-inline-start: 10%;
                            bottom: 80%;
                            width: 2.375rem;
                            height: auto;
                            display: none; 
                            padding: 10px 260x;
                            background-color:#191919;
                            div.share-boxs{
                             
                              div.overflow-hidden{
                                display: grid;
                                justify-content: center;
                                margin-top: 20%;
                              }
                            }
                          }
            
                          .share {
                            .share-wrapper {
                              display: none; /* Hide until hover */
                            }
            
                            &:hover {
                              .share-wrapper {
                                display: inline-block; 
                                bottom: 100%;
                                inset-inline-start: 8%;
                                width: 15%;
                                @media (min-width: 320px) {
                    
                                  width: 30%;
                              
                              }
                              @media (min-width: 360px) {
                              
                                  width: 26%;
                                  inset-inline-start: 11%;
                                
                              }
                              @media (min-width: 375px) {
                              
                                  width: 26%;
                            
                              }
                              @media (min-width: 425px) {
                              
                                width: 23%;
                          
                              }
                              @media (min-width: 480px) {
                                  width: 20%;
                              }
                              @media (min-width: 640px) {
                                inset-inline-start: 8%;
                                  width: 23%;
                              }
                              @media (min-width: 768px) {
                                  width: 19%;
                                  inset-inline-start: 9%;
                              }
                              @media (min-width: 980px) {
                                  width: 20%;
                                  inset-inline-start: 9%;
                              }
                              @media (min-width: 1024px) {
                                width: 18%;
                              }
                              @media (min-width: 1200px) {
                                  width: 15%;
                              }
                              @media (min-width: 1400px) {
                                width: 15%;
                                inset-inline-start: 8%;
                              }
                             @media (min-width: 2560px) {
                              width: 9%;
                              inset-inline-start: 6%;
                             }
                             @media (min-width: 2600px) {
                              width: 16%;
                             }
                              }
                            }
                          }
            
                          .music-play-lists {
                            display: flex;
                            width: 100%;
            
                            li {
                              width: 22%;
                              height: 40px;
                              margin-bottom: 0.4375rem;
                              border-radius: 50%;
                              background-color: transparent;
                              border: 2px solid var(--white);
                              color: white !important;
                              transition: all 0.3s ease;
                              display: flex;
                              justify-content: center; 
                              align-items: center;
                              transition: all 0.3s ease;
                              @media (max-width: 2600px) {
                                height: 45px;
                                width: 15%;
                                 }
                              @media (max-width: 1980px) {
                                width: 20%;
                                   }
                              @media (max-width: 1600px) {
                                    width: 25%;
                                       }
                              @media (max-width: 1400px) {
                                width: 28%;
                                 }
                              @media (max-width: 1200px) {
                                height: 40px;
                                width: 25%;
                                   }
                                 
                              @media (max-width: 980px) {
                                width: 30%;
                                height: 35px;
                                   }
                              @media (max-width: 768px) {
                                height: 30px;
                                 }
                              @media (max-width: 480px) {
                                width: 30%;
                                   }
                              @media (max-width: 360px) {
                                    width: 35%;
                                       } 
                              span.shareIcon{
                               
                                border-radius: 50%;
                                width: 100%;
                                height: 100%;
                                border: 1px solid white !important;
                                display: flex;
                                justify-content: center;
                                align-items: center;
                                border-radius: 50%;
               
                                svg{
                                  fill: white !important;
                                  width: 70%;
                                }
                              }
                              &:hover {
                                height: 100%;
                                border: 1px solid white !important;
                              }
            
                              .share > span {
                                width: 100%;
                                height: 100%;
                                display: inline-block;
                                font-size: 1rem;
                                line-height: 1.625rem;
                              }
            
                              i {
                                color: var(--bs-white);
                                font-size: 0.945em;
                                line-height: 1.625rem;
                                cursor: pointer;
                               
                              }
                            }
                            &:hover {
                              color:var(--button-color);
                            }
                          }
            
                          div.iq-button {
                            margin-inline-start: auto;
                            display: flex;
                            justify-content: flex-end;
                            background-color: var(--button-color);
                            width: 22%;
                            height: 40px;
                            border-radius: 50%;
                            justify-content: center; 
                            align-items: center;
                            @media (max-width: 2600px) {
                              height: 45px;
                              width: 15%;
                               }
                            @media (max-width: 1980px) {
                                width: 20%;
                                 }
                            @media (max-width: 1600px) {
                                  width: 25%;
                                     }  
                            @media (max-width: 1400px) {
                              width: 28%;
                               }
                            @media (max-width: 1200px) {
                              height: 40px;
                              width: 25%;
                                   }
                            @media (max-width: 980px) {
                              width: 30%;
                              height: 35px;
                                 }
                            @media (max-width: 768px) {
                              height: 30px;
                                   }
                            @media (max-width: 480px) {
                                    width: 30%;
                                       } 
                           @media (max-width: 360px) {
                                        width: 35%;
                                           } 
                            &:focus{
                              background-color: transparent;
                              width: 20%;
                              border-radius: 50%;
                              border: 2px solid var(--white);
                          }
                          }
                          &:focus{
                            background-color: transparent;
                            width: 20%;
                            border-radius: 50%;
                            border: 2px solid var(--white);
                        }
                          div.iq-button:hover {
                            color:gray;
                          
                            width: 13%;
                            height: 100%;
                            border-radius: 50%;
                            background-color: var(--button-hover);
                            &:focus{
                              background-color:gray;
                             
                          }
                          }
                          
                        }
                      }
                    }
                  }
            
                  div.metaData {
                    h1.title {
                      color: var(--text-hover);
                    }
                  }
                }
              }
            
              div.imageContainer {
                width: 100%;
                position: relative;
                overflow: hidden;
                border-radius: 3px;
            
                img {
                  width: 100%;
                  height: 26rem;
                  display: block;
                  border-radius: 3px;
                  transition: transform 0.2s ease-in-out;
                  @media all and (max-width:1200px){
                    height: 20rem;
                  }
                  @media all and (max-width:640px){
                    height: 17rem;
                  }
                  @media all and (max-width:360px){
                    height: 13rem;
                  }
                 
            
                  &.landscape {
                    min-height: 130px;
                    background: black;
                  }
            
                  &.portrait {
                    min-height: 420px;
                    background: black;
                  }
                }
            
                div.extraActions {
                  visibility: hidden;
                  opacity: 0;
                  transition: opacity 0.2s ease-in-out; 
            
                  div.showCardOverlay {
                    position: absolute;
                    top: 0;
                    bottom: 0;
                    right: 0;
                    left: 0;
                    background-color: var(--overlay);
                    z-index: 8;
                    // width: 45px;
                    // height: 45px;
                  }
                }
              }
            
              div.metaData {
                margin-top: 10px;
                color: var(--inner-text-color);
            
                h1.title {
                  color: var(--text-color);
                  font-size: 16px;
                  transition: color 0.3s ease;
                  overflow: hidden;
                  text-overflow: ellipsis;
                  display: -webkit-box;
                  -webkit-line-clamp: 2;
                  -webkit-box-orient: vertical;
            
                
                  @media (max-width: 480px) {
                    font-size: 13px;
                  }
                  @media (min-width: 640px) {
                    font-size: 14px;
                  }
                }
            
                div.datas {
                  display: flex;
                  justify-content: space-between;
            
                  @media (max-width: 980px) {
                    display: none;
                  }
            
                  div.left {
                    span {
                      font-size: 12px;
                      margin-inline-end: 6px;
            
                      &.duration.showDot::before {
                        content: "·";
                        margin-inline-end: 6px;
                      }
            
                      &:last-child {
                        margin-inline-end: 0;
                      }
                    }
                  }
                }
              }
            }
            
        }
    }
}
