div.gaiaFeatured {
    position: relative;
    display: flex;
    flex-direction: column;
    // color: var(--text-color);
    color: white;
    width: 100%;
    max-width: 100vw;
    height: 600px;
    box-sizing: border-box;
    // font-family: 'Avenir-Roman', sans-serif;
    transition: min-height 0.3s ease;
    margin-bottom: 20px;
    
    @media (max-width: 768px) {
      height: 400px;
    }

    .swiper{
      width: 100%;
      overflow: hidden;
      height: 600px;
  }
  .swiper-slide{
    width: 100%;
      opacity: 0.4;
  }
  .swiper-slide-active{
      opacity: 1;
  }
  
    div.bgImageContainer {
    min-width: 100%;
    position: relative;
    overflow: hidden;
    
    img{
        display: block;
        width: 100%;
        object-fit: cover;
        height: 600px;

        @media (max-width: 768px){
          height: 400px;
        }
    }
    }

    div.contents {
    position: absolute;
    bottom: 20%;
    width: 100%;
      padding: 10px;
      padding-top: 20px;
      padding-bottom: 20px;
      padding-left: 3%;
      z-index: 2;
      // background: linear-gradient(
      //   to right,
      //   rgba(0, 0, 0, 0.5) 0%,    /* Less dark starting color */
      //   rgba(0, 0, 0, 0.3) 30%,   /* Fading to a lighter shade by 30% */
      //   rgba(0, 0, 0, 0.1) 40%,   /* Almost transparent by 50% */
      //   rgba(255, 255, 255, 0) 50% /* Fully transparent at 50% */
      // );
      background: radial-gradient(
        ellipse at left,
        rgba(0, 0, 0, 0.5) 0%,    /* Dark starting color at the edges */
        rgba(0, 0, 0, 0.3) 55%,   /* Gradual fading towards the center */
        rgba(0, 0, 0, 0.1) 68%,   /* Near-transparent closer to the center */
        rgba(255, 255, 255, 0) 75% /* Fully transparent at the center */
      );
      background-size: 100% 100%;
      background-position: 100% 0%;
        // background-size: 120%;

      @media (max-width: 768px) {
        bottom: 0;
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: center;
        margin-left: 0;
        padding: 0;
        background: linear-gradient(
          to top,
          rgba(0, 0, 0, 0.9) 0%,      
          rgba(0, 0, 0, 0.7) 50%,     
          rgba(0, 0, 0, 0.5) 90%,     
          rgba(0, 0, 0, 0.2) 95%,     
          rgba(0, 0, 0, 0.0) 100%     
        );
      }
  
      div.categoryName{
        // font-size: 16px;
        font-size: 18px;
        font-family: 'Avenir-Black',sans-serif;
        // color: var(--text-color);
        margin-bottom: 20px;
        padding-left: 12px;
        border-left: 3px solid var(--button-color);

        @media (max-width: 767px){
          border-left: none;
        }
        &.hidden{
            display: none;
        }
      }

      div.showCast{
        font-family: 'Avenir-Black', sans-serif;
        color: var(--inner-text-color);
          text-decoration: none;
          font-size: 14px;
          font-weight: bold;
          margin-bottom: 5px;
          max-width: 30%;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;

          @media (max-width: 768px){
            // font-size: 14px;
            font-size: 16px;
            margin-bottom: 0px;
          }

          @media (max-width: 480px){
            display: none;
          }

          span {
            margin-right: 10px;
          }
          &.hidden{
            display: none;
          }
      }

      h1.title {
        font-size: 30px;
        margin-bottom: 15px;
        width: 45%;
        // font-family: 'Avenir-Black', sans-serif;

        @media (max-width: 767px){
          width: auto;
          // font-size: 22px;
          font-size: 24px;
        }
      }
            div.details{
                margin-bottom: 10px;
                .director{
                    // font-size: 14px;
                    font-size: 16px;
                    margin-right: 15px;
                    
                    span {
                      margin-right: 10px;
                    }
                }
                .year{
                    // font-size: 14px;
                    font-size: 16px;
                    
                    span {
                      margin-right: 10px;
                    }
                }
                &.hidden{
                    display: none;
                }
            }

            p.description{
                // font-size: 16px;
                font-size: 18px;
                margin-bottom: 15px;
                line-height: 1.5;
                max-width: 50%;
                white-space: nowrap; 
                overflow: hidden; 
                text-overflow: ellipsis;
      
                @media (max-width: 767px){
                  display: none;
                }
            }

            div.rating{
                margin-bottom: 15px;
                .duration{
                    // font-size: 14px;
                    font-size: 16px;
                    margin-right: 15px;
          
                    span {
                      margin-right: 10px;
                    }
                    &.ltr{
                      direction: ltr !important;
                      unicode-bidi: embed !important;
                    }
                }
                .certificate {
                  display: inline-block;
                  padding: 1px 6px;
                  background-color: rgba(150, 153, 163, 0.32);
                  // font-size: 12px;
                  font-size: 14px;
                  font-weight: 700;
                  // color: var(--text-color);
                  border-radius: 4px;
                }
                &.hidden{
                    display: none;
                }
              }
            
            div.buttonContainer{
            
                display: flex;
                align-items: center;
                gap: 25px;
                @media (max-width: 768px){
                  margin-bottom: 4%;
                }
                @media (max-width: 480px){
                  margin-bottom: 5%;
                }
 
                button {
                  display: flex;
                  align-items: center;
                  background: transparent;
                  border: none;
                  // color: var(--text-color);
                  color: white;
                  // font-size: 14px;
                  font-size: 16px;
                  // font-family: 'Avenir-Medium', sans-serif;
                  cursor: pointer;
                  white-space: nowrap; 
                  max-width: fit-content;
                  transition: fill 0.3s ease, color 0.5s ease;

                  @media (max-width: 767px){
                    // font-size: 12px;
                    font-size: 14px;
                  }
      
                  svg {
                    margin-right: 5px;
                    width: 40px;
                    color: var(--button-color);
                    
                    @media(max-width: 767px){
                      width: 30px;
                    }
                  }

                  
                  &:hover {
                    // color: var(--button-hover);
                    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
                  }
                }
                .watchNow {
                  &:hover circle {
                    transition: fill 0.3s ease, color 0.5s ease;
                    fill: var(--button-hover);
                  }
                }
                
                .addToPlaylist {
                  &:hover circle {
                    transition: fill 0.3s ease, color 0.5s ease; 
                    stroke: var(--button-hover);
                  }
                  &:hover path{
                    transition: fill 0.3s ease, color 0.5s ease;
                    fill: var(--button-hover);
                  }
                }

                .moredetails{
                  &:hover path{
                    fill: var(--button-hover);
                  }
                  @media (max-width: 767px){
                    display: none;
                  }
                }
              
        }
      }

          }

div.gaiaFeatured.rtl{
  .contents{
    background: radial-gradient(ellipse at right, rgba(0, 0, 0, 0.5) 0%, rgba(0, 0, 0, 0.3) 55%, rgba(0, 0, 0, 0.1) 68%, rgba(255, 255, 255, 0) 75%);
    padding-left: 0;
    padding-right: 3%;
    .categoryName{
      padding-left: 0;
      padding-right: 12px;
      border-left: none;
      border-right: 3px solid var(--button-color);
      @media(max-width: 768px){
        border-right: none;
      }
    }
    .rating{
      .duration{
        margin-right: 0px;
        margin-left: 15px;
      }
    }
    .buttonContainer{
      button{
        svg{
          margin-right: 0;
          margin-left: 5px;
        }
      }
    }
  }
}

    @keyframes slideUp {
      0% {
        transform: translateY(0);
        opacity: 0;
      }
  
      50% {
        transform: translateY(15px);
      }
  
      100% {
        transform: translateY(0);
        opacity: 1;
      }
    }
