div.subcriptionPage{
    padding: 150px 0;
    h1.heading{
        text-align: center;
        font-size: 34px;
        color: var(--text-color);
    }
    div.subscriptionContainer{
        width: 90%;
        margin: 30px auto 0;
        overflow-x: scroll;
        table{
        width: 100%;
        border-collapse: collapse;
            thead{
                tr{

                    th{
                        width: 80%;

                        button{
                            display: inline-block;
                            padding: 10px 25px;
                            border-radius: 30px;
                            color: var(--text-color);
                            cursor: pointer;
                            &.active{
                                background-color: var(--text-hover);
                            }
                            &.no-active{
                                background-color: var(--inner-text-color);

                            }
                        }
                    }
                }
            }
            tbody{
                tr{
                    border-bottom: 1px solid var(--border-color);
                    td{
                        padding: 10px 0;
                        &.big{
                            span{
                                color: var(--text-color);
                            }
                        }
                        &.value{
                            span{
                                display: block;
                                text-align: center;
                                &.no-active{
                                    color: var(--inner-text-color);
                                    svg{
                                        stroke: var(--inner-text-color) !important;
                                    }
                                }
                                &.active{
                                    color: var(--text-hover);
                                    svg{
                                        stroke: var(--text-hover) !important;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
        div.buyNowButtonContainer {
            display: flex;
        padding: 18px 20px;
        border-radius: 4px;
        align-items: center;
        margin-bottom: 15px;
        cursor: pointer;
        transition: 0.2s ease-in-out;
        justify-content: center;
        position: relative;
        width: 15rem;
        margin: 50px auto 0;
        @media all and (max-width:640px){
            width: 15rem;
        }
        @media all and (max-width:480px){
            width: 10rem;
        }
        &:hover{
            div.background{

                transform: scale(1.0476190476,1.0357142857);
            }
        }
            div.background{
                background: var(--text-hover);
                position: absolute;
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
                border-radius: 4px;
                transition: 0.1s ease-in-out;
                z-index: 1;

            }
            button { 
                background: transparent;
                font-size: 15px;
                color: var( --text-color);
                font-weight: 600;
                position: relative;
                z-index: 2;
                cursor: pointer;


            }
        }
    }
}