div.replyFieldShorts {
  display: flex;
  width: 100%;
  padding-top: 30px;
  animation: fadeIn 0.4s ease-in-out;
  &.child{
    width: 92%;
    margin: 0 0 0 auto;
  }
  div.left{
    margin-right: 20px;
      div.imageContainer {
        width: 45px;
        height: 45px;
       
          img{
            width: 100%;
            display: block;
            height: 100%;
            border-radius: 50%;

        }
          span.nameAvatar {
            width: 100%;
            height: 45px;
            display: flex;
            justify-content: center;
            align-items: center;
            color: var(--white-color);
            background-color: green;
            border-radius: 50%;
          }
        }
  }
  div.right{
    width: 100%;
      div.inputContainer {
        textarea {
          display: block;
          width: 100%;
          height: 45px;
          padding: 10px 15px;
          border-radius: 5px;
          margin-bottom: 15px !important;
          border: 1px solid #e1e8ed;
          resize: vertical;
          &:focus-visible {
            border: 3px solid var(--button-color) !important;
            outline: none;
          }
        }
      div.upload-featue{
        display: flex;
        justify-content: space-between;
        width: 100%;
        div.selectedImageReply {
          width: 55%;
          height: 30%;
          position: relative; 
          // margin-bottom: 20px;
          img {
            max-width: 100%;
            border-radius: 5px;
            height: auto;
          }
          button.removeButton {
              position: absolute; 
            background: white;
            border: none;
            border-radius: 50%;
            cursor: pointer;
            font-size: 20px;
            color: var(--button-color);
            width: 20px;
            height: 20px;
        
            display: flex;
            justify-content: center;
            align-items: center;
            box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
            transition: background-color 0.3s ease;
          
            &:hover {
              background-color: #f2f2f2;
            }
          
            svg {
              width: 15px;
              height: 15px;
              fill: var(--button-color);
            }
          }
          
        }
     
        div.actions {
          display: flex;
          justify-content: flex-end;
          align-items: flex-start;
      
          div.buttonContainer {
            display: flex;
            align-items: center;

            label.customFileUploadReply {
              background: none;
              padding: 5px;
              border: 1px solid #1da1f2;
              color: white;
         margin-right: 10px;
              font-weight: 600;
              // padding: 7px 20px;
              border-radius: 6px;
              font-size: 14px;
              // margin-bottom: 10px;
              cursor: pointer;
              display: flex;
              align-items: center;
              
              transition: 0.4s ease-in-out;
             
              &:hover {
                background-color: transparent;
                color: var(--white-color);
              }
            }
            button.cancel{
              background: none;
         border: none !important;
              margin-right: 10px;
                color: var(--white-color);
                font-weight: 600;
                // padding: 10px 20px;
                border-radius: 6px;
                font-size: 14px;
                cursor: pointer;
              
                transition: 0.4s ease-in-out;
              &:hover{
                background-color: transparent;
              }
            }
        
      
           
      
            input[type="file"] {
              display: none;
            }
      
            button.giphyButton {
              background: none;
              color: white;
              border: 1px solid #1da1f2;
              // margin-bottom: 10px;
              margin-right: 10px;
              font-weight: 600;
              // border: none !important;
              border-radius: 6px;
              font-size: 14px;
              padding: 5px;
              margin-right: 10px !important;
              cursor: pointer;
              display: flex;
              align-items: center;

              transition: 0.4s ease-in-out;
             
              &:hover {
                background-color: transparent;
                color: var(--white-color);
              }
            }
          }
          
          button.submit {
            background: none;
            // color: var(--white-color);
            font-weight: 600;
            border: none !important;

            border-radius: 6px;
            font-size: 14px;
            cursor: pointer;
          
            transition: 0.4s ease-in-out;
            &:hover {
              background-color: transparent;
              color: var(--white-color);
            }
          }
        }
      }
        div.actions {
          display: flex;
          justify-content: flex-end;

          p {
            font-size: 14px;
            color: var(--text-color);
            span {
              color: var(--grey-color);
              cursor: pointer;
              &:hover {
                text-decoration: underline;
              }
            }
          }

          div.buttonContainer {
            display: flex;
            align-items: center;

            label.customFileUploadReply {
              background: none;
              border: 1px solid #1da1f2;
              color: white;
         margin-right: 10px;
              font-weight: 600;
              // padding: 7px 20px;
              border-radius: 6px;
              font-size: 14px;
              // margin-bottom: 10px;
              cursor: pointer;
              display: flex;
              align-items: center;
              
              transition: 0.4s ease-in-out;
             
              &:hover {
                background-color: transparent;
                color: var(--white-color);
              }
            }
            button.cancel{
              background: none;
         border: none !important;
              margin-right: 10px;
                color: var(--white-color);
                font-weight: 600;
                // padding: 10px 20px;
                border-radius: 6px;
                font-size: 14px;
                cursor: pointer;
              
                transition: 0.4s ease-in-out;
              &:hover{
                background-color: transparent;
              }
            }
        
      
           
      
            input[type="file"] {
              display: none;
            }
      
            button.giphyButton {
              background: none;
              color: white;
 
              border: 1px solid #1da1f2;
              // margin-bottom: 10px;
              font-weight: 600;
              // border: none !important;
              border-radius: 6px;
              font-size: 14px;
              margin-right: 10px !important;
              cursor: pointer;
              display: flex;
              align-items: center;

              transition: 0.4s ease-in-out;
             
              &:hover {
                background-color: transparent;
                color: var(--white-color);
              }
            }
          }
        }
      }
  }
}

div.replyFieldShorts.rtl{
  div.left{
    margin-right: 0;
    margin-left: 20px;
  }
  .right{
    .inputContainer{
      .upload-featue{
        div.actions {
          div.buttonContainer {
            label.customFileUploadReply {
              margin-right: 0;
              margin-left: 10px;
            }
            button.cancel{
              margin-right: 0;
              margin-left: 10px;
            }
            button.giphyButton {
              margin-right: 0;
              margin-left: 10px !important;
            }
          }
        }
      }
    }
  }
}