div.categoriesContainer {
  padding: 15px 0;
  border-bottom: 1px solid var(--border-color);
  overflow-x: hidden;// to fix swiper hidden issue due to template 10
  &:hover {
    .swiper-button-next {
      &::after {
        opacity: 1;
      }
    }
    .swiper-button-prev {
      &::after {
        opacity: 1;
      }
    }
  }
  .swiper {
    overflow: visible;// to fix swiper hidden issue due to template 10
    &.landscape {
        .swiper-button-next {
            right: -82px;
            height: 100%;
            width: 6%;
            top: 5%;
            background: linear-gradient(
              -97deg,
              rgb(28, 31, 41) 0.98%,
              rgba(28, 31, 41, 0.4) 100.23%,
              rgba(28, 31, 41, 0.2) 100.16%,
              rgba(28, 31, 41, 0.0001) 52.12%
            );
            // 
            @media all and (min-width:320px){
                display: none;
                
            }
            @media all and (min-width:980px){
                display: flex;
                width: 4%;
                right: -47px;
                top: 8%;
            }
            @media all and (min-width:1200px){
              right: -57px;
            width: 4% !important;
              top: 7%;

          }
          @media all and (min-width:1400px){
            top: 6%;

            right: -67px;
        }
        @media all and (min-width:1600px){
          right: -70px;
        }
        @media all and (min-width:1768px){
          right: -79px;
          top: 5%;
        }
        @media all and (min-width:1980px){
            right: -91px;

          }
          @media all and (min-width:2200px){
            right: -99px;
        }
            // @media all and (min-width:768px){
            //    width: 7%;
            // }
          }
          .swiper-button-prev {
            left: -77px;
            height: 100%;
            width: 5%;
            top: 5%;
            background: linear-gradient(
              -97deg,
              rgb(28, 31, 41) 0.98%,
              rgba(28, 31, 41, 0.4) 100.23%,
              rgba(28, 31, 41, 0.2) 100.16%,
              rgba(28, 31, 41, 0.0001) 52.12%
            );
            
            @media all and (min-width:320px){
                display: none;
        
            }
            @media all and (min-width:980px){
                display: flex;
                width: 4%;
                left: -47px;
                top: 8%;
            }
            @media all and (min-width:1200px){
              left: -57px;
              width: 4% !important;
              top: 7%;
          }
          @media all and (min-width:1400px){
            left: -67px;
            top: 6%;

        }
        @media all and (min-width:1600px){
          left: -70px;
        }
        @media all and (min-width:1768px){
          left: -79px;
          top: 5%;
        }
        @media all and (min-width:1980px){
            left: -91px;
        }
        @media all and (min-width:2200px){
            left: -99px;
        }
            // @media all and (min-width:768px){
            //     width: 7%;
            //  }
          }
    }
    &.portrait{

        // .swiper-button-next {
        //   &::after {
        //     background: rgb(16, 20, 31);
        //     padding: 6px;
        //     border-radius: 50%;
        //     font-size: 18px;
        //     width: 20px;
        //     height: 20px;
        //     display: flex;
        //     justify-content: center;
        //     align-items: center;
        //     color: var(--inner-text-color);
        //     opacity: 0;
        //     transition: 0.3s ease-in-out;
            
        //   }
        // }
        // .swiper-button-prev {
        //   &::after {
        //     background: rgb(16, 20, 31);
        //     padding: 6px;
        //     border-radius: 50%;
        //     font-size: 18px;
        //     width: 20px;
        //     height: 20px;
        //     display: flex;
        //     justify-content: center;
        //     align-items: center;
        //     color: var(--inner-text-color);
        //     opacity: 0;
        //     transition: 0.3s ease-in-out;
           
        //   }
        // }

        .swiper-button-next {
            right: -82px;
            height: 100%;
            width: 6%;
            top: 5%;
            background: linear-gradient(
              -97deg,
              rgb(28, 31, 41) 0.98%,
              rgba(28, 31, 41, 0.4) 100.23%,
              rgba(28, 31, 41, 0.2) 100.16%,
              rgba(28, 31, 41, 0.0001) 52.12%
            );
            // 
            @media all and (min-width:320px){
                display: none;
                
            }
            @media all and (min-width:980px){
                display: flex;
                width: 4%;
                right: -74px;
                top: 3%;
            }
            @media all and (min-width:1200px){
              right: -57px;
            width: 4% !important;
              top: 5%;

          }
          @media all and (min-width:1400px){

            right: -67px;
        }
        @media all and (min-width:1600px){
          right: -70px;
        }
        @media all and (min-width:1768px){
          right: -79px;
          top: 4%;
        }
        @media all and (min-width:1980px){
            right: -91px;

          }
          @media all and (min-width:2200px){
            right: -99px;
        }
            // @media all and (min-width:768px){
            //    width: 7%;
            // }
          }
          .swiper-button-prev {
            left: -77px;
            height: 100%;
            width: 5%;
            top: 5%;
            background: linear-gradient(
              -97deg,
              rgb(28, 31, 41) 0.98%,
              rgba(28, 31, 41, 0.4) 100.23%,
              rgba(28, 31, 41, 0.2) 100.16%,
              rgba(28, 31, 41, 0.0001) 52.12%
            );
            
            @media all and (min-width:320px){
                display: none;
        
            }
            @media all and (min-width:980px){
                display: flex;
                width: 4%;
                left: -75px;
                top: 2%;
            }
            @media all and (min-width:1200px){
              left: -57px;
              width: 4% !important;
              top: 5%;
          }
          @media all and (min-width:1400px){
            left: -67px;

        }
        @media all and (min-width:1600px){
          left: -70px;
        }
        @media all and (min-width:1768px){
          left: -79px;
          top: 4%;
        }
        @media all and (min-width:1980px){
            left: -91px;
        }
        @media all and (min-width:2200px){
            left: -99px;
        }
            // @media all and (min-width:768px){
            //     width: 7%;
            //  }
          }
    }
  }
  .categoryNameContainer {
    display: inline-flex;
    align-items: center;
    cursor: pointer;
    width: 100%;
    &:hover {
      h1 {
        color: var(--text-hover);
      }
      span {
        color: var(--text-color);
      }
      svg {
        color: var(--text-hover);
      }
    }
    h1 {
      font-size: 24px;
      margin-inline-end: 20px;
      color: var(--text-color);
      transition: color 0.3s ease-in-out;
      display: inline-block;
    }

    span {
      color: var(--text-hover);
    }
    svg {
      color: var(--text-color);
      height: 20px !important;
      width: 20px !important;
      transition: 0.3s ease-in-out;
      vertical-align: sub;
    }
  }
  div.itemsContainer {
    margin-top: 20px;   
    div.show {
      width: 14rem;
      @media all and (min-width: 320px) {
        width: 100%;
      }
    }
 
  } 
}

div.categoriesContainer.rtl{
  direction: rtl;
  text-align: right;
  .categoryNameContainer{
    svg{
      transform: rotate(180deg);
    }
  }
}

div.categoriesContainer.ltr{
  direction: ltr;
  text-align: left;
  .categoryNameContainer{
    display: inline-block;
    direction: rtl;
    text-align: right;
    svg{
      transform: rotate(180deg);
    }
  }
  .itemsContainer{
    .show{
    direction: rtl;
    text-align: right;
    }
  }
}
