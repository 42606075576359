.sectioncontainer {
  .wrapper {
    &:hover {
      .swiper-button-next {
        &::after {
          opacity: 1;
        }
      }

      .swiper-button-prev {
        &::after {
          opacity: 1;
        }
      }
    }

    .swiper {
      overflow: visible; // to fix swiper hidden issue due to template 10
      &.landscape {
        .swiper-button-next {
          height: 100%;
          inset-inline-end: -4%;
          color: white;
          top: 9%;
          width: 10%;
          background: linear-gradient(-97deg,
              rgb(28, 31, 41) 0.98%,
              rgba(28, 31, 41, 0.4) 100.23%,
              rgba(28, 31, 41, 0.2) 100.16%,
              rgba(28, 31, 41, 0.0001) 52.12%);

          @media all and (max-width: 2600px) {
            top: 6%;
            width: 5% !important;
            inset-inline-end: -5%;
          }

          @media all and (max-width: 1600px) {
            top: 9%;
          }

          @media all and (max-width: 1200px) {
            top: 13%;
          }

          @media all and (max-width: 980px) {
            inset-inline-end: -9%;
            width: 9% !important;
            top: 6%;
          }

          @media all and (max-width: 480px) {
            top: 9%;
            width: 10% !important;
          }
        }

        .swiper-button-prev {
          inset-inline-end: -4%;
          height: 100%;
          color: white;
          width: 6%;
          top: 9%;
          background: linear-gradient(-97deg,
              rgb(28, 31, 41) 0.98%,
              rgba(28, 31, 41, 0.4) 100.23%,
              rgba(28, 31, 41, 0.2) 100.16%,
              rgba(28, 31, 41, 0.0001) 52.12%);

          .svg {
            height: 20px;
            align-items: center;
            color: white;
          }

          @media all and (max-width: 2600px) {
            top: 6%;
            width: 5% !important;
            inset-inline-start: -5%;
          }

          @media all and (max-width: 1600px) {
            top: 9%;
          }

          @media all and (max-width: 1200px) {
            top: 13%;
          }

          @media all and (max-width: 980px) {
            inset-inline-start: -9%;
            width: 9% !important;
            top: 6%;
          }

          @media all and (max-width: 480px) {
            top: 9%;
            width: 10% !important;
          }
        }
      }

      &.portrait {
        .swiper-button-next {
          inset-inline-end: -5%;
          height: 100%;
          width: 4% !important;
          background: linear-gradient(-97deg,
              rgb(28, 31, 41) 0.98%,
              rgba(28, 31, 41, 0.4) 100.23%,
              rgba(28, 31, 41, 0.2) 100.16%,
              rgba(28, 31, 41, 0.0001) 52.12%);

          @media all and (max-width:320px) {
            width: 9% !important;
          }

          @media all and (max-width: 480px) {
            width: 8% !important;
          }

          @media all and (max-width: 640px) {
            width: 9% !important;
            inset-inline-end: -9% !important;
          }

          @media all and (max-width: 980px) {
            display: flex;
            width: 4%;
            inset-inline-end: -9% !important;
            top: 6% !important;
          }

          @media all and (max-width: 1200px) {
            width: 4% !important;
            top: 9% !important;
          }

          @media all and (max-width: 1400px) {
            top: 9% !important;
          }

          @media all and (max-width: 1600px) {
            top: 7% !important;
          }

          @media all and (max-width: 1768px) {
            top: 4% !important;
          }
        }

        .swiper-button-prev {
          inset-inline-start: -5%;
          height: 100%;
          width: 4% !important;
          background: linear-gradient(-97deg,
              rgb(28, 31, 41) 0.98%,
              rgba(28, 31, 41, 0.4) 100.23%,
              rgba(28, 31, 41, 0.2) 100.16%,
              rgba(28, 31, 41, 0.0001) 52.12%);

          @media all and (max-width:320px) {
            width: 9% !important;
          }

          @media all and (max-width: 480px) {
            width: 8% !important;
          }

          @media all and (max-width: 640px) {
            width: 9% !important;
            inset-inline-start: -9% !important;

          }

          @media all and (max-width: 980px) {
            display: flex;
            width: 4%;
            inset-inline-start: -9%;
            top: 5% !important;
          }

          @media all and (max-width: 1200px) {
            width: 4% !important;
            top: 9% !important;
          }

          @media all and (max-width: 1400px) {
            top: 9% !important;
          }

          @media all and (max-width: 1600px) {
            top: 7% !important;
          }

          @media all and (max-width: 1768px) {
            top: 4% !important;
          }
        }
      }
    }

    .heading-container {
      display: inline;
      align-items: center;
      cursor: pointer;

      &.hover {
        color: var(--text-hover);
      }

      h4.topHeading {
        font-size: 24px;
        margin-inline-end: 10px;
        color: var(--text-color);
        transition: 0.3s ease-in-out;
        display: inline-block;
        margin-bottom: 20px;

        &:hover {
          color: var(--text-hover);
        }

        span {
          font-size: 24px;
          display: inline-block;
          color: var(--text-color);
          height: 20px !important;
          width: 20px !important;
          transition: color 0.3s ease-in-out;
          vertical-align: sub;
          margin-inline-start: 10px;

          &:hover,
          &:focus {
            color: var(--text-hover);
          }
        }

        &:hover,
        &:focus {
          color: var(--text-hover);
        }
      }

      .show-all {
        color: var(--text-hover);
        font-size: medium;

        &:hover {
          color: var(--text-color);
        }
      }

    }

    .text-decoration-none {
      &:hover {
        color: var(--text-hover);
      }

      svg {
        &:hover {
          color: var(--text-hover);
        }
      }
    }
  }
}
