div.loading{
    position: fixed;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    background-color: var(--bg-color);
    z-index: 99;
    display: flex;
    justify-content: center;
    align-items: center;
    // flex-direction: column;
    div.loadingOverlay{
        position: fixed;
        top: 0;
        bottom: 0;
        right: 0;
        left: 0;
        background-color: var(--bg-color);
        z-index: 999;
        display: flex;
        justify-content: center;
        align-items: center;
      }
      div.loadingText{
        display: flex;
        align-items: flex-end;
          z-index: 999;
          h1{
            color: var(--inner-text-color);
            margin-right: 10px;
            font-size: 28px;
          }
          span{
            margin-right: 10px;
            color: var(--inner-text-color);
            animation: blinking 0.6s ease-in-out infinite;

            &:nth-child(odd){
                color: var(--text-hover);
            }
            &:nth-child(4){
            animation: blinking 0.7s ease-in-out infinite;
                
            }

            &:last-child{
                margin-right: 0;
            }
          }
      }
      .loader {
        transform: rotateZ(45deg);
        perspective: 1000px;
        border-radius: 50%;
        width: 48px;
        height: 48px;
        color: #fff;
        z-index: 999;
      }
        .loader:before,
        .loader:after {
          content: '';
          display: block;
          position: absolute;
          top: 0;
          left: 0;
          width: inherit;
          height: inherit;
          border-radius: 50%;
          transform: rotateX(70deg);
          animation: 1s spin linear infinite;
        }
        .loader:after {
          color: var(--text-hover);
          transform: rotateY(70deg);
          animation-delay: .4s;
        }

      @keyframes rotate {
        0% {
          transform: translate(-50%, -50%) rotateZ(0deg);
        }
        100% {
          transform: translate(-50%, -50%) rotateZ(360deg);
        }
      }

      @keyframes rotateccw {
        0% {
          transform: translate(-50%, -50%) rotate(0deg);
        }
        100% {
          transform: translate(-50%, -50%) rotate(-360deg);
        }
      }

      @keyframes spin {
        0%,
        100% {
          box-shadow: .2em 0px 0 0px currentcolor;
        }
        12% {
          box-shadow: .2em .2em 0 0 currentcolor;
        }
        25% {
          box-shadow: 0 .2em 0 0px currentcolor;
        }
        37% {
          box-shadow: -.2em .2em 0 0 currentcolor;
        }
        50% {
          box-shadow: -.2em 0 0 0 currentcolor;
        }
        62% {
          box-shadow: -.2em -.2em 0 0 currentcolor;
        }
        75% {
          box-shadow: 0px -.2em 0 0 currentcolor;
        }
        87% {
          box-shadow: .2em -.2em 0 0 currentcolor;
        }
      }
   

      @keyframes blinking {
        0%{
            opacity: 1;
        }
        100%{
            opacity: 0;
        }
      }
      
}