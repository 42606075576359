div.loginPage { 
    background-image: url("./assets/Images/login_bg.svg");
    background-repeat: no-repeat;
    // background-position: 50%;
    &.gaia{
        background-image: none;
    }
    span.error{
        color: #E72A31;
        font-size: 12px;
        text-align: start;
       display: block;
       margin-bottom: 10px;

    }
    div.updatesContainer{
        text-align: center;
        padding-top: 100px;
        width: 50%;
        margin: 0 auto -90px;
        @media all and (max-width:1200px){
            width: 60%;
        }
        @media all and (max-width:640px){
            width: 90%;
        }
        p{
            background-color: #fff;
            font-size: 14px;
            color: var(--error-color);
            padding: 10px;
            border-radius: 5px;
            font-weight: 600;
            border-bottom: 4px solid var(--error-color);
        }
    }
	div.loginContainer { 
        width: 20rem;
        margin: 0 auto;
        padding: 130px 0;
        text-align: center;
        
        @media all and (max-width:640px){
            width: 95%;
        }

        &.gaia {
            width: 30rem;
            padding-right: 20px;
            padding-left: 20px;
            border-radius: 2px;
            background: linear-gradient(
                to bottom,
                rgba(0, 0, 0, 0) 0%,    /* Light fade at the top */
                rgba(0, 0, 0, 0) 20%,   /* Lighter fading early */
                rgba(0, 0, 0, 0.03) 60%      /* Fully transparent towards the center */
              ),
              linear-gradient(
                to top,
                rgba(0, 0, 0, 0) 0%,    /* Light fade at the bottom */
                rgba(0, 0, 0, 0) 20%,   /* Lighter fading early */
                rgba(0, 0, 0, 0.03) 60%      /* Fully transparent towards the center */
              );
            background-size: 100% 80%;
            background-position: center;
            background-repeat: no-repeat;
            @media(max-width: 480px){
                width: 20rem;
            }
          }
          

		div.top { 

			
            div#appleid-signin {
                height: 60px;
                cursor: pointer;
                padding: 5px;
                border: transparent;
                border-radius: 10px;
                transition: border 5s ease;

                &:hover {
                    border: 1px solid transparent;
                }
            }

            div.userIconContainer {
                width: 60px;
                height: 60px;
                border-radius: 50%;
                border: 2px solid var(--border-color);
                display: flex;
                justify-content: center;
                align-items: center;
                margin: 0 auto 10px;
				svg { 
                    width: 20px !important;
                    height: 20px !important;
                    color: var(--text-color);
				}
			}

			h1 { 
                color: var(--text-color);
                margin-bottom: 20px;
                @media all and (max-width:640px){
                    font-size: 25px;
                }
			}

			div.facebookContainer { 
                display: flex;
                padding: 18px 20px;
                border-radius: 8px;
                align-items: center;
                cursor: pointer;
                transition: 0.2s ease-in-out;
                justify-content: center;
                position: relative;
                margin-bottom: 10px;
                &:hover{
                    div.background{

                        transform: scale(1.0476190476,1.0357142857);
                    }
                }
                div.background{
                    position: absolute;
                    top: 0;
                    left: 0;
                    right: 0;
                    bottom: 0;
                    background-color: #3d6ac3;
                    z-index: 1;
                    transition: 0.1s ease-in-out;
                border-radius: 8px;
                    

                }
                div.faceBookPackage{
                    // display: none;
                }
                div.contents{
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    position: relative;
                    z-index: 2;
                }
                
				div.left { 
                    line-height: 0;

                    margin-right: 50px;
					svg { 
                        width: 20px !important;
                        height: 20px !important;
                        color: var(--text-color);
                        line-height: 0;
					}
				}

				div.right { 

					span { 
                        color: var(--text-color);
                        font-weight: 700;
                        line-height: 0;
                        font-size: 15px;

					}
				}
			}

            div.googleContainer { 
                display: flex;
                padding: 18px 20px;
                border-radius: 8px;
                align-items: center;
                cursor: pointer;
                transition: 0.2s ease-in-out;
                justify-content: center;
                position: relative;
                margin-bottom: 10px;
                &:hover{
                    div.background{

                        transform: scale(1.0476190476,1.0357142857);
                    }
                }
                div.background{
                    position: absolute;
                    top: 0;
                    left: 0;
                    right: 0;
                    bottom: 0;
                    background-color:#fff;
                    z-index: 1;
                    transition: 0.1s ease-in-out;
                border-radius: 8px;
                    

                }
                div.contents{
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    position: relative;
                    z-index: 2;
                }
                
				div.left { 
                    line-height: 0;

                    margin-right: 50px;
					svg { 
                        width: 20px !important;
                        height: 20px !important;
                        color: var(--text-color);
                        line-height: 0;
					}
				}

				div.right { 

					span { 
                        color: #3c4043;
                        font-weight: 700;
                        line-height: 0;
                        font-size: 15px;

					}
				}
			}

			div.seperate { 
                display: flex;
                align-items: center;
                margin-top: 15px;
                span{
                    font-size: 12px;
                    color: var(--text-color);

                }
				span.line { 
                    height: 0.1px;
                    width: 45%;
                    background-color: var(--inner-text-color);
                    &:first-child{
                        margin-right: 8px;
                    }
                    &:last-child{
                        margin-left: 8px;
                    }
				}

				span { 

				}
			}
		}

        div.buttonContainer { 
            display: flex;
        padding: 18px 20px;
        border-radius: 4px;
        align-items: center;
        margin-bottom: 15px;
        cursor: pointer;
        transition: 0.2s ease-in-out;
        justify-content: center;
        position: relative;
        &:hover{
            div.background{

                transform: scale(1.0476190476,1.0357142857);
            }
        }
            div.background{
                background: rgba(150, 153, 163, 0.16);
                position: absolute;
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
                border-radius: 4px;
                transition: 0.1s ease-in-out;

            }
            button { 
                background: transparent;
                font-size: 15px;
                color: var( --text-color);
                font-weight: 600;

            }
        }
		div.bottom { 
            margin-top: 15px;
			h3 { 
                color: var(--text-color);
                font-size: 16px;
                font-weight: 600;
                margin-bottom: 15px;
			}

			form { 
                margin-bottom: 20px;
				div.emailContainer { 
                    // background-color: var(--text-color);
                    background-color: white;
                    width: 100%;
                    margin-bottom: 10px;
                    border-radius: 8px;

					input { 
                        background: transparent;
                        color: black;
                        outline: none;
                        border: none;
                        text-align: start;
                        width: 100%;
                    padding: 20px 15px;
                    border-radius: 8px;
                    &::placeholder{
                        font-size: 15px;
                        color: rgb(111, 113, 115);
                    }


					}
				}

				div.passwordContainer { 

					// background-color: var(--text-color);
                    background-color: white;
                    width: 100%;
                    margin-bottom: 10px;
                    border-radius: 8px;

					input { 
                        background: transparent;
                        color: black;
                        outline: none;
                        border: none;
                        text-align: start;
                        width: 100%;
                    padding: 20px 15px;
                    border-radius: 8px;
                    &::placeholder{
                        font-size: 15px;
                        color: rgb(111, 113, 115);
                    }


					}
				}

				div.buttonContainer { 
                    display: flex;
                padding: 18px 20px;
                border-radius: 4px;
                align-items: center;
                margin-bottom: 15px;
                cursor: pointer;
                transition: 0.2s ease-in-out;
                justify-content: center;
                position: relative;
                &:hover{
                    div.background{

                        transform: scale(1.0476190476,1.0357142857);
                    }
                }
                    div.background{
                        background: rgba(150, 153, 163, 0.16);
                        position: absolute;
                        top: 0;
                        left: 0;
                        right: 0;
                        bottom: 0;
                        border-radius: 4px;
                        transition: 0.1s ease-in-out;

                    }
					button { 
                        background: transparent;
                        font-size: 15px;
                        color: var( --text-color);
                        font-weight: 600;

					}
				}

				h4.forgotPassword { 
                    color: var(--text-color);
                    font-size: 15px;
                    font-weight: 600;
                    margin-bottom: 6px;
                    cursor: pointer;
				}

				h4 { 
                    font-size: 15px;
                    color: #d5d5d6;
                    font-weight: 400;
					span { 
                        font-weight: 600;
                        font-size: 15px;
                        cursor: pointer;
					}
                    &.gaia{ 
                    color: grey;
                    }
				}
			}
            .template-9-font {
                input, select {
                  font-family: 'Gilroy-Medium' !important;
                }
                }
                .template-10-font{
                    input, select {
                        font-family: 'Avenir-Roman',sans-serif !important;
                    }
                }

			p { 
                font-size: 11px;
                color: var(--inner-text-color);
                line-height: 1.6em;
				span { 
                    color: var(--text-color);
                    cursor: pointer;
				}
			}
		}
	}
}

div.loginPage.rtl{
    .loginContainer{
        .top{
            .facebookContainer{
                div.left { 
                    margin-right: 0;
                    margin-left: 50px;
				}
            }
            .googleContainer{
                div.left { 
                    margin-right: 0;
                    margin-left: 50px;
				}
            }
            .seperate{
                span.line { 
                    &:first-child{
                        margin-right: 0;
                        margin-left: 8px;
                    }
                    &:last-child{
                        margin-left: 0;
                        margin-right: 8px;
                    }
				}
            }
        }
    }
}