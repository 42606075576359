div.gaiaDetailsScreen {
  padding-top: 60px;
  position: relative;
  .swiper-button-next {
    right: -9px !important;
    top: 45%;
    @media all and (min-width: 1200px) {
      right: 0 !important;
    }
  }
  .swiper-button-prev {
    left: -9px !important;
    top: 45%;
    @media all and (min-width: 1200px) {
      left: 0 !important;
    }
  }
  div.details {
    position: relative;
    z-index: 8;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    div.topContainer {
      width: 100%;
      margin-right: 40px;
      margin-bottom: 20px;
      display: flex;
      max-height: 700px;
      @media(max-width: 768px){
        max-height: none;
      }
      div.imageContainer {
        position: relative;
        width: 100%;
        overflow: hidden;
        @media(max-width: 768px){
          height: 600px;
        }
        @media(max-width: 480px){
          height: 500px;
        }
        @media (max-width: 360px){
          height: 450px;
        }
        .trailerPlaceholder {
          position: absolute;
          bottom: 20px;
          inset-inline-end: 20px;
          display: flex;
          justify-content: center;
          align-items: center;
          cursor: pointer;
          z-index: 10;
          &.hidden{
            display: none;
          }
          div.gradientTop {
            position: absolute;
            top: 0;
            z-index: 8;
            width: 100%;
            height: 20%;
            background-position: top;
            background-size: cover;
            background-repeat: no-repeat;
            background: linear-gradient(
              0deg,
              rgba(16, 20, 31, 0),
              rgba(16, 20, 31, 0.2) 19%,
              rgba(16, 20, 31, 0.4) 39%,
              rgba(16, 20, 31, 0.8)
            );
          }
          // video {
          //   display: block;
          //   width: 100%;
          // }
          .imageWrapper {
            position: relative;
            max-width: 250px;
            max-height: 150px;
            border-radius: 10px;
            overflow: hidden;
            @media(max-width: 768px){
              max-width: 150px;
              max-height: 100px;
            }
            &.hidden{
              display: none;
            }
        
            img {
              width: 100%;
              height: 100%;
              object-fit: cover;
              display: block;
              @media(max-width: 480px){
                display: none;
              }
            }
        
            &::after {
              content: '';
              position: absolute;
              top: 0;
              inset-inline-start: 0;
              width: 100%;
              height: 100%;
              background: linear-gradient(to bottom, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.8));
              z-index: 1;
            }
        
            h2 {
              position: absolute;
              top: 50%;
              left: 50%;
              transform: translate(-50%, -50%);
              font-size: 16px;
              color: white;
              text-align: center;
              padding: 5px 10px;
              border-radius: 5px;
              z-index: 2; // Ensure this is above the gradient
              @media(max-width: 480px){
                top: auto;
                left: auto;
                transform: none;
                position: relative;
                font-size: 12px;
              }
              svg{
                color: var(--button-color);
              }
            }
          }
        }
        .trailerOverlay {
          position: fixed;
          top: 0;
          inset-inline-start: 0;
          width: 100%;
          height: 100%;
          background: rgba(0, 0, 0, 0.5);
          // display: flex;
          // justify-content: center;
          // align-items: center;
          z-index: 1000;
      
          .closeIcon1{
            position: relative;
            cursor: pointer;
            height: 25px;
            width: 25px;
            top: 22%;
            inset-inline-start: 74%;
            svg{
              color: var(--white-color);
            }
            @media (max-width: 768px){
              inset-inline-start: 82%;
            }
            @media (max-width: 480px){
              inset-inline-start: 94%;
            }
            @media (max-width: 360px){
              inset-inline-start: 92%;
              top: 25%;
            }
            @media (max-width: 320px){
              inset-inline-start: 91%;
              top: 27%;
            }
          }
      
          .trailer{
            // position: fixed;
            // top: 25%;
            // left: 25%;
            // height: 50%;
            // width: 50%;
            // justify-content: center;
            // align-items: center;
            position: relative;
            top: 22%;
            padding-right: 25%;
            padding-left: 25%;
            @media(max-width: 768px){
                padding-right: 15%;
                padding-left: 15%;
            }
            @media(max-width: 480px){
              padding-right: 3%;
              padding-left: 3%;
          }
          @media(max-width: 360px){
              top: 25%;
          }
          @media(max-width: 320px){
              top: 27%;
          }
      
            video {
              width: 100%;
              border-radius: 10px;
            }
          }
        }
        div.player {
          position: relative;
          z-index: 9;
          width: 100%;
          display: block;
          div.closeIcon2 {
            display: none;
          }
          &.pipMode {
            position: fixed;
            top: 110px;
            width: 22%;
            right: 0;
            z-index: 999999999;
            height: 200px;
    
            @media all and (max-width: 768px) {
              width: 40%;
            }
            @media all and (max-width: 640px) {
              width: 50%;
            }
            @media all and (max-width: 480px) {
              width: 68%;
            }
            video {
              object-fit: cover;
              height: 100%;
            }
            // .video-js {
            //   padding: 0;
            //   height: 100%;
            // }
            div.gradientTop {
              display: none;
            }
            &:hover {
              div.closeIcon2 {
                cursor: pointer;
                position: absolute;
                top: 10px;
                inset-inline-start: 10px;
                z-index: 9;
                // background-color: var(--bg-color);
                height: 25px;
                width: 25px;
                display: flex;
                justify-content: center;
                align-items: center;
                border-radius: 50%;
                svg {
                  // color: var(--text-color);
                  color: var(--bg-color);
                }
              }
              div.gradientTop {
                display: block;
              }
            }
          }
        }
        
        button.items {
          position: absolute; 
          top: 0; 
          inset-inline-start: 0; 
          cursor: pointer;
          background: none; 
          border: none;
          padding: 0;
          font-size: 15px;
          color: var(--yellow-color); 
          animation: blink 1.5s infinite; 
          text-decoration: none;
          z-index: 999;
          margin-left: 3%;
          margin-top: 2%;
        }
        
        /* Blinking effect */
        @keyframes blink {
          0%, 100% {
            opacity: 1;
          }
          50% {
            opacity: 0.5;
          }
        }
        
        /* Optional: Add hover styles for better interactivity */
        button.items:hover {
          text-decoration: underline;
          opacity: 1; /* Ensure full opacity on hover */
        }
        
        
        div.showMetadata {
          padding-left: 3%;
          width: 50%;
          position: absolute;
          z-index: 2;
          color: white;
          top: 50%;
          transform: translateY(-50%);
          @media(max-width: 980px){
            padding-left: 2%;
            padding-right: 2%;
          }
          @media (max-width: 768px){
            bottom: 0;
            transform: none;
          }
          @media(max-width: 480px){
            top: 35%;
          }
          // @media(max-width: 360px){
          //   top: 25%;
          // }
          div.categoryName{
            font-size: 16px;
            // font-family: 'Avenir-Roman',sans-serif;
            margin-bottom: 20px;
            padding-left: 12px;
            border-left: 3px solid var(--button-color);
            &.hidden{
                display: none;
            }
            @media (max-width: 1600px){
              font-size: 14px;
            }
            @media (max-width: 1200px){
              font-size: 12px;
            }
            @media (max-width: 980px){
              font-size: 10px;
            }
          }
          h1.name { 
            font-size: 44px;
            padding-bottom: 10px;
            // font-family: "Avenir-Black",sans-serif;
            @media (max-width: 1600px){
              font-size: 36px;
            }
            @media all and (max-width: 1200px) {
              font-size: 28px;
            }
            @media (max-width: 980px){
              font-size: 18px;
            }
            @media (max-width: 768px){
              width: 200%;
            }
            @media(max-width: 480px){
              font-size: 15px;
            }
          }
          p.description {
            font-size: 16px;
            // font-family: 'Avenir-Roman',sans-serif;
            line-height: 1.4em;
            letter-spacing: 0.3px;
            max-width: 100%;
            display: -webkit-box;
            -webkit-line-clamp: 3;
            -webkit-box-orient: vertical;
            overflow: hidden;
            text-overflow: ellipsis;
            padding-bottom: 20px;

            @media (max-width: 1600px){
              font-size: 14px;
            }
          
            @media all and (max-width: 1200px) {
              font-size: 12px;
            }
            @media (max-width: 980px){
              font-size: 10px;
            }
            @media (max-width: 768px){
              max-width: none;
              width: 200%;
            }
            @media(max-width: 480px){
              font-size: 8px;
              padding-bottom: 10px;
            }
          }
          span.see-more {
            color: white;
            cursor: pointer;
            font-weight: bold;
            text-decoration: underline;
            &:hover{
              color: var(--button-color);
            }
          }
          div.spanning{
            font-size: 21px;
            padding-bottom: 20px;
            // font-family: "Avenir-Roman",sans-serif;
            @media (max-width: 1600px){
              font-size: 18px;
            }
            @media (max-width: 1200px){
              font-size: 16px;
            }
            @media (max-width: 980px){
              font-size: 14px;
            }
            @media(max-width: 768px){
              width: 200%;
            }
            @media all and (max-width: 640px) {
              font-size: 14px;
            }
            @media(max-width: 480px){
              font-size: 10px;
              padding-bottom: 10px;
            }
          span {
            padding-inline-end: 20px;
            &.year {
            }
            &.duration {
              // &.showDot{
  
              //   &::before {
              //     content: "·";
              //     margin-right: 6px;
              //   }
              // }
              &.ltr{
                direction: ltr !important;
                unicode-bidi: embed !important;
              }
            }
            &.certificate {
              // display: block;
              padding: 4px 6px;
              margin-inline-end: 20px;
              background-color: rgba(150, 153, 163, 0.32);
              font-size: 11px;
              font-weight: 700;
              color: var(--white-color);
              border-radius: 4px;
              @media all and (max-width: 640px) {
                font-size: 10px;
              }
            }
            &.director{
            }
          }
        }
          div.genres {  
            display: flex;
            flex-wrap: nowrap; 
            overflow-x: auto;
            overflow-y: hidden; 
            gap: 10px;
            align-items: center;
            width: 100%;
            max-width: 100%; 
            white-space: nowrap; 
            box-sizing: border-box;
            margin-bottom: 30px;
            padding-bottom: 5px;
            // scrollbar-width: none; /* Firefox */
            scrollbar-width: thin; /* Set thin scrollbar */
            scrollbar-color: rgba(58, 56, 56, 0.5) transparent;
            -ms-overflow-style: none; /* IE and Edge */
            ::-webkit-scrollbar {
            display: none; /* Chrome, Safari, and Edge */
          }
            // @media (max-width: 980px){
            //   padding-bottom: 20px;
            // }
            @media (max-width: 768px){
              max-width: none;
              width: 200%;
            }
            @media(max-width: 640px){
              margin-bottom: 8px;
            }
            // @media(max-width: 480px){
            //   padding-bottom: 5px;
            // }
            span {
              background: hsla(0, 0%, 100%, .21);
              font-family: "Avenir-Medium", sans-serif;
              padding: 5px 20px;
              font-size: 16px;
              border-radius: 25px;
              border-width: 0;
              cursor: pointer;
              transition: background-color 0.3s ease;
              white-space: nowrap; 
              // max-width: 150px; 
              @media (max-width: 1600px){
                font-size: 14px;
              }
              @media (max-width: 1200px){
                font-size: 12px;
              }
              @media (max-width: 980px){
                font-size: 10px;
              }
              @media(max-width: 480px){
                font-size: 8px;
              }
        
              &:hover {
                background-color: var(--grey-color);
              }
            }
          }
          div.watchNow {
            width: 30%;
            position: absolute;
            z-index: 2;
            @media (max-width: 768px){
              width: 100%;
            }
            @media (max-width: 480px){
              width: 192%;
            }
            button {
              font-family: "Avenir-Black",sans-serif;
              display: block;
              background: var(--button-color);
              padding: 15px 10px;
              font-size: 24px;
              color: var(--white-color) !important;
              width: 100%;
              cursor: pointer;
              transition: 0.3s ease-in-out;

              @media (max-width: 1600px){
                font-size: 20px;
              }
              @media (max-width: 1200px){
                font-size: 16px;
              }
              @media (max-width: 980px){
                font-size: 14px;
              }
              &:hover {
                background: var(--button-hover);
              }
            }
          }
          div.subscriptions {
            margin-top: 10%;
            // font-family: 'Avenir-Roman',sans-serif;
            white-space: nowrap;
            overflow-x: auto;
            overflow-y: hidden;
            width: 200%;
            scrollbar-width: none; /* Firefox */
            -ms-overflow-style: none; /* IE and Edge */
            ::-webkit-scrollbar {
            display: none; /* Chrome, Safari, and Edge */
          }
          @media (max-width: 1768px){
            margin-top: 11%;
          }
          @media (max-width: 1400px){
            margin-top: 12%;
          }
            @media(max-width: 1200px){
              margin-top: 13%;
            }
            @media(max-width: 980px){
              margin-top: 17%;
            }
            @media(max-width: 768px){
              font-size: 14px;
              margin-top: 20%;
            }
            @media(max-width: 640px){
              margin-top: 17%;
            }
            @media(max-width: 480px){
              margin-top: 30%;
            }
            @media(max-width: 360px){
              font-size: 10px;
              margin-top: 35%;
            }
            // margin-top: 20p;
            // width: 100%;
            // display: flex;
            // flex-wrap: wrap;
            // padding-top: 30px;
            div.package-item{
              margin-left: 0px;
              margin-right: 10px;
              border-radius: 5px;
              
            }
          }
        }
      }
      
      div.imageContainer img {
        display: block;
        width: 80%;
        border-radius: 5px;
        object-fit: cover;
        float: right;
        max-height: 700px;
        @media (max-width: 768px){
          width: 100%;
        }
      }
      
      div.imageContainer::before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 80%;
        height: 100%;
        background: linear-gradient(
          to left,
          transparent,
          rgba(0, 0, 0, 0.3) 20%,
          rgba(0, 0, 0, 0.4) 40%,
          rgba(0, 0, 0, 0.95) 60%,
          rgba(0, 0, 0, 1) 80%,
          rgba(0, 0, 0, 1)
      );
        z-index: 1;
        @media (max-width: 768px){
          width: 100%;
          background: linear-gradient(
          to bottom,
          transparent,
          rgba(0, 0, 0, 0) 20%,
          rgba(0, 0, 0, 0.9) 40%,
          rgba(0, 0, 0, 1) 60%,
          rgba(0, 0, 0, 1) 80%,
          rgba(0, 0, 0, 1)
        );
        }
      }

    }

    div.bottomContainer {
      padding-top: 20px;
      padding-left: 3.5%;
      padding-right: 3.5%;
      width: 100%;
      color: var(--text-color);
      // font-family: "Avenir-Roman",sans-serif;
      &::-webkit-scrollbar {
        display: none;
      }
      -ms-overflow-style: none; /* IE and Edge */
      scrollbar-width: none; /* Firefox */

      div.sectionToggle {
        display: flex;
        flex-direction: row;
        gap: 50px;
        position: relative;
        margin-bottom: 30px;

        &::after {
          content: "";
          position: absolute;
          bottom: -20px;
          left: 0;
          right: 0;
          height: 1px;
          background-color: var(--grey-color);
        }
      
        h1 {
          font-size: 19px;
          cursor: pointer;
          font-family: "Avenir-Roman", sans-serif;
          position: relative;
      
          &.selected {
            font-family: "Avenir-Black", sans-serif;
      
            &::after {
              content: "";
              position: absolute;
              bottom: -20px;
              left: 0;
              right: 0;
              height: 6px;
              background-color: var(--button-color);
            }
          }
        }
      }

      div.left{
        padding-top: 15px;
        padding-right: 3%;
        padding-bottom: 100px;
        div.buttons{
          margin-bottom: 40px;
          display: flex;
          align-items: center;
          gap: 25px;
          @media (max-width: 768px){
            margin-bottom: 4%;
          }
          @media (max-width: 480px){
            margin-bottom: 5%;
            flex-direction: column;
            flex-flow: wrap;
            // left: 0;
          }

          button {
            display: flex;
            align-items: center;
            background: transparent;
            border: none;
            color: var(--text-color);
            // color: white;
            // font-size: 14px;
            font-size: 16px;
            // font-family: 'Avenir-Medium', sans-serif;
            cursor: pointer;
            white-space: nowrap; 
            max-width: fit-content;
            transition: fill 0.3s ease, color 0.5s ease;

            @media (max-width: 767px){
              // font-size: 12px;
              font-size: 14px;
            }

            svg {
              margin-right: 5px;
              width: 40px;
              color: var(--button-color);
              
              @media(max-width: 767px){
                width: 30px;
              }
            }
            &:hover {
              color: var(--button-hover);
            }
          }
          button.addtoList {
            circle {
              transition: fill 0.3s ease, color 0.5s ease; 
              fill: var(--button-color);
              stroke: none;
            }
            path{
              transition: fill 0.3s ease, color 0.5s ease; 
              // stroke: white;
              // fill: white;
              stroke: var(--bg-color);
              fill: var(--bg-color);
            }
              &:hover circle {
                transition: fill 0.3s ease, color 0.5s ease; 
                fill: var(--button-hover);
              }
              &:hover path{
                transition: fill 0.3s ease, color 0.5s ease;
                // fill: var(--button-hover);
              }
          }
          div.shareWrapper{
            position: relative;
          button.share {
            svg{
              height: 35px;
            }
            path{
              transition: fill 0.3s ease, color 0.5s ease; 
              fill: var(--button-color);
              }
              &:hover{
                path{   
                transition: fill 0.3s ease, color 0.5s ease;
                fill: var(--button-hover);
            }
                }
          }
          div.shareContainer {
            // position: absolute;
            // bottom: -126px;
            // left: -20px;
            z-index: 9;
            position: absolute;
            // background-color: #1c1f29;
            border: 1px solid var(--border-color);
            width: 150px;
            border-radius: 8px;
            display: none;
            // @media all and (max-width: 1200px) {
            //   width: 200px;
            // }
            @media all and (max-width: 980px) {
              // width: 232px;
              left: 50%;
              transform: translateX(-50%);
            }
            // @media all and (max-width: 480px) {
            //   // width: 100%;
            // }
            &.active {
              display: block;
              background-color: var(--bg-color);
              z-index: 10;
              // overflow: visible;
            }
            div.facebookContainer {
              button.facebook {
                display: flex !important;
                justify-content: space-between !important;
                padding: 15px !important;
                color: var(--text-color) !important;
                cursor: pointer !important;
                border-bottom: 1px solid var(--border-color) !important;
                width: 100% !important;
                div.icon {
                  width: 10%;
                  svg {
                    width: 20px !important;
                    height: 20px !important;
                    // color: var(--grey-color);
                    color: var(--button-color);
                    transition: 0.3s ease-in-out;
                    &:hover {
                      // color: var(--text-color);
                      color: var(--button-hover);
                    }
                  }
                }
              }
              div.left {
                width: 10%;
              }
              div.right {
                svg {
                  width: 20px !important;
                  height: 20px !important;
                  color: var(--grey-color);
                  transition: 0.3s ease-in-out;
                  &:hover {
                    color: var(--text-color);
                  }
                }
              }
            }
            div.twitterContainer {
              button.twitter {
                display: flex !important;
                justify-content: space-between !important;
                padding: 15px !important;
                color: var(--text-color) !important;
                cursor: pointer !important;
                // border-bottom: 1px solid var(--border-color) !important;
                width: 100% !important;
                div.icon {
                  width: 10%;
                  svg {
                    width: 20px !important;
                    height: 20px !important;
                    // color: var(--grey-color);
                    color: var(--button-color);
                    transition: 0.3s ease-in-out;
                    &:hover {
                      // color: var(--text-color);
                      color: var(--button-hover);
                    }
                  }
                }
              }
              div.left {
                width: 10%;
              }
              div.right {
                svg {
                  width: 20px !important;
                  height: 20px !important;
                  color: var(--grey-color);
                  transition: 0.3s ease-in-out;
                  &:hover {
                    color: var(--text-color);
                  }
                }
              }
            }
          }
        }
          button.reportProblemContainer {
            // position: absolute;
            // bottom: -66px;
            right: 0;
            z-index: 9;
            // background-color: #1c1f29;
            // border: 1px solid var(--border-color);
            width: 200px;
            border-radius: 8px;
            // padding: 15px;
            // display: none;
            cursor: pointer;
            // &.active {
            //   display: block;
            
            &:hover{
              svg{
                path{
              fill: var(--button-hover);
                }
              }
            }
            // }
            svg{
              height: 30px;
              @media(max-width: 767px){
                height: 25px;
              }
            path{
            fill: var(--button-color);
          }}
            // @media all and (max-width: 1200px) {
            //   width: 164px;
            // }
            // @media all and (max-width: 980px) {
            //   width: 200px;
            // }
  
            span {
              color: var(--text-color);
            }
          }
        }

        div.metadata{
          p.description {
            font-size: 16px;
            color: var(--text-color);
            line-height: 1.4em;
            letter-spacing: 0.3px;
            max-width: 100%;
            display: -webkit-box;
            -webkit-line-clamp: 3;
            // -webkit-box-orient: vertical;
            overflow: hidden;
            text-overflow: ellipsis;
          
            @media all and (max-width: 1200px) {
              max-width: 100%;
            }
          }
          div.info {
            margin-top: 30px;
            div.starring {
              display: flex;
              justify-content: space-between;
              margin-bottom: 10px;
              span.left {
                width: 15%;
                @media all and (max-width: 768px) {
                  width: 20%;
                }
                @media all and (max-width: 640px) {
                  width: 25%;
                }
                @media all and (max-width: 480px) {
                  width: 30%;
                }
                @media all and (max-width: 360px) {
                  width: 33%;
                }
  
                h6 {
                  font-size: 16px;
                  // font-weight: 400;
                  color: var(--inner-text-color);
                }
              }
  
              span.right {
                width: 85%;
                @media all and (max-width: 980px) {
                  width: 75%;
                }
                @media all and (max-width: 640px) {
                  width: 70%;
                }
                @media all and (max-width: 480px) {
                  width: 65%;
                }
                @media all and (max-width: 360px) {
                  width: 55%;
                }
                ul.names {
                  display: flex;
                  flex-wrap: wrap;
                  li {
                    font-size: 16px;
                    // font-weight: 600;
                    margin-right: 8px;
                    color: var(--text-color);
                    &:last-child {
                      margin-right: 0;
                    }
                    @media all and (max-width: 480px) {
                      font-size: 14px;
                    }
                  }
                }
              }
            }
          }
          div.director {
            display: flex;
            justify-content: space-between;
            margin-bottom: 30px;
            span.left {
              width: 15%;
              @media all and (max-width: 768px) {
                width: 20%;
              }
              @media all and (max-width: 640px) {
                width: 25%;
              }
              @media all and (max-width: 480px) {
                width: 30%;
              }
              @media all and (max-width: 360px) {
                width: 33%;
              }

              h6 {
                font-size: 16px;
                // font-weight: 400;
                color: var(--inner-text-color);
              }
            }

            span.right {
              width: 85%;
              @media all and (max-width: 980px) {
                width: 75%;
              }
              @media all and (max-width: 640px) {
                width: 70%;
              }
              @media all and (max-width: 480px) {
                width: 65%;
              }
              @media all and (max-width: 360px) {
                width: 55%;
              }
              h6 {
                font-size: 16px;
                // font-weight: 600;
                margin-right: 8px;
                color: var(--text-color);
                @media all and (max-width: 480px) {
                  font-size: 14px;
                }
              }
            }
          }
          div.userRatings{
            display: flex;
            // margin-left: 15px;
            // @media all and (max-width:360px){
            //   margin-left: 10px;
            // }
            margin-bottom: 10px;
            div.star{
              &.active{
                svg{
                  fill: #fbe725;
                }
              }
              svg {
                width: 23px !important;
                height: 23px !important;
                fill: var(--grey-color);
                transition: 0.3s ease-in-out;
              }
            }
           
          }
          div.addRating{
            // margin-left: 15px;
            width: 10px;
            cursor: pointer;
            margin-bottom: 10px;
            &:hover{
              svg{
                fill: var(--grey-color);
              }
            }
            svg {
              width: 27px !important;
              height: 27px !important;
              fill: var(--button-color);
              transition: 0.3s ease-in-out;
            }
          }
          div.categories {
            padding: 30px 0px ;
            background-color: transparent !important;
            @media all and (max-width: 768px) {
              padding: 30px 30px;
            }
            h1.heading {
              font-size: 25px;
              color: var(--text-color);
              margin-bottom: 20px;
              text-transform: capitalize;
            }
            div.categoryLists {
              ul {
                display: flex;
                flex-wrap: wrap;
                li.list {
                  display: flex;
                  margin-inline-end: 10px;
                  width: 24%;
                  background-color: var(--bg-color);
                  margin-bottom: 10px;
              cursor: pointer;
        
                  &:nth-child(4n) {
                    margin-inline-end: 0;
                  }
                  @media all and (max-width: 1200px) {
                    width: 32.6%;
                    &:nth-child(3n) {
                      margin-inline-end: 0;
                    }
                    &:nth-child(4n) {
                      margin-inline-end: 10px;
                    }
                  }
                  @media all and (max-width: 980px) {
                    width: 49%;
                    &:nth-child(3n) {
                      margin-inline-end: 10px;
                    }
                    &:nth-child(2n) {
                      margin-inline-end: 0;
                    }
                  }
                  @media all and (max-width: 640px) {
                    width: 100%;
                    &:nth-child(3n) {
                      margin-inline-end: 0;
                    }
                    &:nth-child(2n) {
                      margin-inline-end: 0;
                    }
                    margin-inline-end: 0;
                  }
                  div.left {
                    div.imageContainer {
                      width: 70px;
                      img {
                        display: block;
                        width: 100%;
                      }
                    }
                  }
                  div.right {
                    padding: 10px;
                    h1.title {
                      font-size: 14px;
                      // text-transform: uppercase;
                      color: var(--text-color);
                    }
                  }
                }
              }
            }
          }
          div.aboutSection {
            background-color: transparent !important;
            padding: 30px 0px;
            @media all and (max-width: 768px) {
              padding: 30px 30px;
            }
            h1.heading {
              font-size: 25px;
              color: var(--text-color);
              margin-bottom: 20px;
            }
            div.aboutLists {
              display: flex;
              flex-wrap: wrap;
              ul{
              flex-direction: column;
              // width: 208px;
            width: 100%;
             div.aboutContainer{
              background-color: transparent;
              width: 100%;
              justify-content: flex-start;
              display: flex;
              flex-direction: column;
              // width: 208px;
              li.aboutlist {
                width: 100%;
                background-color: var(--white-color);
                margin-inline-end: 7px;
                // margin-bottom: 20px;
                cursor: pointer;
                
                &:hover {
                  div.info {
                    h1.name {
                      color: var(--grey-color);
                    }
                  }
                }
                &:nth-child(8n) {
                  margin-inline-end: 0;
                }
                &:last-child {
                  margin-inline-end: 0 !important;
                }
                @media all and (max-width: 2600px) {
                  width: 12%;
                  &:nth-child(7n) {
                    margin-inline-end: 0;
                  }
                  &:nth-child(8n) {
                    margin-inline-end: 7px;
                  }
                }
                @media all and (max-width: 1400px) {
                  width: 14%;
                  &:nth-child(7n) {
                    margin-inline-end: 0;
                  }
                  &:nth-child(8n) {
                    margin-inline-end: 7px;
                  }
                }
                @media all and (max-width: 1200px) {
                  width: 19%;
                  &:nth-child(7n) {
                    margin-inline-end: 7px;
                  }
                  &:nth-child(6n) {
                    margin-inline-end: 0;
                  }
                }
                @media all and (max-width: 980px) {
                  width: 24%;
                  &:nth-child(4n) {
                    margin-inline-end: 0;
                  }
                  &:nth-child(6n) {
                    margin-inline-end: 7px;
                  }
                }
                @media all and (max-width: 768px) {
                  width: 32%;
                  &:nth-child(4n) {
                    margin-inline-end: 0;
                  }
                  &:nth-child(6n) {
                    margin-inline-end: 7px;
                  }
                }
                
                @media all and (max-width: 480px) {
                  width: 49%;
                  &:nth-child(3n) {
                    margin-inline-end: 7px;
                  }
                  &:nth-child(2n) {
                    margin-inline-end: 0;
                  }
                }
                @media all and (max-width: 360px) {
                  width: 99%;
                  &:nth-child(3n) {
                    margin-inline-end: 7px;
                  }
                  &:nth-child(2n) {
                    margin-inline-end: 0;
                  }
                }
               
                div.imageContainer {
                  width: 100%;
                  background-color: var(--white-color);
                  margin-inline-end: 7px;
                  // margin-bottom: 20px;
                  position: relative;
                  align-content: space-around;
                  img {
                    width: 100%;
                    align-items: center;
                    display: block;
                    
                  }
                  div.openurl{
                    position:absolute;
                    z-index: 99;
                    margin-top: -44px;
                    inset-inline-end: 0;
                   svg{
                    fill: #fff !important;
                    width:20px;
                    stroke: #fff;
                   }
                   
                  }
                 
                }
                div.info {
                  padding: 10px 10px 30px;
                  background: #fff;
                  padding: 8px;
                  min-height: 121px;
                  max-height: 121px;
        
                  h1.title {
                    font-size: 16px;
                    color: var(--text-hover);
                  }
                  p.description{
        
                     color: #666;
                    font-weight: 400;
                    font-size: 12px;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    display: -webkit-box;
                    -webkit-line-clamp: 3;
                    -webkit-box-orient: vertical;
                    margin-top: 10px;
                   
                  }
                }
              }
            }
          } 
          }
          }
          div.awardsAndFestivals {
            padding: 30px 0px 0;
            background-color: transparent !important;
            align-items: center;
            h1.heading {
              font-size: 25px;
              color: var(--text-color);
              margin-bottom: 20px;
            }
            ul.lists {
              display: flex;
              flex-wrap: wrap;
              align-items: center;
              align-content: center;
              li.list {
                margin-inline-end: 10px;
                width: 24%;
                margin-bottom: 20px;
                text-align: center;
                align-items: center;
                &:nth-child(4n) {
                  margin-inline-end: 0;
                }
                @media all and (max-width: 1200px) {
                  width: 32.6%;
                  &:nth-child(3n) {
                    margin-inline-end: 0;
                  }
                  &:nth-child(4n) {
                    margin-inline-end: 10px;
                  }
                }
                @media all and (max-width: 980px) {
                  width: 49%;
                  &:nth-child(3n) {
                    margin-inline-end: 10px;
                  }
                  &:nth-child(2n) {
                    margin-inline-end: 0;
                  }
                }
                @media all and (max-width: 640px) {
                  width: 100%;
                  &:nth-child(3n) {
                    margin-inline-end: 0;
                  }
                  &:nth-child(2n) {
                    margin-inline-end: 0;
                  }
                  margin-inline-end: 0;
                }
                div.imageContainer {
                  margin: 0 auto 20px;
                  width: 40px;
                  filter: grayscale(100%);
                  background: rgb(200, 200, 200);
                  margin-bottom: 20px;
                  align-items: center;
                  img {
                    display: block;
                    width: 100%;
                  }
                }
                h1.title {
                  color: var(--text-color);
                  font-size: 14px;
                  margin-bottom: 10px;
                 
                }
                div.descriptionAndYear{
                  display: flex;
                  justify-content: center;
                  span.year{
                    margin-inline-end: 6px;
                    font-size: 13px;
                    color:var(--grey-color);
                    font-weight: normal;
                  }
                  p.description{
                    font-size: 13px;
                    color:var(--grey-color);
                    font-weight: normal;
                  }
                }
              }
            }
          }
          div.castAndCrew {
            background-color: transparent !important;
            padding: 30px 0px;
            @media all and (max-width: 768px) {
              padding: 30px 30px;
            }
        
            h1.heading {
              font-size: 25px;
              color: var(--text-color);
              margin-bottom: 20p
            }
            ul.lists {
              display: flex;
              flex-wrap: wrap;
        
              li.list {
                width: 15%;
                background-color: var(--bg-color);
                margin-inline-end: 7px;
                cursor: pointer;
                
                &:hover {
                  div.info {
                    h1.name {
                      color: var(--text-hover);
                    }
                  }
                }
                &:nth-child(8n) {
                  margin-inline-end: 0;
                }
                &:last-child {
                  margin-inline-end: 0 !important;
                }
                @media all and (max-width: 1400px) {
                  width: 13.7%;
                  &:nth-child(7n) {
                    margin-inline-end: 0;
                  }
                  &:nth-child(8n) {
                    margin-inline-end: 7px;
                  }
                }
                @media all and (max-width: 1200px) {
                  width: 19%;
                  &:nth-child(7n) {
                    margin-inline-end: 7px;
                  }
                  &:nth-child(6n) {
                    margin-inline-end: 0;
                  }
                }
                @media all and (max-width: 980px) {
                  width: 24%;
                  &:nth-child(4n) {
                    margin-inline-end: 0;
                  }
                  &:nth-child(6n) {
                    margin-inline-end: 7px;
                  }
                }
                @media all and (max-width: 768px) {
                  width: 32.5%;
                  margin-bottom: 2%;
                  &:nth-child(4n) {
                    margin-inline-end: 7px;
                  }
                  &:nth-child(3n) {
                    margin-inline-end: 0px;
                  }
                }
                @media all and (max-width: 640px) {
                  width: 32%;
                  &:nth-child(4n) {
                    margin-inline-end: 7px;
                  }
                  &:nth-child(3n) {
                    margin-inline-end: 0px;
                  }
                }
                @media all and (max-width: 480px) {
                  width: 48%;
                  &:nth-child(3n) {
                    margin-inline-end: 7px;
                  }
                  &:nth-child(2n) {
                    margin-inline-end: 0;
                  }
                }
                @media all and (max-width: 360px) {
                  width:99%;
                }
                div.imageContainer {
                  // filter: grayscale(100%);
                  background: rgb(200, 200, 200);
                  width: 100%;
                  img {
                    width: 100%;
                    display: block;
                  }
                }
                div.info {
                  padding: 10px 10px 20px;
                  background-color: rgba(203, 203, 208, 0.16);
                  h1.name {
                    font-size: 14px;
                    color: var(--text-color);
                  }
                  p.role {
                    font-size: 12px;
                    color: var(--grey-color);
                  }
                }
              }
            }
          }
          // span.certificate {
          //   // display: block;
          //   padding: 4px 6px;
          //   background-color: rgba(150, 153, 163, 0.32);
          //   font-size: 11px;
          //   font-weight: 700;
          //   color: var(--text-color);
          //   border-radius: 4px;
          //   @media all and (max-width: 640px) {
          //     font-size: 10px;
          //   }
          // }
        }
      }

      div.right{
        margin-bottom: 200px;
        // font-family: "Avenir-Black",sans-serif;
      }

    }
  }

  div.parent {
    label {
    }

    div {
      a.fff {
      }
    }
  }
}

div.gaiaDetailsScreen.rtl{
  .details{
    .topContainer{
      margin-right: 0;
      margin-left: 40px;
      .imageContainer{
        &::before{
          left: auto;
          right: 0;
          background: linear-gradient(to right, transparent, rgba(0, 0, 0, 0.3) 20%, rgba(0, 0, 0, 0.4) 40%, rgba(0, 0, 0, 0.95) 60%, rgb(0, 0, 0) 80%, rgb(0, 0, 0));
          @media(max-width: 768px){
            width: 100%;
        background: linear-gradient(to bottom, transparent, rgba(0, 0, 0, 0) 20%, rgba(0, 0, 0, 0.9) 40%, rgb(0, 0, 0) 60%, rgb(0, 0, 0) 80%, rgb(0, 0, 0));
          }
        }
        img{
          float: left;
        }
        .showMetadata{
          padding-left: 0;
          padding-right: 3%;
          .categoryName{
            padding-left: 0;
            padding-right: 12px;
            border-right: 3px solid var(--button-color);
            border-left: none;
          }
          .spanning{
            span{
              padding-right: 0;
              padding-left: 20px;
            }
          }
        }
      }
    }
    .bottomContainer{
      .left{
        padding-right: 0;
        padding-left: 3%;
        .buttons{
          .shareWrapper{
            .shareContainer{
              @media(max-width: 360px){
                left: 40%;
                // right: 50%;
              }
            }
          }
          button{
            svg{
              margin-right: 0;
              margin-left: 5px;
            }
          }
        }
      }
    }
  }
}
