div.homePage {
  overflow-x: hidden;
  .swiper {
    overflow: visible;
  }
  .swiper-button-disabled {
    display: none !important;
  }
  
  .swiper-button-next {
    right: -82px;
    height: 100%;
    width: 6%;
    top: 5%;
    background: linear-gradient(
      -97deg,
      rgb(28, 31, 41) 0.98%,
      rgba(28, 31, 41, 0.4) 100.23%,
      rgba(28, 31, 41, 0.2) 100.16%,
      rgba(28, 31, 41, 0.0001) 52.12%
    );
    // 
    @media all and (min-width:320px){
        display: flex;
        right: -26px;
        width: 9%;
        top: 11%;
        
    }
    @media all and (min-width:360px){
        top: 9%;
        right: -42px;
    }
    @media all and (min-width:480px){
        top: 7%;
        width: 5%;
        right: -51px;
    }
    @media all and (min-width:640px){
        top: 5%;
        right: -60px;
    }
    @media all and (min-width:768px){
        width: 4%;
        right: -80px;
    }
    @media all and (min-width:980px){
        width: 4%;
        right: -47px;
    }
    @media all and (min-width:1200px){
      right: -57px;
  }
  @media all and (min-width:1400px){
    right: -67px;
}
@media all and (min-width:1600px){
  right: -70px;
}
@media all and (min-width:1768px){
  right: -79px;
}
    // @media all and (min-width:768px){
    //    width: 7%;
    // }
  }
  .swiper-button-prev {
    left: -77px;
    height: 100%;
    width: 5%;
    top: 5%;
    background: linear-gradient(
      -97deg,
      rgb(28, 31, 41) 0.98%,
      rgba(28, 31, 41, 0.4) 100.23%,
      rgba(28, 31, 41, 0.2) 100.16%,
      rgba(28, 31, 41, 0.0001) 52.12%
    );
    
    @media all and (min-width:320px){
        display: flex;
        left: -26px;
        width: 9%;
        top: 11%;

    }
    @media all and (min-width:360px){
        top: 9%;
        left: -42px;

    }
    @media all and (min-width:480px){
        top: 7%;
        width: 5%;
        left: -51px;
    }
    @media all and (min-width:640px){
        top: 5%;
        left: -60px;
    }
    @media all and (min-width:768px){
        width: 4%;
        left: -80px;
    }
    @media all and (min-width:980px){
        width: 4%;
        left: -47px;
    }
    @media all and (min-width:1200px){
      left: -57px;
  }
  @media all and (min-width:1400px){
    left: -67px;
}
@media all and (min-width:1600px){
  left: -70px;
}
@media all and (min-width:1768px){
  left: -79px;
}
  }
    
  .section-slider {
    .swiper-button-next{
      
      right: -50px;
      width: 10%;
      background-color:white;
    }

    .swiper-button-prev {
    
      left: -50px;
      background-color: white;
    }
  }
      
}
