.gaiaSectionSlider {
    max-width: 1980px;
    margin: 0 auto;
    .wrapper {
      &:hover {
        .swiper-button-next {
          &::after {
            opacity: 1;
          }
        }
  
        .swiper-button-prev {
          &::after {
            opacity: 1;
          }
        }
      }
  
      .swiper {
        @media((max-width: 2600px) and (min-width: 1981px)){
          .swiper-button-next,
          .swiper-button-prev { 
            color: white;
            width: 5% !important;
            background: var(--bg-color) !important;
      
            .svg {
              height: 60px;
              align-items: center;
              color: white;
            }
            &::after {
              font-size: 18px;
              font-weight: bolder;
              color: var(--text-color);
              position: absolute;
              z-index: 10; 
              // background-color: var(--button-color);
              box-shadow: 0 6px 20px rgba(0, 0, 0, 0.2);
      
              &:hover{
                background-color: var(--button-hover);
                transform: scale(1.3);
              }
            }
          }
          .swiper-button-next{
            inset-inline-end: -5% !important;
            &::after{
            	inset-inline-start: -21%;}
          }
          .swiper-button-prev{
            inset-inline-start: -5% !important;
            &::after{
              inset-inline-end: -27%;
            }
          }
          .swiper-button-disabled {
            opacity: 0;
            pointer-events: none;
          }
        }
        &.landscape {
          .swiper-button-next {
            height: 100%;
            inset-inline-end: -4%;
            color: white;
            // top: 9%;
            // width: 10%;
            top: 8%;
            width: 4.5% !important;
            background: linear-gradient(-97deg,
                rgb(28, 31, 41) 0.98%,
                rgba(28, 31, 41, 0.4) 100.23%,
                rgba(28, 31, 41, 0.2) 100.16%,
                rgba(28, 31, 41, 0.0001) 52.12%);
  
            // @media all and (max-width: 2600px) {
            //   top: 6%;
            //   width: 5% !important;
            //   right: -5%;
            // }
            @media((max-width: 2600px) and (min-width: 1981px)){
              margin-top: -35px;
              height: 110%;
              padding-inline-end: 35%;
              inset-inline-end: -35% !important;
            }
  
            @media(max-width: 1980px){
              width: 5% !important;
              inset-inline-end: -5%;
            }
  
            @media all and (max-width: 1600px) {
              top: 8%;
              inset-inline-end: -5%;
              width: 5% !important;
            }
  
            @media all and (max-width: 1200px) {
              top: 13%;
              inset-inline-end: -5%;
            }
  
            @media all and (max-width: 980px) {
              inset-inline-end: -9%;
              width: 9% !important;
              top: 6%;
            }
  
            @media all and (max-width: 480px) {
              top: 9%;
              width: 10% !important;
            }
            &::after{
              @media((max-width: 2600px) and (min-width: 1981px)){
                // left: -11% !important;
                inset-inline-start: -4% !important;
                }
              }
          }
  
          .swiper-button-prev {
            inset-inline-end: -4%;
            height: 100%;
            color: white;
            // width: 6%;
            width: 4.5% !important;
            top: 8%;
            background: linear-gradient(-97deg,
                rgb(28, 31, 41) 0.98%,
                rgba(28, 31, 41, 0.4) 100.23%,
                rgba(28, 31, 41, 0.2) 100.16%,
                rgba(28, 31, 41, 0.0001) 52.12%);
  
            .svg {
              height: 20px;
              align-items: center;
              color: white;
            }
  
            @media((max-width: 2600px) and (min-width: 1981px)){
              margin-top: -35px;
              height: 110%;
              padding-inline-start: 35%;
              inset-inline-start: -35% !important;
            }
  
            // @media all and (max-width: 2600px) {
            //   top: 6%;
            //   width: 5% !important;
            //   left: -5%;
            // }
  
            @media all and (max-width: 1600px) {
              top: 8%;
              width: 4.5% !important;
            }
  
            @media all and (max-width: 1200px) {
              top: 13%;
              width: 5% !important;
            }
  
            @media all and (max-width: 980px) {
              inset-inline-start: -9%;
              width: 9% !important;
              top: 6%;
            }
  
            @media all and (max-width: 480px) {
              top: 9%;
              width: 10% !important;
            }
            &::after{
              @media((max-width: 2600px) and (min-width: 1981px)){
                inset-inline-end: -5% !important;
              }
            }
          }
        }
  
        &.portrait {
          .swiper-button-next {
            margin-top: -4% !important;
            // height: 105% !important;
            inset-inline-end: -5%;
            height: 120%;
            width: 4% !important;
            background: linear-gradient(-97deg,
                rgb(28, 31, 41) 0.98%,
                rgba(28, 31, 41, 0.4) 100.23%,
                rgba(28, 31, 41, 0.2) 100.16%,
                rgba(28, 31, 41, 0.0001) 52.12%);
  
            @media((max-width: 2600px) and (min-width: 1981px)){
              padding-inline-end: 35%;
              inset-inline-end: -35% !important;
              margin-top: -35px;
              height: 130% !important;
            }
  
            &::after {
              @media (min-width: 1981px) and (max-width: 2600px) {
                inset-inline-start: -4% !important;
              }
            }
            
            @media (max-width: 1980px) {
              width: 5% !important;
            }
            
            @media all and (max-width: 1768px) {
              top: 6% !important;
            }
            
            @media all and (max-width: 1600px) {
              top: 7% !important;
            }
            
            @media all and (max-width: 1400px) {
              top: 10% !important;
            }
            
            @media all and (max-width: 1200px) {
              width: 5% !important;
              top: 11% !important;
              height: 100%;
            }
            
            @media all and (max-width: 980px) {
              display: flex;
              width: 9% !important;
              inset-inline-end: -9% !important;
              top: 10% !important;
              height: 110%;
            }
            
            @media all and (max-width: 640px) {
              width: 9% !important;
              inset-inline-end: -9% !important;
            }
            
            @media all and (max-width: 480px) {
              width: 8% !important;
              top: 4% !important;
            }
            
            @media all and (max-width: 320px) {
              width: 9% !important;
            }
            
          }
  
          .swiper-button-prev {
            margin-top: -4% !important;
            // height: 105% !important;
            inset-inline-start: -5%;
            height: 120%;
            width: 4% !important;
            background: linear-gradient(-97deg,
                rgb(28, 31, 41) 0.98%,
                rgba(28, 31, 41, 0.4) 100.23%,
                rgba(28, 31, 41, 0.2) 100.16%,
                rgba(28, 31, 41, 0.0001) 52.12%);
  
                @media((max-width: 2600px) and (min-width: 1981px)){
                  margin-top: -35px;
                  padding-inline-start: 35%;
                  inset-inline-start: -35% !important;
                  height: 130% !important;
                }
                @media (max-width: 1980px) {
                  width: 5% !important;
                }
                
                @media all and (max-width: 1768px) {
                  top: 6% !important;
                }
                
                @media all and (max-width: 1600px) {
                  top: 8% !important;
                }
                
                @media all and (max-width: 1400px) {
                  top: 10% !important;
                }
                
                @media all and (max-width: 1200px) {
                  width: 5% !important;
                  top: 11% !important;
                  height: 100%;
                }
                
                @media all and (max-width: 980px) {
                  display: flex;
                  width: 9% !important;
                  inset-inline-start: -9%;
                  top: 10% !important;
                  height: 110%;
                }
                
                @media all and (max-width: 640px) {
                  width: 9% !important;
                  inset-inline-start: -9% !important;
                }
                
                @media all and (max-width: 480px) {
                  width: 8% !important;
                  top: 4% !important;
                }
                
                @media all and (max-width: 320px) {
                  width: 9% !important;
                }
                
            &::after{
              @media((max-width: 2600px) and (min-width: 1981px)){
                inset-inline-end: -4% !important;
              }
            }
          }
        }
      }
  
      .heading-container {
        display: inline;
        align-items: center;
        cursor: pointer;
  
        &.hover {
          color: var(--text-hover);
        }
  
        h4.topHeading {
          font-size: 24px;
          margin-inline-end: 10px;
          color: var(--text-color);
          transition: 0.3s ease-in-out;
          display: inline-block;
          margin-bottom: 20px;
  
          &:hover {
            color: var(--text-hover);
          }
  
          span {
            font-size: 24px;
            display: inline-block;
            color: var(--text-color);
            height: 20px !important;
            width: 20px !important;
            transition: color 0.3s ease-in-out;
            vertical-align: sub;
            margin-inline-start: 10px;
  
            &:hover,
            &:focus {
              color: var(--text-hover);
            }
          }
  
          &:hover,
          &:focus {
            color: var(--text-hover);
          }
        }
  
        .show-all {
          color: var(--text-hover);
          font-size: medium;
  
          &:hover {
            color: var(--text-color);
          }
        }
  
      }
  
      .text-decoration-none {
        &:hover {
          color: var(--text-hover);
        }
  
        svg {
          &:hover {
            color: var(--text-hover);
          }
        }
      }
    }
  }