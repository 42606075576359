div.gaiaCategoriesContainer {
  direction: ltr;
  text-align: left;

    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding-inline-start: 4.5%;
    padding-inline-end: 4.5%;
    padding-bottom: 20px;
    padding-top: 20px;
    z-index: 10;
    max-width: 1980px;
    margin: 0 auto;
  
    @media ((max-width: 640px) and (min-width: 360px)){
      padding-top: 40px;
    }
  
    .wrapper{
      width: 100%;
    .swiper {
      width: 100%;
  
      &:hover{
        z-index: 999999;
      }
      .swiper-slide {
        display: flex;
        justify-content: center;
        align-items: center;
  
        &.swiper-slide-active {
          transition: transform 0.3s ease;
          
          &:hover {
            transform: translateX(15%);
            @media(max-width: 480px){
              transform: none;
            }
          }
        }
  
        &.swiper-slide.translate-last {
          transition: transform 0.3s ease;
          
          &:hover {
            transform: translateX(-16%);
            @media(max-width: 480px){
              transform: none;
            }
          }
        }
  
        &:hover {
          z-index: 99999;
        }
        &.no-transform {
          &.swiper-slide-active:hover,
          &.translate-last:hover {
            transform: none;
          }
        }
      }
      .swiper-button-next,
      .swiper-button-prev { 
        height: 100%;
        color: white;
        width: 5% !important;
        // top: 9%;
        background: var(--bg-color);
  
        .svg {
          height: 60px;
          align-items: center;
          color: white;
        }
        &::after {
          font-size: 18px;
          font-weight: bolder;
          color: var(--text-color);
          position: absolute;
          z-index: 10; 
          // background-color: var(--button-color);
          box-shadow: 0 6px 20px rgba(0, 0, 0, 0.2);
  
          &:hover{
            background-color: var(--button-hover);
            transform: scale(1.3);
          }
        }
  
        // &:hover {
        //   // background-color: #f0f0f0;
        //   background-color: var(--button-hover);
        // }
      }
      .swiper-button-next{
        right: -5% !important;
        // right: -20px;
        &::after{
        left: -21%;}
      }
      .swiper-button-prev{
        left: -5% !important;
        // left: -20px;
        &::after{
          right: -27%;
        }
      }
      .swiper-button-disabled {
        opacity: 0;
        pointer-events: none;
      }
      &.items{
        &.portrait{
          &.rowCount3{
            .swiper-button-next, .swiper-button-prev{
              margin-top: -40px;
              height: 110%;
              &::after{
    
              }
            }
            .swiper-button-next{
              @media(max-width: 2600px){
                padding-right: 35%;
                right: -35% !important;
              }
              &::after{
                @media(max-width: 2600px){
                  left: -11% !important;
                  }
                  @media (max-width: 1768px){
                    left: -8% !important;
                  }
                  @media(max-width: 1600px){
                    left: -5% !important;
                  }
                  @media (max-width: 1199px){
                    left: -3% !important;
                  }
              }
            }
            .swiper-button-prev{
              @media(max-width: 2600px){
                padding-left: 35%;
                left: -35% !important;
              }
              &::after{
                @media(max-width: 2600px){
                  right: -11% !important;
                  }
                  @media (max-width: 1768px){
                    right: -8% !important;
                  }
                  @media(max-width: 1600px){
                    right: -5% !important;
                  }
                  @media (max-width: 1199px){
                    right: -4% !important;
                  }
              }
            }
          }
          &.rowCount4{
            .swiper-button-next, .swiper-button-prev{
              margin-top: -35px;
              height: 110%;
              // @media (max-width: 480px){
              //   margin-top: -60px;
              // }
            &::after{
              @media(max-width: 2600px){
                top: 42% !important;
              }
              @media(max-width: 1980px){
                top: 45% !important;
              }
              @media(max-width: 1200px){
                top: 49% !important;
              }
              // @media(max-width: 480px){
              //   top: 45% !important;
              // }
              // @media(max-width: 360px){
              //   top: 45% !important;
              // }
            }
          }
            .swiper-button-next{
              @media(max-width: 2600px){
                padding-right: 35%;
                right: -35% !important;
              }
              &::after{
              @media(max-width: 2600px){
              left: -4% !important;
              }
              @media(max-width: 1400px){
                left: -5% !important;
              }
              @media(max-width: 1200px){
                left: -3% !important;
              }
              // @media(max-width: 980px){
              //   left: -4% !important;
              // }
              // @media(max-width: 768px){
              //   left: -5% !important;
              // }
              // @media(max-width: 640px){
              //   left: -7% !important;
              // }
              // @media(max-width: 480px){
              //   left: -8% !important;
              // }
              // @media(max-width: 360px){
              //   left: -10% !important;
              // }
            }
          }
            .swiper-button-prev{
              @media(max-width: 2600px){
                padding-left: 35%;
                left: -35% !important;
              }
              &::after{
                @media(max-width: 2600px){
                  right: -5% !important;
                }
                @media(max-width: 1400px){
                  right: -6% !important;
                }
                @media(max-width: 1200px){
                  right: -4% !important;
                }
                // @media(max-width: 980px){
                //   right: -5% !important;
                // }
                // @media(max-width: 768px){
                //   right: -6% !important;
                // }
                // @media(max-width: 640px){
                //   right: -8% !important;
                // }
                // @media(max-width: 480px){
                //   right: -9% !important;
                // }
                // @media(max-width: 360px){
                //   right: -12% !important;
                // }
              }
            }
          }
          &.rowCount5{
            .swiper-button-next, .swiper-button-prev{
              margin-top: -35px;
              height: 110%;
              @media (max-width: 480px){
                margin-top: -60px;
              }
              @media(width: 980px){
                height: 115%;
              }
              &::after{
                @media(max-width: 1400px){
                  top: 47% !important;
                }
                @media(max-width: 480px){
                  top: 45% !important;
                }
                @media(max-width: 360px){
                  top: 45% !important;
                }
              }
            }
            .swiper-button-next{
              @media(max-width: 2600px){
                padding-right: 35%;
                right: -35% !important;
              }
              &::after{
                @media(max-width: 2600px){
                  left: -4% !important;
                }
                @media(max-width: 980px){
                  left: -4% !important;
                }
                @media(max-width: 768px){
                  left: -5% !important;
                }
                @media(max-width: 640px){
                  left: -7% !important;
                }
                @media(max-width: 480px){
                  left: -8% !important;
                }
                @media(max-width: 360px){
                  left: -10% !important;
                }
              }
            }
            .swiper-button-prev{
              @media(max-width: 2600px){
                padding-left: 35%;
                left: -35% !important;
              }
              &::after{
                @media(max-width: 2600px){
                  right: -4% !important;
                }
                @media(max-width: 980px){
                  right: -5% !important;
                }
                @media(max-width: 768px){
                  right: -6% !important;
                }
                @media(max-width: 640px){
                  right: -8% !important;
                }
                @media(max-width: 480px){
                  right: -9% !important;
                }
                @media(max-width: 360px){
                  right: -12% !important;
                }
              }
            }
          }
          &.rowCount6{
            .swiper-button-next, .swiper-button-prev{
              margin-top: -30px;
              height: 110%;
              &::after{
                @media(max-width: 2600px){
                  top: 42% !important;
                }
                @media(max-width: 1199px){
                  top: 47% !important;
                }
              }
            }
            .swiper-button-next{
              @media(max-width: 2600px){
                padding-right: 35%;
                right: -35% !important;
              }
              &::after{
                @media(max-width: 2600px){
                  left: -4% !important;
                }
                @media(max-width: 1400px){
                  left: -5% !important;
                }
                @media(max-width: 1199px){
                  left: -4% !important;
                }
              }
            }
            .swiper-button-prev{
              @media(max-width: 2600px){
                padding-left: 35%;
                left: -35% !important;
              }
              &::after{
                @media(max-width: 2600px){
                  right: -4% !important;
                }
                @media(max-width: 1400px){
                  right: -5% !important;
                }
                @media(max-width: 1199px){
                  right: -3% !important;
                }
              }
            }
          }
          &.rowCount7{
            .swiper-button-next, .swiper-button-prev{
              height: 110%;
              &::after{
                @media(max-width: 1199px){
                  top: 48% !important;
                }
              }
            }
            .swiper-button-next{
              @media(max-width: 2600px){
                padding-right: 35%;
                right: -35% !important;
              }
              &::after{
                @media(max-width: 2600px){
                  left: -4% !important;
                }
                @media(max-width: 1199px){
                  left: -3% !important;
                }
              }
            }
            .swiper-button-prev{
              @media(max-width: 2600px){
                padding-left: 35%;
                left: -35% !important;
              }
              &::after{
                @media(max-width: 2600px){
                  right: -4% !important;
                }
                @media(max-width: 1199px){
                  right: -3% !important;
                }
              }
            }
          }
          &.rowCount8{
            .swiper-button-next, .swiper-button-prev{
            height: 110%;
            // @media(max-width: 1600px){
            //   height: 110%;
            // }
            // @media(max-width: 1200px){
            //   height: 115%;
            // }
            // @media(max-width: 980px){
            //   margin-top: -25px;
            // }
            // @media(max-width: 768px){
            //   margin-top: -30px;
            // }
            // @media(max-width: 640px){
            //   margin-top: -35px;
            // }
            &::after{
              @media(max-width: 1199px){
                top: 47% !important;
              }
              // top: 46%;
              // @media(max-width: 1600px){
              //   top: 43%;
              // }
              // @media(max-width: 1200px){
              //   top: 46%;
              // }
              // @media(max-width: 768px){
              //   top: 43%;
              // }
              // @media(max-width: 640px){
              //   top: 45%;
              // }
            }
          }
          .swiper-button-next{
            @media(max-width: 2600px){
              padding-right: 35%;
              right: -35% !important;
            }
            &::after{
              @media(max-width: 2600px){
                left: -4% !important;
              }
              @media(max-width: 1199px){
                left: -3.5% !important;
              }
              // @media(max-width: 980px){
              //   left: -30% !important;
              // }
              // @media(max-width: 768px){
              //   left: -36% !important;
              // }
              // @media(max-width: 640px){
              //   left: -40% !important;
              // }
              // @media(max-width: 480px){
              //   left: -65% !important;
              // }
              // @media(max-width: 360px){
              //   left: -80% !important;
              // }
            }
          }
          .swiper-button-prev{
            @media(max-width: 2600px){
              padding-left: 35%;
              left: -35% !important;
            }
            &::after{
              @media(max-width: 2600px){
                right: -4% !important;
              }
              @media(max-width: 1199px){
                right: -3.5% !important;
              }
              // @media(max-width: 980px){
              //   right: -34% !important;
              // }
              // @media(max-width: 768px){
              //   right: -48% !important;
              // }
              // @media(max-width: 640px){
              //   right: -50% !important;
              // }
              // @media(max-width: 480px){
              //   right: -75% !important;
              // }
              // @media(max-width: 360px){
              //   right: -95% !important;
              // }
            }
          }
        }
      }
      &.landscape{
        &.rowCount3{
          .swiper-button-next, .swiper-button-prev{
            height: 110%;
            &::after{
              @media(max-width: 1200px){
                top: 45% !important;
              }
              @media(max-width: 1199px){
                top: 50% !important;
              }
            }
          }
          .swiper-button-next{
            @media(max-width: 2600px){
              padding-right: 35%;
              right: -35% !important;
            }
            &::after{
              @media(max-width: 2600px){
                left: -3% !important;
              }
              @media(max-width: 1768px){
                left: -4% !important;
              }
              @media(max-width: 1199px){
                left: -3.5% !important;
              }
            }
          }
          .swiper-button-prev{
            @media(max-width: 2600px){
              padding-right: 35%;
              left: -35% !important;
            }
            &::after{
              @media(max-width: 2600px){
                right: -4% !important;
              }
              @media (max-width: 1768px){
              right: -5% !important;
              }
              @media(max-width: 1199px){
                right: -4% !important;
              }
            }
          }
        }
        &.rowCount4{
          .swiper-button-next, .swiper-button-prev{
            margin-top: -35px;
            height: 120%;
            // @media (max-width: 479px){
            //   margin-top: -60px;
            // }
            @media (max-width: 1199px){
              height: 125%;
            }
            // @media(max-width: 360px){
            //   height: 130%;
            // }
          &::after{
            @media(max-width: 2600px){
              top: 45% !important;
            }
            @media(max-width: 1980px){
              top: 45% !important;
            }
            @media (max-width: 1400px){
              top: 48% !important;
            }
            @media(max-width: 1199px){
              top: 55% !important;
            }
            // @media(max-width: 980px){
            //   top: 50% !important;
            // }
            // @media(max-width: 479px){
            //   top: 49% !important;
            // }
            // @media(max-width: 360px){
            //   top: 55% !important;
            // }
          }
        }
          .swiper-button-next{
            @media(max-width: 2600px){
              padding-right: 35%;
              right: -35% !important;
            }
            &::after{
            @media(max-width: 2600px){
            left: -4% !important;
            }
            @media(max-width: 1400px){
              left: -5% !important;
            }
            @media(max-width: 1200px){
              left: -3% !important;
            }
            // @media(max-width: 980px){
            //   left: -4% !important;
            // }
            // @media(max-width: 768px){
            //   left: -5% !important;
            // }
            // @media(max-width: 640px){
            //   left: -7% !important;
            // }
            // @media(max-width: 479px){
            //   left: -8% !important;
            // }
            // @media(max-width: 360px){
            //   left: -11% !important;
            // }
          }
        }
          .swiper-button-prev{
            @media(max-width: 2600px){
              padding-left: 35%;
              left: -35% !important;
            }
            &::after{
              @media(max-width: 2600px){
                right: -5% !important;
              }
              @media(max-width: 1400px){
                right: -6% !important;
              }
              @media(max-width: 1200px){
                right: -4% !important;
              }
              // @media(max-width: 980px){
              //   right: -5% !important;
              // }
              // @media(max-width: 768px){
              //   right: -6% !important;
              // }
              // @media(max-width: 640px){
              //   right: -8% !important;
              // }
              // @media(max-width: 479px){
              //   right: -9% !important;
              // }
              // @media(max-width: 360px){
              //   right: -13% !important;
              // }
            }
          }
        }
        &.rowCount5{
          .swiper-button-next, .swiper-button-prev{
            height: 120%;
            
            @media (max-width: 479px){
              margin-top: -60px;
            }
            @media(max-width: 360px){
              height: 130%;
            }
            &::after{
              top: 43%;
  
              @media(max-width: 2600px){
                top: 40%;
              }
              @media(max-width: 1980px){
                top: 40%;
              }
              @media(max-width: 1200px){
                top: 41%;
              }
              @media(max-width: 1199px){
                top: 46%;
              }
              // @media (max-width:980px){
              //   top: 46%;
              // }
              // @media(max-width: 480px){
              //   top: 42%;
              // }
              @media(max-width: 980px){
                top: 45% !important;
              }
              @media(max-width: 768px){
                top: 43% !important;
              }
              @media(max-width: 479px){
                top: 51% !important;
              }
              @media(max-width: 360px){
                top: 55% !important;
              }
            }
          }
          .swiper-button-next{
            @media(max-width: 2600px){
              padding-right: 35%;
              right: -35% !important;
            }
            &::after{
              @media(max-width: 2600px){
                left: -4% !important;
              }
              @media(max-width: 1200px){
                left: -6% !important;
              }
              @media(max-width: 1199px){
                left: -3% !important;
              }
              // @media(max-width: 768px){
              //   left: -36% !important;
              // }
              // @media(max-width: 640px){
              //   left: -40% !important;
              // }
              // @media(max-width: 480px){
              //   left: -55% !important;
              // }
              // @media(max-width: 360px){
              //   left: -80% !important;
              // }
              @media(max-width: 980px){
                left: -4% !important;
              }
              @media(max-width: 768px){
                left: -5% !important;
              }
              @media(max-width: 640px){
                left: -7% !important;
              }
              @media(max-width: 479px){
                left: -8% !important;
              }
              @media(max-width: 360px){
                left: -11% !important;
              }
            }
          }
          .swiper-button-prev{
            @media(max-width: 2600px){
              padding-left: 35%;
              left: -35% !important;
            }
            &::after{
              @media(max-width: 2600px){
                right: -4% !important;
              }
              @media(max-width: 1200px){
                right: -6% !important;
              }
              @media(max-width: 1199px){
                right: -3% !important;
              }
              // @media(max-width: 768px){
              //   right: -48% !important;
              // }
              // @media(max-width: 640px){
              //   right: -50% !important;
              // }
              // @media(max-width: 480px){
              //   right: -65% !important;
              // }
              // @media(max-width: 360px){
              //   right: -90% !important;
              // }
              @media(max-width: 980px){
                right: -5% !important;
              }
              @media(max-width: 768px){
                right: -6% !important;
              }
              @media(max-width: 640px){
                right: -8% !important;
              }
              @media(max-width: 479px){
                right: -9% !important;
              }
              @media(max-width: 360px){
                right: -13% !important;
              }
            }
          }
        }
        &.rowCount6{
          .swiper-button-next, .swiper-button-prev{
            height: 120%;
            @media(max-width: 1199px){
              height: 125%;
            }
            &::after{
              @media(max-width: 1768px){
              top: 42% !important;
              }
              @media(max-width: 1199px){
                top: 48% !important;
              }
            }
          }
          .swiper-button-next{
            @media(max-width: 2600px){
              padding-right: 35%;
              right: -35% !important;
            }
            &::after{
              @media (max-width: 2600px){
                left: -4% !important;
              }
            }
          }
          .swiper-button-prev{
            @media(max-width: 2600px){
              padding-left: 35%;
              left: -35% !important;
            }
            &::after{
              @media (max-width: 2600px){
                right: -4% !important;
              }
            }
          }
        }
        &.rowCount7{
          .swiper-button-next, .swiper-button-prev{
            height: 125%;
            @media(max-width: 1199px){
              height: 130%;
            }
            &::after{
              @media(max-width: 1768px){
                top: 38% !important;
              }
              @media(max-width: 1199px){
                top: 45% !important;
              }
            }
          }
          .swiper-button-next{
            @media(max-width: 2600px){
              padding-right: 35%;
              right: -35% !important;
            }
            &::after{
              @media(max-width: 2600px){
                left: -3% !important;
              }
              @media(max-width: 1768px){
                left: -4% !important;
              }
            }
          }
          .swiper-button-prev{
            @media(max-width: 2600px){
              padding-left: 35%;
              left: -35% !important;
            }
            &::after{
              @media(max-width: 2600px){
                right: -4% !important;
              }
              @media(max-width: 1768px){
                right: -5% !important;
              }
            }
          }
        }
        &.rowCount8{
          .swiper-button-next, .swiper-button-prev{
            height: 125%;
            @media (max-width: 1400px){
              height: 130%;
            }
            @media (max-width: 1200px){
              height: 140%;
            }
            &::after{
              @media(max-width: 1768px){
                top: 41% !important;
              }
              @media(max-width: 1400px){
                top: 35% !important;
              }
              @media (max-width: 1199px){
                top: 46% !important;
              }
            }
          }
          .swiper-button-next{
            @media(max-width: 2600px){
              padding-right: 35%;
              right: -35% !important;
            }
            &::after{
              @media(max-width: 2600px){
                left: -3.5% !important;
              }
            }
          }
          .swiper-button-prev{
            @media(max-width: 2600px){
              padding-left: 35%;
              left: -35% !important;
            }
            &::after{
              @media(max-width: 2600px){
                right: -4% !important;
              }
            }
          }
        }
      }
    }
    }
  }
    div.header-row{
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding-bottom: 30px;
    div.header{
      display: flex;
      justify-content: space-between;
      align-items: center;
      h1 {
        // cursor: pointer;
        // font-family: 'Avenir-Black', sans-serif;
        font-size: 28px;
        // color: white;
        color: var(--text-color);
        // color: #000;
        @media (max-width: 768px){
          font-size: 22px;
        }
      }
    }
    div.view-all{
      font-family: "Avenir-Medium", sans-serif;
      font-size: 12px;
      // color: white;
      color: var(--text-color);
      cursor: pointer;
      display: flex;
      align-items: center;
      @media (max-width: 768px){
        display: none;
      }
  
      .arrow-icon {
        margin-inline-start: 10px;
      }
    }
  }
  div.view-all-mobile{
    display: none;
    @media (max-width: 768px) {
      font-family: 'Avenir-Medium',sans-serif;
      // color: white;
      color: var(--text-color);
  font-size: 16px;
  margin-top: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  }
  }
    } 
  
div.gaiaCategoriesContainer.ltr{
  direction: ltr;
  text-align: left;
  .header-row{
    direction: rtl;
    text-align: right;
    .view-all{
      .arrow-icon{
        transform: rotate(180deg);
      }
    }
  }
  .view-all-mobile{
    direction: rtl;
    text-align: right;
    @media(max-width: 768px){
      .arrow-icon{
        transform: rotate(180deg);
      }
    }
  }
}

div.gaiaCategoriesContainer.rtl{
  direction: rtl;
  text-align: right;
  .wrapper{
    .swiper-slide {
      &.swiper-slide-active {
        &:hover {
          transform: translateX(-15%);
          @media(max-width: 480px){
            transform: none;
          }
        }
      }
      &.swiper-slide.translate-last {
        &:hover {
          transform: translateX(16%);
          @media(max-width: 480px){
            transform: none;
          }
        }
      }
      &.no-transform {
        &.swiper-slide-active:hover,
        &.translate-last:hover {
          transform: none;
        }
      }
    }
  }
  .header-row{
    .view-all{
      .arrow-icon{
        transform: rotate(180deg);
      }
    }
  }
  .view-all-mobile{
    @media(max-width: 768px){
      .arrow-icon{
        transform: rotate(180deg);
      }
    }
  }
}