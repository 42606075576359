.moreTopics-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding-left: 4.5%;
  padding-right: 4.5%;
  padding-bottom: 20px;
  padding-top: 20px;
  z-index: 10;
  max-width: 1980px;
  margin: 0 auto;
  color: var(--text-color);

  .header {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 40px;

    .title {
      font-size: 32px;
      transition: margin-inline-start 0.3s;
    }

    .view-all {
      &.gaia{
      font-family: 'Avenir-Medium', sans-serif;
      }
      &.template_9{
        font-family: 'Gilroy-Medium';
      }
      font-size: 12px;
      text-decoration: none;
      color: var(--text-color);
      cursor: pointer;
      display: flex;
      align-items: center;
      @media (max-width: 768px){
        display: none;
      }

      &:hover {
        text-decoration: underline;
      }

      .arrow-icon {
        margin-inline-start: 10px;

        path {
          // fill: black;
        }
      }
    }
  }

  .topicsList {
    width: 100%;
    list-style: none;
    display: flex;
    white-space: nowrap;
    margin-bottom: 40px;

    &.rtl{
      direction: rtl;
      text-align: right;
    }

    &.ltr{
      direction: ltr;
      text-align: left;
    }

    .swiper-slide {
      width: auto;
      span {
        font-size: 16px;
      color: var(--text-color);
        background: none;
        border: none;
        cursor: pointer;
        transition: color 0.3s, text-decoration 0.3s;

        &:hover {
          color: var(--grey-color);
        }

        &.active {
          &.gaia{
            font-family: 'Avenir-Black', sans-serif;
            }
            font-weight: bold;
          text-decoration: underline;
          text-decoration-color: var(--button-color);
          text-underline-offset: 6px;
          text-decoration-thickness: 4px;
        }
      }
    }
    .swiper-button-next,
    .swiper-button-prev { 
      height: 300%;
      color: white;
      width: 6% !important;
      background: var(--bg-color);

      @media (max-width: 2600px){
        width: 32% !important;
      }

      .svg {
        height: 60px;
        align-items: center;
        color: white;
      }
      &::after {
        font-size: 18px;
        font-weight: bolder;
        color: var(--text-color);
        position: absolute;
        z-index: 10; 
        box-shadow: 0 6px 20px rgba(0, 0, 0, 0.2);
        top: 13%;

        @media (max-width: 1199px){
          top: 30%;
        }

        &:hover{
          background-color: var(--button-hover);
          transform: scale(1.3);
        }
      }
  }
    .swiper-button-next{
      right: -3.5%;
      margin-right: -2%;

      @media (max-width: 2600px){
        margin-right: -24.5%;
      }

      &::after{
        @media(max-width: 2600px){
          margin-right: 80% !important;
        }
      }
    }
    .swiper-button-prev{
      left: -4%;
      margin-left: -2%;

      @media(max-width: 2600px){
        margin-left: -24.5%;
      }
      &::after{
        margin-left: 88%;
      }
    }
  }

  .mainCards {
    width: 100%;

    &.hidden{
      display: none;
    }
    .topRow {
      display: flex;
      margin-bottom: 20px;

      .topCard {
        &.landscape{
        max-width: 25%;
        }
        &.portrait{
          max-width: 20%;
        }
        flex: 1;
        margin-inline-end: 15px;

        &:last-child {
          margin-inline-end: 0;
        }
        &:hover{
          overflow: visible;
          z-index: 99999;
          position: relative;
        }
      }
    }

    .swiper { //update portrait also when needed 
      width: 100%;
  
      &:hover{
        z-index: 999999;
      }
      .swiper-slide {
        display: flex;
        justify-content: center;
        align-items: center;
        &:hover {
          z-index: 99999;
        }
      }
      .swiper-button-next,
      .swiper-button-prev { 
        height: 112%;
        color: white;
        width: 5% !important;
        // top: 9%;
        background: var(--bg-color);

        @media(width: 980px){
          height: 115%;
        }
  
        @media (max-width: 480px){
          height: 110%;
          margin-top: -6%;
        }

        @media(max-width: 360px){
          height: 135%;
          margin-top: -15%;
        }
        .svg {
          height: 60px;
          align-items: center;
          color: white;
        }
        &::after {
          font-size: 18px;
          font-weight: bolder;
          color: var(--text-color);
          position: absolute;
          z-index: 10; 
          // background-color: var(--button-color);
          box-shadow: 0 6px 20px rgba(0, 0, 0, 0.2);
  
          &:hover{
            background-color: var(--button-hover);
            transform: scale(1.3);
          }
        }
      }
      .swiper-button-next{
        right: -5%;
        // right: -20px;
        &::after{
        left: -21%;
        @media(max-width: 480px){
          left: -55%;
        }
        @media(max-width: 360px){
          left: -105%;
        }
      }
      }
      .swiper-button-prev{
        left: -5%;
        // left: -20px;
        &::after{
          right: -27%;
          @media(max-width: 480px){
            right: -60%;
          }
          @media(max-width: 360px){
            right: -100%;
          }
        }
      }
      .swiper-button-disabled {
        opacity: 0;
        pointer-events: none;
      }
        &.portrait{
            .swiper-button-next, .swiper-button-prev{
              margin-top: -35px;
              height: 110%;
              @media(max-width: 1400px){
                height: 112%;
              }
              @media (max-width: 1200px){
                height: 115%;
              }
              @media(max-width: 1199px){
                height: 120%;
              }
              @media(max-width: 980px){
                height: 115%;
              }
              @media(max-width: 979px){
                height: 112%;
              }
              @media(max-width: 767px){
                height: 108%;
              }
              @media (max-width: 479px){
                margin-top: -60px;
                height: 105%;
              }
              &::after{
                @media(max-width: 1768px){
                  top: 42% !important;
                }
                @media(max-width: 1400px){
                  top: 36% !important;
                }
                @media(max-width: 980px){
                  top: 47% !important;
                }
                @media(max-width: 479px){
                  top: 47% !important;
                }
                @media(max-width: 360px){
                  top: 46% !important;
                }
              }
            }
            .swiper-button-next{
              @media(max-width: 2600px){
                margin-right: -20%;
                width: 25% !important;
              }
              &::after{
                @media(max-width: 2600px){
                  left: -4% !important;
                }
                @media(max-width: 1400px){
                  left: -7% !important;
                }
                @media(max-width: 1199px){
                  left: -4% !important;
                }
                @media(max-width: 980px){
                  left: -4% !important;
                }
                @media(max-width: 768px){
                  left: -5% !important;
                }
                @media(max-width: 640px){
                  left: -7% !important;
                }
                @media(max-width: 480px){
                  left: -8% !important;
                }
                @media(max-width: 360px){
                  left: -10% !important;
                }
              }
            }
            .swiper-button-prev{
              @media(max-width: 2600px){
                padding-left: 35%;
                left: -35% !important;
              }
              &::after{
                @media(max-width: 2600px){
                  right: -4% !important;
                }
                @media(max-width: 1400px){
                  right: -5% !important;
                }
                @media(max-width: 1199px){
                  right: -3% !important;
                }
                @media(max-width: 980px){
                  right: -5% !important;
                }
                @media(max-width: 979px){
                  right: -4% !important;
                }
                @media(max-width: 768px){
                  right: -6% !important;
                }
                @media(max-width: 640px){
                  right: -6% !important;
                }
                @media(max-width: 480px){
                  right: -9% !important;
                }
                @media(max-width: 360px){
                  right: -12% !important;
                }
              }
          }
      }
      &.landscape{
          .swiper-button-next, .swiper-button-prev{
            height: 120%;
            
            @media(max-width: 1199px){
              height: 125%;
            }
            @media (max-width: 479px){
              margin-top: -60px;
            }
            @media(max-width: 360px){
              height: 130%;
            }
            &::after{
              top: 43%;
  
              @media(max-width: 2600px){
                top: 30%;
              }
              @media(max-width: 1980px){
                top: 30%;
              }
              @media(max-width: 1400px){
                  top: 26%;
              }
              @media(max-width: 1200px){
                top: 25%;
              }
              @media(max-width: 1199px){
                top: 27%;
              }
              @media(max-width: 980px){
                top: 34% !important;
              }
              @media(max-width: 768px){
                top: 35% !important;
              }
              @media(max-width: 479px){
                top: 43% !important;
              }
              @media(max-width: 360px){
                top: 44% !important;
              }
            }
          }
          .swiper-button-next{
            @media(max-width: 2600px){
              padding-right: 35%;
              right: -35% !important;
            }
            &::after{
              @media(max-width: 2600px){
                left: -4% !important;
              }
              @media(max-width: 1200px){
                left: -6% !important;
              }
              @media(max-width: 1199px){
                left: -3% !important;
              }
              @media(max-width: 980px){
                left: -4% !important;
              }
              @media(max-width: 768px){
                left: -5% !important;
              }
              @media(max-width: 640px){
                left: -7% !important;
              }
              @media(max-width: 479px){
                left: -8% !important;
              }
              @media(max-width: 360px){
                left: -11% !important;
              }
            }
          }
          .swiper-button-prev{
            @media(max-width: 2600px){
              padding-left: 35%;
              left: -35% !important;
            }
            &::after{
              @media(max-width: 2600px){
                right: -4% !important;
              }
              @media(max-width: 1200px){
                right: -6% !important;
              }
              @media(max-width: 1199px){
                right: -3% !important;
              }
              @media(max-width: 980px){
                right: -5% !important;
              }
              @media(max-width: 768px){
                right: -5% !important;
              }
              @media(max-width: 640px){
                right: -8% !important;
              }
              @media(max-width: 479px){
                right: -9% !important;
              }
              @media(max-width: 360px){
                right: -13% !important;
              }
            }
        }
      }
    }

    .template-10 {
      width: 100%;
  
      &:hover{
        z-index: 999999;
      }
      .swiper-slide {
        display: flex;
        justify-content: center;
        align-items: center;
  
        &.swiper-slide-active {
          transition: transform 0.3s ease;
          
          &:hover {
            transform: translateX(15%);
          }
        }
  
        &.swiper-slide.translate-last {
          transition: transform 0.3s ease;
          
          &:hover {
            transform: translateX(-16%);
          }
        }
  
        &:hover {
          z-index: 99999;
        }
      }
      .swiper-button-next,
      .swiper-button-prev { 
        height: 112%;
        color: white;
        width: 5% !important;
        // top: 9%;
        background: var(--bg-color);

        @media(width: 980px){
          height: 115%;
        }
  
        @media (max-width: 480px){
          height: 110%;
          margin-top: -6%;
        }

        @media(max-width: 360px){
          height: 135%;
          margin-top: -15%;
        }
        .svg {
          height: 60px;
          align-items: center;
          color: white;
        }
        &::after {
          font-size: 18px;
          font-weight: bolder;
          color: var(--text-color);
          position: absolute;
          z-index: 10; 
          // background-color: var(--button-color);
          box-shadow: 0 6px 20px rgba(0, 0, 0, 0.2);
  
          &:hover{
            background-color: var(--button-hover);
            transform: scale(1.3);
          }
        }
      }
      .swiper-button-next{
        right: -5%;
        // right: -20px;
        &::after{
        left: -21%;
        @media(max-width: 480px){
          left: -55%;
        }
        @media(max-width: 360px){
          left: -105%;
        }
      }
      }
      .swiper-button-prev{
        left: -5%;
        // left: -20px;
        &::after{
          right: -27%;
          @media(max-width: 480px){
            right: -60%;
          }
          @media(max-width: 360px){
            right: -100%;
          }
        }
      }
      .swiper-button-disabled {
        opacity: 0;
        pointer-events: none;
      }
        &.portrait{
            .swiper-button-next, .swiper-button-prev{
              margin-top: -35px;
              height: 110%;
              @media(max-width: 1400px){
                height: 112%;
              }
              @media (max-width: 1200px){
                height: 115%;
              }
              @media(max-width: 1199px){
                height: 120%;
              }
              @media(max-width: 980px){
                height: 115%;
              }
              @media(max-width: 979px){
                height: 112%;
              }
              @media(max-width: 767px){
                height: 108%;
              }
              @media (max-width: 479px){
                margin-top: -60px;
                height: 105%;
              }
              &::after{
                @media(max-width: 1768px){
                  top: 46% !important;
                }
                @media(max-width: 1400px){
                  top: 47% !important;
                }
                @media(max-width: 480px){
                  top: 45% !important;
                }
                @media(max-width: 360px){
                  top: 45% !important;
                }
              }
            }
            .swiper-button-next{
              @media(max-width: 2600px){
                margin-right: -20%;
                width: 25% !important;
              }
              &::after{
                @media(max-width: 2600px){
                  left: -4% !important;
                }
                @media(max-width: 1400px){
                  left: -7% !important;
                }
                @media(max-width: 1199px){
                  left: -4% !important;
                }
                @media(max-width: 980px){
                  left: -4% !important;
                }
                @media(max-width: 768px){
                  left: -5% !important;
                }
                @media(max-width: 640px){
                  left: -7% !important;
                }
                @media(max-width: 480px){
                  left: -8% !important;
                }
                @media(max-width: 360px){
                  left: -10% !important;
                }
              }
            }
            .swiper-button-prev{
              @media(max-width: 2600px){
                padding-left: 35%;
                left: -35% !important;
              }
              &::after{
                @media(max-width: 2600px){
                  right: -4% !important;
                }
                @media(max-width: 1400px){
                  right: -5% !important;
                }
                @media(max-width: 1199px){
                  right: -3% !important;
                }
                @media(max-width: 980px){
                  right: -5% !important;
                }
                @media(max-width: 979px){
                  right: -4% !important;
                }
                @media(max-width: 768px){
                  right: -6% !important;
                }
                @media(max-width: 640px){
                  right: -6% !important;
                }
                @media(max-width: 480px){
                  right: -9% !important;
                }
                @media(max-width: 360px){
                  right: -12% !important;
                }
              }
          }
      }
      &.landscape{
          .swiper-button-next, .swiper-button-prev{
            height: 120%;
            
            @media(max-width: 1199px){
              height: 125%;
            }
            @media (max-width: 479px){
              margin-top: -60px;
            }
            @media(max-width: 360px){
              height: 130%;
            }
            &::after{
              top: 43%;
  
              @media(max-width: 2600px){
                top: 40%;
              }
              @media(max-width: 1980px){
                top: 40%;
              }
              @media(max-width: 1200px){
                top: 41%;
              }
              @media(max-width: 1199px){
                top: 46%;
              }
              @media(max-width: 980px){
                top: 45% !important;
              }
              @media(max-width: 768px){
                top: 43% !important;
              }
              @media(max-width: 479px){
                top: 51% !important;
              }
              @media(max-width: 360px){
                top: 55% !important;
              }
            }
          }
          .swiper-button-next{
            @media(max-width: 2600px){
              padding-right: 35%;
              right: -35% !important;
            }
            &::after{
              @media(max-width: 2600px){
                left: -4% !important;
              }
              @media(max-width: 1200px){
                left: -6% !important;
              }
              @media(max-width: 1199px){
                left: -3% !important;
              }
              @media(max-width: 980px){
                left: -4% !important;
              }
              @media(max-width: 768px){
                left: -5% !important;
              }
              @media(max-width: 640px){
                left: -7% !important;
              }
              @media(max-width: 479px){
                left: -8% !important;
              }
              @media(max-width: 360px){
                left: -11% !important;
              }
            }
          }
          .swiper-button-prev{
            @media(max-width: 2600px){
              padding-left: 35%;
              left: -35% !important;
            }
            &::after{
              @media(max-width: 2600px){
                right: -4% !important;
              }
              @media(max-width: 1200px){
                right: -6% !important;
              }
              @media(max-width: 1199px){
                right: -3% !important;
              }
              @media(max-width: 980px){
                right: -5% !important;
              }
              @media(max-width: 768px){
                right: -5% !important;
              }
              @media(max-width: 640px){
                right: -8% !important;
              }
              @media(max-width: 479px){
                right: -9% !important;
              }
              @media(max-width: 360px){
                right: -13% !important;
              }
            }
        }
      }
    }

  }
  .view-all-mobile{
    display: none;
    @media (max-width: 768px) {
      &.gaia{
        font-family: 'Avenir-Medium', sans-serif;
        }
        &.template_9{
          font-family: 'Gilroy-Medium';
        }
      color: var(--text-color);
  font-size: 16px;
  margin-top: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  }
  }
}

.moreTopics-container.rtl{
  .mainCards{
    .template-10{
    .swiper-slide {
      &.swiper-slide-active {
        &:hover {
          transform: translateX(-15%);
        }
      }
      &.swiper-slide.translate-last {
        &:hover {
          transform: translateX(16%);
        }
      }
    }
  }
}
  .header{
    .view-all{
      .arrow-icon{
        transform: rotate(180deg);
      }
    }
  }
  // .template-10{
  //   direction: ltr;
  //   text-align: left;
  // }
  .view-all-mobile{
    .arrow-icon{
      transform: rotate(180deg);
    }
  }
}

.moreTopics-container.ltr{
  .header{
    .view-all{
      .arrow-icon{
        transform: rotate(180deg);
      }
    }
  }
  .mainCards{
    .template-10, .swiper{
    direction: ltr;
    text-align: left;
    }
  }
  .view-all-mobile{
    .arrow-icon{
      transform: rotate(180deg);
    }
  }
}

  .moreTopics-container.template-9{
    // border-bottom: 1px solid var(--border-color);
    padding-bottom: 40px;
    padding-left: unset;
    padding-right: unset;
    max-width: none;
    width: 92%;
    overflow-y: clip;
    @media(max-width: 980px){
      width: 85%;
    }
    position: relative;

    &::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: -100%;
    right: -100%;
    border-bottom: 1px solid var(--border-color);
    z-index: 999;
    overflow: visible;
    }
    .header{
      width: unset;
      .title{
        font-size: 24px;
        font-family: 'Gilroy-Medium';
        margin-inline-end: 10px;
      }
      .show_all{
        font-family: 'Gilroy-Medium';
        display: inline-block;
        color: var(--text-hover);
        height: 20px !important;
        width: 20px !important;
        transition: color 0.3s ease-in-out;
        vertical-align: sub;
        margin-inline-start: 10px;
        font-size: medium;
        white-space: nowrap;

        &:hover,
        &:focus {
          color: var(--text-color);
        }
      }
    }
  }
