div.affliatePage {
  padding: 30px 0 0;
  background-color: #fff;
  div.top {
    padding: 30px 0;
    div.logoSection {
      width: 70%;
      margin: 0 auto;
      @media all and (min-width: 980px) {
        width: 30%;
      }
      h1.image {
        width: 100%;
        img {
          width: 100%;
          display: block;
        }
      }
    }
    h1 {
      font-size: 24px;
      text-align: center;
    }
  }
  div.bottom {
    background-color: #022230;
    padding: 136px 20px;
    border-top-left-radius: 91%;
    border-top-right-radius: 0;

    div.buttons {
      margin-left: 40px;
      div.buttonContainer {
        text-align: center;
        button {
          padding: 10px 20px;
          font-size: 15px;
          border-radius: 30px;
          width: 10rem;

          color: #fff; 
          &.useApp {
            background-color: #45c4af;
            margin-bottom: 20px;
            box-shadow: rgba(69, 196, 175, 0.35) 0px 5px 15px;
          }
          &.web {
            box-shadow: rgba(0, 0, 0, 0.03) 0px 16px 24px;
            background-color: rgba(255, 255, 255, 0.3);
            backdrop-filter: blur(8px);
          }
        }
      }
    }
  }
}

div.affliatePage.rtl{
  .bottom{
    .buttons{
      margin-left: 0;
      margin-right: 40px;
    }
  }
}
