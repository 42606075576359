div.downloadContainer{
    div.overlayModal{
        position: fixed;
        top: 0;
        bottom: 0;
        right: 0;
        left: 0;
        background-color: rgba(0, 0, 0, 0.7);
        z-index: 9999;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    div.contents{
        z-index: 9999;
        text-align: center;
        position: fixed;
        left: 50%;
        top: 50%;
        transform: translate(-50%,-50%);
        background-color: var(--bg-color);
        width: 40%;
        margin: 0 auto;
        padding: 40px 40px 40px;
        border-radius: 6px;
        display: flex;
        flex-direction: column;
        @media all and (max-width:1200px){
            width: 95%;
            padding-top: 20px;
            padding-bottom: 20px;
        }
        @media all and (max-width:768px){
            width: 95%;
            padding-top: 10px;
            padding-bottom: 10px;
        }
        @media all and (max-width:480px){
            padding-top: 5px;
            padding-bottom: 5px;
        }
        div.closeIconContainer{
            position: fixed;
            top: 20px;
            inset-inline-end: 30px;
            z-index: 9999;
            cursor: pointer;
            svg{
                width: 25px !important;
                height: 25px !important;
                stroke:var(--text-color) !important;
            }
        }
        h1{
            color: var(--error-color);
            font-size: 15px;
            text-align: center;
            @media(max-width: 480px){
                font-size: 12px;
            }
        }
        h2{
            color: var(--inner-text-color);
            font-size: 13px;
            text-align: start;
            @media(max-width: 480px){
                font-size: 10px;
            }
        }
        p{
            color: var(--inner-text-color);
            font-size: 12px;
            text-align: start;
            @media(max-width: 480px){
                font-size: 9px;
            }
        }
        a.download-button{
            margin-top: 25px;
            padding: 10px 20px;
            background-color: var(--button-color);
            color: white;
            border: none;
            border-radius: 4px;
            font-size: 16px;
            cursor: pointer;
            transition: opacity 0.3s ease;
            align-self: center;

            @media(max-width: 480px){
                font-size: 12px;
            }
            
            &:hover{
                // opacity: 0.9;
                background-color: var(--button-hover);
            }
            
            &:active{
                opacity: 0.8;
            }
        }
    }
}