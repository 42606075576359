div.scheduleDetailsModal {
  div.overlayModal {
    position: fixed;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    background-color: rgba(32, 32, 38, 0.8);
    z-index: 9999;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  div.contents {
    z-index: 99999;
    position: fixed;
    top: 50%;
    left: 50%;
    background-color: var(--bg-color);
    width: 50%;
    border-radius: 15px;
    display: flex;
    flex-direction: column;
    transform: translate(-50%,-50%);

    div.top {
        position: relative;
      width: 100%;
      height: 269px;
      background-size: cover;
      background-position: top;
      border-top-left-radius: 15px;
      border-top-right-radius: 15px;
      background-repeat: no-repeat;
      div.closeIcon{
        position: absolute;
        top: 10px;
        inset-inline-end: 10px;
        filter: drop-shadow(0 0 2px rgba(0,0,0,.5));
        cursor: pointer;
        svg{
            color: var(--white-color);
            width: 20px !important;
            height: 20px !important;
        }
      }
    }
    div.bottom {
      background-color: var(--secondary-bg-color);
      padding: 40px 30px;
      border-bottom-left-radius: 15px;
      border-bottom-right-radius: 15px;
      height: 300px;
    overflow-y: scroll;
    &::-webkit-scrollbar {
      display: block ;
    }
    &::-webkit-scrollbar{
      width: 5px;
      border-radius: 30px;
    }
    &::-webkit-scrollbar-thumb{
      background-color: var(--bg-color);
      border-radius: 30px;
    }
    &::-webkit-scrollbar-track{
      background-color: var(--grey-color);
    }
      div.channelLogo {
        width: 80px;
        height: 80px;
        background-color: rgba(150, 153, 163, 0.16);
        border-radius: 6px;
        margin-bottom: 10px;
        div.logoContainer {
          width: 100%;
          height: 100%;
          display: flex;
          justify-content: center;
          align-items: center;
          img {
            display: block;
            width: 40px;
            height: 100%;
            object-fit: contain;
          }
        }
      }
      div.metaData {
        color: var(--text-color);
        h1.title {
          font-size: 30px;
          margin-bottom: 10px;
        }
        span.startTime,span.endTime {
          color: var(--inner-text-color);
          font-weight: 700;
          font-size: 15px;
          margin-bottom: 10px;
          display: inline-block;
        }
        div.additionalInfo {
          span.caption {
            background-color: var(--white-color);
            color: var(--black-color);
            font-size: 8px;
            padding: 2px 4px;
            font-weight: 700;
            border-radius: 5px;
            outline: none;
            border: none;
            margin-inline-end: 15px;
          }
          span.rating {
            background-color: hsla(226, 7%, 61%, 0.32);
            border-radius: 3px;
            color: var(--white-color);
            font-size: 12px;
            padding: 1px 8px;
          }
          
        }
        p.description{
            margin-top: 10px;
            font-size: 15px;
          }
      }
    }
  }
}
