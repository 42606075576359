div.gaiaShowCardContainer {
    width: 100%;
    cursor: pointer;
    transition: transform 0.5s ease, box-shadow 0.5s ease;
    overflow: visible;
    z-index: 10;
    position: relative;

    &:hover{
      transform: scale(1.3);
      box-shadow: 0 6px 20px rgba(0, 0, 0, 0.2);
      z-index: 9999999999;
      @media(max-width: 768px){
        transform: scale(1.1);
      }
      @media(max-width: 480px){
        transform: scale(1);
      }
  }
    div.imageContainer {
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      opacity: 1;
      transition: opacity 0.3s ease;
  
      &.hidden {
          pointer-events: none;
      }
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
      div.premiumStatus {
        div.premium {
          position: absolute;
          top: 6px;
          left: 6px;
          z-index: 8;
          background-color: rgba(8, 25, 43, 0.65);
          width: 35px;
          height: 30px;
          display: flex;
          align-items: center;
          justify-content: center;
          border-radius: 3px;
          @media all and (max-width: 1200px) {
            width: 26px;
          }
          svg {
            width: 18px !important;
            height: 18px !important;
            @media all and (max-width: 1200px) {
              width: 12px !important;
              height: 12px !important;
            }
          }
        }
        div.free {
          position: absolute;
          top: 6px;
          left: 6px;
          z-index: 8;
          width: 50px;
          @media all and (max-width: 1200px) {
            width: 30px;
          }
          img {
            display: block;
            width: 100%;
          }
        }
        div.lock {
          position: absolute;
          top: 6px;
          left: 6px;
          z-index: 8;
          width: 20px;
          @media all and (max-width: 1200px) {
            width:15px;
          }
          img {
            display: block;
            width: 100%;
          }
        }
      }
      div.continueWatchingBar{
        width: 100%;
      height: 10px;
      position: absolute;
      bottom: 0;
      left: 0;
      z-index: 1;
      box-shadow: 0 -4px 4px 0 rgba(0, 0, 0, .25);
      background-color: #4a4a4a;
        div.line{
          height: 100%;
          background-color: var(--button-color);
        }
      }
    }
  
      div.hover-content{
        position: absolute;
        cursor:default;
        top: 0;
        left: 0;
        width: 100%;
        height: auto; 
        background: #fff;  
        color: #000; 
        box-sizing: border-box;
        opacity: 0;
        transform: translateY(20px);
        pointer-events: none;
        transition: opacity 0.3s ease, transform 0.3s ease;
        overflow: visible; 
        z-index: 9999; 
        box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
        // font-family: 'Avenir-Roman',sans-serif;
  
        &.visible {
            opacity: 1;
            transform: translateY(0);
            pointer-events: all;
        }
  
        img{
          width: 100%;
          height: 100%;
          object-fit:cover;
          cursor: pointer;
        }
        div.premiumStatus {
          div.premium {
            position: absolute;
            top: 6px;
            left: 6px;
            z-index: 8;
            background-color: rgba(8, 25, 43, 0.65);
            width: 35px;
            height: 30px;
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 3px;
            @media all and (max-width: 1200px) {
              width: 26px;
            }
            svg {
              width: 18px !important;
              height: 18px !important;
              @media all and (max-width: 1200px) {
                width: 12px !important;
                height: 12px !important;
              }
            }
          }
          div.free {
            position: absolute;
            top: 6px;
            left: 6px;
            z-index: 8;
            width: 50px;
            @media all and (max-width: 1200px) {
              width: 30px;
            }
            img {
              display: block;
              width: 100%;
            }
          }
          div.lock {
            position: absolute;
            top: 6px;
            left: 6px;
            z-index: 8;
            width: 20px;
            @media all and (max-width: 1200px) {
              width:15px;
            }
            img {
              display: block;
              width: 100%;
            }
          }
        }
        div.continueWatchingBar{
          width: 100%;
          height: 10px;
          position: relative;
          margin-top: -10px;
          z-index: 1;
          box-shadow: 0 -4px 4px 0 rgba(0, 0, 0, .25);
          background-color: #4a4a4a;
            div.line{
              height: 100%;
              background-color: var(--button-color);
            }
        }
      div.extraActions {
          display: flex;
          gap: 10px;
          padding-left: 5px;
          // padding-left: 4%;
        button{
          width: 45px;
          height: 45px;
          display: flex;
          align-items: center;
          background: transparent;
          border: none;
          color: #000;  
          font-size: 12px;
          cursor: pointer;
          // font-family: 'Avenir-Roman',sans-serif;
  
          svg {
              margin-right: 5px;
              overflow: visible;
              width: 45px;
              height: 45px;
          }
  
          &:hover {
            color: var(--text-hover);
          }
        }
        button.playIconContainer {
          padding-left: 1%;
          &:hover circle {
            transition: fill 0.3s ease, color 0.5s ease;
            fill: var(--button-hover); 
          }
        }
        button.addToList {
          margin-top: 2px;   
          margin-left: auto;
          padding-right: 1%;
          width: 45px;
          height: 45px;
          circle{
              stroke: #989595;
          }
          path{
              fill: #989595;
          }
          &:hover circle {
              transition: fill 0.3s ease, color 0.5s ease;
              stroke: var(--button-hover);
            }
            &:hover path{
              transition: fill 0.3s ease, color 0.5s ease;
              fill: var(--button-hover);
            }
          
        }
      }
      
      h1.title {
        padding-left: 4%;
        // font-family: "Avenir-Black", sans-serif;
        font-size: 17px;
        margin-bottom: 2px;
        cursor: pointer;
        &:hover{
            color: var(--text-hover);
        }
      }
  
      div.episode{
        display: flex;
        // padding-left: 17px;
        padding-left: 4%;
        margin-bottom: 5px;
        font-size: 11px;
        color: #4a4a4a;
        // font-family: 'Avenir-Roman',sans-serif;
  
        span{
          text-align: left;
        }
        &.hidden{
          display: none;
        }
      }
  
      div.duration{
        // padding-left: 17px;
        padding-left: 4%;
        display: flex;
        margin-bottom: 8px;
        font-size: 10px;
        color: #4a4a4a;
        // font-family: 'Avenir-Roman',sans-serif;
        span.duration{
          text-align: left; 
          &.ltr{
            direction: ltr !important;
            unicode-bidi: embed !important;
          }
        }
        span.certificate{
          // padding-left: 10px; 
          padding-left: 4%;
          white-space: nowrap;
        }
      }
    }
  }
  
  div.gaiaShowCardContainer.rtl{
    .hover-content{
      .extraActions{
        padding-left: 0;
        padding-right: 5px;
        button{
          svg{
            margin-right: 0;
            margin-left: 5px;
          }
        }
        .playIconContainer{
          padding-left: 0;
          padding-right: 1%;
        }
        .addToList{
          padding-left: 1%;
          padding-right: 0;
          margin-left: 0;
          margin-right: auto;
        }
      }
      .title{
        padding-left: 0;
        padding-right: 4%;
      }
      .episode{
        padding-left: 0;
        padding-right: 4%;
      }
      .duration{
        padding-left: 0;
        padding-right: 2%;
        .duration{
          text-align: right;
        }
        span.certificate{
          padding-left: 0;
          padding-right: 4%;
        }
      }
    }
  }