div.commonPage {
    padding: 130px 0 40px;
    div.top {
      padding-bottom: 60px;
      position: absolute;
      top: 84px;
      z-index: 8;
      left: 60px;
      @media all and (max-width: 640px) {
          top: 50px;
          z-index: 8;
          left: 20px;
        }
      h1.heading {
        font-size: 26px;
        margin-bottom: 10px;
        margin-top: 20px;
        color: var(--text-color);
        @media all and (max-width: 640px) {
          font-size: 22px;
          margin-top: 50px;
        }
        &.gaia{
          font-family: "Avenir-Black",sans-serif;
        }
      }
      p.desc {
        font-size: 18px;
        color: var(--inner-text-color);
        @media all and (max-width: 640px) {
          font-size: 14px;
        }
      }
    }
    div.topbanner {
      position: absolute;
      top: 84px;
      z-index: 8;
      left: 60px;
      width: 91%;
      background-color: transparent;
      @media all and (max-width: 640px) {
          top: 50px;
          z-index: 8;
          left: 20px;
        }
      h1.heading {
        font-size: 26px;
        margin-bottom: 10px;
        margin-top: 20px;
        color: var(--text-color);
        @media all and (max-width: 640px) {
          font-size: 22px;
          margin-top: 50px;
        }
      }
      p.desc {
        font-size: 18px;
        color: var(--inner-text-color);
        @media all and (max-width: 640px) {
          font-size: 14px;
        }
      }
    }
    div.bgImageContainer {
      width: 100%;
      display: block;
      height: 508px;
      position: relative;
      @media((max-width: 2600px) and (min-width:768px)){
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;
      }
      @media all and (max-width: 768px) {
        height: 556px;
      }
      img {
        display: block;
        width: 100%;
        height: 100%;
        object-fit: cover;
        @media all and (max-width: 768px) {
          object-fit: cover;
        }
      }
    }
    div.bannerContainer {
      width: 100%;
      display: flex;
      align-items: center; 
      justify-content: center; 
      padding-bottom: 15%;
      position: relative;
      text-align: center; 
      background-size: cover;
      background-position: center;
      background-repeat: no-repeat;
      @media all and (max-width:1400px){
        padding-bottom: 17%;
       }
      @media all and (max-width:1200px){
        padding-bottom: 19%;
       }
      @media all and (max-width:980px){
        padding-bottom: 23%;
       }
      @media all and (max-width:768px){
        padding-bottom: 30%;
       }
       @media all and (max-width:640px){
        padding-bottom: 37%;
       }
       @media all and (max-width:480px){
        padding-bottom: 45%;
       }
       @media all and (max-width:360px){
        padding-bottom: 60%;
       }
      img {
        display: none;
      }
    
      .topbanner, .top {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%); 
        z-index: 1; 
        color: #fff; 
        @media all and (max-width:1200px){
          top: 45%;
         }
        @media all and (max-width:640px){
          top: 40%;
         }
        .heading {
          font-size: 4rem;
          margin-bottom: 10px;
          @media all and (max-width:480px){
            font-size: 2.1rem;
           }
           @media all and (max-width:360px){
            font-size: 1.8rem;
           }
           @media all and (max-width:320px){
            font-size: 1.5rem;
           }
           
          
        }
        
        .desc {
          font-size: 1.2rem;
        }
      }
    }
    
    div.bottomGradient {
      position: absolute;
      bottom: 0;
      top: 0;
      left: 0;
      right: 0;
      width: 100%;
      z-index: 1;
      height: 100%;
      background-position: top;
      background-size: cover;
      background-repeat: no-repeat;
      background: linear-gradient(
          78.83deg,
          rgb(16, 20, 31) 0%,
          rgba(16, 20, 31, 0) 61.46%
        ),
        linear-gradient(
          360deg,
          rgb(16, 20, 31) 0%,
          rgba(16, 20, 31, 0) 48.44%,
          rgba(16, 20, 31, 0.4) 100%
        );
  
  
    }
    div.bottom {
      margin-top: 40px;
      div.seasonContainer{
        border: 1px solid var(--border-color);
        border-radius: 8px;
        width: 20rem;
        margin-bottom: 20px;
        background: transparent;
        padding: 15px 10px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        position: relative;
        color: var(--text-color);
        cursor: pointer;
        z-index: 999;
        overflow: visible;
        @media all and (max-width:480px){
          width: 100%;
        }
        svg{
          width: 26px !important;
          height: 26px !important;
          transition: 0.3s ease-in-out;
          &.active{
            transform: rotate(-180deg);
          }
        }
        &.zIndexController{
          div.listContainer{
            z-index: 9;
          }
        }
        div.listContainer{
          overflow-y: scroll;
          position: absolute;
          top: 55px;
          width: 100%;
          left: 0;
          right: 0;
          z-index: -1;
          -ms-overflow-style: none ;  /* IE and Edge */
          scrollbar-width: none ;  /* Firefox */
          &::-webkit-scrollbar {
            display: none ;
          }
          &.scroll{
          -ms-overflow-style: unset ;  /* IE and Edge */
    
            scrollbar-width: unset ;  /* Firefox */
          &::-webkit-scrollbar {
            display: block ;
          }
          &::-webkit-scrollbar{
            width: 5px;
            border-radius: 30px;
          }
          &::-webkit-scrollbar-thumb{
            background-color: var(--bg-color);
            border-radius: 30px;
          }
          &::-webkit-scrollbar-track{
            background-color: var(--grey-color);
          }
          }
          cursor: pointer;
        }
        div.list{
         
          
          // border: 1px solid var(--border-color);
          border-bottom-right-radius: 8px;
          border-bottom-left-radius: 8px;
          display: none;
         
          &.active{
            display: block;
          }
          div.item{
            width: 100%;
            padding: 8px 10px;
            border-bottom: 1px solid var(--border-color);
            // background-color: #1c1f29;
            background-color: var(--bg-color);
            &:last-child{
    
              border-bottom-right-radius: 8px;
              border-bottom-left-radius: 8px;
            }
            span{
              font-size: 16px;
              color: var(--text-color);
            }
    
          }
    
        }
      }
      div.itemsContainer {
        display: flex;
        flex-wrap: wrap;
        div.items{
          &.rowCount3{
              width: 32.5%;
              margin-right: 13px;
              margin-bottom: 30px;
              &:nth-child(3n){
                  margin-right: 0;
              }
              @media all and (max-width:1200px){
                  width: 32%;
              }
              &.landscape {
                @media all and (min-width: 981px) {
                  &:hover {
                    div.showCardContainer {
                      .square {
                        position: absolute;
                        z-index: 9999999;
                        transform: translateX(60px) translateY(-60px) scale(1.1);
                        div.metaData {
                          display: block;
                          visibility: visible !important;
                          animation: fadeIn 0.3s ease-in-out;
                        }
                      }
                    }
                    &:nth-child(3n) {
                      div.showCardContainer {
                        div.square {
                          transform: translateX(-60px) translateY(-60px) scale(1.1);
                        }
                      }
                    }
                    
                  }
                }
    
        
                @media all and (max-width: 980px) {
                  width: 24.3%;
                  margin-right: 6px;
                  margin-bottom: 40px;
                  &:nth-child(6n) {
                    margin-right: 6px;
                  }
                  &:nth-child(4n) {
                    margin-right: 0;
                  }
                }
                @media all and (max-width: 768px) {
                  width: 32.3%;
                  &:nth-child(4n) {
                    margin-right: 6px;
                  }
                  &:nth-child(3n) {
                    margin-right: 0;
                  }
                }
                @media all and (max-width: 640px) {
                  width: 48.2%;
    
                  &:nth-child(3n) {
                    margin-right: 6px;
                  }
                  &:nth-child(2n) {
                    margin-right: 0;
                  }
                }
                @media all and (max-width: 480px) {
                  width: 100%;
                  margin-right: 0;
                  &:nth-child(6n) {
                    margin-right: 0;
                  }
                  &:nth-child(4n) {
                    margin-right: 0;
                  }
                  &:nth-child(3n) {
                    margin-right: 0;
                  }
    
                  &:nth-child(2n) {
                    margin-right: 0;
                  }
                  @media all and (max-width: 360px) {
                    width: 100%;
    
                    &:nth-child(3n) {
                      margin-right: 0;
                    }
                    margin-right: 0;
                  }
                }
              }
              &.portrait {
                @media all and (min-width: 981px) {
                  &:hover {
                    div.showCardContainer {
                      .square {
                        position: absolute;
                        z-index: 9999999;
                        transform: translateX(60px) translateY(-60px) scale(1.2);
                        div.metaData {
                          display: block;
                          visibility: visible !important;
                          animation: fadeIn 0.3s ease-in-out;
                        }
                      }
                    }
                    &:nth-child(3n) {
                      div.showCardContainer {
                        div.square {
                          transform: translateX(-60px) translateY(-60px) scale(1.2);
                        }
                      }
                    }
                    @media all and (max-width: 1300px) {
                      &:nth-child(6n) {
                        div.showCardContainer {
                          div.square {
                            transform: translateX(-60px) translateY(-60px)
                              scale(1.2);
                          }
                        }
                      }
                      &:nth-child(7n) {
                        div.showCardContainer {
                          div.square {
                            transform: translateX(60px) translateY(-60px) scale(1.2);
                          }
                        }
                      }
                    }
                    &:last-child {
                      div.showCardContainer {
                        div.square {
                          transform: translateX(-60px) translateY(-60px) scale(1.2);
                        }
                      }
                    }
                  }
                }
               
                @media all and (max-width: 980px) {
                  width: 24%;
                  margin-right: 6px;
                  margin-bottom: 40px;
                  &:nth-child(5n) {
                    margin-right: 6px;
                  }
                  &:nth-child(4n) {
                    margin-right: 0;
                  }
                  &:nth-child(6n) {
                    margin-right: 6px;
                  }
                }
                @media all and (max-width: 768px) {
                  width: 32%;
                  &:nth-child(4n) {
                    margin-right: 6px;
                  }
                  &:nth-child(3n) {
                    margin-right: 0;
                  }
                }
                @media all and (max-width: 640px) {
                  width: 48%;
                  &:nth-child(3n) {
                    margin-right: 6px;
                  }
                  &:nth-child(2n) {
                    margin-right: 0;
                  }
                }
              }
          }
          &.rowCount4{
              width: 24%;
              margin-right: 13px;
              margin-bottom: 30px;
              &:nth-child(4n){
                  margin-right: 0;
              }
              @media all and (max-width:1200px){
                  width: 23.8%;
              }
              &.landscape {
                @media all and (min-width: 981px) {
                  &:hover {
                    div.showCardContainer {
                      .square {
                        position: absolute;
                        z-index: 9999999;
                        transform: translateX(60px) translateY(-60px) scale(1.1);
                        div.metaData {
                          display: block;
                          visibility: visible !important;
                          animation: fadeIn 0.3s ease-in-out;
                        }
                      }
                    }
                    &:nth-child(4n) {
                      div.showCardContainer {
                        div.square {
                          transform: translateX(-60px) translateY(-60px) scale(1.1);
                        }
                      }
                    }
                    
                  }
                }
    
              
                @media all and (max-width: 980px) {
                  width: 24.3%;
                  margin-right: 6px;
                  margin-bottom: 40px;
                  &:nth-child(6n) {
                    margin-right: 6px;
                  }
                  &:nth-child(4n) {
                    margin-right: 0;
                  }
                }
                @media all and (max-width: 768px) {
                  width: 32.3%;
                  &:nth-child(4n) {
                    margin-right: 6px;
                  }
                  &:nth-child(3n) {
                    margin-right: 0;
                  }
                }
                @media all and (max-width: 640px) {
                  width: 48.2%;
    
                  &:nth-child(3n) {
                    margin-right: 6px;
                  }
                  &:nth-child(2n) {
                    margin-right: 0;
                  }
                }
                @media all and (max-width: 480px) {
                  width: 100%;
                  margin-right: 0;
                  &:nth-child(6n) {
                    margin-right: 0;
                  }
                  &:nth-child(4n) {
                    margin-right: 0;
                  }
                  &:nth-child(3n) {
                    margin-right: 0;
                  }
    
                  &:nth-child(2n) {
                    margin-right: 0;
                  }
                  @media all and (max-width: 360px) {
                    width: 100%;
    
                    &:nth-child(3n) {
                      margin-right: 0;
                    }
                    margin-right: 0;
                  }
                }
              }
              &.portrait {
                @media all and (min-width: 981px) {
                  &:hover {
                    div.showCardContainer {
                      .square {
                        position: absolute;
                        z-index: 9999999;
                        transform: translateX(60px) translateY(-60px) scale(1.2);
                        div.metaData {
                          display: block;
                          visibility: visible !important;
                          animation: fadeIn 0.3s ease-in-out;
                        }
                      }
                    }
                    &:nth-child(4n) {
                      div.showCardContainer {
                        div.square {
                          transform: translateX(-60px) translateY(-60px) scale(1.2);
                        }
                      }
                    }
                    @media all and (max-width: 1300px) {
                      &:nth-child(6n) {
                        div.showCardContainer {
                          div.square {
                            transform: translateX(-60px) translateY(-60px)
                              scale(1.2);
                          }
                        }
                      }
                      &:nth-child(7n) {
                        div.showCardContainer {
                          div.square {
                            transform: translateX(60px) translateY(-60px) scale(1.2);
                          }
                        }
                      }
                    }
                    &:last-child {
                      div.showCardContainer {
                        div.square {
                          transform: translateX(-60px) translateY(-60px) scale(1.2);
                        }
                      }
                    }
                  }
                }
               
                @media all and (max-width: 980px) {
                  width: 24%;
                  margin-right: 6px;
                  margin-bottom: 40px;
                  &:nth-child(5n) {
                    margin-right: 6px;
                  }
                  &:nth-child(4n) {
                    margin-right: 0;
                  }
                  &:nth-child(6n) {
                    margin-right: 6px;
                  }
                }
                @media all and (max-width: 768px) {
                  width: 32%;
                  &:nth-child(4n) {
                    margin-right: 6px;
                  }
                  &:nth-child(3n) {
                    margin-right: 0;
                  }
                }
                @media all and (max-width: 640px) {
                  width: 48%;
                  &:nth-child(3n) {
                    margin-right: 6px;
                  }
                  &:nth-child(2n) {
                    margin-right: 0;
                  }
                }
              }
          }
          &.rowCount5{
              width: 19%;
              margin-right: 13px;
              margin-bottom: 30px;
              &:nth-child(5n){
                  margin-right: 0;
              }
              @media all and (max-width:1200px){
                  width: 18.5%;
              }
              &.landscape {
                @media all and (min-width: 981px) {
                  &:hover {
                    div.showCardContainer {
                      .square {
                        position: absolute;
                        z-index: 9999999;
                        transform: translateX(60px) translateY(-60px) scale(1.1);
                        div.metaData {
                          display: block;
                          visibility: visible !important;
                          animation: fadeIn 0.3s ease-in-out;
                        }
                      }
                    }
                    &:nth-child(5n) {
                      div.showCardContainer {
                        div.square {
                          transform: translateX(-60px) translateY(-60px) scale(1.1);
                        }
                      }
                    }
                   
                  }
                }
    
               
                @media all and (max-width: 980px) {
                  width: 24.3%;
                  margin-right: 6px;
                  margin-bottom: 40px;
                  &:nth-child(6n) {
                    margin-right: 6px;
                  }
                  &:nth-child(4n) {
                    margin-right: 0;
                  }
                }
                @media all and (max-width: 768px) {
                  width: 32.3%;
                  &:nth-child(4n) {
                    margin-right: 6px;
                  }
                  &:nth-child(3n) {
                    margin-right: 0;
                  }
                }
                @media all and (max-width: 640px) {
                  width: 48.2%;
    
                  &:nth-child(3n) {
                    margin-right: 6px;
                  }
                  &:nth-child(2n) {
                    margin-right: 0;
                  }
                }
                @media all and (max-width: 480px) {
                  width: 100%;
                  margin-right: 0;
                  &:nth-child(6n) {
                    margin-right: 0;
                  }
                  &:nth-child(4n) {
                    margin-right: 0;
                  }
                  &:nth-child(3n) {
                    margin-right: 0;
                  }
    
                  &:nth-child(2n) {
                    margin-right: 0;
                  }
                  @media all and (max-width: 360px) {
                    width: 100%;
    
                    &:nth-child(3n) {
                      margin-right: 0;
                    }
                    margin-right: 0;
                  }
                }
              }
              &.portrait {
                @media all and (min-width: 981px) {
                  &:hover {
                    div.showCardContainer {
                      .square {
                        position: absolute;
                        z-index: 9999999;
                        transform: translateX(60px) translateY(-60px) scale(1.2);
                        div.metaData {
                          display: block;
                          visibility: visible !important;
                          animation: fadeIn 0.3s ease-in-out;
                        }
                      }
                    }
                    &:nth-child(5n) {
                      div.showCardContainer {
                        div.square {
                          transform: translateX(-60px) translateY(-60px) scale(1.2);
                        }
                      }
                    }
                    @media all and (max-width: 1300px) {
                      &:nth-child(6n) {
                        div.showCardContainer {
                          div.square {
                            transform: translateX(-60px) translateY(-60px)
                              scale(1.2);
                          }
                        }
                      }
                      &:nth-child(7n) {
                        div.showCardContainer {
                          div.square {
                            transform: translateX(60px) translateY(-60px) scale(1.2);
                          }
                        }
                      }
                    }
                    &:last-child {
                      div.showCardContainer {
                        div.square {
                          transform: translateX(-60px) translateY(-60px) scale(1.2);
                        }
                      }
                    }
                  }
                }
                // width: 13.8%;
                // &:nth-child(7n) {
                //   margin-right: 0;
                // }
                // @media all and (max-width: 1300px) {
                //   width: 16%;
                //   &:nth-child(7n) {
                //     margin-right: 6px;
                //   }
                //   &:nth-child(6n) {
                //     margin-right: 0;
                //   }
                // }
                @media all and (max-width: 980px) {
                  width: 24%;
                  margin-right: 6px;
                  margin-bottom: 40px;
                  &:nth-child(5n) {
                    margin-right: 6px;
                  }
                  &:nth-child(4n) {
                    margin-right: 0;
                  }
                  &:nth-child(6n) {
                    margin-right: 6px;
                  }
                }
                @media all and (max-width: 768px) {
                  width: 32%;
                  &:nth-child(4n) {
                    margin-right: 6px;
                  }
                  &:nth-child(3n) {
                    margin-right: 0;
                  }
                }
                @media all and (max-width: 640px) {
                  width: 48%;
                  &:nth-child(3n) {
                    margin-right: 6px;
                  }
                  &:nth-child(2n) {
                    margin-right: 0;
                  }
                }
              }
          }
          &.rowCount6{
              width: 15.8%;
              margin-right: 13px;
              margin-bottom: 30px;
              &:nth-child(6n){
                  margin-right: 0;
              }
              @media all and (max-width:1400px){
                  width: 15.5%;
              }
              @media all and (max-width:1200px){
                  width: 15%;
              }
              &.landscape {
                @media all and (min-width: 981px) {
                  &:hover {
                    div.showCardContainer {
                      .square {
                        position: absolute;
                        z-index: 9999999;
                        transform: translateX(60px) translateY(-60px) scale(1.1);
                        div.metaData {
                          display: block;
                          visibility: visible !important;
                          animation: fadeIn 0.3s ease-in-out;
                        }
                      }
                    }
                    &:nth-child(6n) {
                      div.showCardContainer {
                        div.square {
                          transform: translateX(-60px) translateY(-60px) scale(1.1);
                        }
                      }
                    }
                    
                  }
                }
    
                
                @media all and (max-width: 980px) {
                  width: 24.3%;
                  margin-right: 6px;
                  margin-bottom: 40px;
                  &:nth-child(6n) {
                    margin-right: 6px;
                  }
                  &:nth-child(4n) {
                    margin-right: 0;
                  }
                }
                @media all and (max-width: 768px) {
                  width: 32.3%;
                  &:nth-child(4n) {
                    margin-right: 6px;
                  }
                  &:nth-child(3n) {
                    margin-right: 0;
                  }
                }
                @media all and (max-width: 640px) {
                  width: 48.2%;
    
                  &:nth-child(3n) {
                    margin-right: 6px;
                  }
                  &:nth-child(2n) {
                    margin-right: 0;
                  }
                }
                @media all and (max-width: 480px) {
                  width: 100%;
                  margin-right: 0;
                  &:nth-child(6n) {
                    margin-right: 0;
                  }
                  &:nth-child(4n) {
                    margin-right: 0;
                  }
                  &:nth-child(3n) {
                    margin-right: 0;
                  }
    
                  &:nth-child(2n) {
                    margin-right: 0;
                  }
                  @media all and (max-width: 360px) {
                    width: 100%;
    
                    &:nth-child(3n) {
                      margin-right: 0;
                    }
                    margin-right: 0;
                  }
                }
              }
              &.portrait {
                @media all and (min-width: 981px) {
                  &:hover {
                    div.showCardContainer {
                      .square {
                        position: absolute;
                        z-index: 9999999;
                        transform: translateX(60px) translateY(-60px) scale(1.2);
                        div.metaData {
                          display: block;
                          visibility: visible !important;
                          animation: fadeIn 0.3s ease-in-out;
                        }
                      }
                    }
                    &:nth-child(6n) {
                      div.showCardContainer {
                        div.square {
                          transform: translateX(-60px) translateY(-60px) scale(1.2);
                        }
                      }
                    }
                    @media all and (max-width: 1300px) {
                      &:nth-child(6n) {
                        div.showCardContainer {
                          div.square {
                            transform: translateX(-60px) translateY(-60px)
                              scale(1.2);
                          }
                        }
                      }
                      &:nth-child(7n) {
                        div.showCardContainer {
                          div.square {
                            transform: translateX(60px) translateY(-60px) scale(1.2);
                          }
                        }
                      }
                    }
                    &:last-child {
                      div.showCardContainer {
                        div.square {
                          transform: translateX(-60px) translateY(-60px) scale(1.2);
                        }
                      }
                    }
                  }
                }
                // width: 13.8%;
                // &:nth-child(7n) {
                //   margin-right: 0;
                // }
                // @media all and (max-width: 1300px) {
                //   width: 16%;
                //   &:nth-child(7n) {
                //     margin-right: 6px;
                //   }
                //   &:nth-child(6n) {
                //     margin-right: 0;
                //   }
                // }
                @media all and (max-width: 980px) {
                  width: 24%;
                  margin-right: 6px;
                  margin-bottom: 40px;
                  &:nth-child(5n) {
                    margin-right: 6px;
                  }
                  &:nth-child(4n) {
                    margin-right: 0;
                  }
                  &:nth-child(6n) {
                    margin-right: 6px;
                  }
                }
                @media all and (max-width: 768px) {
                  width: 32%;
                  &:nth-child(4n) {
                    margin-right: 6px;
                  }
                  &:nth-child(3n) {
                    margin-right: 0;
                  }
                }
                @media all and (max-width: 640px) {
                  width: 48%;
                  &:nth-child(3n) {
                    margin-right: 6px;
                  }
                  &:nth-child(2n) {
                    margin-right: 0;
                  }
                }
              }
          }
          &.rowCount7 {
            width: 13.9%;
            margin-right: 6px;
            margin-bottom: 30px;
            &:nth-child(7n) {
              margin-right: 0;
            }  
            @media all and (max-width: 1200px) {
              width: 19.3%;
            }
            &.portrait {
              @media all and (max-width: 2600px) {
                width: 14%;
                margin-right: 6.5px;
                }
                @media all and (max-width: 2500px) {
                  width: 14%;
                 margin-right: 5.5px;
                }
                @media all and (max-width: 2200px) {
                  width: 13.9%;
                  margin-right: 6.5px;
                }
                @media all and (max-width: 1980px) {
                  width: 14%;
                  margin-right: 4.5px;
                }
                @media all and (max-width: 1768px) {
                  width: 14%;
                  margin-right: 4px;
                }
                @media all and (max-width: 1600px) {
                  width: 13.7%;
                  margin-right: 7.5px;
                }
                @media all and (max-width: 1400px) {
                  width: 14%;
                  margin-right: 3px;
                }
                @media all and (max-width: 1200px) {
                  width: 13.6%;
                  margin-right: 6.1px;
                }
                
                
              @media all and (min-width: 981px) {
                &:hover {
                  div.showCardContainer {
                    .square {
                      position: absolute;
                      z-index: 9999999;
                      transform: translateX(60px) translateY(-60px) scale(1.2);
                      div.metaData {
                        display: block;
                        visibility: visible !important;
                        animation: fadeIn 0.3s ease-in-out;
                      }
                    }
                  }
                  &:nth-child(7n) {
                    div.showCardContainer {
                      div.square {
                        transform: translateX(-60px) translateY(-60px) scale(1.2);
                      }
                    }
                  }
                  @media all and (max-width: 1300px) {
                    &:nth-child(6n) {
                      div.showCardContainer {
                        div.square {
                          transform: translateX(-60px) translateY(-60px)
                            scale(1.2);
                        }
                      }
                    }
                    &:nth-child(7n) {
                      div.showCardContainer {
                        div.square {
                          transform: translateX(60px) translateY(-60px) scale(1.2);
                        }
                      }
                    }
                  }
                  &:last-child {
                    div.showCardContainer {
                      div.square {
                        transform: translateX(-60px) translateY(-60px) scale(1.2);
                      }
                    }
                  }
                }
              }
              
              @media all and (max-width: 980px) {
                width: 24%;
                margin-right: 6px;
                margin-bottom: 40px;
                &:nth-child(5n) {
                  margin-right: 6px;
                }
                &:nth-child(4n) {
                  margin-right: 0;
                }
                &:nth-child(6n) {
                  margin-right: 6px;
                }
              }
              @media all and (max-width: 768px) {
                width: 32%;
                &:nth-child(4n) {
                  margin-right: 6px;
                }
                &:nth-child(3n) {
                  margin-right: 0;
                }
              }
              @media all and (max-width: 640px) {
                width: 48%;
                &:nth-child(3n) {
                  margin-right: 6px;
                }
                &:nth-child(2n) {
                  margin-right: 0;
                }
              }
            }
          }
          &.rowCount8 {
            width: 12%;
            margin-right: 8px;
            margin-bottom: 30px;
            &:nth-child(8n) {
              margin-right: 0;
            }
            @media all and (max-width: 1200px) {
              width: 12%;
              margin-right: 6px;
            }
            &.landscape {
              @media all and (min-width: 981px) {
                &:hover {
                  div.showCardContainer {
                    .square {
                      position: absolute;
                      z-index: 9999999;
                      transform: translateX(60px) translateY(-60px) scale(1.1);
                      div.metaData {
                        display: block;
                        visibility: visible !important;
                        animation: fadeIn 0.3s ease-in-out;
                      }
                    }
                  }
                  &:nth-child(5n) {
                    div.showCardContainer {
                      div.square {
                        transform: translateX(-60px) translateY(-60px) scale(1.1);
                      }
                    }
                  }
                 
                }
              }
  
             
              @media all and (max-width: 980px) {
                width: 24.3%;
                margin-right: 6px;
                margin-bottom: 40px;
                &:nth-child(6n) {
                  margin-right: 6px;
                }
                &:nth-child(4n) {
                  margin-right: 0;
                }
              }
              @media all and (max-width: 768px) {
                width: 32.3%;
                &:nth-child(4n) {
                  margin-right: 6px;
                }
                &:nth-child(3n) {
                  margin-right: 0;
                }
              }
              @media all and (max-width: 640px) {
                width: 48.2%;
  
                &:nth-child(3n) {
                  margin-right: 6px;
                }
                &:nth-child(2n) {
                  margin-right: 0;
                }
              }
              @media all and (max-width: 480px) {
                width: 100%;
                margin-right: 0;
                &:nth-child(6n) {
                  margin-right: 0;
                }
                &:nth-child(4n) {
                  margin-right: 0;
                }
                &:nth-child(3n) {
                  margin-right: 0;
                }
  
                &:nth-child(2n) {
                  margin-right: 0;
                }
                @media all and (max-width: 360px) {
                  width: 100%;
  
                  &:nth-child(3n) {
                    margin-right: 0;
                  }
                  margin-right: 0;
                }
              }
            }
            &.portrait {
              @media all and (max-width: 2600px) {
                width: 12.1%;
                margin-right: 10px;
                &:nth-child(8n) {
                  margin-right: 0px;
                }
              }
              @media all and (max-width: 2500px) {
                width: 12%;
                margin-right: 11px;
              }
              @media all and (max-width: 2200px) {
                width: 12.2%;
                margin-right: 6px;
              }
              @media all and (max-width: 1980px) {
                width: 12%;
                margin-right: 9px;
              }
              @media all and (max-width: 1768px) {
                width: 11.9%;
                margin-right: 10px;
              }
              @media all and (max-width: 1600px) {
                width: 12%;
                margin-right: 7px;
              }
              @media all and (max-width: 1400px) {
                width: 12%;
                margin-right: 6px;
              }
              @media all and (max-width: 1200px) {
                width: 12%;
                margin-right: 5px;
              }
              @media all and (min-width: 981px) {
                &:hover {
                  div.showCardContainer {
                    .square {
                      position: absolute;
                      z-index: 9999999;
                      transform: translateX(60px) translateY(-60px) scale(1.2);
                      div.metaData {
                        display: block;
                        visibility: visible !important;
                        animation: fadeIn 0.3s ease-in-out;
                      }
                    }
                  }
                  &:nth-child(8n) {
                    div.showCardContainer {
                      div.square {
                        transform: translateX(-60px) translateY(-60px) scale(1.2);
                      }
                    }
                  }
                 
                
                }
              }
              
              @media all and (max-width: 980px) {
                width: 24%;
                margin-right: 6px;
                margin-bottom: 40px;
               
              }
              @media all and (max-width: 768px) {
                width: 32%;
                &:nth-child(4n) {
                  margin-right: 6px;
                }
                &:nth-child(3n) {
                  margin-right: 0;
                }
              }
              @media all and (max-width: 640px) {
                width: 48%;
                &:nth-child(3n) {
                  margin-right: 6px;
                }
                &:nth-child(2n) {
                  margin-right: 0;
                }
              }
            }
          }
          @media all and (max-width: 980px) {
            width: 24%;
            margin-right: 6px;
            margin-bottom: 40px;
            &:nth-child(5n) {
              margin-right: 6px;
            }
            &:nth-child(4n) {
              margin-right: 0;
            }
            &:nth-child(6n) {
              margin-right: 6px;
            }
          }
          @media all and (max-width: 640px) {
            width: 48%;
            &:nth-child(3n) {
              margin-right: 6px;
            }
            &:nth-child(2n) {
              margin-right: 0;
            }
          }
          &.portrait{
            
              // @media all and (max-width:1400px){
              //     width: 15.6%;
              // }
              // @media all and (max-width:1200px){
              //     width: 18.8%;
              //     &:nth-child(5n){
              //         margin-right: 0;
              //     }
              //    &:nth-child(6n){
              //     margin-right: 13px;
              //    }
              // }
              @media all and (max-width:980px){
                  width:23.5%;
                  margin-right: 13px;
                  margin-bottom: 40px;
                  &:nth-child(4n){
                      margin-right: 0;
                  }
                 &:nth-child(5n){
                  margin-right: 13px;
                 }
              }
              @media all and (max-width:768px){
                  width:23%;
                  &:nth-child(4n){
                      margin-right: 0;
                  }
                 &:nth-child(5n){
                  margin-right: 13px;
                 }
              }
              @media all and (max-width:640px){
                  width:31%;
                  
                 &:nth-child(4n){
                  margin-right: 13px;
                 }
                 &:nth-child(3n){
                  margin-right: 0;
              }
              }
              @media all and (max-width:480px){
                  width:47%;
                  
                 &:nth-child(3n){
                  margin-right: 13px;
                 }
                 &:nth-child(2n){
                  margin-right: 0;
              }
              }
          }
          &.landscape{
              // width: 32.8%;
         
          // &:nth-child(3n){
          //     margin-right: 0;
          // }
          // @media all and (max-width:1400px){
          //     width: 32.5%;
          // }
          // @media all and (max-width:1200px){
          //     width:32%;
          // //     &:nth-child(4n){
          // //         margin-right: 0;
          // //     }
          // //    &:nth-child(5n){
          // //     margin-right: 13px;
          // //    }
          // }
          @media all and (max-width:980px){
              width:32%;
              margin-right: 13px;
          margin-bottom: 40px;
              &:nth-child(3n){
                  margin-right: 0;
              }
              
          }
          @media all and (max-width:768px){
              width:47.6%;
              &:nth-child(2n){
                  margin-right: 0;
              }
              &:nth-child(3n){
                  margin-right: 13px;
              }
          }
          @media all and (max-width:640px){
              width:48%;
              
             &:nth-child(3n){
              margin-right: 13px;
             }
             &:nth-child(2n){
              margin-right: 0;
          }
          }
          @media all and (max-width:480px){
              width:47%;
              
             &:nth-child(3n){
              margin-right: 13px;
             }
             &:nth-child(2n){
              margin-right: 0;
          }
          }
          }

      }
      }
    }
  }
  
div.commonPage.rtl{
  .top{
    left: 0;
    right: 60px;
  }
  .bottom{
    .itemsContainer{
      .items{
        &.rowCount3{
          margin-right: 0;
          margin-left: 13px;
          &:nth-child(3n){
              margin-left: 0;
          }

          &.landscape {
            @media all and (min-width: 981px) {
              &:hover {
                div.showCardContainer {
                  .square {
                    transform: translateX(-60px) translateY(-60px) scale(1.1);
                  }
                }
                &:nth-child(3n) {
                  div.showCardContainer {
                    div.square {
                      transform: translateX(60px) translateY(-60px) scale(1.1);
                    }
                  }
                }
              }
            }
            @media all and (max-width: 980px) {
              margin-right: 0;
              margin-left: 6px;
              &:nth-child(6n) {
                margin-right: 0;
                margin-left: 6px;
              }
              &:nth-child(4n) {
                margin-left: 0;
              }
            }
            @media all and (max-width: 768px) {
              &:nth-child(4n) {
                margin-right: 0;
                margin-left: 6px;
              }
              &:nth-child(3n) {
                margin-left: 0;
              }
            }
            @media all and (max-width: 640px) {
              &:nth-child(3n) {
                margin-right: 0;
                margin-left: 6px;
              }
              &:nth-child(2n) {
                margin-left: 0;
              }
            }
            @media all and (max-width: 480px) {
              margin-left: 0;
              &:nth-child(6n) {
                margin-left: 0;
              }
              &:nth-child(4n) {
                margin-left: 0;
              }
              &:nth-child(3n) {
                margin-left: 0;
              }

              &:nth-child(2n) {
                margin-left: 0;
              }
              @media all and (max-width: 360px) {
                &:nth-child(3n) {
                  margin-left: 0;
                }
                margin-left: 0;
              }
            }
          }
          &.portrait {
            @media all and (min-width: 981px) {
              &:hover {
                div.showCardContainer {
                  .square {
                    transform: translateX(-60px) translateY(-60px) scale(1.2);
                  }
                }
                &:nth-child(3n) {
                  div.showCardContainer {
                    div.square {
                      transform: translateX(60px) translateY(-60px) scale(1.2);
                    }
                  }
                }
                @media all and (max-width: 1300px) {
                  &:nth-child(6n) {
                    div.showCardContainer {
                      div.square {
                        transform: translateX(60px) translateY(-60px)
                          scale(1.2);
                      }
                    }
                  }
                  &:nth-child(7n) {
                    div.showCardContainer {
                      div.square {
                        transform: translateX(-60px) translateY(-60px) scale(1.2);
                      }
                    }
                  }
                }
                &:last-child {
                  div.showCardContainer {
                    div.square {
                      transform: translateX(60px) translateY(-60px) scale(1.2);
                    }
                  }
                }
              }
            }
           
            @media all and (max-width: 980px) {
              margin-right: 0;
              margin-left: 6px;
              &:nth-child(5n) {
                margin-right: 0;
                margin-left: 6px;
              }
              &:nth-child(4n) {
                margin-left: 0;
              }
              &:nth-child(6n) {
                margin-right: 0;
                margin-left: 6px;
              }
            }
            @media all and (max-width: 768px) {
              &:nth-child(4n) {
                margin-right: 0;
                margin-left: 6px;
              }
              &:nth-child(3n) {
                margin-left: 0;
              }
            }
            @media all and (max-width: 640px) {
              &:nth-child(3n) {
                margin-right: 0;
                margin-left: 6px;
              }
              &:nth-child(2n) {
                margin-left: 0;
              }
            }
          }
        }
        &.rowCount4{
          margin-right: 0;
          margin-left: 13px;
          &:nth-child(4n){
              margin-left: 0;
          }
          &.landscape {
            @media all and (min-width: 981px) {
              &:hover {
                div.showCardContainer {
                  .square {
                    transform: translateX(-60px) translateY(-60px) scale(1.1);
                  }
                }
                &:nth-child(4n) {
                  div.showCardContainer {
                    div.square {
                      transform: translateX(60px) translateY(-60px) scale(1.1);
                    }
                  }
                }
                
              }
            }

          
            @media all and (max-width: 980px) {
              margin-right: 0;
              margin-left: 6px;
              &:nth-child(6n) {
                margin-right: 0;
                margin-left: 6px;
              }
              &:nth-child(4n) {
                margin-left: 0;
              }
            }
            @media all and (max-width: 768px) {
              &:nth-child(4n) {
                margin-right: 0;
                margin-left: 6px;
              }
              &:nth-child(3n) {
                margin-left: 0;
              }
            }
            @media all and (max-width: 640px) {
              &:nth-child(3n) {
                margin-right: 0;
                margin-left: 6px;
              }
              &:nth-child(2n) {
                margin-left: 0;
              }
            }
            @media all and (max-width: 480px) {
              margin-left: 0;
              &:nth-child(6n) {
                margin-left: 0;
              }
              &:nth-child(4n) {
                margin-left: 0;
              }
              &:nth-child(3n) {
                margin-left: 0;
              }

              &:nth-child(2n) {
                margin-left: 0;
              }
              @media all and (max-width: 360px) {
                &:nth-child(3n) {
                  margin-left: 0;
                }
                margin-left: 0;
              }
            }
          }
          &.portrait {
            @media all and (min-width: 981px) {
              &:hover {
                div.showCardContainer {
                  .square {
                    transform: translateX(-60px) translateY(-60px) scale(1.2);
                  }
                }
                &:nth-child(4n) {
                  div.showCardContainer {
                    div.square {
                      transform: translateX(60px) translateY(-60px) scale(1.2);
                    }
                  }
                }
                @media all and (max-width: 1300px) {
                  &:nth-child(6n) {
                    div.showCardContainer {
                      div.square {
                        transform: translateX(60px) translateY(-60px)
                          scale(1.2);
                      }
                    }
                  }
                  &:nth-child(7n) {
                    div.showCardContainer {
                      div.square {
                        transform: translateX(-60px) translateY(-60px) scale(1.2);
                      }
                    }
                  }
                }
                &:last-child {
                  div.showCardContainer {
                    div.square {
                      transform: translateX(60px) translateY(-60px) scale(1.2);
                    }
                  }
                }
              }
            }
           
            @media all and (max-width: 980px) {
              margin-right: 0;
              margin-left: 6px;
              &:nth-child(5n) {
                margin-right: 0;
                margin-left: 6px;
              }
              &:nth-child(4n) {
                margin-left: 0;
              }
              &:nth-child(6n) {
                margin-right: 0;
                margin-left: 6px;
              }
            }
            @media all and (max-width: 768px) {
              &:nth-child(4n) {
                margin-right: 0;
                margin-left: 6px;
              }
              &:nth-child(3n) {
                margin-left: 0;
              }
            }
            @media all and (max-width: 640px) {
              &:nth-child(3n) {
                margin-right: 0;
                margin-left: 6px;
              }
              &:nth-child(2n) {
                margin-left: 0;
              }
            }
          }
        }
        &.rowCount5{
          margin-right: 0;
          margin-left: 13px;
          &:nth-child(5n){
              margin-left: 0;
          }
          &.landscape {
            @media all and (min-width: 981px) {
              &:hover {
                div.showCardContainer {
                  .square {
                    transform: translateX(-60px) translateY(-60px) scale(1.1);
                  }
                }
                &:nth-child(5n) {
                  div.showCardContainer {
                    div.square {
                      transform: translateX(60px) translateY(-60px) scale(1.1);
                    }
                  }
                }
              
              }
            }

          
            @media all and (max-width: 980px) {
              margin-right: 0;
              margin-left: 6px;
              &:nth-child(6n) {
                margin-right: 0;
                margin-left: 6px;
              }
              &:nth-child(4n) {
                margin-left: 0;
              }
            }
            @media all and (max-width: 768px) {
              &:nth-child(4n) {
                margin-right: 0;
                margin-left: 6px;
              }
              &:nth-child(3n) {
                margin-left: 0;
              }
            }
            @media all and (max-width: 640px) {
              &:nth-child(3n) {
                margin-right: 0;
                margin-left: 6px;
              }
              &:nth-child(2n) {
                margin-left: 0;
              }
            }
            @media all and (max-width: 480px) {
              margin-left: 0;
              &:nth-child(6n) {
                margin-left: 0;
              }
              &:nth-child(4n) {
                margin-left: 0;
              }
              &:nth-child(3n) {
                margin-left: 0;
              }

              &:nth-child(2n) {
                margin-left: 0;
              }
              @media all and (max-width: 360px) {
                &:nth-child(3n) {
                  margin-left: 0;
                }
                margin-left: 0;
              }
            }
          }
          &.portrait {
            @media all and (min-width: 981px) {
              &:hover {
                div.showCardContainer {
                  .square {
                    transform: translateX(-60px) translateY(-60px) scale(1.2);
                  }
                }
                &:nth-child(5n) {
                  div.showCardContainer {
                    div.square {
                      transform: translateX(60px) translateY(-60px) scale(1.2);
                    }
                  }
                }
                @media all and (max-width: 1300px) {
                  &:nth-child(6n) {
                    div.showCardContainer {
                      div.square {
                        transform: translateX(60px) translateY(-60px)
                          scale(1.2);
                      }
                    }
                  }
                  &:nth-child(7n) {
                    div.showCardContainer {
                      div.square {
                        transform: translateX(-60px) translateY(-60px) scale(1.2);
                      }
                    }
                  }
                }
                &:last-child {
                  div.showCardContainer {
                    div.square {
                      transform: translateX(60px) translateY(-60px) scale(1.2);
                    }
                  }
                }
              }
            }
            @media all and (max-width: 980px) {
              margin-right: 0;
              margin-left: 6px;
              &:nth-child(5n) {
                margin-right: 0;
                margin-left: 6px;
              }
              &:nth-child(4n) {
                margin-left: 0;
              }
              &:nth-child(6n) {
                margin-right: 0;
                margin-left: 6px;
              }
            }
            @media all and (max-width: 768px) {
              &:nth-child(4n) {
                margin-right: 0;
                margin-left: 6px;
              }
              &:nth-child(3n) {
                margin-left: 0;
              }
            }
            @media all and (max-width: 640px) {
              &:nth-child(3n) {
                margin-right: 0;
                margin-left: 6px;
              }
              &:nth-child(2n) {
                margin-left: 0;
              }
            }
          }
        }
        &.rowCount6{
          margin-right: 0;
          margin-left: 13px;
          &:nth-child(6n){
              margin-left: 0;
          }
          &.landscape {
            @media all and (min-width: 981px) {
              &:hover {
                div.showCardContainer {
                  .square {
                    transform: translateX(-60px) translateY(-60px) scale(1.1);
                  }
                }
                &:nth-child(6n) {
                  div.showCardContainer {
                    div.square {
                      transform: translateX(60px) translateY(-60px) scale(1.1);
                    }
                  }
                }
                
              }
            }

            
            @media all and (max-width: 980px) {
              margin-right: 0;
              margin-left: 6px;
              &:nth-child(6n) {
                margin-right: 0;
                margin-left: 6px;
              }
              &:nth-child(4n) {
                margin-left: 0;
              }
            }
            @media all and (max-width: 768px) {
              &:nth-child(4n) {
                margin-right: 0;
                margin-left: 6px;
              }
              &:nth-child(3n) {
                margin-left: 0;
              }
            }
            @media all and (max-width: 640px) {
              &:nth-child(3n) {
                margin-right: 0;
                margin-left: 6px;
              }
              &:nth-child(2n) {
                margin-left: 0;
              }
            }
            @media all and (max-width: 480px) {
              margin-left: 0;
              &:nth-child(6n) {
                margin-left: 0;
              }
              &:nth-child(4n) {
                margin-left: 0;
              }
              &:nth-child(3n) {
                margin-left: 0;
              }

              &:nth-child(2n) {
                margin-left: 0;
              }
              @media all and (max-width: 360px) {
                &:nth-child(3n) {
                  margin-left: 0;
                }
                margin-left: 0;
              }
            }
          }
          &.portrait {
            @media all and (min-width: 981px) {
              &:hover {
                div.showCardContainer {
                  .square {
                    transform: translateX(-60px) translateY(-60px) scale(1.2);
                  }
                }
                &:nth-child(6n) {
                  div.showCardContainer {
                    div.square {
                      transform: translateX(60px) translateY(-60px) scale(1.2);
                    }
                  }
                }
                @media all and (max-width: 1300px) {
                  &:nth-child(6n) {
                    div.showCardContainer {
                      div.square {
                        transform: translateX(60px) translateY(-60px)
                          scale(1.2);
                      }
                    }
                  }
                  &:nth-child(7n) {
                    div.showCardContainer {
                      div.square {
                        transform: translateX(-60px) translateY(-60px) scale(1.2);
                      }
                    }
                  }
                }
                &:last-child {
                  div.showCardContainer {
                    div.square {
                      transform: translateX(60px) translateY(-60px) scale(1.2);
                    }
                  }
                }
              }
            }
            @media all and (max-width: 980px) {
              margin-right: 0;
              margin-left: 6px;
              &:nth-child(5n) {
                margin-right: 0;
                margin-left: 6px;
              }
              &:nth-child(4n) {
                margin-left: 0;
              }
              &:nth-child(6n) {
                margin-right: 0;
                margin-left: 6px;
              }
            }
            @media all and (max-width: 768px) {
              &:nth-child(4n) {
                margin-right: 0;
                margin-left: 6px;
              }
              &:nth-child(3n) {
                margin-left: 0;
              }
            }
            @media all and (max-width: 640px) {
              &:nth-child(3n) {
                margin-right: 0;
                margin-left: 6px;
              }
              &:nth-child(2n) {
                margin-left: 0;
              }
            }
          }
        }
        &.rowCount7 {
          margin-right: 0;
          margin-left: 6px;
          &:nth-child(7n) {
            margin-left: 0;
          } 
          &.portrait {
            @media all and (max-width: 2600px) {
              margin-right: 0;
              margin-left: 6.5px;
              }
              @media all and (max-width: 2500px) {
                margin-right: 0;
              margin-left: 5.5px;
              }
              @media all and (max-width: 2200px) {
                margin-right: 0;
                margin-left: 6.5px;
              }
              @media all and (max-width: 1980px) {
                margin-right: 0;
                margin-left: 4.5px;
              }
              @media all and (max-width: 1768px) {
                margin-right: 0;
                margin-left: 4px;
              }
              @media all and (max-width: 1600px) {
                margin-right: 0;
                margin-left: 7.5px;
              }
              @media all and (max-width: 1400px) {
                margin-right: 0;
                margin-left: 3px;
              }
              @media all and (max-width: 1200px) {
                margin-right: 0;
                margin-left: 6.1px;
              }
              
              
            @media all and (min-width: 981px) {
              &:hover {
                div.showCardContainer {
                  .square {
                    transform: translateX(-60px) translateY(-60px) scale(1.2);
                  }
                }
                &:nth-child(7n) {
                  div.showCardContainer {
                    div.square {
                      transform: translateX(60px) translateY(-60px) scale(1.2);
                    }
                  }
                }
                @media all and (max-width: 1300px) {
                  &:nth-child(6n) {
                    div.showCardContainer {
                      div.square {
                        transform: translateX(60px) translateY(-60px)
                          scale(1.2);
                      }
                    }
                  }
                  &:nth-child(7n) {
                    div.showCardContainer {
                      div.square {
                        transform: translateX(-60px) translateY(-60px) scale(1.2);
                      }
                    }
                  }
                }
                &:last-child {
                  div.showCardContainer {
                    div.square {
                      transform: translateX(60px) translateY(-60px) scale(1.2);
                    }
                  }
                }
              }
            }
            
            @media all and (max-width: 980px) {
              margin-right: 0;
              margin-left: 6px;
              &:nth-child(5n) {
                margin-right: 0;
                margin-left: 6px;
              }
              &:nth-child(4n) {
                margin-left: 0;
              }
              &:nth-child(6n) {
                margin-right: 0;
                margin-left: 6px;
              }
            }
            @media all and (max-width: 768px) {
              &:nth-child(4n) {
                margin-right: 0;
                margin-left: 6px;
              }
              &:nth-child(3n) {
                margin-left: 0;
              }
            }
            @media all and (max-width: 640px) {
              &:nth-child(3n) {
                margin-right: 0;
                margin-left: 6px;
              }
              &:nth-child(2n) {
                margin-left: 0;
              }
            }
          }
        }
        &.rowCount8 {
          margin-right: 0;
          margin-left: 8px;
          &:nth-child(8n) {
            margin-left: 0;
          }
          @media all and (max-width: 1200px) {
            margin-right: 0;
            margin-left: 6px;
          }
          &.landscape {
            @media all and (min-width: 981px) {
              &:hover {
                div.showCardContainer {
                  .square {
                    transform: translateX(-60px) translateY(-60px) scale(1.1);
                  }
                }
                &:nth-child(5n) {
                  div.showCardContainer {
                    div.square {
                      transform: translateX(60px) translateY(-60px) scale(1.1);
                    }
                  }
                }
              
              }
            }

          
            @media all and (max-width: 980px) {
              margin-right: 0;
              margin-left: 6px;
              &:nth-child(6n) {
                margin-right: 0;
                margin-left: 6px;
              }
              &:nth-child(4n) {
                margin-left: 0;
              }
            }
            @media all and (max-width: 768px) {
              &:nth-child(4n) {
                margin-right: 0;
                margin-left: 6px;
              }
              &:nth-child(3n) {
                margin-left: 0;
              }
            }
            @media all and (max-width: 640px) {
              &:nth-child(3n) {
                margin-right: 0;
                margin-left: 6px;
              }
              &:nth-child(2n) {
                margin-left: 0;
              }
            }
            @media all and (max-width: 480px) {
              margin-left: 0;
              &:nth-child(6n) {
                margin-left: 0;
              }
              &:nth-child(4n) {
                margin-left: 0;
              }
              &:nth-child(3n) {
                margin-left: 0;
              }

              &:nth-child(2n) {
                margin-left: 0;
              }
              @media all and (max-width: 360px) {
                &:nth-child(3n) {
                  margin-left: 0;
                }
                margin-left: 0;
              }
            }
          }
          &.portrait {
            @media all and (max-width: 2600px) {
              margin-right: 0;
              margin-left: 10px;
              &:nth-child(8n) {
                margin-left: 0px;
              }
            }
            @media all and (max-width: 2500px) {
              margin-right: 0;
              margin-left: 11px;
            }
            @media all and (max-width: 2200px) {
              margin-right: 0;
              margin-left: 6px;
            }
            @media all and (max-width: 1980px) {
              margin-right: 0;
              margin-left: 9px;
            }
            @media all and (max-width: 1768px) {
              margin-right: 0;
              margin-left: 10px;
            }
            @media all and (max-width: 1600px) {
              margin-right: 0;
              margin-left: 7px;
            }
            @media all and (max-width: 1400px) {
              margin-right: 0;
              margin-left: 6px;
            }
            @media all and (max-width: 1200px) {
              margin-right: 0;
              margin-left: 5px;
            }
            @media all and (min-width: 981px) {
              &:hover {
                div.showCardContainer {
                  .square {
                    transform: translateX(-60px) translateY(-60px) scale(1.2);
                  }
                }
                &:nth-child(8n) {
                  div.showCardContainer {
                    div.square {
                      transform: translateX(60px) translateY(-60px) scale(1.2);
                    }
                  }
                }
              
              
              }
            }
            
            @media all and (max-width: 980px) {
              margin-right: 0;
              margin-left: 6px;
            
            }
            @media all and (max-width: 768px) {
              &:nth-child(4n) {
                margin-right: 0;
                margin-left: 6px;
              }
              &:nth-child(3n) {
                margin-left: 0;
              }
            }
            @media all and (max-width: 640px) {
              &:nth-child(3n) {
                margin-right: 0;
                margin-left: 6px;
              }
              &:nth-child(2n) {
                margin-left: 0;
              }
            }
          }
        }
        @media all and (max-width: 980px) {
          margin-right: 0;
          margin-left: 6px;
          &:nth-child(5n) {
            margin-right: 0;
            margin-left: 6px;
          }
          &:nth-child(4n) {
            margin-left: 0;
          }
          &:nth-child(6n) {
            margin-right: 0;
            margin-left: 6px;
          }
        }
        @media all and (max-width: 640px) {
          &:nth-child(3n) {
            margin-right: 0;
            margin-left: 6px;
          }
          &:nth-child(2n) {
            margin-left: 0;
          }
        }
        &.portrait{
          @media all and (max-width:980px){
            margin-right: 0;
              margin-left: 13px;
              &:nth-child(4n){
                  margin-left: 0;
              }
            &:nth-child(5n){
              margin-right: 0;
              margin-left: 13px;
            }
          }
          @media all and (max-width:768px){
              &:nth-child(4n){
                  margin-left: 0;
              }
            &:nth-child(5n){
              margin-right: 0;
              margin-left: 13px;
            }
          }
          @media all and (max-width:640px){
            &:nth-child(4n){
              margin-right: 0;
              margin-left: 13px;
            }
            &:nth-child(3n){
              margin-left: 0;
          }
          }
          @media all and (max-width:480px){
            &:nth-child(3n){
              margin-right: 0;
              margin-left: 13px;
            }
            &:nth-child(2n){
              margin-left: 0;
          }
          }
        }
        &.landscape{
        @media all and (max-width:980px){
          margin-right: 0;
            margin-left: 13px;
            &:nth-child(3n){
                margin-left: 0;
            }
            
        }
        @media all and (max-width:768px){
            &:nth-child(2n){
                margin-left: 0;
            }
            &:nth-child(3n){
              margin-right: 0;
                margin-left: 13px;
            }
        }
        @media all and (max-width:640px){
          &:nth-child(3n){
            margin-right: 0;
            margin-left: 13px;
          }
          &:nth-child(2n){
            margin-left: 0;
        }
        }
        @media all and (max-width:480px){
          &:nth-child(3n){
            margin-right: 0;
            margin-left: 13px;
          }
          &:nth-child(2n){
            margin-left: 0;
        }
        }
        }
      }
    }
  }
}
