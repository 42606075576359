*{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  list-style: none;
}



code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',Gilroy-Medium,Gilroy-SemiBold
    monospace;
}
@font-face {
  font-family: "Vaud";
  font-weight: 300;
  src: url('./assets/Fonts/Vaud300.woff2') format("woff2"), url('./assets/Fonts/Vaud300.woff') format("woff"), url('./assets/Fonts/Vaud300.ttf') format("truetype")
}

@font-face {
  font-family: "Vaud";
  font-weight: 400;
  src: url('./assets/Fonts/Vaud400.woff2') format("woff2"), url('./assets/Fonts/Vaud400.woff') format("woff"), url('./assets/Fonts/Vaud400.ttf') format("truetype")
}

@font-face {
  font-family: "Vaud";
  font-weight: 600;
  src: url('./assets/Fonts/Vaud600.woff2') format("woff2"), url('./assets/Fonts/Vaud600.woff') format("woff"), url('./assets/Fonts/Vaud600.ttf') format("truetype")
}

@font-face {
  font-family: "Vaud";
  font-weight: 700;
  src: url('./assets/Fonts/Vaud700.woff2') format("woff2"), url('./assets/Fonts/Vaud700.woff') format("woff"), url('./assets/Fonts/Vaud700.ttf') format("truetype")
}

@font-face {
  font-family: 'Avenir-Roman';
  src: url('./assets/Fonts/avenir-roman-maisfontes.d562/avenir-roman.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'Gilroy-SemiBold';
  src: url('./assets/Fonts/Gilroy-SemiBold.ttf') format('truetype');
  font-weight: 600; 
  font-style: normal;   
}
@font-face {
  font-family: 'Gilroy-Medium';
  src: url('./assets/Fonts/Gilroy-Medium.ttf') format('truetype');
  /* font-weight: 500;  */
  font-style: normal;   
}

@font-face {
  font-family: 'Gilroy';
  src: url('./assets/Fonts/Gilroy-Light.ttf') format('truetype');
  font-weight: normal; 
  font-style: normal;   
}
@font-face {
  font-family: 'Avenir-Black';
  src: url('./assets/Fonts/avenir-black.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'Avenir-Medium';
  src: url('./assets/Fonts/Avenir Medium.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}
body {
  margin: 0;
  font-family:Vaud,Helvetica, sans-serif,Gilroy-Medium,Gilroy-SemiBold,Avenir-Black,Avenir-Roman,Avenir-Medium;
  /* font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif; */
    /* font-family: , Helvetica, sans-serif; */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
a{
  text-decoration: none;
  color: #fff;
}
button{
  background-color: none;
  outline: none;
  border: none;
  font-family:Vaud,Helvetica, sans-serif;
}

.rtl{
  direction: rtl;
  text-align: right;
}

.template-9 button {
  font-family: 'Gilroy-Medium' !important;
}
.template-9 {
  font-family: 'Gilroy-SemiBold';
}
.template-10 h1 {
  font-family: 'Avenir-Black',sans-serif;
}
.template-10 h2 {
  font-family: 'Avenir-Black',sans-serif;
}
.template-10 h3 {
  font-family: 'Avenir-Black',sans-serif;
}
.template-10 h4 {
  font-family: 'Avenir-Black',sans-serif;
}
.template-10 h5 {
  font-family: 'Avenir-Black',sans-serif;
}
.template-10 h6 {
  font-family: 'Avenir-Black',sans-serif;
}
.template-10 .title {
  font-family: 'Avenir-Black',sans-serif;
}
.template-10 .heading {
  font-family: 'Avenir-Black',sans-serif;
}
.template-10 button {
  font-family: 'Avenir-Medium',sans-serif;
}
.template-10 {
  font-family: 'Avenir-Roman',sans-serif;
}
